import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { updateProductAssets } from './update-product-assets';
import {
  UpdateProductAssetsArgs,
  UpdateProductAssetsResponse,
} from './update-product-assets.types';

export const useUpdateProductAssets = <
  TransformedData = UpdateProductAssetsResponse,
>(
  props?: GenericMutateQueryProps<
    UpdateProductAssetsArgs,
    UpdateProductAssetsResponse,
    TransformedData
  >,
) => {
  const { onMutate: onUpdateProductAssets, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to update product assets.',
    mutationFn: updateProductAssets,
    ...props,
  });

  return { ...rest, onUpdateProductAssets };
};
