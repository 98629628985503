import { FC, useEffect, useRef, useState } from 'react';
import { Box, Icon, Image } from 'gantri-components';
import {
  StyledCarouselIconContainer,
  StyledCarouselPage,
  StyledImageContainer,
  StyledImageWrapper,
} from './photos-gallery.styles';
import { CarouselProps } from './photos-gallery.types';

export const PhotosGalleryCarousel: FC<CarouselProps> = (props) => {
  const { currentIndex, images, onPageChanged } = props;
  const [activeIndex, setActiveIndex] = useState(currentIndex);
  const index = useRef(currentIndex);

  useEffect(() => {
    setActiveIndex(currentIndex);
  }, [currentIndex]);

  const goToPage = (newIndex: number): void => {
    setActiveIndex(newIndex);

    if (onPageChanged) {
      onPageChanged(newIndex);
    }
  };

  const previous = async () => {
    const nextPage =
      currentIndex - 1 < 0 ? images.length - 1 : currentIndex - 1;

    index.current = nextPage;
    goToPage(nextPage);
  };

  const next = async () => {
    const nextPage =
      currentIndex + 1 > images.length - 1 ? 0 : currentIndex + 1;

    index.current = nextPage;
    goToPage(nextPage);
  };

  return (
    <Box
      height="100%"
      maxWidth="100%"
      overflow="hidden"
      tabIndex={-1}
      width="100%"
    >
      <Box height="100%" style={{ position: 'relative' }} width="100%">
        <StyledCarouselPage>
          <StyledImageWrapper>
            <StyledImageContainer>
              <Image
                alt=""
                fallbackColor="transparent"
                height="100%"
                objectFit="contain"
                source="dynamic"
                src={images[activeIndex]}
                transformationsVariant="f"
                width="100%"
              />
            </StyledImageContainer>
          </StyledImageWrapper>
        </StyledCarouselPage>

        <StyledCarouselIconContainer onClick={previous}>
          <Icon
            color="alt"
            cursor="pointer"
            height="2.4rem"
            name="arrows:arrow_chevron_left_24"
          />
        </StyledCarouselIconContainer>

        <StyledCarouselIconContainer right onClick={next}>
          <Icon
            color="alt"
            cursor="pointer"
            height="2.4rem"
            name="arrows:arrow_chevron_right_24"
          />
        </StyledCarouselIconContainer>
      </Box>
    </Box>
  );
};
