import { Typography, Icon, Tooltip, Stack } from 'gantri-components';
import { List } from '../../../../../../../../../../../../../../components/common/list';
import { Divider } from '../../../../../../../../../../../../../../components/divider';
import { BulkUploadHeaderTooltipIconProps } from './bulk-upload-table-header-tooltip-icon.types';

export const BulkUploadHeaderTooltipIcon = (
  props: BulkUploadHeaderTooltipIconProps,
) => {
  const { footer, header, items } = props;

  return (
    <Tooltip
      Component={
        <Stack
          gap="x"
          horizontalPadding="2x"
          verticalPadding="1.2rem"
          width="30rem"
        >
          {header}
          <List type="unordered">
            {items.map((item, index) => {
              const isArray = Array.isArray(item);

              return isArray ? (
                <List key={index} type="unordered">
                  {item.map((item) => {
                    return (
                      <li key={item}>
                        <Typography text={item} />
                      </li>
                    );
                  })}
                </List>
              ) : (
                <li key={item}>
                  <Typography text={item} />
                </li>
              );
            })}
          </List>
          <Divider />
          {footer}
        </Stack>
      }
      position="right-start"
    >
      <Icon name="alert:i_circle" />
    </Tooltip>
  );
};
