import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { DiscardStockArgs, DiscardStockResponse } from './discard-stock.types';

export const discardStock = ({ id }: DiscardStockArgs) => {
  return axios
    .put<DiscardStockResponse>(`${getCoreApiUrl('stocks')}/${id}/discard`)
    .then(({ data }) => {
      return data;
    });
};
