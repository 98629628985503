import { Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { VLMLocationGrid } from '../../../../../../../../jobs/components/modals/start-job/components/common/vlm-location-grid';
import { VlmNotifiedMessage } from '../../../../../../../../jobs/components/modals/start-job/components/common/vlm-notified-message';
import { StyledFlexContainer } from '../../../../../../../../jobs/components/modals/start-job/components/start-finishing-stage-job-modal/components/step-put-in-vlm/put-in-vlm-content/put-in-vlm-content.styles';
import {
  cancelOrUpdateStockVersionModalVlmLocationAtom,
  cancelOrUpdateStockVersionModalVlmNotifiedAtom,
} from '../../../cancel-or-update-stock-version-modal.atoms';
import { RequestVlmContentProps } from './request-vlm-content.types';

export const RequestVlmContent = (props: RequestVlmContentProps) => {
  const { stockId } = props;

  const vlmNotified = useRecoilValue(
    cancelOrUpdateStockVersionModalVlmNotifiedAtom,
  );
  const vlmLocation = useRecoilValue(
    cancelOrUpdateStockVersionModalVlmLocationAtom,
  );

  return (
    <>
      <VlmNotifiedMessage vlmNotified={vlmNotified} />
      <Typography
        align="center"
        text={`Please pick stock #${stockId} parts from the indicated bin:`}
      />
      <StyledFlexContainer
        justifyContent="center"
        paddingBottom="3.2rem"
        paddingTop="3.2rem"
      >
        <VLMLocationGrid stockLocation={vlmLocation} />
      </StyledFlexContainer>
    </>
  );
};
