import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { dataAttrIsActive } from './menu-link.constants';

export const StyledMenuLinkWrapper = styled.div`
  position: relative;
`;

export const StyledMenuLink = styled(Link)<{
  $isSidebarOpen: boolean;
  $isSubPath: boolean;
  $isValidLink: boolean;
  [dataAttrIsActive]: '' | undefined;
}>`
  align-items: center;
  min-height: 3.4rem;
  display: flex;
  text-decoration: none;

  ${({ $isSidebarOpen }) => {
    return $isSidebarOpen
      ? `
        padding: 0.5rem 1.4rem;
      `
      : `
        justify-content: center;
        padding: 0.5rem;
      `;
  }}

  ${({ $isSidebarOpen, $isSubPath }) => {
    return (
      $isSidebarOpen &&
      $isSubPath &&
      `
      padding-left: 4.6rem;
    `
    );
  }}
  &[${dataAttrIsActive}],
  &:hover {
    text-decoration: none;

    ${({ $isValidLink }) => {
      return $isValidLink
        ? css`
            background-color: ${(props) => {
              return props.theme.colors.surfaces.monochrome.t3;
            }};
            display: flex;

            & > p {
              color: ${(props) => {
                return props.theme.colors.typography.t1;
              }};
              font-family: Sohne Dreiviertelfett, 'Helvetica', sans-serif;
            }

            svg[fill],
            path[fill] {
              fill: ${(props) => {
                return props.theme.colors.typography.t1;
              }};
            }
          `
        : css`
            & > p {
              text-decoration: none;
              pointer-events: none;
            }
          `;
    }}
  }
`;

export const StyledIconWrapper = styled.div<{ isSidebarOpen: boolean }>`
  display: inline-grid;
  padding-right: ${({ isSidebarOpen }) => {
    return isSidebarOpen && '1.6rem';
  }};
`;

export const StyledToggleWrapper = styled.div`
  height: 100%;
  padding: 0.5rem 0.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
