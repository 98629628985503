import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { RestockStockArgs, RestockStockResponse } from './restock-stock.types';

export const restockStock = ({ id }: RestockStockArgs) => {
  return axios
    .put<RestockStockResponse>(`${getCoreApiUrl('stocks')}/${id}/restock`)
    .then(({ data }) => {
      return data;
    });
};
