import { Modal } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { PrintTransferQueueModalContent } from './components/print-transfer-queue-modal-content';
import { PrintTransferQueueModalFooter } from './components/print-transfer-queue-modal-footer';
import { PrintTransferQueueModalHeader } from './components/print-transfer-queue-modal-header';
import { printPrepareQueueModalAtoms } from './print-transfer-queue-modal.atoms';
import { PrintTransferQueueModalProps } from './print-transfer-queue-modal.types';
import { PrintTransferQueueDetailsPanel } from './components/print-transfer-queue-details-panel';
import { useDeletePrintTransferQueueMutation } from '../../../../../api/queue/print-transfer';
import { useResetRecoilAtomsOnMount } from '../../../../../hooks/use-reset-recoil-atoms-on-mount';
import { useHandleCloseModal } from '../hooks/use-handle-close-modal';

export const PrintTransferQueueModal = (
  props: PrintTransferQueueModalProps,
) => {
  const { onClose, onUpdate } = props;

  const queueData = useRecoilValue(printPrepareQueueModalAtoms.queueData);
  const deleteQueueOnClose = useRecoilValue(
    printPrepareQueueModalAtoms.deleteQueueOnClose,
  );

  const { queueData: _queueDataAtom, ...otherAtoms } =
    printPrepareQueueModalAtoms;
  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    // exclude queueData atom as it is set prior to modal open
    otherAtoms,
  );

  const { onDeletePrintTransferQueue } = useDeletePrintTransferQueueMutation();

  const handleCloseModal = useHandleCloseModal({
    onClose,
    onUpdate: async () => {
      await onDeletePrintTransferQueue(queueData?.id);
      await onUpdate();
    },
    updateOnClose: deleteQueueOnClose,
  });

  return (
    <ResetAtomsWrapper>
      <Modal
        closeable={false}
        contentStackProps={{ alignContent: 'stretch' }}
        detailsPanel={<PrintTransferQueueDetailsPanel />}
        detailsPanelWidth="1fr"
        footer={
          <PrintTransferQueueModalFooter handleCloseModal={handleCloseModal} />
        }
        header={
          <PrintTransferQueueModalHeader handleCloseModal={handleCloseModal} />
        }
        isFullScreen
      >
        <PrintTransferQueueModalContent />
      </Modal>
    </ResetAtomsWrapper>
  );
};
