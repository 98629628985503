import { Button } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { stocksApi } from '../../../../../../../../../api';
import { useNotification } from '../../../../../../../../../hooks/useNotification';
import {
  cancelOrUpdateStockVersionModalStepAtom,
  cancelOrUpdateStockVersionModalUpdateOnCloseAtom,
} from '../../../cancel-or-update-stock-version-modal.atoms';
import { ConfirmCancelFooterProps } from './confirm-cancel-footer.types';

export const ConfirmCancelFooter = (props: ConfirmCancelFooterProps) => {
  const { onClose, stockId } = props;

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const setStep = useSetRecoilState(cancelOrUpdateStockVersionModalStepAtom);
  const setUpdateOnClose = useSetRecoilState(
    cancelOrUpdateStockVersionModalUpdateOnCloseAtom,
  );

  const handleCancelStock = async () => {
    try {
      showLoading();

      await stocksApi.cancelStock({ stockId });

      setUpdateOnClose(true);
      setStep('UPDATE_SUCCESS');
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to cancel stock.',
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <>
      <Button size="large" text="Exit" variant="secondary" onClick={onClose} />
      <Button size="large" text="Cancel Stock" onClick={handleCancelStock} />
    </>
  );
};
