import { Button, Flex, Modal, Stack, Typography } from 'gantri-components';
import { partStatuses } from '../../../../../../constants/options';
import { useHandleCloseModal } from '../../../../../jobs/components/modals/hooks/use-handle-close-modal';
import { List } from '../../../../../../components/common/list';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import routePaths from '../../../../../../config/route-paths';
import { OverridePartDelaysModalProps } from './override-part-delays-modal.types';
import { useOverrideDelayedParts } from '../../../../../../api/stocks/routes';

export const OverridePartDelaysModal = (
  props: OverridePartDelaysModalProps,
) => {
  const { onClose, onRefreshStock, stock } = props;

  const handleCloseModal = useHandleCloseModal({
    onClose,
    onUpdate: onRefreshStock,
  });

  const { isLoading, onOverridePartDelays } = useOverrideDelayedParts({
    onSuccess: async () => {
      await handleCloseModal({ updateOnClose: true });
    },
  });

  const delayedParts = stock.parts.filter(({ status }) => {
    return status === partStatuses.delayedStart;
  });

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={async () => {
              await handleCloseModal();
            }}
          />
          <Button
            processing={isLoading}
            size="large"
            text="Confirm"
            onClick={async () => {
              await onOverridePartDelays({ stockId: stock.id });
            }}
          />
        </>
      }
      header="Confirm override"
      maxWidth={{ lg: '45.2rem', md: '100%' }}
      onClose={handleCloseModal}
    >
      <Stack gap="x">
        <Typography text="Please confirm that you want to override part delays for the following part(s):" />
        <List>
          {delayedParts.map(({ id, name }) => {
            return (
              <li key={id}>
                <Flex gap=".5x">
                  <Typography text={name} />
                  <StyledAnchor
                    href={`${routePaths.parts}/${id}`}
                    target="_blank"
                    text={`#${id}`}
                  />
                </Flex>
              </li>
            );
          })}
        </List>
        <Typography
          marginTop="2x"
          text="Upon override, part(s) will be in 'Waiting' status and the first part job(s) will be in 'Ready' status,"
        />
      </Stack>
    </Modal>
  );
};
