import { useEffect, useState } from 'react';
import { useAsync } from 'react-use';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useFetchSandingMinutes } from '../../../../api/jobs/routes';
import { userAtoms } from '../../../../global-atoms/users';
import { delayFor } from '../../../../helpers/delay-for';
import { progressTransitionTimeMs } from '../../components/sanding-minutes-earned-toast/sanding-minutes-earned-toast.styles';
import { sandingMinutesProgressAtoms } from '../../sanding-minutes-progress.atoms';

export const useHandleSandingMinutesProgress = () => {
  const { id: userId, isSander } = useRecoilValue(userAtoms.user);

  // ? render state is separate to prevent invisible yet interact-able elements and as to not interfere with css transitions
  const [isToastRendered, setIsToastRendered] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  const [isFirstRequest, setIsFirstRequest] = useRecoilState(
    sandingMinutesProgressAtoms.isFirstRequest,
  );
  const [sandingMinutes, setSandingMinutes] = useRecoilState(
    sandingMinutesProgressAtoms.sandingMinutes,
  );
  const resetSandingMinutes = useResetRecoilState(
    sandingMinutesProgressAtoms.sandingMinutes,
  );

  const { data, isLoading = true } = useFetchSandingMinutes({
    enabled: isSander,
    fetchArgs: { userId },
  });

  const {
    jobsCompleted,
    lastCompletedJobDuration = 0,
    sandingMinutes: newSandingMinutes = 0,
  } = { ...data };

  useEffect(() => {
    const updateSandingMinutes = (props: { isNewlyCompletedJob: boolean }) => {
      const { isNewlyCompletedJob } = props;

      setSandingMinutes(newSandingMinutes);
      setIsFirstRequest(false);

      if (isNewlyCompletedJob) {
        setIsToastRendered(true);
      }
    };

    const shouldUpdateSandingMinutes = !!newSandingMinutes;

    const shouldResetSandingMinutes = jobsCompleted === 0;

    if (shouldUpdateSandingMinutes) {
      const isNewlyCompletedJob =
        !isFirstRequest &&
        !!newSandingMinutes &&
        newSandingMinutes !== sandingMinutes;

      updateSandingMinutes({ isNewlyCompletedJob });
    } else if (shouldResetSandingMinutes) {
      resetSandingMinutes();
    }
  }, [jobsCompleted, newSandingMinutes]);

  useAsync(async () => {
    // ? render content first to prevent invisible assets overlaying page content
    if (isToastRendered) {
      // ? short delay while content is rendered
      await delayFor(50);

      // ? activate rendered content
      setIsActive(true);

      // ? keep visible for time + transition duration
      await delayFor(4000 + progressTransitionTimeMs);

      // ? update prev value with new value
      setSandingMinutes(newSandingMinutes);

      // ? de-activate rendered content
      setIsActive(false);

      // ? wait for CSS transition to stop before removing content
      await delayFor(progressTransitionTimeMs);

      // ? remove rendered content
      setIsToastRendered(false);
    }
  }, [isToastRendered]);

  return {
    isActive,
    isLoading,
    isToastRendered,
    jobsCompleted,
    lastCompletedJobDuration,
    newSandingMinutes,
    sandingMinutes,
    setIsActive,
    setIsToastRendered,
    setSandingMinutes,
  };
};
