import { Conditional, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { FullScreenModalHeader } from '../../../common/full-screen-modal-header';
import { PrintTransferQueueModalHeaderProps } from './print-transfer-queue-modal-header.types';
import { printPrepareQueueModalAtoms } from '../../print-transfer-queue-modal.atoms';
import { printPrepareQueueModalSteps } from '../../print-transfer-queue-modal.constants';
import { JobSetNavigation } from './components/job-set-navigation';
import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../constants/options';

export const PrintTransferQueueModalHeader = (
  props: PrintTransferQueueModalHeaderProps,
) => {
  const { handleCloseModal } = props;

  const step = useRecoilValue(printPrepareQueueModalAtoms.step);

  const showJobSetNavigation = ![printPrepareQueueModalSteps.queueDeleted].some(
    (stepToHide) => {
      return stepToHide === step;
    },
  );

  return (
    <FullScreenModalHeader
      handleCloseModal={handleCloseModal}
      ModalContent={
        <>
          <Typography
            align="center"
            text={`Are you sure you want to exit ${jobSteps.print} ${jobTypeOptions.transfer}?`}
          />
          <Typography
            align="center"
            text="You will not be able to return to this queue."
          />
        </>
      }
      text={`${jobSteps.print} ${jobTypeOptions.transfer} Queue`}
    >
      <Conditional condition={showJobSetNavigation}>
        <JobSetNavigation />
      </Conditional>
    </FullScreenModalHeader>
  );
};
