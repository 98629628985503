import { useEffect, useState } from 'react';
import cx from 'classnames';
import {
  Box,
  Button,
  Conditional,
  Modal,
  Stack,
  Typography,
} from 'gantri-components';
import { useRecoilState } from 'recoil';
import {
  orderStatusesMap,
  shipmentStatuses,
  stockStatuses,
} from '../../../../constants/options';
import styles from '../modals/components/styles.module.scss';
import { CancelItemsModalPropsDef } from './cancel-items-modal.types';
import { transactionsApi } from '../../../../api';
import { useNotification } from '../../../../hooks/useNotification';
import { ShortProductSummary } from '../../../../components/common/short-product-summary';
import { Label } from '../../../../components/label';
import { cancelItemsModalSteps } from './cancel-items-modal.constants';
import { cancelItemsModalAtoms } from './cancel-items-modal.atoms';

export const CancelItemsModal = (props: CancelItemsModalPropsDef) => {
  const { currentOrderData, onClose, setStatus } = props;

  const { id: orderId, shipments } = currentOrderData;

  const { notify, onInterceptRequest } = useNotification();

  const [shipStocksSelection, setShipStocksSelection] = useState({});
  const [confirmDisabled, setConfirmDisabled] = useState(true);

  const [step, setStep] = useRecoilState(cancelItemsModalAtoms.step);

  useEffect(() => {
    let hasSelectedStock = false;

    Object.keys(shipStocksSelection).forEach((key) => {
      if (shipStocksSelection[key]) {
        hasSelectedStock = true;
      }
    });

    setConfirmDisabled(!hasSelectedStock);
  }, [shipStocksSelection]);

  const submitConfirmation = async () => {
    const selectedStockInfoIds = Object.keys(shipStocksSelection)
      .filter((stockInfoId) => {
        return shipStocksSelection[stockInfoId] === true;
      })
      .map((i) => {
        return parseInt(i, 10);
      });
    const refunds = [];

    shipments.forEach((shipment) => {
      return shipment.stocks.forEach((stock) => {
        if (selectedStockInfoIds.includes(stock.stockInfoId)) {
          refunds.push({
            id: stock.stockInfoId,
          });
        }
      });
    });

    await onInterceptRequest(async () => {
      try {
        const { data } = await transactionsApi.cancelOrRefundItems({
          refundItems: refunds,
          status: orderStatusesMap.cancelled,
          transactionId: orderId,
        });

        if (data.newTransaction && data.newTransaction.shipments.length) {
          notify(data.notice);
        }

        if (data.success) {
          setStatus(data.oldTransaction.status);
        }

        onClose();
      } catch (error) {
        const errorData = error.response.data;

        notify(errorData.notice || errorData.error);
      }
    });
  };

  const totalShipments = shipments && shipments.length;

  const Shipments = () => {
    return (
      <>
        {shipments.map(
          (
            { id: shipmentId, status: shipmentStatus, stocks: shipStocks },
            i,
          ) => {
            const shipmentIdx = i + 1;
            const shipmentStocks = shipStocks.map(
              ({ product, status: stockStatus, stockInfoId }) => {
                const stockSelected = shipStocksSelection[stockInfoId];

                const handleStockClick = () => {
                  setShipStocksSelection({
                    ...shipStocksSelection,
                    [`${stockInfoId}`]: !stockSelected,
                  });
                };

                const stockStyles = cx(
                  styles['stock-wrapper'],
                  'row-flex-start',
                  {
                    [`${styles['border-default']}`]: !stockSelected,
                    [`${styles['border-selected']}`]: stockSelected,
                  },
                );

                const hideStockFromModal =
                  stockStatus === stockStatuses.cancelled ||
                  stockStatus === stockStatuses.refunded;

                return hideStockFromModal ? null : (
                  <div
                    key={stockInfoId}
                    className={stockStyles}
                    role="button"
                    tabIndex={0}
                    onClick={handleStockClick}
                  >
                    <Box padding="x">
                      <ShortProductSummary fetchSku={product.sku} />
                    </Box>
                  </div>
                );
              },
            );

            return (
              <Conditional
                key={shipmentId}
                condition={[
                  shipmentStatuses.waiting,
                  shipmentStatuses.inProgress,
                  shipmentStatuses.readyToShip,
                ].some((status) => {
                  return status === shipmentStatus;
                })}
              >
                <div>
                  {!shipmentStocks ? null : (
                    <div className={styles['shipment-section']}>
                      <Label
                        text={`Shipment ${shipmentIdx}/${totalShipments}`}
                      />
                      {shipmentStocks}
                    </div>
                  )}
                </div>
              </Conditional>
            );
          },
        )}
      </>
    );
  };

  useEffect(() => {
    const shipmentStockInfoIdsMap = {};

    if (shipments.length) {
      shipments.forEach(({ stocks: shipStocks }) => {
        shipStocks.forEach(({ stockInfoId }) => {
          shipmentStockInfoIdsMap[stockInfoId] = false;
        });
      });
    }

    setShipStocksSelection(shipmentStockInfoIdsMap);
  }, []);

  return (
    <Modal
      footer={
        step === cancelItemsModalSteps.selectItems ? (
          <>
            <Button
              size="large"
              text="Cancel"
              variant="secondary"
              onClick={async () => {
                onClose();
              }}
            />
            <Button
              disabled={confirmDisabled}
              size="large"
              text="Confirm"
              onClick={async () => {
                setStep(cancelItemsModalSteps.confirmCancel);
              }}
            />
          </>
        ) : (
          <>
            <Button
              size="large"
              text="Cancel"
              variant="secondary"
              onClick={async () => {
                setStep(cancelItemsModalSteps.selectItems);
              }}
            />
            <Button
              size="large"
              text="Confirm"
              onClick={async () => {
                await submitConfirmation();
              }}
            />
          </>
        )
      }
      header="Cancel Items"
      maxWidth={{ lg: '40rem', md: '100%' }}
      onClose={onClose}
    >
      {step === cancelItemsModalSteps.selectItems ? (
        <Shipments />
      ) : (
        <Stack alignContent="center" minHeight="10rem">
          <Typography
            align="center"
            text="Are you sure you want to cancel these items?"
          />
        </Stack>
      )}
    </Modal>
  );
};
