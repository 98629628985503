import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useNotification } from '../../../../hooks/useNotification';
import { useInvalidateGetPrintTransferQueuesCache } from '../get-print-transfer-queues';
import { createPrintTransferQueue } from './create-print-transfer-queue';
import {
  CreatePrintTransferQueueArgs,
  CreatePrintTransferQueueResponse,
} from './create-print-transfer-queue.types';

export const useCreatePrintTransferQueue = (options?: {
  showLoading?: boolean;
}) => {
  const { invalidateGetPrintTransferQueuesCache } =
    useInvalidateGetPrintTransferQueuesCache();

  const { notify, notifyAxiosError } = useNotification();

  const { isLoading, onMutate } = useQueryMutation<
    CreatePrintTransferQueueArgs,
    CreatePrintTransferQueueResponse
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to add new print transfer queue.',
        });
      },
      onSuccess: async (data) => {
        notify(data.notice);
        await invalidateGetPrintTransferQueuesCache();
      },
    },
    mutationFn: createPrintTransferQueue,
    showLoading: options?.showLoading,
  });

  return { isLoading, onCreatePrintTransferQueue: onMutate };
};
