import { useFormikContext } from 'formik';
import { useState } from 'react';
import { sortBy } from 'lodash';
import { StockJobsAssembleInventoryFormData } from '../../../../stock-jobs-assemble-inventory.schema';
import { useFetchGlobalVariants } from '../../../../../../../../../../../../api/global-variants/routes';
import { AssembleInventoryVariantsSectionProps } from '../../assemble-inventory-variants-section.types';
import { GlobalVariant } from '../../../../../../../../../../../../api/global-variants/routes/fetch-global-variants/fetch-global-variants.types';
import { StockJobVariantInventory } from '../../../../../../../../../../../../api/products/products.types';

export const useInventoryVariantActions = (
  props: AssembleInventoryVariantsSectionProps,
) => {
  const { isEditing } = props;

  const [showAddVariantForm, setShowAddVariantForm] = useState<boolean>(false);
  const [selectedGlobalVariantId, setSelectedGlobalVariantId] =
    useState<number>();

  const { setFieldValue, values } =
    useFormikContext<StockJobsAssembleInventoryFormData>();

  const { data, isLoading } = useFetchGlobalVariants({
    enabled: isEditing,
  });

  const globalVariants = data?.globalVariants || [];

  const variantItems = globalVariants.filter((variant) => {
    const isAlreadySelected = values.variantIds.some((id) => {
      return id === variant.id;
    });

    return !isAlreadySelected;
  });

  const getVariantData = (id: number) => {
    return globalVariants.find((globalVariant) => {
      return globalVariant.id === id;
    });
  };

  const handleShowAddVariantForm = () => {
    setShowAddVariantForm(true);
  };

  const handleOnSelect = (item: GlobalVariant) => {
    setSelectedGlobalVariantId(item?.id);
  };

  const handleCancel = () => {
    setSelectedGlobalVariantId(undefined);
    setShowAddVariantForm(false);
  };

  const handleAdd = async () => {
    const updatedVariantIds = sortBy([
      ...values.variantIds,
      selectedGlobalVariantId,
    ]);

    const selectedGlobalVariant = getVariantData(selectedGlobalVariantId);

    await setFieldValue('variantIds', updatedVariantIds);

    const newStockJobVariantInventories: StockJobVariantInventory[] =
      selectedGlobalVariant.options.reduce(
        (accumulator, { code: variantCode, inventories }) => {
          const updatedInventories = inventories.map(
            ({ amount, id: inventoryId, name, status, unit }) => {
              const stockJobVariantInventory: StockJobVariantInventory = {
                amount,
                globalVariantId: selectedGlobalVariantId,
                inventoryId,
                name,
                status,
                unit,
                variantCode,
              };

              return stockJobVariantInventory;
            },
          );

          return [...accumulator, ...updatedInventories];
        },
        [],
      );

    const updatedAssembleVariantInventories: StockJobVariantInventory[] = [
      ...values.assembleVariantInventories,
      ...newStockJobVariantInventories,
    ];

    await setFieldValue(
      'assembleVariantInventories',
      updatedAssembleVariantInventories,
    );
    setShowAddVariantForm(false);
  };

  return {
    getVariantData,
    handleAdd,
    handleCancel,
    handleOnSelect,
    handleShowAddVariantForm,
    isLoading,
    selectedGlobalVariantId,
    showAddVariantForm,
    variantItems,
  };
};
