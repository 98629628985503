import {
  getModalDetailsPanelBackgroundColor,
  Stack,
  Typography,
} from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useTheme } from 'styled-components';
import { PrintTransferQueueSetPart } from '../../../../../../../../../api/queue/print-transfer/print-transfer.types';
import { StyledAnchor } from '../../../../../../../../../components/common/styled-anchor';
import { ColorSwatchAndLabel } from '../../../../../../../../../components/common/color-swatch-and-label';
import routePaths from '../../../../../../../../../config/route-paths';
import { StyledPartsTable } from './details-panel-parts-section.styles';
import { useGetActiveJobSet } from '../../../../hooks/use-get-active-job-set';
import { getIsClickableIfValue } from '../../../../../../../../../helpers/get-is-clickable-if-value';

export const DetailsPanelPartsSection = () => {
  const set = useGetActiveJobSet();
  const parts = set?.parts || [];

  const theme = useTheme();

  const detailsPanelBackgroundColor = getModalDetailsPanelBackgroundColor({
    isFullScreen: true,
    theme,
  });

  return (
    <Stack gap="x" paddingLeft="2x">
      <Typography text={`Parts (${set?.parts?.length || 0})`} variant="h4" />
      <StyledPartsTable
        backgroundColor={detailsPanelBackgroundColor}
        columns={partsColumns}
        data={parts}
      />
    </Stack>
  );
};

const partsColumns: ColumnDef<PrintTransferQueueSetPart>[] = [
  {
    accessorKey: 'id',
    cell: ({
      getValue,
    }: CellContext<
      PrintTransferQueueSetPart,
      PrintTransferQueueSetPart['id']
    >) => {
      const partId = getValue();

      return (
        <StyledAnchor
          href={`${routePaths.parts}/${partId}`}
          target="_blank"
          text={`#${partId}`}
        />
      );
    },
    header: 'ID',
    maxSize: 105,
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
  },
  {
    accessorKey: 'color',
    cell: ({
      getValue,
    }: CellContext<
      PrintTransferQueueSetPart,
      PrintTransferQueueSetPart['color']
    >) => {
      const colorCode = getValue();

      return <ColorSwatchAndLabel colorCode={colorCode} />;
    },
    header: 'Color',
  },
];
