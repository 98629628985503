import { FC, memo, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useFormikContext } from 'formik';
import { Cell, Grid } from 'gantri-components';
import { productStatuses } from '../../../../constants/options';
import { useGetAllProducts } from '../../../../api/products/routes';
import { selectProductFormAtoms } from '../../../../components/common/select-product-form/select-product-form.atoms';
import { SelectProductFormContent } from '../../../../components/common/select-product-form/components/select-product-form-content';
import { selectProductFormInitialValues } from '../../../../components/common/select-product-form/select-product-form.schema';
import { NewOrderSelectedSkusInfo } from './selected-skus';
import { isValidProductForm } from './products-section.adapter';

export const NewOrderProductsSection: FC = memo((props) => {
  const { setFormikState, values } = useFormikContext<any>();
  const [allProducts, setAllProducts] = useRecoilState(
    selectProductFormAtoms.allProducts,
  );

  const setFilteredProducts = useSetRecoilState(
    selectProductFormAtoms.filteredProducts,
  );

  useGetAllProducts({
    onSuccess: async ({ products }) => {
      setAllProducts(products);
      setFilteredProducts(
        products.filter(({ status }) => {
          return [productStatuses.active, productStatuses.ready].some(
            (allowedStatus) => {
              return allowedStatus === status;
            },
          );
        }),
      );
    },
    showLoading: true,
  });

  const calculateStocks = () => {
    const { productId, quantity, selectedSku, stocks: addedStocks } = values;
    const stocks = [...addedStocks];

    const index = stocks.findIndex((stock) => {
      return stock.product.sku === selectedSku;
    });

    if (index !== -1) {
      stocks[index] = {
        ...stocks[index],
        quantity: stocks[index].quantity + 1,
      };

      return stocks;
    }

    const selectedProduct = allProducts.find(({ id }) => {
      return id === productId;
    });

    const newStockOrderTemplate = {
      amount: {
        subtotal: 0,
        tax: 0,
      },
      idx: `${Date.now()}`,
      product: {
        amount: {
          subtotal: 0,
          tax: 0,
        },
        detail: selectedProduct,
        id: productId,
        manufacturerPrice: selectedProduct.manufacturerPrice,
        name: selectedProduct.name,
        price: selectedProduct.price,
        sku: selectedSku,
      },
      quantity,
    };

    stocks.push(newStockOrderTemplate);

    return stocks;
  };

  const onAddProduct = () => {
    const stocks = calculateStocks();

    setFormikState((prevState) => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          ...selectProductFormInitialValues,
          stocks,
        },
      };
    });
  };

  const isValidForm = useMemo(() => {
    return isValidProductForm(values);
  }, [values]);

  return (
    <Grid columns={12} gap="2x">
      <Cell width={12}>
        <SelectProductFormContent
          autoSubmitWhenValid={false}
          buttonType="button"
          hasExternalValidation
          isValidForm={isValidForm}
          showActiveProductsToggle={false}
          showQuantity={false}
          submitButtonText="Add"
          onActionHandler={onAddProduct}
        />
      </Cell>

      <Cell width={8}>
        <NewOrderSelectedSkusInfo
          orderType={values.orderType}
          stocks={values.stocks}
        />
      </Cell>
    </Grid>
  );
});
