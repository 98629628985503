import { Button, Modal, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';
import { productAtoms } from '../../../../../../../../product.atoms';
import { SkuConfigColorDropdown } from '../../../../../../../product-sku-dropdown/components/sku-config-color-dropdown';
import { SkuConfigVariantDropdowns } from '../../../../../../../product-sku-dropdown/components/sku-config-variant-dropdowns';
import { DownloadCutSheetModalPropsDef } from './download-cut-sheet-modal.types';

export const DownloadCutSheetModal = (props: DownloadCutSheetModalPropsDef) => {
  const { handleDownloadCutSheet, onClose } = props;

  const product = useRecoilValue(productAtoms.product);
  const selectedSku = useRecoilValue(productAtoms.selectedSku);

  const [sku, setSku] = useState<string>(selectedSku);

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            size="large"
            text="Download"
            type="submit"
            onClick={async () => {
              await handleDownloadCutSheet(sku);
            }}
          />
        </>
      }
      header="Cut Sheet Download - Select SKU"
      maxWidth={{ lg: '40rem', md: '100%' }}
      onClose={onClose}
    >
      <Typography color="t2" text={`SKU: ${sku}`} />

      <SkuConfigColorDropdown
        highlightMarketplaceColors
        product={product}
        setSku={setSku}
        sku={sku}
      />

      <SkuConfigVariantDropdowns product={product} setSku={setSku} sku={sku} />
    </Modal>
  );
};
