import { addOrEditPartModalType } from '../../add-or-edit-part-modal.constants';
import { AddOrEditPartModalType } from '../../add-or-edit-part-modal.types';

export const getHeaderText = (modalType: AddOrEditPartModalType) => {
  switch (modalType) {
    case addOrEditPartModalType.add:
      return 'Add Part';

    case addOrEditPartModalType.edit:
      return 'Edit Part';

    default:
      return '';
  }
};
