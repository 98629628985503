import React, { useEffect, useRef } from 'react';
import { Button, Conditional, Flex, Icon, Tooltip } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import {
  MachinesBulkActionsData,
  MachinesBulkActionsProps,
} from './machines-bulk-actions.types';
import { markStickyElements } from '../../../../helpers/mark-sticky-elements';
import { SimpleErrorBoundary } from '../../../../components/simple-error-boundary';
import { useMoreMenu } from '../../../../hooks/use-more-menu';
import { machinesAtoms } from '../../machines.atoms';
import { useGetMachinesBulkActions } from './hooks/use-get-machines-bulk-actions';

export const MachinesBulkActions = (props: MachinesBulkActionsProps) => {
  const { onSuccess } = props;

  const { buttonActions, moreMenuActions } = useGetMachinesBulkActions({
    maxVisibleActions: 3,
    onSuccess,
  });

  const machinesSelected = useRecoilValue(machinesAtoms.machinesSelected);

  const containerRef = useRef<HTMLDivElement>();

  const { MoreMenu } = useMoreMenu({
    data: { machinesSelected },
    options: moreMenuActions,
  });

  useEffect(() => {
    const elementsToMark = [containerRef.current];
    const elementsToObserve = [
      containerRef.current?.querySelector('div:first-child'),
    ];

    const { cleanupUseEffect } = markStickyElements({
      elementsToMark,
      elementsToObserve,
      stickyTo: 'left',
    });

    return cleanupUseEffect;
  }, [containerRef.current]);

  return (
    <SimpleErrorBoundary>
      <Flex alignItems="center" gap="x">
        {buttonActions.map((item) => {
          const {
            disabled: disabledLogic,
            disabledTooltipProps,
            icon,
            name,
            onOptionClick,
          } = item;

          const data: MachinesBulkActionsData = { machinesSelected };
          const disabled =
            typeof disabledLogic === 'function'
              ? disabledLogic(data)
              : disabledLogic;
          const text = typeof name === 'function' ? name(data) : name;

          return (
            <Tooltip
              key={text}
              description={disabled && disabledTooltipProps?.description}
              position="bottom-start"
            >
              <Button
                disabled={disabled}
                icon={
                  icon && <Icon color={disabled ? 't3' : 'link'} name={icon} />
                }
                text={text}
                variant="secondary"
                onClick={() => {
                  return onOptionClick(data);
                }}
              />
            </Tooltip>
          );
        })}

        <Conditional condition={!!moreMenuActions.length}>
          <MoreMenu />
        </Conditional>
      </Flex>
    </SimpleErrorBoundary>
  );
};
