import { SelectedFileDetails } from 'gantri-components';

export const shippingRadioOptions = [
  {
    text: 'Do Not Ship',
    value: 'doNotShip',
  },
  {
    text: 'UPS Next Day Air 1-day',
    value: 'fastest',
  },

  {
    text: 'UPS Ground 3-5 days',
    value: 'cheapest',
  },
  {
    text: 'Other',
    value: 'other',
  },
];

export const defaultFileInfo: SelectedFileDetails = {
  fileBlob: undefined,
  fileExtension: undefined,
  fileName: undefined,
  fileSize: undefined,
  metadata: undefined,
};
