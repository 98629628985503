import React, { useMemo } from 'react';
import { Button, FormikInput, Grid, TextArea } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { Form, Formik } from 'formik';
import { initialState } from './new-order.constants';
import { userAtoms } from '../../global-atoms/users';
import { PageHeading } from '../../components/layout/page-heading';
import MetaData from '../../components/meta-data';
import { selectProductFormAtoms } from '../../components/common/select-product-form/select-product-form.atoms';
import {
  NewOrderAddressesSection,
  NewOrderDetailsSection,
  NewOrderProductsSection,
  NewOrderShippingSection,
  NewOrderSummarySection,
} from './components';
import { NewOrderSection } from './components/section';
import { newOrderSchema } from './new-order.schema';
import { useNewOrder } from './use-new-order';
import { FormState } from './new-order.type';

export const NewOrder = () => {
  const user = useRecoilValue(userAtoms.user);
  const userAddresses = useRecoilValue(userAtoms.addresses);
  const allProducts = useRecoilValue(selectProductFormAtoms.allProducts);
  const { isProcessing, onCreate } = useNewOrder();

  const formInitialState = useMemo(() => {
    return {
      ...initialState,
      addresses: userAddresses ?? [],
    };
  }, [user, allProducts]);

  return (
    <>
      <MetaData title="New Internal Order" />
      <PageHeading title="New Internal Order" />

      <Formik
        initialValues={formInitialState}
        validateOnChange
        validationSchema={newOrderSchema}
        onSubmit={(values) => {
          return onCreate(values as FormState);
        }}
      >
        {({ isValid }) => {
          return (
            <Form>
              <Grid
                columnGap="unset"
                paddingBottom="6x"
                paddingTop="3x"
                rowGap="6x"
              >
                <NewOrderSection title="Details">
                  <NewOrderDetailsSection />
                </NewOrderSection>

                <NewOrderSection title="Products">
                  <NewOrderProductsSection />
                </NewOrderSection>

                <NewOrderSection title="Shipping">
                  <NewOrderShippingSection />
                </NewOrderSection>

                <NewOrderAddressesSection />

                <NewOrderSection title="Notes">
                  <FormikInput
                    Field={<TextArea minRows={4} placeholder="Write a note." />}
                    name="notes"
                    required
                  />
                </NewOrderSection>

                <NewOrderSection title="Summary">
                  <NewOrderSummarySection />
                </NewOrderSection>

                <Button
                  disabled={!isValid}
                  processing={isProcessing}
                  size="extraLarge"
                  text="Create Order"
                  type="submit"
                  width="20rem"
                />
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
