import { ProductColorCode } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { productAtoms } from '../../../../../../../../product.atoms';
import { MarketplaceColor } from '../../marketplace-skus-colors.types';
import { DefaultSkuCellHeader } from './components/default-sku-cell-header';
import { ColorNameAndSwatchCell } from './components/color-name-and-swatch-cell';
import { DefaultSkuCell } from './components/default-sku-cell';
import { UseGetMarketplaceSkuColorsColumnsProps } from './use-get-marketplace-sku-colors-columns.types';
import { ActionsCell } from './components/actions-cell';

export const useGetMarketplaceSkuColorsColumns = (
  props: UseGetMarketplaceSkuColorsColumnsProps,
) => {
  const { isAddingNewColor } = props;

  const product = useRecoilValue(productAtoms.product);

  const columns = useMemo(() => {
    const columns: ColumnDef<MarketplaceColor>[] = [
      {
        accessorKey: 'code',
        cell: (
          props: CellContext<MarketplaceColor, MarketplaceColor['code']>,
        ) => {
          const { getValue, row } = props;
          const { isNew } = row.original;

          return (
            <ColorNameAndSwatchCell
              colorCode={getValue() as ProductColorCode}
              isNew={isNew}
            />
          );
        },
        header: 'Colors',
        maxSize: 166,
        size: 166,
      },
      {
        accessorKey: 'defaultSku',
        cell: (
          props: CellContext<MarketplaceColor, MarketplaceColor['defaultSku']>,
        ) => {
          const { getValue, row } = props;
          const { code, isNew } = row.original;

          return (
            <DefaultSkuCell
              code={code as ProductColorCode}
              defaultSku={getValue()}
              isAddingNewColor={isAddingNewColor}
              isNew={isNew}
            />
          );
        },
        header: DefaultSkuCellHeader,
        size: product?.isPainted ? 330 : 450,
      },
      product?.isPainted && {
        accessorKey: 'defaultSku',
        cell: (
          props: CellContext<MarketplaceColor, MarketplaceColor['defaultSku']>,
        ) => {
          const { getValue, row } = props;
          const { code, isNew } = row.original;

          return (
            <ActionsCell
              code={code as ProductColorCode}
              defaultSku={getValue()}
              isAddingNewColor={isAddingNewColor}
              isNew={isNew}
            />
          );
        },
        header: '',
        id: 'actions',
        size: 0,
      },
    ].filter(Boolean);

    return columns;
  }, [product.isPainted]);

  return columns;
};
