import { Cell, FileUploader, HandleUploadsComplete } from 'gantri-components';
import { useCloudinaryFileUploader } from '../../../../hooks/use-cloudinary-file-uploader';
import { useSortableItem } from '../../../../hooks/use-sortable-item';
import { videoMaxFileSizeMB } from '../../sortable-photo-list.constants';
import { RenderUploadedFileProps } from './render-uploaded-file.types';

export const RenderUploadedFile = (props: RenderUploadedFileProps) => {
  const {
    draggable,
    expectedExtensions,
    fileName,
    getFolderStructureArgs,
    id,
    index,
    isVideoFile,
    newFileName,
    onClick,
    onFileRecovered,
    onImageRemove,
    onImageReplace,
    replaceable,
    selectedPhoto,
    uploaderWidthValue,
  } = props;

  const { SortableDragHandleWrapper, SortableItemWrapper } = useSortableItem({
    id,
    useDragHandle: true,
  });

  const onFileDelete = async () => {
    await onImageRemove(index);
  };

  const handleReplaceComplete: HandleUploadsComplete = async ([
    { fileName },
  ]) => {
    await onImageReplace(fileName, index);
  };

  const handleFileRecovered = async (updatedFileName: string) => {
    await onFileRecovered(updatedFileName, index);
  };

  const { fileUploaderProps } = useCloudinaryFileUploader({
    ...getFolderStructureArgs,
    fileName,
    /** This is the rendered file component, so you can only replace, never upload from nothing */
    handleUploadsComplete: handleReplaceComplete,
    isVideoFile,
    onFileDelete,
    onFileRecovered: handleFileRecovered,
    transformFileName: ({ applyUuid, removeWhitespace }) => {
      return applyUuid(removeWhitespace(newFileName));
    },
  });

  const isActive = fileName === selectedPhoto;

  return (
    <SortableItemWrapper title={fileName}>
      <Cell key={fileName}>
        <FileUploader
          {...fileUploaderProps}
          expectedExtensions={expectedExtensions}
          isActive={isActive}
          isDraggable={draggable}
          isReplaceable={replaceable}
          maxFileSizeMB={
            isVideoFile ? videoMaxFileSizeMB : fileUploaderProps.maxFileSizeMB
          }
          SortableDragHandleWrapper={SortableDragHandleWrapper}
          thumbnailSize={uploaderWidthValue}
          variant="thumbnail"
          onClick={onClick}
        />
      </Cell>
    </SortableItemWrapper>
  );
};
