import {
  Cell,
  Conditional,
  Grid,
  Icon,
  Tooltip,
  Typography,
} from 'gantri-components';
import { IconProps } from 'gantri-components/dist/components/icon/icon.types';
import { useRecoilValue } from 'recoil';
import { ProductJobsPartNameAndToggleProps } from './product-jobs-part-name-and-toggle.types';
import { PartDiagram } from './components/part-diagram';
import { useIsEditingPartRow } from '../../../use-is-editing-part-row';
import { productJobsTabAtoms } from '../../../../../../product-jobs.atoms';

export const ProductJobsPartNameAndToggle = (
  props: ProductJobsPartNameAndToggleProps,
) => {
  const { getIsSelected, index, name, part, toggleSelected } = props;

  const invalidPartIds = useRecoilValue(productJobsTabAtoms.invalidPartIds);

  const isJobBlocksInvalid = invalidPartIds[part.id];

  const isEditingPartRow = useIsEditingPartRow(part.id);
  const isSelected = getIsSelected();

  const disableCollapse = isEditingPartRow && isSelected;

  const chevronIcon: IconProps['name'] = isSelected
    ? 'arrows:arrow_chevron_up_24'
    : 'arrows:arrow_chevron_down_24';

  const handleToggle = disableCollapse
    ? undefined
    : () => {
        toggleSelected();
      };

  return (
    <Grid alignItems="center" columns="4rem max-content 1fr" gap="2x">
      <Cell>
        <Tooltip
          description={
            disableCollapse ? 'You must save your changes first.' : undefined
          }
          position="top"
        >
          <Cell padding="x">
            <Icon
              color={disableCollapse ? 't3' : 't1'}
              cursor={disableCollapse ? 'not-allowed' : 'pointer'}
              name={chevronIcon}
              size="2.4rem"
              onClick={handleToggle}
            />
          </Cell>
        </Tooltip>
      </Cell>

      <PartDiagram part={part} />

      <Typography
        icon={
          <Conditional condition={isJobBlocksInvalid}>
            <Tooltip
              maxWidth="22.7rem"
              position="bottom-start"
              title="Part is missing necessary job blocks details."
            >
              <Icon
                color="warning"
                cursor="pointer"
                name="alert:warning_triangle"
                top=".2rem"
              />
            </Tooltip>
          </Conditional>
        }
        iconPosition="right"
        text={`${index + 1}. ${name}`}
        variant="h5"
      />
    </Grid>
  );
};
