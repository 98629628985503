import { useFormikContext } from 'formik';
import { Button } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { AddStocksFormData } from '../../../add-stocks-modal.types';
import { addStocksModalAtoms } from '../../../add-stocks-modal.atoms';
import { addStocksModalSteps } from '../../../add-stocks-modal.constants';
import { SetQuantityFooterProps } from './set-quantity-footer.types';

export const SetQuantityFooter = (props: SetQuantityFooterProps) => {
  const { onClose } = props;

  const setStep = useSetRecoilState(addStocksModalAtoms.step);

  const { isValid } = useFormikContext<AddStocksFormData>();

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={onClose}
      />
      <Button
        disabled={!isValid}
        size="large"
        text="Continue"
        onClick={() => {
          setStep(addStocksModalSteps.confirmQty);
        }}
      />
    </>
  );
};
