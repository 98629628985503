import { atom } from 'recoil';
import { PrintTransferQueueModalStep } from './print-transfer-queue-modal.types';
import { printPrepareQueueModalSteps } from './print-transfer-queue-modal.constants';
import { PrintTransferQueue } from '../../../../../api/queue/print-transfer/print-transfer.types';

const atomKeyPrefix = 'PRINT_PREPARE_QUEUE_MODAL';

export const printPrepareQueueModalAtoms = {
  activeSetIndex: atom<number>({
    default: 0,
    key: `${atomKeyPrefix}-active-set-index`,
  }),
  deleteQueueOnClose: atom<boolean>({
    default: true,
    key: `${atomKeyPrefix}-delete-queue-on-close`,
  }),
  isPacketPrepared: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-packet-prepared`,
  }),
  queueData: atom<PrintTransferQueue>({
    default: {
      id: null,
      sets: [],
      size: 0,
    },
    key: `${atomKeyPrefix}-queue-data`,
  }),
  step: atom<PrintTransferQueueModalStep>({
    default: printPrepareQueueModalSteps.jobSetStart,
    key: `${atomKeyPrefix}-step`,
  }),
};
