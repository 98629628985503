import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { SetUserTypeArgs, SetUserTypeResponse } from './set-user-type.types';

export const setUserType = ({ userId, ...body }: SetUserTypeArgs) => {
  return axios
    .put<SetUserTypeResponse>(`${baseApiUrl}/set-user-type/${userId}`, body)
    .then(({ data }) => {
      return data;
    });
};
