/** `endDate` defaults to now. */
export const getDaysSince = (
  startDate: string | number | Date,
  endDate?: string | number | Date,
) => {
  if (startDate) {
    const startDateTime = new Date(startDate).getTime();
    const endDateTime = new Date(endDate || Date.now()).getTime();

    return Math.round((endDateTime - startDateTime) / (1000 * 60 * 60 * 24));
  }

  return undefined;
};
