import { FC, useMemo } from 'react';
import {
  Button,
  Conditional,
  Stack,
  Tooltip,
  Typography,
  useModal,
} from 'gantri-components';
import { useParams } from 'react-router-dom';
import { OrderTimeline } from '../../components/order-timeline';
import { orderStatusesMap, transactionTypesMap } from '../../constants/options';
import OrderSummary from '../../components/summaries/order-summary';
import {
  StyledLeftSection,
  StyledOrderActionWrapper,
  StyledOrderPageWrapper,
  StyledRightSection,
} from './order.styles';
import {
  PaddedStyledPageSubsection,
  StyledPageSubsection,
} from '../../components/layout/page-layout-styles';
import Protected from '../../components/common/protected/protected';
import { Items, OptionActionsType } from './order.type';
import MetaData from '../../components/meta-data';
import { PageHeading } from '../../components/layout/page-heading';
import {
  Address,
  Notes,
  OrderActionMenu,
  OrderGiftCard,
  OrderPaymentInfo,
  OrderPaymentSummary,
  OrderShipment,
  RestockModal,
} from './components';
import { useRouter } from '../../hooks';
import routePaths from '../../config/route-paths';
import { transactionsApi } from '../../api';
import { useNotification } from '../../hooks/useNotification';
import { ChangeItemsModal } from './components/change-items-modal';
import { CancelItemsModal } from './components/cancel-items-modal';
import {
  useFetchOrder,
  useInvalidateFetchOrderCache,
  useInvalidateFetchOrderTimelineCache,
} from '../../api/transactions/routes';

export const Order: FC = () => {
  const { notify, notifyAxiosError } = useNotification();
  const { navigate } = useRouter();
  const { id } = useParams<{ id: string }>();
  const orderId = Number(id);

  const pageTitle = orderId ? `Transaction #${orderId}` : 'Orders';

  const { invalidateFetchOrderCache } = useInvalidateFetchOrderCache();

  const { invalidateFetchOrderTimelineCache } =
    useInvalidateFetchOrderTimelineCache();

  const { data } = useFetchOrder({
    enabled: !!orderId,
    fetchArgs: {
      id: orderId,
    },
    onSuccess: async () => {
      await invalidateFetchOrderTimelineCache();
    },
    showLoading: true,
  });

  const currentOrderData = data?.order;

  const { status, type: orderType } = currentOrderData || {};

  const showReturnButton =
    [
      transactionTypesMap.refund,
      transactionTypesMap.thirdPartyRefund,
      transactionTypesMap.tradeRefund,
      transactionTypesMap.wholesaleRefund,
    ].some((type) => {
      return type === orderType;
    }) &&
    ![orderStatusesMap.refunded, orderStatusesMap.cancelled].some(
      (cancelledOrRefunded) => {
        return cancelledOrRefunded === status;
      },
    );

  const [showChangeItemsModal, hideChangeItemsModal] = useModal(() => {
    return (
      <ChangeItemsModal
        order={currentOrderData}
        onClose={hideChangeItemsModal}
      />
    );
  }, [currentOrderData]);

  const [showCancelItemsModal, hideCancelItemsModal] = useModal(() => {
    return (
      <CancelItemsModal
        currentOrderData={currentOrderData}
        setStatus={onUpdateOverallStatus}
        onClose={hideCancelItemsModal}
      />
    );
  }, [currentOrderData]);

  const optionActions: OptionActionsType = {
    cancelItems: showCancelItemsModal,
    changeItems: showChangeItemsModal,
    createRefund: () => {
      return navigate(`${routePaths.orders}/r/${orderId}/refund`);
    },
    createReplacement: () => {
      return navigate(`${routePaths.orders}/r/${orderId}/replacement`);
    },
    createReturn: () => {
      return navigate(`${routePaths.orders}/r/${orderId}/return`);
    },
    reassignStock: () => {
      return navigate(`${routePaths.orders}/r/${orderId}/reassign-stock`);
    },
  };

  const [showRestockModal, hideRestockModal] = useModal(() => {
    const onRestockModalConfirm = async (items: Items[]) => {
      try {
        const { data } = await transactionsApi.restockItems(orderId, items);

        notify(data.notice);

        await invalidateFetchOrderCache();

        hideRestockModal();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to restock items.',
        });
      }
    };

    return (
      <RestockModal
        stocks={currentOrderData?.stocks}
        onClose={hideRestockModal}
        onConfirm={onRestockModalConfirm}
      />
    );
  }, [currentOrderData?.stocks, orderId]);

  const onUpdateOverallStatus = async (newOverallStatus: string) => {
    if (newOverallStatus !== currentOrderData?.status) {
      await invalidateFetchOrderCache();
    }
  };

  const { amount, gift, giftCards, payment, shipments, type } =
    currentOrderData || {};

  const hasInvoice = useMemo(() => {
    return ['Trade', 'Third Party', 'Wholesale'].includes(type);
  }, [type]);

  const disableCompleteReturn = useMemo(() => {
    return shipments?.some(({ rateCost, trackingNumber }) => {
      return !trackingNumber || !rateCost;
    });
  }, [shipments]);

  return currentOrderData ? (
    <>
      <MetaData title={pageTitle} />
      <StyledOrderPageWrapper>
        <StyledLeftSection>
          <PageHeading title={`${orderType} #${orderId}`}>
            <StyledOrderActionWrapper>
              <Protected allowedFor={['Admin']}>
                <Conditional condition={showReturnButton}>
                  <Tooltip
                    description={
                      disableCompleteReturn &&
                      'The order must have shipping details before the refund can be completed.'
                    }
                    position="bottom"
                  >
                    <Button
                      disabled={disableCompleteReturn}
                      text="Complete Return"
                      onClick={showRestockModal}
                    />
                  </Tooltip>
                </Conditional>
              </Protected>
              <OrderActionMenu
                optionActions={optionActions}
                order={currentOrderData}
              />
            </StyledOrderActionWrapper>
          </PageHeading>

          <Stack gap="8x" marginTop="1.6rem">
            <OrderSummary currentOrderData={currentOrderData} />

            <Stack gap="8x" width="100%">
              <Conditional
                condition={!!shipments}
                Fallback={
                  <Typography
                    color="t1"
                    text="There are no shipments associated with this order."
                  />
                }
              >
                {shipments?.map((shipment) => {
                  return (
                    <OrderShipment
                      key={shipment.id}
                      {...shipment}
                      currentOrderData={currentOrderData}
                    />
                  );
                })}
              </Conditional>

              {giftCards?.map((giftCard) => {
                return <OrderGiftCard key={giftCard.id} giftCard={giftCard} />;
              })}
            </Stack>
          </Stack>
        </StyledLeftSection>
        <StyledRightSection>
          <Conditional
            condition={!!currentOrderData.address}
            Fallback={
              <>
                <Typography marginBottom="1rem" text="Shipping" variant="h4" />
                <Typography
                  color="t1"
                  marginBottom="8x"
                  text="There is no shipping address info for this order."
                />
              </>
            }
          >
            <PaddedStyledPageSubsection data-address-section>
              <Address currentOrderData={currentOrderData} />
            </PaddedStyledPageSubsection>
          </Conditional>

          <Conditional condition={payment && !payment.thirdParty}>
            <PaddedStyledPageSubsection>
              <OrderPaymentInfo
                currentOrderData={currentOrderData}
                payment={payment}
              />
            </PaddedStyledPageSubsection>
          </Conditional>

          <PaddedStyledPageSubsection>
            <OrderPaymentSummary amount={amount} gift={gift} />

            <Conditional condition={hasInvoice}>
              <Typography marginTop="3x" text="Invoice #" variant="h6" />
              <Typography text={currentOrderData.invoice ?? 'N/A'} />
            </Conditional>
          </PaddedStyledPageSubsection>

          <StyledPageSubsection>
            <Notes currentOrderData={currentOrderData} />
          </StyledPageSubsection>
        </StyledRightSection>

        <OrderTimeline
          orderId={orderId}
          transactionEmailLog={currentOrderData.transactionEmailLog}
        />
      </StyledOrderPageWrapper>
    </>
  ) : null;
};
