import axios from 'axios';
import { environment } from '../../../../environment';
import { dynamicFolder } from '../../cloudinary.constants';
import { getUploadSignature } from '../get-upload-signature';
import {
  CloudinaryUploadResponse,
  UploadFileArgs,
  UploadFileResponse,
} from './upload-file.types';

export const uploadFile = async (
  args: UploadFileArgs,
): Promise<UploadFileResponse> => {
  const {
    file,
    folder,
    publicId,
    resourceType = 'auto',
    uploadPreset = dynamicFolder,
  } = args;

  const {
    data: { apiKey, uploadSignature, uploadSignatureTimestamp },
  } = await getUploadSignature({
    folder,
    publicId,
    uploadPreset,
  });

  // https://stackoverflow.com/a/70764237
  const axiosInstance = axios.create();

  const data = new FormData();

  data.append('file', file);
  data.append('public_id', publicId);
  data.append('upload_preset', uploadPreset);
  data.append('folder', folder);
  data.append('api_key', apiKey);
  data.append('signature', uploadSignature);
  data.append('timestamp', String(uploadSignatureTimestamp));

  const cloudinaryResponse = await axiosInstance.post<CloudinaryUploadResponse>(
    `https://api.cloudinary.com/v1_1/${environment.CLOUDINARY_ENVIRONMENT}/${resourceType}/upload`,
    data,
  );

  const nonFileNamePattern = /.+\//;
  const fileUrl = cloudinaryResponse?.data?.secure_url;
  const fileName = fileUrl?.replace(nonFileNamePattern, '');

  return { fileName, fileUrl };
};
