import styled from 'styled-components';
import { media } from 'gantri-components';
import { StyledInlineTypography } from '../layout/page-layout-styles';

export const StyledTimelineEmailContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 120px;
  min-width: 250px;

  ${media.lessThan('sm')`
     margin-bottom: 0;
     width: 100%;
  `};
`;

export const StyledEmailIcon = styled.div`
  margin-right: 10px;
  top: 4px;
`;

export const StyledLinkInlineTypography = styled(StyledInlineTypography)`
  cursor: pointer;
`;
