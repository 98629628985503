import styled, { css } from 'styled-components';

export const StyledStockWithPriority = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 5px;
  align-items: center;
`;

export const StyledAttentionsList = styled.div`
  display: grid;
  row-gap: 0.8rem;
  padding: 0.8rem;
  max-width: 20rem;
`;

export const StyledVLMTooltip = styled.div`
  padding: 1.2rem;
  max-width: 23rem;
  ${({ theme }) => {
    return css`
      color: ${theme.colors.typography.t2};
    `;
  }}
`;
