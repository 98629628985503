import {
  Dropdown,
  Flex,
  FormikInput,
  Grid,
  Typography,
} from 'gantri-components';
import { useField } from 'formik';
import { StyledInventoryPurchaseFieldContainer } from '../../new-inventory-purchase.styles';
import UnitInput from '../../../../components/common/inputs/unit-input';
import {
  currenciesAsOptions,
  currencyOptions,
} from '../../../../constants/inventory-purchases';
import { useFormikHelper } from '../../../../hooks';

export const PurchaseDetailsSection = () => {
  const { resetInputsValue } = useFormikHelper();
  const [, , helperUnits] = useField<number>('units');
  const [, , helperTotalCost] = useField<number>('totalCost');
  const [selectedInventory] = useField<{ unit: string }>('inventory');
  const [selectedCurrency] = useField<keyof typeof currencyOptions>('currency');
  const currencyOption = currencyOptions[selectedCurrency.value];

  const onCurrencyChange = (currency: { value: string }) => {
    const { country: countries } = currencyOptions[currency.value];

    if (countries.length === 1) {
      resetInputsValue('country', countries[0], true);
    }
  };

  return (
    <Flex alignItems="flex-start" direction="column">
      <Typography
        marginTop="1rem"
        text="Purchase Details"
        textStyle="bold"
        variant="h4"
      />

      <Grid columns={{ lg: 4, sm: 2 }} width="100%">
        <StyledInventoryPurchaseFieldContainer>
          <Typography text="Units" textStyle="bold" />
          <FormikInput
            Field={
              <UnitInput
                minValue={1}
                placeholder="Units"
                unit={selectedInventory?.value?.unit}
                onValueChange={(value: string) => {
                  return helperUnits.setValue(
                    !!value ? parseInt(value) : 0,
                    false,
                  );
                }}
              />
            }
            name="units"
          />
        </StyledInventoryPurchaseFieldContainer>
        <StyledInventoryPurchaseFieldContainer>
          <Typography
            text={`Total Cost ${currencyOption.symbol}`}
            textStyle="bold"
          />
          <FormikInput
            Field={
              <UnitInput
                minValue={1}
                placeholder="Total Cost"
                unit={currencyOption.symbol}
                onBlurTextChange={(value: string) => {
                  return helperTotalCost.setValue(
                    !!value ? parseFloat(value) : 0,
                    false,
                  );
                }}
              />
            }
            name="totalCost"
          />
        </StyledInventoryPurchaseFieldContainer>
        <StyledInventoryPurchaseFieldContainer>
          <Typography text="Currency" textStyle="bold" />
          <FormikInput
            Field={
              <Dropdown
                idProperty="value"
                items={currenciesAsOptions}
                labelProperty="label"
                onSelect={onCurrencyChange}
              />
            }
            name="currency"
          />
        </StyledInventoryPurchaseFieldContainer>
        <StyledInventoryPurchaseFieldContainer>
          <Typography text="Invoice #" textStyle="bold" />
          <FormikInput name="invoice" placeholder="Invoice #" />
        </StyledInventoryPurchaseFieldContainer>
      </Grid>
    </Flex>
  );
};
