import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  UpdateSkuAssetsArgs,
  UpdateSkuAssetsResponse,
} from './update-sku-assets.types';
import { updateSkuAssets } from './update-sku-assets';

export const useUpdateSkuAssets = <TransformedData = UpdateSkuAssetsResponse>(
  props?: GenericMutateQueryProps<
    UpdateSkuAssetsArgs,
    UpdateSkuAssetsResponse,
    TransformedData
  >,
) => {
  const { onMutate: onUpdateSkuAssets, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to update sku assets.',
    mutationFn: updateSkuAssets,
    ...props,
  });

  return { ...rest, onUpdateSkuAssets };
};
