import styled, { css } from 'styled-components';

export const StyledLargeTextWrapper = styled.div`
  ${({ theme }) => {
    return css`
      * {
        font-family: ${theme.fonts.p1.family};
        font-size: ${theme.fonts.p1.size};
        line-height: ${theme.fonts.p1.lineHeight};
        font-weight: ${theme.fonts.p1.fontWeight};
      }
    `;
  }}
`;
