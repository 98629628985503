import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Stack } from 'gantri-components';
import { PageHeading } from '../../components/layout/page-heading';
import MetaData from '../../components/meta-data';
import {
  StockAttention,
  StockDetails,
  StockHeader,
  StockInventories,
  StockJobs,
  StockMoreButton,
  StockNotes,
  StockParts,
  StockTimeline,
} from './components';
import {
  useGetStock,
  useInvalidateGetStockCache,
} from '../../api/stocks/routes';
import Protected from '../../components/common/protected/protected';

export const Stock = () => {
  const { id: stockId } = useParams<{ id: string }>();
  const { data } = useGetStock({
    fetchArgs: { stockId: +stockId },
    showLoading: true,
  });
  const { stock } = data ?? {};

  const { invalidateGetStockCache } = useInvalidateGetStockCache();

  const onRefreshStock = async () => {
    await invalidateGetStockCache({ stockId: +stockId });
  };

  return (
    !!stock && (
      <Box paddingBottom="s4">
        <MetaData title={`Stock #${stockId}`} />
        <PageHeading title={`Stock #${stockId}`}>
          <Protected allowedFor={['Admin']}>
            <StockMoreButton stock={stock} />
          </Protected>
        </PageHeading>

        <Stack gap={{ lg: '5.6rem', md: '4rem' }}>
          <StockHeader refreshStock={onRefreshStock} stock={stock} />

          <Grid columns={{ lg: 2, md: 1 }} rowGap="4rem">
            <StockDetails refreshStock={onRefreshStock} stock={stock} />
            <StockNotes stock={stock} />
          </Grid>

          <StockAttention stock={stock} />
          <StockParts stock={stock} />
          <StockJobs refreshStock={onRefreshStock} stock={stock} />
          <StockInventories inventories={stock?.inventories ?? []} />
          <StockTimeline timelines={stock?.timelines || []} />
        </Stack>
      </Box>
    )
  );
};
