import { JobBlockInstruction } from '../../../../../../../../../../../../api/products/products.types';
import { PutInstructionsInOrderProps } from './put-instructions-in-order.types';

export const putInstructionsInOrder = (
  props: PutInstructionsInOrderProps,
  options?: { stripLabels?: boolean },
): JobBlockInstruction[] => {
  const { instructions, instructionsJobMap } = props;

  if (!instructionsJobMap?.length) {
    return instructions;
  }

  const sortedInstructions = instructionsJobMap
    .map(({ label }) => {
      const matchingInstruction = instructions.find((instruction) => {
        return instruction.label === label;
      });

      if (matchingInstruction) {
        if (options?.stripLabels) {
          const { label: _label, ...instructionData } = matchingInstruction;

          return instructionData;
        }

        return matchingInstruction;
      }

      return undefined;
    })
    .filter(Boolean);

  return sortedInstructions;
};
