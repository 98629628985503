import {
  Conditional,
  FileUploader,
  HandleUploadsComplete,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { productAtoms } from '../../../../../../../../product.atoms';
import { useCloudinaryFileUploader } from '../../../../../../../../../../hooks/use-cloudinary-file-uploader';
import { Divider } from '../../../../../../../../../../components/divider';
import { Product } from '../../../../../../../../../../api/products/products.types';
import { useFetchProductData } from '../../../../../../../../hooks/use-fetch-product-data';
import { UploadedFileItemProps } from './uploaded-file-item.types';
import { useDownloadWithSmallToast } from '../../../../../../../../../../hooks/use-download-with-small-toast';
import { downloadToastText } from '../../../../../../../../../../hooks/use-download-with-small-toast/use-download-with-small-toast.constants';

export const UploadedFileItem = (props: UploadedFileItemProps) => {
  const {
    expectedExtensions,
    fileName,
    fileNames = [],
    index,
    isRawFile,
    maxFiles = Infinity,
    type,
  } = props;

  const product = useRecoilValue(productAtoms.product);
  const { updateProduct } = useFetchProductData();

  const handleUploadsComplete: HandleUploadsComplete = async ([
    { fileName },
  ]) => {
    const updatedProduct: Product = {
      ...product,
      downloads: {
        ...product.downloads,
        [type]: [...product.downloads[type], fileName],
      },
    };

    await updateProduct(updatedProduct);
  };

  const onFileRecovered = async (updatedFileName) => {
    const updatedProduct: Product = {
      ...product,
      downloads: {
        ...product.downloads,
        [type]: [...product.downloads[type], updatedFileName],
      },
    };

    await updateProduct(updatedProduct);
  };

  const onFileDelete = async () => {
    const updatedProduct: Product = {
      ...product,
      downloads: {
        ...product.downloads,
        [type]: product.downloads[type].filter((_fileName, fileIndex) => {
          return index !== fileIndex;
        }),
      },
    };

    await updateProduct(updatedProduct);
  };

  const { fileUploaderProps } = useCloudinaryFileUploader<'products'>({
    directory: 'products',
    fileName,
    fileType: 'downloads',
    handleUploadsComplete,
    identifiers: {
      productId: product.id,
    },
    isRawFile,
    onFileDelete,
    onFileRecovered,
  });

  const { DownloadStatusToast, downloadSingleFile, processing } =
    useDownloadWithSmallToast({
      ...downloadToastText.file,
    });

  const handleDownload = async () => {
    if (!processing) {
      await downloadSingleFile({ fileName, url: fileUploaderProps.fileUrl });
    }
  };

  return (
    <>
      <FileUploader
        {...fileUploaderProps}
        expectedExtensions={expectedExtensions}
        purifyFileName
        variant="link"
        onClick={handleDownload}
      >
        <DownloadStatusToast position="top-start" />
      </FileUploader>

      <Conditional condition={fileNames.length < maxFiles}>
        <Divider borderColor="t1" />
      </Conditional>
    </>
  );
};
