import { Button } from 'gantri-components';
import { jobsApi } from '../../../../../../../../api';
import {
  jobStatuses,
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../constants/options';
import { useNotification } from '../../../../../../../../hooks/useNotification';
import { fullScreenModalFooterButtonSize } from '../../../../modals.constants';
import { CompletePrintTransferFooterProps } from './complete-print-transfer-footer.types';

export const CompletePrintTransferFooter = (
  props: CompletePrintTransferFooterProps,
) => {
  const { job, onClose, onJobUpdated } = props;

  const { notify, notifyAxiosError, processing, setProcessing } =
    useNotification();

  const handleCompleteJob = async () => {
    try {
      setProcessing(true);

      if (job.status === jobStatuses.ready) {
        await jobsApi.startJob({ jobId: job.id });
      }

      await jobsApi.completeJob({ jobId: job.id });

      notify(
        `${
          jobSteps.print
        } ${jobTypeOptions.transfer.toLowerCase()} job completed successfully.`,
      );

      await onJobUpdated();

      onClose();
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: `Unable to complete ${jobSteps.print.toLowerCase()} ${jobTypeOptions.transfer.toLowerCase()} job.`,
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Button
      processing={processing}
      size={fullScreenModalFooterButtonSize}
      text="Complete job"
      onClick={handleCompleteJob}
    />
  );
};
