import {
  Button,
  Cell,
  Dropdown,
  ErrorMessage,
  FormikInput,
} from 'gantri-components';
import { useMemo } from 'react';
import { MappedJobBlockInstruction } from '../../../../../../../../../../../../api/products/products.types';
import { InstructionJobSelectProps } from './instruction-job-select.types';

export const InstructionJobSelect = (props: InstructionJobSelectProps) => {
  const {
    index,
    instruction,
    instructions,
    instructionsJobMap,
    remove,
    replace,
    schemaId,
  } = props;

  const selectJobDropdownItems = useMemo(() => {
    return instructionsJobMap.map((jobMap) => {
      const disabled = instructions.some((instruction) => {
        return (
          instruction.step === jobMap.step &&
          instruction.type === jobMap.type &&
          instruction.isBrightColor === jobMap.isBrightColor
        );
      });

      return { ...jobMap, disabled };
    });
  }, [instructions]);

  const instructionSchemaId = `${schemaId}[${index}].label`;

  const showJobSelectionErrorMessage =
    !instruction.data.text &&
    instruction.data.files.some(({ url }) => {
      return !url;
    });

  const onJobTypeSelect = ({
    isBrightColor,
    label,
    step,
    type,
  }: MappedJobBlockInstruction) => {
    replace(index, { ...instruction, isBrightColor, label, step, type });
  };

  const onRemove = () => {
    remove(index);
  };

  return (
    <>
      <Cell>
        <FormikInput
          Field={
            <Dropdown
              idProperty="label"
              items={selectJobDropdownItems}
              labelProperty="label"
              placeholder="Select job"
              required
              onSelect={onJobTypeSelect}
            />
          }
          fieldVariant="standard"
          name={instructionSchemaId}
          required
        />

        <ErrorMessage
          errorMessage={
            showJobSelectionErrorMessage
              ? 'Either text or file instructions are required'
              : ''
          }
          required={showJobSelectionErrorMessage}
        />
      </Cell>

      <Button text="Remove" variant="tertiary" onClick={onRemove} />
    </>
  );
};
