import { Button, Cell, Icon, useModal } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { productAtoms } from '../../../../../../../../product.atoms';
import { getDownloadCutSheetUrl } from '../../../../../../../../../../api/products/routes/get-download-cut-sheet-url';
import { useNotification } from '../../../../../../../../../../hooks/useNotification';
import { downloadToastText } from '../../../../../../../../../../hooks/use-download-with-small-toast';
import { DownloadCutSheetModal } from '../download-cut-sheet-modal';
import { useDownloadSingleFile } from '../../../../../../../../../../hooks/use-download/hooks/use-download-single-file';
import { useSmallToast } from '../../../../../../../../../../hooks/use-small-toast';
import { DownloadCutSheetButtonPropsDef } from './download-cut-sheet-button.types';

export const DownloadCutSheetButton = (
  props: DownloadCutSheetButtonPropsDef,
) => {
  const { disabled } = props;

  const skus = useRecoilValue(productAtoms.skus);

  const [showDownloadCutSheetModal, hideDownloadCutSheetModal] =
    useModal(() => {
      return (
        <DownloadCutSheetModal
          handleDownloadCutSheet={handleDownloadCutSheet}
          onClose={hideDownloadCutSheetModal}
        />
      );
    }, []);

  const {
    Toast: DownloadStatusToast,
    setToastStatus,
    setToastText,
  } = useSmallToast();

  const { downloadSingleFile } = useDownloadSingleFile({
    onComplete: () => {
      setToastText(downloadToastText.cutSheet.completedText);
      setToastStatus('success');
    },
    onError: () => {
      setToastText(downloadToastText.cutSheet.errorText);
      setToastStatus('error');
    },
    onStart: () => {},
  });

  const { notifyAxiosError } = useNotification();

  const handleDownloadCutSheet = async (sku: string) => {
    try {
      hideDownloadCutSheetModal();

      setToastText(downloadToastText.cutSheet.startedText);

      const { downloadUrl } = await getDownloadCutSheetUrl({ sku });

      await downloadSingleFile({ url: downloadUrl });
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to download file.',
      });
    }
  };

  const onDownloadClick = async () => {
    if (skus.length > 1) {
      showDownloadCutSheetModal();
    } else {
      await handleDownloadCutSheet(skus[0]);
    }
  };

  return (
    <Cell position="relative">
      <DownloadStatusToast position="top-start" />

      <Button
        disabled={disabled}
        icon={<Icon color="link" name="arrows:arrow_download" />}
        justifySelf="start"
        text="Download"
        variant="ghost"
        onClick={onDownloadClick}
      />
    </Cell>
  );
};
