import { Button } from 'gantri-components';
import { NotifyCustomerFooterProps } from './notify-customer-footer.types';

export const NotifyCustomerFooter = (props: NotifyCustomerFooterProps) => {
  const { handleCloseModal } = props;

  return (
    <Button
      size="large"
      text="Done"
      onClick={async () => {
        await handleCloseModal();
      }}
    />
  );
};
