import { Link } from 'react-router-dom';
import { Conditional, Flex, Typography } from 'gantri-components';
import routePaths from '../../../../../../config/route-paths';
import { NotApplicableText } from '../../../../../../components/common/not-applicable-text';
import { PartDetailsOrderProps } from './part-details-order.types';

export const PartDetailsOrder = (props: PartDetailsOrderProps) => {
  const { orderId, orderType } = props;

  return (
    <Conditional condition={!!orderId} Fallback={<NotApplicableText />}>
      <Flex gap="0.8rem">
        <Link target="_blank" to={`${routePaths.orders}/${orderId}`}>
          <Typography
            color="link"
            decoration="underline"
            text={`#${orderId}`}
          />
        </Link>
        <Typography color="t2" text={orderType} />
      </Flex>
    </Conditional>
  );
};
