import {
  SetGrowthMultiplierArgs,
  SetGrowthMultiplierResponse,
} from './set-growth-multiplier.types';
import { setGrowthMultiplier } from './set-growth-multiplier';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';

export const useSetGrowthMultiplier = <
  TransformedData = SetGrowthMultiplierResponse,
>(
  props: GenericMutateQueryProps<
    SetGrowthMultiplierArgs,
    SetGrowthMultiplierResponse,
    TransformedData
  >,
) => {
  const { onMutate: onSetGrowthMultiplier, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to set growth multiplier.',
    mutationFn: setGrowthMultiplier,
    ...props,
  });

  return { ...rest, onSetGrowthMultiplier };
};
