import { useRecoilValue } from 'recoil';
import { setMachinesOfflineModalSteps } from '../../set-machines-offline-modal.constants';
import { setMachinesOfflineModalAtoms } from '../../set-machines-offline-modal.atoms';
import { ConfirmSetMachinesOfflineFooter } from '../step-confirm-set-machines-offline/confirm-set-machines-offline-footer';
import { ReviewOfflineMachinesFooter } from '../step-review-offline-machines/review-offline-machines-footer';
import { SetMachinesOfflineModalFooterProps } from './set-machines-offline-modal-footer.types';

export const SetMachinesOfflineModalFooter = (
  props: SetMachinesOfflineModalFooterProps,
) => {
  const { handleCloseModal } = props;

  const step = useRecoilValue(setMachinesOfflineModalAtoms.step);

  switch (step) {
    case setMachinesOfflineModalSteps.confirmSetMachinesOffline:
      return (
        <ConfirmSetMachinesOfflineFooter handleCloseModal={handleCloseModal} />
      );

    case setMachinesOfflineModalSteps.reviewOfflinedMachines:
      return (
        <ReviewOfflineMachinesFooter handleCloseModal={handleCloseModal} />
      );

    default:
      return null;
  }
};
