import { useFormikContext } from 'formik';
import {
  Conditional,
  FormikInput,
  TextField,
  Typography,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { getGcodePrintQtyFileName } from '../../../../../../../../../../../../helpers/gcodes';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import { useIsEditingPartRowJobsDataForm } from '../../../../../../hooks/use-is-editing-part-row';
import { PartJobBlocksFormData } from '../../../../part-job-blocks.types';
import { PartJobBlocksPrintWeightProps } from './part-job-blocks-print-weight.types';

export const PartJobBlocksPrintWeight = (
  props: PartJobBlocksPrintWeightProps,
) => {
  const { index, partName, printBlock, replace, weight } = props;

  const isEditingPartRow = useIsEditingPartRowJobsDataForm();

  const { name: productName, version } = useRecoilValue(productAtoms.product);

  const { values } = useFormikContext<PartJobBlocksFormData>();
  const { printBlock: printBlocks } = values;

  const numPrints = printBlocks.length;

  const onTextChange = (weight: string) => {
    const weightValue: number = weight
      ? Number(weight)
      : ('' as unknown as number);

    const formattedGcodeFileName = printBlock.gcode?.url
      ? getGcodePrintQtyFileName({
          numPrints,
          partName,
          printName: printBlock.name,
          productName,
          productVersion: version.version,
          weight: weightValue,
        })
      : undefined;

    replace(index, {
      ...printBlock,
      gcode: formattedGcodeFileName
        ? {
            ...printBlock.gcode,
            fileName: formattedGcodeFileName,
          }
        : printBlock.gcode,
      weight: weightValue,
    });
  };

  return (
    <Conditional
      condition={isEditingPartRow}
      Fallback={<Typography text={weight > 0 ? `${weight}g` : '-'} />}
    >
      <FormikInput
        Field={
          <TextField
            minValue={1}
            rightIcon={<Typography color="t2" text="g" />}
            type="number"
            onTextChange={onTextChange}
          />
        }
        fieldVariant="standard"
        name={`printBlock[${index}].weight`}
        placeholder="Input weight"
        required
      />
    </Conditional>
  );
};
