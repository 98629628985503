import { Button } from 'gantri-components';
import { useHandleShipItems } from '../../../hooks';
import { StepShippingErrorFooterPropsDef } from './step-shipping-error-footer.types';

export const StepShippingErrorFooter = (
  props: StepShippingErrorFooterPropsDef,
) => {
  const { handleCloseModal } = props;

  const { handleShipItems, processing: processingHandleShip } =
    useHandleShipItems();

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        processing={processingHandleShip}
        size="large"
        text="Re-try"
        onClick={async () => {
          await handleShipItems();
        }}
      />
    </>
  );
};
