import { FC } from 'react';
import {
  Conditional,
  Flex,
  Icon,
  Stack,
  Tooltip,
  Typography,
} from 'gantri-components';
import { Link } from 'react-router-dom';
import { CellLastActionProps } from './cell-last-action.types';
import { jobTypeToIconTypeMap } from '../../../../../../constants/jobs';
import { formatDate, timeAgo } from '../../../../../../helpers/formatter';
import routePaths from '../../../../../../config/route-paths';

export const CellLastAction: FC<CellLastActionProps> = (props) => {
  const {
    getValue,
    row: { original },
  } = props;

  const value = getValue();
  const iconName = jobTypeToIconTypeMap[original?.lastActionData?.job?.type];

  return (
    <Conditional condition={!!value}>
      <Stack gap="0.6rem">
        <Conditional condition={!!original?.lastActionData?.job}>
          <Flex alignItems="center" gap="0.6rem">
            {iconName && <Icon color="t2" name={iconName} />}

            <Typography text={original?.lastActionData?.job?.step} />
            <Typography text={original?.lastActionData?.job?.type} />
          </Flex>
        </Conditional>

        <Flex alignItems="center">
          <Typography text={timeAgo(+value)} />

          <Tooltip
            Component={
              <Flex padding="0.8rem">
                <Typography
                  text={formatDate(value, "MM/dd/yyyy 'at' hh:mmaaaaa'm'")}
                />

                <Conditional condition={!!original.lastActionData?.user?.id}>
                  <Typography text=" by " whiteSpace="pre" />

                  <Link
                    target="_blank"
                    to={`${routePaths.users}/${original.lastActionData?.user?.id}`}
                  >
                    <Typography
                      color="link"
                      text={`${
                        original.lastActionData?.user?.firstName || ''
                      } ${original.lastActionData?.user?.lastName || ''}`}
                    />
                  </Link>
                </Conditional>
              </Flex>
            }
          >
            <Icon left="0.6rem" name="alert:i_circle" top="3px" />
          </Tooltip>
        </Flex>
      </Stack>
    </Conditional>
  );
};
