import * as yup from 'yup';
import { machineFieldSchemas } from '../../../machine/machine.schema';
import { AddOrEditMachineFormDef } from './add-or-edit-machine-modal.types';

export const machineInitialValues: AddOrEditMachineFormDef = {
  bay: undefined,
  id: undefined,
  ipAddress: undefined,
  location: 'San Leandro',
  macAddress: undefined,
  name: undefined,
  password: undefined,
  serialNumber: undefined,
  status: undefined,
  type: undefined,
};

export const addOrEditMachineSchema: yup.SchemaOf<AddOrEditMachineFormDef> = yup
  .object()
  .shape({
    bay: machineFieldSchemas.bay,
    id: yup.number(),
    ipAddress: machineFieldSchemas.ipAddress,
    location: machineFieldSchemas.location,
    macAddress: machineFieldSchemas.macAddress,
    name: machineFieldSchemas.name,
    password: machineFieldSchemas.password,
    serialNumber: machineFieldSchemas.serialNumber,
    status: machineFieldSchemas.status,
    type: machineFieldSchemas.type,
  });
