import { v4 as uuidv4 } from 'uuid';
import { Button, Conditional, Flex } from 'gantri-components';
import { CellContext } from '@tanstack/react-table';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MoreMenu } from '../../../../../../components/dropdowns';
import { NewStockItem } from '../../../../new-stocks.types';
import { newStocksAtoms } from '../../../../new-stocks.atoms';
import { useIsEditingRow } from '../../hooks/use-is-editing-row';
import { TableDataItem } from '../../use-get-new-stocks-table-details.types';
import { MoreMenuOption } from '../../../../../../components/dropdowns/more-menu/more-menu.types';
import { getTemplateEditDisabledReason } from '../../../../../../components/common/jobs-template-table/helpers/get-template-edit-disabled-reason';

export const NewStockMenuCell = (props: CellContext<TableDataItem, never>) => {
  const { row } = props;

  const [newStocks, setNewStocks] = useRecoilState(newStocksAtoms.newStocks);
  const [tableData, setTableData] = useRecoilState(newStocksAtoms.tableData);
  const cachedTableData = useRecoilValue(newStocksAtoms.cachedTableData);

  const { isEditingPage, isEditingRow, resetEditingRowId, setEditingRowId } =
    useIsEditingRow(row.original);

  const onEdit = ({ id }: TableDataItem) => {
    setEditingRowId(id);
  };

  const onDuplicate = ({ id }: TableDataItem) => {
    const indexToDuplicate = newStocks.findIndex((item) => {
      return item.id === id;
    });
    const rowToDuplicate = newStocks[indexToDuplicate];
    const updatedNewStocks = [...newStocks];

    updatedNewStocks.splice(indexToDuplicate + 1, 0, {
      ...rowToDuplicate,
      id: uuidv4(),
    });

    setNewStocks(updatedNewStocks);
  };

  const onDelete = ({ id }: TableDataItem) => {
    const filteredNewStocks = newStocks.filter((item) => {
      return item.id !== id;
    });

    setNewStocks(filteredNewStocks);
  };

  const handleSave = () => {
    const updatedNewStocks = tableData.map<NewStockItem>(
      ({
        color,
        id,
        product,
        quantity,
        selectedSku,
        size,
        variantCodes,
        variantPartialDetails,
      }) => {
        return {
          colorCode: color.code,
          id,
          productId: product.id,
          quantity,
          selectedSku,
          sizeCode: size.code,
          variantCodes,
          variantPartialDetails,
        };
      },
    );

    setNewStocks(updatedNewStocks);
    resetEditingRowId();
  };

  const handleCancel = () => {
    setTableData(cachedTableData);
    resetEditingRowId();
  };

  const disabledMessage = getTemplateEditDisabledReason({
    isEditing: isEditingPage,
  });

  const options: MoreMenuOption<TableDataItem>[] = [
    {
      disabledTooltipProps: { description: disabledMessage },
      enabled: !disabledMessage,
      name: 'Edit',
      onOptionClick: onEdit,
    },
    {
      disabledTooltipProps: { description: disabledMessage },
      enabled: !disabledMessage,
      name: 'Duplicate',
      onOptionClick: onDuplicate,
    },
    {
      disabledTooltipProps: { description: disabledMessage },
      enabled: !disabledMessage,
      name: 'Delete',
      onOptionClick: onDelete,
    },
  ];

  return (
    <Conditional
      condition={isEditingRow}
      Fallback={
        <Flex justifyContent="flex-end">
          <MoreMenu data={row.original} options={options} />
        </Flex>
      }
    >
      <Flex gap="x">
        <Button text="Save" variant="primary" onClick={handleSave} />
        <Button text="Cancel" variant="ghost" onClick={handleCancel} />
      </Flex>
    </Conditional>
  );
};
