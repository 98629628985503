import { RecoilState } from 'recoil';
import { MachineType } from '../../constants/machines';
import { getGenericAtomFamily } from '../../helpers/get-generic-atom-family';
import { AtomPageName } from '../../helpers/get-generic-atom-family/get-generic-atom-family.types';

export interface IdleMachineDetails {
  bay: string;
  id: number;
  name: string;
  type: MachineType;
}

const atomKeyPrefix = 'IDLE_MACHINES';
const pageName: AtomPageName = 'global';

export const idleMachinesAtoms = {
  'Bambu Opaque': getGenericAtomFamily<IdleMachineDetails[]>({
    defaultValue: [],
    key: `${atomKeyPrefix}-bambu-opaque`,
  })(pageName),
  'Bambu Translucent': getGenericAtomFamily<IdleMachineDetails[]>({
    defaultValue: [],
    key: `${atomKeyPrefix}-bambu-translucent`,
  })(pageName),
  'Creality High-Temp PLA': getGenericAtomFamily<IdleMachineDetails[]>({
    defaultValue: [],
    key: `${atomKeyPrefix}-creality-high-temp pla`,
  })(pageName),
  'Creality Translucent PLA': getGenericAtomFamily<IdleMachineDetails[]>({
    defaultValue: [],
    key: `${atomKeyPrefix}-creality-translucent-pla`,
  })(pageName),
  Tumbler: getGenericAtomFamily<IdleMachineDetails[]>({
    defaultValue: [],
    key: `${atomKeyPrefix}-tumbler`,
  })(pageName),
  'Ultimaker High-Temp PLA': getGenericAtomFamily<IdleMachineDetails[]>({
    defaultValue: [],
    key: `${atomKeyPrefix}-ultimaker-high-temp-pla`,
  })(pageName),
  'Ultimaker Translucent PLA': getGenericAtomFamily<IdleMachineDetails[]>({
    defaultValue: [],
    key: `${atomKeyPrefix}-ultimaker-translucent-pla`,
  })(pageName),
} satisfies Record<MachineType, RecoilState<IdleMachineDetails[]>>;
