import PropTypes from 'prop-types';
import {
  StyledSummaryWrapper,
  StyledSummaryMobileSpacer,
} from './summaries-styles';

const SummaryWrapper = ({ children }) => {
  return (
    <StyledSummaryWrapper>
      {children}
      <StyledSummaryMobileSpacer />
    </StyledSummaryWrapper>
  );
};

SummaryWrapper.propTypes = {
  bordered: PropTypes.bool,
  children: PropTypes.node,
  classnames: PropTypes.string,
  column: PropTypes.bool,
  fullWidth: PropTypes.bool,
  imageUrl: PropTypes.string,
};

export default SummaryWrapper;
