import { Conditional, ProductColorCode } from 'gantri-components';
import { CellContext } from '@tanstack/react-table';
import { useSetRecoilState } from 'recoil';
import { newStocksAtoms } from '../../../../new-stocks.atoms';
import { useIsEditingRow } from '../../hooks/use-is-editing-row';
import { TableDataItem } from '../../use-get-new-stocks-table-details.types';
import { noneOption } from '../../../../../../constants/options';
import { NotApplicableText } from '../../../../../../components/common/not-applicable-text';
import { ColorSwatchAndLabel } from '../../../../../../components/common/color-swatch-and-label';
import { useSelectProductFormOptions } from '../../../../../../components/common/select-product-form/components/select-product-form-content/hooks/use-select-product-form-options';
import { ColorSwatchDropdown } from '../../../../../../components/common/color-swatch-dropdown';
import { ColorOptionItem } from '../../../../../../components/common/select-product-form/components/select-product-form-content/hooks/use-select-product-form-options/use-select-product-form-options.types';

export const NewStockColorCell = (
  props: CellContext<TableDataItem, TableDataItem['color']>,
) => {
  const { getValue, row } = props;
  const { id, product } = row.original;
  const color = getValue();

  const setTableData = useSetRecoilState(newStocksAtoms.tableData);

  const { isEditingRow } = useIsEditingRow(row.original);

  const onSelect = (item: ColorOptionItem) => {
    setTableData((oldTableData) => {
      return oldTableData.map((data) => {
        const newData: TableDataItem =
          data.id === id
            ? {
                ...data,
                color: { code: item.value, name: item.label },
              }
            : data;

        return newData;
      });
    });
  };

  const { colorOptions, disableColor } = useSelectProductFormOptions({
    productId: product.id,
  });

  return (
    <Conditional
      condition={color.code !== noneOption.code}
      Fallback={<NotApplicableText />}
    >
      <Conditional
        condition={isEditingRow}
        Fallback={
          <ColorSwatchAndLabel colorCode={color.code as ProductColorCode} />
        }
      >
        <ColorSwatchDropdown
          disabled={disableColor}
          items={colorOptions}
          placeholder="Select Color"
          value={color.code}
          width="15rem"
          onSelect={onSelect}
        />
      </Conditional>
    </Conditional>
  );
};
