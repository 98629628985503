import { Typography, useConfirmationModal } from 'gantri-components';
import Cookies from 'universal-cookie';
import {
  ADMIN_ROLE_TYPE,
  AUTH_TOKEN,
  FIREBASE_TOKEN,
  STD_COOKIE_OPTIONS,
} from '../../../constants/environment';
import { environment } from '../../../environment';
import { usersApi } from '../../../api';
import { useNotification } from '../../../hooks/useNotification';

const cookies = new Cookies();

export const useLoginAs = (userId: number, userName: string) => {
  const { notifyAxiosError } = useNotification();

  const onConfirm = async () => {
    try {
      const { data } = await usersApi.masqueradeUser(userId);

      if (data && data.success) {
        cookies.set(AUTH_TOKEN, data.token, STD_COOKIE_OPTIONS);
        cookies.set(FIREBASE_TOKEN, data.firebaseToken, STD_COOKIE_OPTIONS);
        cookies.set(ADMIN_ROLE_TYPE, data.data.type, STD_COOKIE_OPTIONS);
        window.location.href = environment.SHOP_URL;
      }
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to masquerade as user.',
      });
    }
  };

  const [showModal, hideModal] = useConfirmationModal(
    {
      confirmButtonText: 'Confirm',
      confirmButtonVariant: 'primary',
      content: (
        <Typography
          style={{ textAlign: 'center' }}
          text={`Are you sure you want to login as ${userName}?`}
        />
      ),
      headerText: 'Login as a user',
      onClose: () => {
        hideModal();
      },
      onConfirm,
      width: { lg: '42rem', md: '100%' },
    },
    [userId, userName],
  );

  return showModal;
};
