import { useFormikContext } from 'formik';
import { updatePartReleaseRateModalSteps } from '../../update-part-release-rate-modal.constants';
import { UpdatePartReleaseRateModalSchemaDef } from '../../update-part-release-rate-modal.types';
import { PartReleaseRateModalConfirmFooter } from '../step-confirm/part-release-modal-confirm-footer';
import { PartReleaseRateModalUpdateFooter } from '../step-update/part-release-modal-update-footer';
import { UpdatePartReleaseRateModalFooterPropsDef } from './update-part-release-rate-modal-footer.types';

export const UpdatePartReleaseRateModalFooter = (
  props: UpdatePartReleaseRateModalFooterPropsDef,
) => {
  const { onClose } = props;

  const { values } = useFormikContext<UpdatePartReleaseRateModalSchemaDef>();

  switch (values.step) {
    case updatePartReleaseRateModalSteps.updateRate: {
      return <PartReleaseRateModalUpdateFooter onClose={onClose} />;
    }

    case updatePartReleaseRateModalSteps.confirmUpdate: {
      return <PartReleaseRateModalConfirmFooter />;
    }

    default:
      return null;
  }
};
