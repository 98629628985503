import { useRecoilValue } from 'recoil';
import { completePrintHarvestModalAtoms } from '../../complete-print-harvest-modal.atoms';
import { EvaluatePartContent } from '../step-evaluate-part/evaluate-part-content';
import { CompleteChecklistContent } from '../step-complete-checklist/complete-checklist-content';
import { JobFailedContent } from '../step-job-failed/job-failed-content';
import { MachineOfflineContent } from '../step-machine-offline/machine-offline-content';

export const CompletePrintHarvestContent = () => {
  const step = useRecoilValue(completePrintHarvestModalAtoms.step);

  switch (step) {
    case 'EVALUATE_PART':
      return <EvaluatePartContent />;

    case 'COMPLETE_CHECKLIST':
      return <CompleteChecklistContent />;

    case 'JOB_FAILED':
      return <JobFailedContent />;

    case 'MACHINE_OFFLINE':
      return <MachineOfflineContent />;

    default:
      return null;
  }
};
