import { FC, PropsWithChildren, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Tooltip } from 'gantri-components';
import { MenuItemProps } from './menu-item.types';
import { MenuTooltip } from './components/menu-tooltip';
import { MenuLink } from './components/menu-link';
import { uiAtoms } from '../../../../../global-atoms/ui';

export const MenuItem: FC<PropsWithChildren<MenuItemProps>> = (props) => {
  const { expandedMenuItems, isSubPath, onLinkClicked, route } = props;

  const [activeMenuItemTooltipId, setActiveMenuItemTooltipId] = useRecoilState(
    uiAtoms.activeMenuItemTooltipId,
  );
  const isSidebarOpen = useRecoilValue(uiAtoms.isSidebarOpen);

  const tooltipId = useMemo(uuidv4, []);
  const isActiveTooltip = activeMenuItemTooltipId === tooltipId;

  return isSidebarOpen ? (
    <MenuLink
      expandedMenuItems={expandedMenuItems}
      isSubPath={isSubPath}
      route={route}
      onLinkClicked={onLinkClicked}
    />
  ) : (
    <Tooltip
      closeDelay={1000}
      Component={<MenuTooltip route={route} onLinkClicked={onLinkClicked} />}
      open={
        // This key is provided to force a re-render which hides previously active tooltips when a new one is activated and ensure re-activation of tooltips if hovering last active tooltip again after closeDelay
        isActiveTooltip
      }
      overlayContainerStyles={{ justifyContent: 'center' }}
      position="right-start"
      triggerEvent="hover"
      onClose={() => {
        setActiveMenuItemTooltipId('');
      }}
      onOpen={() => {
        setActiveMenuItemTooltipId(tooltipId);
      }}
    >
      <MenuLink
        expandedMenuItems={expandedMenuItems}
        isSubPath={isSubPath}
        route={route}
        onLinkClicked={onLinkClicked}
      />
    </Tooltip>
  );
};
