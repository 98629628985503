import { Box } from 'gantri-components';
import styled, { css } from 'styled-components';

export const progressTransitionTimeMs = 500;

export const progressTransitionTimeSec = progressTransitionTimeMs / 1000;

export const StyledMinutesEarnedBox = styled(Box)<{
  $isActive: boolean;
  $isCompact: boolean;
}>`
  ${({ $isActive, $isCompact, theme }) => {
    return css`
      position: absolute;
      opacity: ${$isActive ? 1 : 0};
      transition: all ease-in-out ${progressTransitionTimeSec}s,
        opacity ease-in-out ${progressTransitionTimeSec}s;

      ${$isCompact
        ? css`
            left: ${$isActive ? '0' : '-200px'};
          `
        : css`
            top: calc(100% - 2px);
            right: ${$isActive ? '-16px' : '-200px'};
            background-color: ${theme.colors.surfaces.monochrome.overlayLevel1};
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border: 1px solid ${theme.colors.dividers.t1};
            border-right: none;
            box-shadow: ${theme.colors.shadow.low};
          `}
    `;
  }}
`;
