import * as yup from 'yup';
import { updatePartReleaseRateModalSteps } from './update-part-release-rate-modal.constants';
import { UpdatePartReleaseRateModalSchemaDef } from './update-part-release-rate-modal.types';

export const updatePartReleaseRateModalSchema: yup.SchemaOf<UpdatePartReleaseRateModalSchemaDef> =
  yup.object().shape({
    notes: yup.string().nullable(),
    partsPerDay: yup.number().nullable().required('Required'),
    processing: yup.boolean(),
    step: yup
      .mixed()
      .oneOf([
        updatePartReleaseRateModalSteps.updateRate,
        updatePartReleaseRateModalSteps.confirmUpdate,
      ])
      .required(),
  });

export const updatePartReleaseRateModalInitialValues: UpdatePartReleaseRateModalSchemaDef =
  {
    notes: null,
    partsPerDay: null,
    processing: false,
    step: updatePartReleaseRateModalSteps.updateRate,
  };
