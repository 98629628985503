import { Button } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { locationsApi } from '../../../../../../../../../api';
import { useNotification } from '../../../../../../../../../hooks/useNotification';
import { cancelOrUpdateStockVersionModalStepAtom } from '../../../cancel-or-update-stock-version-modal.atoms';
import { RequestVlmFooterProps } from './request-vlm-footer.types';

export const RequestVlmFooter = (props: RequestVlmFooterProps) => {
  const { stockLocation } = props;

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const setStep = useSetRecoilState(cancelOrUpdateStockVersionModalStepAtom);

  const onConfirm = async () => {
    try {
      showLoading();

      await locationsApi.emptyVlm(stockLocation.id);

      setStep('REMOVED_FROM_VLM');
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to empty VLM.' });
    } finally {
      hideLoading();
    }
  };

  return <Button size="large" text="Confirm" onClick={onConfirm} />;
};
