import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  CreatePartTemplateArgs,
  CreatePartTemplateResponse,
} from './create-part-template.types';
import { createPartTemplate } from './create-part-template';

export const useCreatePartTemplate = <
  TransformedData = CreatePartTemplateResponse,
>(
  props?: GenericMutateQueryProps<
    CreatePartTemplateArgs,
    CreatePartTemplateResponse,
    TransformedData
  >,
) => {
  const { onMutate: onCreatePartTemplate, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to create the part template.',
    mutationFn: createPartTemplate,
    ...props,
  });

  return { ...rest, onCreatePartTemplate };
};
