import { Button, Stack, Typography } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { shipmentActionsModalSteps } from '../../../shipment-actions-modal.constants';
import { shipmentActionsModalAtoms } from '../../../shipment-actions-modal.atoms';

export const StepShippingErrorContent = () => {
  const setStep = useSetRecoilState(shipmentActionsModalAtoms.step);

  return (
    <Stack gap="2x">
      <Typography
        align="center"
        color="alert"
        text="An error has occurred from Shippo."
      />
      <Button
        justifySelf="center"
        text="Ship Manually"
        variant="ghost"
        onClick={() => {
          setStep(shipmentActionsModalSteps.shipManually);
        }}
      />
    </Stack>
  );
};
