import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { getDownloadCutSheetUrl } from './get-download-cut-sheet-url';
import {
  GetDownloadCutSheetUrlArgsDef,
  GetDownloadCutSheetUrlResponseDef,
} from './get-download-cut-sheet-url.types';

const getDownloadCutSheetUrlQueryObj = getGenericQueryObj({
  groupName: 'get-download-cut-sheet-url',
  query: getDownloadCutSheetUrl,
});

export const useGetDownloadCutSheetUrl = <
  TransformedData = GetDownloadCutSheetUrlResponseDef,
>(
  props?: GenericFetchProps<
    GetDownloadCutSheetUrlArgsDef,
    GetDownloadCutSheetUrlResponseDef,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to get download cut sheet url.',
    queryObj: getDownloadCutSheetUrlQueryObj,
    ...props,
  });
};

export const useInvalidateGetDownloadCutSheetUrlCache = () => {
  const invalidateDownloadCutSheetCache = useGetInvalidateQueryCache(
    getDownloadCutSheetUrlQueryObj,
  );

  return { invalidateDownloadCutSheetCache };
};
