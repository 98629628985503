import React from 'react';
import { useRouter } from '../../hooks';

export function withRouter<ComponentProps>(
  Component: React.FunctionComponent<ComponentProps>,
) {
  function ComponentWithRouterProp(props: ComponentProps) {
    const router = useRouter();

    return <Component {...props} {...router} />;
  }

  return ComponentWithRouterProp;
}
