import { Button, Table, useModal } from 'gantri-components';
import { useState } from 'react';
import { Vendor } from '../../api/vendors/vendors.types';
import { PageWithTable } from '../../components/layout';
import { getRowProps, vendorSortFields } from './vendors.constants';
import { useGetVendorsColumns } from './hooks/use-get-vendors-columns';
import { VendorsFilters } from './components/vendors-filters';
import { NewVendorModal } from './components/new-vendor-modal';
import { useNotification } from '../../hooks/useNotification';
import { vendorsApi } from '../../api';
import {
  pageName,
  vendorsFiltersDefaults,
  vendorsPageAtoms,
} from './components/vendors-filters/vendors-filters.atoms';
import { VendorsSortingField } from './components/vendors-filters/vendors-filters.types';
import { useTableFilters } from '../../components/common/table/hooks/use-table-filters';
import { GetPaginatedVendorsArgs } from '../../api/vendors/routes/get-paginated-vendors/get-paginated-vendors.types';
import { FiltersFetchRequestArgs } from '../../components/common/table/hooks/use-table-filters/use-table-filters.types';

export const Vendors = () => {
  const [inventoryTypes, setInventoryTypes] = useState([]);
  const [vendorToEdit, setVendorToEdit] = useState<Vendor>();

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const [showVendorModal, hideVendorModal] = useModal(() => {
    return (
      <NewVendorModal
        vendor={vendorToEdit}
        onClose={() => {
          setVendorToEdit(undefined);
          hideVendorModal();
        }}
      />
    );
  }, [vendorToEdit]);

  const fetchVendors = async (
    args: FiltersFetchRequestArgs<GetPaginatedVendorsArgs, VendorsSortingField>,
  ) => {
    try {
      showLoading();

      const { data } = await vendorsApi.getPaginatedVendors(args);

      setRecords(data.vendors);
      setTotalCount(data.totalVendors);
      setInventoryTypes(data.allInventoryTypes);
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to fetch vendors.' });
    } finally {
      hideLoading();
    }
  };

  const {
    filtersProps,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<Vendor, GetPaginatedVendorsArgs, VendorsSortingField>({
    fetchRequest: fetchVendors,
    filtersContent: <VendorsFilters inventoryTypes={inventoryTypes} />,
    modalFiltersDetails: [
      {
        atom: vendorsPageAtoms.filters.hasDelayedShipping,
        defaultValue: vendorsFiltersDefaults.filters.hasDelayedShipping,
        payloadKey: 'hasDelayedShipping',
      },
      {
        atom: vendorsPageAtoms.filters.inventoryType,
        defaultValue: vendorsFiltersDefaults.filters.inventoryType,
        payloadKey: 'inventoryType',
      },
      {
        atom: vendorsPageAtoms.filters.isActive,
        defaultValue: vendorsFiltersDefaults.filters.isActive,
        payloadKey: 'isActive',
      },
    ],
    pageName,
    searchFilterDetails: {
      atom: vendorsPageAtoms.search,
    },
    sortByFilterDetails: {
      atom: vendorsPageAtoms.sortBy,
      defaultValue: vendorsFiltersDefaults.sortBy,
    },
    sortOptions: vendorSortFields,
  });

  const columns = useGetVendorsColumns();

  return (
    <PageWithTable pageTitle="Vendors">
      <PageWithTable.Header title="Vendors">
        <Button text="New Vendor" onClick={showVendorModal} />
      </PageWithTable.Header>
      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          getRowProps={getRowProps}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          sorting={sortProps}
          stickyLastColumn
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
