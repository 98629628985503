import { useEffect, useState } from 'react';
import { clone, debounce } from 'lodash';
import {
  Button,
  Cell,
  Flex,
  Grid,
  Stack,
  TextArea,
  Typography,
  TransformFileName,
} from 'gantri-components';
import { useHandleFetchDesign } from '../../hooks/fetch-design';
import { DesignHeading } from '../design-heading';
import {
  finalizationRecords,
  initialEngineering,
  optimizationRecords,
  qualityRecords,
} from './design-engineering.constants';
import Modal from '../../../../components/modals';
import { Messages } from '../messages';
import { Design } from '../../../../api/designs/routes/fetch-design/fetch-design.types';
import { designsApi } from '../../../../api';
import { useNotification } from '../../../../hooks/useNotification';
import { StyledActions, StyledReviewItem } from './design-engineering.styles';
import { useSpinner } from '../../../../hooks';
import {
  useInvalidateFetchDesignCache,
  useUpdateDesign,
} from '../../../../api/designs/routes';
import { PrototypingStep } from './components/prototyping-step';
import { FinishedPrototypesStep } from './components/finished-prototypes-step';
import { Engineering } from '../../../../api/designs/designs.types';
import { RecordUpdated } from './design-engineering.types';

export const DesignEngineering = () => {
  const { onInterceptRequest } = useSpinner();
  const { current, setCurrent } = useHandleFetchDesign();
  const [engineering, setEngineering] =
    useState<Design['engineering']>(initialEngineering);
  const [canProceed, setCanProceed] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [reason, setReason] = useState('');

  const { invalidateFetchDesignCache } = useInvalidateFetchDesignCache();

  const { notifyAxiosError } = useNotification();

  const { onUpdateDesign } = useUpdateDesign({
    onSuccess: invalidateFetchDesignCache,
    showLoading: true,
  });

  const updateDesign = async () => {
    await onUpdateDesign({
      engineering,
      id: current.id,
      step: 'Engineering',
    });
  };

  const recordUpdated: RecordUpdated = (groupKey, key, value) => {
    return async () => {
      const temporal = clone(engineering);

      engineering[groupKey][key] = value;
      setEngineering(temporal);

      await designsApi.saveDesign(current.id, {
        engineering: temporal,
        save: true,
        step: 'Engineering',
      });

      await invalidateFetchDesignCache();
    };
  };

  const toggleModalConfirm = () => {
    setConfirmVisible(!confirmVisible);
    setReason('');
  };

  const handleModalConfirm = async () => {
    await onInterceptRequest(async () => {
      try {
        await designsApi.failDesign(current.id, {
          failReason: reason,
          step: 'Engineering',
        });

        setConfirmVisible(false);
        await invalidateFetchDesignCache();
      } catch (error: unknown) {
        notifyAxiosError({ error, fallbackMessage: 'Unable to fail design.' });
      }
    });
  };

  const transformFileName: TransformFileName = ({
    fileName,
    removeWhitespace,
  }) => {
    return removeWhitespace(fileName);
  };

  const thumbnailSize = '40rem';

  const prototypeUploadPath = `designs/${current?.id}/prototyping`;

  useEffect(() => {
    if (current?.engineering) {
      setEngineering(current.engineering);
      setCanProceed(current.engineering.stepProgress === 100);
    }
  }, [current?.engineering]);

  return current?.model && current?.engineering ? (
    <Grid columns="8fr 4fr" gap="3rem" paddingTop="s1">
      <Grid columns={1} gap="12rem">
        <DesignHeading design={current} />

        <Cell>
          <Flex alignItems="center" justifyContent="space-between">
            <Typography
              text="Modeling optimizations"
              textStyle="bold"
              variant="h2"
            />
            <Typography text="Complete" variant="p2" />
          </Flex>

          <Stack gap="0" marginTop="3rem">
            {optimizationRecords.map((record) => {
              return (
                <StyledReviewItem key={record.key}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Typography text={record.label} variant="p2" />

                    <Grid columns={2} gap="1rem">
                      <Button
                        text="Yes"
                        variant={
                          engineering.modelingOptimization[record.key]
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={recordUpdated(
                          'modelingOptimization',
                          record.key as keyof Engineering['modelingOptimization'],
                          true,
                        )}
                      />
                      <Button
                        text="No"
                        variant={
                          engineering.modelingOptimization[record.key]
                            ? 'secondary'
                            : 'primaryAlert'
                        }
                        onClick={recordUpdated(
                          'modelingOptimization',
                          record.key as keyof Engineering['modelingOptimization'],
                          false,
                        )}
                      />
                    </Grid>
                  </Flex>
                </StyledReviewItem>
              );
            })}
          </Stack>

          <Modal type="confirm" visible={confirmVisible}>
            <Cell paddingTop="6rem">
              <Grid columns={1} gap="4rem">
                <Grid columns={1} gap="1rem">
                  <Typography
                    align="center"
                    text="Fail Engineering"
                    textStyle="bold"
                    variant="p1"
                  />

                  <Typography
                    align="center"
                    color="alert"
                    text="Warning: This will fail the entire design and cannot be undone."
                  />
                </Grid>

                <TextArea
                  minRows={5}
                  placeholder="Enter reason"
                  value={reason}
                  onTextChange={debounce(setReason, 300)}
                />

                <Grid columns={2} gap="1rem">
                  <Button
                    size="large"
                    text="Cancel"
                    variant="secondary"
                    onClick={toggleModalConfirm}
                  />
                  <Button
                    size="large"
                    text="Confirm"
                    variant="primaryAlert"
                    onClick={handleModalConfirm}
                  />
                </Grid>
              </Grid>
            </Cell>
          </Modal>
        </Cell>

        <PrototypingStep
          engineering={engineering}
          prototypeUploadPath={prototypeUploadPath}
          recordUpdated={recordUpdated}
          thumbnailSize={thumbnailSize}
          transformFileName={transformFileName}
        />

        <Cell>
          <Flex alignItems="center" justifyContent="space-between">
            <Typography text="Quality testing" textStyle="bold" variant="h2" />
            <Typography text="Complete" variant="p2" />
          </Flex>

          <Stack gap="0" marginTop="3rem">
            {qualityRecords.map((record) => {
              return (
                <StyledReviewItem key={record.key}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Typography text={record.label} variant="p2" />

                    <Grid columns={2} gap="1rem">
                      <Button
                        text="Yes"
                        variant={
                          engineering.qualityTesting[record.key]
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={recordUpdated(
                          'qualityTesting',
                          record.key as keyof Engineering['qualityTesting'],
                          true,
                        )}
                      />
                      <Button
                        text="No"
                        variant={
                          engineering.qualityTesting[record.key]
                            ? 'secondary'
                            : 'primaryAlert'
                        }
                        onClick={recordUpdated(
                          'qualityTesting',
                          record.key as keyof Engineering['qualityTesting'],
                          false,
                        )}
                      />
                    </Grid>
                  </Flex>
                </StyledReviewItem>
              );
            })}
          </Stack>
        </Cell>

        <Cell>
          <Flex alignItems="center" justifyContent="space-between">
            <Typography text="Finalization" textStyle="bold" variant="h2" />
            <Typography text="Complete" variant="p2" />
          </Flex>

          <Stack gap="0" marginTop="3rem">
            {finalizationRecords.map((record) => {
              return (
                <StyledReviewItem key={record.key}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Typography text={record.label} variant="p2" />

                    <Grid columns={2} gap="1rem">
                      <Button
                        text="Yes"
                        variant={
                          engineering.finalization[record.key]
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={recordUpdated(
                          'finalization',
                          record.key as keyof Engineering['finalization'],
                          true,
                        )}
                      />
                      <Button
                        text="No"
                        variant={
                          engineering.finalization[record.key]
                            ? 'secondary'
                            : 'primaryAlert'
                        }
                        onClick={recordUpdated(
                          'finalization',
                          record.key as keyof Engineering['finalization'],
                          false,
                        )}
                      />
                    </Grid>
                  </Flex>
                </StyledReviewItem>
              );
            })}
          </Stack>
        </Cell>

        <FinishedPrototypesStep
          design={current}
          engineering={engineering}
          prototypeUploadPath={prototypeUploadPath}
          recordUpdated={recordUpdated}
          thumbnailSize={thumbnailSize}
          transformFileName={transformFileName}
        />

        {current.engineering.status !== 'Completed' &&
          current.engineering.status !== 'Failed' && (
            <StyledActions>
              <Grid
                columns="repeat(auto-fit, minmax(0, 12rem))"
                gap="1rem"
                justifyContent="center"
              >
                <Button
                  text="Fail"
                  variant="primaryAlert"
                  onClick={toggleModalConfirm}
                />
                <Button
                  disabled={!canProceed}
                  text="Proceed"
                  onClick={updateDesign}
                />
              </Grid>
            </StyledActions>
          )}
        <Cell />
      </Grid>
      <Cell>
        <Messages
          current={current}
          designId={current.id}
          messages={current.messages || []}
          notes={current.notes || []}
          updateMessages={setCurrent}
        />
      </Cell>
    </Grid>
  ) : null;
};
