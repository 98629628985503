import { useRecoilValue } from 'recoil';
import { discardStockModalAtoms } from '../../discard-stock-modal.atoms';
import { discardStockModalSteps } from '../../discard-stock-modal.constants';
import { NotifyCustomerContent } from '../step-notify-customer/notify-customer-content';
import { ConfirmDiscardContent } from '../step-confirm-discard/confirm-discard-content';
import { DiscardStockModalContentProps } from './discard-stock-modal-content.types';

export const DiscardStockModalContent = (
  props: DiscardStockModalContentProps,
) => {
  const { orderId } = props;

  const step = useRecoilValue(discardStockModalAtoms.step);

  switch (step) {
    case discardStockModalSteps.confirmDiscard:
      return <ConfirmDiscardContent />;

    case discardStockModalSteps.notifyCustomer:
      return <NotifyCustomerContent orderId={orderId} />;

    default:
      return null;
  }
};
