import { Conditional } from 'gantri-components';
import { InventoryDataCell } from '../../../../../../components/common/custom-data-cells/inventory-data-cell';
import { CellJobInventoryRequestsProps } from './cell-job-inventory-requests.types';

export const CellJobInventoryRequests = (
  props: CellJobInventoryRequestsProps,
) => {
  const { getValue } = props;

  const inventoryRequests = getValue();

  return (
    <Conditional condition={!!inventoryRequests}>
      <InventoryDataCell inventoryRequests={inventoryRequests} />
    </Conditional>
  );
};
