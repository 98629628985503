import { v4 as uuidv4 } from 'uuid';
import {
  getBase64FromBlob,
  defaultImageExtensions,
  SelectedFileDetails,
} from 'gantri-components';
import { useProcessFileNameAndReturnMatchingSkus } from '../../../use-process-file-name-and-return-matching-skus';
import { SkuAssetUploadFileData } from '../../../../../../../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';

export const useFormatValidFile = () => {
  const processFileNameAndReturnMatchingSkus =
    useProcessFileNameAndReturnMatchingSkus();

  const formatValidFile = async (
    validFile: SelectedFileDetails,
  ): Promise<SkuAssetUploadFileData> => {
    const { fileBlob, fileExtension, fileName, metadata } = validFile;

    const { fileType, skus } = processFileNameAndReturnMatchingSkus({
      fileExtension,
      fileName,
    });

    const isImage = defaultImageExtensions.some((ext) => {
      return ext === fileExtension;
    });

    const fileUrl = isImage ? await getBase64FromBlob(fileBlob) : '';

    const bulkUploadFileData: SkuAssetUploadFileData = {
      fileBlob,
      fileName,
      fileType,
      fileUrl,
      id: uuidv4(),
      metadata,
      skus,
    };

    return bulkUploadFileData;
  };

  return formatValidFile;
};
