import styled, { css } from 'styled-components';
import { getButtonsColors } from 'gantri-components';

export const StyledDiscountButton = styled.div<{ $active?: boolean }>`
  ${({ $active, theme }) => {
    const colors = getButtonsColors('primary', theme);

    return css`
      height: 3.6rem;
      padding: 0.8rem 1.2rem;
      border: 1px solid ${$active ? colors.border : theme.colors.dividers.t1};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      background-color: ${$active
        ? colors.background
        : theme.colors.surfaces.monochrome.t1};

      * {
        color: ${$active
          ? theme.colors.typography.alt
          : theme.colors.typography.t2} !important;
      }
    `;
  }}
`;
