import { useFormikContext } from 'formik';
import { useRecoilValue } from 'recoil';
import { productJobsTabAtoms } from '../../../../product-jobs.atoms';
import { PartJobBlocksFormData } from '../../components/part-job-blocks/part-job-blocks.types';

export const useIsEditingPartRow = (partId: number) => {
  const isEditingPartId = useRecoilValue(productJobsTabAtoms.isEditingPartId);

  return isEditingPartId === partId;
};

export const useIsEditingPartRowJobsDataForm = () => {
  const {
    values: { partTemplateId },
  } = useFormikContext<PartJobBlocksFormData>();

  return useIsEditingPartRow(partTemplateId);
};
