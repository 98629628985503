import { Button, Cell, Icon, useModal } from 'gantri-components';
import { useState } from 'react';
import { MoreMenu } from '../../../../components/dropdowns';
import { PartSwitchingModal } from '../part-switching-modal';
import { MoreMenuOption } from '../../../../components/dropdowns/more-menu/more-menu.types';
import { UsePartPageMoreMenuProps } from './part-page-more-menu.types';
import { RestartOrCancelPartModal } from './components/restart-or-cancel-part-modal';
import { RestartOrCancelPartModalStep } from './components/restart-or-cancel-part-modal/restart-or-cancel-part-modal.types';
import { activePartStatuses } from './part-page-more-menu.constants';
import { partStatuses } from '../../../../constants/options';

export const PartPageMoreMenu = (props: UsePartPageMoreMenuProps) => {
  const { onUpdatePart, part } = props;

  const [showPartSwitchingModal, hidePartSwitchingModal] = useModal(() => {
    return (
      <PartSwitchingModal
        part={part}
        onClose={hidePartSwitchingModal}
        onUpdatePart={onUpdatePart}
      />
    );
  }, [part?.stockId]);

  const [restartOrCancelInitialStep, setRestartOrCancelInitialStep] =
    useState<RestartOrCancelPartModalStep>(null);

  const [showRestartOrCancelPartModal, hideRestartOrCancelPartModal] =
    useModal(() => {
      return (
        <RestartOrCancelPartModal
          initialStep={restartOrCancelInitialStep}
          part={part}
          onClose={hideRestartOrCancelPartModal}
          onUpdatePart={onUpdatePart}
        />
      );
    }, [restartOrCancelInitialStep, part?.id, part?.batchId]);

  const isActivePartStatus = activePartStatuses.some((activeStatus) => {
    return activeStatus === part?.status;
  });
  const isFloatingPart = !part?.stockId;

  const moreMenuOptions: MoreMenuOption[] = [
    {
      disabledTooltipProps: {
        description: 'Only active stock parts can be re-assigned.',
      },
      enabled: isActivePartStatus && !isFloatingPart,
      name: 'Re-assign stock',
      onOptionClick: showPartSwitchingModal,
    },
    {
      allowedFor: ['Admin', 'Lead'],
      disabledTooltipProps: {
        description:
          'Only active, completed, or cancelled stock parts can be re-started.',
      },
      enabled:
        (isActivePartStatus ||
          [partStatuses.completed, partStatuses.cancelled].some((status) => {
            return status === part?.status;
          })) &&
        !isFloatingPart,
      name: 'Re-start part',
      onOptionClick: () => {
        setRestartOrCancelInitialStep('RESTART_REASON');
        showRestartOrCancelPartModal();
      },
    },
    {
      allowedFor: ['Admin'],
      disabledTooltipProps: {
        description: 'Only active floating parts can be cancelled.',
      },
      enabled: isActivePartStatus && isFloatingPart,
      name: 'Cancel part',
      onOptionClick: () => {
        setRestartOrCancelInitialStep('CONFIRM_CANCEL');
        showRestartOrCancelPartModal();
      },
    },
  ];

  return (
    <MoreMenu
      options={moreMenuOptions}
      Toggle={({ onClick }) => {
        return (
          <>
            <Cell hidden={{ lg: false, sm: true }}>
              <Button
                icon={<Icon color="link" name="arrows:arrow_chevron_down" />}
                text="More"
                variant="secondary"
                onClick={onClick}
              />
            </Cell>
            <Cell hidden={{ lg: true, sm: false }}>
              <Icon name="ui-control:three_dots_horizontal" onClick={onClick} />
            </Cell>
          </>
        );
      }}
    />
  );
};
