import { selectProductFormInitialValues } from '../../components/common/select-product-form/select-product-form.schema';
import { FormState } from './new-order.type';

export const initialState: FormState = {
  addresses: [],
  doNotShip: false,
  email: undefined,
  invoice: undefined,
  locationInstruction: {
    fileBlob: undefined,
    fileName: undefined,
    fileSize: undefined,
    metadata: undefined,
  },
  notes: undefined,
  orderType: undefined,
  /** User Address */
  selectedAddress: {
    city: undefined,
    company: undefined,
    country: 'US',
    createdAt: undefined,
    defaultPayment: undefined,
    defaultPayout: undefined,
    firstName: undefined,
    id: undefined,
    lastName: undefined,
    phone: undefined,
    state: 'AL',
    street: undefined,
    unit: undefined,
    updatedAt: undefined,
    userId: undefined,
    zip: undefined,
  },
  /** to be able to use the product selector component */
  ...selectProductFormInitialValues,

  /** only for 'Trade', 'Wholesale' orders */
  discount: undefined,
  /** 'percentage' | 'static' */
  discountType: 'percentage',

  quantity: 1,

  resaleCertificate: {
    fileBlob: undefined,
    fileName: undefined,
    fileSize: undefined,
    // if the user has already a saved certificate
    fileUrl: undefined,
    metadata: undefined,
  },

  shippingFee: 0,
  /** 'cheapest' | 'fastest' | 'other' | 'doNotShip' */
  shippingType: undefined,

  stocks: [],
  /** FetchUsersEmailsItem */
  userSelected: undefined,
};
