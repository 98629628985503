export const pressRecordTypes = {
  award: 'Award',
  feature: 'Feature',
} as const;

export const pressRecordTypeItems = Object.values(pressRecordTypes).map(
  (value) => {
    return {
      label: value,
      value,
    };
  },
);
