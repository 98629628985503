import { UserDetails } from './user.types';

export const currentUserInit = {
  account: null,
  accountCreationDetails: null,
  accountType: 'Admin',
  credits: {
    creditTransactions: [],
    total: null,
  },
  email: '',
  facebookConnected: false,
  firstName: '',
  googleConnected: false,
  howHeardAboutUs: [],
  isActive: false,
  lastName: '',
  notes: '',
  payments: [],
  referrals: { referralLink: null, referred: [] },
  resaleCertificate: '',
  shop: {
    cart: [],
    orders: [],
    wishlist: [],
  },
  subscriber: {},
  userActivity: null,
  userId: 0,
  userName: '',
} as UserDetails;

export const UserTabs = Object.freeze({
  ACCOUNT: 'account',
  ACTIVITY: 'activity',
  PAYMENTS: 'Payments',
  REFERRALS: 'Referrals',
  REVIEWS: 'Reviews',
  SHOP: 'shop',
});
