import { StocksStatus } from '../../components/dropdowns/stocks-filter/stocks-filter.constants';
import {
  orderStatusesMap,
  shipmentStatuses,
  stockStatuses,
} from '../../constants/options';
import { Stock } from '../../types/store';

export const isAbleToShipShipment = (
  shipment: { status: string; stocks: Stock[] },
  orderStatus: string,
): boolean => {
  const cannotShip =
    hasIncompleteStock(shipment.stocks) ||
    shipment.status !== shipmentStatuses.readyToShip ||
    [
      orderStatusesMap.cancelled,
      orderStatusesMap.delivered,
      orderStatusesMap.refunded,
    ].some((status) => {
      return status === orderStatus || status === shipment.status;
    });

  return !cannotShip;
};

export const hasIncompleteStock = (
  stocks: { status: StocksStatus }[],
): boolean => {
  return stocks.some(({ status: stockStatus }) => {
    const completeStockStatuses: StocksStatus[] = [
      stockStatuses.inStock,
      stockStatuses.completed,
      stockStatuses.restocked,
      stockStatuses.refunded,
      stockStatuses.cancelled,
    ];

    return !completeStockStatuses.includes(stockStatus);
  });
};

export const getCanDeliver = (orderStatus: string): boolean => {
  const invalidStatuses = [
    orderStatusesMap.refunded,
    orderStatusesMap.cancelled,
    orderStatusesMap.delivered,
  ];

  const isInvalidStatus = invalidStatuses.some((status) => {
    return status === orderStatus;
  });

  return !isInvalidStatus;
};
