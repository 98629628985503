import { Cell, Grid, Typography, TypographyColor } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { printPrepareQueueModalAtoms } from '../../../../print-transfer-queue-modal.atoms';
import { Divider } from '../../../../../../../../../components/divider';
import { StyledQueueStack } from './details-panel-queue-section.styles';
import { printPrepareQueueModalSteps } from '../../../../print-transfer-queue-modal.constants';
import { pluralize } from '../../../../../../../../../helpers/pluralize';

export const DetailsPanelQueueSection = () => {
  const queueData = useRecoilValue(printPrepareQueueModalAtoms.queueData);
  const step = useRecoilValue(printPrepareQueueModalAtoms.step);

  const textColor: TypographyColor =
    step === printPrepareQueueModalSteps.queueDeleted ? 't2' : 't1';

  return (
    <StyledQueueStack gap="2x" padding="2x">
      <Typography
        color={textColor}
        paddingBottom="x"
        text="Queue"
        variant="h4"
      />
      <Grid columnGap="3x" columns="13rem 1fr" rowGap="x">
        <Typography color={textColor} text="Size" textStyle="bold" />
        <Typography
          color={textColor}
          text={`${queueData?.size || 0} ${pluralize('job', queueData?.size)}`}
        />

        <Cell width={2}>
          <Divider />
        </Cell>

        <Typography color={textColor} text="Sets of G-codes" textStyle="bold" />
        <Typography
          color={textColor}
          text={`${queueData?.sets?.length || 0} job ${pluralize(
            'set',
            queueData?.size,
          )}`}
        />
      </Grid>
    </StyledQueueStack>
  );
};
