import { UseQueryProps } from '../../../../hooks/use-fetch/react-queries.types';
import { useNotification } from '../../../../hooks/useNotification';
import {
  useInvalidateQueryByPredicate,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';
import {
  FetchPaginatedGiftCodesArgs,
  FetchPaginatedGiftCodesResponse,
} from './fetch-paginated-giftcodes.types';
import { fetchPaginatedGiftCodes } from './fetch-paginated-giftcodes';

interface Props<TData>
  extends UseQueryProps<TData, FetchPaginatedGiftCodesResponse> {
  args?: FetchPaginatedGiftCodesArgs;
}

export const useFetchPaginatedGiftCodesQuery = (
  props?: Props<FetchPaginatedGiftCodesResponse>,
) => {
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch<FetchPaginatedGiftCodesResponse>({
    config: {
      enabled: !!props?.args,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch gift codes.',
        });
      },
      queryFn: () => {
        return fetchPaginatedGiftCodes.query(props?.args);
      },
      queryKey: fetchPaginatedGiftCodes.key(props?.args),
    },
    showLoading: true,
    transformDependencies: props?.transformDependencies,
  });

  return {
    ...data,
    isLoading,
  };
};

export const useInvalidateFetchPaginatedGiftCodesCache = () => {
  const invalidate = useInvalidateQueryByPredicate();

  const invalidateFetchPaginatedGiftCodesCache = (
    args?: FetchPaginatedGiftCodesArgs,
  ) => {
    invalidate((query) => {
      if (typeof query.queryKey[0] === 'string') return false;

      const { groupKey, key } = query.queryKey[0] as {
        groupKey: string;
        key: string;
      };

      return !!args
        ? key === fetchPaginatedGiftCodes.key(args)[0].key
        : groupKey === fetchPaginatedGiftCodes.groupKey();
    });
  };

  return { invalidateFetchPaginatedGiftCodesCache };
};
