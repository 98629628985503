import * as yup from 'yup';
import { ConfigureCutSheetFormSchemaDef } from './configure-cut-sheets-modal.types';

export const configureCutSheetFormSchema: yup.SchemaOf<ConfigureCutSheetFormSchemaDef> =
  yup.object().shape({
    closeable: yup.boolean(),
    firstLifestyleViewPhoto: yup.string().nullable().required(),
    frontViewPhoto: yup.string().nullable().required(),
    productCategory: yup.string().required(),
    requires2LifestylePhotos: yup.boolean(),
    secondLifestyleViewPhoto: yup
      .string()
      .nullable()
      .when('requires2LifestylePhotos', {
        is: true,
        then: (schema) => {
          return schema.required();
        },
      }),
    sideViewPhoto: yup.string().nullable(),
  });
