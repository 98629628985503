import { Conditional, Flex, Stack, Typography } from 'gantri-components';
import { useToggle } from 'react-use';
import { useState } from 'react';
import { useNotification } from '../../../../hooks/useNotification';
import { partsApi } from '../../../../api';
import { StyledTextArea } from './part-notes.styles';
import { PartNotesProps } from './part-notes.types';
import { StyledMiniButton } from '../part-summary/part-summary.styles';
import { SectionTitle } from '../../../../components/layout/section-title';

export const PartNotes = (props: PartNotesProps) => {
  const { part, setPart } = props;

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const [notes, setNotes] = useState<string>(part?.notes);
  const [isEditing, toggleIsEditing] = useToggle(false);

  const handleOnCancel = () => {
    setNotes(part?.notes);
    toggleIsEditing();
  };

  const handleOnSave = async () => {
    try {
      showLoading();

      const { data } = await partsApi.updatePartNotes(part?.id, notes);

      setPart(data.part[0]);

      toggleIsEditing();
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to save part notes.',
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <Stack gap="2x">
      <SectionTitle text="Notes">
        <Conditional
          condition={isEditing}
          Fallback={
            <StyledMiniButton
              justifySelf="start"
              size="small"
              text="Edit"
              variant="ghost"
              onClick={() => {
                toggleIsEditing();
              }}
            />
          }
        >
          <Flex gap=".5x" justifySelf="start">
            <StyledMiniButton
              size="small"
              text="Cancel"
              variant="ghost"
              onClick={handleOnCancel}
            />
            <Typography text="|" />
            <StyledMiniButton
              size="small"
              text="Save"
              variant="ghost"
              onClick={handleOnSave}
            />
          </Flex>
        </Conditional>
      </SectionTitle>

      <Conditional
        condition={isEditing}
        Fallback={<Typography htmlText={notes?.replace(/\n/g, '<br />')} />}
      >
        <StyledTextArea minRows={10} value={notes} onTextChange={setNotes} />
      </Conditional>
    </Stack>
  );
};
