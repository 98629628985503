import { Button } from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useStartJob } from '../../../../../../../../../api/jobs/routes';
import { getJobInfo } from '../../../../../../../helpers/get-job-info';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { completePrintHarvestModalAtoms } from '../../../complete-print-harvest-modal.atoms';
import { EvaluatePartFooterProps } from './evaluate-part-footer.types';

export const EvaluatePartFooter = (props: EvaluatePartFooterProps) => {
  const { handleCloseModal } = props;

  const [job, setJob] = useRecoilState(completePrintHarvestModalAtoms.job);
  const checklist = useRecoilValue(completePrintHarvestModalAtoms.checklist);
  const setStep = useSetRecoilState(completePrintHarvestModalAtoms.step);

  const navigateToChecklist = () => {
    setStep('COMPLETE_CHECKLIST');
  };

  const { isLoading, onStartJob } = useStartJob({
    onSuccess: async (data) => {
      navigateToChecklist();

      setJob({ ...job, status: data?.job?.status });
    },
  });

  const { isReady } = getJobInfo(job);

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        disabled={!checklist}
        processing={isLoading}
        size={fullScreenModalFooterButtonSize}
        text={isReady ? 'Confirm Start' : 'Continue'}
        onClick={async () => {
          // ? See `hasPrintHarvest` JSDoc comment for functionality explanation
          if (isReady) {
            await onStartJob({
              jobId: job.id,
              machineId: job.machineId || job.machine?.id,
            });
          } else {
            navigateToChecklist();
          }
        }}
      />
    </>
  );
};
