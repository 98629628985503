import { useFormikContext } from 'formik';
import {
  Box,
  Button,
  Cell,
  Checkbox,
  Conditional,
  Flex,
  FormikInput,
  generateSku,
  Stack,
} from 'gantri-components';
import { useAsync } from 'react-use';
import { useRecoilState } from 'recoil';
import { selectProductFormAtoms } from '../../select-product-form.atoms';
import { SelectProductFormData } from '../../select-product-form.types';
import { ProductSearch } from './components/product-search';
import { QuantityDropdown } from './components/quantity-dropdown';
import { VariantDropdowns } from './components/variant-dropdown';
import { useSelectProductFormOptions } from './hooks/use-select-product-form-options';
import { SelectProductFormContentProps } from './select-product-form-content.types';
import { selectProductFormInitialValues } from '../../select-product-form.schema';
import { noneOption } from '../../../../../constants/options';
import { getVariantCodesFromPartials } from './helpers/get-variant-codes-from-partials';
import { useGetVariantPartialDetail } from './hooks/use-get-variant-partial-detail';
import { ColorSwatchDropdown } from '../../../color-swatch-dropdown';

export const SelectProductFormContent = (
  props: SelectProductFormContentProps,
) => {
  const {
    autoSubmitWhenValid,
    buttonType = 'submit',
    hasExternalValidation,
    isValidForm,
    onActionHandler,
    showActiveProductsToggle,
    showQuantity,
    submitButtonText,
  } = props;

  const [activeProductsOnly, setActiveProductsOnly] = useRecoilState(
    selectProductFormAtoms.activeProductsOnly,
  );

  const {
    isValid,
    resetForm,
    setFieldValue,
    setFormikState,
    submitForm,
    validateForm,
    values: formikValues,
  } = useFormikContext<SelectProductFormData>();

  const { colorCode, productId, variantCodes, variantPartialDetails } =
    formikValues;

  const { getVariantPartialDetail } = useGetVariantPartialDetail({ productId });

  const {
    hasColors,
    hasVariants,
    productOptions,
    sizeCode,
    skuPartialCodes,
    variantOptions,
  } = useSelectProductFormOptions(formikValues);

  const isColorValid = hasColors ? !!colorCode : true;
  const isVariantValid = hasVariants
    ? variantCodes.length === variantOptions.length
    : true;

  const getOnVariantSelect = (props: {
    partialIndex: number;
    variantIndex: number;
  }) => {
    const { partialIndex, variantIndex } = props;

    return async (item: { code: string; name: string }) => {
      const variantPartialDetail = getVariantPartialDetail({
        partialIndex,
        variantIndex,
        ...item,
      });

      await setFieldValue(
        `variantPartialDetails[${variantIndex}][${partialIndex}]`,
        { ...variantPartialDetail, ...item },
      );
    };
  };

  const { colorOptions, disableColor } = useSelectProductFormOptions({
    productId,
  });

  useAsync(async () => {
    resetForm();

    // formik's `isValid` is unreliable after form reset, so validating manually
    await validateForm();
  }, [productOptions]);

  useAsync(async () => {
    if (autoSubmitWhenValid && isValid) {
      await submitForm();
    }
  }, [isValid, autoSubmitWhenValid]);

  useAsync(async () => {
    // reset other dropdowns on product change

    const shouldSetUnpaintedColorCode =
      !hasColors && colorCode !== noneOption.code;

    const resetColorCode = hasColors && colorCode === noneOption.code;

    const updatedColorCode = resetColorCode
      ? selectProductFormInitialValues.colorCode
      : shouldSetUnpaintedColorCode
      ? noneOption.code
      : colorCode;

    setFormikState((prevState) => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          colorCode: updatedColorCode,
          sizeCode,
          variantCodes: selectProductFormInitialValues.variantCodes,
          variantPartialDetails:
            selectProductFormInitialValues.variantPartialDetails,
        },
      };
    });
  }, [productId]);

  useAsync(async () => {
    // set sku when required fields are provided
    if (productId && sizeCode && isColorValid && isVariantValid) {
      const sku = generateSku({
        color: colorCode,
        productId,
        size: sizeCode,
        variants: variantCodes,
      });

      await setFieldValue('selectedSku', sku);
    } else {
      await setFieldValue('selectedSku', undefined);
    }
  }, [productId, sizeCode, colorCode, variantCodes]);

  useAsync(async () => {
    // set full variant codes when partials are provided
    const variantCodes = getVariantCodesFromPartials({
      skuPartialCodes,
      variantPartialDetails,
    });

    await setFieldValue('variantCodes', variantCodes);
  }, [variantPartialDetails]);

  return (
    <Stack>
      <Box maxWidth="100%" overflow="auto" paddingBottom="x">
        <Flex gap="2x" minWidth="max-content">
          <Stack>
            <FormikInput
              Field={<ProductSearch productId={productId} />}
              fieldVariant="standard"
              labelText="Product"
              name="productId"
              required
            />

            <Conditional condition={showActiveProductsToggle}>
              <Cell justifySelf="start">
                <Checkbox
                  checked={activeProductsOnly}
                  labelText="Active products only"
                  onSelected={setActiveProductsOnly}
                />
              </Cell>
            </Conditional>
          </Stack>

          <Conditional condition={hasColors}>
            <FormikInput
              Field={
                <ColorSwatchDropdown
                  disabled={disableColor}
                  items={colorOptions}
                  placeholder="Select Color"
                  width="15rem"
                />
              }
              fieldVariant="standard"
              labelText="Color"
              name="colorCode"
              required
            />
          </Conditional>

          <Conditional condition={hasVariants}>
            <VariantDropdowns
              getOnVariantSelect={getOnVariantSelect}
              productId={productId}
              variantPartialDetails={variantPartialDetails}
            />
          </Conditional>

          <Conditional condition={showQuantity}>
            <FormikInput
              Field={<QuantityDropdown productId={productId} />}
              fieldVariant="standard"
              labelText="Quantity"
              name="quantity"
              required
            />
          </Conditional>

          <Conditional condition={!autoSubmitWhenValid}>
            <Box paddingTop="2.6rem">
              <Button
                disabled={hasExternalValidation ? !isValidForm : !isValid}
                text={submitButtonText}
                type={buttonType}
                variant="secondary"
                onClick={onActionHandler}
              />
            </Box>
          </Conditional>
        </Flex>
      </Box>
    </Stack>
  );
};
