import { FC, memo, useMemo } from 'react';
import {
  Cell,
  FormikInput,
  getSkuDetails,
  Grid,
  Icon,
  ProductColorCode,
  Stack,
  Typography,
} from 'gantri-components';
import { flatten } from 'lodash';
import { FieldArray } from 'formik';
import { SkusProps } from './products-section.types';
import InfoWrapper from '../../../../components/info-wrapper';
import { formatAsCurrency } from '../../../../helpers/formatter';
import { ShortProductSummary } from '../../../../components/common/short-product-summary';

export const NewOrderSelectedSkusInfo: FC<SkusProps> = memo((props) => {
  const { orderType, stocks } = props;

  const shouldUseManufacturerPrice = useMemo(() => {
    return ['Third Party'].includes(orderType);
  }, [orderType]);

  if (!stocks.length) {
    return null;
  }

  return (
    <FieldArray name="stocks">
      {({ remove }) => {
        return (
          <Stack gap="x">
            {stocks.map(
              ({ idx, product: { detail, id: productId, sku } }, index) => {
                const {
                  manufacturerPrice,
                  name: productName,
                  price,
                  selectors,
                  skuPartialCodes,
                  skuThumbnails,
                } = detail;

                const { color, variantPartials } = getSkuDetails({
                  sku,
                  skuPartialCodes,
                });

                const variantDetails = selectors.map(
                  (variantData, variantIndex) => {
                    return variantData.map(
                      ({ label, options }, partialIndex) => {
                        const name = options.find(({ code }) => {
                          return (
                            code === variantPartials[variantIndex][partialIndex]
                          );
                        })?.name;

                        return { label, name };
                      },
                    );
                  },
                );

                const thumbnailFileName = skuThumbnails[sku];

                return (
                  <InfoWrapper key={idx}>
                    <Grid
                      alignContent="center"
                      alignItems="center"
                      columns="1fr max-content 10rem max-content"
                      gap="2x"
                    >
                      <ShortProductSummary
                        color={color as ProductColorCode}
                        id={productId}
                        name={productName}
                        sku={sku}
                        thumbnail={thumbnailFileName}
                        variants={flatten(variantDetails)}
                      />

                      <Cell>
                        <Typography
                          text={formatAsCurrency(
                            (shouldUseManufacturerPrice
                              ? manufacturerPrice
                              : price) || 0,
                            { isCents: true },
                          )}
                        />
                      </Cell>

                      <Stack alignContent="center" flow="column" gap="10px">
                        <Typography iconPosition="right" text="Qty" />

                        <FormikInput
                          name={`stocks.${index}.quantity`}
                          type="number"
                          onBlurTextChange={(value: string) => {
                            if (!Number(value)) {
                              remove(index);
                            }
                          }}
                        />
                      </Stack>

                      <Cell>
                        <Icon
                          cursor="pointer"
                          name="actions:trash_can"
                          onClick={() => {
                            return remove(index);
                          }}
                        />
                      </Cell>
                    </Grid>
                  </InfoWrapper>
                );
              },
            )}
          </Stack>
        );
      }}
    </FieldArray>
  );
});
