import { useEffect, useState } from 'react';
import { GetRowProps, Table } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import PartsFilter from '../../components/dropdowns/parts-filter';
import { useGetTableColumns } from './hooks/use-get-table-columns';
import { getTableRowStatusFromAttentions } from '../../helpers/get-table-row-status-from-attentions';
import { PageWithTable } from '../../components/layout';
import { useTableFilters } from '../../components/common/table/hooks/use-table-filters';
import { PartsSortingField } from '../../components/dropdowns/parts-filter/parts-filter.types';
import {
  pageName,
  partsFiltersDefaults,
  partsPageAtoms,
} from '../../components/dropdowns/parts-filter/parts-filter.atoms';
import {
  GetPartsArgs,
  PaginatedPart,
  PartLocation,
} from '../../api/parts/routes/get-parts/get-parts.types';
import { FiltersFetchRequestArgs } from '../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { modalsFiltersDetails } from './parts.constants';
import { convertQueriesToString, getActiveQueries } from '../../helpers/checks';
import { getIsInProgressPart } from '../part/components/part-jobs/helpers/get-is-in-progress-part';
import { PartsHeaderContent } from './components/parts-header-content';
import { useFetchPaginatedParts } from '../../api/parts/routes';

export const Parts = () => {
  const queries = getActiveQueries<{ location: number }>();

  const [locations, setLocations] = useState<PartLocation[]>([]);
  const [filterArgs, setFilterArgs] =
    useState<FiltersFetchRequestArgs<GetPartsArgs, PartsSortingField>>();

  const setLocationId = useSetRecoilState(partsPageAtoms.filters.locationId);
  const { location: locationIdQuery, ...remainingQueries } = queries;

  if (locationIdQuery) {
    // Intentionally called outside of useEffect or fetchParts to ensure update is made before initial page load
    setLocationId(locationIdQuery);
  }

  useFetchPaginatedParts({
    enabled: !!filterArgs,
    fetchArgs: {
      ...filterArgs,
      locationId: locationIdQuery || filterArgs?.locationId,
    },
    onSuccess: async ({ locationNames, parts, totalParts }) => {
      setTotalCount(totalParts);
      setRecords(parts);
      setLocations(locationNames || []);
    },
    showLoading: true,
  });

  const {
    filtersProps,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<PaginatedPart, GetPartsArgs, PartsSortingField>({
    fetchRequest: setFilterArgs,
    filtersContent: <PartsFilter locations={locations} />,
    modalFiltersDetails: modalsFiltersDetails,
    pageName,
    searchFilterDetails: {
      atom: partsPageAtoms.search,
    },
    sortByFilterDetails: {
      atom: partsPageAtoms.sortBy,
      defaultValue: partsFiltersDefaults.sortBy,
    },
    sortOptions: [
      {
        bidirectional: true,
        label: 'Ended Date',
        sortingField: 'completedAt',
      },
      {
        bidirectional: true,
        label: 'Attempt #',
        sortingField: 'attempt',
      },
      {
        bidirectional: true,
        label: 'Yield %',
        sortingField: 'printYield',
      },
      {
        bidirectional: true,
        label: 'Last Action',
        sortingField: 'lastActionAt',
      },
    ],
  });

  const handleLocationUpdate = (responseData) => {
    setRecords((old) => {
      return old.map((part) => {
        return part.id === responseData?.id
          ? { ...part, ...responseData }
          : part;
      });
    });
  };

  const getRowProps: GetRowProps<PaginatedPart> = (row) => {
    const { status } = row.original;
    const isInProgressPart = getIsInProgressPart(status);

    return isInProgressPart
      ? { status: getTableRowStatusFromAttentions(row) }
      : {};
  };

  const columns = useGetTableColumns({ handleLocationUpdate }, [
    records,
    locations,
  ]);

  useEffect(() => {
    if (locationIdQuery) {
      const queryString = convertQueriesToString(remainingQueries);

      const { origin, pathname } = window.location;

      window.history.replaceState(
        remainingQueries,
        '',
        origin + pathname + queryString,
      );
    }
  }, []);

  return (
    <PageWithTable pageTitle="Parts">
      <PageWithTable.Header title="Parts">
        <PartsHeaderContent
          filterArgs={filterArgs}
          totalResults={pagingProps.total}
        />
      </PageWithTable.Header>

      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          getRowProps={getRowProps}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          sorting={sortProps}
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
