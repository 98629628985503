import { atom } from 'recoil';

const atomKeyPrefix = 'CONFIGURE-CUT-SHEETS-MODAL';

export const configureCutSheetsModalAtoms = {
  processingUpload: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-processing-upload`,
  }),
};
