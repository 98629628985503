import React, { FC, useMemo, useRef } from 'react';
import {
  Button,
  Cell,
  Conditional,
  Grid,
  Tab,
  TabPanel,
  Tabs,
  TabsList,
  useModal,
} from 'gantri-components';
import { Form, Formik } from 'formik';
import { useToggle } from 'react-use';
import { FormikProps } from 'formik/dist/types';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import MetaData from '../../components/meta-data';
import {
  GeneralSection,
  OverviewSection,
  TransactionsSection,
} from './sections';
import { NewInventoryTransaction } from './modals/new-inventory-transaction';
import { InventoryProps } from './inventory.types';
import { PageHeading } from '../../components/layout/page-heading';
import { useSyncPropertyWithQueryString } from '../../hooks/useSyncPropertyWithQueryString';
import { useRouter } from '../../hooks';
import { useGetInventoryInfoQuery } from '../../api/inventories/routes/get-inventory';
import { StyledTabListGrid } from './inventory.styles';
import { inventorySchema } from './inventory.schema';
import { useUpdateInventoryMutation } from '../../api/inventories/routes/update-inventory';
import { UpdateInventoryArgs } from '../../api/inventories/routes/update-inventory/update-inventory.types';
import { InventoryTabs } from './inventory.constant';
import { FormHeader } from './components/form-header';

type InventoryPageTab = typeof InventoryTabs[keyof typeof InventoryTabs];

export const Inventory: FC<InventoryProps> = () => {
  const { location } = useRouter();
  const [inventoryId] = location.pathname.split('/').reverse();

  const { currentValue: activeTab, updateQueryString } =
    useSyncPropertyWithQueryString<InventoryPageTab>(
      'tab',
      InventoryTabs.OVERVIEW,
    );

  const { inventory, isLoading } = useGetInventoryInfoQuery({
    id: Number(inventoryId),
  });

  const formRef = useRef<FormikProps<any>>();
  const [isEditModeActive, setIsEditModeActive] = useToggle(false);
  const { inventory: currentInventory } = inventory ?? {};
  const { isLoading: isSyncing, onUpdateInventory } =
    useUpdateInventoryMutation({ showLoading: false });

  const [showNewTransactionModal, hideNewTransactionModal] = useModal(() => {
    return (
      <NewInventoryTransaction
        currentInventory={currentInventory}
        onClose={hideNewTransactionModal}
      />
    );
  }, [currentInventory]);

  const initialValues = useMemo(() => {
    if (!currentInventory) return {};

    return {
      ...pick(currentInventory || {}, [
        'alertLevel',
        'description',
        'id',
        'lifespan',
        'locations',
        'name',
        'notes',
        'status',
        'stock',
        'thumbnailUrl',
        'type',
        'typicalLeadTime',
        'unit',
        'website',
      ]),
      locations:
        currentInventory?.locations?.map?.(({ id }) => {
          return id;
        }) || [],
      vendorId: currentInventory?.vendor?.id,
      vendorNumber: currentInventory?.vendor?.vendorNumber,
    };
  }, [currentInventory]);

  const onUpdate = async (values) => {
    const request = {
      ...omit(values, [
        'alertLevel',
        'vendorNumber',
        'vendorId',
        'lifespan',
        'stock',
      ]),
      alertLevel: Number(values.alertLevel) || 0,
      lifespan: values.type === 'Machine Part' ? values.lifespan : null,
      source: 'inventory-details',
      vendorId: values.vendorId,
      vendorNumber: values.vendorNumber,
    } as UpdateInventoryArgs;

    await onUpdateInventory(request, {
      onSuccess: () => {
        setIsEditModeActive(false);
      },
    });
  };

  return isLoading || !currentInventory.id ? null : (
    <>
      <MetaData title={`Inventory ${inventoryId}`} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        innerRef={formRef}
        validateOnChange
        validationSchema={inventorySchema}
        onSubmit={onUpdate}
      >
        {({ resetForm }) => {
          return (
            <Form>
              <PageHeading title={currentInventory.name}>
                <Conditional condition={activeTab === InventoryTabs.OVERVIEW}>
                  <FormHeader
                    isEditModeActive={isEditModeActive}
                    isSyncing={isSyncing}
                    onCancel={() => {
                      resetForm();
                      setIsEditModeActive();
                    }}
                    onEdit={setIsEditModeActive}
                  />
                </Conditional>
              </PageHeading>

              <Grid marginTop="4x" rowGap="7x">
                <GeneralSection
                  currentInventory={currentInventory}
                  isEditModeActive={isEditModeActive}
                />

                <Cell>
                  <Tabs value={activeTab}>
                    <StyledTabListGrid
                      alignItems="center"
                      columns="1fr max-content"
                    >
                      <Cell paddingTop="1.2rem">
                        <TabsList
                          hasBorder={false}
                          onValueChange={(value: InventoryPageTab) => {
                            updateQueryString(value);
                          }}
                        >
                          <Tab
                            label="Overview"
                            value={InventoryTabs.OVERVIEW}
                          />
                          <Tab
                            label="Transactions"
                            value={InventoryTabs.TRANSACTIONS}
                          />
                        </TabsList>
                      </Cell>

                      <Conditional
                        condition={activeTab === InventoryTabs.TRANSACTIONS}
                      >
                        <Button
                          text="New Transaction"
                          onClick={showNewTransactionModal}
                        />
                      </Conditional>
                    </StyledTabListGrid>

                    <TabPanel value={InventoryTabs.OVERVIEW}>
                      <OverviewSection
                        currentInventory={currentInventory}
                        isEditModeActive={isEditModeActive}
                      />
                    </TabPanel>

                    <TabPanel value={InventoryTabs.TRANSACTIONS}>
                      <TransactionsSection currentInventoryId={inventoryId} />
                    </TabPanel>
                  </Tabs>
                </Cell>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
