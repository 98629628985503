import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { DeletePrintTransferQueueResponse } from './delete-print-transfer-queue.types';

export const deletePrintTransferQueue = (id: number) => {
  return axios
    .delete<DeletePrintTransferQueueResponse>(
      `${getCoreApiUrl('queue/print-transfer')}/${id}`,
    )
    .then(({ data }) => {
      return data;
    });
};
