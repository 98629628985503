import * as yup from 'yup';
import { UpdateInventoryTargetFormData } from './update-inventory-target-modal.types';

export const updateInventoryTargetSchema: yup.SchemaOf<UpdateInventoryTargetFormData> =
  yup.object().shape({
    inventoryTarget: yup
      .number()
      .min(1, 'Quantity must be at least 1.')
      .required('Required'),
  });

export const updateInventoryTargetFormInitialValues: UpdateInventoryTargetFormData =
  {
    inventoryTarget: undefined,
  };
