import {
  addDays as addDaysFns,
  addMonths as addMonthsFns,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  endOfMonth as endOfMonthFn,
  endOfWeek as endOfWeekFn,
  format,
  isValid,
  startOfMonth as startOfMonthFn,
  startOfWeek as startOfWeekFn,
} from 'date-fns';
import { convertToTimeZone } from 'date-fns-timezone';
import isNil from 'lodash/isNil';
import {
  LONG_FORMAT,
  MEDIUM_FORMAT,
  PACIFIC_TIMEZONE,
} from '../../constants/dates';
import { LabelValue } from '../../types/label-value';

export * from './format-as-currency';

export * from './format-as-locale-number';

export const makeDateInstance = (date): any => {
  switch (typeof date) {
    case 'string': {
      return new Date(date.split('T')[0]);
    }

    case 'number': {
      return new Date(date);
    }

    default: {
      return date;
    }
  }
};

export const addDays = (date: Date | number = new Date(), value) => {
  return addDaysFns(makeDateInstance(date), value);
};

export const addMonths = (date: Date | number = new Date(), value) => {
  return addMonthsFns(makeDateInstance(date), value);
};

export const startOfMonth = (date: Date | number = new Date()) => {
  return startOfMonthFn(date);
};

export const startOfWeek = (date: Date | number = new Date()) => {
  return startOfWeekFn(date);
};

export const endOfWeek = (date: Date | number = new Date()) => {
  return endOfWeekFn(date);
};

export const endOfMonth = (date: Date | number = new Date()) => {
  return endOfMonthFn(date);
};

const regexSymbols = /[.\s$,/\\:\-?{-~!"^_`[\]]+/g;
const regexEveryThreeDigits = /\B(?=(\d{3})+(?!\d))/g;
const regexEveryThreeDigitsBeforeDecimal = /\d(?=(\d{3})+\.)/g;

export const makeDateString = ({ date, dateMs }) => {
  if (dateMs) {
    const dateObj = new Date(dateMs);

    return Date.parse(dateObj?.toDateString())
      ? formatDate(dateObj, LONG_FORMAT)
      : 'TBD';
  }

  if (date) {
    return Date.parse(date) ? formatDate(date, LONG_FORMAT) : 'TBD';
  }

  return 'TBD';
};

export const formatDate = <ReturnType = string>(
  date,
  dateFormat = MEDIUM_FORMAT,
): ReturnType => {
  const dateInstance = makeDateInstance(
    typeof date === 'string' ? new Date(date) : date,
  );

  if (!date || !isValid(dateInstance)) {
    return '' as ReturnType;
  }

  if (dateFormat === 'T') {
    return dateInstance.getTime() as ReturnType;
  }

  const pacificDate = convertToTimeZone(dateInstance, {
    timeZone: PACIFIC_TIMEZONE,
  });

  return format(pacificDate, dateFormat) as ReturnType;
};

export const formatDateWithoutTimezone = (date, dateFormat = MEDIUM_FORMAT) => {
  if (date) {
    const dateInstance = makeDateInstance(
      typeof date === 'string' ? new Date(date) : date,
    );

    return format(dateInstance, dateFormat);
  }

  return null;
};

export const formatPhoneNumber = (phoneString) => {
  const cleanedString = phoneString.replace(/\D/g, '');
  const match = cleanedString.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? '+1 ' : '';

    return `${intlCode} (${match[2]}) ${match[3]}-${match[4]}`;
  }

  return null;
};

export const formatCommaSeparatedNumber = (number) => {
  return `${parseInt(number, 10)}`.replace(regexEveryThreeDigits, '$&,');
};

export const formatPercentage = (value) => {
  return `${value}%`;
};

/** @deprecated Use `formatAsCurrency` instead. */
export const formatCurrency = (value, moveDecimal = true) => {
  if (isNaN(value)) {
    return '';
  }

  const isNegative = +value < 0;
  const number = Math.abs(value);
  const sign = isNegative ? '-' : '';

  if (!moveDecimal) {
    return `${sign}$${number.toFixed(2)}`.replace(
      regexEveryThreeDigitsBeforeDecimal,
      '$&,',
    );
  }

  return `${sign}$${(number / 100).toFixed(2)}`.replace(
    regexEveryThreeDigitsBeforeDecimal,
    '$&,',
  );
};

export const unformatCurrency = (currencyString) => {
  const digits = currencyString.replace(regexSymbols, '');

  return parseInt(digits, 10);
};

export const convertToUTCTimestamp = (date) => {
  const dateInstance = makeDateInstance(date);

  if (!date || !isValid(dateInstance)) {
    return '';
  }

  return dateInstance.getTime() + dateInstance.getTimezoneOffset() * 60000;
};

export const convertEcurrencyToCurrency = (value) => {
  if (isNil(value)) {
    return 0;
  }

  return (+value || 0) / 100;
};

export const formatByType = (value, type) => {
  switch (type) {
    case 'date':
      return formatDate(value, MEDIUM_FORMAT);
    case 'date-long':
      return formatDate(value, MEDIUM_FORMAT);
    case 'currency':
      return `${value < 0 ? '-' : ''}${formatCurrency(Math.abs(value))}`;
    case 'ecurrency':
      return (value || 0) * 100;
    default:
      return value;
  }
};

export const convertStringArrayToValueLabelArray = <Value extends string>(
  arr: Value[] | Readonly<Value[]>,
): LabelValue<Value>[] => {
  return arr?.map((item) => {
    return {
      label: item,
      value: item,
    };
  });
};

export function timeAgo(
  date1: number | Date,
  date2: number | Date = new Date(),
) {
  const timeDiffDays = Math.abs(differenceInDays(date1, date2));
  const timeDiffMonths = Math.abs(differenceInMonths(date1, date2));
  const timeDiffYears = Math.abs(differenceInYears(date1, date2));

  if (timeDiffMonths < 6) {
    return `${timeDiffDays} day${timeDiffDays === 1 ? '' : 's'} ago`;
  }

  if (timeDiffMonths >= 6 && timeDiffYears < 2) {
    return `${timeDiffMonths} month${timeDiffMonths === 1 ? '' : 's'} ago`;
  }

  return `${timeDiffYears} year${timeDiffYears === 1 ? '' : 's'} ago`;
}
