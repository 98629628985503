export const cutSheetPreview = {
  firstLifestyleViewPhotoPlaceholder: {
    empty:
      'https://res.cloudinary.com/gantri/image/upload/static-assets/core/cut-sheets/cut-sheet-lifestyle-image-1-placeholder-empty.png',
  },
  secondLifestyleViewPhotoPlaceholder: {
    empty:
      'https://res.cloudinary.com/gantri/image/upload/static-assets/core/cut-sheets/cut-sheet-lifestyle-image-2-placeholder-empty.png',
    filled:
      'https://res.cloudinary.com/gantri/image/upload/static-assets/core/cut-sheets/cut-sheet-lifestyle-image-2-placeholder-filled.png',
  },
  sheet:
    'https://res.cloudinary.com/gantri/image/upload/static-assets/core/cut-sheets/cut-sheet-preview-image.png',
} as const;
