import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  FetchPaginatedPayoutsArgs,
  FetchPaginatedPayoutsResponse,
} from './fetch-paginated-payouts.types';

export const fetchPaginatedPayouts = (
  args: FetchPaginatedPayoutsArgs,
): Promise<FetchPaginatedPayoutsResponse> => {
  const {
    count,
    dateRange,
    page = null,
    sortingField = null,
    sortingType = null,
    statuses = null,
  } = args;

  return axios
    .get<FetchPaginatedPayoutsResponse>(
      `${getCoreApiUrl('payouts')}/paginate`,
      {
        params: {
          filter: {
            endDate: dateRange.to,
            startDate: dateRange.from,
            statuses,
          },
          limit: count,
          page,
          sort: {
            field: sortingField,
            type: sortingType,
          },
        },
      },
    )
    .then(({ data }) => {
      return data;
    });
};
