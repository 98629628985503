import {
  Dropdown,
  Flex,
  Grid,
  Stack,
  TextField,
  Typography,
} from 'gantri-components';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { productInfoPageMaxWidth } from '../../info.constants';
import { convertStringArrayToValueLabelArray } from '../../../../../../helpers/formatter';
import { productAtoms } from '../../../../product.atoms';
import {
  productAccessoriesSubCategories,
  productLightSubCategories,
} from '../../../../../../api/products/products.constants';
import { SectionTitle } from '../../../../../../components/layout/section-title';
import { ProductStatus } from './components/product-status';
import { Label } from '../../../../../../components/label';

export const InfoTabAbout = () => {
  const [product, setProduct] = useRecoilState(productAtoms.product);
  const isAccessory = useRecoilValue(productAtoms.isAccessory);

  return (
    <Stack gap="2x">
      <SectionTitle text="About" />

      <Grid
        alignItems="start"
        columnGap="2x"
        columns={2}
        maxWidth={productInfoPageMaxWidth}
        rowGap="3x"
      >
        <TextField
          labelText="Name"
          value={product.name}
          onTextChange={(name) => {
            setProduct({ ...product, name });
          }}
        />

        <ProductStatus />

        <Stack gap=".5x">
          <Label text="Category" />
          <Flex alignItems="center" height="3.4rem">
            <Typography text={product.category} />
          </Flex>
        </Stack>

        <Dropdown
          items={convertStringArrayToValueLabelArray(
            Object.values(
              isAccessory
                ? productAccessoriesSubCategories
                : productLightSubCategories,
            ),
          )}
          labelText="Sub-Category"
          value={product.subCategory}
          onSelect={(item) => {
            setProduct({ ...product, subCategory: item?.value });
          }}
        />
      </Grid>
    </Stack>
  );
};
