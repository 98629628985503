import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Conditional,
  Flex,
  Stack,
  Table,
  TextField,
  Typography,
} from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { formatDate } from '../../../helpers/formatter';
import { UserReferredItem } from '../../../api/users/users.types';
import {
  useInvalidateFetchUserCache,
  useUpdateUser,
} from '../../../api/users/routes';

const columns: ColumnDef<UserReferredItem>[] = [
  {
    accessorKey: 'dateSec',
    cell: ({
      getValue,
    }: CellContext<UserReferredItem, UserReferredItem['signedUp']>) => {
      return <Typography text={formatDate(getValue())} />;
    },
    header: 'Date',
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'signedUp',
    cell: ({
      getValue,
    }: CellContext<UserReferredItem, UserReferredItem['signedUp']>) => {
      return (
        <Conditional condition={getValue()} Fallback={<Typography text="No" />}>
          <Typography text="Yes" />
        </Conditional>
      );
    },
    header: 'Signed Up',
  },
  {
    accessorKey: 'ordered',
    cell: ({
      getValue,
    }: CellContext<UserReferredItem, UserReferredItem['ordered']>) => {
      return (
        <Conditional condition={getValue()} Fallback={<Typography text="No" />}>
          <Typography text="Yes" />
        </Conditional>
      );
    },
    header: 'Ordered',
  },
];

export const Referrals = ({ currentUser }) => {
  const { referrals, userId } = currentUser;
  const [referralText, setReferralPath] = useState(
    referrals.referralLink || '',
  );

  const changeReferralPath = (e) => {
    setReferralPath(e.target.value);
  };

  const { invalidateFetchUserCache } = useInvalidateFetchUserCache();

  const { isLoading, onUpdateUser } = useUpdateUser({
    onSuccess: async () => {
      await invalidateFetchUserCache();
    },
  });

  const updateReferral = async () => {
    await onUpdateUser({
      newReferralHash: referralText as string,
      userId,
    });
  };

  useEffect(() => {
    setReferralPath(referrals.referralLink);
  }, [referrals]);

  return !userId || !referrals ? null : (
    <Stack gap="5x">
      <Stack gap="2x">
        <Typography text="Link" textStyle="bold" variant="h4" />
        <Flex alignItems="center" width="100%" wrap="wrap">
          <Box marginRight="1rem">
            <Typography color="t1" text="gantri.com/" />
          </Box>
          <Box marginRight="1rem">
            <TextField
              ariaLabel="referral-link"
              className="spacer-right-10"
              maxLength={8}
              name="referral-link"
              placeholder="Enter referral path..."
              value={referralText}
              onChange={changeReferralPath}
            />
          </Box>
          <Box marginRight="1rem">
            <Button
              processing={isLoading}
              text="Update"
              onClick={updateReferral}
            />
          </Box>
        </Flex>
      </Stack>
      <Stack gap="2x">
        <Typography text="Referred" textStyle="bold" variant="h4" />
        <Table columns={columns} data={referrals.referred} />
      </Stack>
    </Stack>
  );
};
