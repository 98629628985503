import axios from 'axios';
import { getCoreApiUrl } from '../../../helpers/auth';
import {
  AddPressRecordArgs,
  AddPressRecordResponse,
} from './add-press-record.types';

export const addPressRecord = (body: AddPressRecordArgs) => {
  return axios
    .post<AddPressRecordResponse>(getCoreApiUrl('press'), body)
    .then(({ data }) => {
      return data;
    });
};
