import { isEqual } from 'lodash';
import { GetVariantCodesFromPartialsProps } from './get-variant-codes-from-partials.types';

export const getVariantCodesFromPartials = (
  props: GetVariantCodesFromPartialsProps,
) => {
  const { skuPartialCodes, variantPartialDetails } = props;

  // set full variant codes when partials are provided
  const newPartialCodes =
    variantPartialDetails.map((variantData) => {
      return variantData.map(({ code }) => {
        return code;
      });
    }) || [];

  const variantCodes = skuPartialCodes
    .map((variantData, variantIndex) => {
      return variantData.find(({ partialCodes }) => {
        return isEqual(partialCodes, newPartialCodes[variantIndex]);
      })?.code;
    })
    .filter(Boolean);

  return variantCodes;
};
