import { Conditional, Stack, Typography } from 'gantri-components';
import { useFormikContext } from 'formik';
import { AddStocksFormData } from '../../../add-stocks-modal.types';
import { SelectedStocksDetails } from '../../../../selected-stocks-details';
import { ConfirmQuantityContentProps } from './confirm-quantity-content.types';
import { SingleStockLevels } from '../../single-stock-levels';
import { pluralize } from '../../../../../../../../../helpers/pluralize';

export const ConfirmQuantityContent = (props: ConfirmQuantityContentProps) => {
  const { stocks } = props;

  const { values } = useFormikContext<AddStocksFormData>();

  const onlyOneStock = stocks.length === 1;
  const [firstStock] = stocks;

  return (
    <Stack gap="4x">
      <SelectedStocksDetails stocks={stocks} />

      <Conditional condition={onlyOneStock}>
        <SingleStockLevels stock={firstStock} />
      </Conditional>

      <Stack gap=".5x">
        <Typography text="Confirm addition" textStyle="bold" />
        <Typography
          htmlText={`Are you sure you want to add <strong>${
            values.stocksToAdd
          } ${pluralize('stock', values.stocksToAdd)}</strong>${pluralize(
            '',
            stocks.length,
            { plural: ' of each SKU' },
          )}?`}
          text="Confirm addition"
        />
      </Stack>
    </Stack>
  );
};
