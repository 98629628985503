import { useState } from 'react';
import { Button, Cell, Icon, useModal } from 'gantri-components';
import { StockMoreButtonProps } from './more-button.types';
import { MoreMenu } from '../../../../components/dropdowns';
import { MoreMenuOption } from '../../../../components/dropdowns/more-menu/more-menu.types';
import { environment } from '../../../../environment';
import {
  orderStatusesMap,
  partStatuses,
  stockStatuses,
  transactionTypesMap,
} from '../../../../constants/options';
import { Stock } from '../../../../api/stocks/stocks.types';
import { useCompleteAllJobs, useResetJobs } from '../../hooks';
import { CancelOrUpdateStockVersionModalAction } from './components/cancel-or-update-stock-version-modal/cancel-or-update-stock-version-modal.types';
import { useInvalidateGetStockCache } from '../../../../api/stocks/routes';
import { CancelOrUpdateStockVersionModal } from './components/cancel-or-update-stock-version-modal';
import { useRestockStock } from '../../../../api/stocks/routes/restock-stock';
import { DiscardStockModal } from './components/discard-stock-modal';
import { useDiscardStock } from '../../../../api/stocks/routes/discard-stock';
import { OverridePartDelaysModal } from './components/override-part-delays-modal';

export const StockMoreButton = (props: StockMoreButtonProps) => {
  const { stock } = props;

  const {
    allowDiscard,
    allowRestock,
    id,
    item,
    orderId,
    productId,
    status,
    totalCompletionJobs,
  } = stock;

  const { completeAllJobs } = useCompleteAllJobs();
  const { resetJobs } = useResetJobs();
  const [
    cancelOrUpdateStockVersionModalAction,
    setCancelOrUpdateStockVersionModalAction,
  ] = useState<CancelOrUpdateStockVersionModalAction>(null);

  const { invalidateGetStockCache } = useInvalidateGetStockCache();

  const onRefreshStock = async () => {
    await invalidateGetStockCache({ stockId: id });
  };

  const [showUpdateVersionModal, hideUpdateVersionModal] = useModal(() => {
    return (
      <CancelOrUpdateStockVersionModal
        action={cancelOrUpdateStockVersionModalAction}
        stock={stock}
        onClose={hideUpdateVersionModal}
        onUpdate={onRefreshStock}
      />
    );
  }, [stock, cancelOrUpdateStockVersionModalAction]);

  const { onRestockStock } = useRestockStock({
    onSuccess: async () => {
      await onRefreshStock();
    },
  });

  const { onDiscardStock } = useDiscardStock({
    onSuccess: async () => {
      await onRefreshStock();
    },
    showLoading: true,
  });

  const [showDiscardModal, hideDiscardModal] = useModal(() => {
    return (
      <DiscardStockModal
        orderId={orderId}
        stockId={id}
        onClose={hideDiscardModal}
        onRefreshStock={onRefreshStock}
      />
    );
  }, [stock]);

  const [showOverridePartDelaysModal, hideOverridePartDelaysModal] =
    useModal(() => {
      return (
        <OverridePartDelaysModal
          stock={stock}
          onClose={hideOverridePartDelaysModal}
          onRefreshStock={onRefreshStock}
        />
      );
    }, [stock]);

  const isIncompleteStatus = ![
    stockStatuses.completed,
    stockStatuses.cancelled,
  ].some((validStatus) => {
    return validStatus === status;
  });

  const jobs = stock?.jobs || [];

  const refundOrderStockRequirementsMessage =
    `Stock must be associated with an order with type "${transactionTypesMap.tradeRefund}" or "${transactionTypesMap.thirdPartyRefund}" and order status "${orderStatusesMap.delivered}" or "${orderStatusesMap.refunded}"` as const;

  const moreMenuOptions: MoreMenuOption<Stock>[] = [
    {
      allowedFor: ['Admin'],
      disabledTooltipProps: {
        description: `Stock must have not status "${stockStatuses.completed}" or "${stockStatuses.cancelled}", must have jobs, and must not be associated with an order.`,
      },
      enabled: isIncompleteStatus && !!jobs.length && !orderId,
      name: 'Cancel',
      onOptionClick: () => {
        setCancelOrUpdateStockVersionModalAction('CANCEL');
        showUpdateVersionModal();
      },
    },
    {
      allowedFor: ['Admin'],
      disabledTooltipProps: {
        description: `Stock must have not status "${stockStatuses.completed}" or "${stockStatuses.cancelled}" and must be associated with a product.`,
      },
      enabled: isIncompleteStatus && !!productId,
      name: 'Update version',
      onOptionClick: () => {
        setCancelOrUpdateStockVersionModalAction('UPDATE');
        showUpdateVersionModal();
      },
    },
    {
      allowedFor: ['Admin'],
      disabledTooltipProps: {
        description: `Stock must have parts with status "${partStatuses.delayedStart}".`,
      },
      enabled: (stock) => {
        return (
          isIncompleteStatus &&
          stock.parts.some(({ status }) => {
            return status === partStatuses.delayedStart;
          })
        );
      },
      name: 'Override part delays',
      onOptionClick: () => {
        showOverridePartDelaysModal();
      },
    },
    {
      allowedFor: !environment.IS_PRODUCTION ? ['Admin'] : [],
      disabledTooltipProps: {
        description: `Stock must have not status "${stockStatuses.completed}" or "${stockStatuses.cancelled}" and must have incomplete jobs.`,
      },
      enabled: isIncompleteStatus && totalCompletionJobs !== '100%',
      name: 'Complete all jobs',
      onOptionClick: async () => {
        await completeAllJobs(id);
        await onRefreshStock();
      },
    },
    {
      disabledTooltipProps: {
        description: `Stock must have status "${stockStatuses.waiting}".`,
      },
      enabled: status === stockStatuses.waiting,
      name: 'Reset jobs',
      onOptionClick: async () => {
        await resetJobs(id, item);
        await onRefreshStock();
      },
    },
    {
      allowedFor: ['Admin'],
      disabledTooltipProps: {
        description: `${refundOrderStockRequirementsMessage}, or have status "${stockStatuses.completed}" or "${stockStatuses.restocked}" and either not be associated with an order or associated an order with status "${orderStatusesMap.readyToShip}".`,
      },
      enabled: !!allowDiscard,
      name: 'Discard',
      onOptionClick: async () => {
        if (orderId) {
          showDiscardModal();
        } else {
          await onDiscardStock({ id });
        }
      },
    },
    {
      allowedFor: ['Admin'],
      disabledTooltipProps: {
        description: `${refundOrderStockRequirementsMessage}, or have status "${stockStatuses.discarded}" and not be associated with an order.`,
      },
      enabled: !!allowRestock,
      name: 'Restock',
      onOptionClick: async () => {
        await onRestockStock({ id });
      },
    },
  ];

  return (
    <MoreMenu
      data={stock}
      options={moreMenuOptions}
      Toggle={({ onClick }) => {
        return (
          <>
            <Cell hidden={{ lg: false, sm: true }}>
              <Button
                icon={<Icon color="link" name="arrows:arrow_chevron_down" />}
                text="Actions"
                variant="secondary"
                onClick={onClick}
              />
            </Cell>
            <Cell hidden={{ lg: true, sm: false }}>
              <Icon name="ui-control:three_dots_horizontal" onClick={onClick} />
            </Cell>
          </>
        );
      }}
    />
  );
};
