import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Flex, Typography, useModal } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import { useMoreMenu } from '../../../../hooks/use-more-menu';
import { PressRecord } from '../../../../api/press/press.types';
import { formatDateWithoutTimezone } from '../../../../helpers/formatter';
import { PressRecordModal } from '../../components/press-record-modal';
import { MoreMenuOption } from '../../../../components/dropdowns/more-menu/more-menu.types';
import { pressRecordModalActions } from '../../components/press-record-modal/press-record-modal.constants';
import { pressRecordModalAtoms } from '../../components/press-record-modal/press-record-modal.atoms';
import { MEDIUM_FORMAT } from '../../../../constants/dates';

export const useGetPressPageColumns = () => {
  const setPressRecord = useSetRecoilState(pressRecordModalAtoms.pressRecord);
  const setModalAction = useSetRecoilState(pressRecordModalAtoms.modalAction);

  const [showHandlePressRecordModal, hideHandlePressRecordModal] =
    useModal(() => {
      return <PressRecordModal onClose={hideHandlePressRecordModal} />;
    }, []);

  const PressRecordMenuCell = ({ row }: CellContext<PressRecord, never>) => {
    const options: MoreMenuOption<PressRecord>[] = [
      {
        allowedFor: ['Admin'],
        disabledTooltipProps: {
          description: 'Cannot perform this action while editing.',
        },
        enabled: true,
        name: 'Edit',
        onOptionClick: (data) => {
          setModalAction(pressRecordModalActions.update);
          setPressRecord(data);
          showHandlePressRecordModal();
        },
      },
      {
        allowedFor: ['Admin'],
        disabledTooltipProps: {
          description: 'Cannot perform this action while editing.',
        },
        enabled: true,
        name: 'Delete',
        onOptionClick: (data) => {
          setModalAction(pressRecordModalActions.delete);
          setPressRecord(data);
          showHandlePressRecordModal();
        },
      },
    ];

    const { MoreMenu } = useMoreMenu<PressRecord>({
      data: row.original,
      options,
    });

    return (
      <Flex alignItems="center" gap="x" justifyContent="flex-end">
        <MoreMenu />
      </Flex>
    );
  };

  const columns: ColumnDef<PressRecord>[] = [
    {
      accessorKey: 'title',
      header: 'Title',
      size: 400,
    },
    {
      accessorKey: 'source',
      header: 'Source',
      size: 200,
    },
    {
      accessorKey: 'publishDate',
      cell: ({
        getValue,
        row,
      }: CellContext<PressRecord, PressRecord['publishDate']>) => {
        return (
          <Typography
            text={formatDateWithoutTimezone(
              getValue(),
              row.original.type === 'Award' ? 'yyyy' : MEDIUM_FORMAT,
            )}
          />
        );
      },
      header: 'Date',
      size: 100,
    },
    {
      accessorKey: 'type',
      header: 'Type',
      size: 100,
    },
    {
      accessorKey: 'link',
      cell: ({ getValue }: CellContext<PressRecord, PressRecord['link']>) => {
        const link = getValue();

        return <StyledAnchor href={link} target="_blank" text={link} />;
      },
      header: 'Link',
    },
    {
      cell: PressRecordMenuCell,
      id: 'menu',
      maxSize: 30,
    },
  ];

  return columns;
};
