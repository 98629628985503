import pick from 'lodash/pick';
import { ConfirmDeliveryFormDataDef } from './confirm-delivery-modal.types';

export const convertFormDataToShipmentData = (
  values: ConfirmDeliveryFormDataDef,
) => {
  return {
    photos: values.photos || [],
    ...pick(values, [
      'notes',
      'deliveredAt',
      'discardedQuantity',
      'expectedQuantity',
      'quantity',
    ]),
  };
};
