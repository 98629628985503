import { Typography, Conditional } from 'gantri-components';
import { ZoomableThumbnail } from '../../../../../../../../../../../../../../components';
import { BulkUploadTableScalePhotoCellProps } from './bulk-upload-table-scale-photo-cell.types';

export const BulkUploadTableScalePhotoCell = (
  props: BulkUploadTableScalePhotoCellProps,
) => {
  const { scalePhoto } = props;

  return (
    <Conditional
      condition={!!scalePhoto?.fileUrl}
      Fallback={<Typography text="-" />}
    >
      <ZoomableThumbnail
        heading={scalePhoto?.fileName}
        size={36}
        source={scalePhoto?.fileBlob ? 'absolute' : 'dynamic'}
        src={scalePhoto?.fileUrl}
      />
    </Conditional>
  );
};
