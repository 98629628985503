import { Link } from 'react-router-dom';
import { Icon, Typography, Table } from 'gantri-components';
import { useEffect, useState } from 'react';
import { PageWithTable } from '../../components/layout';
import { useGetDesignersColumns } from './helpers/get-designers-columns';
import { FiltersFetchRequestArgs } from '../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import {
  DesignerData,
  FetchPaginatedDesignersArgs,
} from '../../api/designers/routes/fetch-paginated-designers/fetch-paginated-designers.types';
import { useTableFilters } from '../../components/common/table/hooks/use-table-filters';
import { DesignersFilter } from './components/designers-filter';
import { DesignersSortingField } from './designers.types';
import {
  designersPageAtoms,
  designersFiltersDefaults,
  pageName,
} from './components/designers-filter/designers-filter.atoms';
import { useFetchPaginatedDesigners } from '../../api/designers/routes/fetch-paginated-designers/fetch-paginated-designers.query';
import { fetchPaginatedDesigners } from '../../api/designers/routes';
import { useGetInvalidateQueryCache } from '../../hooks/use-fetch/use-query-fetch';

export const Designers = () => {
  const [filters, setFilters] =
    useState<
      FiltersFetchRequestArgs<
        FetchPaginatedDesignersArgs,
        DesignersSortingField
      >
    >(null);

  const {
    currentPage,
    filtersProps,
    handleFetchRequest,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    DesignerData,
    FetchPaginatedDesignersArgs,
    DesignersSortingField
  >({
    fetchRequest: setFilters,
    filtersContent: <DesignersFilter />,
    modalFiltersDetails: [
      {
        atom: designersPageAtoms.filters.active,
        defaultValue: designersFiltersDefaults.filters.active,
        payloadKey: 'active',
      },
    ],
    pageName,
    searchFilterDetails: {
      atom: designersPageAtoms.search,
    },
    sortByFilterDetails: {
      atom: designersPageAtoms.sortBy,
      defaultValue: designersFiltersDefaults.sortBy,
    },
    sortOptions: [
      { bidirectional: true, label: 'Name', sortingField: 'name' },
      {
        bidirectional: true,
        label: 'Designs',
        sortingField: 'designCount',
      },
      {
        bidirectional: true,
        label: 'Products',
        sortingField: 'productCount',
      },
    ],
  });

  const columns = useGetDesignersColumns({
    fetchDesigners: async () => {
      await invalidateQueryCache();
      await handleFetchRequest({ page: currentPage });
    },
  });

  const response = useFetchPaginatedDesigners({
    filters,
  });

  const invalidateQueryCache = useGetInvalidateQueryCache(
    fetchPaginatedDesigners,
  );

  useEffect(() => {
    const { designers, isLoading, resultsCount } = response;

    if (!isLoading) {
      setRecords(designers ?? []);
      setTotalCount(resultsCount ?? 0);
    }
  }, [response]);

  return (
    <PageWithTable pageTitle="Designers">
      <PageWithTable.Header title="Designers">
        <Link to="/invites">
          <Typography
            color="link"
            icon={<Icon color="link" name="arrows:arrow_right" top="-3px" />}
            iconPosition="right"
            text="View invites"
            textStyle="bold"
          />
        </Link>
      </PageWithTable.Header>
      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          getRowProps={({ original }) => {
            return { 'data-designer-id': original.id };
          }}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          sorting={sortProps}
          stickyLastColumn
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
