import { Modal, useModal } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { publishDraftModalAtoms } from './publish-draft-modal.atoms';
import { getHeaderText } from './helpers/get-header-text';
import { PublishDraftModalFooter } from './components/publish-draft-modal-footer';
import {
  PublishDraftModalProps,
  PublishDraftModalStep,
} from './publish-draft-modal.types';
import { PublishDraftModalContent } from './components/publish-draft-modal-content';
import { useResetRecoilAtomsOnMount } from '../../../../../../../../hooks/use-reset-recoil-atoms-on-mount';
import { ExitWarningModal } from '../../../../../../../../components/common/exit-warning-modal';
import { useCheckForMissingItems } from './hooks/use-check-for-missing-items';

export const PublishDraftModal = (props: PublishDraftModalProps) => {
  const { onClose, product } = props;

  const [step, setStep] = useRecoilState(publishDraftModalAtoms.step);

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    publishDraftModalAtoms,
  );

  const [showExitWarningModal, hideExitWarningModal] = useModal(() => {
    return (
      <ExitWarningModal
        header="Exit Publish Draft"
        message="Are you sure you want to exit?"
        onClose={hideExitWarningModal}
        onConfirm={onClose}
      />
    );
  }, []);

  const { isProductMissingItems } = useCheckForMissingItems();

  useEffect(() => {
    const startingStep: PublishDraftModalStep = isProductMissingItems
      ? 'MISSING_ITEMS'
      : 'DRAFT_DETAILS';

    setStep(startingStep);
  }, []);

  return (
    <ResetAtomsWrapper>
      <Modal
        footer={
          <PublishDraftModalFooter
            product={product}
            showExitModal={showExitWarningModal}
            onCloseModal={onClose}
          />
        }
        header={getHeaderText({ step })}
        width={{ lg: '62rem', md: '100%' }}
        onClose={showExitWarningModal}
      >
        <PublishDraftModalContent product={product} />
      </Modal>
    </ResetAtomsWrapper>
  );
};
