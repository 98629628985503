import { convertStringArrayToValueLabelArray } from '../helpers/formatter';

export const payoutStatuses = convertStringArrayToValueLabelArray([
  'Unpaid',
  'Paid',
  'Cancelled',
]);

export const paymentMethods = convertStringArrayToValueLabelArray([
  'ACH',
  'PayPal',
]);
