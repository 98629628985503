import { Button, Conditional, Flex } from 'gantri-components';
import { MouseEvent } from 'react';
import { jobsApi } from '../../../../../../../../api';
import { JobMenu } from '../../../../../../../../components/common/jobMenu/jobMenu';
import { jobStatuses } from '../../../../../../../../constants/options';
import { useNotification } from '../../../../../../../../hooks/useNotification';
import { useJobComplete, useJobStart } from '../../../../../../../jobs/hooks';
import { SearchPartSummaryJobActionCellProps } from './search-part-summary-job-action-cell.types';

export const SearchPartSummaryJobActionCell = (
  props: SearchPartSummaryJobActionCellProps,
) => {
  const { clearPartSearch, getPartDetails, job, onRefresh } = props;

  const { hideLoading, notify, notifyAxiosError, showLoading } =
    useNotification();

  const onJobHandled = async (jobId: number): Promise<void> => {
    try {
      showLoading();

      const { job } = await jobsApi.getJobDetails(jobId);
      const isInProgress = jobStatuses.inProgress === job.status;
      const isCompleted = jobStatuses.completed === job.status;

      if (isInProgress) {
        await getPartDetails();
        notify('Job has been started successfully.');
      } else {
        if (isCompleted) {
          notify('Job has been completed successfully.');
        }

        clearPartSearch({ clearCurrentResults: true });
      }
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to get latest part details.',
      });
    } finally {
      hideLoading();
    }
  };

  const startJob = useJobStart({
    onStartEnd: onJobHandled,
  });

  const completeJob = useJobComplete({
    onCompleteEnd: onJobHandled,
  });

  const isReady = job.status === jobStatuses.ready;
  const isInProgress = job.status === jobStatuses.inProgress;

  const handleActionButtonClick = async (e: MouseEvent<HTMLButtonElement>) => {
    // prevents jobs details panel from opening
    e.stopPropagation();

    if (isInProgress) {
      await completeJob(job.id);
    } else {
      await startJob(job.id);
    }
  };

  return (
    <Flex alignItems="center" gap="2x" justifyContent="flex-end">
      <Conditional condition={isReady || isInProgress}>
        <Button
          minWidth="8rem"
          text={isInProgress ? 'Complete' : 'Start'}
          onClick={handleActionButtonClick}
        />
      </Conditional>
      <JobMenu job={job} onRefresh={onRefresh} />
    </Flex>
  );
};
