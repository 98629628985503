import * as yup from 'yup';
import { PressRecord } from '../../../../api/press/press.types';
import { YupShape } from '../../../../types/formik-schema.types';

const requiredText = 'Required';

export const pressRecordModalSchema = yup
  .object()
  .shape<YupShape<PressRecord>>({
    id: yup.number(),
    link: yup
      .string()
      .matches(/^https?:\/\/\w+\.\w+/, 'Link must be a valid URL.')
      .required(requiredText),
    publishDate: yup.string().required(requiredText),
    source: yup.string().required(requiredText),
    title: yup.string().required(requiredText),
    type: yup.string().required(requiredText),
  });

export const pressRecordModalInitialValues: PressRecord = {
  id: undefined,
  link: undefined,
  publishDate: undefined,
  source: undefined,
  title: undefined,
  type: undefined,
};
