import {
  ColorPickerItem,
  Conditional,
  Flex,
  Grid,
  Icon,
  Stack,
  Typography,
} from 'gantri-components';
import { FC } from 'react';
import { ProductColorCode } from 'gantri-components/dist/styles/theme';
import { CellPartNameProps } from './cell-part-name.types';
import { ZoomableThumbnail } from '../../../../../../components';
import { NotApplicableText } from '../../../../../../components/common/not-applicable-text';
import { unpaintedColor } from '../../../../../../constants/colors';

export const CellPartName: FC<CellPartNameProps> = (props) => {
  const { getValue, row } = props;

  const { color, material, name, thumbnail } = row.original;

  const partName = getValue();

  return (
    <Conditional condition={!!partName}>
      <Grid alignItems="center" columns="6.4rem 1fr" gap="s1">
        <Conditional condition={row.depth === 0} Fallback={<span />}>
          <ZoomableThumbnail
            heading={name}
            size={64}
            source="absolute"
            src={thumbnail}
          />
        </Conditional>

        <Stack alignContent="center" gap="0.8rem">
          <Typography text={partName} variant="h5" />

          <Grid alignItems="center" columns="max-content 1fr" gap="s1">
            <Flex alignItems="center" gap="0.6rem">
              <Conditional condition={!!color}>
                <Conditional
                  condition={color !== unpaintedColor.code}
                  Fallback={<Icon name="work:no_entry" />}
                >
                  <ColorPickerItem
                    color={{
                      code: color as ProductColorCode,
                      name: color,
                    }}
                    size="1.6rem"
                  />
                </Conditional>
              </Conditional>
              <Conditional condition={!!color} Fallback={<NotApplicableText />}>
                <Typography color="t1" text={color} transform="capitalize" />
              </Conditional>
            </Flex>

            <Typography color="t2" minWidth="10rem" text={material} />
          </Grid>
        </Stack>
      </Grid>
    </Conditional>
  );
};
