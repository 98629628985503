import { Button } from 'gantri-components';
import { useFailJob } from '../../../../../../api/jobs/routes';
import { DuplicateType } from '../../../../../../api/jobs/routes/fail-qa-job/fail-qa-job.types';
import { getFailJobReasonArg } from '../../helpers/get-fail-job-reason-arg';
import { NextStep } from '../job-checklist/job-checklist.types';
import { submitRecommendedNextStepButtonDefaultProps } from './submit-recommended-next-step-button.presets';
import { SubmitRecommendedNextStepButtonProps } from './submit-recommended-next-step-button.types';

export const SubmitRecommendedNextStepButton = (
  props: SubmitRecommendedNextStepButtonProps,
) => {
  const {
    checklist,
    jobId,
    nextStepDetails,
    onFailCallback,
    partId,
    selectedNextStep,
    setDuplicatedJobs,
    setNewParts,
    setStep,
    setUpdateOnClose,
    size,
  } = props;

  const getDuplicateType = (selectedNextStep: NextStep): DuplicateType => {
    switch (selectedNextStep) {
      case 'JOB_FAILED_REFINISH_PAINT_ONLY':
        return 'Finish_Paint';

      case 'JOB_FAILED_REFINISH_TARGETED_REWORK':
        return 'Finish_Targeted';

      case 'JOB_FAILED_REFINISH_COMPLETE_REWORK':
        return 'Finish_Complete';

      case 'JOB_FAILED_RESTART':
      default:
        return 'Restart';
    }
  };

  const { isLoading, onFailJob } = useFailJob({
    onSuccess: async ({ duplicatedJobs, newParts }) => {
      await onFailCallback?.(selectedNextStep);

      setStep(selectedNextStep);
      setUpdateOnClose(true);
      setDuplicatedJobs(duplicatedJobs);
      setNewParts(newParts);
    },
  });

  const handleFailJob = async () => {
    const reason = getFailJobReasonArg(checklist);
    const duplicateType = getDuplicateType(selectedNextStep);

    await onFailJob({
      duplicateType,
      jobId,
      partsData:
        selectedNextStep === 'JOB_FAILED_RESTART'
          ? [
              {
                details: nextStepDetails,
                id: partId,
                type: 'Restart',
              },
            ]
          : [],
      reason,
    });
  };

  return (
    <Button
      disabled={selectedNextStep === 'JOB_FAILED_RESTART' && !nextStepDetails}
      processing={isLoading}
      size={size}
      text="Next"
      onClick={handleFailJob}
    />
  );
};

SubmitRecommendedNextStepButton.defaultProps =
  submitRecommendedNextStepButtonDefaultProps;
