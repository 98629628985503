import { useFormikContext } from 'formik';
import { Button } from 'gantri-components';
import { GrowthMultipliers } from '../../../../../../../../api/stocks/routes/fetch-stock-summary/fetch-stock-summary.types';
import { UpdateGrowthMultiplierModalFooterProps } from './update-growth-multiplier-modal-footer.types';

export const UpdateGrowthMultiplierModalFooter = (
  props: UpdateGrowthMultiplierModalFooterProps,
) => {
  const { onClose, processing } = props;

  const { isValid } = useFormikContext<GrowthMultipliers>();

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={onClose}
      />
      <Button
        disabled={!isValid}
        processing={processing}
        size="large"
        text="Save"
        type="submit"
      />
    </>
  );
};
