import { Conditional, Typography } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { StyledAnchor } from '../../../../../components/common/styled-anchor';
import routePaths from '../../../../../config/route-paths';
import { InventoryRowData } from './stock-inventories-table.types';
import { getIsClickableIfValue } from '../../../../../helpers/get-is-clickable-if-value';

export const getGetStockInventoryColumns = ({
  totalCost,
}: {
  totalCost: string;
}) => {
  const columns: ColumnDef<InventoryRowData>[] = [
    {
      accessorKey: 'id',
      cell: ({
        getValue,
      }: CellContext<InventoryRowData, InventoryRowData['id']>) => {
        const id = getValue();

        return (
          <Conditional condition={!!id}>
            <StyledAnchor
              decoration="underline"
              text={`#${id}`}
              to={`${routePaths.inventories}/${id}`}
            />
          </Conditional>
        );
      },
      header: 'ID',
      meta: {
        getIsClickable: getIsClickableIfValue,
      },
      size: 80,
    },
    {
      accessorKey: 'name',
      footer: 'TOTAL',
      header: 'Name',
      size: 200,
    },
    {
      accessorKey: 'vendor',
      cell: ({
        getValue,
      }: CellContext<InventoryRowData, InventoryRowData['vendor']>) => {
        return <Typography text={getValue()?.name} />;
      },
      header: 'Vendor',
      size: 200,
    },
    {
      accessorKey: 'type',
      header: 'Type',
      size: 110,
    },
    {
      accessorKey: 'description',
      header: 'Description',
      size: 300,
    },
    {
      accessorKey: 'needFixed',
      header: 'Required',
      size: 120,
    },
    {
      accessorKey: 'totalCost',
      footer: totalCost,
      header: 'Total Cost',
      size: 100,
    },
    {
      accessorKey: 'stockFixed',
      header: 'In Stock',
      size: 120,
    },
  ];

  return columns;
};
