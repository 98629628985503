import { atom } from 'recoil';
import { BatchDropdownItem } from './components/step-update-batch/update-batch-content/components/batch-search-render-item/batch-search-render-item.types';
import { BatchUpdateMethod } from './components/step-update-batch/update-batch-content/update-batch-content.types';
import { UpdatePartBatchModalStep } from './update-part-batch-modal.types';

export const atomKeyPrefix = 'UPDATE_PART_BATCH_MODAL';

export const updatePartBatchModalAtoms = {
  activeBatches: atom<BatchDropdownItem[]>({
    default: [],
    key: `${atomKeyPrefix}-available-batches`,
  }),
  batchUpdateMethod: atom<BatchUpdateMethod>({
    default: null,
    key: `${atomKeyPrefix}-update-method`,
  }),
  newBatchId: atom<number>({
    default: null,
    key: `${atomKeyPrefix}-new-batch-id`,
  }),
  step: atom<UpdatePartBatchModalStep>({
    default: 'UPDATE_BATCH',
    key: `${atomKeyPrefix}-step`,
  }),
  updateOnClose: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
};
