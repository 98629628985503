import { useEffect, useState } from 'react';
import { Dropdown, Grid } from 'gantri-components';
import { TimePickerProps } from './time-picker.types';
import {
  hourOptions,
  minuteOptions,
  meridiemOptions,
} from './time-picker.constants';

export const TimePicker = (props: TimePickerProps) => {
  const { hour, meridiem, minute, onChange } = props;

  const [innerHour, setInnerHour] = useState(hour);
  const [innerMinute, setInnerMinute] = useState(minute);
  const [innerMeridiem, setInnerMeridiem] = useState(meridiem);

  useEffect(() => {
    setInnerHour(hour || hourOptions[0]?.value);
  }, [hour]);

  useEffect(() => {
    setInnerMinute(minute || minuteOptions[0]?.value);
  }, [minute]);

  useEffect(() => {
    setInnerMeridiem(meridiem || meridiemOptions[0]?.value);
  }, [meridiem]);

  useEffect(() => {
    onChange({
      formatted: `${innerHour}:${innerMinute} ${innerMeridiem}`,
      hour: innerHour,
      meridiem: innerMeridiem,
      minute: innerMinute,
    });
  }, [innerHour, innerMinute, innerMeridiem]);

  return (
    <Grid columns="repeat(3, 7rem)" gap=".5x">
      <Dropdown
        autoSelectFirst
        items={hourOptions}
        value={innerHour}
        onSelect={(item) => {
          return setInnerHour(item?.value);
        }}
      />

      <Dropdown
        autoSelectFirst
        items={minuteOptions}
        value={innerMinute}
        onSelect={(item) => {
          return setInnerMinute(item?.value);
        }}
      />

      <Dropdown
        autoSelectFirst
        items={meridiemOptions}
        value={innerMeridiem}
        onSelect={(item) => {
          return setInnerMeridiem(item?.value);
        }}
      />
    </Grid>
  );
};
