import { Conditional, Icon, Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { cancelOrUpdateStockVersionModalNewStockAtom } from '../../../cancel-or-update-stock-version-modal.atoms';
import { UpdateSuccessContentProps } from './update-success-content.types';

export const UpdateSuccessContent = (props: UpdateSuccessContentProps) => {
  const { stock } = props;
  const newStock = useRecoilValue(cancelOrUpdateStockVersionModalNewStockAtom);

  return (
    <Stack
      gap="0.8rem"
      justifyItems="center"
      justifySelf="center"
      maxWidth="48.8rem"
      verticalPadding="0.8rem"
    >
      <Icon
        color="success"
        name="ui-control:check_mark_circle_filled_24"
        size="2.4rem"
      />
      <div>
        <Typography
          align="center"
          color="success"
          text={`Stock #${stock.id} was successfully cancelled.`}
        />
        <Conditional condition={!!newStock?.id}>
          <Typography
            align="center"
            color="success"
            text={`Stock #${
              newStock?.id
            } has been created with newest version: ${
              newStock?.version || '-'
            }.`}
          />
        </Conditional>
      </div>
      <Conditional condition={!!stock.location}>
        <Typography
          align="center"
          htmlText={`Next, discard old parts from the following location: <strong>${stock.location?.name}</strong>.`}
          marginTop="4rem"
        />
      </Conditional>
    </Stack>
  );
};
