import { Cell, media, Stack } from 'gantri-components';
import styled, { css } from 'styled-components';
import Thumbnail from '../../../../../../components/common/thumbnail/thumbnail';

export const StyledStack = styled(Stack)`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.surfaces.monochrome.t1};
    `;
  }}
`;

export const StyledThumbnail = styled(Thumbnail)`
  background-size: contain;
`;

export const StyledClickableThumbnail = styled(StyledThumbnail)`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const StyledThumbnailCell = styled(Cell)`
  height: 100%;
  width: 100%;

  ${media.greaterThan('sm')`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `}
  ${media.lessThan('sm')`
    min-height: 40rem;
  `}
`;
