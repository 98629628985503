import {
  Conditional,
  FormikInput,
  TextField,
  Typography,
} from 'gantri-components';
import { formatAsLocaleNumber } from '../../../../../../../../../../../../helpers/formatter';
import { useIsEditingPartRowJobsDataForm } from '../../../../../../hooks/use-is-editing-part-row';
import { PartJobBlocksPrintDurationProps } from './part-job-blocks-print-duration.types';

export const PartJobBlocksPrintDuration = (
  props: PartJobBlocksPrintDurationProps,
) => {
  const { estimatedPrintDuration, index, printBlock, replace } = props;

  const isEditingPartRow = useIsEditingPartRowJobsDataForm();

  return (
    <Conditional
      condition={isEditingPartRow}
      Fallback={
        <Typography
          text={
            estimatedPrintDuration
              ? `${formatAsLocaleNumber(estimatedPrintDuration)}m`
              : '-'
          }
        />
      }
    >
      <FormikInput
        Field={
          <TextField
            placeholder="Input duration"
            rightIcon={<Typography color="t2" text="m" />}
            type="number"
            onTextChange={(estimatedPrintDuration) => {
              return replace(index, {
                ...printBlock,
                estimatedPrintDuration: estimatedPrintDuration
                  ? Number(estimatedPrintDuration)
                  : undefined,
              });
            }}
          />
        }
        fieldVariant="standard"
        name={`printBlock[${index}].estimatedPrintDuration`}
        required
      />
    </Conditional>
  );
};
