import {
  Cell,
  Conditional,
  Flex,
  Grid,
  SearchField,
  Stack,
  TextArea,
  TextField,
  Typography,
  useThemeDefaults,
} from 'gantri-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  convertEcurrencyToCurrency,
  convertStringArrayToValueLabelArray,
  formatByType,
} from '../../../../../../helpers/formatter';
import {
  allCompatibleWithBulbs,
  bulbOptions,
  productInfoPageMaxWidth,
} from '../../info.constants';
import { productAtoms } from '../../../../product.atoms';
import {
  CompatibleWithBulb,
  Specifications,
} from '../../../../../../api/products/products.types';
import { SectionTitle } from '../../../../../../components/layout/section-title';
import { Label } from '../../../../../../components/label';

export const InfoTabSpecifications = () => {
  const [product, setProduct] = useRecoilState(productAtoms.product);
  const isAccessory = useRecoilValue(productAtoms.isAccessory);
  const isPendantLight = useRecoilValue(productAtoms.isPendantLight);
  const isWallLight = useRecoilValue(productAtoms.isWallLight);
  const isWallSconce = useRecoilValue(productAtoms.isWallSconce);

  const themeDefaults = useThemeDefaults();

  const specifications = product?.specifications;

  const productId = product?.id;

  const onUpdateSpecifications = <Key extends keyof Specifications>(args: {
    key: Key;
    value: Specifications[Key];
  }) => {
    const { key, value } = args;

    setProduct({
      ...product,
      specifications: {
        ...specifications,
        [key]: value,
      },
    });
  };

  return (
    <Stack gap="2x">
      <SectionTitle text="Specifications" />

      <Grid
        columnGap="2x"
        columns={2}
        maxWidth={productInfoPageMaxWidth}
        rowGap="3x"
      >
        <Cell width={2}>
          <Stack gap=".5x">
            <Label text="Product ID" />
            <Typography text={productId} />
          </Stack>
        </Cell>

        <TextField
          labelText="Price $"
          placeholder="Input price"
          type="number"
          value={
            specifications?.price
              ? convertEcurrencyToCurrency(specifications?.price).toString()
              : ''
          }
          onTextChange={(price) => {
            onUpdateSpecifications({
              key: 'price',
              value: formatByType(price, 'ecurrency'),
            });
          }}
        />

        <Conditional condition={!isAccessory}>
          <TextField
            labelText="Manufacturer Price $"
            type="number"
            value={
              specifications?.manufacturerPrice
                ? convertEcurrencyToCurrency(
                    specifications?.manufacturerPrice,
                  ).toString()
                : ''
            }
            onTextChange={(manufacturerPrice) => {
              onUpdateSpecifications({
                key: 'manufacturerPrice',
                value: formatByType(manufacturerPrice, 'ecurrency'),
              });
            }}
          />
        </Conditional>

        <Conditional condition={!isAccessory}>
          <Stack gap=".5x">
            <Label text="Royalty" />
            <TextField
              debounce={300}
              rightIcon={<Typography color="t2" text="%" />}
              type="number"
              value={String(specifications?.royalty || 5)}
              onTextChange={(royalty) => {
                onUpdateSpecifications({
                  key: 'royalty',
                  value: Number(royalty || 5),
                });
              }}
            />
          </Stack>
        </Conditional>

        <Cell width={2}>
          <TextArea
            labelText="Materials"
            minRows={3}
            placeholder="Input materials"
            style={{ resize: 'vertical' }}
            value={specifications?.material || ''}
            onTextChange={(material) => {
              onUpdateSpecifications({ key: 'material', value: material });
            }}
          />
        </Cell>

        <Conditional condition={!isAccessory}>
          <Cell width={2}>
            <Stack gap=".5x">
              <Flex alignItems="center">
                <Label text="Dimensions" />
                &nbsp;
                <Typography text="(inches)" />
              </Flex>

              <Grid columnGap="2x" columns={3} rowGap="3x">
                <TextField
                  rightIcon={<Typography color="t2" text="H" />}
                  type="number"
                  value={String(specifications?.dimensions?.height || 0)}
                  onTextChange={(height) => {
                    onUpdateSpecifications({
                      key: 'dimensions',
                      value: {
                        ...specifications?.dimensions,
                        height: Number(height) || 0,
                      },
                    });
                  }}
                />

                <TextField
                  rightIcon={<Typography color="t2" text="W" />}
                  type="number"
                  value={String(specifications?.dimensions?.width || 0)}
                  onTextChange={(width) => {
                    onUpdateSpecifications({
                      key: 'dimensions',
                      value: {
                        ...specifications?.dimensions,
                        width: Number(width) || 0,
                      },
                    });
                  }}
                />

                <TextField
                  rightIcon={<Typography color="t2" text="D" />}
                  type="number"
                  value={String(specifications?.dimensions?.depth || 0)}
                  onTextChange={(depth) => {
                    onUpdateSpecifications({
                      key: 'dimensions',
                      value: {
                        ...specifications?.dimensions,
                        depth: Number(depth) || 0,
                      },
                    });
                  }}
                />
              </Grid>
            </Stack>
          </Cell>

          <Cell width={2}>
            <Stack gap=".5x">
              <Conditional
                condition={isWallLight || isWallSconce}
                Fallback={
                  <>
                    <Flex alignItems="baseline">
                      <Label text="Footprint" />
                      &nbsp;
                      <Typography text="(inches)" />
                    </Flex>

                    <Grid columnGap="2x" columns={2} rowGap="3x">
                      <TextField
                        rightIcon={<Typography color="t2" text="W" />}
                        type="number"
                        value={String(specifications?.footPrint?.width || 0)}
                        onTextChange={(width) => {
                          onUpdateSpecifications({
                            key: 'footPrint',
                            value: {
                              ...specifications?.footPrint,
                              width: Number(width) || 0,
                            },
                          });
                        }}
                      />

                      <TextField
                        rightIcon={<Typography color="t2" text="D" />}
                        type="number"
                        value={String(specifications?.footPrint?.depth || 0)}
                        onTextChange={(depth) => {
                          onUpdateSpecifications({
                            key: 'footPrint',
                            value: {
                              ...specifications?.footPrint,
                              depth: Number(depth) || 0,
                            },
                          });
                        }}
                      />
                    </Grid>
                  </>
                }
              >
                <Flex alignItems="baseline">
                  <Label text="Backplate" />
                  &nbsp;
                  <Typography text="(inches)" />
                </Flex>

                <Grid columnGap="2x" columns={2} rowGap="3x">
                  <TextField
                    rightIcon={<Typography color="t2" text="W" />}
                    type="number"
                    value={String(specifications?.backplate?.width || 0)}
                    onTextChange={(width) => {
                      onUpdateSpecifications({
                        key: 'backplate',
                        value: {
                          ...specifications?.backplate,
                          width: Number(width) || 0,
                        },
                      });
                    }}
                  />

                  <TextField
                    rightIcon={<Typography color="t2" text="H" />}
                    type="number"
                    value={String(specifications?.backplate?.height || 0)}
                    onTextChange={(height) => {
                      onUpdateSpecifications({
                        key: 'backplate',
                        value: {
                          ...specifications?.backplate,
                          height: Number(height) || 0,
                        },
                      });
                    }}
                  />
                </Grid>
              </Conditional>
            </Stack>
          </Cell>

          <Stack gap=".5x">
            <Flex alignItems="baseline">
              <Label text="Weight" />
              &nbsp;
              <Typography text="(pounds)" />
            </Flex>

            <TextField
              type="number"
              value={String(specifications?.weight || 0)}
              onTextChange={(weight) => {
                onUpdateSpecifications({
                  key: 'weight',
                  value: weight,
                });
              }}
            />
          </Stack>

          <Conditional condition={!isPendantLight && !isWallSconce}>
            <Stack gap=".5x">
              <Flex alignItems="baseline">
                <Label text="Cable length" />
                &nbsp;
                <Typography text="(inches)" />
              </Flex>

              <TextField
                type="number"
                value={specifications?.cableLength}
                onTextChange={(cableLength) => {
                  onUpdateSpecifications({
                    key: 'cableLength',
                    value: cableLength,
                  });
                }}
              />
            </Stack>
          </Conditional>

          <SearchField
            items={convertStringArrayToValueLabelArray([...bulbOptions])}
            labelText="Bulb"
            placeholder="Please select"
            searchable
            value={specifications?.bulb}
            onSelect={(item) => {
              onUpdateSpecifications({
                key: 'bulb',
                value: item?.value,
              });
            }}
          />

          <Cell width={2}>
            {/* Ensure "Compatible with" is always on its own row */}
            <Grid
              columnGap="2x"
              columns={2}
              maxWidth={productInfoPageMaxWidth}
              rowGap="3x"
            >
              <Stack gap=".5x">
                <Label text="Compatible with" />
                <SearchField
                  enableSelectedItemsList
                  items={convertStringArrayToValueLabelArray([
                    ...allCompatibleWithBulbs,
                  ])}
                  keepOpenAfterSelection
                  placeholder={`${
                    specifications?.compatibleWith?.length || 0
                  } selected`}
                  selectedItemsList={specifications?.compatibleWith || []}
                  size={themeDefaults.searchField.size}
                  onChange={({ target }) => {
                    const value =
                      (target.value as unknown as CompatibleWithBulb[]) || [];

                    onUpdateSpecifications({
                      key: 'compatibleWith',
                      value,
                    });
                  }}
                />
              </Stack>
            </Grid>
          </Cell>
        </Conditional>
      </Grid>
    </Stack>
  );
};
