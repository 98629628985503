import { RestartOrCancelPartModalStep } from '../../restart-or-cancel-part-modal.types';

export const getHeaderText = (args: {
  partId: number;
  step: RestartOrCancelPartModalStep;
}) => {
  const { partId, step } = args;

  switch (step) {
    case 'CONFIRM_CANCEL':
      return `Cancel Part #${partId}`;

    case 'RESTART_REASON':
    case 'CONFIRM_RESTART':
      return `Re-start Part #${partId}`;

    default:
      return '';
  }
};
