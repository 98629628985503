import { Cell, FileUploader, HandleDeleteFile } from 'gantri-components';
import * as React from 'react';
import { useFormikContext } from 'formik';
import { Shipment } from '../../../../../../../../../../../../../../api/inventory-purchases/inventory-purchases.types';
import { useFirebaseFileUploader } from '../../../../../../../../../../../../../../hooks/use-firebase-file-uploader';
import { ShipmentPhotoItemPropsDef } from './shipment-photo-item.types';

export const ShipmentPhotoItem = (props: ShipmentPhotoItemPropsDef) => {
  const { fileUrl, handleUploadsComplete, thumbnailSize, uploadPath } = props;

  const { setFieldValue, values } = useFormikContext<Shipment>();

  const onFileDelete: HandleDeleteFile = async ({ fileUrl }) => {
    await setFieldValue(
      'photos',
      values.photos?.filter((url) => {
        return fileUrl !== url;
      }) ?? [],
    );
  };

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl,
    handleUploadsComplete,
    onFileDelete,
    path: uploadPath,
  });

  return (
    <Cell>
      <FileUploader
        {...fileUploaderProps}
        fileName={null}
        thumbnailSize={thumbnailSize}
        variant="thumbnail"
      />
    </Cell>
  );
};
