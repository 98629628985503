import { useRecoilValue } from 'recoil';
import { productAtoms } from '../../../../../../../../../../../../product.atoms';
import { useGetBulkUploaderPatterns } from '../../../use-get-bulk-uploader-patterns';

export const useGetMatchingVariantCodes = () => {
  const product = useRecoilValue(productAtoms.product);

  const { variantPatternStrings } = useGetBulkUploaderPatterns();

  const getMatchingVariantCodes = (props: { fileName: string }) => {
    const { fileName } = props;

    const variantCodes: string[][] = [];

    if (product?.selectors?.length) {
      variantPatternStrings.forEach((variantPatternString, index) => {
        const externalVariantPattern = new RegExp(variantPatternString, 'gi');

        const variantPartialCodeMatches = Array.from(
          fileName.matchAll(externalVariantPattern),
          (matchData) => {
            const filteredMatches = matchData.filter((match, index) => {
              // remove complete match and empty group matches
              return index > 0 && !!match;
            });

            return filteredMatches[0];
          },
        ).map((matchValue) => {
          let codeMatch = matchValue;

          product.selectors[index].forEach((selector) => {
            const matchingOption = selector.options.find(({ code, name }) => {
              const matchPattern = new RegExp(matchValue, 'i');

              return matchPattern.test(code) || matchPattern.test(name);
            });

            if (matchingOption) {
              codeMatch = matchingOption.code;
            }
          });

          return codeMatch;
        });

        const matchingFullVariantCodes = product.skuPartialCodes[index]
          .filter(({ partialCodes }) => {
            return variantPartialCodeMatches.every((code) => {
              return partialCodes.some((partial) => {
                return partial === code;
              });
            });
          })
          .map(({ code }) => {
            return code;
          });

        variantCodes.push(matchingFullVariantCodes);
      });
    }

    return variantCodes;
  };

  return getMatchingVariantCodes;
};
