import { GetIsDuplicateProps } from './get-is-duplicate.types';

export const getIsDuplicate = (props: GetIsDuplicateProps) => {
  const { allFiles: files, file } = props;

  const isDuplicate =
    files.filter(({ fileName }) => {
      return fileName === file.fileName;
    }).length >= 2;

  return isDuplicate;
};
