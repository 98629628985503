import {
  Button,
  Cell,
  Conditional,
  Grid,
  Icon,
  Stack,
} from 'gantri-components';
import { FieldArray, useFormikContext } from 'formik';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { AddOrEditPartsFormData } from '../../../../add-or-edit-part-modal.types';
import { partPaintedStatuses } from '../../../../../../../../../../../../api/products/products.constants';
import { Divider } from '../../../../../../../../../../../../components/divider';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import { Label } from '../../../../../../../../../../../../components/label';
import {
  getColorOverridesItems,
  getHandleAddColorOverride,
} from './part-color-overrides.helpers';
import { PartColorOverrideItem } from './components/part-color-override-item';

export const PartColorOverrides = () => {
  const product = useRecoilValue(productAtoms.product);

  const { values } = useFormikContext<AddOrEditPartsFormData>();
  const colorOverride = values.colorOverride || [];

  const schemaId = 'colorOverride';

  const items = useMemo(() => {
    return getColorOverridesItems({
      paintedStatus: values.paintedStatus,
      productId: product.id,
    });
  }, [values.paintedStatus, product.id]);

  return (
    <Conditional
      condition={values.paintedStatus === partPaintedStatuses.painted}
    >
      <Stack gap="x">
        <Divider />

        <FieldArray name={schemaId}>
          {({ push, remove, replace }) => {
            const handleAddColorOverride = getHandleAddColorOverride({ push });

            return (
              <>
                <Conditional condition={!!colorOverride.length}>
                  <Grid
                    alignItems="start"
                    columnGap="2x"
                    columns="1fr 1fr max-content"
                    rowGap="x"
                  >
                    <Cell>
                      <Label text="When product color is:" />
                    </Cell>
                    <Cell width={2}>
                      <Label text="Override part color with:" />
                    </Cell>

                    {colorOverride.map(({ from, to }, index) => {
                      return (
                        <PartColorOverrideItem
                          key={`${from?.code}-${to?.code}`}
                          from={from}
                          index={index}
                          items={items}
                          remove={remove}
                          replace={replace}
                          to={to}
                        />
                      );
                    })}
                  </Grid>
                </Conditional>

                <Button
                  disabled={colorOverride.some(({ from, to }) => {
                    return !from?.code || !to?.code;
                  })}
                  icon={<Icon color="link" name="ui-control:plus" />}
                  justifySelf="start"
                  text="Add Color Override"
                  variant="ghost"
                  onClick={handleAddColorOverride}
                />
              </>
            );
          }}
        </FieldArray>
      </Stack>
    </Conditional>
  );
};
