import { Conditional, Stack } from 'gantri-components';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import {
  productJobsTabAtoms,
  stockJobsSections,
} from '../../../../product-jobs.atoms';
import { productAtoms } from '../../../../../../product.atoms';
import { StockJobsHeader } from '../stock-jobs-header';
import { useInvalidateFetchProductCache } from '../../../../../../../../api/products/routes';
import {
  getStockJobsAssembleInventoryInitialValues,
  StockJobsAssembleInventoryFormData,
  stockJobsAssembleInventoryFormikSchema,
} from './stock-jobs-assemble-inventory.schema';
import { useUpdateProductJobBlocks } from '../../../../../../../../api/products/routes/update-product-job-blocks';
import { jobBlockTypes } from '../../../../../../../../api/products/routes/update-product-job-blocks/update-product-job-blocks.types';
import { AssembleInventoryVariantsSection } from './components/assemble-inventory-variants-section';
import { AssembleInventoryVariantInventorySection } from './components/assemble-inventory-variant-inventory-section';
import { AssembleInventoryStandardInventorySection } from './components/assemble-inventory-standard-inventory-section';

export const StockJobsAssembleInventory = () => {
  const product = useRecoilValue(productAtoms.product);
  const isAccessory = useRecoilValue(productAtoms.isAccessory);
  const isEditingStockJobsSection = useRecoilValue(
    productJobsTabAtoms.isEditingStockJobsSection,
  );
  const resetIsEditingStockJobsSection = useResetRecoilState(
    productJobsTabAtoms.isEditingStockJobsSection,
  );

  const stockBlock = product?.stockBlock;

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();

  const { onUpdateProductJobBlocks } = useUpdateProductJobBlocks({
    onSuccess: async () => {
      await invalidateFetchProductCache();
      resetIsEditingStockJobsSection();
    },
  });

  const onSubmit = async (values: StockJobsAssembleInventoryFormData) => {
    await onUpdateProductJobBlocks({
      id: stockBlock.id,
      jobBlock: {
        stockBlock: { ...stockBlock, ...values },
        type: jobBlockTypes.stock,
      },
      variantIds: values.variantIds,
    });
  };

  const initialValues = useMemo(() => {
    return getStockJobsAssembleInventoryInitialValues({
      product,
      stockJobsBlock: stockBlock,
    });
  }, [stockBlock]);

  const sectionId = stockJobsSections.assembleInventory;
  const isEditing = isEditingStockJobsSection === sectionId;

  return (
    <Conditional condition={!isAccessory}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange
        validateOnMount
        validationSchema={stockJobsAssembleInventoryFormikSchema}
        onSubmit={onSubmit}
      >
        {({}) => {
          return (
            <Form>
              <Stack gap="x">
                <StockJobsHeader
                  sectionId={sectionId}
                  text="Assemble Inventory"
                />

                <Stack gap={isEditing ? '3x' : '5x'} maxWidth="52.8rem">
                  <AssembleInventoryStandardInventorySection
                    isEditing={isEditing}
                  />

                  <AssembleInventoryVariantsSection isEditing={isEditing} />

                  <AssembleInventoryVariantInventorySection
                    isEditing={isEditing}
                  />
                </Stack>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Conditional>
  );
};
