import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import {
  Flex,
  Grid,
  Icon,
  Typography,
  useConfirmationModal,
} from 'gantri-components';
import { ReviewDetailState } from './reviews.types';
import {
  getNpsReviewDetail,
  getProductReviewDetail,
  ProductReviewStatus,
  ProductReviewStatuses,
  UserNPSReview,
  UserProductReview,
  useUpdateProductReviewMutation,
} from '../../../api/users/routes';
import { UpdateProductReviewArgs } from '../../../api/users/routes/update-product-review/update-product-review.types';
import {
  StyledBox,
  StyledTypography,
} from './components/panel-details/review.styles';
import { useNotification } from '../../../hooks/useNotification';

interface State {
  open: boolean;
  reviewSelected: UserProductReview | UserNPSReview;
  source: 'REVIEW' | 'NPS';
  userId: number;
}

const UserReviewContext = createContext<ReviewDetailState>({
  onHideReviewDetails: () => {},
  onShowReviewDetails: () => {},
  onStatusSelected: () => {},
  onUpdateReviewStatus: () => {},
  open: true,
  reviewSelected: null,
  source: null,
  userId: null,
});

const initialState: State = {
  open: false,
  reviewSelected: null,
  source: null,
  userId: null,
};

type Action =
  | {
      data: {
        review: UserProductReview | UserNPSReview;
        source: 'REVIEW' | 'NPS';
        userId: number;
      };
      type: 'SHOW';
    }
  | { type: 'HIDE' }
  | { data: { review: UserProductReview }; type: 'UPDATE_REVIEW' };

function reviewReducer(state: State, action: Action) {
  switch (action.type) {
    case 'SHOW':
      return {
        open: true,
        reviewSelected: action.data.review,
        source: action.data.source,
        userId: action.data.userId,
      };

    case 'HIDE':
      return {
        open: false,
        reviewSelected: null,
        source: null,
        userId: null,
      };

    case 'UPDATE_REVIEW':
      return {
        ...state,
        reviewSelected: action.data.review,
      };

    default:
      return state;
  }
}

export const ReviewDetailsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reviewReducer, initialState);
  const { onUpdateProductReview } = useUpdateProductReviewMutation();
  const updateParamsRef = useRef<UpdateProductReviewArgs>(null);
  const { notify } = useNotification();

  const onHideReviewDetails = () => {
    if (!!updateParamsRef.current?.status) {
      showConfirmClose();

      return;
    }

    dispatch({ type: 'HIDE' });
  };

  const [showConfirmClose, hideConfirmClose] = useConfirmationModal({
    confirmButtonText: 'Exit',
    confirmButtonVariant: 'primaryAlert',
    content: (
      <Typography
        align="center"
        text="Are you sure you want to leave the Product Review Detail?
If you exit now, any changes made to the review status will be lost, and will return to its current status."
      />
    ),
    headerText: 'Exit Review Status',
    onClose: () => {
      hideConfirmClose();
    },
    onConfirm: () => {
      updateParamsRef.current = null;
      onHideReviewDetails();
      hideConfirmClose();
    },
    width: { lg: '43.5rem', md: '100%' },
  });

  const onConfirmUpdate = async () => {
    await onUpdateProductReview(updateParamsRef.current, {
      onSuccess: async () => {
        notify(
          <Flex alignItems="center" gap="x" justifyContent="center">
            <Icon
              color="white"
              name="ui-control:check_mark_circle_filled_24"
              size="2.4rem"
              top="0"
            />
            <Typography
              color="alt"
              text="Review status has successfully been updated!"
            />
          </Flex>,
        );

        dispatch({
          data: {
            review: {
              ...state.reviewSelected,
              status: updateParamsRef.current.status,
            } as UserProductReview,
          },
          type: 'UPDATE_REVIEW',
        });

        updateParamsRef.current = null;
        hidePublishConfirmModal();
      },
    });
  };

  const [showPublishConfirmModal, hidePublishConfirmModal] =
    useConfirmationModal({
      confirmButtonText: 'Confirm',
      confirmButtonVariant: 'primary',
      content: (
        <Grid gap="4x" justifyItems="center">
          <StyledTypography
            align="center"
            htmlText="Are you sure you want to <span>publish</span> this review?"
          />

          <StyledBox>
            <Typography
              marginBottom="x"
              text="Publishing will be shown on all channels:"
            />

            <ul>
              <li>Storefront</li>
              <li>Product Page</li>
              <li>Google Feed</li>
            </ul>
          </StyledBox>
        </Grid>
      ),
      headerText: 'Update Review Status',
      onClose: () => {
        hidePublishConfirmModal();
      },
      onConfirm: onConfirmUpdate,
      width: { lg: '49rem', md: '100%' },
    });

  const onShowReviewDetails = async (
    reviewId: number,
    source: 'REVIEW' | 'NPS',
    userId: number,
  ) => {
    const response =
      source === 'NPS'
        ? await getNpsReviewDetail.query(reviewId)
        : await getProductReviewDetail.query(reviewId);

    dispatch({
      data: { review: response.review, source, userId },
      type: 'SHOW',
    });
  };

  const onUpdateReviewStatus = async (status: ProductReviewStatus) => {
    updateParamsRef.current = {
      id: state.reviewSelected.id,
      status,
      userId: state.userId,
    };

    if (status === ProductReviewStatuses.PUBLISHED) {
      showPublishConfirmModal();

      return;
    }

    await onConfirmUpdate();
  };

  // to check when the user close the details panel
  const onStatusSelected = (status: ProductReviewStatus) => {
    updateParamsRef.current = {
      id: state.reviewSelected.id,
      status,
      userId: state.userId,
    };
  };

  const value = useMemo(() => {
    return {
      ...state,
      onHideReviewDetails,
      onShowReviewDetails,
      onStatusSelected,
      onUpdateReviewStatus,
    };
  }, [state]);

  return (
    <UserReviewContext.Provider value={value}>
      {children}
    </UserReviewContext.Provider>
  );
};

export const useReviewDetailsPanel = () => {
  return useContext(UserReviewContext);
};
