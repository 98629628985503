import { ProductColorCode, getColorsByProduct } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { productAtoms } from '../../../../../../../../../../../../product.atoms';
import { NoneOptionCode } from '../../../../../../../../../../../../../../constants/options';
import {
  convertWhitespace,
  useGetBulkUploaderPatterns,
} from '../../../use-get-bulk-uploader-patterns';

export const useGetMatchingColorCodes = () => {
  const product = useRecoilValue(productAtoms.product);

  const { paintedColorsRegExp } = useGetBulkUploaderPatterns();

  const getMatchingColorCodes = (props: { fileName: string }) => {
    const { fileName } = props;

    const colorCodes: (ProductColorCode | NoneOptionCode)[] = [];

    if (product.isPainted) {
      const colorMatches = fileName.match(paintedColorsRegExp);

      if (!colorMatches) {
        return { colorCodes, isValid: false };
      }

      const [_completeColorMatch, colorMatch] = colorMatches;

      const validColors = getColorsByProduct({
        allowTradeColors: true,
        isPainted: product.isPainted,
        productId: product.id,
      });

      const colorCode = validColors.find(({ code, shortColorName }) => {
        const codePattern = new RegExp(`^${code}$`, 'i');
        const shortColorNamePattern = new RegExp(
          `^${convertWhitespace(shortColorName)}$`,
          'i',
        );

        return (
          codePattern.test(colorMatch) || shortColorNamePattern.test(colorMatch)
        );
      })?.code;

      if (colorCode) {
        colorCodes.push(colorCode);
      } else {
        return { colorCodes, isValid: false };
      }
    } else {
      colorCodes.push('0');
    }

    return { colorCodes, isValid: true };
  };

  return getMatchingColorCodes;
};
