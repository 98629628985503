import { PropsWithChildren } from 'react';
import { StyledInfoWrapper } from './info-wrapper.styles';

interface InfoWrapperProps {
  identifier?: string;
  onClick?: (e: string) => void;
  selected?: boolean;
}

const InfoWrapper = ({
  children,
  identifier,
  onClick,
  selected = false,
}: PropsWithChildren<InfoWrapperProps>) => {
  const handleClick = () => {
    onClick?.(identifier);
  };

  return (
    <StyledInfoWrapper
      $pointer={!!onClick}
      role={onClick ? 'button' : undefined}
      selected={selected}
      tabIndex={0}
      onClick={handleClick}
    >
      {children}
    </StyledInfoWrapper>
  );
};

export default InfoWrapper;
