import { Cell, Flex, Icon, Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { restartOrCancelPartModalAtoms } from '../../../restart-or-cancel-part-modal.atoms';
import {
  PartStatus,
  partStatuses,
} from '../../../../../../../../../constants/options';
import { PartStatusBadge } from '../../../../../../../../../components/common/part-status-badge';
import { List } from '../../../../../../../../../components/common/list';

export const ConfirmRestartContent = () => {
  const restartReason = useRecoilValue(
    restartOrCancelPartModalAtoms.restartReason,
  );

  const newPartStatus: PartStatus =
    restartReason === 'Lost' ? partStatuses.lost : partStatuses.failed;

  return (
    <Stack gap="0.8rem" paddingTop="1.6rem">
      <Icon color="alert" name="alert:warning_triangle_24" size="2.4rem" />
      <Typography text="Confirm Re-start Part" variant="h4" />
      <Typography htmlText="Please confirm the information below to re-start this part. <strong>This action cannot be reversed.</strong>" />{' '}
      <Cell paddingTop="1.6rem">
        <List>
          <li>
            <Flex gap="0.4rem">
              <Typography text="Part status will be updated to" />
              <PartStatusBadge status={newPartStatus} />
            </Flex>
          </li>
          <li>
            <Typography text="All waiting and ready jobs for the part will be cancelled." />
          </li>
          <li>
            <Typography text="A new part and part ID # will be created and linked to same parent Stock." />
          </li>
        </List>
      </Cell>
    </Stack>
  );
};
