import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { GetPartsArgs, GetPartsResponse } from './get-parts.types';
import { getParts } from './get-parts';

const fetchPaginatedPartsQueriesObj = getGenericQueryObj({
  groupName: 'fetch-paginated-parts',
  query: getParts,
});

export const useFetchPaginatedParts = <TransformedData = GetPartsResponse>(
  props?: GenericFetchProps<GetPartsArgs, GetPartsResponse, TransformedData>,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch paginated parts.',
    queryObj: fetchPaginatedPartsQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchPaginatedPartsCache = () => {
  const invalidateFetchPaginatedPartsCache = useGetInvalidateQueryCache(
    fetchPaginatedPartsQueriesObj,
  );

  return { invalidateFetchPaginatedPartsCache };
};
