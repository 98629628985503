import { Modal } from 'gantri-components';
import { Form, Formik } from 'formik';
import {
  UpdateInventoryTargetFormData,
  UpdateInventoryTargetModalProps,
} from './update-inventory-target-modal.types';
import { getHeaderText } from './helpers/get-header-text';
import {
  updateInventoryTargetFormInitialValues,
  updateInventoryTargetSchema,
} from './update-inventory-target-modal.schema';
import { UpdateInventoryTargetModalContent } from './components/update-inventory-target-modal-content';
import { UpdateInventoryTargetModalFooter } from './components/update-inventory-target-modal-footer';
import { useSetInventoryTarget } from '../../../../../../api/products/routes/set-inventory-target';
import { useInvalidateFetchStockSummaryCache } from '../../../../../../api/stocks/routes/fetch-stock-summary/fetch-stock-summary.query';

export const UpdateInventoryTargetModal = (
  props: UpdateInventoryTargetModalProps,
) => {
  const { onClose, stocks } = props;

  const { invalidateFetchStockSummaryCache } =
    useInvalidateFetchStockSummaryCache();

  const { isLoading, onSetInventoryTarget } = useSetInventoryTarget({
    onSuccess: async () => {
      onClose();

      await invalidateFetchStockSummaryCache();
    },
  });

  const handleUpdateInventoryTarget = async ({
    inventoryTarget,
  }: UpdateInventoryTargetFormData) => {
    await onSetInventoryTarget({
      skusData: stocks.map(({ productId, sku }) => {
        return {
          id: Number(productId),
          sku,
        };
      }),
      target: inventoryTarget,
    });
  };

  const numStocks = stocks.length;

  return (
    <Formik
      enableReinitialize
      initialValues={updateInventoryTargetFormInitialValues}
      validateOnChange
      validateOnMount
      validationSchema={updateInventoryTargetSchema}
      onSubmit={handleUpdateInventoryTarget}
    >
      <Form>
        <Modal
          footer={
            <UpdateInventoryTargetModalFooter
              processing={isLoading}
              onClose={onClose}
            />
          }
          header={getHeaderText(numStocks)}
          width={{ lg: '46.4rem', md: '100%' }}
          onClose={onClose}
        >
          <UpdateInventoryTargetModalContent stocks={stocks} />
        </Modal>
      </Form>
    </Formik>
  );
};
