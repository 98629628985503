import { useModal } from 'gantri-components';
import omit from 'lodash/omit';
import { useState } from 'react';
import { InventoryCheckoutForm } from './inventory-checkout-form';
import { CheckoutFormState } from './inventory-checkout.types';
import {
  InventoryForJobs,
  useFetchAllInventoriesForJobsQuery,
} from '../../../../api/inventories/routes/fetch-all-for-jobs';
import { useNotification } from '../../../../hooks/useNotification';
import { inventoriesApi } from '../../../../api';

export const useInventoryCheckoutModal = () => {
  const { notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();
  const [loadInventories, setLoadInventories] = useState<boolean>(false);

  const { inventories } = useFetchAllInventoriesForJobsQuery<
    InventoryForJobs[]
  >({
    enabled: loadInventories,
    showLoading: true,
    transform: (response) => {
      return response.inventories ?? [];
    },
  });

  const { notify } = useNotification();

  const onSubmit = async (values: CheckoutFormState) => {
    await onInterceptProcessingRequest(async () => {
      try {
        const { data } = await inventoriesApi.createInventoryCheckout({
          ...values,
          inventories: values.inventories.map((inventory) => {
            return omit(inventory, ['inventorySelected']);
          }),
        });

        if (data) {
          notify(
            'Manual check-out was successful. Inventory transactions have been updated.',
          );

          hideModal();
        }
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to checkout inventory.',
        });
      }
    });
  };

  const [showModal, hideModal] = useModal(() => {
    return (
      <InventoryCheckoutForm
        inventories={inventories}
        processing={processing}
        onClose={hideModal}
        onSubmit={onSubmit}
      />
    );
  }, [inventories, processing]);

  const onShowInventoryCheckout = async () => {
    showModal();
    setLoadInventories(true);
  };

  return { onShowInventoryCheckout };
};
