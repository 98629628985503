import { Radio } from 'gantri-components';
import { PropsWithChildren } from 'react';
import { radioButtonVariants } from './radio-button.constants';
import { StyledRadioWrapperFlex } from './radio-button.styles';
import { RadioButtonProps } from './radio-button.types';

/**
 * Makes radio, label, and children clickable.
 */
export const RadioButton = <T extends string>(
  props: PropsWithChildren<RadioButtonProps<T>>,
) => {
  const {
    buttonVariant = radioButtonVariants.button,
    checked,
    children,
    groupValue,
    onSelected,
    value,
    ...rest
  } = props;

  const onClick = () => {
    onSelected(groupValue);
  };

  return (
    <StyledRadioWrapperFlex
      $buttonVariant={buttonVariant}
      $checked={checked}
      alignItems="center"
      gap="1rem"
      onClick={onClick}
    >
      <Radio
        {...rest}
        groupValue={groupValue}
        value={value}
        onSelected={onClick}
      />
      {children}
    </StyledRadioWrapperFlex>
  );
};
