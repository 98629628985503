import { useNotification } from '../../../../hooks/useNotification';
import { useQueryFetch } from '../../../../hooks/use-fetch/use-query-fetch';
import { getDesignerFeatureSettings } from './get-designer-feature-settings';
import { GetFeatureSettingsArgs } from './get-designer-feature-settings.types';

interface UseGetDesignerFeatureSettingsProps {
  args: GetFeatureSettingsArgs;
}

export const useGetDesignerFeatureSettings = (
  props: UseGetDesignerFeatureSettingsProps,
) => {
  const { args } = props;
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch({
    config: {
      enabled: !!args,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch designer collection.',
        });
      },
      queryFn: () => {
        return getDesignerFeatureSettings.query(args);
      },
      queryKey: getDesignerFeatureSettings.key(args),
    },
    showLoading: true,
  });

  return { data: data?.settings, isLoading };
};
