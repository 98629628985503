import { useFormikContext } from 'formik';
import { Dropdown, FormikInput } from 'gantri-components';
import { useEffect, useMemo } from 'react';
import {
  activeOpaqueMaterialPrinterTypes,
  activeTranslucentMaterialPrinterTypes,
} from '../../../../../../../../../../../../constants/machines';
import { materials } from '../../../../../../../../../../../../constants/parts';
import { useGetVersionDetails } from '../../../../../../../../hooks/use-get-version-details';
import { AddOrEditPartsFormData } from '../../../../add-or-edit-part-modal.types';

export const PartMachineType = () => {
  const { isMinorVersion } = useGetVersionDetails();

  const { setFieldValue, values } = useFormikContext<AddOrEditPartsFormData>();
  const { machineType, material } = values;

  const options = useMemo(() => {
    return (
      material === materials.opaque
        ? activeOpaqueMaterialPrinterTypes
        : activeTranslucentMaterialPrinterTypes
    ).map((option) => {
      return {
        label: option,
        value: option,
      };
    });
  }, [material]);

  useEffect(() => {
    if (!material && machineType) {
      setFieldValue('machineType', '');
    }
  }, [material]);

  return (
    <FormikInput
      Field={
        <Dropdown
          disabled={isMinorVersion || !material}
          items={options}
          labelPosition="top"
          labelText="Machine Type"
          maxHeight={400}
          placeholder="Select type"
        />
      }
      fieldVariant="standard"
      name="machineType"
      required
    />
  );
};
