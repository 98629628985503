import { useRecoilState } from 'recoil';
import { ChecklistItem } from '../../../../../common/job-checklist/job-checklist.types';
import { AdvancedChecklist } from '../../../../../common/job-checklist/components/advanced-checklist';
import { completePrintHarvestModalAtoms } from '../../../complete-print-harvest-modal.atoms';
import { CompletePrintFailReason } from '../../../complete-print-harvest-modal.types';
import { statusOptions } from '../../complete-print-harvest-content/complete-print-harvest-content.constants';

export const CompleteChecklistContent = () => {
  const [checklist, setChecklist] = useRecoilState(
    completePrintHarvestModalAtoms.checklist,
  );
  const [isChecklistReviewed, setIsChecklistReviewed] = useRecoilState(
    completePrintHarvestModalAtoms.isChecklistReviewed,
  );

  const updateChecklist = (
    reason: CompletePrintFailReason,
    data: ChecklistItem,
  ) => {
    setChecklist((previous) => {
      return {
        ...previous,
        [reason]: data,
      };
    });
  };

  return (
    <AdvancedChecklist
      checklist={checklist}
      isChecklistReviewed={isChecklistReviewed}
      setIsChecklistReviewed={setIsChecklistReviewed}
      showConfirmCheckbox
      statuses={statusOptions}
      onItemChange={updateChecklist}
    />
  );
};
