import { Typography } from 'gantri-components';
import { Label } from '../../../../../../../../../../../../../../components/label';
import { BulkUploadHeaderTooltipIcon } from '../bulk-upload-table-header-tooltip-icon';

export const BulkUploadTableScalePhotoHeader = () => {
  return (
    <Label
      icon={
        <BulkUploadHeaderTooltipIcon
          footer={<Typography text="1 scale image allowed per SKU." />}
          header={
            <Typography
              htmlText="For mapping to type <u><strong>Scale</strong></u>:"
              textStyle="bold"
            />
          }
          items={[
            'Filename includes all elements listed in SKU mapping info.',
            'Filename includes the following code/name: ‘Scale’',
          ]}
        />
      }
      iconPosition="right"
      text="Scale"
    />
  );
};
