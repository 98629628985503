import { useState } from 'react';
import { Table } from 'gantri-components';
import { OrdersFilter } from '../../components/dropdowns/orders-filter';
import { OrderRecord } from './orders.types';
import { PageWithTable } from '../../components/layout';
import { transactionsApi } from '../../api';
import {
  useTableFilters,
  FiltersFetchRequestArgs,
} from '../../components/common/table/hooks';
import {
  FetchPaginatedTransactionsArgs,
  OrdersSortingField,
  ProductInfo,
} from '../../api/transactions/routes/fetch-paginated-transactions/fetch-paginated-transactions.types';
import { useNotification } from '../../hooks/useNotification';
import {
  ordersPageAtoms,
  ordersFiltersDefaults,
  pageName,
} from '../../components/dropdowns/orders-filter/orders-filter.atoms';
import { columns, sortOptions } from './orders.constant';
import { useSpinner } from '../../hooks';
import { OrdersHeaderContent } from './components/orders-header-content';

export const Orders = () => {
  const { notifyAxiosError } = useNotification();
  const { onInterceptRequest } = useSpinner();
  const [allProducts, setAllProducts] = useState<ProductInfo[]>([]);
  const [downloadFilters, setDownloadFilters] =
    useState<
      FiltersFetchRequestArgs<
        FetchPaginatedTransactionsArgs,
        OrdersSortingField
      >
    >();

  const fetchPaginatedOrders = async (
    args: FiltersFetchRequestArgs<
      FetchPaginatedTransactionsArgs,
      OrdersSortingField
    >,
  ) => {
    await onInterceptRequest(async () => {
      try {
        setDownloadFilters(args);

        const {
          data: { allOrders, allProductsNames, orders },
        } = await transactionsApi.fetchPaginatedTransactions(args);

        // TODO: we should eliminate this kind of practice, it's only made slower the get-orders service and returns the same list for every page
        setAllProducts(allProductsNames);
        setRecords(orders);
        setTotalCount(allOrders);
      } catch (error: unknown) {
        notifyAxiosError({ error, fallbackMessage: 'Unable to fetch orders.' });
      }
    });
  };

  const {
    filtersProps,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    OrderRecord,
    FetchPaginatedTransactionsArgs,
    OrdersSortingField
  >({
    fetchRequest: fetchPaginatedOrders,
    filtersContent: <OrdersFilter allProducts={allProducts} />,
    modalFiltersDetails: [
      {
        atom: ordersPageAtoms.filters.action,
        defaultValue: ordersFiltersDefaults.filters.action,
        payloadKey: 'action',
      },
      {
        atom: ordersPageAtoms.filters.color,
        defaultValue: ordersFiltersDefaults.filters.color,
        payloadKey: 'color',
      },
      {
        atom: ordersPageAtoms.filters.completedDateRange,
        defaultValue: ordersFiltersDefaults.filters.completedDateRange,
        payloadKey: 'completedDateRange',
      },
      {
        atom: ordersPageAtoms.filters.createdDateRange,
        defaultValue: ordersFiltersDefaults.filters.createdDateRange,
        payloadKey: 'createdDateRange',
      },
      {
        atom: ordersPageAtoms.filters.late,
        defaultValue: ordersFiltersDefaults.filters.late,
        payloadKey: 'late',
      },
      {
        atom: ordersPageAtoms.filters.productId,
        defaultValue: ordersFiltersDefaults.filters.productId,
        payloadKey: 'productId',
      },
      {
        atom: ordersPageAtoms.filters.size,
        defaultValue: ordersFiltersDefaults.filters.size,
        payloadKey: 'size',
      },
      {
        atom: ordersPageAtoms.filters.statuses,
        defaultValue: ordersFiltersDefaults.filters.statuses,
        payloadKey: 'statuses',
      },
      {
        atom: ordersPageAtoms.filters.types,
        defaultValue: ordersFiltersDefaults.filters.types,
        payloadKey: 'types',
      },
    ],
    pageName,
    searchFilterDetails: {
      atom: ordersPageAtoms.search,
    },
    sortByFilterDetails: {
      atom: ordersPageAtoms.sortBy,
      defaultValue: ordersFiltersDefaults.sortBy,
    },
    sortOptions,
  });

  return (
    <PageWithTable pageTitle="Orders">
      <PageWithTable.Header title="Orders">
        <OrdersHeaderContent
          downloadFilters={downloadFilters}
          totalResults={pagingProps.total}
        />
      </PageWithTable.Header>

      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          getRowProps={({ original }) => {
            return {
              'data-order-id': original.id,
            };
          }}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          sorting={sortProps}
          stickyLastColumn
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
