import { FC, memo } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Flex, Stack, Table, Typography } from 'gantri-components';
import routePaths from '../../../config/route-paths';
import { formatDate, formatAsCurrency } from '../../../helpers/formatter';
import { PayoutItem } from '../../../api/transactions/routes/get-payout/get-payout.types';
import { StyledAnchor } from '../../../components/common/styled-anchor';
import { getIsClickableIfValue } from '../../../helpers/get-is-clickable-if-value';

const useGetPayoutItemsColumns = (data: PayoutItem[]) => {
  let totalQuantity = 0;
  let totalAmount = 0;
  let grandTotal = 0;

  data.forEach((item) => {
    totalQuantity += item.quantity;
    totalAmount += item.amount;
    grandTotal += item.quantity * item.amount;
  });

  const columns: ColumnDef<PayoutItem>[] = [
    {
      accessorKey: 'date',
      cell: ({ getValue }: CellContext<PayoutItem, PayoutItem['date']>) => {
        return <Typography text={formatDate(getValue())} />;
      },
      footer: 'TOTAL:',
      header: 'Date',
      size: 100,
    },
    {
      accessorKey: 'orderId',
      cell: ({
        getValue,
        row,
      }: CellContext<PayoutItem, PayoutItem['orderId']>) => {
        const { type } = row.original;
        const orderId = getValue();

        return (
          <Flex gap="x">
            <Typography display="inline" text={type} />
            <StyledAnchor
              display="inline"
              text={`#${orderId}`}
              to={`${routePaths.orders}/${orderId}`}
            />
          </Flex>
        );
      },
      header: 'Order',
      meta: {
        getIsClickable: getIsClickableIfValue,
      },
      size: 150,
    },
    {
      accessorKey: 'sku',

      cell: ({ getValue }: CellContext<PayoutItem, PayoutItem['sku']>) => {
        return <Typography text={getValue()?.toUpperCase()} variant="p2" />;
      },
      header: 'SKU',
      size: 150,
    },
    {
      accessorKey: 'quantity',
      cell: ({ getValue }: CellContext<PayoutItem, PayoutItem['quantity']>) => {
        return <Typography align="right" text={getValue()} />;
      },
      footer: () => {
        return (
          <Typography align="right" text={totalQuantity} textStyle="bold" />
        );
      },
      header: 'Quantity',
      size: 80,
    },
    {
      accessorKey: 'amount',
      cell: ({ getValue }: CellContext<PayoutItem, PayoutItem['amount']>) => {
        return (
          <Typography
            align="right"
            text={formatAsCurrency(getValue(), { isCents: true })}
          />
        );
      },
      footer: () => {
        return (
          <Typography
            align="right"
            text={formatAsCurrency(totalAmount, { isCents: true })}
            textStyle="bold"
          />
        );
      },
      header: 'Amount',
      size: 80,
    },
    {
      accessorKey: 'amount',
      cell: ({
        getValue,
        row,
      }: CellContext<PayoutItem, PayoutItem['amount']>) => {
        const { quantity } = row.original;
        const amount = getValue();
        const total = quantity * amount;

        return (
          <Typography
            align="right"
            color="t2"
            text={`${formatAsCurrency(total, {
              isCents: true,
            })}`}
          />
        );
      },
      footer: () => {
        return (
          <Typography
            align="right"
            text={formatAsCurrency(grandTotal, { isCents: true })}
            textStyle="bold"
          />
        );
      },
      header: 'Total',
      id: 'total',
      size: 80,
    },
  ];

  return columns;
};

export const PayoutItems: FC<{ items: PayoutItem[] }> = memo(({ items }) => {
  const columns = useGetPayoutItemsColumns(items);

  return (
    <Stack width="100%">
      <Typography text="Items" textStyle="bold" variant="h4" />
      <Table columns={columns} data={items} highlightHoveredRow />
    </Stack>
  );
});
