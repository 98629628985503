import range from 'lodash/range';
import { convertStringArrayToValueLabelArray } from '../../../helpers/formatter';

export const hourOptions = convertStringArrayToValueLabelArray(
  range(1, 13).map((item) => {
    return item < 10 ? `0${item}` : String(item);
  }),
);

export const minuteOptions = convertStringArrayToValueLabelArray(['00', '30']);

export const meridiemOptions = convertStringArrayToValueLabelArray([
  'AM',
  'PM',
]);
