import { Box, Stack, Typography } from 'gantri-components';
import styled, { css } from 'styled-components';
import { progressTransitionTimeSec } from '../sanding-minutes-earned-toast/sanding-minutes-earned-toast.styles';

export const StyledProgressStack = styled(Stack)<{
  $isActive: boolean;
}>`
  ${({ $isActive, theme }) => {
    return css`
      background-color: ${$isActive && theme.colors.surfaces.green.t1};
      transition: background-color ease-in-out ${progressTransitionTimeSec}s;
    `;
  }}
`;

export const StyledTextScrollWrapperBox = styled(Box)`
  ${() => {
    return css`
      overflow: hidden;
      height: ${scrollTextHeight};
    `;
  }}
`;

export const StyledTextScrollBox = styled(Box)<{
  $isActive: boolean;
}>`
  ${({ $isActive }) => {
    return css`
      top: ${$isActive ? '-100%' : 0};
      transition: ${$isActive
        ? `all ease-in-out ${progressTransitionTimeSec}s`
        : 'none'};
    `;
  }}
`;

export const StyledScrollTypography = styled(Typography)`
  ${({ text }) => {
    return css`
      // ? Prevents regular shifts caused by different characters having different widths between the previous and current values
      min-width: ${String(text).length}ch;
      text-align: right;
      line-height: ${scrollTextHeight};
    `;
  }}
`;

export const scrollTextHeight = '3rem';
