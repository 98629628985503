import { useFormikContext } from 'formik';
import {
  Conditional,
  Dropdown,
  FormikInput,
  RadioList,
  TextField,
} from 'gantri-components';
import { newProductTypes } from '../../new-product-modal.constants';
import { NewProductModalFormData } from '../../new-product-modal.type';
import { useNewProductModalContentData } from './hooks/use-new-product-modal-content-data';

export const NewProductModalContent = () => {
  const { errors, values } = useFormikContext<NewProductModalFormData>();

  const {
    categoryItems,
    colorItems,
    handleColorChange,
    isAccessory,
    onTypeSelected,
    subCategoryItems,
  } = useNewProductModalContentData();

  return (
    <>
      <FormikInput
        Field={
          <RadioList
            gridProps={{ columns: 2, gap: 'x' }}
            items={Object.values(newProductTypes)}
            showAsTiles
            value={values.type}
            onSelected={onTypeSelected}
          />
        }
        fieldVariant="standard"
        name="type"
        required
      />

      <FormikInput
        Field={<TextField labelText="Name" placeholder="Input name" />}
        fieldVariant="standard"
        name="name"
        required
      />

      <Conditional condition={!isAccessory}>
        <FormikInput
          Field={
            <Dropdown
              items={categoryItems}
              labelText="Category"
              placeholder="Select category"
            />
          }
          fieldVariant="standard"
          name="category"
          required
        />
      </Conditional>

      <FormikInput
        Field={
          <Dropdown
            items={subCategoryItems}
            labelText="Sub-Category"
            placeholder="Select sub-category"
          />
        }
        fieldVariant="standard"
        name="subCategory"
        required
      />
    </>
  );
};
