import { ProductColorCode } from 'gantri-components';
import { OrdersSortingField } from '../../../api/transactions/routes/fetch-paginated-transactions/fetch-paginated-transactions.types';
import {
  OrderStatus,
  ProductSizeCode,
  TransactionType,
} from '../../../constants/options';
import { DateRangeFilter } from '../../common/table/components/table-filters/table-filters.types';
import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
} from '../../common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../helpers/get-generic-atom-family';
import { SortBy } from '../../common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../helpers/get-generic-atom-family/get-generic-atom-family.types';

interface FormattedAndSec {
  formatted: string;
  sec: number;
}

export const ordersFiltersDefaults: {
  filters: {
    action: boolean;
    color: ProductColorCode;
    completedDateRange: DateRangeFilter<FormattedAndSec>;
    createdDateRange: DateRangeFilter<FormattedAndSec>;
    late: boolean;
    productId: number;
    size: ProductSizeCode;
    statuses: OrderStatus[];
    types: TransactionType[];
  };
  sortBy: SortBy<OrdersSortingField>;
} = {
  filters: {
    action: true,
    color: null,
    completedDateRange: {
      from: {
        formatted: null,
        sec: null,
      },
      to: {
        formatted: null,
        sec: null,
      },
    },
    createdDateRange: {
      from: {
        formatted: null,
        sec: null,
      },
      to: {
        formatted: null,
        sec: null,
      },
    },
    late: false,
    productId: null,
    size: null,
    statuses: [],
    types: [],
  },
  sortBy: {
    sortingField: 'createdAt',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'ORDERS_FILTERS';

export const pageName: AtomPageName = 'orders';

export const ordersPageAtoms = {
  defaults: ordersFiltersDefaults,
  filters: {
    action: getGenericAtomFamily({
      defaultValue: ordersFiltersDefaults.filters.action,
      key: `${atomKeyPrefix}-action`,
    })(pageName),
    color: getGenericAtomFamily({
      defaultValue: ordersFiltersDefaults.filters.color,
      key: `${atomKeyPrefix}-color`,
    })(pageName),
    completedDateRange: getGenericAtomFamily({
      defaultValue: ordersFiltersDefaults.filters.completedDateRange,
      key: `${atomKeyPrefix}-completed-date-range`,
    })(pageName),
    createdDateRange: getGenericAtomFamily({
      defaultValue: ordersFiltersDefaults.filters.createdDateRange,
      key: `${atomKeyPrefix}-created-date-range`,
    })(pageName),
    late: getGenericAtomFamily({
      defaultValue: ordersFiltersDefaults.filters.late,
      key: `${atomKeyPrefix}-late`,
    })(pageName),
    productId: getGenericAtomFamily({
      defaultValue: ordersFiltersDefaults.filters.productId,
      key: `${atomKeyPrefix}-product-id`,
    })(pageName),
    size: getGenericAtomFamily({
      defaultValue: ordersFiltersDefaults.filters.size,
      key: `${atomKeyPrefix}-size`,
    })(pageName),
    statuses: getGenericAtomFamily({
      defaultValue: ordersFiltersDefaults.filters.statuses,
      key: `${atomKeyPrefix}-statuses`,
    })(pageName),
    types: getGenericAtomFamily({
      defaultValue: ordersFiltersDefaults.filters.types,
      key: `${atomKeyPrefix}-types`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<OrdersSortingField>>({
    defaultValue: ordersFiltersDefaults.sortBy,
  })(pageName),
};
