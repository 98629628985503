import { Flex, getFolderStructure } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { Handout } from '../../../../../../../../api/products/products.types';
import {
  useInvalidateFetchProductCache,
  useToggleProductHandoutIsUniversal,
} from '../../../../../../../../api/products/routes';
import { MoreMenu } from '../../../../../../../../components/dropdowns';
import { productAtoms } from '../../../../../../product.atoms';
import { MenuCellProps } from './menu-cell.types';

export const MenuCell = (props: MenuCellProps) => {
  const {
    getValue,
    row: { original },
    setHandout,
    showDeleteModal,
  } = props;

  const product = useRecoilValue(productAtoms.product);

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();

  const { onToggleProductHandoutIsUniversal } =
    useToggleProductHandoutIsUniversal({
      onSuccess: async () => {
        await invalidateFetchProductCache();
      },
      showLoading: true,
    });

  const productId = product?.id;

  const type = getValue();

  const toggleUniversalValue = async (data: Handout) => {
    const makeUniversal = !data.universal;

    const universalHandoutsFolder = getFolderStructure<'products'>({
      directory: 'products',
      fileType: 'universal-handouts',
      identifiers: {},
    });
    const productHandoutsFolder = getFolderStructure<'products'>({
      directory: 'products',
      fileType: 'handouts',
      identifiers: {
        productId,
      },
    });

    const extensionPattern = /\..+$/;

    const fromPublicId = `${
      makeUniversal ? productHandoutsFolder : universalHandoutsFolder
    }/${data.name.replace(extensionPattern, '')}`;

    const toPublicId = `${
      makeUniversal ? universalHandoutsFolder : productHandoutsFolder
    }/${data.name.replace(extensionPattern, '')}`;

    await onToggleProductHandoutIsUniversal({
      fromPublicId,
      handoutId: data.id,
      isUniversal: makeUniversal,
      productId,
      toPublicId,
    });
  };

  const menuOptions = [
    {
      enabled: true,
      name: 'Toggle Universal',
      onOptionClick: toggleUniversalValue,
    },
    {
      enabled: true,
      name: 'Delete',
      onOptionClick: () => {
        setHandout(original);
        showDeleteModal();
      },
    },
  ];

  return type === 'Auto Generated' ? null : (
    <Flex justifyContent="flex-end">
      <MoreMenu data={original} options={menuOptions} />
    </Flex>
  );
};
