import { ProductListRecord } from '../../../../api/products/routes/get-all/get-all.types';

export const findProduct = (props: {
  allProducts: ProductListRecord[];
  productId: number;
}) => {
  const { allProducts, productId } = props;

  return (
    allProducts.find(({ id }) => {
      return id === productId;
    }) || ({} as ProductListRecord)
  );
};
