import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import {
  FetchPaginatedStocksArgs,
  FetchPaginatedStocksResponse,
} from './fetch-paginated-stocks.types';
import { fetchPaginatedStocks } from './fetch-paginated-stocks';

const fetchPaginatedStocksQueriesObj = getGenericQueryObj({
  groupName: 'fetch-paginated-stocks',
  query: fetchPaginatedStocks,
});

export const useFetchPaginatedStocks = <
  TransformedData = FetchPaginatedStocksResponse,
>(
  props?: GenericFetchProps<
    FetchPaginatedStocksArgs,
    FetchPaginatedStocksResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch paginated stocks.',
    queryObj: fetchPaginatedStocksQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchPaginatedStocksCache = () => {
  const invalidateFetchPaginatedStocksCache = useGetInvalidateQueryCache(
    fetchPaginatedStocksQueriesObj,
  );

  return { invalidateFetchPaginatedStocksCache };
};
