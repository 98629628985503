import { Grid, Typography } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { RecommendedStock } from '../../../../../../../../api/parts/routes/get-recommended-stocks/get-recommended-stocks.types';
import { formatDate } from '../../../../../../../../helpers/formatter';
import { TableColumnRadio } from '../../components/table-column-radio';
import { GetRecommendedStocksTableColumnsArgs } from './get-recommended-stocks-table-columns.types';
import { MEDIUM_FORMAT } from '../../../../../../../../constants/dates';

export const getRecommendedStocksTableColumns = (
  props: GetRecommendedStocksTableColumnsArgs,
) => {
  const { currentStockId, selectedStock, setSelectedStock } = props;

  const columns: ColumnDef<RecommendedStock>[] = [
    {
      accessorKey: 'stockId',
      cell: ({
        row: { original },
      }: CellContext<RecommendedStock, RecommendedStock['stockId']>) => {
        return (
          <TableColumnRadio
            currentStockId={currentStockId}
            originalData={original}
            selectedStock={selectedStock}
            setSelectedStock={setSelectedStock}
          />
        );
      },
      header: () => {
        return (
          <Grid alignItems="center" columns="2rem 1fr" gap="x">
            <div data-empty-spacer="" />
            <Typography text="Stock #" />
          </Grid>
        );
      },
    },
    {
      accessorKey: 'orderId',
      cell: ({
        getValue,
        row: { original },
      }: CellContext<RecommendedStock, RecommendedStock['orderId']>) => {
        return (
          <>
            <Typography text={`#${getValue()}`} variant="p2" />
            <Typography color="t2" text={original.orderType} variant="p2" />
          </>
        );
      },
      header: 'Order',
    },
    {
      accessorKey: 'shipsAt',
      cell: ({
        getValue,
      }: CellContext<RecommendedStock, RecommendedStock['shipsAt']>) => {
        return <Typography text={formatDate(getValue(), MEDIUM_FORMAT)} />;
      },
      header: 'Ship by',
    },
    {
      accessorKey: 'completePercent',
      cell: ({
        getValue,
      }: CellContext<
        RecommendedStock,
        RecommendedStock['completePercent']
      >) => {
        return <Typography text={getValue()} variant="p2" />;
      },
      header: '% Complete',
    },
  ];

  return columns;
};
