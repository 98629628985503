import { FC, memo } from 'react';
import {
  Conditional,
  Flex,
  FormikInput,
  Grid,
  Typography,
} from 'gantri-components';
import { useField } from 'formik';
import { StyledDiscountButton } from './discount.styles';
import { formatAsCurrency } from '../../../../helpers/formatter';
import { DiscountType } from '../../new-order.type';

export const NewOrderDiscountSection: FC<{ totalDiscount: number }> = memo(
  (props) => {
    const { totalDiscount } = props;
    const [{ value: discountType }, , helper] =
      useField<DiscountType>('discountType');

    const onChangeType = (type: DiscountType) => {
      if (type === discountType) return;

      helper.setValue(type);
    };

    return (
      <Grid columnGap="3x" columns="1fr 20rem">
        <Flex alignItems="center" gap="unset">
          <Typography marginRight="2x" text="Discount" variant="p2" />
          <StyledDiscountButton
            $active={discountType === 'percentage'}
            onClick={() => {
              return onChangeType('percentage');
            }}
          >
            <Typography text="%" textStyle="bold" variant="p2" />
          </StyledDiscountButton>
          <StyledDiscountButton
            $active={discountType === 'static'}
            onClick={() => {
              return onChangeType('static');
            }}
          >
            <Typography text="$" textStyle="bold" variant="p2" />
          </StyledDiscountButton>
        </Flex>

        <FormikInput
          fieldVariant="standard"
          leftIcon={
            discountType === 'static' && (
              <Typography color="t2" text="$" variant="p2" />
            )
          }
          name="discount"
          placeholder={
            discountType === 'static' ? 'Input amount' : 'Input percent'
          }
          rightIcon={
            discountType === 'percentage' && (
              <Typography color="t2" text="%" variant="p2" />
            )
          }
          type="number"
        />

        <Conditional condition={discountType === 'percentage'}>
          <Typography
            color="t2"
            style={{
              left: 'calc(100% + 1.6rem)',
              position: 'absolute',
              top: '.6rem',
            }}
            text={`(${formatAsCurrency(totalDiscount, { isCents: true })})`}
            variant="p2"
          />
        </Conditional>
      </Grid>
    );
  },
);
