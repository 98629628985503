import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  ToggleProductHandoutIsUniversalArgs,
  ToggleProductHandoutIsUniversalResponse,
} from './toggle-product-handout-is-universal.types';
import { toggleProductHandoutIsUniversal } from './toggle-product-handout-is-universal';

export const useToggleProductHandoutIsUniversal = <
  TransformedData = ToggleProductHandoutIsUniversalResponse,
>(
  props: GenericMutateQueryProps<
    ToggleProductHandoutIsUniversalArgs,
    ToggleProductHandoutIsUniversalResponse,
    TransformedData
  >,
) => {
  const { onMutate: onToggleProductHandoutIsUniversal, ...rest } =
    useGenericMutateQuery({
      fallbackErrorMessage: 'Unable to toggle handout universal status.',
      mutationFn: toggleProductHandoutIsUniversal,
      ...props,
    });

  return { ...rest, onToggleProductHandoutIsUniversal };
};
