import { Typography } from 'gantri-components';
import { Label } from '../../../../../../../../../../../../../../components/label';
import { BulkUploadHeaderTooltipIcon } from '../bulk-upload-table-header-tooltip-icon';

export const BulkUploadTableWhiteBackgroundPhotosHeader = () => {
  return (
    <Label
      icon={
        <BulkUploadHeaderTooltipIcon
          footer={<Typography text="Up to 5 images allowed per SKU." />}
          header={
            <Typography
              htmlText="For mapping to type <u><strong>White <br/>Background Shots</strong></u>:"
              textStyle="bold"
            />
          }
          items={[
            'Filename includes all elements listed in SKU mapping info.',
            'If no other file type is found in the filename, it will be uploaded as a White Background Shot.',
          ]}
        />
      }
      iconPosition="right"
      text="White Background Shots"
    />
  );
};
