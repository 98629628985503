import { Button, Modal, Typography } from 'gantri-components';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { useInvalidateFetchProductCache } from '../../../../../../../../api/products/routes';
import { useDiscardDraft } from '../../../../../../../../api/version/routes';
import { delayFor } from '../../../../../../../../helpers/delay-for';
import { useResetRecoilAtoms } from '../../../../../../../../hooks/use-reset-recoil-atoms';
import { productJobsTabAtoms } from '../../../../product-jobs.atoms';
import { DiscardDraftModalProps } from './discard-draft-modal.types';

export const DiscardDraftModal = (props: DiscardDraftModalProps) => {
  const { onClose, versionId } = props;

  const setVersionId = useSetRecoilState(productJobsTabAtoms.versionId);
  const resetDraftVersionId = useResetRecoilState(
    productJobsTabAtoms.draftVersionId,
  );

  const resetProductJobsTabAtoms = useResetRecoilAtoms(productJobsTabAtoms);

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();

  const fetchProductAndClose = async (props?: { versionId: number }) => {
    const { versionId } = props || {};

    resetProductJobsTabAtoms();

    // Delay added as an immediate request can return the old version data. Unsure what is causing that issue - CD
    await delayFor(500);

    resetDraftVersionId();

    if (versionId) {
      setVersionId(versionId);
    } else {
      await invalidateFetchProductCache();
    }

    onClose();
  };

  const { isLoading, onDiscardDraft } = useDiscardDraft({
    onError: async () => {
      // assume error is because draft already discarded
      await fetchProductAndClose();
    },
    onSuccess: ({ publishVersionId }) => {
      return fetchProductAndClose({ versionId: publishVersionId });
    },
  });

  const handleDiscardDraft = async () => {
    await onDiscardDraft({ versionId });
  };

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Go Back"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            processing={isLoading}
            size="large"
            text="Discard"
            variant="primaryAlert"
            onClick={handleDiscardDraft}
          />
        </>
      }
      header="Discard Draft"
      maxWidth={{ lg: '42rem', md: '100%' }}
      onClose={onClose}
    >
      <Typography text="Are you sure you want to discard all changes made in this draft?" />
    </Modal>
  );
};
