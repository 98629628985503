export const environment: AppEnvironment = {
  API_URL: process.env.REACT_APP_API_URL,
  CLOUDINARY_ENVIRONMENT: process.env.REACT_APP_CLOUDINARY_ENVIRONMENT,
  COOKIES_DOMAIN:
    process.env.REACT_APP_STAGE === 'local' ? 'localhost' : '.gantri.com',
  CORE_URL: process.env.REACT_APP_CORE_URL,
  IS_PRODUCTION: process.env.REACT_APP_STAGE === 'production',
  PUBNUB_CONFIG: {
    publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
    subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  },
  SHOP_URL: process.env.REACT_APP_SHOP_URL,
  STAGE: process.env.REACT_APP_STAGE as EnvironmentStage,
};

export type EnvironmentStage = 'local' | 'develop' | 'staging' | 'production';

interface AppEnvironment {
  API_URL: string;
  CLOUDINARY_ENVIRONMENT: string;
  COOKIES_DOMAIN: string;
  CORE_URL: string;
  IS_PRODUCTION: boolean;
  PUBNUB_CONFIG: { publishKey: string; subscribeKey: string };
  SHOP_URL: string;
  STAGE: EnvironmentStage;
}
