import React, { useEffect } from 'react';
import '@firebase/auth';
import {
  darkTheme,
  lightTheme,
  ModalProvider,
  ThemeProvider,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import Cookies from 'universal-cookie';
import { withRouter } from '../components/routes';
import { GlobalStyle } from '../styles';
import { useTemporalThemeSetup } from '../hooks/useTemporalThemeSetup';
import { ReactQueryProvider } from './react-query';
import { userAtoms } from '../global-atoms/users';
import { AppContent } from './components/app-content';
import { AppContentPropsDef } from './components/app-content/app-content.types';

// Buffer added to support pdf-merger-js
// eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
window.Buffer = window.Buffer || require('buffer').Buffer;

export const cookies = new Cookies();

const App = ({ location, navigate }: AppContentPropsDef) => {
  const theme = useRecoilValue(userAtoms.theme);

  useEffect(() => {
    document.body.scrollTo({ top: 0 });
  }, [location]);

  useTemporalThemeSetup(theme === 'dark' ? darkTheme : lightTheme);

  return (
    <ReactQueryProvider>
      <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
        <GlobalStyle />

        <ModalProvider rootComponent={React.Fragment}>
          <AppContent location={location} navigate={navigate} />
        </ModalProvider>
      </ThemeProvider>
    </ReactQueryProvider>
  );
};

export default withRouter(App) as any;
