import { Icon } from 'gantri-components';
import { MachineStatus, machineStatuses } from '../../../../constants/machines';

export const StatusIcon = ({ status }: { status: MachineStatus }) => {
  switch (status) {
    case machineStatuses.archived:
      return <Icon name="ui-control:minus_circle_filled" size="1.6rem" />;

    case machineStatuses.inProgress:
      return <Icon color="warning" name="time:clock" size="1.6rem" />;

    case machineStatuses.manual:
      return <Icon color="success" name="people:person" size="1.6rem" />;

    case machineStatuses.ready:
      return <Icon color="link" name="actions:thumbs_up" size="1.6rem" />;

    case machineStatuses.offline:
    default:
      return <Icon color="alert" name="ui-control:x" size="1.6rem" />;
  }
};
