import { FC } from 'react';
import { Box, Icon, Tooltip } from 'gantri-components';
import routePaths from '../../../../../../config/route-paths';
import {
  StyledTextAndIconContainer,
  StyledFieldIconWrapper,
  StyledTooltipTypography,
} from '../../custom-data-cell-styles';
import { formatDate } from '../../../../../../helpers/formatter';
import { MachineDataCellProps } from './machine-data-cell.types';
import { LONG_FORMAT } from '../../../../../../constants/dates';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import { MachineDetails } from '../../../modals/common/job-details-panel-large/components/machine-details';

export const MachineDataCell: FC<MachineDataCellProps> = ({
  assignedInfo,
  machine,
}) => {
  return (
    <StyledTextAndIconContainer>
      <MachineDetails {...machine} target="_blank" />

      {assignedInfo && assignedInfo.userId && (
        <Tooltip
          Component={
            <Box horizontalPadding="2x" verticalPadding="x">
              <StyledTooltipTypography
                color="t2"
                text={assignedInfo.description}
              />
              <StyledAnchor
                target="_blank"
                text={` ${assignedInfo.userName} `}
                to={`${routePaths.users}/${assignedInfo.userId}`}
              />
              <StyledTooltipTypography
                color="t2"
                text={formatDate(assignedInfo.assignedAt, LONG_FORMAT)}
              />
            </Box>
          }
          overlayContainerStyles={{ position: 'relative', zIndex: 2 }}
          position="top"
        >
          <StyledFieldIconWrapper>
            <Icon color="t1" name="alert:i_circle" />
          </StyledFieldIconWrapper>
        </Tooltip>
      )}
    </StyledTextAndIconContainer>
  );
};
