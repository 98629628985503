import {
  Conditional,
  Flex,
  Icon,
  Stack,
  Typography,
  TypographyColor,
} from 'gantri-components';
import { ModalContentHeadingProps } from './modal-content-heading.types';

export const ModalContentHeading = (props: ModalContentHeadingProps) => {
  const {
    subTitleHtmlText,
    subTitleText,
    titleHtmlText,
    titleText,
    ...typographyProps
  } = props;

  const { color } = typographyProps;

  const colorsWithIcon: TypographyColor[] = ['alert', 'warning', 'success'];
  const showIcon = colorsWithIcon.includes(color);

  return (
    <Stack gap="x">
      <Flex alignItems="center" gap="x">
        <Conditional condition={showIcon}>
          <Icon
            color={color}
            name={
              color === 'success'
                ? 'ui-control:check_mark_circle_filled_24'
                : 'alert:warning_triangle_24'
            }
            size="2.4rem"
          />
        </Conditional>
        <Typography
          {...typographyProps}
          htmlText={titleHtmlText}
          text={titleText}
          variant="h3"
        />
      </Flex>
      <Conditional condition={!!(subTitleText || subTitleHtmlText)}>
        <Typography
          {...typographyProps}
          htmlText={subTitleHtmlText}
          text={subTitleText}
        />
      </Conditional>
    </Stack>
  );
};
