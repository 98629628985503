import { Conditional, Typography } from 'gantri-components';
import { NotApplicableText } from '../../../../../../components/common/not-applicable-text';
import { formatAsLocaleNumber } from '../../../../../../helpers/formatter';
import { SandDurationCellPropsDef } from './sand-duration-cell.types';

export const SandDurationCell = (props: SandDurationCellPropsDef) => {
  const { sandDuration } = props;

  return (
    <Conditional condition={!!sandDuration} Fallback={<NotApplicableText />}>
      <Typography text={`${formatAsLocaleNumber(sandDuration)}m`} />
    </Conditional>
  );
};
