import { Icon } from 'gantri-components';
import { useTheme } from 'styled-components';
import { useRecoilState } from 'recoil';
import { StyledSidebarToggleWrapper } from './sidebar-toggle.styles';
import { uiAtoms } from '../../../../../global-atoms/ui';

export const SidebarToggle = () => {
  const theme = useTheme();

  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(
    uiAtoms.isSidebarOpen,
  );

  return (
    <StyledSidebarToggleWrapper
      isSidebarOpen={isSidebarOpen}
      onClick={() => {
        return setIsSidebarOpen(!isSidebarOpen);
      }}
    >
      <Icon
        color={theme.name === 'light' ? 't1' : 't2'}
        height="1.8rem"
        name={
          isSidebarOpen ? 'arrows:arrow_line_left' : 'arrows:arrow_line_right'
        }
      />
    </StyledSidebarToggleWrapper>
  );
};
