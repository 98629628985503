import { Conditional } from 'gantri-components';
import { DateWithUserDataCell } from '../../../../../../components/common/custom-data-cells/date-user-data-cell';
import { CellJobStartDateProps } from './cell-job-start-date.types';

export const CellJobStartDate = (props: CellJobStartDateProps) => {
  const {
    getValue,
    row: { original },
  } = props;

  const startDate = getValue();

  return (
    <Conditional condition={!!startDate}>
      <DateWithUserDataCell
        data={original.statusInfo}
        date={startDate}
        jobId={original.id}
        type="Start"
      />
    </Conditional>
  );
};
