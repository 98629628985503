import React, { FC } from 'react';
import {
  Conditional,
  FileUploader,
  FormikInput,
  Grid,
  HandleFileSelected,
  Icon,
  Radio,
  SelectedFileDetails,
  Typography,
} from 'gantri-components';
import { useField } from 'formik';
import {
  defaultFileInfo,
  shippingRadioOptions,
} from './shipping-section.constant';
import { useFirebaseFileUploader } from '../../../../hooks/use-firebase-file-uploader';
import { downloadBlob } from '../../../../helpers/file';

export const NewOrderShippingSection: FC = () => {
  const [{ value: shippingType }] = useField<string>('shippingType');
  const [{ value: file }, , helper] = useField<SelectedFileDetails>(
    'locationInstruction',
  );

  const isOtherShippingType = shippingType === 'other';

  const onFileSelected: HandleFileSelected = async (fileDetails) => {
    await helper.setValue(fileDetails);
  };

  const { fileUploaderProps } = useFirebaseFileUploader({
    onFileSelected,
  });

  return (
    <Grid gap="x">
      {shippingRadioOptions.map((op) => {
        return (
          <FormikInput
            key={op.value}
            Field={
              <Radio
                groupValue={op.value}
                labelText={op.text}
                name="shippingType"
                value={shippingType}
                onSelected={() => {}}
              />
            }
            name="shippingType"
          />
        );
      })}

      <Conditional condition={isOtherShippingType}>
        <FileUploader
          {...(fileUploaderProps as any)}
          buttonProps={{
            text: 'Add Shipping Information',
          }}
          CustomContent={({ fileName }) => {
            return (
              <Grid
                alignItems="center"
                columns="max-content max-content"
                gap="x"
              >
                <Typography
                  color="link"
                  decoration="underline"
                  icon={<Icon color="link" name="arrows:arrow_download" />}
                  iconPosition="left"
                  text={fileName}
                  onClick={() => {
                    downloadBlob(file.fileBlob, fileName);
                  }}
                />

                <Icon
                  color="alert"
                  cursor="pointer"
                  name="actions:trash_can"
                  onClick={() => {
                    helper.setValue(defaultFileInfo);
                  }}
                />
              </Grid>
            );
          }}
          expectedExtensions={['pdf']}
          fileName={file.fileName}
          variant={!!file.fileBlob ? 'custom' : 'link'}
        />
      </Conditional>

      <Typography color="t2" text="Required" variant="p3" />
    </Grid>
  );
};
