import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { convertQueriesToString } from '../../../../helpers/checks';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import {
  GetPaginatedJobsArgs,
  GetPaginatedJobsResponse,
} from './get-paginated-jobs.types';

export const getPaginatedJobs = ({
  assignedTo = [],
  attentionOnly = false,
  colors = [],
  completedDateRange,
  count = 100,
  createdDateRange,
  locations = [],
  machineIssues = [],
  machineTypes = [],
  materials = [],
  page = 1,
  queries = {},
  search = '',
  sortBy = '',
  statuses = [],
  steps = [],
  types = [],
  userIds = [],
  userStatuses = [],
  ...rest
}: GetPaginatedJobsArgs) => {
  return axios.post<GetPaginatedJobsResponse>(
    `${coreApiUrl}/jobs/paginatedJobs${convertQueriesToString(queries)}`,
    {
      ...rest,
      assignedTo,
      attentionOnly,
      colors,
      completedEndDate: completedDateRange?.to,
      completedStartDate: completedDateRange?.from,
      count,
      createdEndDate: createdDateRange?.to,
      createdStartDate: createdDateRange?.from,
      locations,
      machineIssues,
      machineTypes,
      materials,
      page,
      search,
      sortBy,
      statuses,
      steps,
      types,
      userIds,
      userStatuses,
    },
  );
};

const query = (args: GetPaginatedJobsArgs) => {
  return getPaginatedJobs(args).then(({ data }) => {
    return data;
  });
};

export const fetchPaginatedJobs = getGenericQueryObj({
  groupName: 'paginated-jobs',
  query,
});
