import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { FetchActiveProductsAndDesignersResponse } from './fetch-active-products-and-designers.types';
import { fetchActiveProductsAndDesigners } from './fetch-active-products-and-designers';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';

export const useFetchActiveProductsAndDesignersQuery = <
  TransformedData = FetchActiveProductsAndDesignersResponse,
>(
  props?: GenericFetchProps<
    never,
    FetchActiveProductsAndDesignersResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch active products and designers.',
    queryObj: fetchActiveProductsAndDesigners,
    ...props,
  });
};

export const useInvalidateFetchActiveProductsAndDesignersCache = () => {
  const invalidateFetchActiveProductsAndDesignersCache =
    useGetInvalidateQueryCache(fetchActiveProductsAndDesigners);

  return { invalidateFetchActiveProductsAndDesignersCache };
};
