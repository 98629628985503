import { Stack, Typography } from 'gantri-components';
import { machineStatuses } from '../../../../../../../constants/machines';
import { ModalContentHeading } from '../../../../../../jobs/components/modals/common/modal-content-heading';

export const ConfirmSetMachinesOfflineContent = () => {
  return (
    <Stack gap="x">
      <ModalContentHeading titleText="Offline machines" />
      <Typography
        htmlText={[
          'Are you sure you want to offline machines?',
          `Once you confirm,  system will attempt to update any '${machineStatuses.ready}' and '${machineStatuses.manual}' machines to <strong>'${machineStatuses.offline}'</strong> status.`,
        ].join('<br/><br/>')}
      />
    </Stack>
  );
};
