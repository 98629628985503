import { Button, Conditional, Flex } from 'gantri-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { sortBy } from 'lodash';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import {
  useInvalidateFetchProductCache,
  useUpdateMarketplaceColors,
} from '../../../../../../../../../../../../api/products/routes';
import { ActionsCellProps } from './actions-cell.types';
import { productInfoTabAtoms } from '../../../../../../../../info.atoms';

export const ActionsCell = (props: ActionsCellProps) => {
  const { code, defaultSku, isAddingNewColor, isNew } = props;

  const product = useRecoilValue(productAtoms.product);
  const [marketplaceColors, setMarketplaceColors] = useRecoilState(
    productInfoTabAtoms.marketplaceColors,
  );

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();

  const { onUpdateMarketplaceColors } = useUpdateMarketplaceColors({
    onSuccess: async () => {
      await invalidateFetchProductCache();
    },
  });

  const handleRemove = async () => {
    const colors = product.colors.filter((color) => {
      return color.code !== code;
    });

    await onUpdateMarketplaceColors({
      colors,
      id: product.id,
    });
  };

  const handleCancel = () => {
    setMarketplaceColors((colors) => {
      return colors.filter(({ isNew }) => {
        return !isNew;
      });
    });
  };

  const handleAdd = async () => {
    const purifiedColors = sortBy(
      marketplaceColors.map(({ isNew: _isNew, ...rest }) => {
        return rest;
      }),
      'name',
    );

    await onUpdateMarketplaceColors({
      colors: purifiedColors,
      id: product.id,
    });
  };

  return (
    <Conditional
      condition={isNew}
      Fallback={
        <Conditional condition={product.isPainted}>
          <Button
            disabled={isAddingNewColor || product.colors.length <= 1}
            text="Remove"
            variant="tertiary"
            onClick={handleRemove}
          />
        </Conditional>
      }
    >
      <Flex gap="x">
        <Button text="Cancel" variant="secondary" onClick={handleCancel} />
        <Button
          disabled={!code || !defaultSku}
          text="Add"
          onClick={handleAdd}
        />
      </Flex>
    </Conditional>
  );
};
