import { atom } from 'recoil';
import { Product } from '../../api/products/products.types';
import { ProductStatus } from '../../api/products/routes/get-all/get-all.types';
import { getGenericAtomFamily } from '../../helpers/get-generic-atom-family';

const atomKeyPrefix = 'PRODUCT_PAGE';

export const productAtoms = {
  isAccessory: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-accessory`,
  }),
  isPendantLight: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-pendant-light`,
  }),
  isWallLight: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-wall-light`,
  }),
  isWallSconce: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-wall-sconce`,
  }),
  product: atom<Product>({
    default: null,
    key: `${atomKeyPrefix}-product`,
  }),
  productStatus: atom<ProductStatus>({
    default: null,
    key: `${atomKeyPrefix}-product-status`,
  }),
  /** Use `useHandleSetSelectedSku` to set this value in order to maintain `sku` search query. */
  selectedSku: getGenericAtomFamily<string>({
    defaultValue: '',
    key: `${atomKeyPrefix}-selected-sku`,
  })('product'),
  skus: atom<string[]>({
    default: [],
    key: `${atomKeyPrefix}-skus`,
  }),
};
