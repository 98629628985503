import { useMemo } from 'react';
import { Typography } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useRecoilValue } from 'recoil';
import { formatAsCurrency } from '../../../../../../helpers/formatter';
import routePaths from '../../../../../../config/route-paths';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import { getIsClickableIfValue } from '../../../../../../helpers/get-is-clickable-if-value';
import { BomInventory } from '../../../../../../api/products/products.types';
import { productAtoms } from '../../../../product.atoms';

export const useBomColumns = () => {
  const product = useRecoilValue(productAtoms.product);

  const bomColumns = useMemo(() => {
    const columns: ColumnDef<BomInventory>[] = [
      {
        accessorKey: 'id',
        cell: ({ getValue }: CellContext<BomInventory, BomInventory['id']>) => {
          const id = getValue();

          return (
            <StyledAnchor
              text={`#${id}`}
              to={`${routePaths.inventories}/${id}`}
            />
          );
        },
        header: 'ID',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
        size: 60,
      },
      {
        accessorKey: 'name',
        footer: 'TOTAL',
        header: 'Inventory',
        size: 250,
      },
      {
        accessorKey: 'type',
        header: 'Type',
      },
      {
        accessorKey: 'cost',
        cell: ({
          getValue,
        }: CellContext<BomInventory, BomInventory['cost']>) => {
          return (
            <Typography
              text={formatAsCurrency(getValue(), { isCents: true })}
            />
          );
        },
        header: 'Unit Cost',
      },
      {
        accessorKey: 'units',
        header: 'Units',
      },
      {
        accessorKey: 'totalCost',
        cell: ({
          getValue,
        }: CellContext<BomInventory, BomInventory['totalCost']>) => {
          return (
            <Typography
              text={formatAsCurrency(getValue(), { isCents: true })}
            />
          );
        },
        footer: () => {
          return (
            <Typography
              text={formatAsCurrency(product?.bomTotal?.totalCost, {
                isCents: true,
              })}
            />
          );
        },
        header: 'Total Cost',
      },
      {
        accessorKey: 'percent',
        cell: ({
          getValue,
        }: CellContext<BomInventory, BomInventory['percent']>) => {
          return <Typography text={`${getValue() || 0}%`} />;
        },
        footer: () => {
          return <Typography text={`${product?.bomTotal?.percent || 0}%`} />;
        },
        header: '% of total',
      },
    ];

    return columns;
  }, [product?.bomTotal]);

  return bomColumns;
};
