import {
  Button,
  Cell,
  Conditional,
  Flex,
  Grid,
  Tooltip,
} from 'gantri-components';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useFormikContext } from 'formik';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { partsApi } from '../../../../api';
import { PageHeading } from '../../../../components/layout/page-heading';
import routePaths from '../../../../config/route-paths';
import MetaData from '../../../../components/meta-data';
import { useNotification } from '../../../../hooks/useNotification';
import { ProgressTracker } from '../../../../components/common/progress-tracker';
import { BatchPartCreationFormik } from '../../batch-part-creation.types';
import { progressTrackerItems } from './batch-part-creation-header.constants';
import { partsPageAtoms } from '../../../../components/dropdowns/parts-filter/parts-filter.atoms';
import { productJobsTabAtoms } from '../../../product/components/product-jobs/product-jobs.atoms';
import { JobTemplateWithId } from '../../../../components/common/jobs-template-table/jobs-template-table.types';
import { useRouter } from '../../../../hooks';
import { useResetRecoilAtoms } from '../../../../hooks/use-reset-recoil-atoms';
import { getTemplateEditDisabledReason } from '../../../../components/common/jobs-template-table/helpers/get-template-edit-disabled-reason';
import { batchPartCreationAtoms } from '../../batch-part-creation.atoms';
import { unpaintedColor } from '../../../../constants/colors';
import { jobSteps, jobTypeOptions } from '../../../../constants/options';
import { useFetchProductPartTemplates } from '../../../../api/products/routes';

export const BatchPartCreationHeader = () => {
  const resetPartsPageFilters = useResetRecoilAtoms(partsPageAtoms.filters);
  const setPartsPageSearch = useSetRecoilState(partsPageAtoms.search);

  const isEditingJobs = useRecoilValue(productJobsTabAtoms.isEditingJobs);
  const selectedProductDetails = useRecoilValue(
    batchPartCreationAtoms.selectedProductDetails,
  );

  const [jobs, setJobs] = useRecoilState(batchPartCreationAtoms.jobs);
  const [partTemplateId, setPartTemplateId] = useRecoilState(
    batchPartCreationAtoms.partTemplateId,
  );
  const [partThumbnail, setPartThumbnail] = useRecoilState(
    batchPartCreationAtoms.partThumbnail,
  );
  const [step, setStep] = useRecoilState(batchPartCreationAtoms.step);
  const setNavigateTo = useSetRecoilState(batchPartCreationAtoms.navigateTo);

  const { hideLoading, notify, notifyAxiosError, showLoading } =
    useNotification();

  const { navigate } = useRouter();

  const [isMissingRequiredFields, setIsMissingRequiredFields] =
    useState<boolean>(false);

  const [isMissingPrintTransferJob, setIsMissingPrintTransferJob] =
    useState<boolean>(false);

  const [isMissingPrintPrintJob, setIsMissingPrintPrintJob] =
    useState<boolean>(false);

  const [isMissingJobs, setIsMissingJobs] = useState<boolean>(false);

  const { setFieldValue, values: formikValues } =
    useFormikContext<BatchPartCreationFormik>();

  const {
    batchPartName,
    batchPartNotes,
    batchQty,
    colorCode,
    material,
    partName,
    productId,
    template,
  } = formikValues;

  const pageTitle = 'Create New Part(s)';

  const handleBack = () => {
    if (step === 'CONFIRM_BATCH_CREATION') {
      setStep(template);
    } else {
      setStep('SELECT_TEMPLATE');
    }
  };

  const handleNext = async () => {
    if (step === 'SELECT_TEMPLATE') {
      if (template === 'CREATE_FROM_PRODUCT') {
        await onFetchProductPartTemplates({
          color: colorCode,
          partName,
          versionId: selectedProductDetails?.versionId,
        });
      } else {
        setStep(template);
      }
    } else if (isOnEditPanel) {
      setStep('CONFIRM_BATCH_CREATION');
    } else if (step === 'CONFIRM_BATCH_CREATION') {
      await handleCreateBatch();
    }
  };

  const { onFetchProductPartTemplates } = useFetchProductPartTemplates({
    onSuccess: async (data) => {
      if (data) {
        const jobTemplates: JobTemplateWithId[] = data.jobTemplates.map(
          (template) => {
            return {
              ...template,
              id: uuidv4(),
            };
          },
        );

        await setFieldValue('material', data.partTemplate.material);

        setJobs(jobTemplates);
        setPartTemplateId(data.partTemplate.id);
        setPartThumbnail({
          relativePath: null,
          url: data.partTemplate.thumbnail,
        });

        setStep(template);
      }
    },
  });

  const handleCreateBatch = async () => {
    try {
      showLoading();

      const { data } = await partsApi.createParts({
        jobs: jobs.map((job, index) => {
          return { ...job, sortOrder: index + 1 };
        }),
        partInfo: {
          color: colorCode || unpaintedColor.code,
          material,
          name: batchPartName,
          notes: batchPartNotes,
          partTemplateId,
          thumbnail: partThumbnail?.url,
          versionId: selectedProductDetails?.versionId,
        },
        quantity: batchQty,
      });

      notify(data.notice);

      if (data.partId) {
        setNavigateTo(`${routePaths.parts}/${data.partId}`);
      } else {
        resetPartsPageFilters();
        setPartsPageSearch(batchPartName);

        setNavigateTo(routePaths.parts);
      }
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to create parts.',
      });
    } finally {
      hideLoading();
    }
  };

  const handleDisableNextBtn = () => {
    const creatingFromScratch = template === 'CREATE_FROM_SCRATCH';

    if (step === 'SELECT_TEMPLATE') {
      const isMissingRequiredFields = creatingFromScratch
        ? false
        : !colorCode || !partName || !productId;

      setIsMissingRequiredFields(isMissingRequiredFields);
    } else if (isOnEditPanel) {
      if (creatingFromScratch) {
        const isMissingRequiredFields =
          !material || !colorCode || !batchPartName || !batchQty;

        setIsMissingRequiredFields(isMissingRequiredFields);
      } else {
        const isMissingRequiredFields =
          !colorCode ||
          !batchPartName ||
          !productId ||
          !batchPartName ||
          !batchQty;

        setIsMissingRequiredFields(isMissingRequiredFields);
      }

      const isMissingJobs = !jobs.length;

      setIsMissingJobs(isMissingJobs);

      const emptyPrintJobs = !jobs.some(({ type }) => {
        return type === jobTypeOptions.print;
      });

      const isMissingPrintTransferJob =
        !emptyPrintJobs &&
        !jobs.find(({ step, type }) => {
          return step === jobSteps.print && type === jobTypeOptions.transfer;
        });

      setIsMissingPrintTransferJob(isMissingPrintTransferJob);

      const isMissingPrintPrintJob =
        !emptyPrintJobs &&
        !jobs.find(({ step, type }) => {
          return step === jobSteps.print && type === jobTypeOptions.print;
        });

      setIsMissingPrintPrintJob(isMissingPrintPrintJob);
    } else if (step === 'CONFIRM_BATCH_CREATION') {
      setIsMissingRequiredFields(false);
    } else {
      setIsMissingRequiredFields(true);
    }
  };

  useEffect(() => {
    handleDisableNextBtn();
  }, [
    formikValues,
    jobs,
    partTemplateId,
    partThumbnail,
    step,
    isEditingJobs,
    selectedProductDetails,
  ]);

  const isOnEditPanel = ['CREATE_FROM_SCRATCH', 'CREATE_FROM_PRODUCT'].includes(
    step,
  );

  const disableNextBtn =
    isEditingJobs ||
    isMissingRequiredFields ||
    (isOnEditPanel &&
      (isMissingJobs || isMissingPrintTransferJob || isMissingPrintPrintJob));

  return (
    <>
      <MetaData title={pageTitle} />
      <PageHeading
        columns={{ lg: 'max-content 1fr' }}
        ContentBelow={
          <Cell hidden={{ lg: true, sm: false }} paddingBottom="1.6rem">
            <ProgressTracker activeStep={step} items={progressTrackerItems} />
          </Cell>
        }
        title={pageTitle}
      >
        <Grid columns={{ lg: '1fr 15rem', sm: '1fr' }}>
          <Cell hidden={{ lg: false, sm: true }}>
            <ProgressTracker activeStep={step} items={progressTrackerItems} />
          </Cell>
          <Flex gap="0.8rem" justifyContent="flex-end" width="unset">
            <Conditional
              condition={step === 'SELECT_TEMPLATE'}
              Fallback={
                <Tooltip
                  description={getTemplateEditDisabledReason({
                    isEditing: isEditingJobs,
                  })}
                  position="left"
                >
                  <Button
                    disabled={isEditingJobs}
                    text="Back"
                    variant="secondary"
                    onClick={handleBack}
                  />
                </Tooltip>
              }
            >
              <Button
                text="Cancel"
                variant="secondary"
                onClick={() => {
                  navigate(routePaths.parts);
                }}
              />
            </Conditional>
            <Tooltip
              description={getTemplateEditDisabledReason(
                { isEditing: isEditingJobs },
                {
                  reason: 'Missing required fields.',
                  show: isMissingRequiredFields,
                },
                {
                  reason: 'Missing jobs.',
                  show: isMissingJobs,
                },
                {
                  reason: `Missing ${jobSteps.print} ${jobTypeOptions.transfer} job.`,
                  show: isMissingPrintTransferJob,
                },
                {
                  reason: `Missing ${jobSteps.print} ${jobTypeOptions.print} job.`,
                  show: isMissingPrintPrintJob,
                },
                {
                  reason:
                    'Verify your jobs are in the appropriate order before continuing.',
                  show: isOnEditPanel,
                },
              )}
              position="bottom-end"
            >
              <Button
                disabled={disableNextBtn}
                text={step === 'CONFIRM_BATCH_CREATION' ? 'Create' : 'Next'}
                onClick={handleNext}
              />
            </Tooltip>
          </Flex>
        </Grid>
      </PageHeading>
    </>
  );
};
