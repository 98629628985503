import { Conditional, Stack, Typography } from 'gantri-components';
import { useFormikContext } from 'formik';
import { InventoryDataCell } from '../../../../../../../../../../components/common/custom-data-cells/inventory-data-cell';
import {
  convertStockJobInventoriesToInventoryRequests,
  StockJobsAssembleInventoryFormData,
} from '../../stock-jobs-assemble-inventory.schema';
import { StockJobInventory } from '../../../../../../../../../../api/products/products.types';
import { Label } from '../../../../../../../../../../components/label';
import { AssembleInventoryStandardInventorySectionProps } from './assemble-inventory-standard-inventory-section.types';

export const AssembleInventoryStandardInventorySection = (
  props: AssembleInventoryStandardInventorySectionProps,
) => {
  const { isEditing } = props;

  const { setFieldValue, values } =
    useFormikContext<StockJobsAssembleInventoryFormData>();

  const { assembleStandardInventories } = values;

  const assembleStandardInventoryRequests =
    convertStockJobInventoriesToInventoryRequests(assembleStandardInventories);

  return (
    <Stack gap=".5x">
      <Label text="Standard Inventory" />

      <Conditional
        condition={isEditing || !!assembleStandardInventories.length}
        Fallback={<Typography text="-" />}
      >
        <InventoryDataCell
          editing={isEditing}
          hideExceedsStockIcon
          inventoryRequests={assembleStandardInventoryRequests}
          onEdit={async (updatedInventoryRequests) => {
            const assembleStandardInventories: StockJobInventory[] =
              updatedInventoryRequests.map(({ inventory, need }) => {
                const { id, ...rest } = inventory;
                const stockJobInventory: StockJobInventory = {
                  amount: Number(need || 0),
                  inventoryId: id as number,
                  ...rest,
                };

                return stockJobInventory;
              });

            await setFieldValue(
              'assembleStandardInventories',
              assembleStandardInventories,
            );
          }}
        />
      </Conditional>
    </Stack>
  );
};
