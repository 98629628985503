import {
  Button,
  Cell,
  Conditional,
  Flex,
  Grid,
  Icon,
  Tooltip,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { StyledVersionTypography } from '../../product-jobs-header.styles';
import { MoreMenu } from '../../../../../../../../components/dropdowns';
import { DraftVersionHeadingProps } from './draft-version-heading.types';
import { productJobsTabAtoms } from '../../../../product-jobs.atoms';
import { getTemplateEditDisabledReason } from '../../../../../../../../components/common/jobs-template-table/helpers/get-template-edit-disabled-reason';
import { productAtoms } from '../../../../../../product.atoms';
import { getIsStockJobsDurationValid } from '../../../stock-jobs-section/helpers/get-is-stock-jobs-block-valid';

export const DraftVersionHeading = (props: DraftVersionHeadingProps) => {
  const { productVersion, showDiscardDraftModal, showPublishModal } = props;

  const isEditingJobs = useRecoilValue(productJobsTabAtoms.isEditingJobs);
  const invalidPartIds = useRecoilValue(productJobsTabAtoms.invalidPartIds);

  const product = useRecoilValue(productAtoms.product);
  const isAccessory = useRecoilValue(productAtoms.isAccessory);

  const { type: versionType, version } = productVersion || {};
  const isMinorDraftVersion = versionType === 'Minor';

  const hasInvalidJobBlocks = useMemo(() => {
    const isPartJobBlocksInvalid =
      !!Object.values(invalidPartIds).filter(Boolean).length;

    const isStockJobsDurationInvalid = !getIsStockJobsDurationValid({
      isAccessory,
      stockJobsBlock: product.stockBlock,
    });

    return isPartJobBlocksInvalid || isStockJobsDurationInvalid;
  }, [product, invalidPartIds]);

  const disabledPublishReason = getTemplateEditDisabledReason({
    hasInvalidJobBlocks,
    isEditing: isEditingJobs,
  });

  return (
    <Grid alignItems="center" columns="1fr max-content" gap="s1">
      <Conditional
        condition={isMinorDraftVersion}
        Fallback={
          <StyledVersionTypography
            htmlText={`Draft <span data-color="gold">major version</span> v${version}`}
            variant="h5"
          />
        }
      >
        <Flex alignItems="center" gap="0.8rem">
          <StyledVersionTypography
            htmlText={`Draft <span data-color="green">minor version</span> v${version}`}
            variant="h5"
          />
          <Tooltip description="You can make changes to instructions, inventories, duration, and thumbnails only.">
            <Icon name="alert:i_circle" top="2px" />
          </Tooltip>
        </Flex>
      </Conditional>
      <Flex gap="0.8rem" hidden={{ lg: false, sm: true }}>
        <Tooltip description={disabledPublishReason} position="top">
          <Button
            disabled={!!disabledPublishReason}
            text="Publish Draft"
            variant="primary"
            onClick={showPublishModal}
          />
        </Tooltip>

        <Button
          text="Discard Draft"
          variant="primaryAlert"
          onClick={showDiscardDraftModal}
        />
      </Flex>
      <Cell hidden={{ lg: true, sm: false }}>
        <MoreMenu
          options={[
            {
              disabledTooltipProps: { description: disabledPublishReason },
              enabled: !disabledPublishReason,
              name: 'Publish Draft',
              onOptionClick: showPublishModal,
            },
            {
              enabled: true,
              name: 'Discard Draft',
              onOptionClick: showDiscardDraftModal,
            },
          ]}
        />
      </Cell>
    </Grid>
  );
};
