import * as yup from 'yup';
import { required } from '../../../../helpers/validators';

export const NewVendorFormSchema = yup.object().shape({
  city: required(),
  contact: required(),
  country: required(),
  email: required().email(),
  name: required(),
  state: required(),
});
