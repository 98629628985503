import React, { useEffect, useState } from 'react';
import firebase from '@firebase/app';
import { Conditional, Grid } from 'gantri-components';
import Div100vh from 'react-div-100vh';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import axios, { AxiosError } from 'axios';
import { ErrorFallback, errorHandler } from '../../../components';
import { NetworkErrorMessage } from '../network-error';
import {
  getTokenCookie,
  isValidAdminRoleType,
  REDIRECTED_FOR_AUTHENTICATION,
  redirectToMantle,
} from '../../../helpers/auth';
import debounce from '../../../helpers/debounce';
import firebaseConfig from '../../../config/firebase';
import { environment } from '../../../environment';
import { Notification } from '../../../components/notification';
import { FullPageLoadingSpinner } from '../../../components/common/full-page-loading-spinner';
import { DesktopSidebar } from '../../sidebar/desktop-sidebar';
import { MobileHeader } from '../../sidebar/mobile-header';
import { clearAllCache } from '../../../helpers/versioning';
import { StyledAppContainer } from '../../app.styles';
import { useNotification } from '../../../hooks/useNotification';
import {
  sidebarCollapseThreshold,
  tabletThreshold,
  uiAtoms,
} from '../../../global-atoms/ui';
import { userAtoms } from '../../../global-atoms/users';
import {
  ADMIN_ROLE_TYPE,
  STD_COOKIE_OPTIONS,
} from '../../../constants/environment';
import {
  useGetAdminUser,
  useInvalidateGetAdminUserCache,
} from '../../../api/users/routes';
import { cookies } from '../../index';
import { AppContentPropsDef } from './app-content.types';
import { useGetAdminVersion } from '../../../api/dashboard/routes/get-version';
import routePaths from '../../../config/route-paths';

export const AppContent = (props: AppContentPropsDef) => {
  const { navigate } = props;

  const [showError, setShowError] = useState<boolean>(false);

  const [version, setVersion] = useState('');

  const [viewportWidth, setViewportWidth] = useRecoilState(
    uiAtoms.viewportWidth,
  );
  const setUser = useSetRecoilState(userAtoms.user);
  const setAddresses = useSetRecoilState(userAtoms.addresses);
  const setFirebaseToken = useSetRecoilState(userAtoms.firebaseToken);
  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(
    uiAtoms.isSidebarOpen,
  );

  const { notify } = useNotification();

  const { invalidateGetAdminUserCache } = useInvalidateGetAdminUserCache();

  const requireLogin = !getTokenCookie();

  const { data } = useGetAdminUser({
    enabled: !requireLogin,
    onError: (error: unknown) => {
      if (axios.isAxiosError(error)) {
        const typedError = error as AxiosError<{ error?: string }>;
        const isUnauthorized = typedError?.response?.status === 401;

        if (isUnauthorized) {
          redirectToMantle();
        } else {
          setShowError(true);
        }
      } else {
        setShowError(true);
      }
    },
    onSuccess: async ({ data, firebaseToken }) => {
      try {
        if (isValidAdminRoleType(data.type)) {
          setUser(data);
          setAddresses(data.addresses);
          setFirebaseToken(firebaseToken);
          cookies.set(ADMIN_ROLE_TYPE, data.type, STD_COOKIE_OPTIONS);
        } else {
          redirectToMantle();
        }

        if (firebaseToken) {
          try {
            firebase.initializeApp(firebaseConfig[environment.STAGE]);
            await firebase.auth().signInWithCustomToken(firebaseToken);
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('firebase authentication error: ', error);
            setShowError(true);
          }
        }

        if (localStorage.getItem(REDIRECTED_FOR_AUTHENTICATION) && data) {
          localStorage.removeItem(REDIRECTED_FOR_AUTHENTICATION);

          navigate(routePaths.main);
        }

        setShowError(false);
      } catch (error) {
        setShowError(true);
      }
    },
  });

  const isLoggedIn = !!data;

  useGetAdminVersion({
    enabled: !requireLogin,
    onSuccess: async ({ lastTag }) => {
      if (lastTag) {
        const lastReportedVersion = localStorage.getItem('VERSION');

        const version = lastReportedVersion || lastTag;

        // eslint-disable-next-line no-console
        console.log(`Core is running at ${version}`);

        setVersion(version);

        if (lastTag !== lastReportedVersion) {
          notify(`${lastTag} now available. Clearing cache.`);

          clearAllCache();
          localStorage.setItem('VERSION', lastTag);
        }
      }
    },
  });

  const handleWindowResize = () => {
    const debouncedResize = debounce(() => {
      setViewportWidth(window.innerWidth);
    }, 50);

    debouncedResize();
  };

  const appDataAttrs = {
    'data-app-container': '',
  };

  const isDesktop = viewportWidth > tabletThreshold;

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();

    return () => {
      return window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const sidebarShouldBeOpen = viewportWidth > sidebarCollapseThreshold;

    setIsSidebarOpen(sidebarShouldBeOpen);
  }, [viewportWidth]);

  useEffect(() => {
    if (requireLogin) {
      redirectToMantle();
    }
  }, [requireLogin]);

  return isLoggedIn || showError ? (
    <>
      <Notification />
      <FullPageLoadingSpinner />
      <Div100vh>
        <Grid
          columns={{ lg: 'max-content 1fr', md: '1fr' }}
          gap="unset"
          height="100%"
        >
          <Conditional
            condition={isDesktop}
            Fallback={<MobileHeader version={version} />}
          >
            <DesktopSidebar version={version} />
          </Conditional>

          <StyledAppContainer
            isDesktop={isDesktop}
            isSidebarOpen={isSidebarOpen}
            {...appDataAttrs}
          >
            <Conditional
              condition={showError}
              Fallback={
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onError={errorHandler}
                  onReset={invalidateGetAdminUserCache}
                >
                  <Outlet />
                </ErrorBoundary>
              }
            >
              <NetworkErrorMessage />
            </Conditional>
          </StyledAppContainer>
        </Grid>
      </Div100vh>
    </>
  ) : null;
};
