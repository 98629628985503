import { Conditional, Flex, Typography } from 'gantri-components';
import { Link } from 'react-router-dom';
import TimelineUserInfo from '../../../../../../components/common/timeline/user-info';
import { formatDate } from '../../../../../../helpers/formatter';
import { LONG_FORMAT } from '../../../../../../constants/dates';
import { Timeline } from '../../../../../../api/jobs/jobs.types';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import routePaths from '../../../../../../config/route-paths';

export const TimelineItem = ({
  createdAtSec,
  description,
  email,
  extra,
  firstName,
  lastName,
  status,
  userId,
}: Timeline) => {
  const descriptionEndsWithBy = description.trim().match(/by$/i);
  let showOrderLink = false;

  if (
    extra &&
    extra.orderId &&
    (description === 'Unassigned from' || description === 'Assigned to')
  ) {
    showOrderLink = true;
  } else if (description.indexOf('Unassigned from ') === 0) {
    showOrderLink = true;
    description = 'Unassigned from';
  }

  return (
    <div>
      <Typography
        display="inline"
        text={formatDate(createdAtSec, LONG_FORMAT)}
      />
      <Flex>
        <Typography color="t1" display="inline" text={description} />
        <Conditional condition={!!extra?.batchId}>
          &nbsp;
          <StyledAnchor
            href={`${routePaths.batches}/${extra?.batchId}`}
            text={`#${extra?.batchId}`}
          />
        </Conditional>
        <Conditional
          condition={showOrderLink}
          Fallback={
            <Conditional condition={!!userId}>
              <Conditional condition={!descriptionEndsWithBy}>
                &nbsp;
                <Typography color="t1" display="inline" text="by" />
              </Conditional>
              &nbsp;
              <TimelineUserInfo
                email={email}
                firstName={firstName}
                lastName={lastName}
                userId={userId}
              />
            </Conditional>
          }
        >
          &nbsp;
          <Link to={`${routePaths.orders}/${extra?.orderId}`}>
            <Typography
              color="link"
              display="inline"
              text={`#${extra?.orderId}`}
            />
          </Link>
        </Conditional>

        {['Manually assigned', 'Manually unassigned'].includes(status) && (
          <>
            {!descriptionEndsWithBy && (
              <>
                &nbsp;
                <Typography color="t1" display="inline" text="by" />
              </>
            )}
            &nbsp;
            <Link to={`${routePaths.users}/${userId}`}>
              <Typography
                color="link"
                display="inline"
                text={`${firstName} ${lastName}`}
              />
            </Link>
          </>
        )}
      </Flex>
    </div>
  );
};
