import { Dropdown } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { pressPageAtoms, pressPageAtomsDefaults } from '../../press.atoms';
import { pressRecordTypeItems } from '../../press.constants';
import { DatePicker } from '../../../../components/common/date-picker/date-picker';
import { FilterResetLabel } from '../../../../components/common/filter-reset-label';
import {
  FilterGrid,
  FilterRow,
} from '../../../../components/common/filter-grid';

export const PressPageFilters = () => {
  const [dateRange, setDateRange] = useRecoilState(
    pressPageAtoms.filters.dateRange,
  );
  const [type, setType] = useRecoilState(pressPageAtoms.filters.type);

  const placeholder = 'Select type...';

  return (
    <FilterGrid isSmallFormat>
      <FilterRow columns={1}>
        <FilterResetLabel
          atom={pressPageAtoms.filters.dateRange}
          default={pressPageAtoms.defaults.filters.dateRange}
          text="Date range"
        />
        <DatePicker
          initialValue={dateRange}
          range
          onDateChanged={({ formattedFrom, formattedTo }) => {
            setDateRange({
              from: formattedFrom,
              to: formattedTo,
            });
          }}
        />
      </FilterRow>

      <FilterRow columns={1}>
        <FilterResetLabel
          atom={pressPageAtoms.filters.type}
          default={pressPageAtoms.defaults.filters.type}
          text="Type"
        />
        <Dropdown
          items={[
            { label: placeholder, value: pressPageAtomsDefaults.filters.type },
            ...pressRecordTypeItems,
          ]}
          placeholder={placeholder}
          value={type}
          onSelect={(item) => {
            setType(item?.value);
          }}
        />
      </FilterRow>
    </FilterGrid>
  );
};
