import { ProductColorDetail } from 'gantri-components';
import { FieldArrayRenderProps } from 'formik';
import { sortBy } from 'lodash';
import { PartTemplateColorOverride } from '../../../../../../../../../../../../../../api/products/products.types';
import { deselectPartColorOverrideItem } from '../../part-color-overrides.constants';

interface GetFromDropdownItemsProps extends PartTemplateColorOverride {
  colorOverride: PartTemplateColorOverride[];
  items: ProductColorDetail[];
}

export const getFromDropdownItems = (props: GetFromDropdownItemsProps) => {
  const { colorOverride, from, items, to } = props;

  let fromItems = items.filter((item) => {
    const matchesToColor = item?.code === to?.code;
    const matchesOtherFromColor = colorOverride.some(({ from }) => {
      return item?.code === from.code;
    });

    return !(matchesToColor || matchesOtherFromColor);
  });

  if (from?.code) {
    const selectedColorItem = items.find(({ code }) => {
      return code === from.code;
    });

    fromItems = sortBy([...fromItems, selectedColorItem], 'shortColorName');
  }

  return fromItems;
};

interface GetToDropdownItemsProps
  extends Pick<PartTemplateColorOverride, 'from'> {
  items: ProductColorDetail[];
}

export const getToDropdownItems = (props: GetToDropdownItemsProps) => {
  const { from, items } = props;

  const toItems = [
    deselectPartColorOverrideItem,
    ...items.filter((item) => {
      const matchesFromColor = item?.code === from?.code;

      return !matchesFromColor;
    }),
  ];

  return toItems;
};

interface GetHandleSelectFromItemProps
  extends Pick<FieldArrayRenderProps, 'replace'> {
  colorOverride: PartTemplateColorOverride[];
}

export const getHandleSelectFromItem = (
  props: GetHandleSelectFromItemProps,
) => {
  const { colorOverride, replace } = props;

  return (props: { index: number; item: ProductColorDetail }) => {
    const { index, item } = props;

    replace<PartTemplateColorOverride>(index, {
      ...colorOverride[index],
      from: {
        code: item?.code,
        name: item?.shortColorName,
      },
    });
  };
};

interface GetHandleSelectToItemProps
  extends Pick<FieldArrayRenderProps, 'replace'> {
  colorOverride: PartTemplateColorOverride[];
}

export const getHandleSelectToItem = (props: GetHandleSelectToItemProps) => {
  const { colorOverride, replace } = props;

  return (props: { index: number; item: ProductColorDetail }) => {
    const { index, item } = props;

    replace<PartTemplateColorOverride>(index, {
      ...colorOverride[index],
      to: {
        code: item?.code,
        name: item?.shortColorName,
      },
    });
  };
};
