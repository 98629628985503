import {
  Conditional,
  Radio,
  Stack,
  Typography,
  Table,
  Grid,
} from 'gantri-components';
import { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { PrintJob } from '../../../../../../../../../api/jobs/jobs.types';
import { MachineDataCell } from '../../../../../../custom-data-cells';
import { DateWithUserDataCell } from '../../../../../../../../../components/common/custom-data-cells/date-user-data-cell';
import { printQcModalAtoms } from '../../../complete-print-qa-modal.atoms';
import { NotApplicableText } from '../../../../../../../../../components/common/not-applicable-text';

export const SelectJobContent = () => {
  const availablePrintJobs = useRecoilValue(
    printQcModalAtoms.availablePrintJobs,
  );
  const [selectedPrintJob, setSelectedPrintJob] = useRecoilState(
    printQcModalAtoms.selectedPrintJob,
  );

  const columns = useMemo(() => {
    const handleRadioSelected = (printJob: PrintJob) => {
      if (printJob?.id !== selectedPrintJob?.id) {
        setSelectedPrintJob(printJob);
      }
    };

    const qaJobColumns: ColumnDef<PrintJob>[] = [
      {
        accessorKey: 'description',
        cell: ({
          getValue,
          row: { original },
        }: CellContext<PrintJob, PrintJob['description']>) => {
          const { id } = original;

          return (
            <Grid alignItems="center" columns="2rem 1fr" gap="x">
              <Radio
                groupValue={id}
                name="print"
                value={selectedPrintJob?.id}
                onSelected={() => {
                  handleRadioSelected(original);
                }}
              />
              <Typography
                text={getValue()}
                onClick={() => {
                  handleRadioSelected(original);
                }}
              />
            </Grid>
          );
        },
        header: () => {
          return (
            <Grid alignItems="center" columns="2rem 1fr" gap="x">
              <div data-spacer-element="" />
              <Typography text="Name" />
            </Grid>
          );
        },
      },
      {
        accessorKey: 'machine',
        cell: ({ getValue }: CellContext<PrintJob, PrintJob['machine']>) => {
          const machine = getValue();

          return (
            <Conditional
              condition={!!machine?.id}
              Fallback={<NotApplicableText />}
            >
              <MachineDataCell machine={machine} />
            </Conditional>
          );
        },
        header: 'Machine',
      },
      {
        accessorKey: 'statusInfo',
        cell: ({
          getValue,
          row: { original },
        }: CellContext<PrintJob, PrintJob['statusInfo']>) => {
          const statusInfo = getValue();

          return (
            <Conditional
              condition={!!statusInfo?.completed}
              Fallback={<NotApplicableText />}
            >
              <DateWithUserDataCell
                data={original.statusInfo}
                date={original.statusInfo?.completed?.date}
                jobId={original.id}
                type="End"
              />
            </Conditional>
          );
        },
        header: 'Completed',
        id: 'completedAt',
      },
    ];

    return qaJobColumns;
  }, [selectedPrintJob]);

  return (
    <Conditional
      condition={!!availablePrintJobs.length}
      Fallback={
        <Stack>
          <Typography paddingTop="1.6rem" text="Verify" variant="h3" />
          <Typography
            align="center"
            color="alert"
            marginBottom="1rem"
            text="No completed Print Jobs could be found. Please complete at least one Print Job to be able to proceed."
          />
        </Stack>
      }
    >
      <Stack gap="2.4rem">
        <Stack gap="0.8rem">
          <Typography paddingTop="1.6rem" text="Verify" variant="h3" />
          <Typography text="Select the right print job:" />
        </Stack>

        <Table columns={columns} data={availablePrintJobs} />
      </Stack>
    </Conditional>
  );
};
