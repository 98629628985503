import { Flex } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { useMoreMenu } from '../../../../../../../../../../../../../../hooks/use-more-menu';
import { DeleteFileArgs } from '../../../../../../../../../../../../../../api/cloudinary/routes/delete-file/delete-file.types';
import { bulkUploadModalAtoms } from '../../../../../../bulk-upload-modal.atoms';
import { BulkUploadTableActionsCellProps } from './bulk-upload-table-actions-cell.types';
import { FormattedSkuLevelAssetData } from '../../../../../../../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';

export const BulkUploadTableActionsCell = (
  props: BulkUploadTableActionsCellProps,
) => {
  const { showAssetsRemovedToast, ...bulkUploadItem } = props;
  const {
    dimmingPhotoDark,
    dimmingPhotoLight,
    scalePhoto,
    sku,
    usdz,
    whiteBackgroundPhotos,
  } = bulkUploadItem;

  const setAssetsBySku = useSetRecoilState(bulkUploadModalAtoms.assetsBySku);
  const setFilesToDeleteCloudinary = useSetRecoilState(
    bulkUploadModalAtoms.filesToDeleteCloudinary,
  );
  const setFilesToDeleteFirebase = useSetRecoilState(
    bulkUploadModalAtoms.filesToDeleteFirebase,
  );
  const setNumFilesAdded = useSetRecoilState(
    bulkUploadModalAtoms.numFilesAdded,
  );
  const setNumFilesRemoved = useSetRecoilState(
    bulkUploadModalAtoms.numFilesRemoved,
  );

  const disableDelete: boolean = ![
    dimmingPhotoDark,
    dimmingPhotoLight,
    ...whiteBackgroundPhotos,
    scalePhoto,
    usdz,
  ].filter((file) => {
    return file?.fileUrl;
  }).length;

  const handleAddFilesToDeleteList = (props: FormattedSkuLevelAssetData) => {
    const {
      dimmingPhotoDark,
      dimmingPhotoLight,
      scalePhoto,
      usdz,
      whiteBackgroundPhotos,
    } = props;

    const totalFilesToRemove: number = [
      dimmingPhotoDark,
      dimmingPhotoLight,
      ...whiteBackgroundPhotos,
      scalePhoto,
      usdz,
    ].filter((file) => {
      return file && !file?.id;
    }).length;

    const totalAddedFilesToRemove: number = [
      dimmingPhotoDark,
      dimmingPhotoLight,
      ...whiteBackgroundPhotos,
      scalePhoto,
      usdz,
    ].filter((file) => {
      return !!file?.id;
    }).length;

    setAssetsBySku((data) => {
      return {
        ...data,
        [sku]: {
          ...data[sku],
          dimmingPhotoDark: undefined,
          dimmingPhotoLight: undefined,
          scalePhoto: undefined,
          usdz: undefined,
          whiteBackgroundPhotos: [],
        },
      };
    });

    setFilesToDeleteCloudinary((files) => {
      const filesToDelete: DeleteFileArgs[] = [
        dimmingPhotoDark,
        dimmingPhotoLight,
        ...whiteBackgroundPhotos,
        scalePhoto,
      ].filter((file) => {
        return file && !file?.id;
      });

      return [...files, ...filesToDelete];
    });

    if (usdz?.fileUrl) {
      setFilesToDeleteFirebase((files) => {
        return [...files, usdz];
      });
    }

    setNumFilesRemoved((num) => {
      return num + totalFilesToRemove;
    });

    setNumFilesAdded((num) => {
      return num - totalAddedFilesToRemove || 0;
    });

    if (totalFilesToRemove) {
      showAssetsRemovedToast();
    }
  };

  const { MoreMenu } = useMoreMenu({
    data: bulkUploadItem,
    options: [
      {
        disabled: disableDelete,
        name: 'Delete All SKU Assets',
        onOptionClick: handleAddFilesToDeleteList,
      },
    ],
  });

  return (
    <Flex justifyContent="end">
      <MoreMenu />
    </Flex>
  );
};
