import React, { FC } from 'react';
import {
  Conditional,
  OnRowClick,
  Stack,
  Table,
  Typography,
} from 'gantri-components';
import { ReviewsProps } from './reviews.types';
import { npsColumns, useProductColumns } from './reviews.columns';
import { ReviewDetailsPanel } from './components';
import { useReviewDetailsPanel } from './reviews-context';
import {
  useFetchUserNpsReviewsQuery,
  useFetchUserProductReviewsQuery,
  UserNPSReview,
  UserProductReview,
} from '../../../api/users/routes';

export const Reviews: FC<ReviewsProps> = (props) => {
  const { currentUser } = props;
  const { onShowReviewDetails } = useReviewDetailsPanel();

  const { isLoading: isLoadingNps, reviews: npsReviews } =
    useFetchUserNpsReviewsQuery({
      userId: currentUser.userId,
    });

  const { isLoading, reviews } = useFetchUserProductReviewsQuery({
    userId: currentUser.userId,
  });

  const onReviewRowClick: OnRowClick<Partial<UserProductReview>> = (
    { original },
    event,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    onShowReviewDetails(original.id, 'REVIEW', currentUser.userId);
  };

  const onNPSReviewRowClick: OnRowClick<Partial<UserNPSReview>> = (
    { original },
    event,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    onShowReviewDetails(original.id, 'NPS', currentUser.userId);
  };

  return (
    <>
      <Stack gap="3x">
        <Typography marginTop=".4rem" text="Product Reviews" variant="h4" />

        <Conditional
          condition={!isLoading && reviews?.length > 0}
          Fallback={
            <Typography color="t2" text="No reviews are currently available." />
          }
        >
          <Table
            columns={useProductColumns}
            data={isLoading ? [] : reviews ?? []}
            getRowProps={({ original }) => {
              return {
                'review-id': original?.id,
              };
            }}
            highlightHoveredRow
            onRowClick={onReviewRowClick}
          />
        </Conditional>

        <Typography marginTop="3x" text="NPS Reviews" variant="h4" />
        <Conditional
          condition={!isLoadingNps && npsReviews?.length > 0}
          Fallback={
            <Typography color="t2" text="No reviews are currently available." />
          }
        >
          <Table
            columns={npsColumns}
            data={isLoadingNps ? [] : npsReviews ?? []}
            getRowProps={({ original }) => {
              return {
                'nps-id': original?.id,
              };
            }}
            highlightHoveredRow
            onRowClick={onNPSReviewRowClick}
          />
        </Conditional>
      </Stack>

      <ReviewDetailsPanel />
    </>
  );
};
