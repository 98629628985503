import { sortBy } from 'lodash';
import { Part } from '../../../../api/jobs/jobs.types';
import { StockPartsTableRecord } from './stock-parts.types';

export const convertStockPartsToStockPartsTableRecords = (
  parts: Part[],
): StockPartsTableRecord[] => {
  return sortBy(
    parts.map((part) => {
      const { attempt, attemptsList } = part;
      const subRows: StockPartsTableRecord[] =
        attempt > 1
          ? attemptsList.map((attempt) => {
              return {
                ...part,
                ...attempt,
                subRows: undefined,
              };
            })
          : undefined;

      return {
        ...part,
        subRows,
      };
    }),
    'id',
  );
};
