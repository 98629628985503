import { Button } from 'gantri-components';
import { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { batchApi } from '../../../../../../../../../api';
import { useNotification } from '../../../../../../../../../hooks/useNotification';
import { updatePartBatchModalAtoms } from '../../../update-part-batch-modal.atoms';

interface UpdateBatchFooterProps {
  currentBatchId: number;
  handleCloseModal: () => Promise<void>;
  partId: number;
}

export const UpdateBatchFooter = (props: UpdateBatchFooterProps) => {
  const { currentBatchId, handleCloseModal, partId } = props;

  const { notifyAxiosError } = useNotification();
  const [processing, setProcessing] = useState<boolean>(false);
  const setStep = useSetRecoilState(updatePartBatchModalAtoms.step);
  const updateMethod = useRecoilValue(
    updatePartBatchModalAtoms.batchUpdateMethod,
  );
  const [newBatchId, setNewBatchId] = useRecoilState(
    updatePartBatchModalAtoms.newBatchId,
  );
  const setUpdateOnClose = useSetRecoilState(
    updatePartBatchModalAtoms.updateOnClose,
  );

  const isAddingToBatch = updateMethod === 'ADD_TO_EXISTING_BATCH';
  const isRemovingFromBatch = updateMethod === 'REMOVE_FROM_BATCH';
  const isCreatingNewBatch = updateMethod === 'ADD_TO_NEW_BATCH';
  const isAddToBatchDisabled =
    !updateMethod || (isAddingToBatch && !newBatchId);

  const handleAddToBatch = async () => {
    await batchApi.addPartToBatch({
      batchId: newBatchId,
      partId,
    });

    setStep('PART_ADDED_SUCCESS');
  };

  const handleRemoveFromBatch = async () => {
    await batchApi.removePartFromBatch({
      batchId: currentBatchId,
      partId,
    });

    setStep('PART_REMOVED_SUCCESS');
  };

  const handleCreateNewBatch = async () => {
    const { data } = await batchApi.createBatch(partId);

    setNewBatchId(data.batchId);
    setStep('BATCH_CREATION_SUCCESS');
  };

  const handleUpdateBatch = async () => {
    try {
      setProcessing(true);

      if (isAddingToBatch) {
        await handleAddToBatch();
      } else if (isRemovingFromBatch) {
        await handleRemoveFromBatch();
      } else if (isCreatingNewBatch) {
        await handleCreateNewBatch();
      }

      setUpdateOnClose(true);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to update batch.',
      });

      if (isAddingToBatch) {
        setStep('PART_ADDED_ERROR');
      } else if (isRemovingFromBatch) {
        setStep('PART_REMOVED_ERROR');
      } else if (isCreatingNewBatch) {
        setStep('BATCH_CREATION_ERROR');
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={handleCloseModal}
      />
      <Button
        disabled={isAddToBatchDisabled}
        processing={processing}
        size="large"
        text="Update"
        onClick={handleUpdateBatch}
      />
    </>
  );
};
