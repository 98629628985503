import { environment } from '../../../../environment';

export const getEnvLabel = () => {
  switch (environment.STAGE) {
    case 'local':
      return 'Local';
    case 'develop':
      return 'Dev';
    case 'staging':
      return 'Staging';
    default:
      return '';
  }
};
