import React, { useMemo } from 'react';
import {
  Grid,
  Typography,
  FormikInput,
  SearchField,
  Box,
} from 'gantri-components';
import { useField } from 'formik';
import { MigrateIcon, SelectorHeader, SelectorItem } from '../components';
import { useInventoryMigrationState } from '../inventory-migration-context';
import { Inventory } from '../inventory-migration.types';
import { useFormikHelper } from '../../../../../hooks';
import { StyledAnchor } from '../../../../../components/common/styled-anchor';

export const InventoryMigrationForm = () => {
  const { resetInputsValue } = useFormikHelper();
  const { activeInventories, isLoading } = useInventoryMigrationState();
  const [existingSelected, , helperExistingSelected] =
    useField<Inventory>('existingSelected');
  const [, , helperReplacementSelected] = useField<Inventory>(
    'replacementSelected',
  );

  const replacementInventories = useMemo(() => {
    if (!existingSelected?.value?.id) return [];

    return activeInventories.filter((inventory) => {
      return (
        inventory.unit === existingSelected.value.unit &&
        inventory.id !== existingSelected.value.id &&
        inventory.type === existingSelected.value.type
      );
    });
  }, [activeInventories, existingSelected?.value?.id]);

  return (
    <Grid rowGap="2rem">
      <Typography text="Select the existing inventory and replacement inventory you'd like to migrate." />
      <Box>
        <Typography
          color="t2"
          display="inline"
          text="If you need to migrate paint inventory, please"
        />{' '}
        <StyledAnchor
          display="inline"
          target="_blank"
          text="submit a request to the software team."
          to="https://form.asana.com/?k=uxvFdJrnWvaxwV-Khwk9XA&d=1186582822873190"
        />
      </Box>
      <Grid alignItems="flex-start" columns="1fr max-content 1fr" gap="2rem">
        <FormikInput
          Field={
            <SearchField
              autoSelectFirst={false}
              dropdownPopupWidth="30rem"
              header={<SelectorHeader />}
              idProperty="id"
              items={activeInventories}
              labelProperty="name"
              maxHeight="33rem"
              placeholder="Select existing inventory"
              processing={isLoading}
              renderItem={(option) => {
                return <SelectorItem option={option} />;
              }}
              onSelect={async (option) => {
                await helperExistingSelected.setValue(option);

                resetInputsValue(
                  ['replacementSelected', 'replacementId'],
                  null,
                  false,
                );
              }}
            />
          }
          fieldVariant="standard"
          labelText="Existing Inventory"
          name="existingId"
        />

        <MigrateIcon />

        <FormikInput
          Field={
            <SearchField
              autoSelectFirst={false}
              disabled={!existingSelected?.value?.id}
              dropdownPopupWidth="30rem"
              header={<SelectorHeader />}
              idProperty="id"
              items={replacementInventories}
              labelProperty="name"
              maxHeight="33rem"
              placeholder="Select replacement inventory"
              renderItem={(option) => {
                return <SelectorItem option={option} />;
              }}
              onSelect={(option) => {
                return helperReplacementSelected.setValue(option);
              }}
            />
          }
          fieldVariant="standard"
          labelText="Replacement Inventory"
          name="replacementId"
        />
      </Grid>
    </Grid>
  );
};
