import { Conditional, Flex, Icon, Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { productAtoms } from '../../../../../../../../product.atoms';
import { useFetchSkuAssets } from '../../../../../../../../../../api/products/routes/fetch-sku-assets';
import { useSkuAssetsReviewDownloadData } from './hooks/use-sku-assets-review-download-data';
import { useCreateProductAssetsReviewReport } from './hooks/use-handle-create-product-assets-review-report';
import { RequiredAssetsSection } from './components/required-assets-section';
import { OptionalAssetsSection } from './components/optional-assets-section';
import { ReviewAssetsModalContentPropsDef } from './review-assets-modal-content.types';
import { SimpleErrorBoundary } from '../../../../../../../../../../components/simple-error-boundary';

export const ReviewAssetsModalContent = (
  props: ReviewAssetsModalContentPropsDef,
) => {
  const { onClose } = props;

  const product = useRecoilValue(productAtoms.product);

  const { downloadData, handleCreateAndSetDownloadData } =
    useSkuAssetsReviewDownloadData();

  const { handleCreateAndSetReport, report } =
    useCreateProductAssetsReviewReport();

  const { isLoading } = useFetchSkuAssets({
    fetchArgs: {
      productId: product.id,
    },
    onSuccess: async ({ skuAssets: assetsBySku }) => {
      const assets = Object.values(assetsBySku);

      handleCreateAndSetReport(assets);
      handleCreateAndSetDownloadData(assets);
    },
  });

  const noLifestyleImages = !product.assets?.product.lifestylePhotos?.length;
  const noVideo = !product.assets?.product?.videos?.length;

  const isMissingRequiredAssets =
    !!report.skusMissingProductPhotos.length ||
    report.noScaleImage ||
    report.noDimmingImages ||
    report.noUsdzFile ||
    noLifestyleImages;

  const numSkusMissingOptionalAssets = report.skusMissingOptionalAssets.length;
  const someSkusAreMissingOptionalAssets = !!numSkusMissingOptionalAssets;
  const isMissingOptionalAssets = someSkusAreMissingOptionalAssets || noVideo;

  return (
    <SimpleErrorBoundary>
      <Stack
        alignContent={{ lg: 'center', md: 'start' }}
        gap="1.6rem"
        minHeight="15.3rem"
      >
        <Conditional
          key="Is loading"
          condition={isLoading}
          Fallback={
            <Conditional
              key="Required and optional assets complete."
              condition={isMissingRequiredAssets || isMissingOptionalAssets}
              Fallback={
                <Typography
                  align="center"
                  icon={
                    <Icon
                      color="link"
                      name="ui-control:check_mark_circle_filled"
                    />
                  }
                  text="Required and optional assets complete."
                />
              }
            >
              <SimpleErrorBoundary>
                <RequiredAssetsSection
                  isMissingRequiredAssets={isMissingRequiredAssets}
                  noLifestyleImages={noLifestyleImages}
                  report={report}
                  onClose={onClose}
                />
              </SimpleErrorBoundary>

              <SimpleErrorBoundary>
                <OptionalAssetsSection
                  downloadData={downloadData}
                  isMissingOptionalAssets={isMissingOptionalAssets}
                  noVideo={noVideo}
                  numSkusMissingOptionalAssets={numSkusMissingOptionalAssets}
                  someSkusAreMissingOptionalAssets={
                    someSkusAreMissingOptionalAssets
                  }
                />
              </SimpleErrorBoundary>
            </Conditional>
          }
        >
          <Flex alignItems="center" justifyContent="center" minHeight="10rem">
            <Icon color="link" name="animated:loader" size="4rem" />
          </Flex>
        </Conditional>
      </Stack>
    </SimpleErrorBoundary>
  );
};
