import {
  Flex,
  getFileUrl,
  Icon,
  Typography,
  getPurifiedFileName,
} from 'gantri-components';
import { cloudinaryUrl } from '../../../../../../../../helpers/images';
import { NameCellProps } from './name-cell.types';

export const NameCell = ({ getValue, row: { original } }: NameCellProps) => {
  const { link, productId, type, universal } = original;
  const name = getValue();

  const cleanFileName = getPurifiedFileName(name);

  const fileUrl = /upload/i.test(type)
    ? universal
      ? getFileUrl<'products'>({
          directory: 'products',
          fileName: name,
          fileType: 'universal-handouts',
          identifiers: {},
        })
      : getFileUrl<'products'>({
          directory: 'products',
          fileName: name,
          fileType: 'handouts',
          identifiers: {
            productId,
          },
        })
    : link;

  return (
    <a
      download
      href={cloudinaryUrl(fileUrl, {
        flags: ['attachment'],
        format: 'pdf',
      })}
    >
      <Flex alignItems="center" justifyContent="flex-start">
        <Typography
          color="link"
          icon={<Icon color="link" name="arrows:arrow_external" />}
          iconPosition="right"
          text={cleanFileName}
        />
      </Flex>
    </a>
  );
};
