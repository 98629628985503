import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Cell, Conditional, Grid, Typography, Table } from 'gantri-components';
import { VendorInventory } from '../../../../../../api/vendors/vendors.types';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import routePaths from '../../../../../../config/route-paths';
import { getIsClickableIfValue } from '../../../../../../helpers/get-is-clickable-if-value';
import {
  InventoriesSectionProps,
  InventoriesTablesProps,
} from './inventories-section.types';

const InventoriesTable = (props: InventoriesTablesProps) => {
  const { inventories, title } = props;

  const placeholderValue = '-';

  const columns: ColumnDef<VendorInventory>[] = [
    {
      accessorKey: 'id',
      cell: ({
        getValue,
      }: CellContext<VendorInventory, VendorInventory['id']>) => {
        const id = getValue();

        return (
          <Conditional
            condition={!!id}
            Fallback={<Typography text={placeholderValue} />}
          >
            <Typography text={id} />
          </Conditional>
        );
      },
      header: 'ID',
      maxSize: 100,
    },
    {
      accessorKey: 'name',
      cell: ({
        getValue,
        row,
      }: CellContext<VendorInventory, VendorInventory['name']>) => {
        const { id } = row.original;
        const name = getValue();

        return (
          <Conditional
            condition={name === placeholderValue}
            Fallback={
              <StyledAnchor
                text={name}
                to={`${routePaths.inventories}/${id}`}
              />
            }
          >
            <Typography text={name} />
          </Conditional>
        );
      },
      header: 'Name',
      meta: {
        getIsClickable: getIsClickableIfValue,
      },
    },
  ];

  const placeholderVendorInventory: VendorInventory[] = [
    {
      id: null,
      name: placeholderValue,
      type: null,
    },
  ];
  const vendorInventories: VendorInventory[] = inventories.length
    ? inventories
    : placeholderVendorInventory;

  return (
    <>
      <Typography text={title} variant="h4" />
      <Table columns={columns} data={vendorInventories} />
    </>
  );
};

export const InventoriesSection = (props: InventoriesSectionProps) => {
  const { editedVendor } = props;
  const { activeInventories, inactiveInventories } = editedVendor;

  return (
    <Grid alignItems="flex-start" columns={{ lg: 2, sm: 1 }} gap="3.2rem">
      <Cell>
        <InventoriesTable
          inventories={activeInventories}
          title={`Active Inventories (${activeInventories?.length})`}
        />
      </Cell>
      <Cell>
        <InventoriesTable
          inventories={inactiveInventories}
          title={`Inactive Inventories (${inactiveInventories.length})`}
        />
      </Cell>
    </Grid>
  );
};
