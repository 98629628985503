import { capitalize } from 'lodash';
import { jobStatuses } from '../../../../../../constants/options';
import { FailedReason } from '../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { checklistDefaults } from '../../../../../jobs/components/modals/common/job-checklist/job-checklist.constants';
import { GetYieldDetailsArgs, YieldReason } from './get-yield-details.types';

export const getYieldDetails = (args: GetYieldDetailsArgs) => {
  const { part, step, type } = args;
  const matchingJobs = part.jobs.filter((job) => {
    return job.type === type && job.step === step;
  });
  const numMatchingJobs = matchingJobs.length;

  const failedJobs = matchingJobs.filter(({ status }) => {
    return status === jobStatuses.failed;
  });

  const numMatchingFailedJobs = failedJobs.length;

  const value = !!numMatchingFailedJobs
    ? Math.round(
        ((numMatchingJobs - numMatchingFailedJobs) / numMatchingJobs) * 100,
      )
    : null;

  const reasonsObj = failedJobs.reduce<Record<FailedReason, number>>(
    (jobsAccumulator, { failedReason }) => {
      const { reason } = failedReason;

      const failReasonCodes = Object.keys(reason) as FailedReason[];

      const failReasonCounts = failReasonCodes.reduce<
        Record<FailedReason, number>
      >((codeAccumulator, code) => {
        if (jobsAccumulator[code]) {
          return { ...codeAccumulator, [code]: jobsAccumulator[code] + 1 };
        }

        return { ...codeAccumulator, [code]: 1 };
      }, {} as Record<FailedReason, number>);

      return { ...jobsAccumulator, ...failReasonCounts };
    },
    {} as Record<FailedReason, number>,
  );

  let reasons: YieldReason[] = (Object.keys(reasonsObj) as FailedReason[]).map(
    (code) => {
      const defaultLabel = checklistDefaults[code]?.label;

      return {
        label: defaultLabel || capitalize(code.replace(/_/g, ' ')),
        /** Percentage */
        value: Math.round((reasonsObj[code] / numMatchingFailedJobs) * 100),
      };
    },
  );

  reasons = reasons.slice(0, 3);

  return { reasons, value };
};
