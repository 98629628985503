import { Stack } from 'gantri-components';
import { ModalContentHeading } from '../../../../../jobs/components/modals/common/modal-content-heading';
import { RecommendedStocksTable } from '../../../../../jobs/components/modals/common/recommended-stocks-table';
import { ContentProps } from './content.types';

export const Content = (props: ContentProps) => {
  const { part, recommendedStocks, selectedStock, setSelectedStock, step } =
    props;

  switch (step) {
    case 'PART_SWITCHING_TABLE':
      return (
        <Stack gap="x">
          <ModalContentHeading titleText="Part Switching" />
          <RecommendedStocksTable
            part={part}
            recommendedStocks={recommendedStocks}
            selectedStock={selectedStock}
            setSelectedStock={setSelectedStock}
          />
        </Stack>
      );

    case 'PART_SWITCH_COMPLETE':
      return (
        <ModalContentHeading
          color="success"
          subTitleText={`${part?.name} has been successfully reassigned to Stock #${selectedStock.stockId}.`}
          titleText="Part Switching complete"
        />
      );

    default:
      return null;
  }
};
