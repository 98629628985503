import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { FetchGrowthMultiplierResponse } from './fetch-growth-multiplier.types';

const query = () => {
  return axios
    .get<FetchGrowthMultiplierResponse>(
      `${coreApiUrl}/products/category/growth-multiplier`,
    )
    .then(({ data }) => {
      return data;
    });
};

export const fetchGrowthMultiplier = getGenericQueryObj({
  groupName: 'growth-multiplier',
  query,
});
