import { useRecoilValue } from 'recoil';
import { cancelOrUpdateStockVersionModalStepAtom } from '../../cancel-or-update-stock-version-modal.atoms';
import { DoneButton } from '../done-button';
import { ConfirmCancelFooter } from '../step-confirm-cancel/confirm-cancel-footer';
import { ConfirmUpdateFooter } from '../step-confirm-update/confirm-update-footer';
import { RequestVlmFooter } from '../step-request-vlm/request-vlm-footer';
import { UpdateSuccessFooter } from '../step-update-success/update-success-footer';
import { UpdateStockVersionFooterProps } from './update-stock-version-footer.types';

export const UpdateStockVersionFooter = (
  props: UpdateStockVersionFooterProps,
) => {
  const { onClose, stock } = props;

  const step = useRecoilValue(cancelOrUpdateStockVersionModalStepAtom);

  switch (step) {
    case 'CONFIRM_UPDATE':
      return <ConfirmUpdateFooter stockId={stock.id} onClose={onClose} />;

    case 'CONFIRM_CANCEL':
      return <ConfirmCancelFooter stockId={stock.id} onClose={onClose} />;

    case 'UPDATE_SUCCESS':
      return (
        <UpdateSuccessFooter stockLocation={stock.location} onClose={onClose} />
      );

    case 'REQUEST_VLM':
      return <RequestVlmFooter stockLocation={stock.location} />;

    case 'UPDATE_ERROR':
    case 'REMOVED_FROM_VLM':
      return <DoneButton onClose={onClose} />;

    default:
      return null;
  }
};
