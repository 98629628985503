import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  CreateMachineArgs,
  CreateMachineResponse,
} from './create-machine.types';

export const createMachine = (body: CreateMachineArgs) => {
  return axios
    .post<CreateMachineResponse>(`${coreApiUrl}/machines`, body)
    .then(({ data }) => {
      return data;
    });
};
