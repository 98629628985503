import { DateRangeFilter } from '../../components/common/table/components/table-filters/table-filters.types';
import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
  SortBy,
} from '../../components/common/table/hooks';
import { getGenericAtomFamily } from '../../helpers/get-generic-atom-family';
import { AtomPageName } from '../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import {
  PressRecordSortingField,
  PressRecordType,
} from '../../api/press/press.types';

export const pressPageAtomsDefaults: {
  filters: {
    dateRange: DateRangeFilter;
    type: PressRecordType;
  };
  sortBy: SortBy<PressRecordSortingField>;
} = {
  filters: {
    dateRange: {
      from: null,
      to: null,
    },
    type: null,
  },
  sortBy: {
    sortingField: 'publishDate',
    sortingType: 'DESC',
  },
};

const atomKeyPrefix = 'PRESS_PAGE';

export const pageName: AtomPageName = 'press';

export const pressPageAtoms = {
  defaults: pressPageAtomsDefaults,
  filters: {
    dateRange: getGenericAtomFamily({
      defaultValue: pressPageAtomsDefaults.filters.dateRange,
      key: `${atomKeyPrefix}-date-range`,
    })(pageName),
    type: getGenericAtomFamily({
      defaultValue: pressPageAtomsDefaults.filters.type,
      key: `${atomKeyPrefix}-type`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<PressRecordSortingField>>({
    defaultValue: pressPageAtomsDefaults.sortBy,
  })(pageName),
};
