import groupBy from 'lodash/groupBy';
import { Stock } from '../../../../../../types/store';
import {
  StockStatus,
  stockStatuses,
} from '../../../../../../constants/options';

type GroupedByStatusResponse = { status: StockStatus; stocks: Stock[] }[];

export const getStocksGroupedByStatus = (
  stocks: Stock[],
): GroupedByStatusResponse => {
  const groups = groupBy(stocks, 'status');

  const sortOrder = Object.values(stockStatuses).reduce(
    // ? add any missing stock statuses to the end of the list
    (accumulator, status) => {
      if (
        accumulator.some((statusInList) => {
          return statusInList === status;
        })
      ) {
        return accumulator;
      }

      return [...accumulator, status];
    },
    [
      stockStatuses.inStock,
      stockStatuses.waiting,
      stockStatuses.printing,
      stockStatuses.finishing,
      stockStatuses.assembling,
      stockStatuses.qa,
      stockStatuses.packing,
      stockStatuses.completed,
      stockStatuses.delivered,
    ],
  );

  return sortOrder
    .filter((status) => {
      return !!groups[status];
    })
    .map((status) => {
      return { status, stocks: groups[status] };
    });
};
