import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useNotification } from '../../../../hooks/useNotification';
import { createHandout } from './create-handout';
import {
  CreateHandoutArgs,
  CreateHandoutResponse,
} from './create-handout.types';

export const useCreateDesignerHandout = () => {
  const { notifyAxiosError } = useNotification();

  const { onMutate: onCreateDesignerHandout, ...rest } = useQueryMutation<
    CreateHandoutArgs,
    CreateHandoutResponse
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to create designer handout.',
        });
      },
    },
    mutationFn: async (args) => {
      return createHandout(args).then(({ data }) => {
        return data;
      });
    },
    showLoading: true,
  });

  return { ...rest, onCreateDesignerHandout };
};
