import StatusDataCell from '../../../../../../components/common/custom-data-cells/status-data-cell';
import { CellJobStatusProps } from './cell-job-status.types';

export const CellJobStatus = (props: CellJobStatusProps) => {
  const {
    row: { original },
  } = props;

  return <StatusDataCell {...original} />;
};
