import { Cell, Table } from 'gantri-components';
import { getJobsRowProps } from '../../../../helpers/get-jobs-row-props';
import { useGetPartPageJobTableColumns } from './hooks/use-get-part-jobs-table-columns';
import { useQuickViewTableAction } from './hooks/use-quick-view-table-action';
import { useGetPartJobsTableData } from './hooks/use-get-part-jobs-table-data';
import { PartJobsProps } from './part-jobs.types';
import { PartStatus } from '../../../../constants/options';
import { useJobComplete, useJobStart } from '../../../jobs/hooks';
import { useTableColumnsSync } from '../../../../hooks';
import { JobsDetailsPanelWrapper } from '../../../../components/jobs-details-panel-wrapper';
import { SectionTitle } from '../../../../components/layout/section-title';

export const PartJobs = (props: PartJobsProps) => {
  const { fetchPartDetails, part } = props;
  const { QuickViewTableAction, isQuickViewEnabled } =
    useQuickViewTableAction();

  const columnsSyncProps = useTableColumnsSync('part-jobs');

  const { data, sortProps } = useGetPartJobsTableData({
    isQuickViewEnabled,
    part,
  });

  const statusesToHideJobMenu: PartStatus[] = ['Lost', 'Cancelled', 'Failed'];
  const hideJobMenu = statusesToHideJobMenu.some((hideStatus) => {
    return hideStatus === part.status;
  });

  const startJob = useJobStart({
    onStartEnd: fetchPartDetails,
  });

  const completeJob = useJobComplete({
    onCompleteEnd: fetchPartDetails,
  });

  const { DownloadGcodeStatusToast, DownloadInstructionStatusToast, columns } =
    useGetPartPageJobTableColumns(
      {
        completeJob,
        hideJobMenu,
        onRefresh: fetchPartDetails,
        startJob,
      },
      [hideJobMenu, part?.id],
    );

  return (
    <Cell>
      <SectionTitle text="Jobs" />

      <DownloadGcodeStatusToast position="top-end" />
      <DownloadInstructionStatusToast maxWidth="25rem" position="top-end" />

      <JobsDetailsPanelWrapper records={data} onRefresh={fetchPartDetails}>
        <Table
          columns={columns}
          customAction={{
            Component: QuickViewTableAction,
            position: 'left',
          }}
          data={data}
          getRowProps={getJobsRowProps}
          highlightHoveredRow
          sorting={{ ...sortProps, disabled: isQuickViewEnabled }}
          stickyLastColumn={!hideJobMenu}
          {...columnsSyncProps}
        />
      </JobsDetailsPanelWrapper>
    </Cell>
  );
};
