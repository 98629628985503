import { Conditional, Typography } from 'gantri-components';
import { useEffect } from 'react';
import { PartReleaseRateQueuePart } from '../../../../../../api/parts/routes/get-part-release-rate-details/get-part-release-rate-details.types';
import { usePartReleaseRateQueueColumns } from './hooks/use-part-release-rate-queue-columns';
import { usePagingState } from '../../../../../../hooks';
import { PartReleaseRateQueueTablePropsDef } from './part-release-rate-queue-table.types';
import { StyledTable } from './part-release-rate-queue-table.styles';

export const PartReleaseRateQueueTable = (
  props: PartReleaseRateQueueTablePropsDef,
) => {
  const { data = [] } = props;

  const {
    currentPage,
    itemsPerPage,
    pages,
    records,
    setCurrentPage,
    setRecords,
    setTotalCount,
    totalCount,
  } = usePagingState<PartReleaseRateQueuePart>();

  const columns = usePartReleaseRateQueueColumns([data]);

  useEffect(() => {
    const startingIndex = (currentPage - 1) * itemsPerPage;
    const endingIndex = startingIndex + itemsPerPage;

    setRecords(data.slice(startingIndex, endingIndex));
    setTotalCount(data.length);
  }, [data, currentPage]);

  return (
    <Conditional
      condition={!!records.length}
      Fallback={
        <Typography color="t2" paddingTop="x" text="No matching parts" />
      }
    >
      <StyledTable
        columns={columns}
        data={records}
        highlightHoveredRow
        paging={{
          currentPage,
          onPageChange: setCurrentPage,
          pageCount: pages,
          total: totalCount,
        }}
      />
    </Conditional>
  );
};
