import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const atomKeyPrefix = 'SANDING_MINUTES_PROGRESS';

const { persistAtom } = recoilPersist();

export const sandingMinutesProgressAtoms = {
  isFirstRequest: atom<boolean>({
    default: true,
    effects_UNSTABLE: [persistAtom],
    key: `${atomKeyPrefix}-is-first-request`,
  }),
  sandingMinutes: atom<number>({
    default: 0,
    effects_UNSTABLE: [persistAtom],
    key: `${atomKeyPrefix}-sanding-minutes`,
  }),
};
