import { FilterSkusByColorProps } from './filter-skus-by-color.types';

export const filterSkusByColor = (props: FilterSkusByColorProps): string[] => {
  const { colorCode, skus = [] } = props;

  return skus.filter((sku) => {
    const colorCodePattern = new RegExp(`-${colorCode}(-.+)?$`);

    return colorCodePattern.test(sku);
  });
};
