import { useRecoilValue, useResetRecoilState } from 'recoil';
import { productJobsTabAtoms } from '../../../../../../product-jobs.atoms';
import { PartJobBlocksFormData } from '../../part-job-blocks.types';
import {
  useInvalidateFetchProductCache,
  useUpdateProductJobBlocks,
} from '../../../../../../../../../../api/products/routes';
import { jobBlockTypes } from '../../../../../../../../../../api/products/routes/update-product-job-blocks/update-product-job-blocks.types';
import { putInstructionsInOrder } from '../../components/part-job-blocks-instructions/helpers/put-instructions-in-order';
import { productAtoms } from '../../../../../../../../product.atoms';

export const useOnSubmit = () => {
  const product = useRecoilValue(productAtoms.product);
  const partId = useRecoilValue(productJobsTabAtoms.isEditingPartId);
  const resetIsEditingPartId = useResetRecoilState(
    productJobsTabAtoms.isEditingPartId,
  );

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();

  const { onUpdateProductJobBlocks } = useUpdateProductJobBlocks({
    onSuccess: async () => {
      await invalidateFetchProductCache();

      resetIsEditingPartId();
    },
  });

  const hasFinishBlock = !!product.parts.find(({ id }) => {
    return id === partId;
  })?.jobBlock?.finishBlock;

  const onSubmit = async ({
    bondoBlock,
    finishBlock,
    hasBondo,
    id,
    printBlock,
  }: PartJobBlocksFormData) => {
    await onUpdateProductJobBlocks({
      id,
      jobBlock: {
        bondoBlock: hasBondo
          ? {
              ...bondoBlock,
              instructions: putInstructionsInOrder(bondoBlock, {
                stripLabels: true,
              }),
            }
          : undefined,
        finishBlock: hasFinishBlock
          ? {
              ...finishBlock,
              instructions: putInstructionsInOrder(finishBlock, {
                stripLabels: true,
              }),
            }
          : undefined,
        printBlock: printBlock.map((printBlock) => {
          return {
            ...printBlock,
            instructions: putInstructionsInOrder(printBlock, {
              stripLabels: true,
            }),
          };
        }),
        type: jobBlockTypes.part,
      },
    });
  };

  return onSubmit;
};
