import { FormikInput } from 'gantri-components';
import { useGetVersionDetails } from '../../../../../../../../hooks/use-get-version-details';

export const PartName = () => {
  const { isMinorVersion } = useGetVersionDetails();

  return (
    <FormikInput
      disabled={isMinorVersion}
      fieldVariant="standard"
      labelPosition="top"
      labelText="Name"
      name="name"
      placeholder="Input name"
      required
    />
  );
};
