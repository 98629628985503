import {
  Button,
  CheckboxList,
  Conditional,
  Dropdown,
  Flex,
  Grid,
  Stack,
  TextArea,
  TextField,
} from 'gantri-components';
import { capitalize, range } from 'lodash';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { productAtoms } from '../../../../product.atoms';
import {
  productInfoPageMaxWidth,
  rooms,
  shapes,
  tags,
} from '../../info.constants';
import { SectionTitle } from '../../../../../../components/layout/section-title';
import { Label } from '../../../../../../components/label';

export const InfoTabMarketplaceConfiguration = () => {
  const [product, setProduct] = useRecoilState(productAtoms.product);
  const isAccessory = useRecoilValue(productAtoms.isAccessory);

  const [selectedTags, setSelectedTags] = useState<Record<string, boolean>>({});

  const toggleTag = (tag) => {
    const newSelectedTags = {
      ...selectedTags,
      [tag]: !selectedTags[tag],
    };

    setSelectedTags(newSelectedTags);

    const tags = Object.keys(newSelectedTags).filter((key) => {
      return newSelectedTags[key];
    });

    setProduct({ ...product, tags });
  };

  useEffect(() => {
    const temporal = tags.reduce((accumulator, item) => {
      return {
        ...accumulator,
        [item]:
          product.tags &&
          product.tags.some((element) => {
            return element === item;
          }),
      };
    }, {});

    setSelectedTags(temporal);
  }, [product.tags]);

  return (
    <Stack gap="2x">
      <SectionTitle text="Marketplace Configuration" />

      <Stack gap="3x" maxWidth={productInfoPageMaxWidth}>
        <Conditional condition={!isAccessory}>
          {/* Style Tags */}
          <Stack gap=".5x" maxWidth={{ lg: '32rem', md: 'unset' }}>
            <Label text="Style Tags" />

            <Flex gap="0.6rem" wrap="wrap">
              {tags.map((tag) => {
                return (
                  <Button
                    key={tag}
                    size="medium"
                    text={capitalize(tag)}
                    variant={selectedTags[tag] ? 'primary' : 'secondary'}
                    onClick={() => {
                      return toggleTag(tag);
                    }}
                  />
                );
              })}
            </Flex>
          </Stack>

          {/* Rooms */}
          <Stack gap=".5x" maxWidth={{ lg: '40vw', md: 'unset' }}>
            <Label text="Rooms" />
            <CheckboxList
              gridProps={{ columns: { lg: 3, sm: 2 } }}
              items={[...rooms]}
              values={product.rooms || []}
              onValueChange={(selectedValues) => {
                setProduct({ ...product, rooms: selectedValues });
              }}
            />
          </Stack>

          {/* Shapes */}
          <Stack gap=".5x" maxWidth={{ lg: '26vw', md: '64vw' }}>
            <Label text="Shape" />
            <CheckboxList
              gridProps={{ columns: 2, rows: 3 }}
              items={[...shapes]}
              values={product.shapes || []}
              onValueChange={(selectedValues) => {
                setProduct({ ...product, shapes: selectedValues });
              }}
            />
          </Stack>
        </Conditional>

        <Conditional condition={!isAccessory}>
          <Grid columns={{ lg: 2, md: 1 }}>
            <Dropdown
              items={range(1, 6).map((value) => {
                return { label: value, value };
              })}
              labelText="Product Brightness (1-5)"
              value={String(product.brightness)}
              onSelect={(item) => {
                setProduct({ ...product, brightness: item?.value });
              }}
            />
          </Grid>
        </Conditional>

        <TextArea
          labelText="Summary"
          minRows={2}
          placeholder="Input summary"
          value={product.summary || ''}
          onTextChange={(summary) => {
            setProduct({ ...product, summary });
          }}
        />

        <TextField
          labelText="Heading"
          placeholder="Input heading"
          value={product.heading || ''}
          onTextChange={(heading) => {
            setProduct({ ...product, heading });
          }}
        />

        <TextArea
          labelText="Description"
          minRows={4}
          placeholder="Input description"
          value={product.description || ''}
          onTextChange={(description) => {
            setProduct({ ...product, description });
          }}
        />

        <TextArea
          labelText="Keywords"
          minRows={4}
          placeholder="Input keywords"
          value={product.keywords || ''}
          onTextChange={(keywords) => {
            setProduct({ ...product, keywords });
          }}
        />
      </Stack>
    </Stack>
  );
};
