import { Box, Typography } from 'gantri-components';
import MetaData from '../../components/meta-data';

export const Unauthorized = () => {
  return (
    <>
      <MetaData title="Unauthorized" />
      <Box padding="3x">
        <Typography
          color="alert"
          text="You do not have access to view this page."
          variant="p1"
        />
      </Box>
    </>
  );
};
