import { Button } from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ButtonVariant } from 'gantri-components/dist/components/button/button.types';
import { text } from './complete-checklist-footer.constants';
import { CompleteChecklistFooterProps } from './complete-checklist-footer.types';
import { checkAgainstFailingChecklistItems } from '../../../../../helpers/check-against-failing-checklist-items';
import { usePassOrFailJob } from './hooks/use-pass-or-fail-job';
import { completePrintHarvestModalAtoms } from '../../../complete-print-harvest-modal.atoms';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { CompleteChecklistButtonWrapper } from '../../../../../common/job-checklist/components/complete-checklist-button-wrapper';

export const CompleteChecklistFooter = (
  props: CompleteChecklistFooterProps,
) => {
  const { handleCloseModal } = props;

  const job = useRecoilValue(completePrintHarvestModalAtoms.job);
  const checklist = useRecoilValue(completePrintHarvestModalAtoms.checklist);

  const [isChecklistReviewed, setIsChecklistReviewed] = useRecoilState(
    completePrintHarvestModalAtoms.isChecklistReviewed,
  );
  const setStep = useSetRecoilState(completePrintHarvestModalAtoms.step);

  const shouldTakeMachineOffline = checkAgainstFailingChecklistItems(
    checklist,
    (item) => {
      return item.failStatusShouldTakeMachineOffline;
    },
  );

  const partFailed = checkAgainstFailingChecklistItems(checklist, (item) => {
    return item.failStatusShouldFailPart;
  });

  const { onFail, onPass, processing } = usePassOrFailJob({
    handleCloseModal,
    job,
  });

  const onCompleteChecklist = async () => {
    if (partFailed) {
      await onFail();
    } else {
      await onPass();
    }
  };

  const nextBtnVariant: ButtonVariant =
    partFailed || shouldTakeMachineOffline ? 'primaryAlert' : 'primary';

  const onBack = () => {
    if (isChecklistReviewed) {
      setIsChecklistReviewed(false);
    }

    setStep('EVALUATE_PART');
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Back"
        variant="secondary"
        onClick={onBack}
      />
      <CompleteChecklistButtonWrapper checklist={checklist}>
        <Button
          data-button-variant={nextBtnVariant}
          disabled={!isChecklistReviewed}
          processing={processing}
          size={fullScreenModalFooterButtonSize}
          text={partFailed ? text.failJobButton : text.passJobButton(job.type)}
          variant={nextBtnVariant}
          onClick={onCompleteChecklist}
        />
      </CompleteChecklistButtonWrapper>
    </>
  );
};
