import { Stack } from 'gantri-components';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import {
  getPartJobBlocksInitialValues,
  partJobBlocksFormikSchema,
} from './part-job-blocks.schema';
import { PartJobBlocksProps } from './part-job-blocks.types';
import { PartJobBlocksPrintBlocks } from './components/part-job-blocks-print-blocks';
import { PartJobBlocksHeader } from './components/part-job-blocks-header';
import { PartJobBlocksBondoBlock } from './components/part-job-blocks-bondo-block';
import { PartJobBlocksFinishBlock } from './components/part-job-blocks-finish-block';
import { useOnSubmit } from './hooks/use-on-submit';

export const PartJobBlocks = (props: PartJobBlocksProps) => {
  const { part } = props;

  const initialValues = useMemo(() => {
    return getPartJobBlocksInitialValues({
      part,
    });
  }, [part, part?.jobBlock]);

  const onSubmit = useOnSubmit();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange
      validateOnMount
      validationSchema={partJobBlocksFormikSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        const { bondoBlock, finishBlock, printBlock } = values;

        return (
          <Form>
            <Stack gap="2x" paddingBottom="3x" paddingLeft="7x" paddingTop="x">
              <PartJobBlocksHeader />
              <Stack gap="3x" width="72.5rem">
                <PartJobBlocksPrintBlocks
                  partName={part.name}
                  printBlocks={printBlock}
                />
                <PartJobBlocksBondoBlock
                  bondoBlock={bondoBlock}
                  hasBondo={part.hasBondo}
                />
                <PartJobBlocksFinishBlock
                  finishBlock={finishBlock}
                  material={part.material}
                  paintedStatus={part.paintedStatus}
                />
              </Stack>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};
