import { useRecoilValue } from 'recoil';
import { restartOrCancelPartModalAtoms } from '../../restart-or-cancel-part-modal.atoms';
import { RestartReasonFooter } from '../step-restart-reason/restart-reason-footer';
import { ConfirmRestartFooter } from '../step-confirm-restart/confirm-restart-footer';
import { ConfirmCancelFooter } from '../step-confirm-cancel/confirm-cancel-footer';
import { RestartOrCancelPartModalFooterProps } from './restart-or-cancel-part-modal-footer.types';

export const RestartOrCancelPartModalFooter = (
  props: RestartOrCancelPartModalFooterProps,
) => {
  const { handleCloseModal, part } = props;

  const step = useRecoilValue(restartOrCancelPartModalAtoms.step);

  switch (step) {
    case 'RESTART_REASON':
      return <RestartReasonFooter handleCloseModal={handleCloseModal} />;

    case 'CONFIRM_RESTART':
      return (
        <ConfirmRestartFooter handleCloseModal={handleCloseModal} part={part} />
      );

    case 'CONFIRM_CANCEL':
      return (
        <ConfirmCancelFooter handleCloseModal={handleCloseModal} part={part} />
      );

    default:
      return null;
  }
};
