import { atom } from 'recoil';
import { VLMLocation } from '../../../../../../api/locations/routes/get-available-locations/get-available-locations.types';
import {
  CancelOrUpdateStockVersionModalStep,
  UpdateStockNewStock,
} from './cancel-or-update-stock-version-modal.types';

const atomKeyPrefix = 'CANCEL_OR_UPDATE_STOCK_VERSION_MODAL';

export const cancelOrUpdateStockVersionModalStepAtom =
  atom<CancelOrUpdateStockVersionModalStep>({
    default: null,
    key: `${atomKeyPrefix}-step`,
  });

export const cancelOrUpdateStockVersionModalNewStockAtom =
  atom<UpdateStockNewStock>({
    default: null,
    key: `${atomKeyPrefix}-new-stock`,
  });

export const cancelOrUpdateStockVersionModalUpdateOnCloseAtom = atom<boolean>({
  default: false,
  key: `${atomKeyPrefix}-update-on-close`,
});

export const cancelOrUpdateStockVersionModalVlmNotifiedAtom = atom<boolean>({
  default: false,
  key: `${atomKeyPrefix}-vlm-notified`,
});

export const cancelOrUpdateStockVersionModalVlmLocationAtom = atom<VLMLocation>(
  {
    default: null,
    key: `${atomKeyPrefix}-vlm-location`,
  },
);
