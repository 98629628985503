import { FC } from 'react';
import { Modal } from 'gantri-components';
import { Form, Formik } from 'formik';
import {
  NewProductModalFormData,
  NewProductModalProps,
} from './new-product-modal.type';
import routePaths from '../../../../config/route-paths';
import { useRouter } from '../../../../hooks';
import {
  newProductModalInitialValues,
  newProductModalSchema,
} from './new-product-modal.schema';
import { useCreateProduct } from '../../../../api/products/routes/create-product';
import { NewProductModalFooter } from './components/new-product-modal-footer';
import { NewProductModalContent } from './components/new-product-modal-content';
import { useInvalidateGetAllProductsCache } from '../../../../api/products/routes';

export const NewProductModal: FC<NewProductModalProps> = ({ onClose }) => {
  const { navigate } = useRouter();

  const { invalidateGetAllProductsCache } = useInvalidateGetAllProductsCache();

  const { isLoading, onCreateProduct } = useCreateProduct({
    onSuccess: async ({ productId }) => {
      onClose();

      await invalidateGetAllProductsCache();

      navigate(`${routePaths.products}/${productId}`);
    },
  });

  const onSubmit = async (values: NewProductModalFormData) => {
    await onCreateProduct({
      ...values,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={newProductModalInitialValues}
      validateOnChange
      validateOnMount
      validationSchema={newProductModalSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Modal
          footer={
            <NewProductModalFooter processing={isLoading} onClose={onClose} />
          }
          header="New Product"
          maxWidth={{ lg: '50rem', md: '100%' }}
          onClose={onClose}
        >
          <NewProductModalContent />
        </Modal>
      </Form>
    </Formik>
  );
};
