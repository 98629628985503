import {
  FetchPaginatedPayoutsArgs,
  FetchPaginatedPayoutsResponse,
} from './fetch-paginated-payouts.types';
import { fetchPaginatedPayouts } from './fetch-paginated-payouts';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';

export const useGetPaginatedPayouts = <
  TransformedData = FetchPaginatedPayoutsResponse,
>(
  props?: GenericFetchProps<
    FetchPaginatedPayoutsArgs,
    FetchPaginatedPayoutsResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch payouts records.',
    queryObj: getPaginatedPayoutsQueryObj,
    ...props,
  });
};

export const getPaginatedPayoutsQueryObj = getGenericQueryObj({
  groupName: 'payouts-paginated-records',
  query: fetchPaginatedPayouts,
});

export const useInvalidateGetPaginatedPayoutsCache = () => {
  const invalidateGetPaginatedPayoutsCache = useGetInvalidateQueryCache(
    getPaginatedPayoutsQueryObj,
  );

  return { invalidateGetPaginatedPayoutsCache };
};
