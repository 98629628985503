import { Conditional, Icon, Stack, Tooltip } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { SectionTitle } from '../../../../../../components/layout/section-title';
import { SkuConfigurationVariants } from './components/marketplace-skus-variants';
import { SkuConfigurationColors } from './components/marketplace-skus-colors';
import { productInfoPageMaxWidth } from '../../info.constants';
import { productAtoms } from '../../../../product.atoms';

export const MarketplaceSkusOverview = () => {
  const product = useRecoilValue(productAtoms.product);

  return (
    <Stack gap="2x">
      <SectionTitle text="Marketplace SKUs Overview">
        <Conditional condition={!product?.colors?.length}>
          <Tooltip
            maxWidth="22.7rem"
            position="top-start"
            title="A painted product cannot become 'Active' until at least one color is selected."
          >
            <Icon color="warning" name="alert:warning_triangle" />
          </Tooltip>
        </Conditional>
      </SectionTitle>

      <Stack gap="3x" maxWidth={productInfoPageMaxWidth}>
        <SkuConfigurationColors />

        <SkuConfigurationVariants />
      </Stack>
    </Stack>
  );
};
