import {
  Box,
  Button,
  Conditional,
  Flex,
  Grid,
  Icon,
  SearchField,
  Stack,
  Tooltip,
} from 'gantri-components';
import { useFormikContext } from 'formik';
import { StockJobsAssembleInventoryFormData } from '../../stock-jobs-assemble-inventory.schema';
import { Label } from '../../../../../../../../../../components/label';
import { AssembleInventoryVariantsSectionProps } from './assemble-inventory-variants-section.types';
import { VariantSectionItem } from './components/variant-section-item';
import { useGetVersionDetails } from '../../../../../../hooks/use-get-version-details';
import { getTemplateEditDisabledReason } from '../../../../../../../../../../components/common/jobs-template-table/helpers/get-template-edit-disabled-reason';
import { useInventoryVariantActions } from './hooks/use-inventory-variant-actions';

export const AssembleInventoryVariantsSection = (
  props: AssembleInventoryVariantsSectionProps,
) => {
  const { isEditing } = props;

  const { values } = useFormikContext<StockJobsAssembleInventoryFormData>();

  const { isMinorVersion } = useGetVersionDetails();

  const {
    getVariantData,
    handleAdd,
    handleCancel,
    handleOnSelect,
    handleShowAddVariantForm,
    isLoading,
    selectedGlobalVariantId,
    showAddVariantForm,
    variantItems,
  } = useInventoryVariantActions({ isEditing });

  return (
    <Conditional condition={isEditing}>
      <Stack gap="x">
        <Label text="Variants" />

        <Conditional
          condition={isLoading}
          Fallback={
            <>
              {values.variantIds.map((id) => {
                return (
                  <VariantSectionItem key={id} variant={getVariantData(id)} />
                );
              })}
            </>
          }
        >
          <Box verticalPadding="x">
            <Icon color="link" name="animated:loader" size="3.2rem" />
          </Box>
        </Conditional>

        <Conditional
          condition={showAddVariantForm}
          Fallback={
            <Flex>
              <Tooltip
                description={
                  isMinorVersion &&
                  getTemplateEditDisabledReason({ isMinorVersion })
                }
                position="right"
              >
                <Button
                  disabled={isMinorVersion}
                  icon={<Icon color="link" name="ui-control:plus" />}
                  text="Add Variant"
                  variant="secondary"
                  onClick={handleShowAddVariantForm}
                />
              </Tooltip>
            </Flex>
          }
        >
          <Grid columns="1fr max-content max-content" gap="x">
            <SearchField
              idProperty="id"
              items={variantItems}
              labelProperty="name"
              placeholder="Search variant"
              processing={isLoading}
              onSelect={handleOnSelect}
            />

            <Button text="Cancel" variant="secondary" onClick={handleCancel} />

            <Button
              disabled={!selectedGlobalVariantId}
              text="Add"
              onClick={handleAdd}
            />
          </Grid>
        </Conditional>
      </Stack>
    </Conditional>
  );
};
