import { Form, Formik } from 'formik';
import { Modal } from 'gantri-components';
import {
  updatePartReleaseRateModalInitialValues,
  updatePartReleaseRateModalSchema,
} from './update-part-release-rate-modal.schema';
import {
  UpdatePartReleaseRateModalSchemaDef,
  UpdatePartReleaseRateModalPropsDef,
} from './update-part-release-rate-modal.types';
import { UpdatePartReleaseRateModalContent } from './components/update-part-release-rate-modal-content';
import { UpdatePartReleaseRateModalFooter } from './components/update-part-release-rate-modal-footer';
import {
  useInvalidateGetPartReleaseRateDetailsCache,
  useUpdatePartReleaseRate,
} from '../../../../../../api/parts/routes';

export const UpdatePartReleaseRateModal = (
  props: UpdatePartReleaseRateModalPropsDef,
) => {
  const { initialPartsPerDay, onClose } = props;

  const { invalidateGetPartReleaseRateDetailsCache } =
    useInvalidateGetPartReleaseRateDetailsCache();

  const { onUpdatePartReleaseRate } = useUpdatePartReleaseRate({
    onSuccess: async () => {
      onClose();

      await invalidateGetPartReleaseRateDetailsCache();
    },
  });

  const onSubmit = async (values: UpdatePartReleaseRateModalSchemaDef) => {
    await onUpdatePartReleaseRate({
      newPartsCount: values.partsPerDay,
      notes: values.notes,
    });
  };

  return (
    <Formik
      initialValues={updatePartReleaseRateModalInitialValues}
      validateOnChange
      validateOnMount
      validationSchema={updatePartReleaseRateModalSchema}
      onSubmit={onSubmit}
    >
      {() => {
        return (
          <Form>
            <Modal
              footer={<UpdatePartReleaseRateModalFooter onClose={onClose} />}
              header="Update part release rate"
              maxWidth={{ lg: '40rem', md: '100%' }}
              onClose={onClose}
            >
              <UpdatePartReleaseRateModalContent
                initialPartsPerDay={initialPartsPerDay}
              />
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
