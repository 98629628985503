import { Cell, Grid, Icon, Stack } from 'gantri-components';
import styled, { css } from 'styled-components';
import { notificationTypes } from '../../hooks/useNotification/use-notification.constants';
import { NotificationType } from '../../hooks/useNotification/use-notification.types';

export const notificationHeight = '4rem';

export const StyledNotificationsStack = styled(Stack)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10001;
`;

export const StyledGrid = styled(Grid)<{
  type: NotificationType;
}>`
  ${({ theme, type }) => {
    const getNotificationColor = () => {
      switch (type) {
        case notificationTypes.error:
          return theme.colors.surfaces.alert.t3;

        case notificationTypes.warning:
          return theme.colors.surfaces.warning.t3;

        case notificationTypes.success:
        default:
          return theme.colors.surfaces.blue.t3;
      }
    };

    return css`
      height: ${notificationHeight};
      background-color: ${getNotificationColor()};
      box-shadow: ${theme.colors.shadow.low};
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    `;
  }}
`;

export const StyledOverflowEllipsisCell = styled(Cell)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  * {
    overflow: inherit;
    white-space: inherit;
    text-overflow: inherit;
  }
`;

export const ClickableIcon = styled(Icon)`
  cursor: pointer;
`;
