import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { CreateVersionArgs, CreateVersionResponse } from '../../version.types';
import { createDraftVersion } from './create-draft';

export const useCreateDraftVersion = <TransformedData = CreateVersionResponse>(
  props?: GenericMutateQueryProps<
    CreateVersionArgs,
    CreateVersionResponse,
    TransformedData
  >,
) => {
  const { onMutate: onCreateDraftVersion, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to create draft version.',
    mutationFn: createDraftVersion,
    ...props,
  });

  return { ...rest, onCreateDraftVersion };
};
