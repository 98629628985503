import { getFileUrl } from 'gantri-components';
import { format } from 'date-fns';
import { Payout } from '../../api/transactions/routes/get-payout/get-payout.types';
import { cloudinaryUrl } from '../../helpers/images';
import { MEDIUM_FORMAT } from '../../constants/dates';

export const formattedDesignerLocation = (payout: Payout): string => {
  const { city, country, state } = payout.location;

  if (!state) {
    return `${city}, ${country}`;
  }

  return `${city}, ${state}`;
};

export const getLogoUrl = (payout: Payout): string => {
  const logoUrl = getFileUrl({
    directory: 'designers',
    fileName: payout?.logoUrl,
    fileType: 'headshot',
    identifiers: {
      userId: payout?.userId,
    },
  });

  return cloudinaryUrl(logoUrl, {
    crop: 'limit',
    height: 80,
    width: 80,
  });
};

export const formattedSummaryPeriod = (payout: Payout) => {
  const formattedStartDate = format(new Date(payout.startDate), MEDIUM_FORMAT);
  const formattedEndDate = format(new Date(payout.endDate), MEDIUM_FORMAT);

  return `${formattedStartDate} - ${formattedEndDate}`;
};
