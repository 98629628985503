import {
  Badge,
  Conditional,
  Flex,
  Icon,
  Stack,
  Tooltip,
  Typography,
  useModal,
} from 'gantri-components';
import { useTheme } from 'styled-components';
import isNil from 'lodash/isNil';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { Vendor } from '../../../../api/vendors/vendors.types';
import routePaths from '../../../../config/route-paths';
import { formatDate } from '../../../../helpers/formatter';
import { TableHeaderWithTooltip } from '../../../../components/common/table-header-with-tooltip';
import { StyledTruncatedTypography } from '../../vendors.styles';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import { MoreMenu } from '../../../../components/dropdowns';
import { useNotification } from '../../../../hooks/useNotification';
import { NewVendorModal } from '../../components/new-vendor-modal';
import { getIsClickableIfValue } from '../../../../helpers/get-is-clickable-if-value';

export const useGetVendorsColumns = () => {
  const [vendorToEdit, setVendorToEdit] = useState<Vendor>();
  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const [showVendorModal, hideVendorModal] = useModal(() => {
    return (
      <NewVendorModal
        vendor={vendorToEdit}
        onClose={() => {
          setVendorToEdit(undefined);
          hideVendorModal();
        }}
      />
    );
  }, [vendorToEdit]);

  const columns: ColumnDef<Vendor>[] = [
    {
      accessorKey: 'id',
      cell: ({ getValue }: CellContext<Vendor, Vendor['id']>) => {
        const id = getValue();

        return (
          <StyledAnchor text={`#${id}`} to={`${routePaths.vendors}/${id}`} />
        );
      },
      header: 'Vendor #',
      meta: {
        getIsClickable: getIsClickableIfValue,
      },
      size: 100,
    },
    {
      accessorKey: 'name',
      header: 'Vendor Name',
      size: 250,
    },
    {
      accessorKey: 'activeInventoriesCount',
      cell: ({
        getValue,
        row: { original },
      }: CellContext<Vendor, Vendor['activeInventoriesCount']>) => {
        const { activeInventories } = original;
        const activeInventoriesCount = getValue();

        const maxToList = 10;
        const numBeyondMax =
          activeInventoriesCount > maxToList
            ? activeInventoriesCount - maxToList
            : 0;
        const inventoriesToLog = Array.isArray(activeInventories)
          ? activeInventories?.slice(0, maxToList)
          : [];

        return (
          <Typography
            icon={
              activeInventories?.length > 0 && (
                <Tooltip
                  Component={
                    <Stack
                      gap="0.8rem"
                      horizontalPadding="2rem"
                      verticalPadding="1.2rem"
                      width="22.4rem"
                    >
                      {inventoriesToLog.map(({ id, name }) => {
                        return <Typography key={id} color="t2" text={name} />;
                      })}
                      <Conditional condition={!!numBeyondMax}>
                        <Typography text={`+${numBeyondMax} additional...`} />
                      </Conditional>
                    </Stack>
                  }
                  position="top"
                >
                  <Icon name="alert:i_circle_24" size="1.6rem" />
                </Tooltip>
              )
            }
            iconPosition="right"
            text={activeInventoriesCount}
          />
        );
      },
      header: 'Active Inventories',
      minSize: 125,
    },
    {
      accessorKey: 'lastOrdered',
      cell: ({ getValue }: CellContext<Vendor, Vendor['lastOrdered']>) => {
        return <Typography text={formatDate(getValue())} />;
      },
      header: 'Last Ordered',
      size: 120,
    },
    {
      accessorKey: 'status',
      cell: ({ getValue }: CellContext<Vendor, Vendor['status']>) => {
        const theme = useTheme();
        const status = getValue();

        const badgeColors =
          status === 'Inactive'
            ? {
                borderColor: theme.colors.surfaces.monochrome.t3,
                color: theme.colors.surfaces.monochrome.overlayLevel2,
                textColor: theme.colors.typography.t1,
              }
            : {
                borderColor: theme.colors.surfaces.green.t2,
                color: theme.colors.surfaces.green.t1,
                textColor: theme.colors.typography.t1,
              };

        return <Badge {...badgeColors} text={status} />;
      },
      header: 'Status',
      size: 60,
    },
    {
      accessorKey: 'contact',
      header: 'Contact',
    },
    {
      accessorKey: 'email',
      header: 'Email',
    },
    {
      accessorKey: 'website',
      cell: ({ getValue }: CellContext<Vendor, Vendor['website']>) => {
        const website = getValue();
        const isValidLink = /^https?:\/\//i.test(website);
        const href = isValidLink ? website : `https://${website}`;

        return (
          <a href={href} rel="noreferrer" target="_blank">
            <StyledTruncatedTypography
              color="link"
              decoration="underline"
              text={website}
              width="20rem"
            />
          </a>
        );
      },
      header: 'Website',
      meta: {
        getIsClickable: getIsClickableIfValue,
      },
    },
    {
      accessorKey: 'delayedShipments',
      cell: ({ getValue }: CellContext<Vendor, Vendor['delayedShipments']>) => {
        const delayedShipments = getValue();

        return (
          <Typography
            color={delayedShipments ? 'warning' : 't1'}
            text={delayedShipments}
          />
        );
      },
      header: () => {
        return (
          <TableHeaderWithTooltip
            headerText="Delayed Shipments"
            tooltipDescription="Total instances of shipments being delivered later than the expected delivery date."
          />
        );
      },
      meta: {
        label: 'Delayed Shipments',
      },
      minSize: 180,
    },
    {
      accessorKey: 'discarded',
      cell: ({ getValue }: CellContext<Vendor, Vendor['quantityIssues']>) => {
        const discarded = getValue();

        return <Typography text={!isNil(discarded) ? `${+discarded}%` : ''} />;
      },
      header: () => {
        return (
          <TableHeaderWithTooltip
            headerText="Discarded Avg"
            tooltipDescription="Average discarded inventory % from all active vendor inventories.."
          />
        );
      },
      meta: {
        label: 'Discarded Avg',
      },
      size: 140,
    },
    {
      cell: ({ row }) => {
        return (
          <Flex justifyContent="flex-end">
            <MoreMenu
              data={row.original}
              options={[
                {
                  enabled: true,
                  name: 'Edit',
                  onOptionClick: async (vendor: Vendor) => {
                    try {
                      showLoading();
                      setVendorToEdit(vendor);
                      showVendorModal();
                    } catch (error: unknown) {
                      notifyAxiosError({
                        error,
                        fallbackMessage: 'Unable to fetch vendor.',
                      });
                    } finally {
                      hideLoading();
                    }
                  },
                },
              ]}
            />
          </Flex>
        );
      },
      id: 'menu',
      size: 30,
    },
  ];

  return columns;
};
