import styled from 'styled-components';

export const StyledBadgeContainer = styled.div`
  margin-bottom: 0.2rem;
`;

export const StyledUserContainer = styled.div`
  a {
    display: inline-block;
  }
`;
