import { GetHeaderTextArgs } from './get-header-text.types';

export const getHeaderText = (args: GetHeaderTextArgs) => {
  const { action, step } = args;

  switch (step) {
    case 'CONFIRM_UPDATE':
      return 'Update Version';

    case 'CONFIRM_CANCEL':
      return 'Cancel Stock';

    case 'UPDATE_ERROR':
      return 'Update not successful';

    case 'UPDATE_SUCCESS':
      return action === 'CANCEL'
        ? 'Successfully Cancelled'
        : 'Update successful';

    case 'REQUEST_VLM':
      return 'Stock Picking';

    case 'REMOVED_FROM_VLM':
      return 'Stock Picking Complete';

    default:
      return null;
  }
};
