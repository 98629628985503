import { Modal } from 'gantri-components';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UpdateStockVersionContent } from './components/update-stock-version-content';
import { UpdateStockVersionFooter } from './components/update-stock-version-footer';
import { getHeaderText } from './helpers/get-header-text';
import {
  cancelOrUpdateStockVersionModalNewStockAtom,
  cancelOrUpdateStockVersionModalStepAtom,
  cancelOrUpdateStockVersionModalUpdateOnCloseAtom,
} from './cancel-or-update-stock-version-modal.atoms';
import { useResetCancelOrUpdateStockVersionModalStates } from './hooks/use-reset-cancel-or-update-stock-version-modal-states';
import {
  CancelOrUpdateStockVersionModalProps,
  CancelOrUpdateStockVersionModalStep,
} from './cancel-or-update-stock-version-modal.types';
import routePaths from '../../../../../../config/route-paths';

/** Modal for either cancelling a stock or replacing it with one from the latest version. */
export const CancelOrUpdateStockVersionModal = (
  props: CancelOrUpdateStockVersionModalProps,
) => {
  const { action, onClose, onUpdate, stock } = props;

  const resetCancelOrUpdateStockVersionModalStates =
    useResetCancelOrUpdateStockVersionModalStates();
  const [step, setStep] = useRecoilState(
    cancelOrUpdateStockVersionModalStepAtom,
  );

  const newStock = useRecoilValue(cancelOrUpdateStockVersionModalNewStockAtom);
  const updateOnClose = useRecoilValue(
    cancelOrUpdateStockVersionModalUpdateOnCloseAtom,
  );

  const handleOnClose = async () => {
    onClose();

    if (newStock?.id) {
      window.location.assign(`${routePaths.stocks}/${newStock.id}`);
    } else if (updateOnClose) {
      await onUpdate();
    }
  };

  useEffect(() => {
    resetCancelOrUpdateStockVersionModalStates();

    const startingStep: CancelOrUpdateStockVersionModalStep =
      action === 'CANCEL' ? 'CONFIRM_CANCEL' : 'CONFIRM_UPDATE';

    setStep(startingStep);
  }, []);

  return (
    <Modal
      footer={
        <UpdateStockVersionFooter stock={stock} onClose={handleOnClose} />
      }
      header={getHeaderText({ action, step })}
      width={{ lg: '60rem', md: '100%' }}
      onClose={handleOnClose}
    >
      <UpdateStockVersionContent stock={stock} />
    </Modal>
  );
};
