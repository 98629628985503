import { CellContext, ColumnDef } from '@tanstack/react-table';
import {
  Conditional,
  Flex,
  Icon,
  Tooltip,
  Typography,
  useModal,
} from 'gantri-components';
import { useMemo, useState } from 'react';
import { PaginatedBatch } from '../../../../api/batch/routes/get-paginated-batches/get-paginated-batches.types';
import { ColorSwatchAndLabel } from '../../../../components/common/color-swatch-and-label';
import { MoreMenu } from '../../../../components/dropdowns';
import routePaths from '../../../../config/route-paths';
import { BatchStatusBadge } from '../../components/batch-badge';
import { ArchiveBatchModal } from './components/archive-batch-modal';
import { DateWithUserTooltip } from '../../../../components/common/date-with-user-tooltip';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import { getIsClickableIfValue } from '../../../../helpers/get-is-clickable-if-value';
import { StyledSwatchStack } from './use-get-batches-columns.styles';
import { SandDurationHeader } from './components/sand-duration-header';
import { SandDurationCell } from './components/sand-duration-cell';

interface UseGetBatchesColumnsProps {
  onUpdate: () => Promise<void>;
}

export const useGetBatchesColumns = (props: UseGetBatchesColumnsProps) => {
  const { onUpdate } = props;

  const [batchId, setBatchId] = useState<number>(null);

  const [showArchiveModal, hideArchiveModal] = useModal(() => {
    return (
      <ArchiveBatchModal
        batchId={batchId}
        onClose={hideArchiveModal}
        onUpdate={onUpdate}
      />
    );
  }, [batchId]);

  const columns: ColumnDef<PaginatedBatch>[] = useMemo(() => {
    return [
      {
        accessorKey: 'id',
        cell: ({
          getValue,
        }: CellContext<PaginatedBatch, PaginatedBatch['id']>) => {
          const id = getValue();

          return (
            <StyledAnchor text={`#${id}`} to={`${routePaths.batches}/${id}`} />
          );
        },
        header: 'Batch',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
        size: 80,
      },
      {
        accessorKey: 'colors',
        cell: ({
          getValue,
        }: CellContext<PaginatedBatch, PaginatedBatch['colors']>) => {
          const colors = getValue();

          return (
            <Conditional
              condition={!!colors.length}
              Fallback={<Typography text="-" />}
            >
              <StyledSwatchStack gap="2px">
                {colors.map((colorCode) => {
                  return (
                    <ColorSwatchAndLabel
                      key={colorCode}
                      colorCode={colorCode}
                      size="1.6rem"
                    />
                  );
                })}
              </StyledSwatchStack>
            </Conditional>
          );
        },
        header: 'Colors',
      },
      {
        accessorKey: 'currentSteps',
        cell: ({
          getValue,
        }: CellContext<PaginatedBatch, PaginatedBatch['currentSteps']>) => {
          const steps = getValue();

          return (
            <Conditional
              condition={!!steps.length}
              Fallback={<Typography text="-" />}
            >
              {steps.map((step) => {
                return <Typography key={step} text={step} />;
              })}
            </Conditional>
          );
        },
        header: () => {
          return (
            <Typography
              icon={
                <Tooltip
                  position="bottom-end"
                  title="Current job step/type of all parts in the batch."
                >
                  <Icon name="alert:i_circle" />
                </Tooltip>
              }
              iconPosition="right"
              text="Current Step"
              textStyle="bold"
            />
          );
        },
      },
      {
        accessorKey: 'totalParts',
        cell: ({
          getValue,
        }: CellContext<PaginatedBatch, PaginatedBatch['totalParts']>) => {
          const totalParts = getValue();

          return (
            <Conditional
              condition={!!totalParts}
              Fallback={<Typography text="-" />}
            >
              <Typography text={totalParts} />
            </Conditional>
          );
        },
        header: '# of Parts',
      },
      {
        accessorKey: 'totalSandRawDuration',
        cell: ({
          getValue,
        }: CellContext<
          PaginatedBatch,
          PaginatedBatch['totalSandRawDuration']
        >) => {
          return <SandDurationCell sandDuration={getValue()} />;
        },
        header: SandDurationHeader,
      },
      {
        accessorKey: 'status',
        cell: ({
          getValue,
        }: CellContext<PaginatedBatch, PaginatedBatch['status']>) => {
          return <BatchStatusBadge status={getValue()} />;
        },
        header: 'Status',
      },
      {
        accessorKey: 'createdAt',
        cell: ({
          getValue,
          row,
        }: CellContext<PaginatedBatch, PaginatedBatch['createdAt']>) => {
          return (
            <DateWithUserTooltip
              date={getValue()}
              statusInfo={row.original.statusInfo}
              type="created"
            />
          );
        },
        header: 'Created',
      },
      {
        cell: ({ row }: CellContext<PaginatedBatch, never>) => {
          return (
            <Flex justifyContent="flex-end">
              <MoreMenu
                options={[
                  {
                    enabled:
                      row.original.status === 'Active' &&
                      row.original.totalParts === 0,
                    name: 'Archive batch',
                    onOptionClick: () => {
                      setBatchId(row.original.id);
                      showArchiveModal();
                    },
                  },
                ]}
              />
            </Flex>
          );
        },
        id: 'menu',
        maxSize: 20,
      },
    ];
  }, []);

  return columns;
};
