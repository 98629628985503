import styled, { css } from 'styled-components';
import { media } from 'gantri-components';
import { transitions } from '../constants/styles';
import { getActiveEnv } from '../helpers/get-active-env';

// prettier-ignore
export const StyledAppContainer = styled.div<{
  isDesktop?: boolean;
  isSidebarOpen: boolean;
}>`
  ${({ isDesktop, theme }) => {

    const { isDevelopEnv, isStagingEnv } = getActiveEnv();

    return css`
        ${transitions.base}
        transition-property: left;
        padding: 0 1.6rem;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 0.4rem;

          ${isDevelopEnv && isDesktop && css`
            background-color: ${theme.colors.surfaces.warning.t1};
          `}

          ${isStagingEnv && isDesktop && css`
            background-color: ${theme.colors.surfaces.green.t1};
          `}
        }

        ${media.lessThan('md')`
          padding: 4.8rem 1.2rem 0;
        `}
      `;
    }}
`;
