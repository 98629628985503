import { Flex, Icon, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { updatePartBatchModalAtoms } from '../../update-part-batch-modal.atoms';
import { UpdatePartBatchModalStep } from '../../update-part-batch-modal.types';

export const UpdatePartBatchModalHeader = () => {
  const step = useRecoilValue(updatePartBatchModalAtoms.step);

  switch (step) {
    case 'UPDATE_BATCH':
      return <Typography align="center" text="Update Batch" variant="h4" />;

    case 'PART_REMOVED_SUCCESS':
    case 'PART_ADDED_SUCCESS':
    case 'BATCH_CREATION_SUCCESS':
      return (
        <Flex alignItems="center" gap="x" justifyContent="center">
          <Icon
            color="success"
            name="ui-control:check_mark_circle_filled_24"
            size="2.4rem"
          />
          <Typography align="center" text={getSuccessText(step)} variant="h4" />
        </Flex>
      );

    case 'PART_REMOVED_ERROR':
    case 'PART_ADDED_ERROR':
    case 'BATCH_CREATION_ERROR':
      return (
        <Flex alignItems="center" gap="x" justifyContent="center">
          <Icon color="alert" name="alert:warning_triangle_24" size="2.4rem" />
          <Typography align="center" text="Error" variant="h4" />
        </Flex>
      );

    default:
      return null;
  }
};

const getSuccessText = (step: UpdatePartBatchModalStep) => {
  switch (step) {
    case 'PART_REMOVED_SUCCESS':
      return 'Part removed';

    case 'PART_ADDED_SUCCESS':
      return 'Part added';

    case 'BATCH_CREATION_SUCCESS':
      return 'Batch created and part added';

    default:
      return '';
  }
};
