import { Button, Conditional } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { locationsApi } from '../../../../../../../../../api';
import { useNotification } from '../../../../../../../../../hooks/useNotification';
import {
  cancelOrUpdateStockVersionModalStepAtom,
  cancelOrUpdateStockVersionModalVlmLocationAtom,
  cancelOrUpdateStockVersionModalVlmNotifiedAtom,
} from '../../../cancel-or-update-stock-version-modal.atoms';
import { DoneButton } from '../../done-button';
import { UpdateSuccessFooterProps } from './update-success-footer.types';

export const UpdateSuccessFooter = (props: UpdateSuccessFooterProps) => {
  const { onClose, stockLocation } = props;

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const setStep = useSetRecoilState(cancelOrUpdateStockVersionModalStepAtom);
  const setVlmNotified = useSetRecoilState(
    cancelOrUpdateStockVersionModalVlmNotifiedAtom,
  );
  const setVlmLocation = useSetRecoilState(
    cancelOrUpdateStockVersionModalVlmLocationAtom,
  );

  const handleRequestVlm = async () => {
    try {
      showLoading();

      if (stockLocation?.id) {
        const { data } = await locationsApi.callVlm(stockLocation.id);

        setVlmNotified(data.vlmNotified);
        setVlmLocation(data.location);
      }
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to contact VLM.' });
    } finally {
      hideLoading();
      setStep('REQUEST_VLM');
    }
  };

  return (
    <Conditional
      condition={!!stockLocation}
      Fallback={<DoneButton onClose={onClose} />}
    >
      <Button size="large" text="Continue" onClick={handleRequestVlm} />
    </Conditional>
  );
};
