import { useFormikContext } from 'formik';
import { Button } from 'gantri-components';
import { updatePartReleaseRateModalSteps } from '../../../update-part-release-rate-modal.constants';
import { UpdatePartReleaseRateModalSchemaDef } from '../../../update-part-release-rate-modal.types';

export const PartReleaseRateModalConfirmFooter = () => {
  const { isSubmitting, isValid, setFieldValue, values } =
    useFormikContext<UpdatePartReleaseRateModalSchemaDef>();

  return (
    <>
      <Button
        size="large"
        text="Back"
        variant="secondary"
        onClick={async () => {
          await setFieldValue(
            'step',
            updatePartReleaseRateModalSteps.updateRate,
          );
        }}
      />
      <Button
        disabled={!isValid || values.processing}
        processing={isSubmitting}
        size="large"
        text="Confirm"
        type="submit"
      />
    </>
  );
};
