import {
  Button,
  Cell,
  Dropdown,
  FormikInput,
  Grid,
  Modal,
  TextField,
} from 'gantri-components';
import React from 'react';
import { Form, Formik } from 'formik';
import { useNotification } from '../../../../hooks/useNotification';
import { NewVendorModalProps } from './new-vendor-modal.types';
import { NewVendorFormSchema } from './new-vendor-form.schema';
import { vendorsApi } from '../../../../api';
import { convertQueriesToString } from '../../../../helpers/checks';
import { VendorPageQueries } from '../../../vendor/vendor.types';
import routePaths from '../../../../config/route-paths';
import {
  canadaStates,
  countries,
  usaStates,
} from '../../../../constants/locales';
import { useRouter, useSpinner } from '../../../../hooks';
import { useInvalidateFetchAllVendorNamesCache } from '../../../../api/vendors/routes';

export const NewVendorModal = (props: NewVendorModalProps) => {
  const { onClose, vendor } = props;

  const { onInterceptRequest } = useSpinner();
  const { navigate } = useRouter();
  const { notifyAxiosError } = useNotification();
  const { invalidateFetchAllVendorNamesCache } =
    useInvalidateFetchAllVendorNamesCache();
  const isEditing = !!vendor;

  const onSubmit = async (values) => {
    await onInterceptRequest(async () => {
      try {
        if (isEditing) {
          const { data } = await vendorsApi.updateVendor(vendor.id, values);

          navigate(`${routePaths.vendors}/${data.vendor.id}`);

          return;
        }

        const { data } = await vendorsApi.createVendor(values);
        const queryString = convertQueriesToString<VendorPageQueries>({
          isNewVendor: true,
        });

        navigate(`${routePaths.vendors}/${data.vendor.id}${queryString}`);
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: `Unable to ${
            isEditing ? 'update' : 'create new'
          } vendor.`,
        });
      } finally {
        invalidateFetchAllVendorNamesCache();
      }
    });
  };

  return (
    <Formik
      initialValues={
        vendor ?? {
          city: '',
          contact: '',
          country: '',
          email: '',
          name: '',
          state: '',
        }
      }
      validateOnChange
      validateOnMount
      validationSchema={NewVendorFormSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid, setFieldValue, values }) => {
        return (
          <Form>
            <Modal
              footer={
                <>
                  <Button
                    size="large"
                    text="Cancel"
                    variant="secondary"
                    onClick={onClose}
                  />
                  <Button
                    disabled={!isValid}
                    size="large"
                    text="Confirm"
                    type="submit"
                  />
                </>
              }
              header={`${dirty ? 'Edit' : 'New'} Vendor`}
              width={{ lg: '55rem', md: '100%' }}
              onClose={onClose}
            >
              <Grid columns={{ lg: 2, sm: 1 }}>
                <Cell width={{ lg: 2, sm: 1 }}>
                  <FormikInput
                    labelText="Vendor name"
                    name="name"
                    placeholder="Enter a name"
                    required
                  />
                </Cell>
                <Cell>
                  <FormikInput
                    labelText="Contact name"
                    name="contact"
                    placeholder="Enter a name"
                    required
                  />
                </Cell>
                <Cell>
                  <FormikInput
                    labelText="Email"
                    name="email"
                    placeholder="Enter email"
                    required
                  />
                </Cell>
                <Cell width={{ lg: 2, sm: 1 }}>
                  <FormikInput
                    Field={
                      <Dropdown
                        idProperty="name"
                        items={countries}
                        labelProperty="name"
                        placeholder="Country"
                        onSelect={() => {
                          setFieldValue('state', '');
                        }}
                      />
                    }
                    labelText="Country"
                    name="country"
                    placeholder="Enter country"
                    required
                  />
                </Cell>
                <Cell>
                  <FormikInput
                    labelText="City"
                    name="city"
                    placeholder="Enter city"
                    required
                  />
                </Cell>
                <Cell>
                  <FormikInput
                    Field={
                      ['United States', 'Canada'].includes(values.country) ? (
                        <Dropdown
                          idProperty="name"
                          items={
                            values.country === 'Canada'
                              ? canadaStates
                              : usaStates
                          }
                          labelProperty="name"
                          placeholder="Select state/province"
                        />
                      ) : (
                        <TextField
                          name="state"
                          placeholder="Enter state/province"
                        />
                      )
                    }
                    fieldVariant="standard"
                    labelText="State/Province"
                    name="state"
                    required
                  />
                </Cell>
              </Grid>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
