import { ExtractStyleFromResolutionAwarePropEntry } from 'gantri-components/dist/types/layout';
import { AppSize } from 'gantri-components/dist/types/resolution-aware-prop.type';
import styled, { css } from 'styled-components';
import { Cell, generateStylesForResolutionAwareProps } from 'gantri-components';

export const StyledFormikInputContainer = styled(Cell)`
  ${({ theme }) => {
    // @ts-ignore
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'font-family',
        resolutionAwareProp: 'p1',
        valueFormatter: () => {
          const { family } = theme.fonts.p1;

          return `${family}, "Helvetica", sans-serif;`;
        },
      },
      {
        cssProperty: 'font-size',
        resolutionAwareProp: [],
        valueFormatter: ([], size: AppSize) => {
          const type = theme.fonts.p1;

          // @ts-ignore
          return type[
            size === 'sm'
              ? 'sizeSmaller'
              : size === 'md'
              ? 'sizeMedium'
              : 'size'
          ];
        },
      },
      {
        cssProperty: 'line-height',
        resolutionAwareProp: [],
        valueFormatter: ([], size: AppSize) => {
          const type = theme.fonts.p1;

          // @ts-ignore
          return type[
            size === 'sm'
              ? 'lineHeightSmaller'
              : size === 'md'
              ? 'lineHeightMedium'
              : 'lineHeight'
          ];
        },
      },
      {
        cssProperty: 'font-weight',
        resolutionAwareProp: 'p1',
        // @ts-ignore
        valueFormatter: () => {
          const type = theme.fonts.p1;

          return type.fontWeight ?? 400;
        },
      },
    ];

    return css`
      input {
        ${generateStylesForResolutionAwareProps(entries)};
      }
    `;
  }}
`;
