import {
  Conditional,
  FormikInput,
  Stack,
  TextField,
  Typography,
} from 'gantri-components';
import { useFormikContext } from 'formik';
import { AddStocksFormData } from '../../../add-stocks-modal.types';
import { SelectedStocksDetails } from '../../../../selected-stocks-details';
import { SetQuantityContentProps } from './set-quantity-content.types';
import { SingleStockLevels } from '../../single-stock-levels';

export const SetQuantityContent = (props: SetQuantityContentProps) => {
  const { stocks } = props;

  const { values } = useFormikContext<AddStocksFormData>();

  const onlyOneStock = stocks.length === 1;
  const [firstStock] = stocks;
  const { qtyToAdd } = firstStock;

  const placeholderText =
    onlyOneStock && qtyToAdd > 0
      ? `Recommended to add: ${qtyToAdd}`
      : 'Input quantity';

  return (
    <Stack gap="4x">
      <SelectedStocksDetails stocks={stocks} />

      <Conditional condition={onlyOneStock}>
        <SingleStockLevels stock={firstStock} />
      </Conditional>

      <Stack gap=".5x">
        <Typography text="Stocks to add" textStyle="bold" />
        <FormikInput
          Field={
            <TextField
              minValue={1}
              placeholder={placeholderText}
              type="number"
            />
          }
          name="stocksToAdd"
          required
          value={String(values.stocksToAdd)}
        />
      </Stack>
    </Stack>
  );
};
