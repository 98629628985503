import { defaultImageExtensions, FileUploaderProps } from 'gantri-components';
import { ProductSkuLevelFileType } from '../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';

export const invalidFileReasons = {
  isInvalidDimensions: 'Small file',
  isInvalidExtension: 'Invalid extension',
  isInvalidFileName: 'Invalid name',
  isInvalidFileSize: 'Large file',
  isInvalidQty: 'Qty limit reached',
} as const;

export const productSkuLevelAssetsFileTypes = {
  dimmingPhotoDark: 'dimmingPhotoDark',
  dimmingPhotoLight: 'dimmingPhotoLight',
  scalePhoto: 'scalePhoto',
  usdz: 'usdz',
  whiteBackgroundPhotos: 'whiteBackgroundPhotos',
} as const satisfies Record<ProductSkuLevelFileType, ProductSkuLevelFileType>;

export const productFileTypesMaxQty = {
  dimmingPhotoDark: 1,
  dimmingPhotoLight: 1,
  scalePhoto: 1,
  usdz: 1,
  whiteBackgroundPhotos: 5,
} as const satisfies Record<
  keyof typeof productSkuLevelAssetsFileTypes,
  number
>;

export const bulkUploadsRestrictions = {
  expectedExtensions: [...defaultImageExtensions, 'usdz'] as string[],
  maxFileSizeMB: 10,
  maxUploadsAllowed: 200,
  minImageHeight: 400,
  minImageWidth: 400,
} as const satisfies Partial<FileUploaderProps>;

export const exitWarningModalHeader = 'Bulk Manage SKU Assets';
