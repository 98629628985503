import { Button } from 'gantri-components';
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { ChangeUserTypeModalFormDataDef } from '../../../user-type-modal.types';
import { changeUserTypeModalSteps } from '../../../user-type-modal.constants';
import { ChangeUserTypeModalUpdateFooterPropsDef } from './change-user-type-modal-update-footer.types';

export const ChangeUserTypeModalUpdateFooter = (
  props: ChangeUserTypeModalUpdateFooterPropsDef,
) => {
  const { initialValues, onClose } = props;

  const { isValid, setFieldValue, values } =
    useFormikContext<ChangeUserTypeModalFormDataDef>();

  const isUnchanged = isEqual(values, initialValues);

  const onContinue = async () => {
    await setFieldValue('step', changeUserTypeModalSteps.confirm);
  };

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={onClose}
      />
      <Button
        disabled={!isValid || isUnchanged}
        size="large"
        text="Continue"
        onClick={onContinue}
      />
    </>
  );
};
