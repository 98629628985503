import styled from 'styled-components';
import { Grid, media } from 'gantri-components';

export const StyledUserSummaryBorder = styled.div`
  border: 1px solid
    ${(props) => {
      return props.theme.colors.dividers.t1;
    }};
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 20px;
  padding-top: 0;
  overflow-x: auto;
`;

export const StyledUserSummarySectionContainer = styled.div`
  flex: 1;
  min-width: 500px;
  margin-top: 20px;
`;

export const StyledSummarySpacer = styled.div`
  width: 100%;
  height: 24px;
`;

export const StyledSummaryFieldLabelWrapper = styled.div`
  width: 250px;
  margin-right: 10px;
`;

export const StyledSummaryFieldValueWrapper = styled.div`
  min-width: 200px;
`;

export const StyledNotesWrapper = styled.div`
  margin-bottom: 3rem;
  min-width: 35rem;

  ${media.lessThan('sm')`
    min-width: 100%;
 `}
`;

export const StyledExternalLinkWrapper = styled.a`
  display: flex;
  align-items: center;
`;

export const StyledTabListGrid = styled(Grid)`
  border-bottom: 1px solid
    ${(props) => {
      return props.theme.colors.dividers.t1;
    }};
`;
