import { Conditional, Typography } from 'gantri-components';
import { CellJobDurationProps } from './cell-job-duration.types';

export const CellJobDuration = (props: CellJobDurationProps) => {
  const { getValue } = props;

  const duration = getValue();

  return (
    <Conditional condition={!!duration}>
      <Typography text={duration} />
    </Conditional>
  );
};
