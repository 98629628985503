import { CellContext, ColumnDef } from '@tanstack/react-table';
import { ProductColorDetails, productColorsMap } from 'gantri-components';
import { TableSortField } from '../../components/common/table/table.props';
import {
  ProductReviewsSortingField,
  ProductReviewStatus,
  Review as ReviewRecord,
} from '../../api/users/routes';
import { formatDate } from '../../helpers/formatter';
import { StyledAnchor } from '../../components/common/styled-anchor';
import routePaths from '../../config/route-paths';
import { getIsClickableIfValue } from '../../helpers/get-is-clickable-if-value';
import { ReviewBadgeStatus } from '../user/reviews/components';
import { ShortProductSummary } from '../../components/common/short-product-summary';

export const sortOptions: TableSortField<ProductReviewsSortingField>[] = [
  { bidirectional: true, label: 'User', sortingField: 'user' },
  { bidirectional: true, label: 'Product Rating', sortingField: 'rating' },
  { bidirectional: true, label: 'Last Date', sortingField: 'lastDate' },
  { bidirectional: true, label: 'Product', sortingField: 'product' },
  { bidirectional: true, label: 'Version', sortingField: 'version' },
  { bidirectional: true, label: 'Stock #', sortingField: 'stockId' },
  { bidirectional: true, label: 'Order #', sortingField: 'orderId' },
];

export const columns: ColumnDef<ReviewRecord>[] = [
  {
    accessorKey: 'userName',
    cell: ({
      getValue,
      row,
    }: CellContext<ReviewRecord, ReviewRecord['userName']>) => {
      const userName = getValue();

      return (
        <StyledAnchor
          href={`${routePaths.users}/${row.original.userId}`}
          text={userName}
        />
      );
    },
    header: 'User',
    id: 'userName',
    meta: {
      getIsClickable: getIsClickableIfValue,
      hideable: false,
      reorderable: false,
    },
    size: 200,
  },
  {
    accessorKey: 'status',
    cell: ({ getValue }: CellContext<ReviewRecord, ReviewRecord['status']>) => {
      const status = getValue() as ProductReviewStatus;

      return <ReviewBadgeStatus status={status} />;
    },

    header: 'Review Status',
    id: 'status',
    meta: {
      hideable: false,
      reorderable: false,
    },
    size: 120,
  },
  {
    accessorKey: 'rating',
    header: 'Product Rating',
    id: 'rating',
    meta: {
      hideable: false,
      reorderable: false,
    },
    size: 120,
  },
  {
    accessorKey: 'lastDate',
    cell: ({ getValue }) => {
      const date = getValue();

      return formatDate(date, 'MM/dd/yyyy');
    },
    header: 'Last Date',
    id: 'lastDate',
    size: 100,
  },
  {
    accessorKey: 'product',
    cell: ({
      getValue,
    }: CellContext<ReviewRecord, ReviewRecord['product']>) => {
      const product = getValue();

      return <ShortProductSummary {...product} />;
    },
    header: 'Product',
    id: 'product',
    meta: {
      hideable: false,
      reorderable: false,
    },
    size: 250,
  },
  {
    accessorKey: 'color',
    cell: ({ getValue }: CellContext<ReviewRecord, string>) => {
      const color: ProductColorDetails = productColorsMap[getValue()];

      return color?.shortColorName;
    },
    header: 'Color',
    id: 'color',
    size: 80,
  },
  {
    accessorKey: 'version',
    header: 'Version',
    id: 'version',
    size: 80,
  },
  {
    accessorKey: 'stockId',
    cell: ({ getValue }: CellContext<ReviewRecord, string>) => {
      const stockId = getValue();

      if (!stockId) return '';

      return (
        <StyledAnchor
          text={`#${stockId}`}
          to={`${routePaths.stocks}/${stockId}`}
        />
      );
    },
    header: 'Stock #',
    id: 'stockId',
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
    size: 80,
  },
  {
    accessorKey: 'orderId',
    cell: ({ getValue }: CellContext<ReviewRecord, string>) => {
      const orderId = getValue();

      if (!orderId) return '';

      return (
        <StyledAnchor
          text={`#${orderId}`}
          to={`${routePaths.orders}/${orderId}`}
        />
      );
    },
    header: 'Order #',
    id: 'orderId',
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
    size: 80,
  },
  {
    cell: () => {
      return null;
    },
    header: '',
    id: 'actions',
    meta: {
      hideable: false,
      label: 'Actions',
      reorderable: false,
    },
    size: 30,
  },
];
