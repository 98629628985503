import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { SetUserTypeArgs, SetUserTypeResponse } from './set-user-type.types';
import { setUserType } from './set-user-type';

export const useSetUserType = <TransformedData = SetUserTypeResponse>(
  props?: GenericMutateQueryProps<
    SetUserTypeArgs,
    SetUserTypeResponse,
    TransformedData
  >,
) => {
  const { onMutate: onSetUserType, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to update user.',
    mutationFn: setUserType,
    ...props,
  });

  return { ...rest, onSetUserType };
};
