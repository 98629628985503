import { ChangeEvent, memo } from 'react';
import {
  TextField,
  Typography,
  Grid,
  Cell,
  FileUploader,
  HandleDeleteFile,
  HandleUploadsComplete,
} from 'gantri-components';
import { StyledHeroSection } from './hero-section.styles';
import { HeroSectionProps } from './hero-section.types';
import {
  fileRecoveredMessage,
  useCloudinaryFileUploader,
} from '../../../../../../hooks/use-cloudinary-file-uploader';
import { useNotification } from '../../../../../../hooks/useNotification';

const Section = (props: HeroSectionProps) => {
  const { heroImages, id, updateDesignerInfo, videoUrl } = props;

  const { notify } = useNotification();

  const heroImage = heroImages?.[0];

  const onFileDelete: HandleDeleteFile = async () => {
    updateDesignerInfo({
      key: 'heroImages',
      type: 'key',
      value: [],
    });
  };

  const handleUploadsComplete: HandleUploadsComplete = async ([
    { fileName },
  ]) => {
    updateDesignerInfo({
      key: 'heroImages',
      type: 'key',
      value: [fileName],
    });
  };

  const onFileRecovered = async (updatedFileName: string) => {
    notify(fileRecoveredMessage.saveRequired, {
      keepOpen: true,
    });

    updateDesignerInfo({
      key: 'heroImages',
      type: 'key',
      value: [updatedFileName],
    });
  };

  const { fileUploaderProps } = useCloudinaryFileUploader<'designers'>({
    directory: 'designers',
    fileName: heroImage,
    fileType: 'hero-imagery',
    handleUploadsComplete,
    identifiers: {
      userId: id,
    },
    onFileDelete,
    onFileRecovered,
    transformFileName: ({ applyUuid, removeWhitespace }) => {
      return applyUuid(removeWhitespace('hero-image'));
    },
  });

  const onVideoUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateDesignerInfo({
      key: 'videoUrl',
      type: 'key',
      value: event.target.value,
    });
  };

  return (
    <StyledHeroSection>
      <Typography
        marginBottom="1rem"
        text="Hero"
        textStyle="bold"
        variant="h4"
      />
      <Grid columns={{ lg: 3, sm: 1 }} rowGap="3rem">
        <Cell>
          <Grid
            alignItems="baseline"
            columnGap="1.4rem"
            columns={{ lg: 'max-content 1fr', md: '1fr' }}
          >
            <Cell>
              <Typography
                marginBottom="5px"
                text="Hero image"
                textStyle="bold"
              />
              <FileUploader {...fileUploaderProps} variant="thumbnail" />
            </Cell>
            <Cell>
              <Typography
                marginBottom="5px"
                text="Video url"
                textStyle="bold"
              />
              <TextField
                placeholder="Label"
                value={videoUrl}
                onChange={onVideoUrlChange}
              />
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    </StyledHeroSection>
  );
};

export const HeroSection = memo(Section);
