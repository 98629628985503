import { Box, Checkbox, Stack, Typography } from 'gantri-components';
import { useEffect } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { printPrepareQueueModalAtoms } from '../../../print-transfer-queue-modal.atoms';
import { ModalContentHeading } from '../../../../common/modal-content-heading';
import { getPrintRfidUrl } from '../../../../../../../../components/common/custom-data-cells/rfid-data-cell';
import { downloadToastText } from '../../../../../../../../hooks/use-download-with-small-toast/use-download-with-small-toast.constants';
import { pluralize } from '../../../../../../../../helpers/pluralize';
import { useGetNumJobsInSet } from '../../../hooks/get-num-jobs-in-set';
import { getFileNameWithCopyQty } from '../../../../complete-job/complete-print-transfer-modal/components/complete-print-transfer-content/helpers/get-file-name-with-copy-qty';
import { useGetActiveJobSet } from '../../../hooks/use-get-active-job-set';
import { useDownloadButton } from '../../../../../../../../hooks/use-download-button';
import { FileToDownload } from '../../../../../../../../hooks/use-download/hooks/use-handle-download-request/use-handle-download-request.types';

export const JobSetPrepareContent = () => {
  const [isPacketPrepared, setIsPacketPrepared] = useRecoilState(
    printPrepareQueueModalAtoms.isPacketPrepared,
  );
  const resetIsPacketPrepared = useResetRecoilState(
    printPrepareQueueModalAtoms.isPacketPrepared,
  );

  const set = useGetActiveJobSet();
  const numJobsInSet = useGetNumJobsInSet();
  const gcode = set?.jobDetails?.gcode;

  const gcodeFile: FileToDownload = {
    fileName: getFileNameWithCopyQty(gcode?.fileName, numJobsInSet),
    url: gcode?.url,
  };

  const {
    DownloadButton: DownloadGcodeButton,
    DownloadStatusToast: DownloadGcodeToast,
  } = useDownloadButton({
    ...downloadToastText.gcode,
    download: gcodeFile,
  });

  const rfidFiles =
    set?.parts?.map?.(({ id, stockId }) => {
      const url = getPrintRfidUrl({
        partId: id,
        stockId,
      });

      const file: FileToDownload = { url };

      return file;
    }) || [];

  const {
    DownloadButton: DownloadTagButton,
    DownloadStatusToast: DownloadTagToast,
  } = useDownloadButton({
    ...downloadToastText.rfidTag,
    download: rfidFiles,
  });

  useEffect(() => {
    resetIsPacketPrepared();
  }, []);

  return (
    <Stack gap="5x" rows="max-content 1fr">
      <Stack gap="5x">
        <Box>
          {/* Toasts must be above section title to achieve desired positioning. */}
          <DownloadGcodeToast position="bottom-end" />
          <DownloadTagToast position="bottom-end" />
          <ModalContentHeading
            titleText={`Prepare ${pluralize('packet', numJobsInSet)}`}
          />
        </Box>
        <Stack gap="2x" paddingBottom="2x">
          <Typography
            text={`Download ${pluralize('G-code', numJobsInSet)}`}
            variant="h5"
          />
          <Typography
            htmlText={`Please download g-code and connect <strong>${numJobsInSet}</strong> SD ${pluralize(
              'card',
              numJobsInSet,
            )} to reader.`}
          />
          <DownloadGcodeButton
            text={`Download: ${gcode?.fileName || 'gcode'}`}
          />
        </Stack>
        <Stack gap="2x">
          <Typography
            text={`Print RFID ${pluralize('tag', numJobsInSet)}`}
            variant="h5"
          />
          <DownloadTagButton text={`Print ${pluralize('tag', numJobsInSet)}`} />
        </Stack>
      </Stack>
      <Stack alignContent="flex-end" gap="x">
        <Typography text="Confirmation" variant="h5" />
        <Checkbox
          checked={isPacketPrepared}
          labelHtmlText={`I have verified that all packets (<strong>${numJobsInSet}</strong>) have been properly prepared.`}
          onSelected={setIsPacketPrepared}
        />
      </Stack>
    </Stack>
  );
};
