import {
  validateFileSize,
  validateImageDimensions,
  defaultImageExtensions,
  getBase64FromBlob,
  validateExtensions,
  ValidateFile,
} from 'gantri-components';
import { maxUsdzFileSizeMB } from '../../../../../../sku-level-assets.constants';
import {
  bulkUploadsRestrictions,
  invalidFileReasons,
} from '../../../../bulk-upload-modal.constants';
import { useGetIsInvalidQty } from '../use-get-is-invalid-qty';
import { useProcessFileNameAndReturnMatchingSkus } from '../use-process-file-name-and-return-matching-skus';

export const useValidateFile = () => {
  const getIsInvalidQty = useGetIsInvalidQty();

  const processFileNameAndReturnMatchingSkus =
    useProcessFileNameAndReturnMatchingSkus();

  const validateFile: ValidateFile = async (props) => {
    const { fileBlob, fileExtension, fileName, fileSize } = props;

    const isImage = defaultImageExtensions.some((ext) => {
      return ext === fileExtension;
    });

    if (isImage) {
      const base64ImgSrc = await getBase64FromBlob(fileBlob);

      const isInvalidDimensions = !!(await validateImageDimensions({
        fileName,
        minHeight: bulkUploadsRestrictions.minImageHeight,
        minWidth: bulkUploadsRestrictions.minImageWidth,
        src: base64ImgSrc,
      }));

      if (isInvalidDimensions) {
        return invalidFileReasons.isInvalidDimensions;
      }
    }

    const isInvalidExtension = !!validateExtensions({
      expectedExtensions: [...bulkUploadsRestrictions.expectedExtensions],
      fileExtension,
    });

    if (isInvalidExtension) {
      return invalidFileReasons.isInvalidExtension;
    }

    const isInvalidFileSize = !!validateFileSize({
      fileName,
      fileSize,
      maxFileSizeMB: isImage
        ? bulkUploadsRestrictions.maxFileSizeMB
        : maxUsdzFileSizeMB,
    });

    if (isInvalidFileSize) {
      return invalidFileReasons.isInvalidFileSize;
    }

    const { fileType, skus } = processFileNameAndReturnMatchingSkus({
      fileExtension,
      fileName,
    });

    if (!skus.length) {
      return invalidFileReasons.isInvalidFileName;
    }

    const { isInvalidQty } = getIsInvalidQty({
      fileType,
      skus,
    });

    if (isInvalidQty) {
      return invalidFileReasons.isInvalidQty;
    }

    return undefined;
  };

  return validateFile;
};
