import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  FetchPaginatedStocksArgs,
  FetchPaginatedStocksResponse,
} from './fetch-paginated-stocks.types';

export const fetchPaginatedStocks = (body: FetchPaginatedStocksArgs) => {
  return axios
    .post<FetchPaginatedStocksResponse>(
      `${coreApiUrl}/stocks/paginatedStocks`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
