import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { GetOverviewArgs, GetOverviewResponseDef } from './get-overview.types';

export const getMachineOverview = (args: GetOverviewArgs) => {
  const { machineId } = args;

  return axios
    .get<GetOverviewResponseDef>(`${coreApiUrl}/machines/${machineId}/overview`)
    .then(({ data }) => {
      return data;
    });
};
