import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useNotification } from '../../../../hooks/useNotification';
import {
  CreateTransactionArgs,
  CreateTransActionResponse,
} from './create-transaction.types';
import { createTransaction } from './create-transaction';

export const useCreateOrderMutation = (options?: {
  onSuccess?: (response: CreateTransActionResponse['order']) => void;
}) => {
  const { notify, notifyAxiosError } = useNotification();

  const { isLoading, onMutate } = useQueryMutation<
    CreateTransactionArgs,
    CreateTransActionResponse
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to create order.',
        });
      },
      onSuccess: async (response) => {
        notify(response.notice);
        options?.onSuccess?.(response.order);
      },
    },
    mutationFn: async (variables) => {
      return createTransaction(variables).then((response) => {
        return response.data;
      });
    },
  });

  return { isLoading, onCreateOrder: onMutate };
};
