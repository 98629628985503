import { useEffect } from 'react';
import { DefaultTheme } from 'styled-components';
import { Color, getThemeColor } from 'gantri-components';

export const useTemporalThemeSetup = (theme: DefaultTheme) => {
  useEffect(() => {
    const { dividers, palette, product, surfaces, typography } = theme.colors;

    const cssVariables: Record<string, Color> = {
      accept: surfaces.alert.t1,
      'accept-secondary': surfaces.blue.t1,
      alert: surfaces.alert.t1,
      'background-color': surfaces.monochrome.t1,
      black: 'monochrome_black',
      blossom: product.blossompink,
      blue: 'blue_400',
      canyon: product.canyon,
      carbon: product.carbon,
      charcoal: 'monochrome_600',
      'checked-background-color': surfaces.monochrome.overlayLevel2,
      'color-modal-input-placeholder': surfaces.monochrome.overlayLevel2,
      'constrast-border': surfaces.monochrome.t2,
      coral: product.coral,
      cream: 'monochrome_100',
      dark: 'monochrome_900',
      divider_t1: dividers.t1,
      fog: product.fog,
      forest: product.forest,
      'full-black': 'monochrome_black',
      gold: 'gold_400',
      green: 'green_400',
      grey: 'monochrome_600',
      'hover-color': 'monochrome_200',
      'inputs-foreground': typography.t1,
      'light-blue': 'blue_200',
      'light-gold': 'gold_200',
      'light-green': 'green_200',
      'light-grey': 'monochrome_200',
      'light-pink': surfaces.alert.t1,
      'light-red': 'red_200',
      meadow: product.meadow,
      midnight: product.midnight,
      'modal-background-color': surfaces.monochrome.overlayLevel1,
      'modal-checkbox-picker-background': surfaces.monochrome.overlayLevel1,
      'modal-close-icon-color': surfaces.monochrome.overlayLevel2,
      'modal-details-background-color': surfaces.monochrome.overlayLevel1,
      'modal-input-background': surfaces.monochrome.overlayLevel2,
      'modal-pickers-background': surfaces.monochrome.overlayLevel2,
      'notification-success': palette.blue_400,
      'pickers-background': surfaces.monochrome.t2,
      primary: 'green_400',
      red: 'red_400',
      'row-completed': surfaces.alert.t1,
      sage: product.sage,
      sand: product.sand,
      sedona: product.sedona,
      sky: product.sky,
      snow: product.snow,
      sprout: product.sproutgreen,
      stone: product.stone,
      sunrise: product.sunrise,
      'table-highlighted-inverted-alert': surfaces.alert.t2,
      'table-highlighted-normal': 'transparent',
      'translucent-black': palette.translucent_black_t1,
      'translucent-white': palette.translucent_white,
      warning: surfaces.warning.t1,
      white: 'monochrome_white',
      yellow: 'yellow_400',
    };

    Object.keys(cssVariables).forEach((key) => {
      document.body.style.setProperty(
        `--colors-${key}`,
        getThemeColor(cssVariables[key], theme),
      );
    });
  }, [theme]);
};
