import { FC, Fragment, PropsWithChildren } from 'react';
import { Conditional, Flex, Line } from 'gantri-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTheme } from 'styled-components';
import { StyledStyledSidebarLinkSpacer } from '../sidebar.styles';
import { menuItems } from './sidebar-links.constants';
import { SidebarLinksProps } from './sidebar-links.types';
import { MenuItem } from './components/menu-item';
import { uiAtoms } from '../../../global-atoms/ui';
import { SandingMinutesProgress } from '../../../components/sanding-minutes-progress';
import { sandingMinutesProgressVariants } from '../../../components/sanding-minutes-progress/sanding-minutes-progress.constants';
import { userAtoms } from '../../../global-atoms/users';
import { StyledSidebarLinksWrapper } from './sidebar-links.styles';

export const SidebarLinks: FC<PropsWithChildren<SidebarLinksProps>> = ({
  onLinkClicked,
  userType,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(
    uiAtoms.isSidebarOpen,
  );
  const expandedMenuItems = useRecoilValue(uiAtoms.expandedMenuItems);

  const { isSander } = useRecoilValue(userAtoms.user);

  const theme = useTheme();

  const lowerCaseUserType = userType.toLowerCase() as Lowercase<
    typeof userType
  >;
  const items =
    lowerCaseUserType === 'user' ? [] : menuItems[lowerCaseUserType];

  return (
    <StyledSidebarLinksWrapper>
      {items.map((route, i) => {
        if (route.type === 'spacer') {
          const key = `spacer-${i}`;

          return <StyledStyledSidebarLinkSpacer key={key} />;
        }

        const key = `${route.name}-${i}`;

        return (
          <Fragment key={key}>
            <MenuItem
              expandedMenuItems={expandedMenuItems}
              route={route}
              onLinkClicked={onLinkClicked}
            />
            {route.subPaths?.length && isSidebarOpen && (
              <Flex alignItems="stretch" direction="column">
                {expandedMenuItems[route.name] &&
                  route.subPaths.map((route, i) => {
                    const key = `${route.name}-${i}`;

                    return (
                      <MenuItem
                        key={key}
                        isSubPath
                        route={route}
                        onLinkClicked={onLinkClicked}
                      />
                    );
                  })}
              </Flex>
            )}
          </Fragment>
        );
      })}

      <Conditional key="is-sander" condition={isSander}>
        <Line verticalMargin="x" />

        <MenuItem
          route={{
            icon: 'work:sand_paper',
            name: 'Sanding Minutes',
            type: 'spacer',
          }}
          onLinkClicked={() => {
            setIsSidebarOpen(true);
          }}
        />

        <Conditional condition={isSidebarOpen}>
          <SandingMinutesProgress
            progressStrokeColor={theme.colors.surfaces.monochrome.t1Alt}
            variant={sandingMinutesProgressVariants.compact}
          />
        </Conditional>
      </Conditional>

      <StyledStyledSidebarLinkSpacer />
    </StyledSidebarLinksWrapper>
  );
};
