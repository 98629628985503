import { Cell, Conditional, Typography } from 'gantri-components';
import Thumbnail from '../../../../../../components/common/thumbnail/thumbnail';
import { useThumbnailZoomModal } from '../../../../../jobs/components/modals/common/job-checklist/components/advanced-checklist/components/advanced-checklist-reason/use-thumbnail-zoom-modal';
import { placeholderImgSrc } from './search-part-summary-thumbnail.constants';
import {
  StyledClickableThumbnail,
  StyledStack,
  StyledThumbnailCell,
} from './search-part-summary-thumbnail.styles';
import { SearchPartSummaryThumbnailProps } from './search-part-summary-thumbnail.types';

export const SearchPartSummaryThumbnail = (
  props: SearchPartSummaryThumbnailProps,
) => {
  const { part } = props;

  const [showZoomModal] = useThumbnailZoomModal({
    alt: part.name,
    heading: part.name,
    src: part.thumbnail,
  });

  return (
    <Cell>
      <StyledStack alignContent="center" justifyItems="center" padding="1.6rem">
        <Conditional
          condition={!!part.thumbnail}
          Fallback={
            <>
              <Thumbnail size="13.4rem" src={placeholderImgSrc} />
              <Typography color="t2" text="No diagram available currently." />
            </>
          }
        >
          <StyledThumbnailCell>
            <StyledClickableThumbnail
              size="unset"
              src={part.thumbnail}
              onClick={showZoomModal}
            />
          </StyledThumbnailCell>
        </Conditional>
      </StyledStack>
    </Cell>
  );
};
