import {
  Conditional,
  Flex,
  Icon,
  Stack,
  Tooltip,
  Typography,
  getPurifiedFileName,
} from 'gantri-components';
import { Fragment, useMemo } from 'react';
import { StyledAnchor } from '../../../../../../../../../../../../components/common/styled-anchor';
import { Divider } from '../../../../../../../../../../../../components/divider';
import { Label } from '../../../../../../../../../../../../components/label';
import { putInstructionsInOrder } from '../../helpers/put-instructions-in-order';
import { getIsDuplicate } from '../instruction-details/helpers/get-is-duplicate';
import { isDuplicateMessage } from '../instruction-details/instruction-details.constants';
import { AllInstructionsNonEditViewProps } from './all-instructions-non-edit-view.types';

export const AllInstructionsNonEditView = (
  props: AllInstructionsNonEditViewProps,
) => {
  const { boldLabels, instructions, instructionsJobMap } = props;

  const sortedInstructions = useMemo(() => {
    return putInstructionsInOrder({ instructions, instructionsJobMap });
  }, [instructions]);

  return (
    <Conditional
      condition={!!sortedInstructions.length}
      Fallback={<Typography text="-" />}
    >
      <Stack gap="0">
        {sortedInstructions.map((instruction, index) => {
          const { data, label } = instruction;
          const { files = [], text } = data || {};

          const isNotLastIndex = index < sortedInstructions.length - 1;

          return (
            <Fragment key={label}>
              <Stack gap=".2rem">
                <Conditional
                  condition={boldLabels}
                  Fallback={<Typography text={label} variant="p3" />}
                >
                  <Label text={label} />
                </Conditional>

                <Conditional
                  condition={!!(text || files.length)}
                  Fallback={<Typography text="-" />}
                >
                  <Conditional condition={!!text}>
                    <Typography htmlText={text?.replace(/\n/g, '<br />')} />
                  </Conditional>

                  <Stack gap=".2rem" justifySelf="start">
                    {files?.map((file) => {
                      const { fileName, url } = file;

                      const isDuplicate = getIsDuplicate({
                        allFiles: files,
                        file,
                      });

                      return (
                        <Flex key={`${url}-${label}`} gap="x">
                          <Conditional condition={isDuplicate}>
                            <Tooltip
                              maxWidth="22rem"
                              position="bottom-start"
                              title={isDuplicateMessage}
                            >
                              <Icon
                                color="warning"
                                name="alert:warning_triangle"
                                top="3px"
                              />
                            </Tooltip>
                          </Conditional>

                          <StyledAnchor
                            href={url}
                            icon={
                              fileName && (
                                <Icon
                                  color="link"
                                  name="arrows:arrow_external"
                                />
                              )
                            }
                            iconPosition="right"
                            target="_blank"
                            text={getPurifiedFileName(fileName)}
                          />
                        </Flex>
                      );
                    })}
                  </Stack>
                </Conditional>
              </Stack>
              <Conditional condition={isNotLastIndex}>
                <Divider paddingBottom="x" paddingTop="x" />
              </Conditional>
            </Fragment>
          );
        })}
      </Stack>
    </Conditional>
  );
};
