import { Conditional, Icon, Tooltip, Typography } from 'gantri-components';
import { Divider } from '../../../../../../../../../../../../components/divider';
import { ConditionalLineItemPropsDef } from './conditional-line-item.types';

export const ConditionalLineItem = (props: ConditionalLineItemPropsDef) => {
  const { condition, hideDivider, text, tooltipTitle } = props;

  return (
    <Conditional key={text} condition={condition}>
      <Conditional key="Hide divider" condition={!hideDivider}>
        <Divider />
      </Conditional>
      <Typography
        icon={
          <Conditional condition={!!tooltipTitle}>
            <Tooltip position="top" title={tooltipTitle}>
              <Icon name="alert:i_circle" />
            </Tooltip>
          </Conditional>
        }
        iconPosition="right"
        paddingLeft="x"
        paddingRight="x"
        text={text}
      />
    </Conditional>
  );
};
