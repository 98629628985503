import { Button, Table, useModal } from 'gantri-components';
import { InviteDesignerModal } from './components/invite-designer';
import { designersApi } from '../../api';
import { InvitesSortingField } from './designer-invites.types';
import { PageWithTable } from '../../components/layout';
import { FiltersFetchRequestArgs } from '../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import {
  FetchInvitesArgs,
  InviteData,
} from '../../api/designers/routes/fetch-invites/fetch-invites.types';
import { useTableFilters } from '../../components/common/table/hooks/use-table-filters';
import { useNotification } from '../../hooks/useNotification';
import {
  designerInvitesAtoms,
  invitesFiltersDefaults,
  pageName,
} from './designer-invites.atoms';
import { useGetDesignerInvitesColumns } from './hooks/use-get-designer-invites-columns';

export const DesignerInvites = () => {
  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const [showInviteDesignerModal, hideInviteDesignerModal] = useModal(() => {
    return (
      <InviteDesignerModal
        onClose={async () => {
          hideInviteDesignerModal();
          await refetchPaginatedInvites();
        }}
      />
    );
  });

  const refetchPaginatedInvites = () => {
    return handleFetchRequest({ page: currentPage });
  };

  const columns = useGetDesignerInvitesColumns({
    refetchPaginatedInvites,
  });

  const fetchPaginatedInvites = async (
    args: FiltersFetchRequestArgs<FetchInvitesArgs, InvitesSortingField>,
  ) => {
    try {
      showLoading();

      const { data } = await designersApi.fetchInvites(args);

      setRecords(data.invites);
      setTotalCount(data.resultsCount);
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to fetch invites.' });
    } finally {
      hideLoading();
    }
  };

  const {
    currentPage,
    handleFetchRequest,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<InviteData, FetchInvitesArgs, InvitesSortingField>({
    fetchRequest: fetchPaginatedInvites,
    pageName,
    searchFilterDetails: {
      atom: designerInvitesAtoms.search,
    },
    sortByFilterDetails: {
      atom: designerInvitesAtoms.sortBy,
      defaultValue: invitesFiltersDefaults.sortBy,
    },
    sortOptions: [
      { bidirectional: true, label: 'Name', sortingField: 'name' },
      {
        bidirectional: true,
        label: 'Invite Date',
        sortingField: 'invitedDate',
      },
    ],
  });

  return (
    <PageWithTable pageTitle="Invites">
      <PageWithTable.Header title="Invites">
        <Button text="New Invite" onClick={showInviteDesignerModal} />
      </PageWithTable.Header>
      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          paging={pagingProps}
          search={searchProps}
          sorting={sortProps}
          stickyLastColumn
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
