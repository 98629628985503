import * as yup from 'yup';
import { required } from '../../../helpers/validators';

export const PayoutForSchema = yup.object().shape({
  method: yup
    .string()
    .when('status', {
      is: (value) => {
        return value === 'Paid';
      },
      then: yup
        .string()
        .required('Change to Paid requires a method.')
        .nullable(),
    })
    .nullable(),
  status: required(),
});
