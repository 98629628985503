import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  CreatePrintTransferQueueArgs,
  CreatePrintTransferQueueResponse,
} from './create-print-transfer-queue.types';

export const createPrintTransferQueue = (
  body: CreatePrintTransferQueueArgs,
) => {
  return axios
    .post<CreatePrintTransferQueueResponse>(
      `${getCoreApiUrl('queue/print-transfer')}/create`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
