import { useRecoilValue } from 'recoil';
import { cancelOrUpdateStockVersionModalStepAtom } from '../../cancel-or-update-stock-version-modal.atoms';
import { ConfirmCancelContent } from '../step-confirm-cancel/confirm-cancel-content';
import { ConfirmUpdateContent } from '../step-confirm-update/confirm-update-content';
import { RemovedFromVlmContent } from '../step-removed-from-vlm/removed-from-vlm-content';
import { RequestVlmContent } from '../step-request-vlm/request-vlm-content';
import { UpdateErrorContent } from '../step-update-error/update-error-content';
import { UpdateSuccessContent } from '../step-update-success/update-success-content';
import { UpdateStockVersionContentProps } from './update-stock-version-content.types';

export const UpdateStockVersionContent = (
  props: UpdateStockVersionContentProps,
) => {
  const { stock } = props;

  const step = useRecoilValue(cancelOrUpdateStockVersionModalStepAtom);

  switch (step) {
    case 'CONFIRM_UPDATE':
      return <ConfirmUpdateContent orderId={stock.orderId} />;

    case 'CONFIRM_CANCEL':
      return <ConfirmCancelContent />;

    case 'UPDATE_ERROR':
      return <UpdateErrorContent stockId={stock.id} />;

    case 'UPDATE_SUCCESS':
      return <UpdateSuccessContent stock={stock} />;

    case 'REQUEST_VLM':
      return <RequestVlmContent stockId={stock.id} />;

    case 'REMOVED_FROM_VLM':
      return <RemovedFromVlmContent stockId={stock.id} />;

    default:
      return null;
  }
};
