import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { ResponsiveBar } from '@nivo/bar';
import { range } from 'lodash';
import { StyledChartWrapper } from './part-release-graph.styles';
import { PartReleaseGraphPropsDef } from './part-release-graph.types';
import { PartReleaseRateTableTooltip } from './components/part-release-graph-tooltip';

export const PartReleaseGraph = (props: PartReleaseGraphPropsDef) => {
  const { tableData } = props;

  const theme = useTheme();

  const increment = 100;

  const maxValue = useMemo(() => {
    const tableDataValues = tableData.map(({ value }) => {
      return value;
    });
    const largestValue = Math.max(...tableDataValues);

    return Math.ceil(largestValue / increment) * increment;
  }, [tableData]);

  const gridRanges = useMemo(() => {
    return range(0, maxValue + increment, increment);
  }, [maxValue]);

  const printOrFinishLabelPattern = /^(print)|(finish)/i;

  return (
    <StyledChartWrapper $minHeight="40rem">
      <ResponsiveBar
        axisBottom={{
          tickPadding: 9,
          tickSize: 0,
          tickValues: gridRanges,
        }}
        axisLeft={{
          tickPadding: 15,
          tickSize: 0,
        }}
        colorBy="id"
        data={tableData}
        defs={[
          {
            colors: [
              { color: theme.colors.surfaces.blue.t3, offset: 0 },
              { color: theme.colors.surfaces.blue.t3, offset: 100 },
            ],
            // Due to an issue with Safari, this essentially only exists to give us a target-able fill attr. The actual fill color is assigned in this component's styles file.
            id: 'part-level-color',
            type: 'linearGradient',
          },
          {
            colors: [
              {
                color: theme.colors.palette.monochrome_700,
                offset: 0,
              },
              {
                color: theme.colors.palette.monochrome_700,
                offset: 100,
              },
            ],
            // Due to an issue with Safari, this essentially only exists to give us a target-able fill attr. The actual fill color is assigned in this component's styles file.
            id: 'product-level-color',
            type: 'linearGradient',
          },
        ]}
        enableGridX
        enableGridY={false}
        enableLabel={false}
        fill={[
          {
            id: 'part-level-color',
            match: (item) => {
              return printOrFinishLabelPattern.test(String(item.data.data.id));
            },
          },
          {
            id: 'product-level-color',
            match: (item) => {
              return !printOrFinishLabelPattern.test(String(item.data.data.id));
            },
          },
        ]}
        gridXValues={gridRanges}
        groupMode="stacked"
        indexScale={{ round: false, type: 'band' }}
        layout="horizontal"
        margin={{ bottom: 35, left: 130, right: 20, top: 0 }}
        markers={[{ axis: 'x', value: 0 }]}
        maxValue={maxValue}
        padding={0.35}
        theme={{
          fontSize: 12,
          grid: {
            line: {
              stroke: theme.colors.dividers.t2,
              strokeDasharray: '4, 3',
              strokeWidth: 1,
            },
          },
          markers: {
            lineColor: theme.colors.dividers.t2,
          },
          textColor: theme.colors.typography.t1,
        }}
        tooltip={PartReleaseRateTableTooltip}
      />
    </StyledChartWrapper>
  );
};
