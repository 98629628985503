import { Typography } from 'gantri-components';
import { useTheme } from 'styled-components';
import styles from './styles.module.scss';
import { timelinePresets } from './timeline.presets';
import { TimelineProps } from './timeline.types';
import { Paging } from '../paging';
import { StyledPaginationSpacer } from './timeline.styles';

export const cssClasses = {
  container: styles.container,
  content: styles.content,
  item: styles.item,
  paginatedItem: styles.paginatedItem,
};

export const Timeline = <TimelineData extends Record<any, any>>(
  props: TimelineProps<TimelineData>,
) => {
  const {
    getVersion,
    isReversed,
    isTitleVisible,
    items,
    onPageChange,
    pageCount,
    paginated,
    renderItem,
    timelineCurrentPage,
    title,
    total,
  } = props;
  const renderedItems = items.map((item, index) => {
    const theme = useTheme();
    const borderLeft =
      items.length - 1 === index
        ? {}
        : {
            borderLeft: `1px solid ${theme.colors.palette.green_500}`,
            paddingBottom: '30px',
          };
    const object = getVersion ? { ...item, getVersion } : item;

    return (
      <div key={index} className={cssClasses.item} style={borderLeft}>
        <div className={cssClasses.content}>
          {renderItem({ ...object, index })}
        </div>
      </div>
    );
  });

  return (
    <div className={cssClasses.container}>
      {isTitleVisible &&
        (typeof title === 'string' ? (
          <Typography
            marginBottom="30px"
            text={title}
            textStyle="bold"
            variant="h5"
          />
        ) : (
          title
        ))}

      {isReversed ? renderedItems.reverse() : renderedItems}
      {paginated && (
        <>
          <StyledPaginationSpacer />
          <Paging
            currentPage={timelineCurrentPage}
            pageCount={pageCount}
            total={total}
            onPageChange={onPageChange}
          />
        </>
      )}
    </div>
  );
};

Timeline.defaultProps = timelinePresets;
