import React, { FC, useEffect, useMemo, useRef } from 'react';
import {
  Cell,
  Conditional,
  Dropdown,
  Flex,
  getFileUrl,
  Grid,
  Line,
  Typography,
} from 'gantri-components';
import { useToggle } from 'react-use';
import {
  ProductReviewStatus,
  ProductReviewStatuses,
  UserProductReview,
} from '../../../../../api/users/routes';
import { StyledAnchor } from '../../../../../components/common/styled-anchor';
import routePaths from '../../../../../config/route-paths';
import { StyledStatusDot } from './review.styles';
import { getColorByStatus } from './review-adapter';
import Thumbnail from '../../../../../components/common/thumbnail/thumbnail';
import { formatDate } from '../../../../../helpers/formatter';
import { LONG_FORMAT } from '../../../../../constants/dates';
import { PhotosGallery } from '../../../../../components/common/photos-gallery';
import { ShortProductSummary } from '../../../../../components/common/short-product-summary';

const statuses = [
  {
    description: 'Approve the review',
    label: ProductReviewStatuses.PUBLISHED,
    value: ProductReviewStatuses.PUBLISHED,
  },
  {
    description: 'Reject the review',
    label: ProductReviewStatuses.REJECTED,
    value: ProductReviewStatuses.REJECTED,
  },
  {
    description: 'Action required by Gantri',
    label: ProductReviewStatuses.PENDING,
    value: ProductReviewStatuses.PENDING,
  },
  {
    description: 'Not finished or expired',
    label: ProductReviewStatuses.INCOMPLETE,
    value: ProductReviewStatuses.INCOMPLETE,
  },
];

export const ReviewDetails: FC<{
  onStatusSelected?: (status: ProductReviewStatus) => void;
  onUpdateReviewStatus?: (status: ProductReviewStatus) => void;
  review: UserProductReview;
}> = (props) => {
  const { onStatusSelected, onUpdateReviewStatus, review } = props;
  const [viewInEditMode, setViewInEditMode] = useToggle(false);
  const [showGallery, setShowGallery] = useToggle(false);
  const imageIndexSelected = useRef(0);
  const statusSelected = useRef(review.status);

  const images = useMemo(() => {
    return review.photos?.map((photo) => {
      return photo.includes('cdn-yotpo')
        ? photo
        : getFileUrl<'reviews'>({
            directory: 'reviews',
            fileName: photo,
            fileType: 'reviews',
            identifiers: {
              reviewId: review.id,
            },
          });
    });
  }, [review]);

  useEffect(() => {
    setViewInEditMode(false);
  }, [review.status]);

  return (
    <>
      <Grid columns="14rem 1fr" gap="3x">
        <Typography text="Review Status" textStyle="bold" />
        <Flex alignItems="center" gap="x">
          <Conditional
            condition={!viewInEditMode}
            Fallback={
              <>
                <Dropdown
                  dropdownPopupWidth="20rem"
                  idProperty="value"
                  items={statuses}
                  labelProperty="label"
                  renderItem={(item) => {
                    return (
                      <Flex
                        direction="column"
                        gap="0"
                        // @ts-ignore
                        padding=".4rem .4rem .4rem 1.6rem"
                      >
                        <Typography
                          icon={
                            <StyledStatusDot
                              color={getColorByStatus(item.value)}
                            />
                          }
                          iconGap=".6rem"
                          text={item.label}
                        />
                        <Typography
                          marginLeft="2.3rem"
                          text={item.description}
                          variant="p3"
                        />
                      </Flex>
                    );
                  }}
                  renderPlaceholder={(item) => {
                    return item ? (
                      <Typography
                        icon={
                          <StyledStatusDot
                            color={getColorByStatus(item.value)}
                          />
                        }
                        iconGap=".6rem"
                        paddingLeft="1rem"
                        text={item.label}
                        whiteSpace="nowrap"
                      />
                    ) : null;
                  }}
                  value={review.status}
                  onSelect={(item) => {
                    statusSelected.current = item.value;
                    onStatusSelected(item.value);
                  }}
                />

                <Typography
                  color="link"
                  decoration="underline"
                  text="Update"
                  onClick={() => {
                    onUpdateReviewStatus?.(statusSelected.current);
                  }}
                />
              </>
            }
          >
            <Typography
              icon={<StyledStatusDot color={getColorByStatus(review.status)} />}
              iconGap=".6rem"
              text={review.status}
            />
            <Typography
              color="link"
              decoration="underline"
              text="Edit"
              onClick={() => {
                return setViewInEditMode(true);
              }}
            />
          </Conditional>
        </Flex>

        <Typography text="User" textStyle="bold" />
        <StyledAnchor
          href={`${routePaths.users}/${review.user.id}`}
          text={review.user.name}
        />

        <Typography text="Order #" textStyle="bold" />
        <StyledAnchor
          href={`${routePaths.orders}/${review.orderId}`}
          text={`#${review.orderId}`}
        />

        <Typography text="Stock #" textStyle="bold" />
        <StyledAnchor
          href={`${routePaths.stocks}/${review.stockId}`}
          text={`#${review.stockId}`}
        />

        <Typography text="Review ID" textStyle="bold" />
        <Typography text={`#${review.id}`} />

        <Typography text="Version" textStyle="bold" />
        <Typography text={review.version} />

        <Typography text="Last Date" textStyle="bold" />
        <Typography text={formatDate(review.lastDate, LONG_FORMAT)} />

        <Cell width={2}>
          <Line />
        </Cell>

        <Typography text="Product" textStyle="bold" />
        <ShortProductSummary {...review.product} />

        <Typography text="Product Category" textStyle="bold" />
        <Typography text={review.product.category} />

        <Typography text="Product Rating (1-5)" textStyle="bold" />
        <Typography text={review.rating} />

        <Cell width={2}>
          <Line />
        </Cell>

        <Typography text="Headline" textStyle="bold" />
        <Cell marginTop="-2rem" width={2}>
          <Typography color="t2" text={review.headline} variant="p2" />
        </Cell>

        <Typography text="Review" textStyle="bold" />
        <Cell marginTop="-2rem" width={2}>
          <Typography color="t2" text={review.review} variant="p2" />
        </Cell>

        <Typography text="Uploaded Photos" textStyle="bold" />
        <Cell marginTop="-2rem" width={2}>
          <Flex gap=".4rem" wrap="wrap">
            {images.map((photo, index) => {
              return (
                <Thumbnail
                  key={photo}
                  size="10rem"
                  src={photo}
                  onClick={() => {
                    imageIndexSelected.current = index;
                    setShowGallery(true);
                  }}
                />
              );
            })}
          </Flex>
        </Cell>
      </Grid>

      <PhotosGallery
        photos={images}
        selectedIndex={imageIndexSelected.current}
        show={showGallery}
        onClose={setShowGallery}
      />
    </>
  );
};
