import * as yup from 'yup';
import {
  StockJobInventory,
  StockJobVariantInventory,
} from '../../../../../../api/products/products.types';
import { inventoryStatuses } from '../../../../../../constants/options';

export const getRequiredText = (label: string) => {
  return `${label} is required` as const;
};

export const stockJobInventorySchema: yup.SchemaOf<StockJobInventory[]> = yup
  .array()
  .of(
    yup.object().shape({
      amount: yup.number().required(),
      inventoryId: yup.number().required(),
      name: yup.string().required(),
      status: yup.mixed().oneOf(Object.values(inventoryStatuses)).required(),
      unit: yup.string().required(),
    }),
  )
  .required(getRequiredText('Inventory'));

export const stockJobVariantInventorySchema: yup.SchemaOf<
  StockJobVariantInventory[]
> = yup
  .array()
  .of(
    yup.object().shape({
      amount: yup.number(),
      globalVariantId: yup.number(),
      inventoryId: yup.number(),
      name: yup.string().required(),
      status: yup.mixed().oneOf(Object.values(inventoryStatuses)).required(),
      unit: yup.string().required(),
      variantCode: yup.string().required(),
    }),
  )
  .required(getRequiredText('Inventory'));
