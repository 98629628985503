import { Table } from 'gantri-components';
import { useState } from 'react';
import { PageWithTable } from '../../components/layout';
import {
  FiltersFetchRequestArgs,
  useTableFilters,
} from '../../components/common/table/hooks';
import { DesignsSortingField } from '../../components/dropdowns/designs-filter/designs-filter.types';
import {
  designsPageAtoms,
  designsFiltersDefaults,
  pageName,
} from '../../components/dropdowns/designs-filter/designs-filter.atoms';
import {
  DesignRecord,
  FetchPaginatedDesignsArgs,
} from '../../api/designs/routes/fetch-paginated-designs/fetch-paginated-designs.types';
import { sortOptions, columns, getRowProps } from './designs.constant';
import { DesignsFilter } from '../../components/dropdowns/designs-filter';
import { useFetchPaginatedDesigns } from '../../api/designs/routes';

export const Designs = () => {
  const [filters, setFilters] =
    useState<
      FiltersFetchRequestArgs<FetchPaginatedDesignsArgs, DesignsSortingField>
    >();

  useFetchPaginatedDesigns({
    enabled: !!filters,
    fetchArgs: filters,
    onSuccess: async ({ allDesigns, designs }) => {
      setRecords(designs);
      setTotalCount(allDesigns);
    },
    showLoading: true,
  });

  const {
    filtersProps,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    DesignRecord,
    FetchPaginatedDesignsArgs,
    DesignsSortingField
  >({
    fetchRequest: setFilters,
    filtersContent: <DesignsFilter />,
    modalFiltersDetails: [
      {
        atom: designsPageAtoms.filters.statuses,
        defaultValue: designsFiltersDefaults.filters.statuses,
        payloadKey: 'statuses',
      },
      {
        atom: designsPageAtoms.filters.steps,
        defaultValue: designsFiltersDefaults.filters.steps,
        payloadKey: 'steps',
      },
      {
        atom: designsPageAtoms.filters.categories,
        defaultValue: designsFiltersDefaults.filters.categories,
        payloadKey: 'categories',
      },
      {
        atom: designsPageAtoms.filters.action,
        defaultValue: designsFiltersDefaults.filters.action,
        payloadKey: 'action',
      },
    ],
    pageName,
    searchFilterDetails: {
      atom: designsPageAtoms.search,
    },
    sortByFilterDetails: {
      atom: designsPageAtoms.sortBy,
      defaultValue: designsFiltersDefaults.sortBy,
    },
    sortOptions,
  });

  return (
    <PageWithTable pageTitle="Designs">
      <PageWithTable.Header title="Designs" />

      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records || []}
          filters={filtersProps}
          getRowProps={getRowProps}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          sorting={sortProps}
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
