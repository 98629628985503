import { FC, PropsWithChildren } from 'react';
import { Typography } from 'gantri-components';
import Grid from '../../../../../components/common/grid';
import Cell from '../../../../../components/common/cell';
import { AccountDropdown } from '../../../account-dropdown';
import { HeaderDetailsProps } from './header-details.types';
import { getEnvLabel } from '../../../helpers/get-env-label';

export const HeaderDetails: FC<PropsWithChildren<HeaderDetailsProps>> = ({
  type,
  version,
}) => {
  return (
    <Grid columns={1} gap="unset">
      <Cell>
        <Grid
          alignItems="baseline"
          columns="repeat(3, max-content)"
          gap=".4rem"
        >
          <Typography
            style={{ fontSize: '1.5rem', lineHeight: '2.2rem' }}
            text={type}
            textStyle="bold"
            variant="h4"
          />
          <Typography
            style={{ fontSize: '1.2rem' }}
            text={getEnvLabel()}
            variant="p2"
          />
          {!!version && (
            <Typography
              color="t2"
              style={{ fontSize: '1.2rem' }}
              text={version}
              variant="p2"
            />
          )}
        </Grid>
      </Cell>
      <Cell>
        <AccountDropdown showName />
      </Cell>
    </Grid>
  );
};
