import { FC } from 'react';
import {
  Button,
  FormikInput,
  Modal,
  Stack,
  TextArea,
  Typography,
} from 'gantri-components';
import { Form, Formik } from 'formik';
import { AddCreditsModalProps } from './credits.types';
import { formatAsCurrency } from '../../../helpers/formatter';
import { AddCreditSchema } from './add-credit.schema';
import { usersApi } from '../../../api';
import { useNotification } from '../../../hooks/useNotification';

export const AddCreditsModal: FC<AddCreditsModalProps> = ({
  currentUser,
  onClose,
  updateUser,
}) => {
  const { email, firstName, lastName, userId } = currentUser;

  const { notify, notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const onConfirm = async (values) => {
    await onInterceptProcessingRequest(async () => {
      const { credit, reason } = values;

      try {
        const { data } = await usersApi.addCredit({
          amount: Number(credit.match(/\d+.*/g)?.join('')),

          reason,
          userId,
        });

        notify(data.notice);

        updateUser({ ...currentUser, credits: data.credits });
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to add credit to user.',
        });
      } finally {
        onClose();
      }
    });
  };

  return (
    <Formik
      initialValues={{ credit: '', reason: '' }}
      validationSchema={AddCreditSchema}
      onSubmit={onConfirm}
    >
      {({ isValid, setFieldValue }: any) => {
        return (
          <Form>
            <Modal
              footer={
                <>
                  <Button
                    size="large"
                    text="Cancel"
                    variant="secondary"
                    onClick={onClose}
                  />
                  <Button
                    disabled={!isValid}
                    processing={processing}
                    size="large"
                    text="Add"
                    type="submit"
                  />
                </>
              }
              header="Add Credits"
              width={{ lg: '40rem', md: '100%' }}
              onClose={onClose}
            >
              <Stack alignContent="center">
                <Stack gap="0">
                  <Typography text={`${firstName} ${lastName}`} />
                  <Typography text={email} />
                </Stack>

                <FormikInput
                  ariaLabel="credit"
                  labelText="Credit"
                  name="credit"
                  onBlurTextChange={(value) => {
                    const number = Number(value.match(/\d+.*/g)?.join('') ?? 0);

                    if (value) {
                      setFieldValue(
                        'credit',
                        formatAsCurrency(Number.isNaN(number) ? 0 : number),
                      );
                    }
                  }}
                />
                <FormikInput
                  Field={
                    <TextArea ariaLabel="reason" placeholder="Enter reason" />
                  }
                  name="reason"
                />
              </Stack>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
