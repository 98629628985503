export const spoolInventoryNeedValues = {
  opaque: 4.5,
  translucent: 5,
} as const;

export const spoolInventoryDetails = {
  opaqueOldFormulation: {
    description: 'Old Formulation',
    id: 10913,
    need: spoolInventoryNeedValues.opaque,
  },
  opaqueSandable: {
    description: 'Sandable',
    id: 10912,
    need: spoolInventoryNeedValues.opaque,
  },
  translucent: {
    description: '',
    id: 10178,
    need: spoolInventoryNeedValues.translucent,
  },
} as const satisfies Record<
  string,
  {
    description: string;
    id: number;
    need: number;
  }
>;
