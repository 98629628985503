import sortBy from 'lodash/sortBy';
import {
  formatAsCurrency,
  formatAsLocaleNumber,
} from '../../../../../helpers/formatter';
import { InventoryForStock } from '../../../../../api/inventories/routes/fetch-all-for-stock';
import { StockInventory } from '../../../../../api/stocks/stocks.types';
import { InventoryRowData } from './stock-inventories-table.types';

export const transformFetchAllInventories = (
  inventories: StockInventory[],
  allInventories: InventoryForStock[],
): { rows: InventoryRowData[]; totalCost: string } => {
  let totalCost = 0;
  const mapper: Record<null, InventoryForStock> = allInventories?.reduce(
    (acc, inventory) => {
      return {
        ...acc,
        [inventory.id]: inventory,
      };
    },
    {},
  );

  const temporal = sortBy(
    inventories
      .filter((inventory: StockInventory) => {
        return !!mapper[inventory.id];
      })
      .map((inventory: StockInventory) => {
        const { id, need } = inventory;
        const inventoryRowData = mapper[id];
        const needFloat = need ?? '';

        const stockFloat = inventoryRowData.stock
          ? parseFloat(inventoryRowData.stock.toString())
          : '';

        const needFixed = needFloat
          ? formatAsLocaleNumber(needFloat, { maximumFractionDigits: 3 })
          : '0';

        const stockFixed = stockFloat
          ? formatAsLocaleNumber(stockFloat, { maximumFractionDigits: 3 })
          : '0';

        const smallUnit = inventoryRowData?.unit?.toLowerCase() || '';
        const isRed =
          +needFixed === 0 || +stockFixed === 0 || needFloat > stockFloat;

        const itemCost = +inventoryRowData.unitCost * need;

        const temporal = {
          ...(inventoryRowData || {}),
          isRed,
          needFixed: `${needFixed} ${smallUnit}`,
          stockFixed: `${stockFixed} ${smallUnit}`,
          totalCost: formatAsCurrency(itemCost, { isCents: true }),
        };

        totalCost += itemCost;

        return temporal;
      }),
    'name',
  );

  return {
    rows: temporal,
    totalCost: formatAsCurrency(totalCost, { isCents: true }),
  };
};
