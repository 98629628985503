import { Cell, Conditional, Stack, Typography } from 'gantri-components';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import {
  productJobsTabAtoms,
  stockJobsSections,
} from '../../../../product-jobs.atoms';
import { InventoryDataCell } from '../../../../../../../../components/common/custom-data-cells/inventory-data-cell';
import { productAtoms } from '../../../../../../product.atoms';
import { StockJobsHeader } from '../stock-jobs-header';
import { useInvalidateFetchProductCache } from '../../../../../../../../api/products/routes';
import {
  getStockJobsPackInventoryInitialValues,
  StockJobsPackInventoryFormData,
  stockJobsPackInventoryFormikSchema,
} from './stock-jobs-pack-inventory.schema';
import { convertStockJobInventoriesToInventoryRequests } from '../stock-jobs-assemble-inventory/stock-jobs-assemble-inventory.schema';
import { StockJobInventory } from '../../../../../../../../api/products/products.types';
import { useUpdateProductJobBlocks } from '../../../../../../../../api/products/routes/update-product-job-blocks';
import { jobBlockTypes } from '../../../../../../../../api/products/routes/update-product-job-blocks/update-product-job-blocks.types';

export const StockJobsPackInventory = () => {
  const product = useRecoilValue(productAtoms.product);
  const isEditingStockJobsSection = useRecoilValue(
    productJobsTabAtoms.isEditingStockJobsSection,
  );
  const resetIsEditingStockJobsSection = useResetRecoilState(
    productJobsTabAtoms.isEditingStockJobsSection,
  );

  const stockBlock = product?.stockBlock;

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();

  const { onUpdateProductJobBlocks } = useUpdateProductJobBlocks({
    onSuccess: async () => {
      await invalidateFetchProductCache();
      resetIsEditingStockJobsSection();
    },
  });

  const onSubmit = async (values: StockJobsPackInventoryFormData) => {
    await onUpdateProductJobBlocks({
      id: stockBlock.id,
      jobBlock: {
        stockBlock: { ...stockBlock, ...values },
        type: jobBlockTypes.stock,
      },
      variantIds: product.variants.map(({ id }) => {
        return id;
      }),
    });
  };

  const initialValues = useMemo(() => {
    return getStockJobsPackInventoryInitialValues({
      stockJobsBlock: stockBlock,
    });
  }, [stockBlock]);

  const sectionId = stockJobsSections.packInventory;
  const isEditing = isEditingStockJobsSection === sectionId;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange
      validateOnMount
      validationSchema={stockJobsPackInventoryFormikSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, values }) => {
        const { packInventory } = values;

        return (
          <Form>
            <Stack gap="x">
              <StockJobsHeader sectionId={sectionId} text="Pack Inventory" />
              <Cell>
                <Conditional
                  condition={isEditing || !!packInventory.length}
                  Fallback={<Typography text="-" />}
                >
                  <InventoryDataCell
                    editing={isEditing}
                    hideExceedsStockIcon
                    inventoryRequests={convertStockJobInventoriesToInventoryRequests(
                      packInventory,
                    )}
                    onEdit={async (updatedInventoryRequests) => {
                      const packInventory: StockJobInventory[] =
                        updatedInventoryRequests.map(({ inventory, need }) => {
                          const { id, ...rest } = inventory;
                          const stockJobInventory: StockJobInventory = {
                            amount: Number(need || 0),
                            inventoryId: id as number,
                            ...rest,
                          };

                          return stockJobInventory;
                        });

                      await setFieldValue('packInventory', packInventory);
                    }}
                  />
                </Conditional>
              </Cell>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};
