import { useRecoilValue } from 'recoil';
import { PressRecordModalFooterProps } from './press-record-modal-footer.types';
import { pressRecordModalActions } from '../../press-record-modal.constants';
import { pressRecordModalAtoms } from '../../press-record-modal.atoms';
import { ActionDeleteFooter } from '../action-delete/action-delete-footer';
import { ActionFormFooter } from '../action-form/action-form-footer';

export const PressRecordModalFooter = (props: PressRecordModalFooterProps) => {
  const { handleCloseModal, processingForm } = props;

  const modalAction = useRecoilValue(pressRecordModalAtoms.modalAction);

  switch (modalAction) {
    case pressRecordModalActions.delete:
      return <ActionDeleteFooter handleCloseModal={handleCloseModal} />;

    default:
      return (
        <ActionFormFooter
          handleCloseModal={handleCloseModal}
          processing={processingForm}
        />
      );
  }
};
