import { atom } from 'recoil';
import {
  HandleReturnAndShipManually,
  ShipmentActionsModalStep,
} from './shipment-actions-modal.types';

const atomKeyPrefix = 'SHIP_ACTIONS_MODAL';

export const shipmentActionsModalAtoms = {
  shipManually: {
    handleReturnAndShipManually: atom<HandleReturnAndShipManually>({
      default: null,
      key: `${atomKeyPrefix}-on-return-ship-manually`,
    }),
    shippingCost: atom<number>({
      default: null,
      key: `${atomKeyPrefix}-shipping-cost`,
    }),
    trackingNumber: atom<string>({
      default: '',
      key: `${atomKeyPrefix}-tracking-number`,
    }),
  },
  shipNormally: {
    isDoNotShip: atom<boolean>({
      default: false,
      key: `${atomKeyPrefix}-is-do-no-ship`,
    }),
    isGift: atom<boolean>({
      default: false,
      key: `${atomKeyPrefix}-is-gift`,
    }),
    isGiftMessageIncluded: atom<boolean>({
      default: false,
      key: `${atomKeyPrefix}-is-gift-message-included`,
    }),
    isInPlainPackaging: atom<boolean>({
      default: false,
      key: `${atomKeyPrefix}-is-in-plain-packaging`,
    }),
  },
  shipmentId: atom<number>({
    default: null,
    key: `${atomKeyPrefix}-shipment-id`,
  }),
  step: atom<ShipmentActionsModalStep>({
    default: null,
    key: `${atomKeyPrefix}-step`,
  }),
};
