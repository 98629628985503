import { useRecoilValue } from 'recoil';
import { UserRole, userRoles } from '../../constants/users';
import { userAtoms } from '../../global-atoms/users';
import { getRolesForAccessLevelAndAbove } from '../../helpers/get-roles-for-access-level-and-above';

export const useUserHasAccessLevel = () => {
  const { id: loggedInUserId, type: loggedInUserRole } = useRecoilValue(
    userAtoms.user,
  );

  const getUserHasAccessTo = (
    userRolesOrIds: (UserRole | number)[],
    options?: {
      /** If `userRolesOrIds` array is empty, return `true`. */
      trueIfEmpty?: boolean;
    },
  ) => {
    const { trueIfEmpty } = options || {};

    return !userRolesOrIds?.length && trueIfEmpty
      ? true
      : !!userRolesOrIds?.some((userRoleOrId) => {
          const isUserId = typeof userRoleOrId === 'number';

          if (isUserId) {
            return userRoleOrId === loggedInUserId;
          }

          return userRoleOrId === loggedInUserRole;
        });
  };

  const hasAdminAccess = getUserHasAccessLevel({
    accessLevel: userRoles.admin,
    userRole: loggedInUserRole,
  });
  const hasLeadAccess = getUserHasAccessLevel({
    accessLevel: userRoles.lead,
    userRole: loggedInUserRole,
  });
  const hasWorkerAccess = getUserHasAccessLevel({
    accessLevel: userRoles.worker,
    userRole: loggedInUserRole,
  });

  return {
    getUserHasAccessTo,
    hasAdminAccess,
    hasLeadAccess,
    hasWorkerAccess,
    role: loggedInUserRole,
  };
};

export const getUserHasAccessLevel = (props: {
  accessLevel: UserRole;
  userRole: UserRole;
}) => {
  const { accessLevel, userRole } = props;

  return getRolesForAccessLevelAndAbove(accessLevel).some((role) => {
    return role === userRole;
  });
};
