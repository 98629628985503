import { useFormikContext } from 'formik';
import {
  Dropdown,
  FormikInput,
  Stack,
  TextArea,
  Typography,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useEffect, useMemo } from 'react';
import { AddJobFormValues, AddJobType } from '../../../add-job-modal.types';
import { nonProductJobTypesForJobStep } from '../../../../../../../../constants/jobs';
import { addJobModalAtoms } from '../../../add-job-modal.atoms';
import { AssignedToField } from '../../../../../../../../components/common/assigned-to-field';

export const AddJobFormContent = () => {
  const { setFieldValue, values } = useFormikContext<AddJobFormValues>();
  const { step, type } = values;

  const workers = useRecoilValue(addJobModalAtoms.workers);

  const stepOptions = useMemo(() => {
    return Object.keys(nonProductJobTypesForJobStep).map((step) => {
      return {
        label: step,
        value: step,
      };
    });
  }, []);
  const availableTypesForStep = useMemo(() => {
    return nonProductJobTypesForJobStep[step] || [];
  }, [step]);
  const typeOptions = useMemo(() => {
    return availableTypesForStep.map((type: AddJobType) => {
      return {
        label: type,
        value: type,
      };
    });
  }, [availableTypesForStep]);

  useEffect(() => {
    const assignedTypeIsAvailable = availableTypesForStep.some(
      (availableType) => {
        return availableType === type;
      },
    );

    if (type && !assignedTypeIsAvailable) {
      setFieldValue('type', undefined);
    }
  }, [availableTypesForStep]);

  return (
    <Stack gap="2x">
      <Typography text="Select step and type" variant="h5" />
      <FormikInput
        autoFocus
        Field={
          <Dropdown
            items={stepOptions}
            placeholder="Select step"
            onSelect={(item: typeof stepOptions[number]) => {
              setFieldValue('step', item?.value);
            }}
          />
        }
        labelText="Step"
        name="step"
        required
      />
      <FormikInput
        Field={
          <Dropdown
            disabled={!step}
            items={typeOptions}
            placeholder="Select type"
            onSelect={(item: typeof typeOptions[number]) => {
              setFieldValue('type', item?.value);
            }}
          />
        }
        fieldVariant="standard"
        labelText="Type"
        name="type"
        required
      />
      <FormikInput
        Field={
          <AssignedToField
            labelText="Assigned to"
            workers={workers}
            onSelect={(item) => {
              setFieldValue('assignedTo', item?.id);
            }}
          />
        }
        fieldVariant="standard"
        name="assignedTo"
      />
      <FormikInput
        debounce={300}
        labelText="Duration (min)"
        name="duration"
        placeholder="Enter duration"
        type="number"
      />
      <FormikInput
        debounce={300}
        Field={<TextArea minRows={4} placeholder="Enter instructions" />}
        labelText="Instruction"
        name="instruction"
      />
    </Stack>
  );
};
