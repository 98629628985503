import { RowReorderingProps } from 'gantri-components';
import { Dispatch, SetStateAction } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Batch, BatchPart } from '../../../../api/batch/batch.types';
import { batchApi } from '../../../../api';
import { useNotification } from '../../../../hooks/useNotification';
import { batchAtoms } from '../../batch.atoms';

interface UseRowReorderingProps {
  batch: Batch;
  setBatch: Dispatch<SetStateAction<Batch>>;
}

export const useReordering = (props: UseRowReorderingProps) => {
  const { batch, setBatch } = props;

  const [isReorderActive, setIsReorderActive] = useRecoilState(
    batchAtoms.isReorderActive,
  );
  const isAddingPart = useRecoilValue(batchAtoms.isAddingPart);

  const { notifyAxiosError } = useNotification();

  const onReorder: RowReorderingProps<BatchPart>['onReorder'] = async (
    parts,
  ) => {
    try {
      await batchApi.updateBatchPartSortOrder({
        batchId: batch?.id,
        parts: parts.map(({ id }, index) => {
          return {
            batchSortOrder: index + 1,
            id,
          };
        }),
      });

      setBatch({ ...batch, parts });
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to update batch sort order.',
      });
    }
  };

  const reordering: RowReorderingProps<BatchPart> = {
    disabled: isAddingPart,
    isActive: isReorderActive,
    isAvailable: batch?.parts?.length >= 2,
    onReorder,
    setIsActive: setIsReorderActive,
  };

  return { reordering };
};
