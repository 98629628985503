import { GetRowProps } from 'gantri-components';
import { MachineRecord } from '../../machines.types';
import { getTableRowStatusFromAttentions } from '../../../../helpers/get-table-row-status-from-attentions';
import { dataAttrMachineId } from '../../machine.constants';

export const getRowProps: GetRowProps<MachineRecord> = (row) => {
  const { id } = row.original;

  const status = getTableRowStatusFromAttentions(row);

  return {
    [dataAttrMachineId]: id,
    status,
  };
};
