import { Box, GetFolderStructureArgs, Stack } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { productLevelCloudinaryFileTypes } from '../../../../../../api/products/products.constants';
import {
  ProductAssetKeyNames,
  ProductCloudinaryFileType,
} from '../../../../../../api/products/products.types';
import { SortablePhotosList } from '../../../../../../components';
import { Label } from '../../../../../../components/label';
import { productAtoms } from '../../../../product.atoms';
import { getTransformProductAssetFileName } from '../../helpers/get-transform-product-asset-file-name';
import { useHandleAssets } from '../../hooks/use-handle-assets';
import { ProductPhotosListProps } from './product-photos-list.types';

export const ProductPhotosList = (props: ProductPhotosListProps) => {
  const {
    children,
    expectedExtensions,
    fileType,
    gap = '2x',
    isVideoFile,
    max,
    photos,
    selectedPhoto,
    selectedSku,
    title,
  } = props;

  const product = useRecoilValue(productAtoms.product);

  const {
    getOnFileRecovered,
    getOnProductAssetRemove,
    getOnProductAssetReplace,
    getOnProductAssetUpload,
    getOnReorderPhotos,
    handlePhotoSelected,
  } = useHandleAssets();

  const onClick = async (fileName: string) => {
    if (selectedPhoto !== fileName) {
      await handlePhotoSelected(fileName);
    }
  };

  let getFolderStructureArgs: GetFolderStructureArgs<'products'>;

  if (
    fileType === productLevelCloudinaryFileTypes.lifestylePhotos ||
    fileType === productLevelCloudinaryFileTypes.videos
  ) {
    getFolderStructureArgs = {
      directory: 'products',
      fileType,
      identifiers: {
        productId: product.id,
      },
    };
  } else {
    getFolderStructureArgs = {
      directory: 'products',
      fileType,
      identifiers: {
        productId: product.id,
        sku: selectedSku,
      },
    };
  }

  const { transformFileName } = getTransformProductAssetFileName({
    fileType,
    productId: product.id,
    sku: selectedSku,
  });

  return (
    <Stack gap={gap}>
      <Box>
        <Label text={title} />
        {children}
      </Box>

      <SortablePhotosList
        expectedExtensions={expectedExtensions}
        getFolderStructureArgs={getFolderStructureArgs}
        isVideoFile={isVideoFile}
        max={max}
        newFileName={fileType}
        photos={photos}
        selectedPhoto={selectedPhoto}
        transformFileName={transformFileName}
        onClick={onClick}
        onFileRecovered={getOnFileRecovered({
          assets: photos,
          fileType: cloudinaryFileTypeMap[fileType],
        })}
        onImageRemove={getOnProductAssetRemove({
          assets: photos,
          fileType: cloudinaryFileTypeMap[fileType],
        })}
        onImageReplace={getOnProductAssetReplace({
          assets: photos,
          fileType: cloudinaryFileTypeMap[fileType],
        })}
        onImageUpload={getOnProductAssetUpload({
          assets: photos,
          fileType: cloudinaryFileTypeMap[fileType],
        })}
        onReorder={getOnReorderPhotos({
          fileType: cloudinaryFileTypeMap[fileType],
        })}
      />
    </Stack>
  );
};

const cloudinaryFileTypeMap = {
  'product-photos': 'whiteBackgroundPhotos',
  videos: 'videos',
} as const satisfies Partial<
  Record<Exclude<ProductCloudinaryFileType, 'usdz'>, ProductAssetKeyNames>
>;
