import { getSkuDetails, ProductColorCode } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { ColorSwatchAndLabel } from '../../../../../../../../../../../../components/common/color-swatch-and-label';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import { BulkUploadTableSkuHeader } from './components/bulk-upload-table-sku-header';
import { BulkUploadTableWhiteBackgroundPhotosCell } from './components/bulk-upload-table-white-background-photos-cell';
import { BulkUploadTableWhiteBackgroundPhotosHeader } from './components/bulk-upload-table-white-background-photos-header';
import { BulkUploadTableScalePhotoCell } from './components/bulk-upload-table-scale-photo-cell';
import { BulkUploadTableScalePhotoHeader } from './components/bulk-upload-table-scale-photo-header';
import { BulkUploadTableDimmingPhotosCell } from './components/bulk-upload-table-dimming-photos-cell';
import { BulkUploadTableDimmingPhotosHeader } from './components/bulk-upload-table-dimming-photos-header';
import { BulkUploadTableUsdzCell } from './components/bulk-upload-table-usdz-cell';
import { BulkUploadTableUsdzHeader } from './components/bulk-upload-table-usdz-header';
import { BulkUploadTableActionsCell } from './components/bulk-upload-table-actions-cell';
import { FormattedSkuLevelAssetData } from '../../../../../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';

export const useBulkUploadColumns = (props: {
  showAssetsRemovedToast: () => void;
}) => {
  const { showAssetsRemovedToast } = props;

  const skus = useRecoilValue(productAtoms.skus);

  const columns = useMemo(() => {
    const longestSkuLength = Math.max(
      ...skus.map((sku) => {
        return sku.length;
      }),
    );

    const columns: ColumnDef<FormattedSkuLevelAssetData>[] = [
      {
        accessorKey: 'sku',
        cell: ({
          getValue,
        }: CellContext<
          FormattedSkuLevelAssetData,
          FormattedSkuLevelAssetData['sku']
        >) => {
          const sku = getValue();
          const { color } = getSkuDetails({ sku, skuPartialCodes: [] });

          return (
            <ColorSwatchAndLabel
              colorCode={color as ProductColorCode}
              text={sku}
            />
          );
        },
        header: BulkUploadTableSkuHeader,
        size: longestSkuLength * 9,
      },
      {
        accessorKey: 'whiteBackgroundPhotos',
        cell: ({
          getValue,
          row,
        }: CellContext<
          FormattedSkuLevelAssetData,
          FormattedSkuLevelAssetData['whiteBackgroundPhotos']
        >) => {
          const whiteBackgroundPhotos = getValue();
          const { selectedWhiteBackgroundPhoto } = row.original;

          return (
            <BulkUploadTableWhiteBackgroundPhotosCell
              selectedWhiteBackgroundPhoto={selectedWhiteBackgroundPhoto}
              whiteBackgroundPhotos={whiteBackgroundPhotos}
            />
          );
        },
        header: BulkUploadTableWhiteBackgroundPhotosHeader,
      },
      {
        accessorKey: 'scalePhoto',
        cell: ({
          getValue,
        }: CellContext<
          FormattedSkuLevelAssetData,
          FormattedSkuLevelAssetData['scalePhoto']
        >) => {
          const scalePhoto = getValue();

          return <BulkUploadTableScalePhotoCell scalePhoto={scalePhoto} />;
        },
        header: BulkUploadTableScalePhotoHeader,
      },
      {
        cell: ({ row }: CellContext<FormattedSkuLevelAssetData, never>) => {
          const { dimmingPhotoDark, dimmingPhotoLight } = row.original;

          return (
            <BulkUploadTableDimmingPhotosCell
              dimmingPhotoDark={dimmingPhotoDark}
              dimmingPhotoLight={dimmingPhotoLight}
            />
          );
        },
        header: BulkUploadTableDimmingPhotosHeader,
        id: 'dimmingPhotos',
      },
      {
        accessorKey: 'usdz',
        cell: ({
          getValue,
          row,
        }: CellContext<
          FormattedSkuLevelAssetData,
          FormattedSkuLevelAssetData['usdz']
        >) => {
          const usdz = getValue();
          const { sku } = row.original;

          return <BulkUploadTableUsdzCell sku={sku} usdz={usdz} />;
        },
        header: BulkUploadTableUsdzHeader,
      },
      {
        accessorKey: 'sku',
        cell: ({
          row,
        }: CellContext<
          FormattedSkuLevelAssetData,
          FormattedSkuLevelAssetData['sku']
        >) => {
          return (
            <BulkUploadTableActionsCell
              {...row.original}
              showAssetsRemovedToast={showAssetsRemovedToast}
            />
          );
        },
        header: '',
        id: 'actions',
        size: 30,
      },
    ];

    return columns;
  }, []);

  return columns;
};
