import { Conditional, Stack } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useDraftModals } from './hooks/use-draft-modals';
import { DraftVersionHeading } from './components/draft-version-heading';
import { PublishedVersionHeading } from './components/published-version-heading';
import { productAtoms } from '../../../../product.atoms';

export const ProductJobsHeader = () => {
  const product = useRecoilValue(productAtoms.product);

  const productId = product.id;
  const productVersion = product.version;

  const { showDiscardDraftModal, showNewVersionModal, showPublishModal } =
    useDraftModals({
      currentProduct: product,
      productId,
      productVersion,
    });

  const { id, status } = productVersion || {};
  const isDraftVersion = status === 'Draft';

  return (
    <Conditional condition={!!id}>
      <Stack gap="2x" paddingTop="3x">
        <Conditional
          condition={isDraftVersion}
          Fallback={
            <PublishedVersionHeading
              currentProduct={product}
              productVersion={productVersion}
              showNewVersionModal={showNewVersionModal}
            />
          }
        >
          <DraftVersionHeading
            productVersion={productVersion}
            showDiscardDraftModal={showDiscardDraftModal}
            showPublishModal={showPublishModal}
          />
        </Conditional>
      </Stack>
    </Conditional>
  );
};
