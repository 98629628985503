import { Conditional, Typography } from 'gantri-components';
import { Link } from 'react-router-dom';
import { LONG_FORMAT } from '../../../../../../constants/dates';
import { formatDate } from '../../../../../../helpers/formatter';
import routePaths from '../../../../../../config/route-paths';
import { PartDetailsCreatedProps } from './part-details-created.types';

export const PartDetailsCreated = (props: PartDetailsCreatedProps) => {
  const { part } = props;

  const createdUser = part?.statusInfo?.created?.user;
  const createdDate = `${formatDate(part?.createdAt, LONG_FORMAT)}${
    createdUser?.id ? ' by' : ''
  }`;
  const createdUserName = [createdUser?.firstName, createdUser?.lastName]
    .filter((i) => {
      return !!i;
    })
    .join(' ');

  return (
    <div>
      <Typography display="inline" text={createdDate} />
      <Conditional condition={!!createdUser?.id}>
        &nbsp;
        <Link to={`${routePaths.users}/${createdUser?.id}`}>
          <Typography
            color="link"
            decoration="underline"
            display="inline"
            text={createdUserName}
          />
        </Link>
      </Conditional>
    </div>
  );
};
