import { Conditional, Grid, Icon, Typography } from 'gantri-components';
import { FC } from 'react';
import { CellCurrentJobProps } from './cell-current-job.types';
import { jobTypeToIconTypeMap } from '../../../../../../constants/jobs';

export const CellCurrentJob: FC<CellCurrentJobProps> = (props) => {
  const { getValue } = props;

  const value = getValue();

  return (
    <Conditional condition={!!value?.type}>
      <Grid
        alignItems="center"
        columns="1.6rem max-content max-content"
        gap="0.6rem"
      >
        <Icon name={jobTypeToIconTypeMap?.[value?.type]} size="1.6rem" />
        <Typography text={value?.step} />
        <Typography text={value?.type} />
      </Grid>
    </Conditional>
  );
};
