import * as yup from 'yup';
import { required } from '../../helpers/validators';

export const NewInventoryPurchaseSchema = yup.object().shape({
  address: required(),
  city: required(),
  country: required(),
  inventory: required(),
  invoice: required(),
  priority: required(),
  state: required(),
  totalCost: required(),
  units: required(),
});
