import { jobsApi } from '../../../api';
import { useNotification } from '../../../hooks/useNotification';
import { ResetJobsResponse } from '../../../api/jobs/routes/reset-jobs/reset-jobs.types';

export const useResetJobs = () => {
  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const resetJobs = async (
    stockId: number,
    sku: string,
  ): Promise<ResetJobsResponse> => {
    try {
      showLoading();

      const data = await jobsApi.resetJobs({
        sku,
        stockId,
      });

      return data.data;
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to reset jobs.' });
    } finally {
      hideLoading();
    }
  };

  return { resetJobs };
};
