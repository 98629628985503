import { Modal } from 'gantri-components';
import { useResetRecoilAtomsOnMount } from '../../../../../hooks/use-reset-recoil-atoms-on-mount';
import { manageQueuesModalAtoms } from './manage-queues-modal.atoms';
import { ManageQueuesModalContent } from './components/manage-queues-modal-content';
import { ManageQueuesModalFooter } from './components/manage-queues-modal-footer';
import { ManageQueuesModalProps } from './manage-queues-modal.types';
import { jobSteps, jobTypeOptions } from '../../../../../constants/options';

export const ManageQueuesModal = (props: ManageQueuesModalProps) => {
  const { onClose } = props;

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    manageQueuesModalAtoms,
  );

  const handleCloseModal = onClose;

  return (
    <ResetAtomsWrapper>
      <Modal
        contentStackProps={{
          alignContent: { lg: 'center', md: 'flex-start' },
          minHeight: '16.8rem',
        }}
        footer={<ManageQueuesModalFooter handleCloseModal={handleCloseModal} />}
        header={`Manage Existing ${jobSteps.print} ${jobTypeOptions.transfer} Queues`}
        maxWidth={{ lg: '62rem', md: '100%' }}
        onClose={handleCloseModal}
      >
        <ManageQueuesModalContent />
      </Modal>
    </ResetAtomsWrapper>
  );
};
