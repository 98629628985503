import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  UpdatePartTemplateThumbnailArgs,
  UpdatePartTemplateThumbnailResponse,
} from './update-part-template-thumbnail.types';
import { updatePartTemplateThumbnail } from './update-part-template-thumbnail';

export const useUpdatePartTemplateThumbnail = <
  TransformedData = UpdatePartTemplateThumbnailResponse,
>(
  props?: GenericMutateQueryProps<
    UpdatePartTemplateThumbnailArgs,
    UpdatePartTemplateThumbnailResponse,
    TransformedData
  >,
) => {
  const { onMutate: onUpdatePartTemplateThumbnail, ...rest } =
    useGenericMutateQuery({
      fallbackErrorMessage: 'Unable to update the part template.',
      mutationFn: updatePartTemplateThumbnail,
      ...props,
    });

  return { ...rest, onUpdatePartTemplateThumbnail };
};
