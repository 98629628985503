import * as React from 'react';
import { FC } from 'react';
import {
  Button,
  Conditional,
  Dropdown,
  FormikInput,
  Grid,
  Modal,
  Typography,
} from 'gantri-components';
import { Form, Formik } from 'formik';
import { convertStringArrayToValueLabelArray } from '../../../../helpers/formatter';
import { referralCancelReasons } from '../../../../constants/options';
import { CancelReferralSchema } from './cancel-referral-schema';
import { CancelReferralProps } from './cancel-referral.types';

export const CancelReferralModal: FC<CancelReferralProps> = ({
  initialValue = { reason: '', reasonDescription: '' },
  onCancel,
  onSubmit,
}) => {
  const options = convertStringArrayToValueLabelArray(referralCancelReasons);

  return (
    <Formik
      initialValues={initialValue}
      validateOnChange
      validateOnMount
      validationSchema={CancelReferralSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, values }: any) => {
        return (
          <Form>
            <Modal
              footer={
                <Grid columns={2} gap="1.6rem">
                  <Button
                    size="large"
                    text="No"
                    variant="secondary"
                    onClick={onCancel}
                  />

                  <Button
                    disabled={!isValid}
                    size="large"
                    text="Yes"
                    type="submit"
                  />
                </Grid>
              }
              header={
                <Typography
                  align="center"
                  text="Cancel referral"
                  textStyle="bold"
                  variant="h4"
                />
              }
              width={{ lg: '42rem', md: '100%' }}
              onClose={onCancel}
            >
              <Grid width="100%">
                <FormikInput
                  ariaLabel="reason"
                  Field={
                    <Dropdown
                      items={options}
                      labelText="Enter reason"
                      name="reason"
                      width="100%"
                    />
                  }
                  name="reason"
                />

                <Conditional condition={values.reason === 'Other'}>
                  <FormikInput
                    ariaLabel="reasonDescription"
                    name="reasonDescription"
                    placeholder="Enter details"
                  />
                </Conditional>
              </Grid>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
