import { useFormikContext } from 'formik';
import { Button } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { AddStocksFormData } from '../../../add-stocks-modal.types';
import { addStocksModalAtoms } from '../../../add-stocks-modal.atoms';
import { addStocksModalSteps } from '../../../add-stocks-modal.constants';
import { ConfirmQuantityFooterProps } from './confirm-quantity-footer.types';

export const ConfirmQuantityFooter = (props: ConfirmQuantityFooterProps) => {
  const { processing } = props;

  const setStep = useSetRecoilState(addStocksModalAtoms.step);

  const { isValid } = useFormikContext<AddStocksFormData>();

  return (
    <>
      <Button
        size="large"
        text="Back"
        variant="secondary"
        onClick={() => {
          setStep(addStocksModalSteps.setQty);
        }}
      />
      <Button
        disabled={!isValid}
        processing={processing}
        size="large"
        text="Confirm"
        type="submit"
      />
    </>
  );
};
