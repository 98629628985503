import { Typography, useConfirmationModal } from 'gantri-components';
import { useRef } from 'react';
import { referralsApi } from '../../../api';
import { useSpinner } from '../../../hooks';
import { useNotification } from '../../../hooks/useNotification';

export const useSendReward = (props?: {
  onRefreshReferralList?: CallableFunction;
}) => {
  const referralIdRef = useRef<number>();
  const { onInterceptRequest } = useSpinner();
  const { notify, notifyAxiosError } = useNotification();

  const [showConfirmModal, hideConfirmModal] = useConfirmationModal(
    {
      confirmButtonText: 'Yes',
      confirmButtonVariant: 'primary',
      content: (
        <Typography
          style={{ textAlign: 'center' }}
          text="Are you sure you want to send the reward?"
        />
      ),
      headerText: 'Send Reward',
      onClose: () => {
        hideConfirmModal();
      },
      onConfirm: async () => {
        await onInterceptRequest(async () => {
          try {
            const response = await referralsApi.sendCredits({
              userId: referralIdRef.current,
            });

            notify(response.data.notice);
            await props?.onRefreshReferralList();
          } catch (error) {
            notifyAxiosError(error);
          } finally {
            hideConfirmModal();
          }
        });
      },
      width: '40rem',
    },
    [],
  );

  const onShowConfirmation = (referralId: number) => {
    referralIdRef.current = referralId;
    showConfirmModal();
  };

  return { onShowConfirmation };
};
