import { useFormikContext } from 'formik';
import {
  Conditional,
  FormikInput,
  TextField,
  Typography,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { getGcodePrintQtyFileName } from '../../../../../../../../../../../../helpers/gcodes';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import { useGetVersionDetails } from '../../../../../../../../hooks/use-get-version-details';
import { useIsEditingPartRowJobsDataForm } from '../../../../../../hooks/use-is-editing-part-row';
import { PartJobBlocksFormData } from '../../../../part-job-blocks.types';
import { PartJobBlocksPrintNameProps } from './part-job-blocks-print-name.types';

export const PartJobBlocksPrintName = (props: PartJobBlocksPrintNameProps) => {
  const { index, partName, printBlock, printName, replace } = props;

  const { isMinorVersion } = useGetVersionDetails();

  const isEditingPartRow = useIsEditingPartRowJobsDataForm();

  const { name: productName, version } = useRecoilValue(productAtoms.product);

  const { values } = useFormikContext<PartJobBlocksFormData>();
  const { printBlock: printBlocks } = values;

  const numPrints = printBlocks.length;

  const onTextChange = (name: string) => {
    const formattedGcodeFileName = printBlock.gcode?.url
      ? getGcodePrintQtyFileName({
          numPrints,
          partName,
          printName: name,
          productName,
          productVersion: version.version,
          weight: printBlock.weight,
        })
      : undefined;

    replace(index, {
      ...printBlock,
      gcode: formattedGcodeFileName
        ? {
            ...printBlock.gcode,
            fileName: formattedGcodeFileName,
          }
        : printBlock.gcode,
      name,
    });
  };

  return (
    <Conditional
      condition={isEditingPartRow}
      Fallback={<Typography text={printName || '-'} />}
    >
      <FormikInput
        Field={
          <TextField
            disabled={isMinorVersion}
            placeholder="Input name"
            onTextChange={onTextChange}
          />
        }
        fieldVariant="standard"
        name={`printBlock[${index}].name`}
        required
      />
    </Conditional>
  );
};
