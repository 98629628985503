import styled from 'styled-components';
import { Flex, Grid, palette } from 'gantri-components';
import { Property } from 'csstype';

export const StyledHeaderContainer = styled(Grid)`
  background-color: ${({ theme }) => {
    return theme.colors.surfaces.monochrome.overlayLevel1;
  }};
`;

export const StyledInventoryPurchaseFieldContainer = styled.div<{
  margin?: Property.Margin;
}>`
  width: 100%;
  margin-top: ${(props) => {
    return props.margin ? props.margin : '1.5rem';
  }};

  & > *:first-child {
    margin-bottom: 1rem;
  }
`;

export const StyledIconContainer = styled.div`
  width: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
`;

export const StyledUploadContentContainer = styled(Flex)`
  cursor: pointer;
  min-height: 2.4rem;
  border: 1px dashed ${palette.green_400};
  user-select: none;
`;
