import React, { FC, useEffect, useState } from 'react';
import { Cell, Flex, Grid, Icon, Overlay } from 'gantri-components';
import { useKey } from 'react-use';
import {
  StyledBackdrop,
  StyledGallerySelector,
  StyledGallerySelectorBlock,
  StyledGallerySelectorContainer,
  StyledGallerySelectorGuide,
} from './photos-gallery.styles';
import { PhotosGalleryProps } from './photos-gallery.types';
import { PhotosGalleryPresets } from './photos-gallery.presets';
import { PhotosGalleryCarousel } from './carousel';
import Thumbnail from '../thumbnail/thumbnail';

export const PhotosGallery: FC<PhotosGalleryProps> = (props) => {
  const { onClose, photos, selectedIndex, show } = props;
  const [currentIndex, setCurrentIndex] = useState(selectedIndex);

  useEffect(() => {
    setTimeout(() => {
      setCurrentIndex(selectedIndex ?? 0);
    }, 0);
  }, [selectedIndex]);

  const onPageChange = (page: number) => {
    if (page !== currentIndex) {
      setCurrentIndex(page);
    }
  };

  useKey(
    'ArrowLeft',
    (event: KeyboardEvent) => {
      event.preventDefault();
      onPageChange(currentIndex - 1 < 0 ? photos.length - 1 : currentIndex - 1);
    },
    {},
    [currentIndex, photos?.length],
  );

  useKey(
    'ArrowRight',
    (event: KeyboardEvent) => {
      event.preventDefault();
      onPageChange(currentIndex + 1 > photos.length - 1 ? 0 : currentIndex + 1);
    },
    {},
    [currentIndex, photos?.length],
  );

  useKey('Escape', (event: KeyboardEvent) => {
    event.preventDefault();
    onClose?.();
  });

  useEffect(() => {
    const element = document.body.querySelector(
      `[data-gallery-selector-position="${currentIndex}"]`,
    );

    if (element) {
      element.scrollIntoView();
    }
  }, [currentIndex]);

  return (
    <Overlay
      content={
        <StyledBackdrop>
          <Grid
            backgroundColor="transparent"
            columns="1fr"
            gap="s1"
            height="100%"
            maxWidth="100vw"
            paddingBottom="2x"
            paddingTop={{ lg: '2x', md: '8x' }}
            rows="max-content 1fr max-content"
            width="100%"
          >
            <Cell>
              <Flex
                alignItems="center"
                gap="2x"
                justifyContent="flex-end"
                paddingRight="2x"
              >
                <Icon
                  color="white"
                  cursor="pointer"
                  name="ui-control:x_24"
                  size="2.4rem"
                  onClick={onClose}
                />
              </Flex>
            </Cell>

            <Cell>
              <PhotosGalleryCarousel
                currentIndex={currentIndex}
                images={photos}
                onPageChanged={setCurrentIndex}
              />
            </Cell>

            <Cell>
              <StyledGallerySelectorBlock>
                <StyledGallerySelectorContainer>
                  {photos.map((photo, index) => {
                    return (
                      <GallerySelector
                        key={photo}
                        photoUrl={photo}
                        position={index}
                        selected={currentIndex === index}
                        onSelected={setCurrentIndex}
                      />
                    );
                  })}
                </StyledGallerySelectorContainer>
              </StyledGallerySelectorBlock>
            </Cell>
          </Grid>
        </StyledBackdrop>
      }
      open={show}
      triggerEvent="manual"
      onClose={onClose}
    />
  );
};

export const GallerySelector = ({
  onSelected,
  photoUrl,
  position,
  selected,
}) => {
  const onClick = () => {
    onSelected(position);
  };

  return (
    <StyledGallerySelector selected={selected} onClick={onClick}>
      <Thumbnail size="7.4rem" src={photoUrl} />
      <StyledGallerySelectorGuide data-gallery-selector-position={position} />
    </StyledGallerySelector>
  );
};

PhotosGallery.defaultProps = PhotosGalleryPresets;
