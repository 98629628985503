import {
  Flex,
  Icon,
  Conditional,
  Stack,
  Box,
  Typography,
} from 'gantri-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { BoxProps } from 'gantri-components/dist/components/box/box.types';
import { bulkUploadModalAtoms } from '../../../../bulk-upload-modal.atoms';
import { formatDate } from '../../../../../../../../../../../../helpers/formatter';
import { Divider } from '../../../../../../../../../../../../components/divider';
import { ValidatedFilesList } from './components/validated-files-list';
import { StyledHistoryPanelStack } from './bulk-upload-history-panel.styles';
import { SimpleErrorBoundary } from '../../../../../../../../../../../../components/simple-error-boundary';

export const BulkUploadHistoryPanel = () => {
  const fileValidationByTimestamp = useRecoilValue(
    bulkUploadModalAtoms.fileValidationByTimestamp,
  );
  const [isHistoryExpanded, setIsHistoryExpanded] = useRecoilState(
    bulkUploadModalAtoms.isHistoryExpanded,
  );

  const fileValidationRecords = useMemo(() => {
    return Object.values(fileValidationByTimestamp);
  }, [Object.keys(fileValidationByTimestamp)]);

  const paddingProps: Pick<BoxProps, 'horizontalPadding' | 'verticalPadding'> =
    {
      horizontalPadding: '2rem',
      verticalPadding: '2x',
    };

  return (
    <StyledHistoryPanelStack
      alignItems="start"
      gap="0"
      maxWidth={isHistoryExpanded ? '40rem' : 'max-content'}
      rows="max-content max-content 1fr"
      width={isHistoryExpanded ? '40rem' : 'max-content'}
    >
      <Flex
        alignItems="center"
        gap="x"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setIsHistoryExpanded((value) => {
            return !value;
          });
        }}
        {...paddingProps}
      >
        <Icon color="t2" name="time:clock" />

        <Conditional condition={isHistoryExpanded}>
          <Flex alignItems="center" gap="x" justifyContent="space-between">
            <Typography text="Uploads Initiated" variant="h5" />
            <Icon color="t2" cursor="pointer" name="arrows:arrow_line_right" />
          </Flex>
        </Conditional>
      </Flex>

      <Divider
        paddingLeft={
          isHistoryExpanded ? paddingProps.horizontalPadding : undefined
        }
        paddingRight={
          isHistoryExpanded ? paddingProps.horizontalPadding : undefined
        }
      />

      <Conditional condition={isHistoryExpanded}>
        <Box height={0} minHeight="100%" width="100%" {...paddingProps}>
          <Box height={0} minHeight="100%" overflow="auto" width="100%">
            <Conditional
              condition={!!fileValidationRecords.length}
              Fallback={<Typography color="t2" text="None" />}
            >
              {fileValidationRecords
                .reverse()
                .map(({ invalidFiles, timestamp, validFiles }) => {
                  return (
                    <SimpleErrorBoundary key={timestamp}>
                      <Stack
                        gap="x"
                        height="unset"
                        paddingBottom="2x"
                        paddingTop="x"
                        width="100%"
                      >
                        <Typography
                          text={formatDate(timestamp, 'h:mmaaa')}
                          variant="p3"
                        />

                        <ValidatedFilesList filesList={validFiles} isValid />

                        <ValidatedFilesList
                          filesList={invalidFiles}
                          isValid={false}
                        />
                      </Stack>
                    </SimpleErrorBoundary>
                  );
                })}
            </Conditional>
          </Box>
        </Box>
      </Conditional>
    </StyledHistoryPanelStack>
  );
};
