import { Typography, Flex, Button, Grid, Conditional } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useFullScreenExitWarningModal } from '../../../../../../../../../jobs/components/modals/common/full-screen-modal-header';
import { BulkUploadExitWarningModalContent } from '../bulk-upload-exit-warning-modal-content';
import { bulkUploadModalAtoms } from '../../bulk-upload-modal.atoms';
import { exitWarningModalHeader } from '../../bulk-upload-modal.constants';
import { useHandleBulkUploadSave } from './hooks/use-handle-bulk-upload-save';
import { BulkUploadModalFooterProps } from './bulk-upload-modal-footer.types';

export const BulkUploadModalFooter = (props: BulkUploadModalFooterProps) => {
  const { handleCloseModal } = props;

  const numFilesAdded = useRecoilValue(bulkUploadModalAtoms.numFilesAdded);
  const numFilesRemoved = useRecoilValue(bulkUploadModalAtoms.numFilesRemoved);
  const disableSave = useRecoilValue(bulkUploadModalAtoms.disableSave);

  const [showExitWarningModal] = useFullScreenExitWarningModal({
    children: <BulkUploadExitWarningModalContent />,
    header: exitWarningModalHeader,
    onConfirm: handleCloseModal,
  });

  const onCancel = async () => {
    if (disableSave) {
      await handleCloseModal();
    } else {
      showExitWarningModal();
    }
  };

  const { handleSave, processing } = useHandleBulkUploadSave({
    handleCloseModal,
  });

  const filesModifiedText = [
    numFilesAdded && `${numFilesAdded} added`,
    numFilesRemoved && `${numFilesRemoved} removed`,
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <Grid
      alignItems="center"
      columns="1fr max-content"
      gap="x"
      horizontalPadding="2rem"
      verticalPadding="x"
    >
      <Conditional
        condition={!!numFilesAdded || !!numFilesRemoved}
        Fallback={<Typography color="t2" text="No Changes" />}
      >
        <Typography text={filesModifiedText} />
      </Conditional>

      <Flex gap="x">
        <Button
          size="large"
          text="Cancel"
          variant="secondary"
          onClick={onCancel}
        />
        <Button
          disabled={disableSave || processing}
          size="large"
          text="Save all Changes"
          onClick={handleSave}
        />
      </Flex>
    </Grid>
  );
};
