import { Modal } from 'gantri-components';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { restartOrCancelPartModalAtoms } from './restart-or-cancel-part-modal.atoms';
import { RestartOrCancelPartModalProps } from './restart-or-cancel-part-modal.types';
import { getHeaderText } from './helpers/get-header-text';
import { RestartOrCancelPartModalFooter } from './components/restart-or-cancel-part-modal-footer';
import { RestartOrCancelPartModalContent } from './components/restart-or-cancel-part-modal-content';
import { useResetRecoilAtomsOnMount } from '../../../../../../hooks/use-reset-recoil-atoms-on-mount';
import { useHandleCloseModal } from '../../../../../jobs/components/modals/hooks/use-handle-close-modal';

export const RestartOrCancelPartModal = (
  props: RestartOrCancelPartModalProps,
) => {
  const { initialStep, onClose, onUpdatePart, part } = props;

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    restartOrCancelPartModalAtoms,
  );
  const [step, setStep] = useRecoilState(restartOrCancelPartModalAtoms.step);

  const handleCloseModal = useHandleCloseModal({
    onClose,
    onUpdate: onUpdatePart,
  });

  useEffect(() => {
    setStep(initialStep);
  }, []);

  return (
    <ResetAtomsWrapper>
      <Modal
        footer={
          <RestartOrCancelPartModalFooter
            handleCloseModal={handleCloseModal}
            part={part}
          />
        }
        header={getHeaderText({ partId: part.id, step })}
        height={{ lg: '54rem', md: '100%' }}
        width={{ lg: '64rem', md: '100%' }}
        onClose={async () => {
          await handleCloseModal();
        }}
      >
        <RestartOrCancelPartModalContent />
      </Modal>
    </ResetAtomsWrapper>
  );
};
