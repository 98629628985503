import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { FetchActiveProductsAndDesignersResponse } from './fetch-active-products-and-designers.types';

const query = () => {
  return axios
    .get<FetchActiveProductsAndDesignersResponse>(
      `${coreApiUrl}/products/designers/names`,
    )
    .then(({ data }) => {
      return data;
    });
};

export const fetchActiveProductsAndDesigners = getGenericQueryObj({
  groupName: 'product-and-designer-names',
  query,
});
