import { Button } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { useDiscardStock } from '../../../../../../../../../api/stocks/routes/discard-stock';
import { discardStockModalAtoms } from '../../../discard-stock-modal.atoms';
import { discardStockModalSteps } from '../../../discard-stock-modal.constants';
import { ConfirmDiscardFooterProps } from './confirm-discard-footer.types';

export const ConfirmDiscardFooter = (props: ConfirmDiscardFooterProps) => {
  const { handleCloseModal, stockId } = props;

  const setStep = useSetRecoilState(discardStockModalAtoms.step);
  const setUpdateOnClose = useSetRecoilState(
    discardStockModalAtoms.updateOnClose,
  );

  const { isLoading: isDiscardingStock, onDiscardStock } = useDiscardStock({
    onSuccess: async ({ notifyCustomer }) => {
      if (notifyCustomer) {
        setStep(discardStockModalSteps.notifyCustomer);
        setUpdateOnClose(true);
      } else {
        await handleCloseModal({ updateOnClose: true });
      }
    },
    showLoading: false,
  });

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        processing={isDiscardingStock}
        size="large"
        text="Confirm"
        onClick={async () => {
          await onDiscardStock({ id: stockId });
        }}
      />
    </>
  );
};
