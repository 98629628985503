import { Flex, useModal } from 'gantri-components';
import { useMoreMenu } from '../../../../../../hooks/use-more-menu';
import { AddStocksModal } from '../../../use-stock-summary-modals/components/add-stocks-modal';
import { UpdateInventoryTargetModal } from '../../../use-stock-summary-modals/components/update-inventory-target-modal';
import { StockSummaryRowMenuProps } from './stock-summary-row-menu.types';

export const StockSummaryRowMenu = (props: StockSummaryRowMenuProps) => {
  const { data } = props;

  const [showAddStockModal, hideAddStockModal] = useModal(() => {
    return <AddStocksModal stocks={[data]} onClose={hideAddStockModal} />;
  }, [data]);

  const [showUpdateInventoryTargetModal, hideUpdateInventoryTargetModal] =
    useModal(() => {
      return (
        <UpdateInventoryTargetModal
          stocks={[data]}
          onClose={hideUpdateInventoryTargetModal}
        />
      );
    }, [data]);

  const { MoreMenu } = useMoreMenu({
    data,
    options: [
      {
        enabled: true,
        name: 'Add Stock',
        onOptionClick: showAddStockModal,
      },
      {
        allowedFor: ['Admin'],
        enabled: true,
        name: 'Update Inventory Target',
        onOptionClick: showUpdateInventoryTargetModal,
      },
    ],
  });

  return (
    <Flex justifyContent="end">
      <MoreMenu />
    </Flex>
  );
};
