import { Flex, Icon, Tooltip, Typography } from 'gantri-components';
import { TableHeaderWithTooltipProps } from './table-header-with-tooltip.types';

export const TableHeaderWithTooltip = (props: TableHeaderWithTooltipProps) => {
  const { headerText, tooltipDescription } = props;

  return (
    <Flex alignItems="center" gap="0.8rem">
      <Typography text={headerText} textStyle="bold" />
      {tooltipDescription && (
        <Tooltip
          description={tooltipDescription}
          overlayContainerStyles={{
            maxWidth: 'fit-content',
          }}
          position="top-end"
        >
          <Icon name="alert:i_circle_24" top="2px" />
        </Tooltip>
      )}
    </Flex>
  );
};
