import { FC } from 'react';
import {
  Conditional,
  Flex,
  Grid,
  Stack,
  Typography,
  useModal,
} from 'gantri-components';
import { Link } from 'react-router-dom';
import { StockDetailsProps } from './stock-details.types';
import { stockStatuses } from '../../../../constants/options';
import { UpdateLocationModal } from '../../../../components/modals/update-location-modal';
import { OnUpdateLocation } from '../../../../components/modals/update-location-modal/update-location-modal.types';
import { stocksApi } from '../../../../api';
import { useNotification } from '../../../../hooks/useNotification';
import routePaths from '../../../../config/route-paths';
import { MinorVersionInfo } from '../../../../components/minor-version-info';
import { formatDate } from '../../../../helpers/formatter';
import { LONG_FORMAT } from '../../../../constants/dates';
import { SectionTitle } from '../../../../components/layout/section-title';

export const StockDetails: FC<StockDetailsProps> = (props) => {
  const { refreshStock, stock } = props;
  const {
    completed,
    completedBy,
    createdAt,
    location,
    orderId,
    orderType,
    status,
    stockYield,
    versionInfo,
  } = stock;

  const { notify } = useNotification();

  const formattedDates = {
    completed: completed ? formatDate(completed, LONG_FORMAT) : 'N/A',
    completedBy: completedBy ? formatDate(completedBy, LONG_FORMAT) : 'N/A',
    createdAt: createdAt ? formatDate(createdAt, LONG_FORMAT) : 'N/A',
  };

  const onUpdateLocation: OnUpdateLocation = async (locationId) => {
    const response = await stocksApi.updateStockLocation({
      locationId,
      stockId: stock?.id,
    });

    if (response.data.success) {
      notify(response.data.notice);
      refreshStock();
      hideStockLocationUpdateModal();
    }
  };

  const [showStockLocationUpdateModal, hideStockLocationUpdateModal] =
    useModal(() => {
      return (
        <UpdateLocationModal
          currentLocationId={location?.id}
          currentLocationName={location?.name}
          locationTimelines={stock.locationTimelines}
          stockId={stock.id}
          onClose={hideStockLocationUpdateModal}
          onUpdateLocation={onUpdateLocation}
        />
      );
    }, [stock]);

  return (
    <Stack gap="2x">
      <SectionTitle text="Details" />

      <Grid columns="18rem 1fr" rowGap="1.2rem">
        <Typography text="Created" textStyle="bold" />
        <Typography
          color={createdAt ? 't1' : 't2'}
          text={formattedDates.createdAt}
        />

        <Typography text="Complete by" textStyle="bold" />
        <Typography
          color={completedBy ? 't1' : 't2'}
          text={formattedDates.completedBy}
        />

        <Typography text="Completed" textStyle="bold" />
        <Typography
          color={completed ? 't1' : 't2'}
          text={formattedDates.completed}
        />

        <Typography text="Version" textStyle="bold" />
        <MinorVersionInfo {...versionInfo} />

        <Typography text="Order" textStyle="bold" />
        <Conditional
          condition={!!orderId}
          Fallback={<Typography color="t2" text="N/A" />}
        >
          <Flex alignItems="center" gap="0.8rem">
            <Link to={`${routePaths.orders}/${orderId}`}>
              <Typography
                color="link"
                decoration="underline"
                htmlTag="a"
                text={`#${orderId}`}
              />
            </Link>

            <Typography color="t2" text={orderType} />
          </Flex>
        </Conditional>

        <Typography text="VLM Assigment" textStyle="bold" />
        <Flex gap="0.6rem">
          <Typography text={location?.name ?? 'Unassigned'} />
          <Conditional
            condition={!(status === stockStatuses.cancelled && !location?.name)}
          >
            <Typography
              color="link"
              decoration="underline"
              text="Edit"
              onClick={showStockLocationUpdateModal}
            />
          </Conditional>
        </Flex>

        <Typography text="Yield" textStyle="bold" />
        <Typography text={stockYield} />
      </Grid>
    </Stack>
  );
};
