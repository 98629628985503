import { Button } from 'gantri-components';
import routePaths from '../../../../config/route-paths';
import {
  StyledHeaderButtonsContainer,
  StyledHeaderButtonWrapper,
} from './stocks-header-content.styles';
import Protected from '../../../../components/common/protected/protected';
import { useRouter } from '../../../../hooks';
import { useGetDownloadMenuOption } from '../../../../hooks/use-get-download-menu-option';
import { useMoreMenu } from '../../../../hooks/use-more-menu';
import { StocksHeaderContentProps } from './stocks-header-content.types';
import { downloadColumns } from './stocks-header-content.constants';
import { fetchPaginatedStocks } from '../../../../api/stocks/routes/fetch-paginated-stocks/fetch-paginated-stocks';

export const StocksHeaderContent = (props: StocksHeaderContentProps) => {
  const { downloadFilters, totalResults } = props;

  const { navigate } = useRouter();

  const { Toast: DownloadToast, downloadOption } = useGetDownloadMenuOption({
    columns: downloadColumns,
    fetchRequestWithFilters: (overrides) => {
      return fetchPaginatedStocks({ ...downloadFilters, ...overrides }).then(
        ({ stocks }) => {
          return stocks;
        },
      );
    },
    fileNamePrefix: 'Stocks Admin',
    totalResults,
  });

  const { MoreMenu } = useMoreMenu({
    options: [
      {
        ...downloadOption,
        allowedFor: ['Lead', 'Admin'],
      },
    ],
  });

  return (
    <StyledHeaderButtonsContainer>
      <StyledHeaderButtonWrapper>
        <Button
          text="Summary"
          variant="secondary"
          onClick={() => {
            return navigate(routePaths.stockSummary);
          }}
        />
      </StyledHeaderButtonWrapper>
      <Protected allowedFor={['Admin']}>
        <StyledHeaderButtonWrapper>
          <Button
            text="New Stocks"
            onClick={() => {
              return navigate(routePaths.newStock);
            }}
          />
        </StyledHeaderButtonWrapper>
      </Protected>
      <Protected allowedFor={['Lead', 'Admin']}>
        <MoreMenu />
        <DownloadToast />
      </Protected>
    </StyledHeaderButtonsContainer>
  );
};
