import {
  Cell,
  Conditional,
  FormikInput,
  TextArea,
  Typography,
} from 'gantri-components';
import { Label } from '../label';
import { ToggleEditableTextAreaCellProps } from './toggle-editable-text-area-cell.types';

export const ToggleEditableTextAreaCell = (
  props: ToggleEditableTextAreaCellProps,
) => {
  const {
    cellProps,
    isEditing,
    label,
    labelProps,
    name,
    textProps,
    value,
    ...rest
  } = props;

  return (
    <Cell {...cellProps}>
      <Label paddingBottom=".5x" text={label} {...labelProps} />
      <Conditional
        condition={isEditing}
        Fallback={<Typography text={value} {...textProps} />}
      >
        <FormikInput name={name} {...rest} Field={<TextArea value={value} />} />
      </Conditional>
    </Cell>
  );
};
