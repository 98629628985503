import { useState } from 'react';
import {
  Box,
  Tab,
  TabPanel,
  Tabs,
  TabsList,
  useModal,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { Activity } from './activity';
import { Account } from './account';
import { Shop } from './shop';
import { Credits } from './credits';
import { Referrals } from './referrals';
import { MoreMenu } from '../../components/dropdowns/more-menu';
import { UserTypeModal } from './user-type-modal';
import MetaData from '../../components/meta-data';
import { PageHeading } from '../../components/layout/page-heading';
import { useSyncPropertyWithQueryString } from '../../hooks/useSyncPropertyWithQueryString';
import { currentUserInit, UserTabs } from './user.constant';
import { useRouter } from '../../hooks';
import { convertUserToUserDetails } from './user-adapter';
import { useLoginAs } from './hooks/use-login-as';
import { useToggleActiveAccount } from './hooks/use-toggle-active-account';
import { UserTab } from './user.types';
import { usersApi } from '../../api';
import { useNotification } from '../../hooks/useNotification';
import { userAtoms } from '../../global-atoms/users';
import { StyledTabListGrid } from './shared.styles';
import { Reviews } from './reviews';
import { ReviewDetailsProvider } from './reviews/reviews-context';
import { useFetchUser } from '../../api/users/routes';
import { TradeStatusModal } from './components/trade-status-modal';

export const User = () => {
  const { notify, notifyAxiosError } = useNotification();
  const { location } = useRouter();

  const { id: currentUserId, isAdmin } = useRecoilValue(userAtoms.user);

  const [currentUser, setCurrentUser] = useState(currentUserInit);

  const {
    email,
    firstName,
    isActive,
    lastName,
    payments,
    shop,
    tradeStatus,
    userId,
    userName,
  } = currentUser;

  const { locationUserId } = location?.state || {};
  const [fallbackUserId] = window.location.pathname.split('/').reverse();
  const pageUserId = locationUserId
    ? parseInt(String(locationUserId), 10)
    : parseInt(fallbackUserId, 10);

  const { currentValue, updateQueryString } =
    useSyncPropertyWithQueryString<UserTab>('tab', 'account');

  const [showUpdateUserTypeModal, hideUpdateUserTypeModal] = useModal(() => {
    return (
      <UserTypeModal
        currentUser={currentUser}
        onClose={hideUpdateUserTypeModal}
      />
    );
  }, [pageUserId, currentUser]);

  const [showUpdateTradeStatusModal, hideUpdateTradeStatusModal] =
    useModal(() => {
      return (
        <TradeStatusModal
          tradeStatus={tradeStatus}
          userId={userId}
          onClose={hideUpdateTradeStatusModal}
        />
      );
    }, [tradeStatus, userId]);

  const updateUser = (user) => {
    setCurrentUser(convertUserToUserDetails(user, { isAdmin, payments }));
  };

  useFetchUser({
    fetchArgs: {
      userId: pageUserId,
    },
    onSuccess: async (user) => {
      updateUser(user);
    },
    showLoading: true,
  });

  const showLoginAsConfirmation = useLoginAs(pageUserId, userName);
  const showToggleActiveAccountConfirmation = useToggleActiveAccount({
    currentUser,
    isActive,
    updateUser: setCurrentUser,
    userId: pageUserId,
  });

  const accountOptions = [
    {
      enabled: true,
      name: 'Reset password',
      onOptionClick: async () => {
        try {
          const { data } = await usersApi.forgotPassword({
            email,
          });

          notify(data.notice);
        } catch (error: unknown) {
          notifyAxiosError({
            error,
            fallbackMessage: 'Unable to reset password.',
          });
        }
      },
    },
    {
      enabled: true,
      name: 'Change user type',
      onOptionClick: showUpdateUserTypeModal,
    },
    {
      name: 'Change trade status',
      onOptionClick: showUpdateTradeStatusModal,
    },
    {
      enabled: true,
      name: isActive ? 'Deactivate' : 'Activate',
      onOptionClick: showToggleActiveAccountConfirmation,
    },
    isAdmin && userId !== currentUserId
      ? {
          enabled: true,
          name: 'Log in as user',
          onOptionClick: showLoginAsConfirmation,
        }
      : null,
  ].filter(Boolean);

  return (
    <>
      <MetaData title={`User #${pageUserId}`} />
      <PageHeading
        subTitle={email}
        title={`${firstName || ''} ${lastName || ''}`}
      >
        <MoreMenu options={accountOptions} />
      </PageHeading>

      <Box marginTop="1rem">
        <Tabs value={currentValue}>
          <StyledTabListGrid alignItems="center">
            <TabsList
              hasBorder={false}
              onValueChange={(value: UserTab) => {
                updateQueryString(value);
              }}
            >
              <Tab label="Account" value={UserTabs.ACCOUNT} />
              <Tab label="Activity" value={UserTabs.ACTIVITY} />
              <Tab label="Shop" value={UserTabs.SHOP} />
              <Tab label="Payments" value={UserTabs.PAYMENTS} />
              <Tab label="Referrals" value={UserTabs.REFERRALS} />
              <Tab label="Reviews" value={UserTabs.REVIEWS} />
            </TabsList>
          </StyledTabListGrid>

          <TabPanel value={UserTabs.ACCOUNT}>
            <Account currentUser={currentUser} updateUser={setCurrentUser} />
          </TabPanel>
          <TabPanel value={UserTabs.ACTIVITY}>
            <Activity userId={userId} />
          </TabPanel>
          <TabPanel value={UserTabs.SHOP}>
            <Shop data={shop} />
          </TabPanel>
          <TabPanel value={UserTabs.PAYMENTS}>
            <Credits currentUser={currentUser} updateUser={setCurrentUser} />
          </TabPanel>
          <TabPanel value={UserTabs.REFERRALS}>
            <Referrals currentUser={currentUser} />
          </TabPanel>
          <TabPanel value={UserTabs.REVIEWS}>
            <ReviewDetailsProvider>
              <Reviews currentUser={currentUser} />
            </ReviewDetailsProvider>
          </TabPanel>
        </Tabs>
      </Box>
    </>
  );
};
