import { Conditional, generateSku, Stack, Typography } from 'gantri-components';
import { CellContext } from '@tanstack/react-table';
import { useSetRecoilState } from 'recoil';
import { NotApplicableText } from '../../../../../../components/common/not-applicable-text';
import { VariantDropdowns } from '../../../../../../components/common/select-product-form/components/select-product-form-content/components/variant-dropdown';
import { newStocksAtoms } from '../../../../new-stocks.atoms';
import { useIsEditingRow } from '../../hooks/use-is-editing-row';
import { TableDataItem } from '../../use-get-new-stocks-table-details.types';
import { useSelectProductFormOptions } from '../../../../../../components/common/select-product-form/components/select-product-form-content/hooks/use-select-product-form-options';
import { useGetVariantPartialDetail } from '../../../../../../components/common/select-product-form/components/select-product-form-content/hooks/use-get-variant-partial-detail';
import { getVariantCodesFromPartials } from '../../../../../../components/common/select-product-form/components/select-product-form-content/helpers/get-variant-codes-from-partials';

export const NewStockVariantCell = (
  props: CellContext<TableDataItem, TableDataItem['variantPartialDetails']>,
) => {
  const { getValue, row } = props;

  const { color, id, product, size } = row.original;
  const variantPartialDetails = getValue() || [];

  const setTableData = useSetRecoilState(newStocksAtoms.tableData);

  const { getVariantPartialDetail } = useGetVariantPartialDetail({
    productId: product.id,
  });

  const { isEditingRow } = useIsEditingRow(row.original);

  const { hasVariants, skuPartialCodes } = useSelectProductFormOptions({
    productId: product.id,
  });

  const getOnVariantSelect = (props: {
    partialIndex: number;
    variantIndex: number;
  }) => {
    const { partialIndex, variantIndex } = props;

    return (item: { code: string; name: string }) => {
      const variantPartialDetail = getVariantPartialDetail({
        partialIndex,
        variantIndex,
        ...item,
      });

      setTableData((oldTableData) => {
        const updatedTableData = oldTableData.map((data) => {
          if (data.id === id) {
            const variantPartialDetails = data.variantPartialDetails.map(
              (variantLevelData, index) => {
                if (index === variantIndex) {
                  return variantLevelData.map((partialData, index) => {
                    if (index === partialIndex) {
                      const updatedPartial = {
                        ...variantPartialDetail,
                        ...item,
                      };

                      return updatedPartial;
                    }

                    return partialData;
                  });
                }

                return variantLevelData;
              },
            );

            const variantCodes = getVariantCodesFromPartials({
              skuPartialCodes,
              variantPartialDetails,
            });

            const selectedSku = generateSku({
              color,
              productId: product.id,
              size,
              variants: variantCodes,
            });

            return {
              ...data,
              selectedSku,
              variantCodes,
              variantPartialDetails,
            };
          }

          return data;
        });

        return updatedTableData;
      });
    };
  };

  return (
    <Conditional condition={hasVariants} Fallback={<NotApplicableText />}>
      <Conditional
        condition={isEditingRow}
        Fallback={
          <>
            {variantPartialDetails.map((variant) => {
              return variant.map(({ code, label, name }) => {
                return <Typography key={code} text={`${label}: ${name}`} />;
              });
            })}
          </>
        }
      >
        <Stack gap="x">
          <VariantDropdowns
            getOnVariantSelect={getOnVariantSelect}
            productId={product.id}
            variantPartialDetails={variantPartialDetails}
          />
        </Stack>
      </Conditional>
    </Conditional>
  );
};
