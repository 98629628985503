import { Link } from 'react-router-dom';
import { Cell, Conditional, Grid, Icon, Typography } from 'gantri-components';
import { reverse } from 'lodash';
import { Fragment } from 'react';
import routePaths from '../../../../../../config/route-paths';
import { PartStatusBadge } from '../../../../../../components/common/part-status-badge';
import { PartDetailsPartAttemptsProps } from './part-details-part-attempts.types';

export const PartDetailsPartAttempts = (
  props: PartDetailsPartAttemptsProps,
) => {
  const { part } = props;

  const sortedAttempts = reverse(
    (part?.attemptsList || []).map((details, index) => {
      return {
        ...details,
        attemptNum: index + 1,
      };
    }),
  );

  return (
    <Grid alignItems="center" columns="repeat(4, max-content)" gap="0.4rem">
      {sortedAttempts.map(({ attemptNum, id, status }) => {
        return (
          <Fragment key={id}>
            <Typography text={attemptNum} />
            <Conditional
              condition={part?.id !== id}
              Fallback={
                <>
                  <Icon color="link" name="arrows:arrow_triangle_left" />
                  <Typography
                    paddingLeft={{ lg: '0.4rem', sm: '0' }}
                    text={`#${id}`}
                  />
                </>
              }
            >
              <span data-icon-spacer="" />
              <Link to={`${routePaths.parts}/${id}`}>
                <Typography
                  color="link"
                  data-attempt={String(attemptNum)}
                  decoration="underline"
                  paddingLeft={{ lg: '0.4rem', sm: '0' }}
                  text={`#${id}`}
                />
              </Link>
            </Conditional>
            <Cell paddingLeft={{ lg: 's1', sm: '0.4rem' }}>
              <PartStatusBadge status={status} />
            </Cell>
          </Fragment>
        );
      })}
    </Grid>
  );
};
