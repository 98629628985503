import { Grid, Stack } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledStack = styled(Stack)`
  ${({ theme }) => {
    return css`
      border: 1px solid ${theme.colors.dividers.t1};
      border-radius: 4px;
      overflow: hidden;
    `;
  }}
`;

export const StyledGrid = styled(Grid)`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.surfaces.monochrome.t1Alt};
      border-bottom: 1px solid ${theme.colors.dividers.t1};
    `;
  }}
`;
