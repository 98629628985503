import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Tooltip } from 'gantri-components';
import { StyledAccountSettingsWrapper } from './account-dropdown.styles';
import { AccountDropdownProps } from './account-dropdown.types';
import { UserSettingsDropdown } from './components/user-settings-dropdown';
import { UserSettingsToggle } from './components/user-settings-toggle';
import { userAtoms } from '../../../global-atoms/users';

export const AccountDropdown: FC<PropsWithChildren<AccountDropdownProps>> = (
  props,
) => {
  const { showName } = props;

  const theme = useRecoilValue(userAtoms.theme);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    document.body.setAttribute('data-theme', theme || 'light');
  }, [theme]);

  return (
    <StyledAccountSettingsWrapper showName={showName}>
      <Tooltip
        Component={<UserSettingsDropdown theme={theme} />}
        position="bottom-start"
        triggerEvent="click"
        onClose={setIsOpen}
        onOpen={setIsOpen}
      >
        <UserSettingsToggle isOpen={isOpen} showName={showName} />
      </Tooltip>
    </StyledAccountSettingsWrapper>
  );
};
