import styled from 'styled-components';

export const StyledToggleWrapper = styled.div<{
  showName: boolean;
}>`
  cursor: pointer;
  ${({ showName }) => {
    return !showName && 'display: inline-block;';
  }}

  span {
    top: unset;
  }
`;
