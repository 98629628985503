import { VendorsInventoryType } from '../../../../api/vendors/routes/get-paginated-vendors/get-paginated-vendors.types';
import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
} from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../../helpers/get-generic-atom-family';
import { SortBy } from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { VendorsSortingField } from './vendors-filters.types';

export const vendorsFiltersDefaults: {
  filters: {
    hasDelayedShipping: boolean;
    inventoryType: VendorsInventoryType;
    isActive: boolean;
  };
  sortBy: SortBy<VendorsSortingField>;
} = {
  filters: {
    hasDelayedShipping: false,
    inventoryType: null,
    isActive: false,
  },
  sortBy: {
    sortingField: 'name',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'VENDORS_FILTERS';

export const pageName: AtomPageName = 'vendors';

export const vendorsPageAtoms = {
  defaults: vendorsFiltersDefaults,
  filters: {
    hasDelayedShipping: getGenericAtomFamily({
      defaultValue: vendorsFiltersDefaults.filters.hasDelayedShipping,
      key: `${atomKeyPrefix}-has-delayed-shipping`,
    })(pageName),
    inventoryType: getGenericAtomFamily({
      defaultValue: vendorsFiltersDefaults.filters.inventoryType,
      key: `${atomKeyPrefix}-inventory-type`,
    })(pageName),
    isActive: getGenericAtomFamily({
      defaultValue: vendorsFiltersDefaults.filters.isActive,
      key: `${atomKeyPrefix}-is-active`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<VendorsSortingField>>({
    defaultValue: vendorsFiltersDefaults.sortBy,
  })(pageName),
};
