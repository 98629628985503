import { Form, Formik } from 'formik';
import { Modal } from 'gantri-components';
import { pluralize } from '../../../../../../helpers/pluralize';
import { useResetRecoilAtomsOnMount } from '../../../../../../hooks/use-reset-recoil-atoms-on-mount';
import {
  AddStocksFormData,
  AddStocksModalProps,
} from './add-stocks-modal.types';
import { addStocksModalAtoms } from './add-stocks-modal.atoms';
import {
  addStocksFormInitialValues,
  getAddStocksFormSchema,
} from './add-stocks-modal.schema';
import { AddStocksModalFooter } from './components/add-stocks-modal-footer';
import { AddStocksModalContent } from './components/add-stocks-modal-content';
import { useCreateStocks } from '../../../../../../api/stocks/routes/create-stocks/create-stocks.query';
import { useInvalidateFetchStockSummaryCache } from '../../../../../../api/stocks/routes/fetch-stock-summary/fetch-stock-summary.query';

export const AddStocksModal = (props: AddStocksModalProps) => {
  const { onClose, stocks } = props;

  const numStocks = stocks.length;

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(addStocksModalAtoms);

  const { invalidateFetchStockSummaryCache } =
    useInvalidateFetchStockSummaryCache();

  const { isLoading, onCreateStocks } = useCreateStocks({
    onSuccess: async () => {
      onClose();

      await invalidateFetchStockSummaryCache();
    },
  });

  const handleAddStocks = async ({ stocksToAdd }: AddStocksFormData) => {
    await onCreateStocks(
      stocks.map(({ productId, sku }) => {
        return {
          productId,
          quantity: stocksToAdd,
          sku,
        };
      }),
    );
  };

  return (
    <ResetAtomsWrapper>
      <Formik
        enableReinitialize
        initialValues={addStocksFormInitialValues}
        validateOnChange
        validateOnMount
        validationSchema={getAddStocksFormSchema(numStocks)}
        onSubmit={handleAddStocks}
      >
        <Form>
          <Modal
            footer={
              <AddStocksModalFooter processing={isLoading} onClose={onClose} />
            }
            header={`Add ${pluralize('Stock', numStocks, {
              plural: `Stock (${numStocks})`,
            })}`}
            width={{ lg: '46.4rem', md: '100%' }}
            onClose={onClose}
          >
            <AddStocksModalContent stocks={stocks} />
          </Modal>
        </Form>
      </Formik>
    </ResetAtomsWrapper>
  );
};
