import { Modal, Stack } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useResetRecoilAtomsOnMount } from '../../../../../../hooks/use-reset-recoil-atoms-on-mount';
import { useHandleCloseModal } from '../../../../../jobs/components/modals/hooks/use-handle-close-modal';
import { discardStockModalAtoms } from './discard-stock-modal.atoms';
import { DiscardStockModalProps } from './discard-stock-modal.types';
import { DiscardStockModalContent } from './components/discard-stock-modal-content';
import { DiscardStockModalFooter } from './components/discard-stock-modal-footer';
import { DiscardStockModalHeader } from './components/discard-stock-modal-header';

export const DiscardStockModal = (props: DiscardStockModalProps) => {
  const { onClose, onRefreshStock, orderId, stockId } = props;

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    discardStockModalAtoms,
  );

  const updateOnClose = useRecoilValue(discardStockModalAtoms.updateOnClose);

  const handleCloseModal = useHandleCloseModal({
    onClose,
    onUpdate: onRefreshStock,
    updateOnClose,
  });

  return (
    <ResetAtomsWrapper>
      <Modal
        footer={
          <DiscardStockModalFooter
            handleCloseModal={handleCloseModal}
            stockId={stockId}
          />
        }
        header={<DiscardStockModalHeader />}
        maxWidth={{ lg: '45.2rem', md: '100%' }}
        onClose={handleCloseModal}
      >
        <Stack alignContent="center" minHeight="13.6rem">
          <DiscardStockModalContent orderId={orderId} />
        </Stack>
      </Modal>
    </ResetAtomsWrapper>
  );
};
