import * as yup from 'yup';
import { optional, required } from '../../../../helpers/validators';

export const addressSchema = yup.object().shape({
  city: required(),
  company: optional(),
  country: required(),
  firstName: required(),
  lastName: required(),
  phone: required(),
  state: required(),
  street: required(),
  unit: optional(),
  zip: required(),
});
