import { Conditional, Flex, Typography } from 'gantri-components';
import { StyledAnchor } from '../../../../../../../../components/common/styled-anchor';
import routePaths from '../../../../../../../../config/route-paths';
import { MachineDetailsPropsDef } from './machine-details.types';

export const MachineDetails = (props: MachineDetailsPropsDef) => {
  const { bay, id, name, target } = props;

  return (
    <Flex gap=".5x" width="auto" wrap="wrap">
      <Conditional condition={!!id} Fallback={<Typography text={name} />}>
        <StyledAnchor
          href={`${routePaths.machines}/${id}`}
          target={target}
          text={name}
        />
      </Conditional>

      <Conditional condition={!!bay}>
        <Typography color="t2" text={bay} />
      </Conditional>
    </Flex>
  );
};
