import { Box, Modal, Stack } from 'gantri-components';
import { Form, Formik } from 'formik';
import {
  ChangeUserTypeModalFormDataDef,
  UserTypeModalProps,
} from './user-type-modal.types';
import {
  useInvalidateFetchPaginatedUsersCache,
  useInvalidateFetchUserCache,
  useInvalidateGetAdminUserCache,
  useSetUserType,
} from '../../../api/users/routes';
import { changeUserTypeModalFormSchema } from './user-type-modal.schema';
import { changeUserTypeModalSteps } from './user-type-modal.constants';
import { ChangeUserTypeModalContent } from './components/change-user-type-modal-content';
import { ChangeUserTypeModalFooter } from './components/change-user-type-modal-footer';

export const UserTypeModal = (props: UserTypeModalProps) => {
  const { currentUser, onClose } = props;

  const { invalidateFetchPaginatedUsersCache } =
    useInvalidateFetchPaginatedUsersCache();
  const { invalidateFetchUserCache } = useInvalidateFetchUserCache();
  const { invalidateGetAdminUserCache } = useInvalidateGetAdminUserCache();

  const { onSetUserType } = useSetUserType({
    onSuccess: async () => {
      onClose();

      await invalidateFetchUserCache();
      await invalidateFetchPaginatedUsersCache();
      await invalidateGetAdminUserCache();
    },
  });

  const onConfirm = async ({
    isSander,
    type,
  }: ChangeUserTypeModalFormDataDef) => {
    await onSetUserType({
      isSander,
      type,
      userId: currentUser.userId,
    });
  };

  const initialValues: ChangeUserTypeModalFormDataDef = {
    isSander: !!currentUser.isSander,
    step: changeUserTypeModalSteps.update,
    type: currentUser.accountType,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange
      validationSchema={changeUserTypeModalFormSchema}
      onSubmit={onConfirm}
    >
      {() => {
        return (
          <Form>
            <Modal
              footer={
                <ChangeUserTypeModalFooter
                  initialValues={initialValues}
                  onClose={onClose}
                />
              }
              header="Change user type"
              width={{ lg: '40rem', md: '100%' }}
              onClose={onClose}
            >
              <Box height="100%" minHeight="8.8rem">
                <Stack alignContent="center">
                  <ChangeUserTypeModalContent initialValues={initialValues} />
                </Stack>
              </Box>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
