import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  Cell,
  Conditional,
  Icon,
  Stack,
  Table,
  Tooltip,
  Typography,
} from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import MetaData from '../../components/meta-data';
import { PageHeading } from '../../components/layout/page-heading';
import { useGetNewStocksTableDetails } from './hooks/use-get-new-stocks-table-details';
import { newStocksAtoms } from './new-stocks.atoms';
import { useResetRecoilAtoms } from '../../hooks/use-reset-recoil-atoms';
import { useHandleCreateNewStocks } from './hooks/use-handle-create-new-stocks';
import { useIsEditingRow } from './hooks/use-get-new-stocks-table-details/hooks/use-is-editing-row';
import { getTemplateEditDisabledReason } from '../../components/common/jobs-template-table/helpers/get-template-edit-disabled-reason';
import { selectProductFormAtoms } from '../../components/common/select-product-form/select-product-form.atoms';
import { OnSubmitSelectProductForm } from '../../components/common/select-product-form/select-product-form.types';
import { NewStockItem } from './new-stocks.types';
import { SelectProductForm } from '../../components/common/select-product-form';

export const NewStocks = () => {
  const resetNewStocksTable = useResetRecoilAtoms(newStocksAtoms);

  const allProducts = useRecoilValue(selectProductFormAtoms.allProducts);
  const setNewStocks = useSetRecoilState(newStocksAtoms.newStocks);

  const { isEditingPage } = useIsEditingRow();

  const handleCreateNewStocks = useHandleCreateNewStocks();

  const { columns, tableData } = useGetNewStocksTableDetails();

  const showTable = !!tableData.length && !!allProducts.length;

  const onSubmit: OnSubmitSelectProductForm = (values) => {
    const id = uuidv4();
    const newStock: NewStockItem = { ...values, id };

    setNewStocks((stocks) => {
      return [...stocks, newStock];
    });
  };

  return (
    <>
      <MetaData title="New Stocks" />
      <PageHeading title="New Stocks">
        <Conditional condition={showTable}>
          <Button
            icon={<Icon color="link" name="ui-control:x_small" />}
            text="Clear All"
            variant="secondary"
            onClick={resetNewStocksTable}
          />
        </Conditional>
      </PageHeading>

      <Box paddingBottom="7x" paddingTop="3x">
        <SelectProductForm
          showActiveProductsToggle
          showQuantity
          onSubmit={onSubmit}
        />
      </Box>

      <Conditional condition={showTable}>
        <Stack>
          <Typography text="Items" variant="h4" />
          <Table columns={columns} data={tableData} />
          <Cell justifySelf="start">
            <Tooltip
              description={getTemplateEditDisabledReason({
                isEditing: isEditingPage,
              })}
              position="top-start"
            >
              <Button
                disabled={isEditingPage}
                text="Create new stocks"
                onClick={handleCreateNewStocks}
              />
            </Tooltip>
          </Cell>
        </Stack>
      </Conditional>
    </>
  );
};
