import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useNotification } from '../../../../hooks/useNotification';
import {
  UpdateAddressArgs,
  UpdateAddressResponse,
} from './update-address.types';
import { updateAddress } from './update-address';

export const useUpdateAddressMutation = (options?: {
  onSuccess?: (response: UpdateAddressResponse['data']) => void;
}) => {
  const { notify, notifyAxiosError } = useNotification();

  const { isLoading, onMutate } = useQueryMutation<
    UpdateAddressArgs,
    UpdateAddressResponse
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to update address.',
        });
      },
      onSuccess: async (response) => {
        notify(response.notice);
        options?.onSuccess?.(response.data);
      },
    },
    mutationFn: async (variables) => {
      return updateAddress(variables).then((response) => {
        return response.data;
      });
    },
  });

  return { isLoading, onUpdateAddress: onMutate };
};
