import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  UpdateCutSheetArgsDef,
  UpdateCutSheetResponseDef,
} from './update-cut-sheet.types';

export const updateCutSheet = (props: UpdateCutSheetArgsDef) => {
  const { productId, ...body } = props;

  return axios
    .put<UpdateCutSheetResponseDef>(
      `${getCoreApiUrl('products')}/${productId}/update-cut-sheet`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
