import { productColorsMap } from 'gantri-components';
import routePaths from '../../../../config/route-paths';
import { formatDate } from '../../../../helpers/formatter';
import { formatAsLinkForCSV } from '../../../../hooks/use-get-download-menu-option/helpers/format-as-link-for-csv';
import { getAdminUrl } from '../../../../helpers/get-admin-url';
import { DOWNLOAD_LONG_FORMAT } from '../../../../constants/dates';
import { newlineSeparator } from '../../../../hooks/use-get-download-menu-option/helpers/download-csv';
import { DownloadColumn } from '../../../../hooks/use-get-download-menu-option/helpers/download-csv/download-csv.types';
import { Stock } from '../../../../api/stocks/stocks.types';

export const downloadColumns: DownloadColumn<Stock>[] = [
  {
    getValue: ({ id }) => {
      return formatAsLinkForCSV({
        text: `#${id}`,
        url: `${getAdminUrl() + routePaths.stocks}/${id}`,
      });
    },
    header: 'Stock #',
  },
  {
    getValue: ({ createdAt }) => {
      return formatDate(createdAt, DOWNLOAD_LONG_FORMAT);
    },
    header: 'Created',
  },
  {
    getValue: ({ completedBy }) => {
      return formatDate(completedBy, DOWNLOAD_LONG_FORMAT);
    },
    header: 'Complete by',
  },
  {
    getValue: ({ completedAt }) => {
      return formatDate(completedAt, DOWNLOAD_LONG_FORMAT);
    },
    header: 'Completed',
  },
  {
    getValue: ({ status }) => {
      return status;
    },
    header: 'Status',
  },
  {
    getValue: ({ totalCompletionJobs }) => {
      return totalCompletionJobs;
    },
    header: 'Completion',
  },
  {
    getValue: ({ stockYield }) => {
      return String(stockYield ?? '')?.replace(/(\d+)%?$/g, '$1%');
    },
    header: 'Yield',
  },
  {
    getValue: ({ stockProduct }) => {
      return stockProduct.name;
    },
    header: 'Item',
  },
  {
    getValue: ({ stockProduct }) => {
      return stockProduct.category;
    },
    header: 'Category',
  },
  {
    getValue: ({ location }) => {
      return [location?.rack, location?.shelf, location?.bin]
        .filter(Boolean)
        .join(newlineSeparator);
    },
    header: 'VLM Assignment',
  },
  {
    getValue: ({ color }) => {
      return productColorsMap[color]?.shortColorName;
    },
    header: 'Color',
  },
  {
    getValue: ({ stockProduct }) => {
      return stockProduct?.options?.sizes?.[0]?.name;
    },
    header: 'Size',
  },
  {
    getValue: ({ item }) => {
      return item;
    },
    header: 'SKU',
  },
  {
    getValue: ({ orderId, orderType }) => {
      return orderId
        ? formatAsLinkForCSV({
            text: `${orderType} #${orderId}`,
            url: `${getAdminUrl() + routePaths.orders}/${orderId}`,
          })
        : '';
    },
    header: 'Order #',
  },
  {
    getValue: ({ attentions }) => {
      const numAlerts = attentions?.length;

      return !!numAlerts ? numAlerts : '';
    },
    header: 'Attention #',
  },
  {
    getValue: ({ attentions }) => {
      return attentions
        .map(({ description, name }) => {
          return description || name;
        })
        .join(newlineSeparator);
    },
    header: 'Attention Notes',
  },
];
