import {
  Cell,
  Flex,
  Icon,
  Tooltip,
  Typography,
  useModal,
} from 'gantri-components';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import { LONG_FORMAT } from '../../../../constants/dates';
import { formatDate } from '../../../../helpers/formatter';
import { UpdatePartReleaseRateModal } from './components/update-part-release-rate-modal';
import { StyledSummaryGrid } from './part-release-rate-summary.styles';
import { PartReleaseRateSummaryPropsDef } from './part-release-rate-summary.types';
import { ViewHistoryButton } from './components/view-history-button';
import { PartReleaseRateTimelineItem } from './components/part-release-rate-timeline-item';

export const PartReleaseRateSummary = (
  props: PartReleaseRateSummaryPropsDef,
) => {
  const { lastUpdatedAt, numBufferedParts, partsPerDay, timeline } = props;

  const [showUpdatePartReleaseRateModal, hideUpdatePartReleaseRateModal] =
    useModal(() => {
      return (
        <UpdatePartReleaseRateModal
          initialPartsPerDay={partsPerDay}
          onClose={hideUpdatePartReleaseRateModal}
        />
      );
    }, [partsPerDay, numBufferedParts]);

  return (
    <StyledSummaryGrid
      alignItems="center"
      columns="1fr max-content"
      padding="2x"
    >
      <Cell>
        <Typography
          icon={
            <Tooltip
              maxWidth="22.6rem"
              position="top-start"
              title="The maximum number of painted parts (and their print jobs) released per day."
            >
              <Icon name="alert:i_circle" />
            </Tooltip>
          }
          iconPosition="right"
          text="Painted parts released"
        />
        <Flex alignItems="baseline" gap="x" paddingBottom="x" paddingTop=".5x">
          <Typography text={`${partsPerDay} parts per day`} variant="h5" />
          <StyledAnchor
            text="Update"
            onClick={showUpdatePartReleaseRateModal}
          />
        </Flex>
        <Typography
          text={`Last updated ${formatDate(lastUpdatedAt, LONG_FORMAT)}`}
          variant="p3"
        />
      </Cell>

      <Cell>
        <ViewHistoryButton
          loadHistory={async () => {
            return timeline;
          }}
          renderItem={PartReleaseRateTimelineItem}
        />
      </Cell>
    </StyledSummaryGrid>
  );
};
