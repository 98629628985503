import { FC } from 'react';
import {
  Typography,
  FileUploader,
  HandleDeleteFile,
  HandleUploadsComplete,
} from 'gantri-components';
import { ImagerySectionProps } from './imagery-section.types';
import {
  fileRecoveredMessage,
  useCloudinaryFileUploader,
} from '../../../../../../hooks/use-cloudinary-file-uploader';
import { useNotification } from '../../../../../../hooks/useNotification';

export const ImagerySection: FC<ImagerySectionProps> = (props) => {
  const { designerInfo, updateDesignerInfo } = props;

  const { notify } = useNotification();

  const handleUploadsComplete: HandleUploadsComplete = async ([
    { fileName },
  ]) => {
    updateDesignerInfo({
      key: 'handout',
      type: 'key',
      value: {
        ...designerInfo.handout,
        backImage: fileName,
      },
    });
  };

  const onFileRecovered = async (updatedFileName: string) => {
    notify(fileRecoveredMessage.saveRequired, {
      keepOpen: true,
    });

    updateDesignerInfo({
      key: 'handout',
      type: 'key',
      value: {
        ...designerInfo.handout,
        backImage: updatedFileName,
      },
    });
  };

  const onFileDelete: HandleDeleteFile = async () => {
    updateDesignerInfo({
      key: 'handout',
      type: 'key',
      value: {
        ...designerInfo.handout,
        backImage: '',
      },
    });
  };

  const { fileUploaderProps } = useCloudinaryFileUploader<'designers'>({
    directory: 'designers',
    fileName: designerInfo.handout.backImage,
    fileType: 'handouts',
    handleUploadsComplete,
    identifiers: {
      userId: designerInfo.userId,
    },
    onFileDelete,
    onFileRecovered,
    transformFileName: ({ applyUuid, removeWhitespace }) => {
      return applyUuid(removeWhitespace('back-image'));
    },
  });

  return (
    <>
      <Typography
        marginBottom="1rem"
        marginTop="4rem"
        text="Imagery"
        textStyle="bold"
        variant="h4"
      />
      <Typography
        marginBottom="1rem"
        text="Backside image"
        textStyle="bold"
        variant="p2"
      />
      <div style={{ zIndex: 0 }}>
        <FileUploader {...fileUploaderProps} variant="thumbnail" />
      </div>
    </>
  );
};
