import { Conditional, Stack } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import {
  InfoTabHandouts,
  InfoTabMarketplaceConfiguration,
  InfoTabBulbChange,
  InfoTabDesigns,
  InfoTabAbout,
  InfoTabSpecifications,
  InfoTabAwards,
  MarketplaceSkusOverview,
} from './components';
import { productAtoms } from '../../product.atoms';
import { SimpleErrorBoundary } from '../../../../components/simple-error-boundary';
import { InfoTabDownloads } from './components/info-tab-downloads';
import { useResetRecoilAtoms } from '../../../../hooks/use-reset-recoil-atoms';
import { productInfoTabAtoms } from './info.atoms';

export const Info = () => {
  const isAccessory = useRecoilValue(productAtoms.isAccessory);

  const resetInfoTabAtoms = useResetRecoilAtoms(productInfoTabAtoms);

  useEffect(() => {
    return () => {
      resetInfoTabAtoms();
    };
  }, []);

  return (
    <Stack gap="7x" paddingBottom="5x">
      <SimpleErrorBoundary>
        <InfoTabAbout />
      </SimpleErrorBoundary>

      <Conditional condition={!isAccessory}>
        <SimpleErrorBoundary>
          <MarketplaceSkusOverview />
        </SimpleErrorBoundary>
      </Conditional>

      <SimpleErrorBoundary>
        <InfoTabMarketplaceConfiguration />
      </SimpleErrorBoundary>

      <Conditional condition={!isAccessory}>
        <SimpleErrorBoundary>
          <InfoTabAwards />
        </SimpleErrorBoundary>
      </Conditional>

      <SimpleErrorBoundary>
        <InfoTabSpecifications />
      </SimpleErrorBoundary>

      <Conditional condition={!isAccessory}>
        <SimpleErrorBoundary>
          <InfoTabBulbChange />
        </SimpleErrorBoundary>

        <SimpleErrorBoundary>
          <InfoTabDownloads />
        </SimpleErrorBoundary>

        <SimpleErrorBoundary>
          <InfoTabDesigns />
        </SimpleErrorBoundary>
      </Conditional>

      <SimpleErrorBoundary>
        <InfoTabHandouts />
      </SimpleErrorBoundary>
    </Stack>
  );
};
