import {
  Conditional,
  Flex,
  Icon,
  Stack,
  Typography,
  useModal,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { productAtoms } from '../../../../../../product.atoms';
import { StyledAnchor } from '../../../../../../../../components/common/styled-anchor';
import { ConfigureCutSheetsModal } from './components/configure-cut-sheets-modal';
import { DownloadCutSheetButton } from './components/download-cut-sheet-button';

export const CutSheetsSection = () => {
  const product = useRecoilValue(productAtoms.product);

  const isConfigured = product.downloads?.cutSheet?.isConfigured;

  const [showConfigureCutSheetsModal, hideConfigureCutSheetsModal] =
    useModal(() => {
      return <ConfigureCutSheetsModal onClose={hideConfigureCutSheetsModal} />;
    }, []);

  return (
    <Stack gap="x">
      <Conditional
        condition={isConfigured}
        Fallback={
          <Flex
            alignItems="baseline"
            gap="2x"
            style={{ paddingLeft: '1.2rem' }}
          >
            <Typography text="Not configured" />
            <StyledAnchor
              text="Configure"
              onClick={showConfigureCutSheetsModal}
            />
          </Flex>
        }
      >
        <Flex alignItems="baseline" gap="2x" style={{ paddingLeft: '1.2rem' }}>
          <Typography
            icon={<Icon color="t2" name="ui-control:check_mark" />}
            text="Configured"
          />
          <StyledAnchor text="Update" onClick={showConfigureCutSheetsModal} />
        </Flex>
      </Conditional>

      <DownloadCutSheetButton disabled={!isConfigured} />
    </Stack>
  );
};
