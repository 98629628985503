const labelColumnSize = '16rem';

export const jobsBlockGridColumns = `${labelColumnSize} 1fr`;

export const detailsGridColumns = `${labelColumnSize} 40rem`;

/** These are the default values entered for brand new job block templates. */
export const defaultJobBlockDurations = {
  assembleStage: 5,
  packPack: 10,
  qaQc: 10,
  sandPrimed: 5,
  sandRaw: 10,
} as const;
