import { Typography } from 'gantri-components';
import styled, { css, CSSProperties } from 'styled-components';

export const StyledTruncatedTypography = styled(Typography)<{
  width?: CSSProperties['width'];
}>`
  ${({ width = '20rem' }) => {
    return css`
      width: ${width};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
  }}
`;
