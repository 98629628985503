import { FC } from 'react';
import { Button, Icon } from 'gantri-components';
import {
  orderStatusesMap,
  shipmentStatuses,
  transactionTypesMap,
} from '../../../../../../constants/options';
import { OrderShipmentActionButtonProps } from './order-shipment-action-button.types';
import { StyledIconButton } from './order-shipment-action-button.styles';
import { getCanDeliver, isAbleToShipShipment } from '../../../../adapter';
import { transactionsApi } from '../../../../../../api';
import { useNotification } from '../../../../../../hooks/useNotification';
import { useInvalidateFetchOrderCache } from '../../../../../../api/transactions/routes';
import {
  shipmentActionsModalSteps,
  useShipmentActionsModal,
} from '../../../shipment-actions-modal';

export const shouldShipmentStatusShowLabelBtn = (shipmentStatus: string) => {
  return [
    shipmentStatuses.labelCreated,
    shipmentStatuses.preTransit,
    shipmentStatuses.unknown,
  ].some((showBtnStatus) => {
    return showBtnStatus === shipmentStatus;
  });
};

export const OrderShipmentActionButton: FC<OrderShipmentActionButtonProps> = (
  props,
) => {
  const { currentOrderData, id, labelUrls, status, stocks } = props;

  const { address, status: orderStatus, type } = currentOrderData;

  const { notify, notifyAxiosError, onInterceptRequest } = useNotification();

  const { openShipmentActionsModal } = useShipmentActionsModal({
    order: currentOrderData,
  });

  const { invalidateFetchOrderCache } = useInvalidateFetchOrderCache();

  const openLabelDownloads = () => {
    labelUrls.forEach((fileUrl) => {
      const link = document.createElement('a');

      link.href = fileUrl;
      link.download = `file-${Date.now()}.jpg`;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(fileUrl);

      document.body.removeChild(link);
    });
  };

  if (labelUrls && shouldShipmentStatusShowLabelBtn(status)) {
    return (
      <StyledIconButton
        icon={<Icon name="arrows:arrow_download" size="1.6rem" />}
        text={`Label${labelUrls.length > 1 ? 's' : ''}`}
        variant="secondary"
        onClick={openLabelDownloads}
      />
    );
  }

  const disableShipButton = !isAbleToShipShipment(
    { status, stocks },
    orderStatus,
  );

  const { doNotShip } = address;

  const canDeliverToStatus = getCanDeliver(orderStatus);

  const deliverManually = async () => {
    await onInterceptRequest(async () => {
      try {
        const { data } = await transactionsApi.deliverManually(id);

        notify(data.notice);

        await invalidateFetchOrderCache();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to mark order as delivered.',
        });
      }
    });
  };

  // TODO: has no sense, never turns to true
  const deliveredMarketingOrRND =
    orderStatusesMap.delivered === orderStatus &&
    [transactionTypesMap.rnd, transactionTypesMap.marketing].some(
      (marketingOrRnD) => {
        return marketingOrRnD === type;
      },
    );

  if (
    orderStatusesMap.shipped === orderStatus ||
    (doNotShip && canDeliverToStatus)
  ) {
    return (
      <Button
        disabled={deliveredMarketingOrRND}
        text="Deliver"
        onClick={deliverManually}
      />
    );
  }

  return (
    <Button
      disabled={disableShipButton}
      text="Ship"
      onClick={() => {
        openShipmentActionsModal({
          isDoNotShip: undefined,
          shipmentId: id,
          step: shipmentActionsModalSteps.shipNormally,
        });
      }}
    />
  );
};
