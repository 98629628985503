import { CloudinaryResourceType } from 'gantri-components';
import { cloudinaryUriBasePattern } from '../../cloudinary.constants';

const cloudinaryDeletedFilesPath = 'deleted-files/';

export const getDeletionPublicIdsFromUrl = (fileUrl: string) => {
  const resourceType = getResourceTypeFromUrl(fileUrl);

  const publicIdBase = fileUrl.replace(cloudinaryUriBasePattern, '');
  const publicId =
    resourceType === 'raw' ? publicIdBase : clearExtension(publicIdBase);

  const fromPublicId = publicId;

  const toPublicId = cloudinaryDeletedFilesPath + fromPublicId;

  return { fromPublicId, publicId, resourceType, toPublicId };
};

export const getRecoveryPublicIdsFromUrl = (fileUrl: string) => {
  const resourceType = getResourceTypeFromUrl(fileUrl);

  const publicIdBase = fileUrl.replace(cloudinaryUriBasePattern, '');
  const publicId =
    resourceType === 'raw' ? publicIdBase : clearExtension(publicIdBase);

  const fromPublicId = cloudinaryDeletedFilesPath + publicId;

  // ? Updating the timestamp ensures that the local and cloudinary cache for the file is busted.
  // ? The updated file name MUST be saved to the database after this action is performed.
  const toPublicId = publicId.replace(/_\d+$/, `_${Date.now()}`);

  return { fromPublicId, resourceType, toPublicId };
};

export const getResourceTypeFromUrl = (
  fileUrl: string,
): CloudinaryResourceType => {
  const resourceTypePattern = /.+\/(\w+)\/upload\/.+/gi;
  const resourceType = fileUrl.replace(
    resourceTypePattern,
    '$1',
  ) as CloudinaryResourceType;

  return resourceType;
};

export const clearExtension = (publicId: string) => {
  const extensionPattern = /\.[^.]+$/;

  return publicId.replace(extensionPattern, '');
};
