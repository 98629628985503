import {
  SetInventoryTargetArgs,
  SetInventoryTargetResponse,
} from './set-inventory-target.types';
import { setInventoryTarget } from './set-inventory-target';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';

export const useSetInventoryTarget = <
  TransformedData = SetInventoryTargetResponse,
>(
  props: GenericMutateQueryProps<
    SetInventoryTargetArgs,
    SetInventoryTargetResponse,
    TransformedData
  >,
) => {
  const { onMutate: onSetInventoryTarget, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to set inventory target.',
    mutationFn: setInventoryTarget,
    ...props,
  });

  return { ...rest, onSetInventoryTarget };
};
