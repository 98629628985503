import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { StockJobsAssembleInventoryFormData } from '../../../../stock-jobs-assemble-inventory.schema';
import { StockJobVariantInventory } from '../../../../../../../../../../../../api/products/products.types';
import { useFetchGlobalVariants } from '../../../../../../../../../../../../api/global-variants/routes';

export const useInventoriesByVariants = () => {
  const [inventoriesByVariants, setInventoriesByVariants] = useState<
    Record<number, Record<string, StockJobVariantInventory[]>>
  >({});

  const { values } = useFormikContext<StockJobsAssembleInventoryFormData>();

  const { assembleVariantInventories, variantIds } = values;

  const { data, isLoading } = useFetchGlobalVariants();

  const globalVariants = data?.globalVariants || [];

  useEffect(() => {
    if (globalVariants.length) {
      const inventoriesByVariants = assembleVariantInventories.reduce<
        Record<number, Record<string, StockJobVariantInventory[]>>
      >((accumulator, inventory) => {
        const { globalVariantId, variantCode } = inventory;

        const existingInventories =
          accumulator?.[globalVariantId]?.[variantCode] || [];

        return {
          ...accumulator,
          [globalVariantId]: {
            ...accumulator?.[globalVariantId],
            [variantCode]: [...existingInventories, inventory],
          },
        };
      }, {});

      variantIds.forEach((variantId) => {
        const matchingVariantData = globalVariants.find(({ id }) => {
          return id === variantId;
        });

        const updatedInventoriesForVariant = matchingVariantData.options.reduce<
          Record<string, StockJobVariantInventory[]>
        >((accumulator, { code }) => {
          // ! accounts for when no inventories currently assigned to the variant code
          const inventoriesForVariantCode =
            inventoriesByVariants?.[variantId]?.[code] || [];

          return { ...accumulator, [code]: inventoriesForVariantCode };
        }, {});

        inventoriesByVariants[variantId] = updatedInventoriesForVariant;
      });
      setInventoriesByVariants(inventoriesByVariants);
    }
  }, [globalVariants, variantIds, assembleVariantInventories]);

  return {
    globalVariants,
    /**
     * Structure:
     * ```
     * {
     *   [globalVariantId]: {
     *     [variantCode]: stockJobVariantInventory[]
     *   }
     * }
     * ```
     */
    inventoriesByVariants,
    isLoading,
  };
};
