import { Box, Conditional, Stack, Typography } from 'gantri-components';
import { LONG_FORMAT } from '../../../../../../constants/dates';
import { formatDate } from '../../../../../../helpers/formatter';
import TimelineUserInfo from '../../../../../../components/common/timeline/user-info';
import { Label } from '../../../../../../components/label';
import { PartReleaseRateTimelineItemPropsDef } from './part-release-rate-timeline-item.types';

export const PartReleaseRateTimelineItem = (
  props: PartReleaseRateTimelineItemPropsDef,
) => {
  const {
    createdAtSec,
    description,
    email,
    firstName,
    index,
    lastName,
    partCount,
    userId,
  } = props;

  return (
    <Stack gap="x">
      <Conditional condition={index === 0}>
        <Label text="Current value" />
      </Conditional>
      <Box>
        <Typography
          display="inline"
          text={`Updated ${formatDate(createdAtSec, LONG_FORMAT)}${
            userId ? ' by' : ''
          }`}
        />

        <Conditional condition={!!userId}>
          &nbsp;
          <TimelineUserInfo
            email={email}
            firstName={firstName}
            lastName={lastName}
            userId={userId}
          />
        </Conditional>
      </Box>

      <Box>
        <Typography
          color="t2"
          htmlText={`Updated to ${partCount} parts per day`}
        />
        <Typography
          color="t2"
          htmlText={description?.replace(/\n/g, '<br />')}
        />
      </Box>
    </Stack>
  );
};
