import { Button } from 'gantri-components';
import { useFormikContext } from 'formik';
import { NewProductModalFormData } from '../../new-product-modal.type';
import { NewProductModalFooterProps } from './new-product-modal-footer.types';

export const NewProductModalFooter = (props: NewProductModalFooterProps) => {
  const { onClose, processing } = props;

  const { isValid } = useFormikContext<NewProductModalFormData>();

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={onClose}
      />
      <Button
        disabled={!isValid}
        processing={processing}
        size="large"
        text="Save"
        type="submit"
      />
    </>
  );
};
