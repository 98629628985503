import { Conditional, Typography } from 'gantri-components';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../../../../helpers/formatter';
import { LONG_FORMAT } from '../../../../../../constants/dates';
import { NotApplicableText } from '../../../../../../components/common/not-applicable-text';
import { PartDetailsEndedProps } from './part-details-ended.types';
import routePaths from '../../../../../../config/route-paths';

export const PartDetailsEnded = (props: PartDetailsEndedProps) => {
  const { part } = props;

  const endedUser = part?.statusInfo?.completed?.user;
  const endedDate = `${formatDate(part?.completedAt, LONG_FORMAT)}${
    endedUser?.id ? ' by' : ''
  }`;
  const endedUserName = [endedUser?.firstName, endedUser?.lastName]
    .filter((i) => {
      return !!i;
    })
    .join(' ');

  return (
    <Conditional
      condition={!!part?.completedAt}
      Fallback={<NotApplicableText />}
    >
      <div>
        <Typography display="inline" text={endedDate} />
        <Conditional condition={!!endedUser?.id}>
          &nbsp;
          <Link to={`${routePaths.users}/${endedUser?.id}`}>
            <Typography
              color="link"
              decoration="underline"
              display="inline"
              text={endedUserName}
            />
          </Link>
        </Conditional>
      </div>
    </Conditional>
  );
};
