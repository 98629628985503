import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useNotification } from '../../../../hooks/useNotification';
import {
  CreateAddressArgs,
  CreateAddressResponse,
} from './create-address.types';
import { createAddress } from './create-address';

export const useCreateAddressMutation = (options?: {
  onSuccess?: (response: CreateAddressResponse['data']) => void;
}) => {
  const { notify, notifyAxiosError } = useNotification();

  const { isLoading, onMutate } = useQueryMutation<
    CreateAddressArgs,
    CreateAddressResponse
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to create new address.',
        });
      },
      onSuccess: async (response) => {
        notify(response.notice);
        options?.onSuccess?.(response.data);
      },
    },
    mutationFn: async (variables) => {
      return createAddress(variables).then((response) => {
        return response.data;
      });
    },
  });

  return { isLoading, onCreateAddress: onMutate };
};
