import {
  Typography,
  Icon,
  Conditional,
  FileUploaderCustomContent,
  Stack,
} from 'gantri-components';
import { bulkUploadsRestrictions } from '../../../../bulk-upload-modal.constants';
import { StyledDropZoneFlex } from './file-uploader-drop-zone.styles';

export const FileUploaderDropZone: FileUploaderCustomContent = (props) => {
  const { handleOpenFileSelect, isDisabled, isDraggingOver, processing } =
    props;

  return (
    <StyledDropZoneFlex
      $isDisabled={isDisabled || processing}
      $isDraggingOver={isDraggingOver}
      $processing={processing}
      alignItems="center"
      height="11.2rem"
      justifyContent="center"
      onClick={handleOpenFileSelect}
    >
      <Conditional
        condition={processing}
        Fallback={
          <Stack
            alignItems="center"
            gap="x"
            height="unset"
            justifyContent="center"
          >
            <Conditional
              condition={isDraggingOver}
              Fallback={
                <Typography
                  color="link"
                  icon={<Icon color="link" name="ui-control:plus" />}
                  text="Upload or Drop"
                />
              }
            >
              <Typography
                color="success"
                icon={<Icon color="success" name="arrows:arrow_upload" />}
                text="Upload or Drop"
              />
            </Conditional>
            <Typography
              color="t2"
              paddingLeft="x"
              text={`Max file upload qty: ${bulkUploadsRestrictions.maxUploadsAllowed}`}
              variant="p3"
            />
          </Stack>
        }
      >
        <Icon color="link" name="animated:loader" size="2.4rem" />
      </Conditional>
    </StyledDropZoneFlex>
  );
};
