import { useRecoilValue } from 'recoil';
import { publishDraftModalAtoms } from '../../publish-draft-modal.atoms';
import { MissingItemsContent } from '../step-missing-items/missing-items-content';
import { DraftDetailsContent } from '../step-draft-details/draft-details-content';
import { ConfirmPublishContent } from '../step-confirm-publish/confirm-publish-content';
import { PublishDraftModalContentProps } from './publish-draft-modal-content.types';

export const PublishDraftModalContent = (
  props: PublishDraftModalContentProps,
) => {
  const { product } = props;

  const step = useRecoilValue(publishDraftModalAtoms.step);

  switch (step) {
    case 'MISSING_ITEMS':
      return <MissingItemsContent />;

    case 'DRAFT_DETAILS':
      return <DraftDetailsContent product={product} />;

    case 'CONFIRM_PUBLISH':
      return <ConfirmPublishContent product={product} />;

    default:
      return null;
  }
};
