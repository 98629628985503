import * as yup from 'yup';
import { StringSchema } from 'yup';
import { isEmpty } from 'lodash';
import { required, requiredRule } from '../../../helpers/validators';

export const formattedNumberRule = (
  yupInstance: StringSchema = yup.string(),
) => {
  return yupInstance.test(
    'isDecimalNumber',
    'Required',
    (value = '', { schema }) => {
      const number = Number(value.match(/\d+.*/g)?.join('') ?? 0);
      const valid = Number.isNaN(number) ? false : number > 0;

      return schema.spec.nullable ? isEmpty(value) || valid : valid;
    },
  );
};

export const AddCreditSchema = yup.object().shape({
  credit: formattedNumberRule(requiredRule(yup.string().nullable())),
  reason: required(),
});
