import { Button, Modal } from 'gantri-components';
import { useMemo } from 'react';
import { Form, Formik } from 'formik';
import { AddOrEditPartModalContent } from './components/add-or-edit-part-modal-content';
import { schema } from './add-or-edit-part-modal.schema';
import {
  AddOrEditPartModalProps,
  AddOrEditPartModalType,
  AddOrEditPartsFormData,
} from './add-or-edit-part-modal.types';
import { getHeaderText } from './helpers/get-header-text';
import { useOnSubmit } from './hooks/use-on-submit';
import { getEmptyPart } from './helpers/get-empty-part';
import { addOrEditPartModalType } from './add-or-edit-part-modal.constants';
import { SimpleErrorBoundary } from '../../../../../../../../components/simple-error-boundary';

export const AddOrEditPartModal = <ModalType extends AddOrEditPartModalType>(
  props: AddOrEditPartModalProps<ModalType>,
) => {
  const { modalType, onClose, part } = props;

  const initialValues: AddOrEditPartsFormData = useMemo(() => {
    return part
      ? { ...part, colorOverride: part.colorOverride || [] }
      : getEmptyPart();
  }, [part]);

  const { onSubmit, processing } = useOnSubmit({
    initialValues,
    modalType,
    onClose,
  });
  const isEditModal = modalType === addOrEditPartModalType.edit;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange
      validateOnMount
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ isValid }) => {
        return (
          <Form>
            <Modal
              footer={
                <>
                  <Button
                    size="large"
                    text="Cancel"
                    variant="secondary"
                    onClick={onClose}
                  />
                  <Button
                    disabled={!isValid}
                    processing={processing}
                    size="large"
                    text={isEditModal ? 'Save Part' : 'Add Part'}
                    type="submit"
                    variant="primary"
                  />
                </>
              }
              header={getHeaderText(modalType)}
              width={{ lg: '44rem', md: '100%' }}
              onClose={onClose}
            >
              <SimpleErrorBoundary>
                <AddOrEditPartModalContent modalType={modalType} />
              </SimpleErrorBoundary>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
