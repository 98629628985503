import { Link } from 'react-router-dom';
import { Conditional, Flex, Typography } from 'gantri-components';
import routePaths from '../../../../../../config/route-paths';
import { formatDate } from '../../../../../../helpers/formatter';
import { LONG_FORMAT } from '../../../../../../constants/dates';
import { NotApplicableText } from '../../../../../../components/common/not-applicable-text';
import { PartDetailsLastActionProps } from './part-details-last-action.types';

export const PartDetailsLastAction = (props: PartDetailsLastActionProps) => {
  const { part } = props;

  const lastActionAt = part?.lastActionAt;
  const lastActionedJob = part?.lastActionData?.job;
  const lastActionedJobUser = part?.lastActionData?.user;
  const jobDetails = `${lastActionedJob?.name} (${lastActionedJob?.status})${
    lastActionedJobUser ? ' by' : ''
  }`;
  const userName = [
    lastActionedJobUser?.firstName,
    lastActionedJobUser?.lastName,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <Conditional
      condition={!!lastActionAt && !!lastActionedJob}
      Fallback={<NotApplicableText />}
    >
      <Flex gap="0.4rem" wrap="wrap">
        <Typography display="inline" text={jobDetails} />
        <Conditional condition={!!lastActionedJobUser}>
          <Link
            target="_blank"
            to={`${routePaths.users}/${lastActionedJobUser?.id}`}
          >
            <Typography
              color="link"
              decoration="underline"
              display="inline"
              text={userName}
            />
          </Link>
        </Conditional>
        <Typography
          display="inline"
          text={`on ${formatDate(lastActionAt, LONG_FORMAT)}`}
        />
      </Flex>
    </Conditional>
  );
};
