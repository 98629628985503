import { useModal } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { ShipmentActionsModalStep } from '../../shipment-actions-modal.types';
import { shipmentActionsModalAtoms } from '../../shipment-actions-modal.atoms';
import { ShipmentActionsModal } from '../../shipment-actions-modal';
import {
  UseShipmentActionsModalPropsDef,
  OpenShipmentActionsModalPropsDef,
} from './use-shipment-actions-modal.types';

export const useShipmentActionsModal = (
  props: UseShipmentActionsModalPropsDef,
) => {
  const { order } = props;

  const setShipmentId = useSetRecoilState(shipmentActionsModalAtoms.shipmentId);
  const setIsDoNotShip = useSetRecoilState(
    shipmentActionsModalAtoms.shipNormally.isDoNotShip,
  );
  const setStep = useSetRecoilState(shipmentActionsModalAtoms.step);
  const setHandleReturnAndShipManually = useSetRecoilState(
    shipmentActionsModalAtoms.shipManually.handleReturnAndShipManually,
  );

  const [showShipmentActionsModal, hideShipmentActionsModal] = useModal(() => {
    return (
      <ShipmentActionsModal order={order} onClose={hideShipmentActionsModal} />
    );
  }, [order]);

  const openShipmentActionsModal = <Step extends ShipmentActionsModalStep>(
    props: OpenShipmentActionsModalPropsDef<Step>,
  ) => {
    const { handleReturnAndShipManually, isDoNotShip, shipmentId, step } =
      props;

    if (handleReturnAndShipManually) {
      setHandleReturnAndShipManually(() => {
        return handleReturnAndShipManually;
      });
    }

    if (isDoNotShip !== undefined) {
      setIsDoNotShip(isDoNotShip);
    }

    setShipmentId(shipmentId);
    setStep(step);
    showShipmentActionsModal();
  };

  return { openShipmentActionsModal };
};
