import { useNotification } from '../../../../hooks/useNotification';
import { UseQueryProps } from '../../../../hooks/use-fetch/react-queries.types';
import {
  useInvalidateQueryByPredicate,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';
import {
  FetchPaginatedGiftCardsArgs,
  FetchPaginatedGiftCardsResponse,
} from './fetch-paginated-giftcards.types';
import { fetchPaginatedGiftCards } from './fetch-paginated-giftcards';

interface Props<TData>
  extends UseQueryProps<TData, FetchPaginatedGiftCardsResponse> {
  args?: FetchPaginatedGiftCardsArgs;
}

export const useFetchPaginatedGiftCardsQuery = (
  props?: Props<FetchPaginatedGiftCardsResponse>,
) => {
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch<FetchPaginatedGiftCardsResponse>({
    config: {
      enabled: !!props?.args,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch gift cards.',
        });
      },
      queryFn: () => {
        return fetchPaginatedGiftCards.query(props?.args);
      },
      queryKey: fetchPaginatedGiftCards.key(props?.args),
    },
    showLoading: true,
    transformDependencies: props?.transformDependencies,
  });

  return {
    ...data,
    isLoading,
  };
};

export const useInvalidateFetchPaginatedGiftCardsCache = () => {
  const invalidate = useInvalidateQueryByPredicate();

  const invalidateFetchPaginatedGiftCardsCache = (
    args?: FetchPaginatedGiftCardsArgs,
  ) => {
    invalidate((query) => {
      if (typeof query.queryKey[0] === 'string') return false;

      const { groupKey, key } = query.queryKey[0] as {
        groupKey: string;
        key: string;
      };

      return !!args
        ? key === fetchPaginatedGiftCards.key(args)[0].key
        : groupKey === fetchPaginatedGiftCards.groupKey();
    });
  };

  return { invalidateFetchPaginatedGiftCardsCache };
};
