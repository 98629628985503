import { Conditional, Typography } from 'gantri-components';
import { CellJobMachineTypeProps } from './cell-job-machine-type.types';

export const CellJobMachineType = (props: CellJobMachineTypeProps) => {
  const { getValue } = props;

  const machineType = getValue();

  return (
    <Conditional condition={!!machineType}>
      <Typography color="t1" text={machineType} />
    </Conditional>
  );
};
