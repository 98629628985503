import { useState } from 'react';
import {
  Cell,
  Grid,
  Stack,
  Typography,
  productColorsMap,
  Box,
  HandleUploadsComplete,
  FileUploader,
} from 'gantri-components';
import {
  getExtension,
  getNameWithoutExtension,
} from '../../../../../../helpers/firebase';
import SelectInput from '../../../../../../components/common/inputs/select-input';
import { useFirebaseFileUploader } from '../../../../../../hooks/use-firebase-file-uploader';
import { FinishedPrototypeItem } from './components/finished-prototyping-item';
import { FinishedPrototypesStepPropsDef } from './finished-prototypes-step.types';

export const FinishedPrototypesStep = (
  props: FinishedPrototypesStepPropsDef,
) => {
  const {
    design,
    engineering,
    prototypeUploadPath,
    recordUpdated,
    thumbnailSize,
    transformFileName,
  } = props;

  const [color, setColor] = useState('');

  const thirdStep = design?.model?.subSteps?.['3'];
  const thirdStepColors = thirdStep?.colors?.map(({ code }) => {
    return productColorsMap[code];
  });

  const onFinishedPrototypeUploaded: HandleUploadsComplete = async ([
    { fileName, fileSize, fileUrl },
  ]) => {
    const prototypes = [...engineering.finalization.finishedPrototypes];

    const name = getNameWithoutExtension(fileName);

    prototypes.push({
      color,
      fileExtension: getExtension(fileName),
      fileName: name,
      fileSize: String(fileSize),
      fileUrl,
      name,
    });

    await recordUpdated('finalization', 'finishedPrototypes', prototypes)();
    setColor('');
  };

  const { fileUploaderProps: finishedPrototypeFileUploaderProps } =
    useFirebaseFileUploader({
      fileUrl: null,
      handleUploadsComplete: onFinishedPrototypeUploaded,
      onFileDelete: null,
      path: prototypeUploadPath,
    });

  return (
    <Stack columns={1} gap="x">
      <Typography text="Finished prototypes" textStyle="bold" variant="h2" />
      <Typography
        color="t2"
        marginTop="x"
        text="Upload photos of prototypes after they finish printing"
      />

      <Grid columns={`repeat(2, ${thumbnailSize})`} gap="4x">
        {engineering.finalization.finishedPrototypes.map((item, index) => {
          return (
            <FinishedPrototypeItem
              key={item.fileUrl}
              engineering={engineering}
              index={index}
              item={item}
              prototypeUploadPath={prototypeUploadPath}
              recordUpdated={recordUpdated}
              thirdStepColors={thirdStepColors}
              thumbnailSize={thumbnailSize}
              transformFileName={transformFileName}
              onFinishedPrototypeUploaded={onFinishedPrototypeUploaded}
            />
          );
        })}
        <Cell>
          <Box marginBottom="2x">
            <FileUploader
              key={engineering.finalization.finishedPrototypes.length}
              {...finishedPrototypeFileUploaderProps}
              fileName={null}
              isUploaderOnly
              thumbnailSize={thumbnailSize}
              transformFileName={transformFileName}
              variant="thumbnail"
            />
          </Box>
          <SelectInput
            labelProperty="shortColorName"
            options={thirdStepColors}
            placeholder="Color"
            value={color}
            valueProperty="code"
            onChange={setColor}
          />
        </Cell>
      </Grid>
    </Stack>
  );
};
