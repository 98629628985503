import {
  Box,
  Conditional,
  Flex,
  Icon,
  Tooltip,
  defaultImageExtensions,
  FileUploader,
} from 'gantri-components';
import { memo } from 'react';
import { useFirebaseFileUploader } from '../../../../../../../../../../../../../../hooks/use-firebase-file-uploader/use-firebase-file-uploader';
import { isDuplicateMessage } from '../../instruction-details.constants';
import { InstructionUploaderProps } from './instruction-uploader.types';

export const InstructionUploader = memo((props: InstructionUploaderProps) => {
  const {
    disabled,
    file,
    handleUploadsComplete,
    inputName,
    isDuplicate,
    onFileDelete,
    processing,
    uploadPath,
    variant = 'plain',
  } = props;

  const { fileName, url } = file || {};

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl: url,
    handleUploadsComplete,
    onFileDelete,
    path: uploadPath,
  });

  return (
    <Flex
      alignItems="center"
      gap="x"
      justifyContent="start"
      justifySelf="start"
    >
      <Conditional condition={isDuplicate}>
        <Tooltip
          maxWidth="22rem"
          position="bottom-start"
          title={isDuplicateMessage}
        >
          <Icon color="warning" name="alert:warning_triangle" top="3px" />
        </Tooltip>
      </Conditional>

      <Box justifySelf="start" paddingTop={url ? 'unset' : '.5x'}>
        <FileUploader
          {...fileUploaderProps}
          buttonProps={{
            minWidth: '8rem',
            processing,
            text: 'Upload Instruction',
          }}
          expectedExtensions={[...defaultImageExtensions, 'pdf']}
          fileName={fileName}
          inputName={inputName}
          isDisabled={disabled}
          variant={variant}
        />
      </Box>
    </Flex>
  );
});
