import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  UpdateMachineArgs,
  UpdateMachineResponse,
} from './update-machine.types';

export const updateMachine = (args: UpdateMachineArgs) => {
  const { editMode = false, machineId, ...rest } = args;

  return axios
    .put<UpdateMachineResponse>(`${coreApiUrl}/machines/${machineId}`, {
      editMode,
      ...rest,
    })
    .then(({ data }) => {
      return data;
    });
};
