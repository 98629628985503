import { getFileUrl, Image, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import { cloudinaryUrl } from '../../../../../../../../../../../../helpers/images';
import { ConfigureCutSheetFormSchemaDef } from '../../configure-cut-sheets-modal.types';
import {
  StyledPreviewStack,
  StyledFirstLifestylePreviewBox,
  StyledSecondLifestylePreviewBox,
} from './cut-sheet-preview.styles';
import { cutSheetPreview } from './cut-sheet-preview.constants';

export const CutSheetPreview = () => {
  const product = useRecoilValue(productAtoms.product);

  const { values } = useFormikContext<ConfigureCutSheetFormSchemaDef>();

  const firstLifestylePhotoSize = {
    height: 231,
    width: 173,
  };

  const firstLifestylePhotoSrc = useMemo(() => {
    return cloudinaryUrl(
      values.firstLifestyleViewPhoto
        ? getFileUrl<'products'>({
            directory: 'products',
            fileName: values.firstLifestyleViewPhoto,
            fileType: 'lifestyle-photos',
            identifiers: {
              productId: product.id,
            },
          })
        : cutSheetPreview.firstLifestyleViewPhotoPlaceholder.empty,
      {
        crop: 'fill',
        height: firstLifestylePhotoSize.height,
        width: firstLifestylePhotoSize.width,
      },
    );
  }, [values.firstLifestyleViewPhoto]);

  const secondLifestylePhotoSize = {
    height: 126,
    width: 173,
  };

  const secondLifestylePhotoSrc = useMemo(() => {
    return cloudinaryUrl(
      values.secondLifestyleViewPhoto
        ? getFileUrl<'products'>({
            directory: 'products',
            fileName: values.secondLifestyleViewPhoto,
            fileType: 'lifestyle-photos',
            identifiers: {
              productId: product.id,
            },
          })
        : values.requires2LifestylePhotos
        ? cutSheetPreview.secondLifestyleViewPhotoPlaceholder.empty
        : cutSheetPreview.secondLifestyleViewPhotoPlaceholder.filled,
      {
        crop: 'fill',
        height: secondLifestylePhotoSize.height,
        width: secondLifestylePhotoSize.width,
      },
    );
  }, [values.secondLifestyleViewPhoto, values.requires2LifestylePhotos]);

  return (
    <StyledPreviewStack
      gap="2x"
      horizontalPadding="3x"
      justifyContent="center"
      verticalPadding="2x"
    >
      <Typography align="center" color="t2" text="Preview" variant="p3" />

      <Image
        alt="Cut sheet preview"
        containerAspectRatio="495:640"
        height="32rem"
        source="absolute"
        src={cloudinaryUrl(cutSheetPreview.sheet, {
          aspectRatio: '495:640',
          crop: 'limit',
          height: 640,
        })}
        style={{ position: 'relative' }}
        width="24.8rem"
      />

      <StyledFirstLifestylePreviewBox>
        <Image
          alt="First lifestyle photo"
          containerAspectRatio={`${firstLifestylePhotoSize.width}:${firstLifestylePhotoSize.height}`}
          height={`${Math.ceil(firstLifestylePhotoSize.height / 2)}px`}
          source="absolute"
          src={firstLifestylePhotoSrc}
          style={{ position: 'relative' }}
          width={`${Math.ceil(firstLifestylePhotoSize.width / 2)}px`}
        />
      </StyledFirstLifestylePreviewBox>

      <StyledSecondLifestylePreviewBox>
        <Image
          alt="Second lifestyle photo"
          containerAspectRatio={`${secondLifestylePhotoSize.width}:${secondLifestylePhotoSize.height}`}
          height={`${Math.ceil(secondLifestylePhotoSize.height / 2)}px`}
          source="absolute"
          src={secondLifestylePhotoSrc}
          style={{ position: 'relative' }}
          width={`${Math.ceil(secondLifestylePhotoSize.width / 2)}px`}
        />
      </StyledSecondLifestylePreviewBox>
    </StyledPreviewStack>
  );
};
