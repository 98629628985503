import { useRecoilValue } from 'recoil';
import { RestartReasonContent } from '../step-restart-reason/restart-reason-content';
import { ConfirmRestartContent } from '../step-confirm-restart/confirm-restart-content';
import { ConfirmCancelContent } from '../step-confirm-cancel/confirm-cancel-content';
import { restartOrCancelPartModalAtoms } from '../../restart-or-cancel-part-modal.atoms';

export const RestartOrCancelPartModalContent = () => {
  const step = useRecoilValue(restartOrCancelPartModalAtoms.step);

  switch (step) {
    case 'RESTART_REASON':
      return <RestartReasonContent />;

    case 'CONFIRM_RESTART':
      return <ConfirmRestartContent />;

    case 'CONFIRM_CANCEL':
      return <ConfirmCancelContent />;

    default:
      return null;
  }
};
