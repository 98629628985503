import { Form, Formik, FormikHelpers } from 'formik';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import {
  SelectProductFormData,
  SelectProductFormProps,
} from './select-product-form.types';
import {
  selectProductFormInitialValues,
  selectProductFormSchema,
} from './select-product-form.schema';
import { SelectProductFormContent } from './components/select-product-form-content';
import { selectProductFormAtoms } from './select-product-form.atoms';
import { useGetAllProducts } from '../../../api/products/routes';
import { selectProductFormDefaultProps } from './select-product-form.presets';
import { productStatuses } from '../../../constants/options';

/**
 * By default returns ALL products.
 *
 * * Use `showActiveProductsToggle` to show only Active products by default, and add a toggle to show all.
 *
 * * Use `filterProducts` to filter by other statuses or requirements.
 * */
export const SelectProductForm = (props: SelectProductFormProps) => {
  const {
    autoSubmitWhenValid,
    filterProducts,
    onSubmit,
    showActiveProductsToggle,
    showQuantity,
    submitButtonText,
  } = props;

  const handleSubmit = async (
    values: SelectProductFormData,
    { resetForm, validateForm }: FormikHelpers<SelectProductFormData>,
  ) => {
    onSubmit?.(values);

    resetForm();

    // formik's `isValid` is unreliable after form reset, so validating manually
    await validateForm();
  };

  const [activeProductsOnly, setActiveProductsOnly] = useRecoilState(
    selectProductFormAtoms.activeProductsOnly,
  );
  const [allProducts, setAllProducts] = useRecoilState(
    selectProductFormAtoms.allProducts,
  );

  const setFilteredProducts = useSetRecoilState(
    selectProductFormAtoms.filteredProducts,
  );

  useGetAllProducts({
    onSuccess: async ({ products }) => {
      setAllProducts(products);
    },
    showLoading: true,
  });

  useEffect(() => {
    setActiveProductsOnly(showActiveProductsToggle);
  }, [showActiveProductsToggle]);

  useEffect(() => {
    let filteredProducts = !!filterProducts
      ? allProducts.filter(filterProducts)
      : allProducts;

    if (activeProductsOnly) {
      filteredProducts = filteredProducts.filter(({ status }) => {
        return status === productStatuses.active;
      });
    }

    setFilteredProducts(filteredProducts);
  }, [allProducts, activeProductsOnly]);

  return (
    <Formik
      enableReinitialize
      initialValues={selectProductFormInitialValues}
      validateOnChange
      validateOnMount
      validationSchema={selectProductFormSchema}
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <Form>
            <SelectProductFormContent
              autoSubmitWhenValid={autoSubmitWhenValid}
              showActiveProductsToggle={showActiveProductsToggle}
              showQuantity={showQuantity}
              submitButtonText={submitButtonText}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

SelectProductForm.defaultProps = selectProductFormDefaultProps;
