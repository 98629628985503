import {
  Button,
  Conditional,
  Dropdown,
  Grid,
  Icon,
  Stack,
  Tooltip,
  Typography,
  useModal,
} from 'gantri-components';
import React from 'react';
import { useRecoilState } from 'recoil';
import {
  noDesignsDisabledTooltipDescription,
  statusOptions,
} from '../../../../info.constants';
import { formatDate } from '../../../../../../../../helpers/formatter';
import { ScheduleLaunchModal } from '..';
import { LONG_FORMAT } from '../../../../../../../../constants/dates';
import { productAtoms } from '../../../../../../product.atoms';
import { useFetchProductData } from '../../../../../../hooks/use-fetch-product-data';
import { productStatuses } from '../../../../../../../../constants/options';
import { Label } from '../../../../../../../../components/label';

export const ProductStatus = () => {
  const [product, setProduct] = useRecoilState(productAtoms.product);

  const { refetchProduct } = useFetchProductData();

  const [showLaunchModal, hideLaunchModal] = useModal(() => {
    return (
      <ScheduleLaunchModal
        launchDate={product.scheduleLaunchedAt}
        productId={product.id}
        refetchProduct={refetchProduct}
        onClose={hideLaunchModal}
      />
    );
  }, [product.scheduleLaunchedAt]);

  const isActive = product.status === productStatuses.active;
  const isOffMarket = product.status === productStatuses.offMarket;
  const isReady = product.status === productStatuses.ready;
  const isLaunchScheduled = isReady && !!product.scheduleLaunchedAt;
  const isLaunchWaiting = isReady && !product.scheduleLaunchedAt;
  const productHasDesigns = !!product.designs?.length;

  return (
    <Stack gap=".5x">
      <Grid alignItems="baseline" columns="1fr max-content" gap="2x">
        <Label text="Status" />

        <Conditional condition={isActive}>
          <Typography
            icon={
              <Icon
                color="success"
                name="ui-control:check_mark_circle_filled"
                style={{ top: '6px' }}
              />
            }
            iconGap="0.4rem"
            text={`Launched ${formatDate(product.launchedAt, LONG_FORMAT)}`}
            variant="p3"
          />
        </Conditional>

        <Conditional condition={isOffMarket}>
          <Typography
            color="alert"
            text={formatDate(product.offMarketAt, LONG_FORMAT)}
            variant="p3"
          />
        </Conditional>

        <Conditional condition={isLaunchScheduled}>
          <Grid alignItems="center" columns="repeat(2, max-content)" gap="5px">
            <Typography
              color="t2"
              text={`Launching ${formatDate(
                product.scheduleLaunchedAt,
                LONG_FORMAT,
              )}`}
              variant="p3"
            />
            <Button
              size="small"
              text="Edit"
              variant="ghost"
              onClick={showLaunchModal}
            />
          </Grid>
        </Conditional>
      </Grid>

      <Grid
        alignItems="baseline"
        columns={isLaunchWaiting ? '1fr max-content' : '1fr'}
        gap="x"
      >
        <Dropdown
          disabledTooltipProps={{ maxWidth: '25.4rem', position: 'left' }}
          items={statusOptions(product)}
          value={product.status}
          onSelect={(item) => {
            setProduct({ ...product, status: item?.value });
          }}
        />

        <Conditional condition={isLaunchWaiting}>
          <Tooltip
            description={
              !productHasDesigns && noDesignsDisabledTooltipDescription
            }
            position="top-end"
          >
            <Button
              disabled={!productHasDesigns}
              text="Schedule Launch"
              variant="secondary"
              onClick={showLaunchModal}
            />
          </Tooltip>
        </Conditional>
      </Grid>
    </Stack>
  );
};
