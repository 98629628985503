import {
  createContext,
  FC,
  PropsWithChildren,
  useMemo,
  useContext,
  useState,
  useCallback,
} from 'react';
import { FormikHelpers } from 'formik';
import pick from 'lodash/pick';
import {
  InventoryMigrationContextState,
  InventoryMigrationFormState,
  Step,
  OnSuccessInfo,
} from './inventory-migration.types';
import { Inventory } from '../../../../api/inventories/routes/fetch-all/fetch-all-inventories.types';
import {
  paintInventoryNamePattern,
  useFetchAllInventories,
} from '../../../../api/inventories/routes/fetch-all';
import { useQueryMutation } from '../../../../hooks/use-mutation';
import { inventoriesApi } from '../../../../api';
import {
  MigrateInventoryRequest,
  MigrationInventoryResponse,
} from '../../../../api/inventories/routes/migrate-inventory';
import { useNotification } from '../../../../hooks/useNotification';
import {
  inventoryStatuses,
  inventoryTypes,
} from '../../../../constants/options';

export const InventoryMigrationContext =
  createContext<InventoryMigrationContextState>({
    activeInventories: [],
    inactiveInventories: [],
    initialValues: { existingId: null, replacementId: null },
    isLoading: true,
    onClose: () => {},
    onMigrate: () => {},
    onSuccess: () => {},
    step: 'FILLED_FORM_STEP',
  });

export const InventoryMigrationProvider: FC<
  PropsWithChildren<{
    onClose?: () => void;
    onSuccess?: (info: OnSuccessInfo) => void;
  }>
> = ({ children, onClose, onSuccess: onDone }) => {
  const { notify, notifyAxiosError } = useNotification();
  const [step, setStep] = useState<Step>('FILLED_FORM_STEP');
  const { data: inventoriesByStatus, isLoading } = useFetchAllInventories({
    transform: ({ inventories }) => {
      return inventories.reduce<
        Record<string, (Inventory & { disabled: boolean })[]>
      >(
        (accumulator, inventory) => {
          // ! Temporarily disable paint inventories as specific paints are no longer applied at the product level.
          // ! In the inventory details page, we show active and off market products that use the inventory, but that was tracked based on the inventories been saved in the job templates and that's no longer applicable.
          // ! - Decker - July 19, 2024
          const isPaint =
            inventory.type === inventoryTypes.material &&
            paintInventoryNamePattern.test(inventory.name);

          accumulator[inventory.status]?.push({
            ...inventory,
            disabled: isPaint,
          });

          return accumulator;
        },
        {
          [inventoryStatuses.active]: [],
          [inventoryStatuses.inactive]: [],
        },
      );
    },
  });

  const { onMutate: onMigrateFn } = useQueryMutation<
    MigrateInventoryRequest,
    MigrationInventoryResponse
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to migrate inventory.',
        });
      },
    },
    mutationFn: (request) => {
      return inventoriesApi.migrateInventory(request);
    },
    showLoading: true,
  });

  const onMigrate = useCallback(
    async (
      values: InventoryMigrationFormState,
      formikHelpers: FormikHelpers<InventoryMigrationFormState>,
    ) => {
      await onMigrateFn(pick(values, ['existingId', 'replacementId']), {
        onSuccess: (response) => {
          notify(response.notice);

          formikHelpers.setValues({
            ...values,
            productManualUpdate: response?.productManualUpdate?.map(
              (product) => {
                return {
                  id: product.id,
                  productName: `${product.name} ${product.category}`,
                };
              },
            ),
            stockUpdated: response.stockUpdated,
            totalProductUpdated: response.totalProductUpdated,
          });

          setStep('SUMMARY_STEP');
        },
      });
    },
    [],
  );

  const onSuccess = useCallback((values: InventoryMigrationFormState) => {
    onDone?.(pick(values, ['existingId', 'replacementId']));
    onClose?.();
  }, []);

  const values = useMemo(() => {
    return {
      activeInventories: inventoriesByStatus?.[inventoryStatuses.active] ?? [],
      inactiveInventories:
        inventoriesByStatus?.[inventoryStatuses.inactive] ?? [],
      initialValues: { existingId: null, replacementId: null },
      isLoading,
      onClose,
      onMigrate,
      onSuccess,
      step,
    } as InventoryMigrationContextState;
  }, [inventoriesByStatus]);

  return (
    <InventoryMigrationContext.Provider value={{ ...values, step }}>
      {children}
    </InventoryMigrationContext.Provider>
  );
};

export const useInventoryMigrationState =
  (): InventoryMigrationContextState => {
    return useContext(InventoryMigrationContext);
  };
