import { Stack, Table, Typography } from 'gantri-components';
import { UserActivityFilter } from '../../../components/dropdowns';
import {
  GetUserActivitiesArgs,
  UserActivity,
} from '../../../api/users/routes/get-user-activities/get-user-activities.types';
import { useNotification } from '../../../hooks/useNotification';
import { usersApi } from '../../../api';
import {
  FiltersFetchRequestArgs,
  useTableFilters,
} from '../../../components/common/table/hooks';
import {
  pageName,
  userActivityFiltersDefaults,
  userActivityPageAtoms,
} from './user-activity.atoms';
import { columns } from './user-activity.constants';

export const Activity = ({ userId }: { userId: number }) => {
  const { notifyAxiosError, onInterceptRequest } = useNotification();

  const fetchUserActivity = async (
    args: FiltersFetchRequestArgs<GetUserActivitiesArgs>,
  ) => {
    if (userId) {
      await onInterceptRequest(async () => {
        try {
          const { data } = await usersApi.getUserActivities({
            userId,
            ...args,
          });

          setRecords(data.userActivities ?? []);
          setTotalCount(data.count);
        } catch (error: unknown) {
          notifyAxiosError({
            error,
            fallbackMessage: 'Unable to load user data.',
          });
        }
      });
    }
  };

  const { filtersProps, pagingProps, records, setRecords, setTotalCount } =
    useTableFilters<UserActivity, GetUserActivitiesArgs>(
      {
        fetchRequest: fetchUserActivity,
        filtersContent: <UserActivityFilter />,
        modalFiltersDetails: [
          {
            atom: userActivityPageAtoms.filters.dateRange,
            defaultValue: userActivityFiltersDefaults.filters.dateRange,
            payloadKey: 'dateRange',
          },
          {
            atom: userActivityPageAtoms.filters.type,
            defaultValue: userActivityFiltersDefaults.filters.type,
            payloadKey: 'type',
          },
        ],
        pageName,
        pageSize: 20,
      },
      [userId],
    );

  return (
    <Stack>
      <Typography text="Activity" textStyle="bold" variant="h4" />
      <Table
        columns={columns}
        data={records}
        filters={filtersProps}
        paging={pagingProps}
      />
    </Stack>
  );
};
