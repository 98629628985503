import styled from 'styled-components';

export const StyledSummaryWrapper = styled.div`
  display: flex;
  padding: 20px;
  border: 1px solid
    ${(props) => {
      return props.theme.colors.dividers.t1;
    }};
  width: 100%;
  overflow-x: auto;
`;

export const StyledSummaryColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  margin: 0 20px;

  &:first-of-type {
    margin-left: unset;
  }
`;

export const StyledSummaryMobileSpacer = styled.div`
  height: 100%;
  min-width: 1px;
`;

export const StyledStockSummaryContainer = styled.div`
  width: 100%;
  max-width: 100vw;
`;
