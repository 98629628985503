import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import {
  Cell,
  FileUploader,
  getBase64FromBlob,
  Stack,
} from 'gantri-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { closestCenter, DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { productAtoms } from '../../../../../../product.atoms';
import { Label } from '../../../../../../../../components/label';
import { useCloudinaryFileUploader } from '../../../../../../../../hooks/use-cloudinary-file-uploader';
import { useDragAndDrop } from '../../../../../../../../hooks/use-drag-and-drop';
import { LifestylePhotosSectionItem } from './components/lifestyle-photos-section-item';
import { SortableLifestylePhotoData } from './components/lifestyle-photos-section-item/lifestyle-photos-section-item.types';
import { lifestyleProductAssetsAtoms } from './lifestyle-photos-section.atoms';
import { useUpdateProductLifestylePhotos } from '../../../../../../../../api/products/routes/update-product-lifestyle-photos';
import { useFetchProductData } from '../../../../../../hooks/use-fetch-product-data';
import { getTransformProductAssetFileName } from '../../../../helpers/get-transform-product-asset-file-name';

export const LifestylePhotosSection = () => {
  const product = useRecoilValue(productAtoms.product);

  const [editingLifestylePhotoData, setEditingLifestylePhotoData] =
    useRecoilState(lifestyleProductAssetsAtoms.editingLifestylePhotoData);

  const [assets, setAssets] = useState<SortableLifestylePhotoData[]>([]);

  const { invalidateFetchProductCache } = useFetchProductData();

  const { onUpdateLifestylePhotos } = useUpdateProductLifestylePhotos({
    onSuccess: async () => {
      await invalidateFetchProductCache();
    },
  });

  const { transformFileName } = getTransformProductAssetFileName({
    fileType: 'lifestyle-photos',
    productId: product.id,
  });

  const { fileUploaderProps, handleUploadFile } =
    useCloudinaryFileUploader<'products'>({
      directory: 'products',
      fileType: 'lifestyle-photos',
      identifiers: {
        productId: product.id,
      },
      isDisabled: !!editingLifestylePhotoData,
      onFileSelected: async (filePendingUpload) => {
        const { fileBlob, fileName } = filePendingUpload;

        const base64Url = await getBase64FromBlob(fileBlob);

        setAssets((savedAssets) => {
          const newAsset: SortableLifestylePhotoData = {
            base64Url,
            color: null,
            fileName,
            filePendingUpload,
            id: uuidv4(),
            variants: null,
          };

          setEditingLifestylePhotoData(newAsset);

          return [...savedAssets, newAsset];
        });
      },
      transformFileName,
    });

  const { handleDragEnd, sensors } = useDragAndDrop({
    data: assets,
    onReorder: async (reorderedData) => {
      setAssets(reorderedData);
      await onUpdateLifestylePhotos({
        lifestylePhotos: reorderedData,
        productId: product.id,
      });
    },
  });

  const isDraggable = editingLifestylePhotoData ? false : assets.length > 1;

  const lifestylePhotos = product?.assets?.product?.lifestylePhotos || [];

  const getSortableAssets = () => {
    return lifestylePhotos.map((data) => {
      const sortableItem: SortableLifestylePhotoData = {
        ...data,
        id: uuidv4(),
      };

      return sortableItem;
    });
  };

  const resetAssets = () => {
    const sortableAssets = getSortableAssets();

    setAssets(sortableAssets);
  };

  const shouldAutoUpload = !product.isPainted && !product.selectors?.length;

  useEffect(() => {
    const sortableAssets = getSortableAssets();

    setAssets(sortableAssets);
  }, [lifestylePhotos]);

  return (
    <Stack gap="2x">
      <Label text="Lifestyle Images" />

      <Cell>
        <DndContext
          collisionDetection={closestCenter}
          sensors={sensors}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={assets}>
            {assets.map((asset, index) => {
              return (
                <LifestylePhotosSectionItem
                  key={asset.id}
                  asset={asset}
                  assets={assets}
                  handleUploadFile={handleUploadFile}
                  index={index}
                  isDraggable={isDraggable}
                  resetAssets={resetAssets}
                />
              );
            })}
          </SortableContext>
        </DndContext>

        <Cell justifyContent="start">
          <FileUploader
            {...fileUploaderProps}
            isUploaderOnly
            processing={shouldAutoUpload ? fileUploaderProps.processing : false}
            variant="thumbnail"
          />
        </Cell>
      </Cell>
    </Stack>
  );
};
