import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { StartJobArgs, StartJobResponse } from './start-job.types';
import { startJob } from './start-job';

export const useStartJob = <TransformedData = StartJobResponse>(
  props?: GenericMutateQueryProps<
    StartJobArgs,
    StartJobResponse,
    TransformedData
  >,
) => {
  const { onMutate: onStartJob, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to start job.',
    mutationFn: startJob,
    ...props,
  });

  return { ...rest, onStartJob };
};
