import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { DiscardStockArgs, DiscardStockResponse } from './discard-stock.types';
import { discardStock } from './discard-stock';

export const useDiscardStock = <TransformedData = DiscardStockResponse>(
  props?: GenericMutateQueryProps<
    DiscardStockArgs,
    DiscardStockResponse,
    TransformedData
  >,
) => {
  const { onMutate: onDiscardStock, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to discard stock.',
    mutationFn: discardStock,
    ...props,
  });

  return { ...rest, onDiscardStock };
};
