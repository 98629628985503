import { FC, PropsWithChildren } from 'react';
import { Box, Flex, Icon, Line, Overlay, Typography } from 'gantri-components';
import { DetailsPanelProps } from './details-panel.props';
import { DetailsPanelPresets } from './details-panel.presets';
import {
  StyledDetailsPanelStack,
  StyledDetailsPanelContent,
  detailsPanelZIndex,
} from './details-panel.styles';
import { StyledPageHeadingGrid } from '../../layout/page-heading/page-heading.styles';
import { useMoreMenu } from '../../../hooks/use-more-menu';

export const DetailsPanel: FC<PropsWithChildren<DetailsPanelProps>> = (
  props,
) => {
  const {
    children,
    dataAttrs,
    footer,
    header,
    menu,
    onClose,
    onOpen,
    open,
    title,
  } = props;

  const { MoreMenu } = useMoreMenu({
    options: menu || [],
    top: '2px',
  });

  return (
    <Overlay
      content={
        <StyledDetailsPanelStack
          {...dataAttrs}
          gap="unset"
          rows="max-content 1fr max-content"
        >
          <Box>
            <StyledPageHeadingGrid
              alignItems="center"
              columnGap="x"
              columns="1fr max-content"
              horizontalPadding="2x"
              justifyContent="space-between"
              width="100%"
            >
              <Typography text={title} variant="h4" />

              <Flex alignItems="center" gap="x">
                {header}

                {!!menu?.length && <MoreMenu />}

                <Icon
                  color="t2"
                  cursor="pointer"
                  name="ui-control:x"
                  onClick={onClose}
                />
              </Flex>
            </StyledPageHeadingGrid>
            <Line />
          </Box>

          <StyledDetailsPanelContent>{children}</StyledDetailsPanelContent>

          {footer}
        </StyledDetailsPanelStack>
      }
      open={open}
      triggerEvent="manual"
      zIndex={detailsPanelZIndex}
      onClose={onClose}
      onOpen={onOpen}
    />
  );
};

DetailsPanel.defaultProps = DetailsPanelPresets;
