import { getColorsByProduct } from 'gantri-components';
import { FieldArrayRenderProps } from 'formik';
import { partPaintedStatuses } from '../../../../../../../../../../../../api/products/products.constants';
import {
  PartPaintedStatus,
  PartTemplateColorOverride,
} from '../../../../../../../../../../../../api/products/products.types';

export const getColorOverridesItems = (props: {
  paintedStatus: PartPaintedStatus;
  productId: number;
}) => {
  const { paintedStatus, productId } = props;

  return getColorsByProduct({
    allowTradeColors: true,
    isPainted: paintedStatus === partPaintedStatuses.painted,
    productId,
  });
};

export const getHandleAddColorOverride = (
  props: Pick<FieldArrayRenderProps, 'push'>,
) => {
  const { push } = props;

  return () => {
    push<PartTemplateColorOverride>({
      from: {
        code: undefined,
        name: undefined,
      },
      to: {
        code: undefined,
        name: undefined,
      },
    });
  };
};
