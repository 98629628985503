import { useMemo } from 'react';
import {
  Conditional,
  Dropdown,
  Flex,
  FormikInput,
  Stack,
  Toggle,
  Typography,
} from 'gantri-components';
import { useFormikContext } from 'formik';
import { ChangeUserTypeModalFormDataDef } from '../../../user-type-modal.types';
import { convertStringArrayToValueLabelArray } from '../../../../../../helpers/formatter';
import { userRoles } from '../../../../../../constants/users';
import { Label } from '../../../../../../components/label';

export const ChangeUserTypeModalUpdateContent = () => {
  const { setFieldValue, values } =
    useFormikContext<ChangeUserTypeModalFormDataDef>();

  const userTypeItems = useMemo(() => {
    return convertStringArrayToValueLabelArray(Object.values(userRoles));
  }, []);

  return (
    <>
      <FormikInput
        ariaLabel="user-type"
        Field={
          <Dropdown
            items={userTypeItems}
            onSelect={async (item) => {
              if (item?.value === userRoles.user) {
                await setFieldValue('isSander', false);
              }
            }}
          />
        }
        labelText="User type"
        name="type"
        placeholder="User type"
      />
      <Conditional condition={values.type !== userRoles.user}>
        <Stack gap=".5x">
          <Label text="Is sander" />

          <Flex gap="x">
            <FormikInput
              ariaLabel="is-sander"
              Field={
                <Toggle
                  value={values.isSander}
                  onSelected={async (value) => {
                    await setFieldValue('isSander', value);
                  }}
                />
              }
              name="isSander"
            />

            <Conditional
              condition={values.isSander}
              Fallback={<Typography color="t2" text="No" />}
            >
              <Typography color="t2" text="Yes" />
            </Conditional>
          </Flex>
        </Stack>
      </Conditional>
    </>
  );
};
