import { Button, Table } from 'gantri-components';
import { useState } from 'react';
import { Download } from './downloads.types';
import { DownloadsFilter } from '../../components/dropdowns/downloads-filter';
import { PageWithTable } from '../../components/layout';
import {
  downloadsFiltersDefaults,
  downloadsPageAtoms,
  pageName,
} from '../../components/dropdowns/downloads-filter/downloads-filter.atoms';
import {
  DownloadsSortingField,
  FetchPaginatedArgs,
} from '../../api/downloads/routes/fetch-paginated/fetch-paginated.types';
import {
  FiltersFetchRequestArgs,
  useTableFilters,
} from '../../components/common/table/hooks';
import { useGetDownloadsColumns } from './downloads.constant';
import { useDownloadModal } from './download-modal/use-download-modal';
import {
  useFetchPaginatedDownloads,
  useInvalidateFetchPaginatedDownloadsCache,
} from '../../api/downloads/routes';

export const Downloads = () => {
  const [filters, setFilters] =
    useState<
      FiltersFetchRequestArgs<FetchPaginatedArgs, DownloadsSortingField>
    >();

  useFetchPaginatedDownloads({
    enabled: !!filters,
    fetchArgs: filters,
    onSuccess: async (data) => {
      setRecords(data.downloads);
      setTotalCount(data.totalDownloads);
    },
  });

  const { invalidateFetchPaginatedDownloadsCache } =
    useInvalidateFetchPaginatedDownloadsCache();

  const fetchPaginatedDownloads = async () => {
    await handleFetchRequest({ page: 1 });
    await invalidateFetchPaginatedDownloadsCache();
  };

  const onCreateDownload = useDownloadModal({
    onComplete: fetchPaginatedDownloads,
  });

  const {
    filtersProps,
    handleFetchRequest,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<Download, FetchPaginatedArgs, DownloadsSortingField>({
    fetchRequest: setFilters,
    filtersContent: <DownloadsFilter />,
    modalFiltersDetails: [
      {
        atom: downloadsPageAtoms.filters.type,
        defaultValue: downloadsFiltersDefaults.filters.type,
        payloadKey: 'types',
      },
    ],
    pageName,
    searchFilterDetails: {
      atom: downloadsPageAtoms.search,
    },
    sortByFilterDetails: {
      atom: downloadsPageAtoms.sortBy,
      defaultValue: downloadsFiltersDefaults.sortBy,
    },
    sortOptions: [
      { bidirectional: true, label: 'Name', sortingField: 'name' },
      {
        bidirectional: true,
        label: 'Create Date',
        sortingField: 'updatedAt',
      },
    ],
  });

  const { DownloadStatusToast, columns } = useGetDownloadsColumns({
    fetchPaginatedDownloads,
  });

  return (
    <PageWithTable pageTitle="Downloads">
      <PageWithTable.Header title="Downloads">
        <Button
          text="New Download"
          onClick={() => {
            return onCreateDownload();
          }}
        />
        <DownloadStatusToast position="bottom-end" />
      </PageWithTable.Header>
      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          sorting={sortProps}
          stickyLastColumn
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
