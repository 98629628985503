import { useFormikContext } from 'formik';
import { ChangeUserTypeModalFormDataDef } from '../../user-type-modal.types';
import { changeUserTypeModalSteps } from '../../user-type-modal.constants';
import { ChangeUserTypeModalUpdateContent } from '../step-update/change-user-type-modal-update-content';
import { ChangeUserTypeModalConfirmContent } from '../step-confirm/change-user-type-modal-confirm-content';
import { ChangeUserTypeModalContentPropsDef } from './change-user-type-modal-content.types';

export const ChangeUserTypeModalContent = (
  props: ChangeUserTypeModalContentPropsDef,
) => {
  const { initialValues } = props;

  const { values } = useFormikContext<ChangeUserTypeModalFormDataDef>();

  switch (values.step) {
    case changeUserTypeModalSteps.update:
      return <ChangeUserTypeModalUpdateContent />;

    case changeUserTypeModalSteps.confirm:
      return (
        <ChangeUserTypeModalConfirmContent initialValues={initialValues} />
      );

    default:
      return null;
  }
};
