import { Conditional, Flex, Icon, Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useTheme } from 'styled-components';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import { StyledAnchor } from '../../../../../../../../../../../../components/common/styled-anchor';
import { useHandleSetSelectedSku } from '../../../../../../../../../../hooks/use-handle-set-selected-sku';
import routePaths from '../../../../../../../../../../../../config/route-paths';
import { productFetchTypes } from '../../../../../../../../../../../../api/products/routes';
import { ExpandableItemsList } from '../../../../../../../../../../../../components/common/expandable-items-list';
import { productQueryKeys } from '../../../../../../../../../../product.constants';
import { convertQueriesToString } from '../../../../../../../../../../../../helpers/checks';
import { assetLevelViews } from '../../../../../../../../assets.constants';
import { ConditionalLineItem } from '../conditional-line-item';
import { StyledBackgroundStack } from '../../../../review-assets-modal.styles';
import { RequiredAssetsSectionPropsDef } from './required-assets-section.types';

export const RequiredAssetsSection = (props: RequiredAssetsSectionPropsDef) => {
  const { isMissingRequiredAssets, noLifestyleImages, onClose, report } = props;

  const product = useRecoilValue(productAtoms.product);

  const theme = useTheme();

  const { handleSetSelectedSku } = useHandleSetSelectedSku();

  return (
    <Conditional
      key="Required assets complete."
      condition={isMissingRequiredAssets}
      Fallback={
        <Typography
          icon={
            <Icon color="link" name="ui-control:check_mark_circle_filled" />
          }
          text="Required assets complete."
        />
      }
    >
      <StyledBackgroundStack
        $backgroundColor={theme.colors.surfaces.warning.t1}
        gap="0"
        height="auto"
        padding="x"
      >
        <Typography
          icon={<Icon color="warning" name="alert:warning_triangle" />}
          paddingBottom="x"
          text="Missing required assets."
        />
        <ExpandableItemsList
          idProperty="sku"
          Item={({ sku }) => {
            const queriesString = convertQueriesToString({
              [productQueryKeys.assetLevel]: assetLevelViews.sku,
              [productQueryKeys.sku]: sku,
              [productQueryKeys.tab]: productFetchTypes.assets,
            });

            return (
              <StyledAnchor
                href={`${routePaths.products}/${product.id}${queriesString}`}
                text={sku}
                onClick={() => {
                  // providing both onClick and href to support opening links in a new tab
                  handleSetSelectedSku(sku);
                  onClose();
                }}
              />
            );
          }}
          Label={({ numItems }) => {
            return (
              <Flex alignItems="center" gap="x">
                <Typography text={numItems} textStyle="bold" />
                <Typography text="SKUs missing at least 1 White background shot" />
              </Flex>
            );
          }}
          list={report.skusMissingProductPhotos}
        />
        <Conditional
          condition={
            report.noScaleImage ||
            report.noDimmingImages ||
            report.noUsdzFile ||
            noLifestyleImages
          }
        >
          <Stack gap="x" paddingBottom="x">
            <ConditionalLineItem
              condition={report.noScaleImage}
              text="No scale image present"
              tooltipTitle="At least 1 SKU should have a scale image."
            />

            <ConditionalLineItem
              condition={report.noDimmingImages}
              text="No dimming images present"
              tooltipTitle="At least 1 SKU should have dimming images (light and dark)."
            />

            <ConditionalLineItem
              condition={report.noUsdzFile}
              text="No USDZ files present"
              tooltipTitle="At least 1 SKU should have a USDZ model."
            />

            <ConditionalLineItem
              condition={noLifestyleImages}
              text="No lifestyle images present"
              tooltipTitle="Product should have at least 1 editorial image."
            />
          </Stack>
        </Conditional>
      </StyledBackgroundStack>
    </Conditional>
  );
};
