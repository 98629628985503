import { getGenericAtomFamily } from '../../helpers/get-generic-atom-family';
import { AtomPageName } from '../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { NewStockItem } from './new-stocks.types';
import { TableDataItem } from './hooks/use-get-new-stocks-table-details/use-get-new-stocks-table-details.types';

const atomKeyPrefix = 'NEW_STOCKS';
const pageName: AtomPageName = 'new-stocks';

export const newStocksAtoms = {
  activeProductsOnly: getGenericAtomFamily<boolean>({
    defaultValue: true,
    key: `${atomKeyPrefix}-active-products-only`,
  })(pageName),
  cachedTableData: getGenericAtomFamily<TableDataItem[]>({
    defaultValue: [],
    key: `${atomKeyPrefix}-cached-table-data`,
  })(pageName),
  editingRowId: getGenericAtomFamily<string>({
    defaultValue: null,
    key: `${atomKeyPrefix}-editing-row-id`,
  })(pageName),
  newStocks: getGenericAtomFamily<NewStockItem[]>({
    defaultValue: [],
    key: `${atomKeyPrefix}-new-stocks`,
  })(pageName),
  tableData: getGenericAtomFamily<TableDataItem[]>({
    defaultValue: [],
    key: `${atomKeyPrefix}-table-data`,
  })(pageName),
};
