import { Button } from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { fullScreenModalFooterButtonSize } from '../../../../modals.constants';
import { printPrepareQueueModalAtoms } from '../../../print-transfer-queue-modal.atoms';
import { useNavigateToNextAvailableSet } from '../../../hooks/use-navigate-to-next-available-set';
import { useBulkCompletePrintTransferQueueSetJobs } from '../../../../../../../../api/queue/print-transfer';
import { useGetNumJobsInSet } from '../../../hooks/get-num-jobs-in-set';
import { pluralize } from '../../../../../../../../helpers/pluralize';
import { printPrepareQueueModalSteps } from '../../../print-transfer-queue-modal.constants';
import { HandleCloseModal } from '../../../../hooks/use-handle-close-modal/use-handle-close-modal.types';

export interface JobSetPrepareFooterProps {
  handleCloseModal: HandleCloseModal;
}

export const JobSetPrepareFooter = (props: JobSetPrepareFooterProps) => {
  const { handleCloseModal } = props;

  const isPacketPrepared = useRecoilValue(
    printPrepareQueueModalAtoms.isPacketPrepared,
  );
  const [queueData, setQueueData] = useRecoilState(
    printPrepareQueueModalAtoms.queueData,
  );
  const activeSetIndex = useRecoilValue(
    printPrepareQueueModalAtoms.activeSetIndex,
  );
  const setStep = useSetRecoilState(printPrepareQueueModalAtoms.step);

  const numJobsInSet = useGetNumJobsInSet();

  const { navigateToNextAvailableSet } = useNavigateToNextAvailableSet({
    handleCloseModal,
  });

  const { isLoading, onBulkCompletePrintTransferQueueSetJobs } =
    useBulkCompletePrintTransferQueueSetJobs({
      onDeleted: () => {
        setStep(printPrepareQueueModalSteps.queueDeleted);
      },
    });

  return (
    <Button
      disabled={!isPacketPrepared}
      processing={isLoading}
      size={fullScreenModalFooterButtonSize}
      text={`Complete ${pluralize('job', numJobsInSet)}`}
      onClick={async () => {
        const { printPrepareQueue } =
          await onBulkCompletePrintTransferQueueSetJobs({
            queueId: queueData?.id,
            returnQueue: true,
            setIndex: activeSetIndex,
          });

        setQueueData(printPrepareQueue);
        await navigateToNextAvailableSet(printPrepareQueue);
      }}
    />
  );
};
