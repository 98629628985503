import { Cell, CellContext, ColumnDef } from '@tanstack/react-table';
import { Typography } from 'gantri-components';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { jobSteps, jobTypeOptions } from '../../../../../constants/options';
import { useDownloadWithSmallToast } from '../../../../../hooks/use-download-with-small-toast';
import { downloadToastText } from '../../../../../hooks/use-download-with-small-toast/use-download-with-small-toast.constants';
import { getVersionDetails } from '../../helpers';
import { jobsTemplateTableAtoms } from '../../jobs-template-table.atoms';
import { JobTemplateWithId } from '../../jobs-template-table.types';
import {
  JobTemplateTypeCell,
  JobTemplateMenuCell,
  JobTemplateInventoryCell,
  JobTemplateEditableInputCell,
  JobTemplateStepCell,
  JobTemplateMachineTypeCell,
  JobTemplateGcodeCell,
  JobTemplateInstructionCell,
} from './components';
import { UseGetJobsTemplateTableColumnsProps } from './use-get-jobs-template-table-columns.types';

export const useGetJobsTemplateTableColumns = (
  props: UseGetJobsTemplateTableColumnsProps,
) => {
  const {
    cachedJobTemplates,
    disableEditing,
    onEdit,
    onSaveOverride,
    partId,
    partName,
    productName,
    refetchProduct,
    restrictJobStepTo,
    setJobTemplates,
    version,
  } = props;

  const editingTemplateId = useRecoilValue(
    jobsTemplateTableAtoms.editingTemplateId,
  );

  const {
    DownloadStatusToast: DownloadGcodeStatusToast,
    downloadSingleFile: downloadGcode,
  } = useDownloadWithSmallToast({ ...downloadToastText.gcode });

  const {
    DownloadStatusToast: DownloadInstructionStatusToast,
    downloadMultipleFiles: downloadInstructionFiles,
  } = useDownloadWithSmallToast({ ...downloadToastText.instruction });

  const columns = useMemo(() => {
    const isPrintJobsTable = restrictJobStepTo === jobSteps.print;

    const showPrintJobColumns = partId && isPrintJobsTable;

    const printJobColumns: ColumnDef<JobTemplateWithId>[] = showPrintJobColumns
      ? [
          {
            accessorKey: 'gcode',
            cell: (
              props: CellContext<JobTemplateWithId, JobTemplateWithId['gcode']>,
            ) => {
              return (
                <JobTemplateGcodeCell
                  partName={partName}
                  onEdit={onEdit}
                  {...props}
                />
              );
            },
            header: 'G-code',
            meta: {
              getOnClick: ({
                getValue,
                row,
              }: Cell<JobTemplateWithId, JobTemplateWithId['gcode']>) => {
                const gcode = getValue();
                const isEditingRow = row.original.id === editingTemplateId;
                const isPrintTransferJob =
                  row.original.step === jobSteps.print &&
                  row.original.type === jobTypeOptions.transfer;
                const isClickable =
                  isPrintTransferJob && !!gcode?.url && !isEditingRow;

                return isClickable
                  ? () => {
                      return downloadGcode({
                        fileName: gcode?.fileName,
                        url: gcode?.url,
                      });
                    }
                  : undefined;
              },
            },
            size: 240,
          },
          {
            accessorKey: 'weight',
            cell: (
              props: CellContext<
                JobTemplateWithId,
                JobTemplateWithId['weight']
              >,
            ) => {
              return (
                <JobTemplateEditableInputCell
                  disableEditing={
                    props.row.original.type !== jobTypeOptions.transfer
                  }
                  keyName="weight"
                  placeholder="Weight"
                  textFieldProps={{
                    rightIcon: <Typography color="t2" text="g" />,
                    type: 'number',
                  }}
                  transformTextValue={(value) => {
                    return value ? `${value} g` : undefined;
                  }}
                  onEdit={onEdit}
                  {...props}
                />
              );
            },
            header: 'Weight',
            size: 110,
          },
          {
            accessorKey: 'machineType',
            cell: (
              props: CellContext<
                JobTemplateWithId,
                JobTemplateWithId['machineType']
              >,
            ) => {
              return <JobTemplateMachineTypeCell onEdit={onEdit} {...props} />;
            },
            header: 'Machine Type',
            size: 240,
          },
        ]
      : [];

    const columns: ColumnDef<JobTemplateWithId>[] = [
      {
        accessorKey: 'type',
        cell: (
          props: CellContext<JobTemplateWithId, JobTemplateWithId['type']>,
        ) => {
          return (
            <JobTemplateTypeCell
              cachedJobTemplates={cachedJobTemplates}
              partId={partId}
              refetchProduct={refetchProduct}
              setJobTemplates={setJobTemplates}
              version={version}
              onEdit={onEdit}
              onSaveOverride={onSaveOverride}
              {...props}
            />
          );
        },
        header: 'Type',
        size: 140,
      },
      {
        accessorKey: 'description',
        cell: (
          props: CellContext<
            JobTemplateWithId,
            JobTemplateWithId['description']
          >,
        ) => {
          const { isMinorVersion } = getVersionDetails(version);

          return (
            <JobTemplateEditableInputCell
              disableEditing={isMinorVersion}
              keyName="description"
              placeholder="Name"
              onEdit={onEdit}
              {...props}
            />
          );
        },
        header: 'Name',
        size: 200,
      },
      {
        accessorKey: 'step',
        cell: (
          props: CellContext<JobTemplateWithId, JobTemplateWithId['step']>,
        ) => {
          return (
            <JobTemplateStepCell
              partId={partId}
              restrictJobStepTo={restrictJobStepTo}
              version={version}
              onEdit={onEdit}
              {...props}
            />
          );
        },
        header: 'Step',
        size: 140,
      },
      {
        accessorKey: 'duration',
        cell: (
          props: CellContext<JobTemplateWithId, JobTemplateWithId['duration']>,
        ) => {
          return (
            <JobTemplateEditableInputCell
              keyName="duration"
              placeholder="Duration"
              onEdit={onEdit}
              {...props}
            />
          );
        },
        header: 'Dur (m)',
        size: 120,
      },
      {
        accessorKey: 'instruction',
        cell: (
          props: CellContext<
            JobTemplateWithId,
            JobTemplateWithId['instruction']
          >,
        ) => {
          return <JobTemplateInstructionCell onEdit={onEdit} {...props} />;
        },
        header: 'Instruction',
        meta: {
          getOnClick: ({
            getValue,
            row,
          }: Cell<JobTemplateWithId, JobTemplateWithId['instruction']>) => {
            const instruction = getValue();
            const isEditingRow = row.original.id === editingTemplateId;
            const isClickable = !!instruction?.files?.length && !isEditingRow;

            return isClickable
              ? () => {
                  return downloadInstructionFiles(
                    instruction.files.map(({ fileName, url }) => {
                      return { fileName, url };
                    }),
                  );
                }
              : undefined;
          },
        },
        size: 240,
      },
      ...printJobColumns,
      {
        accessorKey: 'inventoryRequests',
        cell: (
          props: CellContext<
            JobTemplateWithId,
            JobTemplateWithId['inventoryRequests']
          >,
        ) => {
          return <JobTemplateInventoryCell onEdit={onEdit} {...props} />;
        },
        header: 'Inventories',
        size: editingTemplateId ? 360 : 260,
      },
      !disableEditing && {
        cell: (props: CellContext<JobTemplateWithId, never>) => {
          return (
            <JobTemplateMenuCell
              cachedJobTemplates={cachedJobTemplates}
              refetchProduct={refetchProduct}
              version={version}
              onSaveOverride={onSaveOverride}
              {...props}
            />
          );
        },
        id: 'menu',
        maxSize: 50,
      },
    ].filter(Boolean);

    return columns;
  }, [
    // BE EXTREMELY CAREFUL CHANGING THE DEPENDENCIES.
    // The inventories column is unstable due to sort behavior requirements from product.
    // See `InventoryDataCell` for more info.
    partId,
    partName,
    productName,
    version,
    cachedJobTemplates,
    disableEditing,
    editingTemplateId,
  ]);

  return { DownloadGcodeStatusToast, DownloadInstructionStatusToast, columns };
};
