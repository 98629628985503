import { Link } from 'react-router-dom';
import { Typography } from 'gantri-components';
import { makeDateString } from '../../helpers/formatter';
import routePaths from '../../config/route-paths';
import SummaryWrapper from './wrapper';
import { StyledSummaryColumn } from './summaries-styles';

const OrderSummary = ({ currentOrderData }) => {
  const {
    completedAt,
    completedAtSec,
    createdAt,
    createdAtSec,
    status,
    transactionId,
    user,
  } = currentOrderData;

  const userLink = () => {
    if (!user) {
      return <Typography color="t1" text="No user for this order." />;
    }

    const linkContent =
      user.firstName && user.lastName
        ? `${user.firstName} ${user.lastName}`
        : user.email;

    return (
      <Link to={`/users/${user.id}`}>
        <Typography color="link" text={linkContent} />
      </Link>
    );
  };

  const formattedDates = {
    completedAt: makeDateString({
      date: completedAt,
      dateMs: completedAtSec,
    }),
    createdAt: makeDateString({
      date: createdAt,
      dateMs: createdAtSec,
    }),
  };

  const renderOriginalTransaction = () => {
    return (
      transactionId && (
        <div>
          <Typography text="Original" />
          <Link
            to={{
              pathname: `${routePaths.orders}/${transactionId}`,
              state: { locationOrderId: transactionId },
            }}
          >
            {transactionId}
          </Link>
        </div>
      )
    );
  };

  return (
    <SummaryWrapper fullWidth>
      <StyledSummaryColumn>
        <Typography color="t1" text="User" />
        {userLink()}
      </StyledSummaryColumn>
      <StyledSummaryColumn>
        <Typography color="t1" text="Created At" />
        <Typography color="t1" text={formattedDates.createdAt} />
      </StyledSummaryColumn>
      <StyledSummaryColumn>
        <Typography color="t1" text="Completed" />
        <Typography color="t1" text={formattedDates.completedAt} />
      </StyledSummaryColumn>
      <StyledSummaryColumn>
        <Typography color="t1" text="Status" />
        <Typography color="t1" text={status} />
      </StyledSummaryColumn>
      {renderOriginalTransaction()}
    </SummaryWrapper>
  );
};

export default OrderSummary;
