import { ProductColorCode } from 'gantri-components';
import { ProductCategory } from '../../api/products/products.types';
import { StockSummarySortingField } from '../../api/stocks/routes/fetch-stock-summary/fetch-stock-summary.types';
import {
  getSortByFilterAtomFamily,
  SortBy,
} from '../../components/common/table/hooks';
import { getGenericAtomFamily } from '../../helpers/get-generic-atom-family';
import { AtomPageName } from '../../helpers/get-generic-atom-family/get-generic-atom-family.types';

export const stockSummaryFiltersDefaults: {
  filters: {
    category: ProductCategory[];
    colors: ProductColorCode[];
    designerId: number;
    productId: number;
  };
  sortBy: SortBy<StockSummarySortingField>;
} = {
  filters: {
    category: [],
    colors: [],
    designerId: null,
    productId: null,
  },
  sortBy: {
    sortingField: 'name',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'STOCK_SUMMARY_FILTERS';

export const pageName: AtomPageName = 'stock-summary';

export const stockSummaryPageAtoms = {
  defaults: stockSummaryFiltersDefaults,
  filters: {
    category: getGenericAtomFamily({
      defaultValue: stockSummaryFiltersDefaults.filters.category,
      key: `${atomKeyPrefix}-category`,
    })(pageName),
    colors: getGenericAtomFamily({
      defaultValue: stockSummaryFiltersDefaults.filters.colors,
      key: `${atomKeyPrefix}-colors`,
    })(pageName),
    designerId: getGenericAtomFamily({
      defaultValue: stockSummaryFiltersDefaults.filters.designerId,
      key: `${atomKeyPrefix}-designer-id`,
    })(pageName),
    productId: getGenericAtomFamily({
      defaultValue: stockSummaryFiltersDefaults.filters.productId,
      key: `${atomKeyPrefix}-product-id`,
    })(pageName),
  },
  sortBy: getSortByFilterAtomFamily<SortBy<StockSummarySortingField>>({
    defaultValue: stockSummaryFiltersDefaults.sortBy,
  })(pageName),
};
