import { atom } from 'recoil';
import { SortableLifestylePhotoData } from './components/lifestyle-photos-section-item/lifestyle-photos-section-item.types';

const atomKeyPrefix = 'LIFESTYLE-PRODUCT-ASSETS';

export const lifestyleProductAssetsAtoms = {
  editingLifestylePhotoData: atom<SortableLifestylePhotoData>({
    default: null,
    key: `${atomKeyPrefix}-editing-lifestyle-photo-data`,
  }),
};
