import { Box, Stack } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledPreviewStack = styled(Stack)`
  ${({ theme }) => {
    return css`
      border: 1px solid ${theme.colors.dividers.t1};
      background-color: ${theme.colors.surfaces.monochrome.t1Alt};
    `;
  }}
`;

export const StyledFirstLifestylePreviewBox = styled(Box)`
  position: absolute;
  top: 175px;
  left: calc(50% - 71px);
  transform: translateX(-50%);
`;

export const StyledSecondLifestylePreviewBox = styled(Box)`
  position: absolute;
  top: 293px;
  left: calc(50% - 71px);
  transform: translateX(-50%);
`;
