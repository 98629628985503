import styled, { css } from 'styled-components';
import { palette } from 'gantri-components';
import { transitions } from '../../constants/styles';

export const StyledGantriLogoGroup = styled.g`
  fill: ${(props) => {
    return props.fillColor || palette.monochrome_black;
  }};
  ${transitions.base}
  transition-property: fill;
`;

export const StyledLargeGroup = styled.g`
  ${transitions.base}
  transition-property: opacity;
  opacity: ${({ isCompressed }) => {
    return isCompressed ? '0' : '1';
  }};
`;

export const StyledGantriLogoWrapper = styled.div`
  ${({ hoverColor, noPointer }) => {
    return (
      hoverColor &&
      css`
        &:hover {
          ${noPointer ? '' : ' cursor: pointer;'}
          ${StyledGantriLogoGroup} {
            fill: ${hoverColor};
          }
        }
      `
    );
  }}
`;
