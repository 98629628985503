import { FormikInput, TextField } from 'gantri-components';
import { addOrEditPartModalType } from '../../../../add-or-edit-part-modal.constants';
import { PartNumPrintsProps } from './part-num-prints.types';

export const PartNumPrints = (props: PartNumPrintsProps) => {
  const { modalType } = props;

  const isEditModal = modalType === addOrEditPartModalType.edit;

  return (
    <FormikInput
      disabled={isEditModal}
      Field={<TextField type="number" />}
      fieldVariant="standard"
      labelPosition="top"
      labelText="# Prints"
      name="totalPrintBlocks"
      placeholder="Input number"
      required
    />
  );
};
