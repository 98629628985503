import { Conditional, Typography } from 'gantri-components';
import { CellContext } from '@tanstack/react-table';
import { useSetRecoilState } from 'recoil';
import { QuantityDropdown } from '../../../../../../components/common/select-product-form/components/select-product-form-content/components/quantity-dropdown';
import { newStocksAtoms } from '../../../../new-stocks.atoms';
import { useIsEditingRow } from '../../hooks/use-is-editing-row';
import { TableDataItem } from '../../use-get-new-stocks-table-details.types';
import { QuantityOptionItem } from '../../../../../../components/common/select-product-form/components/select-product-form-content/components/quantity-dropdown/quantity-dropdown.types';

export const NewStockQuantityCell = (
  props: CellContext<TableDataItem, TableDataItem['quantity']>,
) => {
  const { getValue, row } = props;
  const { id, product } = row.original;
  const quantity = getValue();

  const setTableData = useSetRecoilState(newStocksAtoms.tableData);

  const { isEditingRow } = useIsEditingRow(row.original);

  const onSelect = (item: QuantityOptionItem) => {
    setTableData((oldTableData) => {
      return oldTableData.map((data) => {
        const newData: TableDataItem =
          data.id === id
            ? {
                ...data,
                quantity: item.value,
              }
            : data;

        return newData;
      });
    });
  };

  return (
    <Conditional
      condition={isEditingRow}
      Fallback={<Typography text={quantity} />}
    >
      <QuantityDropdown
        productId={product.id}
        value={quantity}
        onSelect={onSelect}
      />
    </Conditional>
  );
};
