import { Cell, Conditional, Grid, Stack, Typography } from 'gantri-components';
import { GridProps } from 'gantri-components/dist/components/grid/grid.types';
import { Overview } from './components/overview';
import Timeline from '../../components/common/timeline';
import { useHandleFetchDesign } from './hooks/fetch-design';
import { Messages } from './components/messages';
import TimelineItem from '../../components/common/timeline/base-timeline-item';
import MetaData from '../../components/meta-data';
import { PageHeading } from '../../components/layout/page-heading';
import Thumbnail from '../../components/common/thumbnail/thumbnail';
import { StyledFlexCategorySquare } from './design.styles';
import {
  productLightCategories,
  productLightSubCategories,
} from '../../api/products/products.constants';
import { DesignCategory } from './components/design-category';
import routePaths from '../../config/route-paths';
import { StyledAnchor } from '../../components/common/styled-anchor';
import { NotApplicableText } from '../../components/common/not-applicable-text';
import { placeholderImageSrc } from '../../constants/images';

export const Design = () => {
  const { current, setCurrent } = useHandleFetchDesign();

  const frontSketch =
    current?.sketches?.front?.uploadedFile?.fileUrl || placeholderImageSrc;

  const products = current?.products || [];
  const numProducts = products.length;

  const productGridProps: GridProps =
    numProducts > 3
      ? {
          columns: { lg: 2, md: 1 },
          flow: { lg: 'column', md: 'unset' },
          rows: {
            lg: Math.ceil(numProducts / 2),
            md: 'unset',
          },
        }
      : { columns: 1 };

  return current?.concept ? (
    <>
      <MetaData title={`Design ${current.name}`} />
      <PageHeading
        subTitle={current.designerName}
        subTitleProps={{
          color: 'link',
          to: `/designers/${current.userId}`,
        }}
        title={current.name}
      />
      <Grid columns="2fr 1fr" gap="3x" paddingTop="2x">
        <Cell>
          <Stack gap="10x">
            <Cell>
              <Stack gap="3x">
                <Cell>
                  <Grid
                    alignItems="center"
                    columnGap="3x"
                    columns={3}
                    flow="column"
                    rowGap=".5x"
                    rows={2}
                  >
                    <DesignCategory
                      designId={current?.id}
                      initialValue={current.concept.subSteps['1'].category}
                      options={Object.values(productLightCategories)}
                      title="Category"
                      type="category"
                    />
                    <DesignCategory
                      designId={current?.id}
                      initialValue={current.concept.subSteps['2'].subCategory}
                      options={Object.values(productLightSubCategories)}
                      title="Sub-category"
                      type="subCategory"
                    />
                  </Grid>
                </Cell>
                <Stack gap="2x">
                  <Typography text="Size" />
                  <StyledFlexCategorySquare
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color="alt"
                      text={
                        current.concept.subSteps['3'].size.label ||
                        current.concept.subSteps['3'].size.name
                          .slice(0, 1)
                          .toUpperCase()
                      }
                      textStyle="bold"
                      variant="p1"
                    />
                  </StyledFlexCategorySquare>
                </Stack>
              </Stack>
            </Cell>

            <Grid alignItems="center" columns="16rem 1fr" gap="3rem">
              <Cell>
                <Thumbnail size="16rem" src={frontSketch} />
              </Cell>
              <Stack gap="x">
                <Stack gap="2x">
                  <Typography
                    text={`${current.name} is completed.`}
                    variant="h4"
                  />
                  <Typography text="View product(s):" />
                </Stack>

                <Conditional
                  condition={!!numProducts}
                  Fallback={<NotApplicableText />}
                >
                  <Grid
                    columnGap="2x"
                    maxWidth="45.4rem"
                    rowGap="x"
                    {...productGridProps}
                  >
                    {products.map(({ id, name }) => {
                      return (
                        <StyledAnchor
                          key={id}
                          target="_blank"
                          text={name}
                          to={`${routePaths.products}/${id}`}
                        />
                      );
                    })}
                  </Grid>
                </Conditional>
              </Stack>
            </Grid>

            <Cell>
              <Overview design={current} />
            </Cell>

            <Cell>
              <Timeline
                items={current.timelines || []}
                renderItem={TimelineItem}
              />
            </Cell>

            <Cell />
          </Stack>
        </Cell>
        <Cell>
          <Messages
            current={current}
            designId={current.id}
            messages={current.messages || []}
            notes={current.notes || []}
            updateMessages={setCurrent}
          />
        </Cell>
      </Grid>
    </>
  ) : null;
};
