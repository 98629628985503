import * as yup from 'yup';
import {
  JobBlockInstruction,
  InstructionJobMap,
  JobBlockStock,
} from '../../../../../../../../api/products/products.types';
import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../constants/options';
import {
  instructionsJobMapSchema,
  jobBlockInstructionsSchema,
  mapInstructionsToLabels,
} from '../../../parts-section/components/part-job-blocks/part-job-blocks.schema';

export interface StockJobsInstructionsFormData {
  id: number;
  instructions: JobBlockInstruction[];
  instructionsJobMap: InstructionJobMap[];
}

export const getStockJobsInstructionsInitialValues = ({
  isAccessory,
  stockJobsBlock,
}: {
  isAccessory: boolean;
  stockJobsBlock: JobBlockStock;
}): StockJobsInstructionsFormData => {
  const initialInstructionJobMap = stockJobsBlock?.instructionsJobMap || [];

  const instructionsJobMap = isAccessory
    ? initialInstructionJobMap.filter(({ step, type }) => {
        return step === jobSteps.pack && type === jobTypeOptions.pack;
      })
    : initialInstructionJobMap;

  return {
    id: stockJobsBlock?.id,
    instructions:
      mapInstructionsToLabels({
        instructions: stockJobsBlock.instructions,
        instructionsJobMap,
      }) || [],
    instructionsJobMap,
  };
};

export const stockJobsInstructionsFormikSchema: yup.SchemaOf<StockJobsInstructionsFormData> =
  yup.object().shape({
    id: yup.number().required(),
    instructions: jobBlockInstructionsSchema,
    instructionsJobMap: instructionsJobMapSchema,
  });
