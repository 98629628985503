import {
  Box,
  Conditional,
  Icon,
  Stack,
  TextArea,
  Typography,
  getPurifiedFileName,
  HandleDeleteFile,
  HandleUploadsComplete,
} from 'gantri-components';
import { useEffect, useState } from 'react';
import { useGetIsEditingRow } from '../../../use-get-is-editing-row';
import { useValidateFields } from '../job-template-type-cell/hooks/use-validate-fields';
import { jobTemplateFloatingPartFileUploadPath } from '../../../../../../../helpers/gcodes';
import { JobTemplateInstructionCellProps } from './job-template-instruction-cell.types';
import { useDownload } from '../../../../../../../hooks/use-download';
import { InstructionUploader } from './components/instruction-file-uploader';
import { StyledAnchor } from '../../../../../styled-anchor';

export const JobTemplateInstructionCell = (
  props: JobTemplateInstructionCellProps,
) => {
  const { getValue, onEdit, row } = props;

  const isEditingRow = useGetIsEditingRow(row);

  const { getHasError } = useValidateFields();
  const hasError = getHasError('instruction');

  const instruction = getValue();

  const [instructionText, setInstructionText] = useState<string>(
    instruction?.text,
  );

  const { downloadSingleFile } = useDownload();

  const uploadPath = jobTemplateFloatingPartFileUploadPath({
    fileType: 'instructions',
  });

  const handleUploadsComplete: HandleUploadsComplete = async ([
    { fileName, fileUrl },
  ]) => {
    onEdit({
      ...row.original,
      instruction: {
        files: [
          {
            fileName,
            path: uploadPath,
            url: fileUrl,
          },
        ],
        text: instructionText,
      },
    });
  };

  const getOnFileDelete = (index: number): HandleDeleteFile => {
    return async () => {
      const updatedFiles = instruction?.files?.filter((_data, deleteIndex) => {
        return deleteIndex !== index;
      });

      onEdit({
        ...row.original,
        instruction: {
          files: updatedFiles,
          text: instruction?.text,
        },
      });
    };
  };

  useEffect(() => {
    setInstructionText(instruction?.text);
  }, [instruction?.text]);

  return (
    <Box
      onClick={(event) => {
        return event.stopPropagation();
      }}
    >
      <Conditional
        condition={isEditingRow}
        Fallback={
          <Stack gap="0.4rem">
            <Conditional condition={!!instruction?.text}>
              <Typography
                htmlText={instruction?.text?.replace(/\n/g, '<br />')}
              />
            </Conditional>
            {instruction?.files?.map((props) => {
              const { fileName, url } = props;

              return (
                <StyledAnchor
                  key={url}
                  icon={<Icon color="link" name="arrows:arrow_external" />}
                  iconPosition="right"
                  text={getPurifiedFileName(fileName)}
                  onClick={() => {
                    return downloadSingleFile({
                      fileName,
                      url,
                    });
                  }}
                />
              );
            })}
          </Stack>
        }
      >
        <Stack gap="0.8rem">
          <TextArea
            placeholder="Enter text instruction..."
            style={{ height: '10rem' }}
            value={instruction?.text}
            onBlurTextChange={(value) => {
              onEdit({
                ...row.original,
                instruction: {
                  ...instruction,
                  text: value,
                },
              });
            }}
            onTextChange={setInstructionText}
          />

          <Conditional
            condition={!!instruction?.files?.length}
            Fallback={
              <InstructionUploader
                file={null}
                handleUploadsComplete={handleUploadsComplete}
                hasError={hasError}
                uploadPath={uploadPath}
                variant="button"
                onFileDelete={null}
              />
            }
          >
            {instruction?.files?.map((file, index) => {
              const onFileDelete = getOnFileDelete(index);

              return (
                <InstructionUploader
                  key={file.url}
                  file={file}
                  handleUploadsComplete={null}
                  hasError={hasError}
                  uploadPath={null}
                  onFileDelete={onFileDelete}
                />
              );
            })}
          </Conditional>
        </Stack>
      </Conditional>
    </Box>
  );
};
