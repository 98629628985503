import { Conditional, Icon, Overlay } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import LoadingSpinner from '../spinner';
import {
  StyledGLogoWrapper,
  StyledLoaderOverlay,
  StyledForceCloseButtonContainer,
} from './full-page-loading-spinner.styles';
import GantriLogo from '../../gantri-logo';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../jobs-details-panel-wrapper/components/jobs-details-panel';
import { fullPageLoadingSpinnerAtoms } from './full-page-loading-spinner.atoms';

export const FullPageLoadingSpinner = () => {
  const [showSpinner, setShowSpinner] = useRecoilState(
    fullPageLoadingSpinnerAtoms.showSpinner,
  );
  const [showForceClose, setShowForceClose] = useState<boolean>(false);

  const debouncedShowForceClose = useRef(
    // ref required to correctly cancel debounce called via state changes https://stackoverflow.com/a/74738879
    debounce(() => {
      setShowForceClose(true);
    }, 5000),
  ).current;

  useEffect(() => {
    if (showSpinner && !showForceClose) {
      debouncedShowForceClose();
    } else if (!showSpinner) {
      if (showForceClose) {
        setShowForceClose(false);
      } else {
        debouncedShowForceClose.cancel();
      }
    }
  }, [showSpinner]);

  return (
    <Overlay
      content={
        <StyledLoaderOverlay
          key="loader"
          data-linked-to={JOB_DETAILS_PANEL_LINKED_TO}
          data-loading-overlay=""
        >
          <Conditional condition={showForceClose}>
            <StyledForceCloseButtonContainer
              onClick={() => {
                setShowSpinner(false);
              }}
            >
              <Icon name="ui-control:x" size="1.2rem" />
            </StyledForceCloseButtonContainer>
          </Conditional>
          <LoadingSpinner color="green_400" size="10rem" timing="750ms" />
          <StyledGLogoWrapper>
            <GantriLogo fillColor="green_400" isCompressed noPointer />
          </StyledGLogoWrapper>
        </StyledLoaderOverlay>
      }
      open={showSpinner}
      triggerEvent="manual"
    />
  );
};
