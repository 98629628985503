import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useNotification } from '../../../../hooks/useNotification';
import { updateFeatureSettings } from './update-feature-settings';
import {
  UpdateFeatureSettingsArgs,
  UpdateFeatureSettingsResponse,
} from './update-feature-settings.types';

export const useUpdateFeatureSettings = () => {
  const { notify, notifyAxiosError } = useNotification();

  const { onMutate: onUpdateFeatureSetting, ...rest } = useQueryMutation<
    UpdateFeatureSettingsArgs,
    UpdateFeatureSettingsResponse
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage:
            'Error occurred while updating feature page settings.',
        });
      },
      onSuccess: (data) => {
        const { notice } = data;

        notify(notice);
      },
    },
    mutationFn: async (args) => {
      return updateFeatureSettings(args).then(({ data }) => {
        return data;
      });
    },
    showLoading: true,
  });

  return { ...rest, onUpdateFeatureSetting };
};
