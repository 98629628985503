import { Button, Flex, Icon, Tooltip } from 'gantri-components';
import {
  JobStep,
  JobType,
} from '../../../../../../../../../../../../constants/options';
import { AddInstructionsButtonProps } from './add-instructions-button.types';

export const AddInstructionsButton = (props: AddInstructionsButtonProps) => {
  const { instructions, instructionsJobMap, push } = props;

  const isEveryJobOptionInUse = instructionsJobMap.every(({ step, type }) => {
    return instructions.some((instruction) => {
      return step === instruction.step && type === instruction.type;
    });
  });

  const isMissingRequiredFields = instructions.some((instruction) => {
    const { data, step, type } = instruction;
    const { files, text } = data;

    const isMissingFileData =
      !files?.length ||
      files?.some(({ url }) => {
        return !url;
      });

    const isMissingInstructionData = !text && isMissingFileData;

    return !step || !type || isMissingInstructionData;
  });

  const getDisabledReason = () => {
    if (!!instructions.length) {
      if (isMissingRequiredFields) {
        return 'You must fill out the required instructions fields before you can add a new instruction.';
      }

      if (isEveryJobOptionInUse) {
        return 'Every job type is already in use. Please update an existing instruction.';
      }
    }

    return '';
  };

  const disabledReason = getDisabledReason();
  const disableAddInstruction = !!disabledReason;

  const onAddInstructions = () => {
    push({
      data: {
        files: [],
        text: '',
      },
      step: '' as JobStep,
      type: '' as JobType,
    });
  };

  return (
    <Flex justifySelf="start">
      <Tooltip description={getDisabledReason()} position="top">
        <Button
          disabled={disableAddInstruction}
          icon={
            <Icon
              color={disableAddInstruction ? 't1' : 'link'}
              name="ui-control:plus"
            />
          }
          text="Add Instruction"
          variant="ghost"
          onClick={onAddInstructions}
        />
      </Tooltip>
    </Flex>
  );
};
