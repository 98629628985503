import {
  Badge,
  Cell,
  ColorPickerItem,
  Conditional,
  Flex,
  Grid,
  productColorsMap,
  Stack,
  Typography,
} from 'gantri-components';
import { useTheme } from 'styled-components';
import routePaths from '../../../../../../config/route-paths';
import { SearchPartSummaryThumbnail } from '../search-part-summary-thumbnail';
import { getPartStatusBadgeColors } from '../../../../../../components/common/part-status-badge/helpers/get-part-status-badge-colors';
import { SearchPartSummaryDetailsProps } from './search-part-summary-details.types';
import { unpaintedColor } from '../../../../../../constants/colors';
import { ShortProductSummary } from '../../../../../../components/common/short-product-summary';
import { StyledLargeTextWrapper } from './search-part-summary-details.styles';
import { Divider } from '../../../../../../components/divider';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import { NotApplicableText } from '../../../../../../components/common/not-applicable-text';
import { Label } from '../../../../../../components/label';

export const SearchPartSummaryDetails = (
  props: SearchPartSummaryDetailsProps,
) => {
  const { part } = props;

  const theme = useTheme();
  const badgeColors = getPartStatusBadgeColors({ status: part.status, theme });

  const productColorCode = part.color;
  const productColorName = productColorsMap[productColorCode]?.shortColorName;

  const { batchId } = part;

  const batchUrl = `${routePaths.batches}/${batchId}`;
  const batchText = `Batch #${batchId}`;

  const locationName = part.location?.name;

  return (
    <Stack gap="0">
      <Stack gap="x" padding="2x">
        <Grid alignItems="center" columns="max-content 1fr" gap="2x">
          <StyledAnchor
            fontSize="2.4rem"
            fontWeight="bold"
            lineHeight="3.6rem"
            text={`Part #${part.id}`}
            to={`${routePaths.parts}/${part.id}`}
          />

          <Cell justifyContent="flex-start">
            <Badge {...badgeColors} size="large" text={part.status} />
          </Cell>
        </Grid>

        <Typography fontSize="2.4rem" lineHeight="3.6rem" text={part.name} />
      </Stack>

      <Divider />

      <Cell hidden={{ lg: true, sm: false }}>
        <SearchPartSummaryThumbnail part={part} />
        <Divider />
      </Cell>

      <Grid
        alignItems="start"
        columnGap="2x"
        columns={2}
        horizontalPadding="2x"
        overflow="auto"
        rowGap="4x"
        verticalPadding="3x"
      >
        <Cell rowGap=".5x">
          <Label text="Part Color" />
          <Grid alignItems="center" columns="max-content 1fr" gap="0.8rem">
            <ColorPickerItem
              color={{
                code: productColorCode,
                name: productColorName,
              }}
              size="2rem"
            />
            <Typography
              text={productColorName || unpaintedColor.name}
              variant="p1"
            />
          </Grid>
        </Cell>

        <Cell rowGap=".5x">
          <Label text="Material" />
          <Typography text={part.material || '-'} variant="p1" />
        </Cell>

        <Cell minWidth={{ lg: '28rem', md: 'unset' }} rowGap=".5x">
          <Label text="Product" />
          <StyledLargeTextWrapper>
            <ShortProductSummary
              columns={{
                lg: 'max-content 1fr',
                md: '1fr',
                sm: 'max-content 1fr',
              }}
              imageSize="6rem"
              {...part.product}
            />
          </StyledLargeTextWrapper>
        </Cell>

        <Cell rowGap=".5x">
          <Label text="Order" />
          <Conditional
            condition={!!part.order?.id}
            Fallback={<NotApplicableText variant="p1" />}
          >
            <Flex gap=".5x">
              <Typography color="t2" text={part.order?.type} variant="p1" />
              <StyledAnchor
                text={`#${part.order?.id}`}
                to={`${routePaths.orders}/${part.order?.id}`}
                variant="p1"
              />
            </Flex>
          </Conditional>
        </Cell>

        <Cell rowGap=".5x" width={2}>
          <Label text="Location" />
          <Conditional
            condition={!!batchId || !!locationName}
            Fallback={<Typography text="Unassigned" variant="p1" />}
          >
            <Conditional
              condition={!!batchId && !!locationName}
              Fallback={
                <>
                  <Conditional condition={!!batchId}>
                    <StyledAnchor text={batchText} to={batchUrl} variant="p1" />
                  </Conditional>
                  <Conditional condition={!!locationName}>
                    <Typography
                      text={locationName || 'Unassigned'}
                      variant="p1"
                    />
                  </Conditional>
                </>
              }
            >
              <Typography
                htmlText={`<a style="text-decoration: underline" href="${batchUrl}">${batchText}</a>, ${locationName}`}
                variant="p1"
              />
            </Conditional>
          </Conditional>
        </Cell>
      </Grid>
    </Stack>
  );
};
