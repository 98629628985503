import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  OverrideDelayedPartsArgs,
  OverrideDelayedPartsResponse,
} from './override-delayed-parts.types';
import { overrideDelayedParts } from './override-delayed-parts';

export const useOverrideDelayedParts = <
  TransformedData = OverrideDelayedPartsResponse,
>(
  props?: GenericMutateQueryProps<
    OverrideDelayedPartsArgs,
    OverrideDelayedPartsResponse,
    TransformedData
  >,
) => {
  const { onMutate: onOverridePartDelays, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to override part delays.',
    mutationFn: overrideDelayedParts,
    ...props,
  });

  return { ...rest, onOverridePartDelays };
};
