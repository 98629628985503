import { atom } from 'recoil';
import { AddStocksModalStep } from './add-stocks-modal.types';
import { addStocksModalSteps } from './add-stocks-modal.constants';

const keyPrefix = 'STOCK_SUMMARY_ADD_STOCKS_MODAL';

export const addStocksModalAtoms = {
  step: atom<AddStocksModalStep>({
    default: addStocksModalSteps.setQty,
    key: `${keyPrefix}-step`,
  }),
};
