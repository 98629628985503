import {
  Button,
  Modal,
  SearchField,
  Stack,
  Typography,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';
import { productAtoms } from '../../../../../../product.atoms';
import { designStatuses } from '../../../../../../../../constants/options';
import { useFetchPaginatedDesigns } from '../../../../../../../../api/designs/routes';
import { useUpdateProductDesign } from '../../../../../../../../api/products/routes';
import { DesignRecord } from '../../../../../../../../api/designs/routes/fetch-paginated-designs/fetch-paginated-designs.types';
import { SelectDesignModalProps } from './select-design-modal.types';
import { useFetchProductData } from '../../../../../../hooks/use-fetch-product-data';

export const SelectDesignModal = (props: SelectDesignModalProps) => {
  const { onClose } = props;

  const product = useRecoilValue(productAtoms.product);

  const [completedDesigns, setCompletedDesigns] = useState<DesignRecord[]>([]);
  const [selectedDesign, setSelectedDesign] = useState<number>(
    product.designs?.[0]?.id,
  );

  const { isLoading: isLoadingDesignsList } = useFetchPaginatedDesigns({
    fetchArgs: {
      action: false,
      categories: [],
      count: 1000,
      page: 1,
      search: '',
      sortingField: 'name',
      sortingType: 'ASC',
      statuses: [designStatuses.completed],
      steps: [],
    },
    onSuccess: async ({ designs }) => {
      setCompletedDesigns(designs);
    },
  });

  const { refetchProduct } = useFetchProductData();

  const { isLoading: isLoadingUpdateProductDesign, onUpdateProductDesign } =
    useUpdateProductDesign({
      onSuccess: async () => {
        await refetchProduct();
        onClose();
      },
    });

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            processing={isLoadingUpdateProductDesign}
            size="large"
            text="Confirm"
            onClick={async () => {
              await onUpdateProductDesign({
                designId: selectedDesign,
                id: product.id,
              });
            }}
          />
        </>
      }
      header="Select Design"
      maxWidth={{ lg: '46rem', md: '100%' }}
      onClose={onClose}
    >
      <Stack alignContent="center" minHeight="12.7rem">
        <SearchField
          idProperty="id"
          items={completedDesigns}
          labelProperty="name"
          labelText="Select design"
          maxHeight="30rem"
          placeholder="Search designs"
          processing={isLoadingDesignsList}
          renderItem={(item) => {
            return (
              <Typography
                horizontalPadding="2x"
                text={`${item?.name} (#${item?.id})`}
                verticalPadding="x"
              />
            );
          }}
          renderPlaceholder={(item) => {
            return item ? (
              <Typography
                horizontalPadding="2x"
                text={`${item.name} (#${item.id})`}
                verticalPadding="x"
              />
            ) : null;
          }}
          required
          value={selectedDesign}
          onSelect={(item) => {
            setSelectedDesign(item?.id);
          }}
        />
      </Stack>
    </Modal>
  );
};
