import {
  Box,
  Cell,
  Conditional,
  Grid,
  Icon,
  Stack,
  Tooltip,
  Typography,
} from 'gantri-components';
import { ColorSwatchAndLabel } from '../../../../components/common/color-swatch-and-label';
import { DateWithUserTooltip } from '../../../../components/common/date-with-user-tooltip';
import { Divider } from '../../../../components/divider';
import { Label } from '../../../../components/label';
import { BatchStatusBadge } from '../../../batches/components/batch-badge';
import { SandDurationCell } from '../../../batches/hooks/use-get-batches-columns/components/sand-duration-cell';
import { SandDurationHeader } from '../../../batches/hooks/use-get-batches-columns/components/sand-duration-header';
import { BatchSummaryProps } from './batch-summary.types';

export const BatchSummary = (props: BatchSummaryProps) => {
  const { batch } = props;

  return (
    <Grid
      alignItems="stretch"
      columnGap="3x"
      columns={{
        lg: `repeat(${batch?.archivedAt ? '10' : '8'}, max-content) 1fr`,
        sm: '1fr max-content 1fr',
      }}
      rowGap="3x"
    >
      <Stack gap="x">
        <Label text="Colors" />
        <Conditional
          condition={!!batch?.colors?.length}
          Fallback={<Typography text="-" />}
        >
          {batch?.colors?.map((colorCode) => {
            return (
              <ColorSwatchAndLabel key={colorCode} colorCode={colorCode} />
            );
          })}
        </Conditional>
      </Stack>

      <Divider borderLocation="left" height="100%" />

      <Stack gap="x">
        <Label text="Status" />
        <Box>
          <BatchStatusBadge status={batch?.status} />
        </Box>
      </Stack>

      <Cell hidden={{ sm: true }}>
        <Divider borderLocation="left" height="100%" />
      </Cell>

      <Stack gap="x">
        <SandDurationHeader />
        <Box>
          <SandDurationCell sandDuration={batch.totalSandRawDuration} />
        </Box>
      </Stack>

      <Divider borderLocation="left" height="100%" />

      <Stack gap="x">
        <Label
          icon={
            <Tooltip
              maxWidth="22.4rem"
              position="top-start"
              title="Current job step/type of all parts in the batch."
            >
              <Icon name="alert:i_circle" />
            </Tooltip>
          }
          iconPosition="right"
          text="Current Step"
        />

        <Conditional
          condition={!!batch?.currentSteps?.length}
          Fallback={<Typography text="-" />}
        >
          {batch?.currentSteps?.map((partStep) => {
            return <Typography key={partStep} text={partStep} />;
          })}
        </Conditional>
      </Stack>

      <Cell hidden={{ sm: true }}>
        <Divider borderLocation="left" height="100%" />
      </Cell>

      <Stack gap="x">
        <Label text="Created" />
        <DateWithUserTooltip
          date={batch?.createdAt}
          statusInfo={batch?.statusInfo}
          type="created"
        />
      </Stack>

      <Conditional condition={!!batch?.archivedAt}>
        <Cell hidden={{ sm: true }}>
          <Divider borderLocation="left" height="100%" />
        </Cell>

        <Stack gap="x">
          <Label text="Archived" />
          <DateWithUserTooltip
            date={batch?.archivedAt}
            statusInfo={batch?.statusInfo}
            type="archived"
          />
        </Stack>
      </Conditional>
    </Grid>
  );
};
