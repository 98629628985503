import { Button, Conditional, Icon } from 'gantri-components';
import { useState } from 'react';
import { useToggle } from 'react-use';
import { DetailsPanel } from '../../../../../../components/common/details-panel';
import Timeline from '../../../../../../components/common/timeline';
import TimelineItem from '../../../../../../components/common/timeline/base-timeline-item';
import {
  BasicTimelineItemDef,
  ViewHistoryButtonPropsDef,
} from './view-history-button.types';

export const ViewHistoryButton = <History extends BasicTimelineItemDef>(
  props: ViewHistoryButtonPropsDef<History>,
) => {
  const { loadHistory, renderItem = TimelineItem } = props;

  const [showHistory, toggleShowHistory] = useToggle(false);
  const [history, setHistory] = useState<History[]>([]);

  return (
    <>
      <Button
        icon={<Icon color="t2" name="time:clock" />}
        variant="tertiary"
        onClick={async () => {
          if (!showHistory) {
            const history = await loadHistory();

            setHistory(history);
          }

          toggleShowHistory();
        }}
      />
      <Conditional condition={showHistory}>
        <DetailsPanel
          open={showHistory}
          title="History"
          onClose={toggleShowHistory}
        >
          {history && (
            <Timeline
              isTitleVisible={false}
              items={history}
              renderItem={renderItem}
            />
          )}
        </DetailsPanel>
      </Conditional>
    </>
  );
};
