import { useState } from 'react';
import { productFileTypesMaxQty } from '../../../../../../../sku-level-assets/components/bulk-upload-modal/bulk-upload-modal.constants';
import { FormattedSkuLevelAssetData } from '../../../../../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';
import { SkuAssetDownloadData } from './use-sku-assets-review-download-data.types';

export const useSkuAssetsReviewDownloadData = () => {
  const [downloadData, setDownloadData] = useState<SkuAssetDownloadData[]>([]);

  const handleCreateAndSetDownloadData = (
    assets: FormattedSkuLevelAssetData[],
  ) => {
    const downloadData = assets.map(
      ({
        dimmingPhotoDark,
        dimmingPhotoLight,
        scalePhoto,
        sku,
        usdz,
        whiteBackgroundPhotos,
      }) => {
        const getStatusIcon = (isValid: boolean) => {
          return isValid ? '✅' : '❌';
        };

        const whiteBackgroundPhotosStatus = whiteBackgroundPhotos.map(
          (photo) => {
            return getStatusIcon(!!photo);
          },
        );

        for (
          let value = whiteBackgroundPhotosStatus.length;
          value < productFileTypesMaxQty.whiteBackgroundPhotos;
          value++
        ) {
          whiteBackgroundPhotosStatus.push(getStatusIcon(false));
        }

        const data: SkuAssetDownloadData = {
          dimmingPhotoDark: getStatusIcon(!!dimmingPhotoDark),
          dimmingPhotoLight: getStatusIcon(!!dimmingPhotoLight),
          scalePhoto: getStatusIcon(!!scalePhoto),
          sku,
          usdz: getStatusIcon(!!usdz),
          whiteBackgroundPhotos: whiteBackgroundPhotosStatus.join(''),
        };

        return data;
      },
    );

    setDownloadData(downloadData);
  };

  return { downloadData, handleCreateAndSetDownloadData };
};
