import { useRecoilValue } from 'recoil';
import { UploadFileResponse } from '../../../../../../../../../../../../../../../../api/cloudinary/routes/upload-file/upload-file.types';
import { useUploadBlob } from '../../../../../../../../../../../../../../../../helpers/firebase';
import { productAtoms } from '../../../../../../../../../../../../../../product.atoms';
import { SkuAssetUploadFileData } from '../../../../../../../../../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';
import { getTransformProductAssetFileName } from '../../../../../../../../../../../../helpers/get-transform-product-asset-file-name';

export const useHandleBulkUploadUsdz = () => {
  const product = useRecoilValue(productAtoms.product);

  const uploadBlob = useUploadBlob();

  const handleBulkUploadUsdz = async (props: {
    sku: string;
    usdz: SkuAssetUploadFileData;
  }) => {
    const { sku, usdz } = props;
    const { fileBlob, fileName, metadata } = usdz;

    const { transformFileName } = getTransformProductAssetFileName({
      fileType: 'model',
      sku,
    });

    const formattedFileName = transformFileName({ fileName });

    const response: UploadFileResponse = {
      fileName: formattedFileName,
      fileUrl: undefined,
    };

    await uploadBlob({
      blob: fileBlob,
      displayLoader: false,
      filename: formattedFileName,
      hideUploadNotification: true,
      metadata: { ...metadata, name: formattedFileName },
      onComplete: async (url) => {
        response.fileUrl = url;
      },
      path: `products/${product.id}/model`,
    });

    return response;
  };

  return handleBulkUploadUsdz;
};
