import { Conditional, Grid, Stack, Typography } from 'gantri-components';
import { PartDetailsLastAction } from './components/part-details-last-action/index';
import { PartDetailsPartAttempts } from './components/part-details-part-attempts';
import { PartDetailsOrder } from './components/part-details-order';
import { PartDetailsStock } from './components/part-details-stock';
import { PartDetailsProps } from './part-details.types';
import { PartDetailsEnded } from './components/part-details-ended';
import { PartDetailsCreated } from './components/part-details-created';
import { NotApplicableText } from '../../../../components/common/not-applicable-text';
import { MinorVersionInfo } from '../../../../components/minor-version-info';
import { ShortProductSummary } from '../../../../components/common/short-product-summary';
import { SectionTitle } from '../../../../components/layout/section-title';

export const PartDetails = (props: PartDetailsProps) => {
  const { part, stock } = props;

  return (
    <Stack gap="2x">
      <SectionTitle text="Details" />
      <Grid
        alignItems="start"
        columns={{ lg: '18rem 1fr', sm: 'repeat(2, 1fr)' }}
        gap="1.2rem"
      >
        <Typography text="Created" textStyle="bold" />
        <PartDetailsCreated part={part} />

        <Typography text="Ended" textStyle="bold" />
        <PartDetailsEnded part={part} />

        <Conditional condition={!!part?.statusInfo?.completed?.reason}>
          <Typography text="End reason" textStyle="bold" />
          <Typography text={part?.statusInfo?.completed?.reason} />
        </Conditional>

        <Conditional condition={!!part?.statusInfo?.completed?.reasonDetails}>
          <Typography text="End details" textStyle="bold" />
          <Typography text={part?.statusInfo?.completed?.reasonDetails} />
        </Conditional>

        <Typography text="Last action" textStyle="bold" />
        <PartDetailsLastAction part={part} />

        <Typography text="Product" textStyle="bold" />
        <ShortProductSummary {...part.product} />

        <Typography text="Version" textStyle="bold" />
        <Conditional
          condition={!!part?.versionInfo?.version}
          Fallback={<NotApplicableText />}
        >
          <MinorVersionInfo {...part?.versionInfo} />
        </Conditional>

        <Typography text="Stock" textStyle="bold" />
        <PartDetailsStock stockId={part?.stockId} />

        <Typography text="Order" textStyle="bold" />
        <PartDetailsOrder
          orderId={stock?.orderId}
          orderType={stock?.orderType}
        />

        <Typography text="Part attempts" textStyle="bold" />
        <PartDetailsPartAttempts part={part} />
      </Grid>
    </Stack>
  );
};
