import {
  Cell,
  Box,
  TransformFileName,
  HandleUploadsComplete,
  FileUploader,
} from 'gantri-components';
import SelectInput from '../../../../../../../../components/common/inputs/select-input';
import { useFirebaseFileUploader } from '../../../../../../../../hooks/use-firebase-file-uploader';
import {
  Engineering,
  FinishedPrototype,
} from '../../../../../../../../api/designs/designs.types';
import { RecordUpdated } from '../../../../design-engineering.types';

interface FinishedPrototypeItemPropsDef {
  engineering: Engineering;
  index: number;
  item: FinishedPrototype;
  onFinishedPrototypeUploaded: HandleUploadsComplete<Record<string, any>>;
  prototypeUploadPath: string;
  recordUpdated: RecordUpdated;
  thirdStepColors: any[];
  thumbnailSize: string;
  transformFileName: TransformFileName;
}

export const FinishedPrototypeItem = (props: FinishedPrototypeItemPropsDef) => {
  const {
    engineering,
    index,
    item,
    onFinishedPrototypeUploaded,
    prototypeUploadPath,
    recordUpdated,
    thirdStepColors,
    thumbnailSize,
    transformFileName,
  } = props;

  const onFinishedPrototypeDelete = (index: number) => {
    return async () => {
      const prototypes = [...engineering.finalization.finishedPrototypes];

      prototypes.splice(index, 1);

      await recordUpdated('finalization', 'finishedPrototypes', prototypes)();
    };
  };

  const onColorUpdated = (index: number) => {
    return async (value: string) => {
      const prototypes = [...engineering.finalization.finishedPrototypes];

      if (prototypes[index].fileName !== value) {
        prototypes[index].color = value;

        await recordUpdated('finalization', 'finishedPrototypes', prototypes)();
      }
    };
  };

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl: item.fileUrl,
    handleUploadsComplete: onFinishedPrototypeUploaded,
    onFileDelete: onFinishedPrototypeDelete(index),
    path: prototypeUploadPath,
  });

  return (
    <Cell>
      <Box marginBottom="2x">
        <FileUploader
          {...fileUploaderProps}
          fileName={item.fileName}
          thumbnailSize={thumbnailSize}
          transformFileName={transformFileName}
          variant="thumbnail"
        />
      </Box>
      <SelectInput
        labelProperty="shortColorName"
        options={thirdStepColors}
        placeholder="Color"
        value={item.color}
        valueProperty="code"
        onChange={onColorUpdated(index)}
      />
    </Cell>
  );
};
