import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Typography } from 'gantri-components';
import { UserCreditTransactionItem } from '../../../api/users/users.types';
import { formatAsCurrency } from '../../../helpers/formatter';
import { PaymentElement } from '../../../types/store';

export const storeCreditColumns: ColumnDef<UserCreditTransactionItem>[] = [
  {
    accessorKey: 'date',
    cell: ({
      getValue,
    }: CellContext<
      UserCreditTransactionItem,
      UserCreditTransactionItem['date']
    >) => {
      return <Typography text={getValue()} />;
    },
    header: 'Date',
  },
  {
    accessorKey: 'amount',
    cell: ({
      getValue,
    }: CellContext<
      UserCreditTransactionItem,
      UserCreditTransactionItem['amount']
    >) => {
      return (
        <Typography text={formatAsCurrency(getValue(), { isCents: true })} />
      );
    },
    header: 'Amount',
  },
  {
    accessorKey: 'reason',
    header: 'Reason',
  },
];

export const creditCardColumns: ColumnDef<PaymentElement>[] = [
  {
    accessorKey: 'type',
    header: 'Type',
  },
  {
    accessorKey: 'last4',
    header: 'Last 4',
  },
  {
    accessorKey: 'expires',
    header: 'Expires',
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'billing',
    header: 'Billing Zip',
  },
];
