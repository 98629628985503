import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { RenameFileArgs, RenameFileResponse } from './rename-file.types';
import { renameFile } from './rename-file';

export const useRenameCloudinaryFile = <TransformedData = RenameFileResponse>(
  props?: GenericMutateQueryProps<
    RenameFileArgs,
    RenameFileResponse,
    TransformedData
  >,
) => {
  const { onMutate: onRenameFile, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to name file.',
    mutationFn: renameFile,
    ...props,
  });

  return { ...rest, onRenameFile };
};
