import { ColorDetails, ColorPicker } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { OnDateChanged } from '../../../../components/common/date-picker/date-picker.types';
import { DatePicker } from '../../../../components/common/date-picker/date-picker';
import { batchesFilterAtoms } from '../../batches.atoms';
import { BatchStatus } from '../../../../api/batch/batch.types';
import { FilterResetLabel } from '../../../../components/common/filter-reset-label';
import {
  FilterGrid,
  FilterRow,
} from '../../../../components/common/filter-grid';
import { AtomsCheckboxList } from '../../../../components/common/atoms-checkbox-list';
import { jobTypeOptions } from '../../../../constants/options';

export const BatchesFilters = () => {
  const [colors, setColors] = useRecoilState(batchesFilterAtoms.colors);
  const [createdDateRange, setCreatedDateRange] = useRecoilState(
    batchesFilterAtoms.createdDateRange,
  );
  const [status, setStatus] = useRecoilState(batchesFilterAtoms.status);

  const createdDateChanged: OnDateChanged = ({
    formattedFrom,
    formattedTo,
  }) => {
    setCreatedDateRange({
      from: formattedFrom || null,
      to: formattedTo || null,
    });
  };

  const statusOptions: BatchStatus[] = ['Active', 'Archived'];

  const handleColorSelect = (selectedColorsDetails: ColorDetails[]) => {
    const newSelectedColorCodes = selectedColorsDetails
      ? selectedColorsDetails.map(({ code }) => {
          return code;
        })
      : [];

    setColors(newSelectedColorCodes);
  };

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={batchesFilterAtoms.colors}
          default={batchesFilterAtoms.defaults.filters.colors}
          text="Colors"
        />
        <ColorPicker
          allowMultipleSelection
          value={colors}
          onValueChange={handleColorSelect}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={batchesFilterAtoms.createdDateRange}
          default={batchesFilterAtoms.defaults.filters.createdDateRange}
          text="Date Created"
        />
        <DatePicker
          initialValue={{
            from: createdDateRange.from,
            to: createdDateRange.to,
          }}
          range
          onDateChanged={createdDateChanged}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={batchesFilterAtoms.jobTypes}
          default={batchesFilterAtoms.defaults.filters.jobTypes}
          text="Current Finish Step"
        />
        <AtomsCheckboxList
          atom={batchesFilterAtoms.jobTypes}
          gridProps={{ columns: 2 }}
          items={[
            // ? Listed in production order instead of alphabetical order
            jobTypeOptions.sandRaw,
            jobTypeOptions.prime,
            jobTypeOptions.sandPrimed,
            jobTypeOptions.paint,
            jobTypeOptions.qc,
            jobTypeOptions.stage,
            jobTypeOptions.bondo,
            jobTypeOptions.glue,
          ]}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={batchesFilterAtoms.status}
          default={batchesFilterAtoms.defaults.filters.status}
          text="Status"
        />
        <AtomsCheckboxList
          atom={batchesFilterAtoms.status}
          gridProps={{ columns: 2 }}
          items={statusOptions}
        />
      </FilterRow>
    </FilterGrid>
  );
};
