import { TableSortField } from '../../components/common/table/table.props';
import { ReferralsSortingField } from './referrals.types';

export const sortOptions: TableSortField<ReferralsSortingField>[] = [
  {
    bidirectional: true,
    label: 'Join Date',
    sortingField: 'joinDate',
  },
  {
    bidirectional: true,
    label: 'Purchase Date',
    sortingField: 'purchaseDate',
  },
  {
    bidirectional: true,
    label: 'Referrer',
    sortingField: 'referrer',
  },
  {
    bidirectional: true,
    label: 'Referrer Email',
    sortingField: 'email',
  },
  {
    bidirectional: true,
    label: 'Reward Date',
    sortingField: 'rewardDate',
  },
];
