import { Cell, Conditional, Stack } from 'gantri-components';
import { SkuLabelWrapperPropsDef } from './sku-label-wrapper.types';

export const SkuLabelWrapper = (props: SkuLabelWrapperPropsDef) => {
  const { children, hasVariants } = props;

  return (
    <Conditional condition={hasVariants} Fallback={children}>
      <Stack flow="column" rows="1fr 3.6rem">
        <Cell />
        {children}
      </Stack>
    </Conditional>
  );
};
