import { MachineRecord } from '../../machines.types';
import { DownloadColumn } from '../../../../hooks/use-get-download-menu-option/helpers/download-csv/download-csv.types';
import { formatAsLinkForCSV } from '../../../../hooks/use-get-download-menu-option/helpers/format-as-link-for-csv';
import { getAdminUrl } from '../../../../helpers/get-admin-url';
import routePaths from '../../../../config/route-paths';
import { DOWNLOAD_LONG_FORMAT } from '../../../../constants/dates';
import { formatDate } from '../../../../helpers/formatter';
import { newlineSeparator } from '../../../../hooks/use-get-download-menu-option/helpers/download-csv';

export const downloadColumns: DownloadColumn<MachineRecord>[] = [
  {
    getValue: ({ id, name }) => {
      return formatAsLinkForCSV({
        text: name,
        url: `${getAdminUrl() + routePaths.machines}/${id}`,
      });
    },
    header: 'Machine',
  },
  {
    getValue: ({ type }) => {
      return type;
    },
    header: 'Type',
  },
  {
    getValue: ({ location }) => {
      return location || 'San Leandro';
    },
    header: 'Location',
  },
  {
    getValue: ({ status }) => {
      return status;
    },
    header: 'Status',
  },
  {
    getValue: ({ lastStatusChangeInfo }) => {
      return formatDate(lastStatusChangeInfo.date, DOWNLOAD_LONG_FORMAT);
    },
    header: 'Last status change at',
  },
  {
    getValue: ({ lastStatusChangeInfo }) => {
      return lastStatusChangeInfo.id
        ? formatAsLinkForCSV({
            text: lastStatusChangeInfo.name,
            url: `${getAdminUrl() + routePaths.users}/${
              lastStatusChangeInfo.id
            }`,
          })
        : '';
    },
    header: 'Last status change by',
  },
  {
    getValue: ({ printHours }) => {
      return printHours;
    },
    header: 'Maintenance in',
  },
  {
    getValue: ({ issues }) => {
      return issues
        .map(({ type }) => {
          return type;
        })
        .join(newlineSeparator);
    },
    header: 'Open issues',
  },
  {
    getValue: ({ attentions }) => {
      return !!attentions.length ? attentions.length : '';
    },
    header: 'Attention',
  },
];
