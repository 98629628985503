import axios, { AxiosRequestConfig } from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { GetAvailableJobsForPrintTransferQueueResponse } from './get-available-print-transfer-queue-jobs.types';

const query = (configs?: AxiosRequestConfig) => {
  return axios
    .get<GetAvailableJobsForPrintTransferQueueResponse>(
      `${getCoreApiUrl('queue/print-transfer')}/jobs`,
      configs,
    )
    .then(({ data }) => {
      return data;
    });
};

const groupKey = () => {
  return 'get-available-jobs-for-print-transfer-queue';
};

const key = (configs?: AxiosRequestConfig) => {
  const queries = [configs];

  return [
    {
      groupKey: groupKey(),
      key: `${groupKey()}?${queries.join('&')}`,
    },
  ] as const;
};

export const getAvailableJobsForPrintTransferQueue = {
  groupKey,
  key,
  query,
};
