import { Conditional, Stack } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { ProductJobsHeader } from './components';
import { productAtoms } from '../../product.atoms';
import { PartsSection } from './components/parts-section';
import { StockJobsSection } from './components/stock-jobs-section';
import { SimpleErrorBoundary } from '../../../../components/simple-error-boundary';

export const ProductJobs = () => {
  const product = useRecoilValue(productAtoms.product);
  const isAccessory = useRecoilValue(productAtoms.isAccessory);

  return (
    <Stack gap="5x">
      <SimpleErrorBoundary>
        <ProductJobsHeader />
      </SimpleErrorBoundary>

      <Stack gap="9x">
        <Conditional condition={!isAccessory && !!product.parts}>
          <SimpleErrorBoundary>
            <PartsSection />
          </SimpleErrorBoundary>
        </Conditional>

        <Conditional condition={!!product.stockBlock}>
          <SimpleErrorBoundary>
            <StockJobsSection />
          </SimpleErrorBoundary>
        </Conditional>
      </Stack>
    </Stack>
  );
};
