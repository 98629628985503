import { flatten } from 'lodash';
import {
  StockStatus,
  stockStatuses,
} from '../../../../../../constants/options';
import {
  EditableShipment,
  EditableStock,
} from '../../change-items-modal.types';
import { Shipment } from '../../../../../../types/store';

export const getEditableStocksDetails = (shipments: Shipment[]) => {
  const editableStockStatuses: StockStatus[] = [stockStatuses.waiting];

  const editableShipments: EditableShipment[] = shipments?.length
    ? shipments
        .filter((shipment) => {
          return shipment.stocks.some((stock) => {
            return editableStockStatuses.includes(stock.status);
          });
        })
        .map(({ id: shipmentId, stocks = [] }) => {
          const shipmentNumber =
            shipments.findIndex(({ id }) => {
              return id === shipmentId;
            }) + 1;

          const editableStocks: EditableStock[] = stocks
            .filter((stock) => {
              return editableStockStatuses.includes(stock.status);
            })
            .map(({ product, stockInfoId }) => {
              return {
                productId: product.id,
                sku: product.sku,
                stockInfoId,
              };
            });

          return { editableStocks, shipmentId, shipmentNumber };
        })
    : [];

  const editableStocks = flatten(
    editableShipments.map(({ editableStocks }) => {
      return editableStocks;
    }),
  );

  const hasEditableStocks = !!editableStocks.length;

  return { editableShipments, editableStocks, hasEditableStocks };
};
