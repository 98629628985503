import { Typography, Tooltip, Flex, Box } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledValidationStatusBox = styled(Box)<{
  $backgroundColor: string;
}>`
  ${({ $backgroundColor }) => {
    return css`
      background-color: ${$backgroundColor};
    `;
  }}
`;

export const StyledPaddedFlex = styled(Flex)`
  padding: 1.2rem 0.8rem;
`;

export const StyledOverflowTypography = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledOverflowTooltip = styled(Tooltip)`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  * {
    width: 100%;
  }
`;
