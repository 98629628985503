import { Icon, Tooltip } from 'gantri-components';
import { Label } from '../../../../../../../../../../../../components/label';

export const DefaultSkuCellHeader = () => {
  return (
    <Label
      icon={
        <Tooltip
          maxWidth="29.2rem"
          position="top-start"
          titleHtmlText={[
            'Default SKU combination that will be displayed in Marketplace.',
            'Only configurable for products with variants.',
          ].join('<br/>')}
        >
          <Icon name="alert:i_circle" />
        </Tooltip>
      }
      iconPosition="right"
      text="Default SKU"
    />
  );
};
