import * as yup from 'yup';
import { machineStatuses, machineTypes } from '../../constants/machines';
import { MachineRecord } from '../machines/machines.types';

const inputLength = {
  macAddress: 12,
  serialNumber: 15,
} as const;

export const machineSchemaData = {
  ipAddress: {
    message: 'Input is not a valid IP address format',
    pattern: /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/g,
  },
  macAddress: {
    message: `Input must be ${inputLength.macAddress} characters`,
    pattern: /^(?:[a-zA-Z0-9]{2}:){5}[a-zA-Z0-9]{2}$/g,
  },
  serialNumber: {
    message: `Input must be ${inputLength.serialNumber} characters`,
    pattern: /^[a-zA-Z0-9]{15}$/g,
  },
} satisfies Record<string, { message: string; pattern: RegExp }>;

const requiredText = 'Required';

export const getFormattedMacAddress = (value: string) => {
  const separator = ':';
  const separatorPattern = new RegExp(separator, 'g');
  const purifiedValue = value.replace(separatorPattern, '');
  const every2Chars = purifiedValue.match(/.{1,2}/g) || [];
  const formattedValue = every2Chars.join(separator);

  return formattedValue;
};

export interface EditMachineDataFormDef
  extends Pick<
    MachineRecord,
    | 'bay'
    | 'location'
    | 'macAddress'
    | 'id'
    | 'ipAddress'
    | 'password'
    | 'name'
    | 'serialNumber'
    | 'status'
    | 'type'
  > {}

export const machineFieldSchemas = {
  bay: yup.string().nullable().required(requiredText),
  ipAddress: yup
    .string()
    .trim()
    .nullable()
    .matches(
      machineSchemaData.ipAddress.pattern,
      machineSchemaData.ipAddress.message,
    ),
  location: yup.string().nullable(),
  macAddress: yup
    .string()
    .nullable()
    .trim()
    .length(
      // offset for separator characters
      inputLength.macAddress + 5,
      machineSchemaData.macAddress.message,
    )
    .matches(
      machineSchemaData.macAddress.pattern,
      machineSchemaData.macAddress.message,
    ),
  name: yup.string().nullable().required(requiredText),
  password: yup.string().nullable(),
  serialNumber: yup
    .string()
    .nullable()
    .trim()
    .length(inputLength.serialNumber, machineSchemaData.serialNumber.message)
    .matches(
      machineSchemaData.serialNumber.pattern,
      machineSchemaData.serialNumber.message,
    ),
  status: yup
    .mixed()
    .oneOf(Object.values(machineStatuses))
    .required(requiredText),
  type: yup.mixed().oneOf(Object.values(machineTypes)).required(requiredText),
} as const;

export const machineSchema: yup.SchemaOf<EditMachineDataFormDef> = yup
  .object()
  .shape({
    bay: machineFieldSchemas.bay,
    id: yup.number().required(),
    ipAddress: machineFieldSchemas.ipAddress,
    location: machineFieldSchemas.location,
    macAddress: machineFieldSchemas.macAddress,
    name: machineFieldSchemas.name,
    password: machineFieldSchemas.password,
    serialNumber: machineFieldSchemas.serialNumber,
    status: machineFieldSchemas.status,
    type: machineFieldSchemas.type,
  });
