import { Grid } from 'gantri-components';
import { Divider } from '../../../../components/divider';
import { SearchPartSummaryDetails } from './components/search-part-summary-details';
import { SearchPartSummaryJobs } from './components/search-part-summary-jobs';
import { SearchPartSummaryThumbnail } from './components/search-part-summary-thumbnail';
import { StyledGrid, StyledStack } from './search-part-summary.styles';
import { SearchPartSummaryProps } from './search-part-summary.types';

export const SearchPartSummary = (props: SearchPartSummaryProps) => {
  const { clearPartSearch, getPartDetails, part } = props;

  return (
    <StyledStack gap="0" height="unset">
      <StyledGrid columns={{ lg: '1fr 1fr', sm: '1fr' }} gap="0" width="100%">
        <SearchPartSummaryDetails getPartDetails={getPartDetails} part={part} />

        <Grid columns="max-content 1fr" gap="0" hidden={{ sm: true }}>
          <Divider borderLocation="right" height="100%" />
          <SearchPartSummaryThumbnail part={part} />
        </Grid>
      </StyledGrid>

      <SearchPartSummaryJobs
        clearPartSearch={clearPartSearch}
        getPartDetails={getPartDetails}
        part={part}
      />
    </StyledStack>
  );
};
