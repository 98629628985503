import styled, { css } from 'styled-components';
import {
  generateStylesForResolutionAwareProps,
  media,
} from 'gantri-components';
import { Property } from 'csstype';
import { ExtractStyleFromResolutionAwarePropEntry } from '../../../types/layout';
import { ResolutionAwareProp } from '../../../types/resolution-aware-prop.type';

export const StyledBackdrop = styled.div`
  ${() => {
    return css`
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(28, 28, 28, 0.9);
    `;
  }}
`;

export const StyledGallerySelectorBlock = styled.div`
  max-width: 100vw;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

export const StyledGallerySelector = styled.div<{ selected: boolean }>`
  ${({ selected, theme }) => {
    return css`
      width: 7.4rem;
      height: 7.4rem;
      background-position: center;
      background-size: cover;
      cursor: pointer;
      transition: ${theme.transitions.base};
      position: relative;

      &:after {
        content: '';
        position: absolute;
        inset: -1px;
        border: ${selected
          ? `2px solid ${theme.colors.palette.monochrome_white}`
          : 'unset'};
      }
    `;
  }}
`;

export const StyledGallerySelectorGuide = styled.div`
  position: absolute;
  left: -10px;
  right: -10px;
`;

export const StyledGallerySelectorContainer = styled.div`
  display: flex;
  gap: 4px;
  overflow: overlay;
  padding: 1px;

  & > * {
    min-width: 7.4rem;
    min-height: 7.4rem;
  }

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: none;
  }
`;

export const StyledCarouselIconContainer = styled.span<{
  bottom?: Property.Bottom<any>;
  notVisible?: ResolutionAwareProp<boolean>;
  right?: boolean;
  top?: Property.Top<any>;
}>`
  ${({ bottom, notVisible, right, theme, top }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'display',
        resolutionAwareProp: notVisible,
        valueFormatter: (value) => {
          return value === true ? 'none' : 'block';
        },
      },
    ];

    return css`
      position: absolute;
      left: ${right ? 'unset' : theme.dimensions.spacing.s3};
      right: ${right ? theme.dimensions.spacing.s3 : 'unset'};
      top: ${top || (bottom ? 'unset' : 0)};
      bottom: ${bottom || (top ? 'unset' : 0)};
      margin: auto;
      height: 32px;
      z-index: 2;
      border-radius: 50%;
      ${generateStylesForResolutionAwareProps(entries)}

      ${media.lessThan('md')`
        left: ${right ? 'unset' : theme.dimensions.spacing.s2};
        right: ${right ? theme.dimensions.spacing.s2 : 'unset'};
      `}

      ${media.lessThan('sm')`
        left: ${right ? 'unset' : theme.dimensions.spacing.s1};
        right: ${right ? theme.dimensions.spacing.s1 : 'unset'};
      `}
    `;
  }}
`;

export const StyledImageWrapper = styled.div`
  ${({ theme }) => {
    return css`
      display: grid;
      grid-template-columns: 1.6rem 1fr 1.6rem;
      align-items: center;
      padding: 0 ${theme.dimensions.spacing.s3};
      gap: ${theme.dimensions.spacing.s3};
      height: 100%;

      ${media.lessThan('md')`
        padding: 0 ${theme.dimensions.spacing.s2};
        gap: ${theme.dimensions.spacing.s2};
      `}

      ${media.lessThan('sm')`
        padding: 0 ${theme.dimensions.spacing.s1};
        gap:0.8rem;
      `}
    `;
  }}
`;

export const StyledImageContainer = styled.div`
  width: 100%;
  height: 100%;
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: calc(100vh - 18rem);

  > div > div {
    width: 100%;
    height: 100%;

    img {
      position: relative;
    }
  }
`;

export const StyledCarouselPage = styled.div`
  position: absolute;
  inset: 0;
`;
