import { useModal } from 'gantri-components';
import { useCallback } from 'react';
import { NewInventoryModal } from './new-inventory-modal';
import { NewInventoryFormState } from './new-inventory.types';
import { useRouter } from '../../../../hooks';
import { inventoriesApi } from '../../../../api';
import { useNotification } from '../../../../hooks/useNotification';

export const useNewInventoryModal = (props?: { onSuccess?: () => void }) => {
  const { notify, notifyAxiosError, onInterceptRequest } = useNotification();
  const { navigate } = useRouter();

  const onSubmit = useCallback(async (values: NewInventoryFormState) => {
    await onInterceptRequest(async () => {
      try {
        const { data } = await inventoriesApi.create(values);

        notify(data.notice);
        hideModal();
        props?.onSuccess?.();
        navigate(`/inventories/${data.inventory.id}`);
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to create new inventory.',
        });
      }
    });
  }, []);

  const [showModal, hideModal] = useModal(() => {
    return <NewInventoryModal onCancel={hideModal} onSubmit={onSubmit} />;
  }, []);

  return [showModal, hideModal];
};
