import {
  Conditional,
  Flex,
  Grid,
  Icon,
  LinearProgress,
  Typography,
} from 'gantri-components';
import { useTheme } from 'styled-components';
import { SandingMinutesDetailsPropsDef } from './sanding-minutes-details.types';
import {
  StyledProgressStack,
  StyledTextScrollBox,
  StyledScrollTypography,
  StyledTextScrollWrapperBox,
} from './sanding-minutes-details.styles';
import { progressTransitionTimeSec } from '../sanding-minutes-earned-toast/sanding-minutes-earned-toast.styles';

export const SandingMinutesDetails = (props: SandingMinutesDetailsPropsDef) => {
  const {
    isActive,
    isCompact,
    isLoading,
    newSandingMinutes,
    progressStrokeColor,
    sandingMinutes,
  } = props;

  const theme = useTheme();

  const maxSandingMinutes = 400;

  const prevPercent = Math.floor((sandingMinutes / maxSandingMinutes) * 100);
  const prevPercentText = `${prevPercent}%`;
  const isPrevComplete = prevPercent >= 100;

  const newPercent = Math.floor((newSandingMinutes / maxSandingMinutes) * 100);
  const newPercentText = `${newPercent}%`;
  const isComplete = newPercent >= 100;

  return (
    <StyledProgressStack
      $isActive={isActive}
      alignContent="center"
      gap=".6rem"
      height="unset"
      horizontalPadding={isCompact ? '.6rem' : '2x'}
      paddingBottom="x"
      paddingTop="2x"
      width="100%"
    >
      <LinearProgress
        backgroundColor={
          isActive
            ? theme.colors.surfaces.monochrome.t2
            : theme.colors.surfaces.monochrome.t3
        }
        current={newSandingMinutes}
        height={isActive ? '1.2rem' : '.8rem'}
        progressStrokeColor={
          isActive
            ? theme.colors.surfaces.green.t1
            : progressStrokeColor || theme.colors.surfaces.monochrome.t1
        }
        progressStrokeSize=".3rem"
        total={maxSandingMinutes}
        transition={`all ease-in-out ${progressTransitionTimeSec}s`}
      />

      <Flex alignItems="baseline" gap=".5x" justifyContent="space-between">
        <Grid
          alignItems="baseline"
          columns="max-content 1fr"
          gap=".5x"
          height="100%"
        >
          <Conditional
            key="is-loading"
            condition={isLoading && !newSandingMinutes}
            Fallback={
              <StyledTextScrollWrapperBox>
                <StyledTextScrollBox $isActive={isActive}>
                  <StyledScrollTypography
                    color="link"
                    text={sandingMinutes}
                    textStyle="bold"
                    variant="p1"
                  />
                  <StyledScrollTypography
                    color="link"
                    text={newSandingMinutes}
                    textStyle="bold"
                    variant="p1"
                  />
                </StyledTextScrollBox>
              </StyledTextScrollWrapperBox>
            }
          >
            <Icon color="link" name="animated:loader" />
          </Conditional>

          <Flex alignItems="baseline">
            <Typography color="t2" display="inline" text="/" variant="p1" />
            <Typography
              color="t2"
              text={`${maxSandingMinutes} ${isCompact ? '' : 'SAND '}MIN.`}
              whiteSpace="nowrap"
            />
          </Flex>
        </Grid>

        <StyledTextScrollWrapperBox>
          <StyledTextScrollBox $isActive={isActive}>
            <Conditional
              key="is-prev-complete"
              condition={isPrevComplete}
              Fallback={
                <StyledScrollTypography color="t2" text={prevPercentText} />
              }
            >
              <StyledScrollTypography
                color="link"
                text={prevPercentText}
                textStyle="bold"
              />
            </Conditional>

            <Conditional
              key="is-complete"
              condition={isComplete}
              Fallback={
                <StyledScrollTypography color="t2" text={newPercentText} />
              }
            >
              <StyledScrollTypography
                color="link"
                text={newPercentText}
                textStyle="bold"
              />
            </Conditional>
          </StyledTextScrollBox>
        </StyledTextScrollWrapperBox>
      </Flex>
    </StyledProgressStack>
  );
};
