import styled, { css } from 'styled-components';
import { Grid } from 'gantri-components';
import { mediaQueries } from '../../constants/styles';

export const StyledTabListGrid = styled(Grid)`
  border-bottom: 1px solid
    ${(props) => {
      return props.theme.colors.dividers.t1;
    }};
`;

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-right: 10px;

  & > *:last-child {
    width: 100%;
    margin-top: 10px;
  }

  ${mediaQueries.mobile(css`
    flex: 1;
  `)}
`;

export const StyledSkuContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`;

export const StyledSkuInputContainer = styled(StyledInputContainer)<{
  minWidth?: string;
  sku?: boolean;
}>`
  min-width: ${({ minWidth }) => {
    return minWidth || '8rem';
  }};
  margin-bottom: 10px;

  select {
    max-width: 200px;
  }

  ${({ sku }) => {
    return sku && 'margin-right: 30px;';
  }}
  ${({ sku }) => {
    return (
      !sku &&
      css`
        &:not(:last-child) {
          margin-right: 10px;
        }
      `
    );
  }}
`;
