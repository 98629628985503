import { useEffect } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Conditional, productColorsMap, Typography } from 'gantri-components';
import { newStocksAtoms } from '../../new-stocks.atoms';
import { findProduct } from '../use-find-product';
import { NewStockMenuCell } from './components/new-stock-menu-cell';
import { NewStockColorCell } from './components/new-stock-color-cell';
import { NewStockVariantCell } from './components/new-stock-variant-cell';
import { NewStockQuantityCell } from './components/new-stock-quantity-cell';
import { TableDataItem } from './use-get-new-stocks-table-details.types';
import { NotApplicableText } from '../../../../components/common/not-applicable-text';
import { selectProductFormAtoms } from '../../../../components/common/select-product-form/select-product-form.atoms';
import { productSizes } from '../../../../constants/options';

export const useGetNewStocksTableDetails = () => {
  const newStocks = useRecoilValue(newStocksAtoms.newStocks);
  const [tableData, setTableData] = useRecoilState(newStocksAtoms.tableData);
  const setCachedTableData = useSetRecoilState(newStocksAtoms.cachedTableData);
  const allProducts = useRecoilValue(selectProductFormAtoms.allProducts);

  const columns: ColumnDef<TableDataItem>[] = [
    {
      accessorKey: 'product',
      cell: ({
        getValue,
      }: CellContext<TableDataItem, TableDataItem['product']>) => {
        const productName = getValue()?.name;

        return <Typography text={productName} />;
      },
      header: 'Product',
      size: 200,
    },
    {
      accessorKey: 'designer',
      cell: ({
        getValue,
      }: CellContext<TableDataItem, TableDataItem['designer']>) => {
        const designer = getValue();

        return (
          <Conditional condition={!!designer} Fallback={<NotApplicableText />}>
            <Typography text={designer} />
          </Conditional>
        );
      },
      header: 'Designer',
      size: 200,
    },
    {
      accessorKey: 'color',
      cell: NewStockColorCell,
      header: 'Color',
    },
    {
      accessorKey: 'variantPartialDetails',
      cell: NewStockVariantCell,
      header: 'Variants',
    },
    {
      accessorKey: 'quantity',
      cell: NewStockQuantityCell,
      header: 'Quantity',
    },
    {
      cell: NewStockMenuCell,
      id: 'menu',
      maxSize: 30,
    },
  ];

  useEffect(() => {
    const tableData = newStocks.map<TableDataItem>(
      ({
        colorCode,
        id,
        productId,
        quantity,
        selectedSku,
        sizeCode,
        variantCodes,
        variantPartialDetails,
      }) => {
        const { category, designerName, fullProductName, name } = findProduct({
          allProducts,
          productId,
        });

        const productName =
          fullProductName || [name, category].filter(Boolean).join(' ');
        const colorName = productColorsMap[colorCode]?.shortColorName;
        const sizeName = productSizes[sizeCode];

        return {
          color: { code: colorCode, name: colorName },
          designer: designerName,
          id,
          product: {
            id: productId,
            name: productName,
          },
          quantity,
          selectedSku,
          size: { code: sizeCode, name: sizeName },
          variantCodes,
          variantPartialDetails: variantPartialDetails || [],
        };
      },
    );

    setTableData(tableData);
    setCachedTableData(tableData);
  }, [newStocks, allProducts]);

  return { columns, tableData };
};
