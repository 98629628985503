import { FC } from 'react';
import { Conditional, GetRowProps, Icon, Table } from 'gantri-components';
import { stockInventoriesTableDefaultProps } from './stock-inventories-table.presets';
import {
  InventoryRowData,
  StockInventoriesTableProps,
} from './stock-inventories-table.types';
import { getGetStockInventoryColumns } from './stock-inventories-table.constants';
import { useStockInventoriesResume } from './use-stock-inventories-resume';

export const StockInventoriesTable: FC<StockInventoriesTableProps> = (
  props,
) => {
  const { inventories, isLoading, totalCost } =
    useStockInventoriesResume(props);

  const columns = getGetStockInventoryColumns({ totalCost });

  const getRowProps: GetRowProps<InventoryRowData> = ({ original }) => {
    return {
      status: original.isRed ? 'error' : undefined,
    };
  };

  return (
    <Conditional
      condition={!isLoading}
      Fallback={<Icon color="link" name="animated:loader" />}
    >
      <Table
        columns={columns}
        data={inventories}
        getRowProps={getRowProps}
        highlightHoveredRow
      />
    </Conditional>
  );
};

StockInventoriesTable.defaultProps = stockInventoriesTableDefaultProps;
