import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { useSyncPropertyWithQueryString } from '../../../../hooks/useSyncPropertyWithQueryString';
import { productAtoms } from '../../product.atoms';
import { productQueryKeys } from '../../product.constants';

export const useHandleSetSelectedSku = () => {
  const [selectedSku, setSelectedSku] = useRecoilState(
    productAtoms.selectedSku,
  );
  const { currentValue: skuQuery, updateQueryString: updateSkuQuery } =
    useSyncPropertyWithQueryString<string>(productQueryKeys.sku, undefined);

  const handleSetSelectedSku = (sku: string) => {
    setSelectedSku(sku);
    updateSkuQuery(sku);
  };

  useEffect(() => {
    if (selectedSku && !skuQuery) {
      handleSetSelectedSku(selectedSku);
    }
  }, [skuQuery]);

  return {
    /** Updates the `selectedSku` atom and the `sku` query values. */
    handleSetSelectedSku,
    skuQuery,
  };
};
