import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  CreatePartTemplateArgs,
  CreatePartTemplateResponse,
} from './create-part-template.types';

export const createPartTemplate = (body: CreatePartTemplateArgs) => {
  return axios
    .post<CreatePartTemplateResponse>(
      getCoreApiUrl('product-part-templates'),
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
