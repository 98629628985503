import { Button, Conditional, Flex, Icon, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useTheme } from 'styled-components';
import { CSVLink } from 'react-csv';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import { getDownloadFileName } from '../../../../../../../../../../../../hooks/use-get-download-menu-option/helpers/get-download-file-name';
import { productFileTypesMaxQty } from '../../../../../../../sku-level-assets/components/bulk-upload-modal/bulk-upload-modal.constants';
import { StyledBackgroundStack } from '../../../../review-assets-modal.styles';
import { ConditionalLineItem } from '../conditional-line-item';
import { OptionalAssetsSectionPropsDef } from './optional-assets-section.types';

export const OptionalAssetsSection = (props: OptionalAssetsSectionPropsDef) => {
  const {
    downloadData,
    isMissingOptionalAssets,
    noVideo,
    numSkusMissingOptionalAssets,
    someSkusAreMissingOptionalAssets,
  } = props;

  const product = useRecoilValue(productAtoms.product);

  const theme = useTheme();

  return (
    <Conditional
      key="Is missing optional assets"
      condition={isMissingOptionalAssets}
    >
      <StyledBackgroundStack
        $backgroundColor={theme.colors.surfaces.monochrome.t1Alt}
        gap="x"
        height="auto"
        horizontalPadding="x"
        paddingBottom="2x"
        paddingTop="x"
      >
        <Typography
          icon={<Icon name="alert:warning_triangle" />}
          paddingBottom="x"
          text="Missing optional assets."
        />

        <Conditional
          key="SKUs missing optional assets"
          condition={someSkusAreMissingOptionalAssets}
        >
          <Flex alignItems="center" gap="x" horizontalPadding="x">
            <Typography text={numSkusMissingOptionalAssets} textStyle="bold" />
            <Typography text="SKUs missing optional assets" />
          </Flex>
        </Conditional>

        <ConditionalLineItem
          condition={noVideo}
          text="Product video is missing"
        />
      </StyledBackgroundStack>

      <Conditional
        key="Download - SKU Assets CSV"
        condition={someSkusAreMissingOptionalAssets}
      >
        <CSVLink
          data={downloadData}
          filename={getDownloadFileName(`${product.name} assets review`)}
          headers={[
            { key: 'sku', label: 'SKU' },
            {
              key: 'whiteBackgroundPhotos',
              label: `White Background Shots 1-${productFileTypesMaxQty.whiteBackgroundPhotos}`,
            },
            { key: 'scalePhoto', label: 'Scale' },
            { key: 'dimmingPhotoLight', label: 'Dimming L' },
            { key: 'dimmingPhotoDark', label: 'Dimming D' },
            { key: 'usdz', label: 'USDZ' },
          ]}
        >
          <Button
            icon={<Icon color="link" name="arrows:arrow_download" />}
            text="Download - SKU Assets CSV"
            variant="ghost"
          />
        </CSVLink>
      </Conditional>
    </Conditional>
  );
};
