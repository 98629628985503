import { v4 as uuidv4 } from 'uuid';
import { MachineType } from '../../../../../../../../../../api/machines/machines.types';
import { PartPaintedStatus } from '../../../../../../../../../../api/products/products.types';
import { Material } from '../../../../../../../../../../constants/options/options.types';
import { AddOrEditPartsFormData } from '../../add-or-edit-part-modal.types';

export const getEmptyPart = (): AddOrEditPartsFormData => {
  return {
    colorOverride: [],
    hasBondo: false,
    id: uuidv4() as unknown as number,
    machineType: '' as MachineType,
    material: '' as Material,
    name: '',
    paintedStatus: '' as PartPaintedStatus,
    totalPrintBlocks: 1,
  };
};
