import { media } from 'gantri-components';
import styled, { css } from 'styled-components';
import { zIndexes } from '../../../constants/styles';

export const StyledLoaderOverlay = styled.div`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.overlay};
      bottom: 0;
      display: flex;
      height: 100%;
      left: 0;
      margin: 0;
      pointer-events: visible;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      z-index: ${zIndexes.loadingOverlay};
      display: flex;
      align-items: center;
      justify-content: center;
    `;
  }}
`;

export const StyledGLogoWrapper = styled.div`
  position: absolute;
  width: 29px;
  height: 27px;
  overflow: hidden;
  display: flex;
`;

export const StyledForceCloseButtonContainer = styled.span`
  ${({ theme }) => {
    return css`
      position: absolute;
      top: 3.2rem;
      right: 3.2rem;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      background-color: ${theme.colors.palette.translucent_black_t1};
      display: grid;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      ${media.lessThan('md')`
      top: 8rem;
    `}

      svg[fill] {
        fill: ${theme.colors.palette.monochrome_white};
      }
    `;
  }}
`;
