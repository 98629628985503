import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Typography } from 'gantri-components';
import Grid from '../../../../../../../components/common/grid';

export const StyledMenuTooltip = styled(Grid)`
  ${({ theme }) => {
    return css`
      padding: 0.4rem 0 0.8rem;
      z-index: 1;
      border: 1px solid ${theme.colors.dividers.t1};
      background-color: ${theme.colors.surfaces.monochrome.overlayLevel1};
      border-radius: 0.3rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    `;
  }}
`;

export const StyledTooltipLink = styled(Link)`
  padding: 0.8rem 1.6rem;
  white-space: nowrap;
  color: var(--colors-grey);
  transition: unset;

  &:hover {
    color: ${(props) => {
      return props.theme.colors.typography.t1;
    }};
  }
`;

export const StyledPopupTypography = styled(Typography)`
  color: inherit;
`;
