import { Flex, LinearProgress, Typography } from 'gantri-components';
import { useTheme } from 'styled-components';
import { StockTotalCompletionJobsPropsDef } from './stock-total-completion-jobs.types';

export const StockTotalCompletionJobs = (
  props: StockTotalCompletionJobsPropsDef,
) => {
  const { totalCompletionJobs } = props;

  const theme = useTheme();

  const percentCompletionNum = parseInt(totalCompletionJobs);

  const backgroundColor =
    percentCompletionNum < 100
      ? theme.name === 'dark'
        ? theme.colors.palette.monochrome_white
        : theme.colors.surfaces.monochrome.t3
      : theme.colors.surfaces.green.t3;

  return totalCompletionJobs ? (
    <Flex alignItems="center" gap="x">
      <LinearProgress
        backgroundBorderColor={backgroundColor}
        backgroundColor={backgroundColor}
        current={percentCompletionNum}
        progressBackgroundColor={
          percentCompletionNum < 100
            ? theme.colors.palette.monochrome_black
            : theme.colors.surfaces.green.t3
        }
        progressStrokeColor={theme.colors.surfaces.monochrome.t1}
        total={100}
        width="5rem"
      />

      <Typography
        color={percentCompletionNum >= 100 ? 'link' : 't1'}
        text={totalCompletionJobs}
      />
    </Flex>
  ) : null;
};
