import { Button } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { useStartJob } from '../../../../../../../../../../api/jobs/routes';
import { fullScreenModalFooterButtonSize } from '../../../../../../modals.constants';
import { startAssembleStageModalAtoms } from '../../../start-assemble-stage-job-modal.atoms';
import { ConfirmStartFooterProps } from './confirm-start-footer.types';

export const ConfirmStartFooter = (props: ConfirmStartFooterProps) => {
  const { handleCloseModal, job } = props;

  const setVlmNotified = useSetRecoilState(
    startAssembleStageModalAtoms.vlmNotified,
  );
  const setStockLocation = useSetRecoilState(
    startAssembleStageModalAtoms.stockLocation,
  );
  const setStep = useSetRecoilState(startAssembleStageModalAtoms.step);

  const { isLoading, onStartJob } = useStartJob({
    onSuccess: async ({ location, vlmNotified }) => {
      setVlmNotified(vlmNotified);

      if (location) {
        setStockLocation(location);
        setStep('GET_FROM_VLM');
      } else {
        setStep('NO_ASSIGNED_LOCATION');
      }
    },
  });

  const handleConfirmStart = async () => {
    await onStartJob({
      callVlm: true,
      jobId: job.id,
    });
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        processing={isLoading}
        size={fullScreenModalFooterButtonSize}
        text="Yes"
        onClick={handleConfirmStart}
      />
    </>
  );
};
