import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  ArchivePartTemplateArgs,
  ArchivePartTemplateResponse,
} from './archive-part-template.types';

export const archivePart = (args: ArchivePartTemplateArgs) => {
  const { id } = args;

  return axios
    .delete<ArchivePartTemplateResponse>(
      `${getCoreApiUrl('product-part-templates')}/${id}`,
    )
    .then(({ data }) => {
      return data;
    });
};
