import { v4 as uuidv4 } from 'uuid';
import axios, { AxiosError } from 'axios';
import { useSetRecoilState } from 'recoil';
import { notificationAtoms } from '../../components/notification/notification.atoms';
import { useSpinner } from '../use-spinner';
import {
  defaultNotificationDuration,
  notificationTypes,
} from './use-notification.constants';
import {
  Notify,
  NotifyAxiosError,
  NotificationDetails,
} from './use-notification.types';

export const useNotification = () => {
  const useSpinnerValues = useSpinner();

  const setNotifications = useSetRecoilState(notificationAtoms.notifications);

  const showNotification = (newNotification: NotificationDetails) => {
    const {
      closeOthersOfType,
      keepOpen = false,
      message = '',
      time = defaultNotificationDuration,
      type = 'success',
    } = newNotification;

    if (message) {
      setNotifications((prevNotifications) => {
        const purifiedNotificationsList = prevNotifications.filter(
          (prevNotification) => {
            if (closeOthersOfType && type === prevNotification.type) {
              return false;
            }

            return true;
          },
        );

        const newNotification: NotificationDetails = {
          id: uuidv4(),
          keepOpen,
          message,
          time,
          type,
        };

        return [...purifiedNotificationsList, newNotification];
      });
    }
  };

  const notify: Notify = (message, options = {}) => {
    showNotification({
      closeOthersOfType: true,
      message,
      type: notificationTypes.success,
      ...options,
    });
  };

  const notifyWarning: Notify = (message, options = {}) => {
    showNotification({
      message,
      type: notificationTypes.warning,
      ...options,
    });
  };

  const notifyError: Notify = (message, options = {}) => {
    showNotification({
      message,
      type: notificationTypes.error,
      ...options,
    });
  };

  /**
   * Validates the error for axios and returns the endpoint `error` message or the `fallbackMessage`.
   *
   * Usage example:
   * ```
   * const { hideLoading, notifyAxiosError, showLoading } = useNotification();
   *
   * const handleEndpoint = async () => {
   *   try {
   *     showLoading();
   *     await yourRequestedAxiosEndpoint();
   *   } catch (error: unknown) {
   *     notifyAxiosError({
   *       error,
   *       fallbackMessage: 'Unable to complete task.',
   *     });
   *   } finally {
   *     hideLoading();
   *   }
   * };
   * ```
   */
  const notifyAxiosError: NotifyAxiosError = (
    { error, fallbackMessage },
    options = {},
  ) => {
    if (axios.isAxiosError(error)) {
      const typedError = error as AxiosError<{ error?: string }>;

      notifyError(
        typedError?.response?.data?.error ||
          // @ts-expect-error -  Creating a new axios instance seems to nest the message key within itself
          typedError?.response?.data?.error?.message ||
          fallbackMessage,
        options,
      );
    } else {
      notifyError(fallbackMessage, options);
    }
  };

  return {
    ...useSpinnerValues,
    notify,
    notifyAxiosError,
    notifyError,
    notifyWarning,
  };
};
