import { Grid, Icon, Typography } from 'gantri-components';
import { SandingMinutesEarnedToastPropsDef } from './sanding-minutes-earned-toast.types';
import { StyledMinutesEarnedBox } from './sanding-minutes-earned-toast.styles';

export const SandingMinutesEarnedToast = (
  props: SandingMinutesEarnedToastPropsDef,
) => {
  const { isActive, isCompact, lastCompletedJobDuration } = props;

  return (
    <StyledMinutesEarnedBox
      $isActive={isActive}
      $isCompact={isCompact}
      paddingLeft={isCompact ? 'x' : '3x'}
      paddingRight={isCompact ? 'x' : '2x'}
      verticalPadding={isCompact ? 'x' : '1.3rem'}
      width={isCompact ? 'unset' : '21.6rem'}
    >
      <Grid alignItems="center" columns="repeat(2, max-content)" gap="2x">
        <Icon color="link" name="alert:lightning_bolt_filled" />
        <Typography text={`${lastCompletedJobDuration} minutes earned`} />
      </Grid>
    </StyledMinutesEarnedBox>
  );
};
