import { v4 as uuidv4 } from 'uuid';
import { applyUuidDefault } from 'gantri-components';
import { GetTransformProductAssetFileNameProps } from './get-transform-product-asset-file-name.types';

export const getTransformProductAssetFileName = (
  props: GetTransformProductAssetFileNameProps,
) => {
  const { fileType, productId, sku } = props;

  const transformFileName = (props: {
    applyUuid?: (value: string) => string;
    fileName: string;
  }) => {
    const { applyUuid = applyUuidDefault, fileName = '' } = props || {};

    const fileNamePatternWithGroupedExtension = /^[^.]+(\..+)$/;

    switch (fileType) {
      case 'lifestyle-photos':
      case 'videos':
        return applyUuid(`${productId}--${fileType}-${uuidv4()}`);

      case 'model': {
        const formattedFileName = fileName.replace(
          fileNamePatternWithGroupedExtension,
          `${sku}--${fileType}$1`,
        );

        return applyUuid(formattedFileName);
      }

      default:
        return applyUuid(`${sku}--${fileType}-${uuidv4()}`);
    }
  };

  return { transformFileName };
};
