import { FC, useEffect } from 'react';
import { jobsDetailsPanelIdQueryKey } from '../../components/jobs-details-panel-wrapper';
import routePaths from '../../config/route-paths';
import { convertQueriesToString } from '../../helpers/checks';
import { useRouter } from '../../hooks';

export const Job: FC = () => {
  const { navigate, params } = useRouter();

  useEffect(() => {
    const queries = convertQueriesToString({
      [jobsDetailsPanelIdQueryKey]: params.id,
    });

    navigate(`${routePaths.jobs}${queries}`);
  }, []);

  return null;
};
