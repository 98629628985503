import { useModal } from 'gantri-components';
import { NewGiftCode } from './new-gift-code';
import { convertFormValuesToAsyncCreateGiftCodeArgs } from './gift-codes.adapter';
import { giftCodesApi } from '../../api';
import { useNotification } from '../../hooks/useNotification';

export const useNewGiftCode = (props: { onReloadList?: () => void }) => {
  const { onReloadList } = props;

  const { notify, notifyAxiosError, onInterceptRequest } = useNotification();

  const onSave = async (formValues) => {
    await onInterceptRequest(async () => {
      try {
        const { data } = await giftCodesApi.createGiftCode({
          ...convertFormValuesToAsyncCreateGiftCodeArgs(formValues),
        });

        notify(data.notice);

        hideModal();
        onReloadList?.();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to create gift code.',
        });
      }
    });
  };

  const [showModal, hideModal] = useModal(() => {
    return <NewGiftCode onClose={hideModal} onSave={onSave} />;
  }, []);

  return { onShowModal: showModal };
};
