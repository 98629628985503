import {
  Cell,
  Checkbox,
  ColorDetails,
  ColorPicker,
  SearchField,
  Stack,
  Toggle,
} from 'gantri-components';
import { useRecoilState, useResetRecoilState } from 'recoil';
import DatePicker from '../../common/date-picker';
import { ordersPageAtoms } from './orders-filter.atoms';
import { OnDateChanged } from '../../common/date-picker/date-picker.types';
import {
  productionStatusFilterOptions,
  productionTypesFilterOptions,
  statusOptions,
  typeOptions,
} from './orders-filter.constants';
import { OrdersFiltersProps } from './orders-filter.types';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { FilterGrid, FilterRow } from '../../common/filter-grid';
import { AtomsCheckboxList } from '../../common/atoms-checkbox-list';
import { AtomsRadioList } from '../../common/atoms-radio-list';
import { productSizeOptions } from '../../../constants/options';

export const OrdersFilter = ({ allProducts }: OrdersFiltersProps) => {
  const [action, setAction] = useRecoilState(ordersPageAtoms.filters.action);
  const [color, setColor] = useRecoilState(ordersPageAtoms.filters.color);
  const resetColor = useResetRecoilState(ordersPageAtoms.filters.color);
  const [createdDateRange, setCreatedDateRange] = useRecoilState(
    ordersPageAtoms.filters.createdDateRange,
  );
  const [completedDateRange, setCompletedDateRange] = useRecoilState(
    ordersPageAtoms.filters.completedDateRange,
  );
  const [late, setLate] = useRecoilState(ordersPageAtoms.filters.late);
  const [productId, setProductId] = useRecoilState(
    ordersPageAtoms.filters.productId,
  );
  const resetProductId = useResetRecoilState(ordersPageAtoms.filters.productId);
  const [statuses, setStatuses] = useRecoilState(
    ordersPageAtoms.filters.statuses,
  );
  const resetStatuses = useResetRecoilState(ordersPageAtoms.filters.statuses);
  const [types, setTypes] = useRecoilState(ordersPageAtoms.filters.types);
  const resetTypes = useResetRecoilState(ordersPageAtoms.filters.types);

  const createdDateChanged: OnDateChanged = ({
    formattedFrom,
    formattedTo,
    from,
    to,
  }) => {
    setCreatedDateRange({
      from: {
        formatted: formattedFrom || null,
        sec: Number(from) || null,
      },
      to: {
        formatted: formattedTo || null,
        sec: Number(to) || null,
      },
    });
  };

  const onCompletedDateChanged: OnDateChanged = ({
    formattedFrom,
    formattedTo,
    from,
    to,
  }) => {
    setCompletedDateRange({
      from: {
        formatted: formattedFrom || null,
        sec: Number(from) || null,
      },
      to: {
        formatted: formattedTo || null,
        sec: Number(to) || null,
      },
    });
  };

  const isTypesProductionFilterApplied = !(
    productionTypesFilterOptions.length !== types.length ||
    productionTypesFilterOptions.some((option) => {
      return !types.includes(option);
    })
  );
  const isStatusProductionFilterApplied = !(
    productionStatusFilterOptions.length !== statuses.length ||
    productionStatusFilterOptions.some((option) => {
      return !statuses.includes(option);
    })
  );
  const isProductionFilterApplied =
    isStatusProductionFilterApplied && isTypesProductionFilterApplied;

  const toggleProductionFilter = () => {
    if (isProductionFilterApplied) {
      resetTypes();
      resetStatuses();
    } else {
      setTypes(productionTypesFilterOptions);
      setStatuses(productionStatusFilterOptions);
    }
  };

  const handleColorSelect = (selectedColorDetails: ColorDetails) => {
    const colorCode = selectedColorDetails?.code;

    if (colorCode) {
      setColor(selectedColorDetails.code);
    } else {
      resetColor();
    }
  };

  const productOptions = allProducts.map(({ id, name }) => {
    return {
      label: name,
      value: id,
    };
  });

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={ordersPageAtoms.filters.createdDateRange}
          default={ordersPageAtoms.defaults.filters.createdDateRange}
          text="Created"
        />
        <DatePicker
          initialValue={{
            from: createdDateRange.from.formatted,
            to: createdDateRange.to.formatted,
          }}
          range
          onDateChanged={createdDateChanged}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={ordersPageAtoms.filters.completedDateRange}
          default={ordersPageAtoms.defaults.filters.completedDateRange}
          text="Completed"
        />
        <DatePicker
          initialValue={{
            from: completedDateRange.from.formatted,
            to: completedDateRange.to.formatted,
          }}
          range
          onDateChanged={onCompletedDateChanged}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={ordersPageAtoms.filters.types}
          default={ordersPageAtoms.defaults.filters.types}
          text="Type"
        />
        <AtomsCheckboxList
          atom={ordersPageAtoms.filters.types}
          gridProps={{ columns: 2 }}
          items={typeOptions}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={ordersPageAtoms.filters.color}
          default={ordersPageAtoms.defaults.filters.color}
          text="Colors"
        />
        <ColorPicker
          allowMultipleSelection={false}
          value={color}
          onValueChange={handleColorSelect}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={ordersPageAtoms.filters.productId}
          default={ordersPageAtoms.defaults.filters.productId}
          text="Item"
        />
        <SearchField
          items={productOptions}
          maxHeight="50rem"
          placeholder="Select product"
          value={productId}
          onSelect={(item) => {
            if (!!item?.value) {
              setProductId(item?.value);
            } else {
              resetProductId();
            }
          }}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={ordersPageAtoms.filters.action}
          default={ordersPageAtoms.defaults.filters.action}
          text="Action only"
        />
        <Cell justifyContent="start">
          <Toggle value={action} onSelected={setAction} />
        </Cell>
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={ordersPageAtoms.filters.late}
          default={ordersPageAtoms.defaults.filters.late}
          text="Late order only"
        />
        <Cell justifyContent="start">
          <Toggle value={late} onSelected={setLate} />
        </Cell>
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={ordersPageAtoms.filters.statuses}
          default={ordersPageAtoms.defaults.filters.statuses}
          text="Order Status"
        />
        <Stack gap="3x">
          <AtomsCheckboxList
            atom={ordersPageAtoms.filters.statuses}
            gridProps={{ columns: 2 }}
            items={statusOptions}
          />
          <Checkbox
            checked={isProductionFilterApplied}
            labelText="Production Filter"
            name="Production Filter"
            onSelected={toggleProductionFilter}
          />
        </Stack>
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={ordersPageAtoms.filters.size}
          default={ordersPageAtoms.defaults.filters.size}
          text="Size"
        />
        <AtomsRadioList
          atom={ordersPageAtoms.filters.size}
          gridProps={{ columns: 2 }}
          idProperty="code"
          items={productSizeOptions}
          labelProperty="name"
        />
      </FilterRow>
    </FilterGrid>
  );
};
