import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useNotification } from '../../../../hooks/useNotification';
import { useInvalidateGetAvailableJobsForPrintTransferQueueCache } from '../get-available-print-transfer-queue-jobs';
import { useInvalidateGetPrintTransferQueuesCache } from '../get-print-transfer-queues';
import { deletePrintTransferQueue } from './delete-print-transfer-queue';
import { DeletePrintTransferQueueResponse } from './delete-print-transfer-queue.types';

export const useDeletePrintTransferQueueMutation = (args?: {
  onSuccess?: (data: DeletePrintTransferQueueResponse) => void;
  showLoading?: boolean;
}) => {
  const { invalidateGetPrintTransferQueuesCache } =
    useInvalidateGetPrintTransferQueuesCache();

  const { invalidateGetAvailableJobsForPrintTransferQueueCache } =
    useInvalidateGetAvailableJobsForPrintTransferQueueCache();

  const { notify, notifyAxiosError } = useNotification();

  const { isLoading, onMutate } = useQueryMutation<
    number,
    DeletePrintTransferQueueResponse
  >({
    config: {
      onError: (error) => {
        // @ts-expect-error
        if (error.response.status === 404) {
          notify('Queue deleted successfully.');
        } else {
          notifyAxiosError({
            error,
            fallbackMessage: 'Unable to delete print transfer queue.',
          });
        }
      },
      onSuccess: async (data) => {
        notify(data.notice);
        args?.onSuccess?.(data);
        await invalidateGetPrintTransferQueuesCache();
        await invalidateGetAvailableJobsForPrintTransferQueueCache();
      },
    },
    mutationFn: deletePrintTransferQueue,
    showLoading: args?.showLoading,
  });

  return { isLoading, onDeletePrintTransferQueue: onMutate };
};
