import { OrderRecord } from '../../orders.types';
import { DownloadColumn } from '../../../../hooks/use-get-download-menu-option/helpers/download-csv/download-csv.types';
import { formatAsLinkForCSV } from '../../../../hooks/use-get-download-menu-option/helpers/format-as-link-for-csv';
import { getAdminUrl } from '../../../../helpers/get-admin-url';
import routePaths from '../../../../config/route-paths';
import { formatAsCurrency, formatDate } from '../../../../helpers/formatter';
import {
  DOWNLOAD_LONG_FORMAT,
  MEDIUM_FORMAT,
} from '../../../../constants/dates';
import { calculateTotal, getShipmentInfo } from '../../order.adapters';
import { newlineSeparator } from '../../../../hooks/use-get-download-menu-option/helpers/download-csv';

export const downloadColumns: DownloadColumn<OrderRecord>[] = [
  {
    getValue: ({ id }) => {
      return formatAsLinkForCSV({
        text: `#${id}`,
        url: `${getAdminUrl() + routePaths.orders}/${id}`,
      });
    },
    header: 'Order #',
  },
  {
    getValue: ({ type }) => {
      return type;
    },
    header: 'Type',
  },
  {
    getValue: ({ createdAtSec }) => {
      return formatDate(createdAtSec, DOWNLOAD_LONG_FORMAT);
    },
    header: 'Created',
  },
  {
    getValue: ({ completedAtSec }) => {
      return formatDate(completedAtSec, DOWNLOAD_LONG_FORMAT);
    },
    header: 'Completed',
  },
  {
    getValue: ({ user }) => {
      const text =
        user.firstName && user.lastName
          ? [user.firstName, user.lastName].join(' ')
          : user.email;

      return formatAsLinkForCSV({
        text,
        url: `${getAdminUrl() + routePaths.users}/${user.id}`,
      });
    },
    header: 'User',
  },
  {
    getValue: (order) => {
      return calculateTotal(order);
    },
    header: 'Total',
  },
  {
    getValue: ({ status }) => {
      return status;
    },
    header: 'Order Status',
  },
  {
    getValue: ({ shipments }) => {
      return shipments
        .map(({ shipsAtSec }) => {
          return formatDate(shipsAtSec, MEDIUM_FORMAT);
        })
        .join(newlineSeparator);
    },
    header: 'Ships By',
  },
  {
    getValue: ({ shipments }) => {
      return shipments
        .map(({ status }) => {
          return status;
        })
        .join(newlineSeparator);
    },
    header: 'Shipments',
  },
  {
    getValue: ({ shipments }) => {
      return shipments.map(getShipmentInfo).join(newlineSeparator);
    },
    header: 'Items',
  },
  {
    getValue: ({ amount }) => {
      return formatAsCurrency(amount.subtotal, { isCents: true });
    },
    header: 'Sales Price',
  },
  {
    getValue: ({ amount }) => {
      return formatAsCurrency(amount.discount, { isCents: true });
    },
    header: 'Discount',
  },
  {
    getValue: ({ amount }) => {
      return formatAsCurrency(amount.shipping, { isCents: true });
    },
    header: 'Shipping',
  },
  {
    getValue: ({ amount }) => {
      return formatAsCurrency(amount.tax, { isCents: true });
    },
    header: 'Sales Tax',
  },
  {
    getValue: ({ additionalDataForCSV }) => {
      return additionalDataForCSV.shipping_state;
    },
    header: 'Shipping State',
  },
];
