import { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { Badge } from 'gantri-components';
import { getBadgeColorByStatus } from '../panel-details/review-adapter';
import { ProductReviewStatus } from '../../../../../api/users/routes';

export const ReviewBadgeStatus: FC<{ status: ProductReviewStatus }> = (
  props,
) => {
  const theme = useTheme();
  const { status } = props;
  const badgeProps = useMemo(() => {
    return getBadgeColorByStatus(status, theme);
  }, [status, theme]);

  return <Badge {...badgeProps} text={status} />;
};
