import { atom } from 'recoil';

const atomKeyPrefix = 'FILE_UPLOADER';

export const fileUploaderAtoms = {
  isDisabled: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-disabled`,
  }),
};

export const isUploadingDisabledMessage =
  'Cannot start a new upload before the previous one has completed.';
