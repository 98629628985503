import { Button } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { ConfirmCancelFooterProps } from './confirm-cancel-footer.types';
import { restartOrCancelPartModalAtoms } from '../../../restart-or-cancel-part-modal.atoms';
import { partsApi } from '../../../../../../../../../api';
import { useNotification } from '../../../../../../../../../hooks/useNotification';

export const ConfirmCancelFooter = (props: ConfirmCancelFooterProps) => {
  const { handleCloseModal, part } = props;

  const notes = useRecoilValue(restartOrCancelPartModalAtoms.notes);

  const { hideLoading, notify, notifyAxiosError, showLoading } =
    useNotification();

  const handleCancelPart = async () => {
    try {
      showLoading();

      const { data } = await partsApi.cancelPart({
        partId: part.id,
        reasonDetails: notes,
      });

      notify(data.notice, { time: 5000 });

      await handleCloseModal({ updateOnClose: true });
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to cancel part.',
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <>
      <Button
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        disabled={!notes}
        text="Cancel Part"
        variant="primaryAlert"
        onClick={handleCancelPart}
      />
    </>
  );
};
