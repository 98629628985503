import { Link } from 'react-router-dom';
import { Conditional, Flex, Typography } from 'gantri-components';
import routePaths from '../../../../../../config/route-paths';
import { NotApplicableText } from '../../../../../../components/common/not-applicable-text';
import { PartDetailsStockProps } from './part-details-stock.types';

export const PartDetailsStock = (props: PartDetailsStockProps) => {
  const { stockId } = props;

  return (
    <Conditional condition={!!stockId} Fallback={<NotApplicableText />}>
      <Flex gap="0.4rem">
        <Link target="_blank" to={`${routePaths.stocks}/${stockId}`}>
          <Typography
            color="link"
            decoration="underline"
            text={`#${stockId}`}
          />
        </Link>
      </Flex>
    </Conditional>
  );
};
