import { useFormikContext } from 'formik';
import { updatePartReleaseRateModalSteps } from '../../update-part-release-rate-modal.constants';
import { UpdatePartReleaseRateModalSchemaDef } from '../../update-part-release-rate-modal.types';
import { PartReleaseRateModalConfirmContent } from '../step-confirm/part-release-modal-confirm-content';
import { PartReleaseRateModalUpdateContent } from '../step-update/part-release-modal-update-content';
import { UpdatePartReleaseRateModalContentPropsDef } from './update-part-release-rate-modal-content.types';

export const UpdatePartReleaseRateModalContent = (
  props: UpdatePartReleaseRateModalContentPropsDef,
) => {
  const { initialPartsPerDay } = props;

  const { values } = useFormikContext<UpdatePartReleaseRateModalSchemaDef>();

  switch (values.step) {
    case updatePartReleaseRateModalSteps.updateRate: {
      return (
        <PartReleaseRateModalUpdateContent
          initialPartsPerDay={initialPartsPerDay}
        />
      );
    }

    case updatePartReleaseRateModalSteps.confirmUpdate: {
      return <PartReleaseRateModalConfirmContent />;
    }

    default:
      return null;
  }
};
