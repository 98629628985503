import { useRecoilState, useResetRecoilState } from 'recoil';
import { newStocksAtoms } from '../../../../new-stocks.atoms';
import { TableDataItem } from '../../use-get-new-stocks-table-details.types';

export const useIsEditingRow = (rowData?: TableDataItem) => {
  const [editingRowId, setEditingRowId] = useRecoilState(
    newStocksAtoms.editingRowId,
  );
  const resetEditingRowId = useResetRecoilState(newStocksAtoms.editingRowId);

  const isEditingPage = !!editingRowId;
  const isEditingRow = rowData?.id === editingRowId;

  return {
    isEditingPage,
    isEditingRow,
    resetEditingRowId,
    setEditingRowId,
  };
};
