import { Box } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledStockWrapperBox = styled(Box)`
  ${({ theme }) => {
    return css`
      border: 1px solid ${theme.colors.dividers.t1};
      border-radius: 5px;
    `;
  }}
`;
