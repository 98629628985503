import { Dropdown, FormikInput, Grid, Typography } from 'gantri-components';
import { StyledPageSubsection } from '../../../../components/layout/page-layout-styles';
import { StyledInventoryPurchaseFieldContainer } from '../../new-inventory-purchase.styles';
import { factoryDestinations } from '../../../../constants/inventory-purchases';
import { inventoryPurchasePriorityAsOptions } from '../../new-inventory-purchase.adapter';

export const ShippingDetailsDestination = () => {
  return (
    <StyledPageSubsection>
      <Typography
        marginTop="1rem"
        text="Destination:"
        textStyle="bold"
        variant="h4"
      />

      <Grid columns={2} width="100%">
        <StyledInventoryPurchaseFieldContainer>
          <Typography text="Address" textStyle="bold" />
          <FormikInput
            Field={
              <Dropdown
                idProperty="value"
                items={factoryDestinations}
                labelProperty="label"
                placeholder="Address"
              />
            }
            name="address"
          />
        </StyledInventoryPurchaseFieldContainer>
        <StyledInventoryPurchaseFieldContainer>
          <Typography text="Priority" textStyle="bold" />
          <FormikInput
            Field={
              <Dropdown
                idProperty="value"
                items={inventoryPurchasePriorityAsOptions}
                labelProperty="label"
              />
            }
            name="priority"
          />
        </StyledInventoryPurchaseFieldContainer>
      </Grid>
    </StyledPageSubsection>
  );
};
