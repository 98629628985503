import {
  FormikInput,
  Stack,
  TextArea,
  TextField,
  Typography,
} from 'gantri-components';
import { Label } from '../../../../../../../../../components/label';
import { PartReleaseRateModalUpdateContentPropsDef } from './part-release-modal-update-content.types';

export const PartReleaseRateModalUpdateContent = (
  props: PartReleaseRateModalUpdateContentPropsDef,
) => {
  const { initialPartsPerDay } = props;

  return (
    <Stack gap="4x">
      <Typography text="Update the value for painted parts released per day." />

      <Stack gap=".5x">
        <Label text="Current" />
        <Typography text={`${initialPartsPerDay} parts per day`} />
      </Stack>
      <FormikInput
        Field={
          <TextField
            labelText="Update to"
            placeholder="Input value"
            required
            rightIcon={
              <Typography color="t2" text="parts per day" whiteSpace="nowrap" />
            }
            type="number"
          />
        }
        fieldVariant="standard"
        name="partsPerDay"
      />

      <FormikInput
        Field={
          <TextArea
            labelText="Notes"
            minRows={4}
            placeholder="Provide any notes about this update"
            style={{ font: 'inherit' }}
          />
        }
        fieldVariant="standard"
        name="notes"
      />
    </Stack>
  );
};
