import * as yup from 'yup';
import { ConfirmDeliveryFormDataDef } from './confirm-delivery-modal.types';

const requiredText = 'Required';

export const confirmDeliveryFormSchema: yup.SchemaOf<ConfirmDeliveryFormDataDef> =
  yup.object().shape({
    deliveredAt: yup.string().nullable().required(requiredText),
    discardedQuantity: yup.number().nullable().min(0),
    expectedQuantity: yup.number().nullable().min(0).required(requiredText),
    notes: yup
      .string()
      .nullable()
      .when('discardedQuantity', {
        is: (value) => {
          return !!value && value > 0;
        },
        then: (schema) => {
          return schema.required(requiredText);
        },
      }),
    photos: yup.array().of(yup.string().nullable().required(requiredText)),
    quantity: yup.number().nullable().min(0).required(requiredText),
  });
