import { useMemo } from 'react';
import {
  Conditional,
  Dropdown,
  FormikInput,
  Grid,
  Typography,
} from 'gantri-components';
import { useField } from 'formik';
import { StyledPageSubsection } from '../../../../components/layout/page-layout-styles';
import { StyledInventoryPurchaseFieldContainer } from '../../new-inventory-purchase.styles';
import {
  countryOptions,
  getStatesByCountry,
} from '../../new-inventory-purchase.adapter';
import { useFormikHelper } from '../../../../hooks';

export const ShippingDetailsOrigin = () => {
  const [country] = useField<string>('country');
  const { resetInputsValue } = useFormikHelper();

  const hasStates =
    country.value === 'United States' || country.value === 'Canada';
  const states = useMemo(() => {
    return getStatesByCountry(country.value);
  }, [country]);

  const onCountryChange = () => {
    resetInputsValue('state', '', true);
  };

  return (
    <StyledPageSubsection>
      <Typography
        marginTop="1rem"
        text="Origin:"
        textStyle="bold"
        variant="h4"
      />

      <Grid columns={{ lg: 3, sm: 2 }} width="100%">
        <StyledInventoryPurchaseFieldContainer>
          <Typography text="Country" textStyle="bold" />
          <FormikInput
            Field={
              <Dropdown
                idProperty="name"
                items={countryOptions}
                labelProperty="name"
                placeholder="Country"
                onSelect={onCountryChange}
              />
            }
            name="country"
          />
        </StyledInventoryPurchaseFieldContainer>

        <StyledInventoryPurchaseFieldContainer>
          <Typography text="State/Province" textStyle="bold" />
          <Conditional
            condition={hasStates}
            Fallback={<FormikInput name="state" placeholder="State" />}
          >
            <FormikInput
              Field={
                <Dropdown
                  idProperty="name"
                  items={states}
                  labelProperty="name"
                  placeholder="State"
                />
              }
              name="state"
            />
          </Conditional>
        </StyledInventoryPurchaseFieldContainer>

        <StyledInventoryPurchaseFieldContainer>
          <Typography text="City" textStyle="bold" />
          <FormikInput name="city" placeholder="City" />
        </StyledInventoryPurchaseFieldContainer>
      </Grid>
    </StyledPageSubsection>
  );
};
