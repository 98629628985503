import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export const sidebarCollapseThreshold = 1400;

export const tabletThreshold = 1024;

export const mobileThreshold = 640;

const atomKeyPrefix = 'UI';

const { persistAtom } = recoilPersist();

export const uiAtoms = {
  activeMenuItemTooltipId: atom<string>({
    default: null,
    effects_UNSTABLE: [persistAtom],
    key: `${atomKeyPrefix}-active-menu-item-tooltip-id`,
  }),
  expandedMenuItems: atom<Record<string, boolean>>({
    default: {},
    effects_UNSTABLE: [persistAtom],
    key: `${atomKeyPrefix}-expanded-menu-items`,
  }),
  isSidebarOpen: atom<boolean>({
    default: false,
    effects_UNSTABLE: [persistAtom],
    key: `${atomKeyPrefix}-is-sidebar-open`,
  }),
  viewportWidth: atom<number>({
    default: window.innerWidth,
    effects_UNSTABLE: [persistAtom],
    key: `${atomKeyPrefix}-viewport-width`,
  }),
};
