import {
  Typography,
  Table,
  Conditional,
  Stack,
  productColorsMap,
} from 'gantri-components';
import { unpaintedColor } from '../../../../../../constants/colors';
import { getRecommendedStocksTableColumns } from './helpers/get-recommended-stocks-table-columns';
import { RecommendedStocksTableProps } from './recommended-stocks-table.types';

export const RecommendedStocksTable = (props: RecommendedStocksTableProps) => {
  const { part, recommendedStocks, selectedStock, setSelectedStock } = props;
  const { product } = part;

  const columns = getRecommendedStocksTableColumns({
    currentStockId: part.stockId,
    selectedStock,
    setSelectedStock,
  });

  const productString = [
    product.name,
    productColorsMap[product?.color]?.shortColorName || unpaintedColor.name,
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <Stack gap="4x">
      <Typography
        text={`Which ${productString} would you like to reassign ${part.name} to?`}
      />

      <Table columns={columns} data={recommendedStocks} />
      <Conditional condition={!recommendedStocks.length}>
        <Typography
          color="t2"
          text={`No available Stocks that require ${part.name}.`}
        />
      </Conditional>
    </Stack>
  );
};
