import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  UpdateCutSheetArgsDef,
  UpdateCutSheetResponseDef,
} from './update-cut-sheet.types';
import { updateCutSheet } from './update-cut-sheet';

export const useUpdateCutSheet = <TransformedData = UpdateCutSheetResponseDef>(
  props?: GenericMutateQueryProps<
    UpdateCutSheetArgsDef,
    UpdateCutSheetResponseDef,
    TransformedData
  >,
) => {
  const { onMutate: onUpdateCutSheet, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to update cut sheet.',
    mutationFn: updateCutSheet,
    ...props,
  });

  return { ...rest, onUpdateCutSheet };
};
