import { useState } from 'react';
import { debounce } from 'lodash';
import {
  Cell,
  Grid,
  TextField,
  Typography,
  Box,
  HandleUploadsComplete,
  FileUploader,
  Stack,
} from 'gantri-components';
import {
  getExtension,
  getNameWithoutExtension,
} from '../../../../../../helpers/firebase';
import { useFirebaseFileUploader } from '../../../../../../hooks/use-firebase-file-uploader';
import { PrototypingItem } from './components/prototyping-item';
import { PrototypingStepPropsDef } from './prototyping-step.types';

export const PrototypingStep = (props: PrototypingStepPropsDef) => {
  const {
    engineering,
    prototypeUploadPath,
    recordUpdated,
    thumbnailSize,
    transformFileName,
  } = props;

  const [photoName, setPhotoName] = useState('');

  const onPrototypeUploaded: HandleUploadsComplete = async ([
    { fileName, fileSize, fileUrl },
  ]) => {
    const images = [...engineering.prototyping.images];

    const name = photoName || getNameWithoutExtension(fileName);

    images.push({
      fileExtension: getExtension(fileName),
      fileName: name,
      fileSize,
      fileUrl,
      name,
      nameOfPhoto: name,
    });

    await recordUpdated('prototyping', 'images', images)();
    setPhotoName('');
  };

  const { fileUploaderProps: prototypeFileUploaderProps } =
    useFirebaseFileUploader({
      fileUrl: null,
      handleUploadsComplete: onPrototypeUploaded,
      onFileDelete: null,
      path: prototypeUploadPath,
    });

  const onNewImageNameTextChange = (value: string) => {
    setPhotoName(value);
  };

  return (
    <Stack columns={1} gap="x">
      <Typography text="Prototyping" textStyle="bold" variant="h2" />
      <Typography
        color="t2"
        marginTop="x"
        text="Upload photos of prototypes after they finish printing"
      />

      <Grid columns={`repeat(2, ${thumbnailSize})`} gap="4x">
        {engineering.prototyping.images.map((item, index) => {
          return (
            <PrototypingItem
              key={item.fileUrl}
              engineering={engineering}
              index={index}
              item={item}
              prototypeUploadPath={prototypeUploadPath}
              recordUpdated={recordUpdated}
              thumbnailSize={thumbnailSize}
              transformFileName={transformFileName}
              onPrototypeUploaded={onPrototypeUploaded}
            />
          );
        })}
        <Cell>
          <Box marginBottom="2x">
            <FileUploader
              key={engineering.prototyping.images.length}
              {...prototypeFileUploaderProps}
              fileName={null}
              isUploaderOnly
              thumbnailSize={thumbnailSize}
              transformFileName={transformFileName}
              variant="thumbnail"
            />
          </Box>
          <TextField
            placeholder="Name of photo"
            value={photoName}
            onTextChange={debounce(onNewImageNameTextChange, 300)}
          />
        </Cell>
      </Grid>
    </Stack>
  );
};
