import ReplacementReason from './replacement-reason';
import RefundReason from './refund-reason';
import ReassignStock from './reassign-stock/reassign-stock';
import { useRouter } from '../../hooks';
import { useFetchOrder } from '../../api/transactions/routes';

const OrderReason = () => {
  const { params } = useRouter();
  const { id, type } = params;

  const { data } = useFetchOrder({
    enabled: !!id,
    fetchArgs: {
      id,
    },
    showLoading: true,
  });

  const currentOrderData = data?.order;

  if (type === 'replacement' && currentOrderData)
    return <ReplacementReason currentOrderData={currentOrderData} id={id} />;
  if (type === 'refund' && currentOrderData)
    return <RefundReason currentOrderData={currentOrderData} id={id} />;
  if (type === 'return' && currentOrderData)
    return (
      <RefundReason currentOrderData={currentOrderData} id={id} isReturn />
    );
  if (type === 'reassign-stock' && currentOrderData)
    return <ReassignStock currentOrderData={currentOrderData} id={id} />;

  return null;
};

export default OrderReason;
