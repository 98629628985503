import * as yup from 'yup';
import { addressSchema } from './components/addresses-section/address.schema';
import { required } from '../../helpers/validators';

export const newOrderSchema = yup.object().shape({
  email: yup.lazy((_, context) => {
    return ['Trade', 'Third Party', 'Wholesale'].includes(
      context.parent.orderType,
    )
      ? yup.string().required('Required').email('Invalid email address')
      : yup.string().nullable();
  }),
  locationInstruction: yup.lazy((_, context) => {
    return context.parent.shippingType === 'other'
      ? yup.object().shape({
          fileBlob: yup.string().required('Required'),
        })
      : yup.mixed().nullable();
  }),
  notes: required(),
  orderType: required(),
  resaleCertificate: yup.lazy((_, context) => {
    return context.parent.orderType === 'Wholesale'
      ? yup.object().shape({
          fileName: yup.string().required('Required'),
        })
      : yup.mixed().nullable();
  }),
  selectedAddress: yup.lazy((_, context) => {
    return context.parent.shippingType !== 'doNotShip'
      ? addressSchema
      : yup.mixed().nullable();
  }),
  shippingType: required(),
  stocks: yup.array().required('Required'),
});
