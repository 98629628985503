import styled from 'styled-components';
import { media } from 'gantri-components';

export const StyledOrderPageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledLeftSection = styled.div`
  width: calc(66% - 20px);
  display: flex;
  flex-direction: column;
  margin-right: 20px;

  ${media.lessThan('sm')`
      margin-right: 0;
      width: 100%;
  `};
`;

export const StyledRightSection = styled.div`
  margin-left: 20px;
  min-width: 245px;
  width: calc(33% - 20px);
  margin-top: 60px;
  margin-bottom: 120px;

  ${media.lessThan('md')`
    margin-top: 9rem;
  `};

  ${media.lessThan('sm')`
     margin-left: 0;
     margin-top: 6.4rem;
     margin-bottom: 0;
     width: 100%;
  `};
`;

export const StyledOrderActionWrapper = styled.div`
  display: flex;
  align-items: center;

  & > button:not(:last-child) {
    margin-right: 10px;
  }
`;
