import { useRecoilValue, useSetRecoilState } from 'recoil';
import { transactionsApi } from '../../../../../../api';
import { useInvalidateFetchOrderCache } from '../../../../../../api/transactions/routes';
import { useNotification } from '../../../../../../hooks/useNotification';
import { shipmentActionsModalSteps } from '../../shipment-actions-modal.constants';
import { shipmentActionsModalAtoms } from '../../shipment-actions-modal.atoms';
import { HandleShipItemsPropsDef } from './use-handle-ship-items.types';

export const useHandleShipItems = () => {
  const shipmentId = useRecoilValue(shipmentActionsModalAtoms.shipmentId);
  const setStep = useSetRecoilState(shipmentActionsModalAtoms.step);

  const { notify, notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const { invalidateFetchOrderCache } = useInvalidateFetchOrderCache();

  const handleShipItems = async (props?: HandleShipItemsPropsDef) => {
    const { handleCloseModal, isDoNotShip } = props || {};

    await onInterceptProcessingRequest(async () => {
      if (isDoNotShip) {
        try {
          const { data } = await transactionsApi.deliverDoNotShipShipment({
            shipmentId,
          });

          notify(data.notice);

          await invalidateFetchOrderCache();

          await handleCloseModal?.();
        } catch (error: unknown) {
          notifyAxiosError({
            error,
            fallbackMessage:
              'Unable to deliver shipment marked as "Do Not Ship".',
          });
        }
      } else {
        try {
          const { data } = await transactionsApi.shipTransaction({
            id: shipmentId,
          });

          notify(data.notice);

          await invalidateFetchOrderCache();

          await handleCloseModal?.();
        } catch (error: unknown) {
          notifyAxiosError({ error, fallbackMessage: 'Unable to ship order.' });

          setStep(shipmentActionsModalSteps.shippingError);
        }
      }
    });
  };

  return { handleShipItems, processing };
};
