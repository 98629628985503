import { useEffect, useState } from 'react';
import { deleteBlob } from '../../../../helpers/firebase';
import { InventoryPurchase as InventoryPurchaseType } from '../../../../api/inventory-purchases/inventory-purchases.types';
import {
  HandleSave,
  OnUpdate,
  ToggleEditMode,
  UpdateEditedInventoryPurchaseWith,
} from './use-inventory-purchase-handlers.types';
import { useNotification } from '../../../../hooks/useNotification';
import { inventoryPurchasesApi } from '../../../../api/inventory-purchases';
import { defaultValues } from './use-inventory-purchase-handlers.constants';
import { inventoryPurchaseStatuses } from '../../../../constants/inventory-purchases';

export const useInventoryPurchaseHandlers = ({ id }: { id: number }) => {
  const { hideLoading, notify, notifyAxiosError, showLoading } =
    useNotification();

  const [unalteredInventoryPurchase, setUnalteredInventoryPurchase] =
    useState<InventoryPurchaseType>();
  const [editedInventoryPurchase, setEditedInventoryPurchase] =
    useState<InventoryPurchaseType>();
  const [isEditingDisabled, setIsEditingDisabled] = useState<boolean>(true);

  const updateEditedInventoryPurchaseWith: UpdateEditedInventoryPurchaseWith = (
    newValues,
  ) => {
    setEditedInventoryPurchase((oldValues) => {
      return { ...oldValues, ...newValues };
    });
  };

  const isPurchaseComplete =
    unalteredInventoryPurchase?.status === inventoryPurchaseStatuses.completed;

  const handleSave: HandleSave = async ({ setIsEditing }) => {
    try {
      showLoading();
      setIsEditing(false);

      const { data } = await inventoryPurchasesApi.updateInventoryPurchase(
        editedInventoryPurchase,
      );

      onUpdate(data.purchase);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to update inventory purchase.',
      });
    } finally {
      hideLoading();
    }
  };

  const toggleEditMode: ToggleEditMode = ({ isEditing, setIsEditing }) => {
    if (isEditing) {
      // revert unsaved changes
      handleDeletingUnsavedAttachments();
      setEditedInventoryPurchase(unalteredInventoryPurchase);
      setIsEditingDisabled(false);
    } else {
      // set defaults on edited inventory purchase value
      setEditedInventoryPurchase({
        ...unalteredInventoryPurchase,
        address: unalteredInventoryPurchase.address || defaultValues.address,
        country: unalteredInventoryPurchase.country || defaultValues.country,
        currency: unalteredInventoryPurchase.currency || defaultValues.currency,
        priority: unalteredInventoryPurchase.priority || defaultValues.priority,
      });
      setIsEditingDisabled(true);
    }

    setIsEditing(!isEditing);
  };

  const handleDeletingUnsavedAttachments = () => {
    const attachmentsToDelete = editedInventoryPurchase.attachments.filter(
      ({ fileName }) => {
        const isNewAttachment = !unalteredInventoryPurchase.attachments.some(
          (attachment) => {
            return attachment.fileName === fileName;
          },
        );

        return isNewAttachment;
      },
    );

    if (attachmentsToDelete.length) {
      try {
        showLoading();
        notify('Deleting unsaved attachments...');

        attachmentsToDelete.forEach((attachment) => {
          deleteBlob({
            fileUrl: attachment.url,
          });
        });
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to delete unsaved attachments.',
        });
      } finally {
        hideLoading();
      }
    }
  };

  const fetchInventoryPurchase = async () => {
    try {
      showLoading();

      const { data } = await inventoryPurchasesApi.getInventoryPurchase(id);

      setUnalteredInventoryPurchase(data.purchase);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to load inventory purchase.',
      });
    } finally {
      hideLoading();
    }
  };

  const onUpdate: OnUpdate = (newInventoryPurchaseData) => {
    if (newInventoryPurchaseData) {
      setUnalteredInventoryPurchase(newInventoryPurchaseData);
    } else {
      setEditedInventoryPurchase(unalteredInventoryPurchase);
    }
  };

  useEffect(() => {
    setEditedInventoryPurchase(unalteredInventoryPurchase);

    if (isEditingDisabled && !isPurchaseComplete) {
      setIsEditingDisabled(false);
    }

    if (!isEditingDisabled && isPurchaseComplete) {
      setIsEditingDisabled(true);
    }
  }, [unalteredInventoryPurchase]);

  return {
    editedInventoryPurchase,
    fetchInventoryPurchase,
    handleSave,
    isEditingDisabled,
    isPurchaseComplete,
    onUpdate,
    setIsEditingDisabled,
    toggleEditMode,
    updateEditedInventoryPurchaseWith,
  };
};
