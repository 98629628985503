import axios from 'axios';
import { environment } from '../../../../environment';
import { GetVersionResponse } from './get-version.types';

export const getVersion = () => {
  return axios
    .get<GetVersionResponse>(`${environment.API_URL}/git/get-last-tag`)
    .then(({ data }) => {
      return data;
    });
};
