import * as yup from 'yup';
import { InventoryRequest } from '../../../../../../../../api/jobs/jobs.types';
import {
  StockJobInventory,
  JobBlockStock,
  StockJobVariantInventory,
  Product,
} from '../../../../../../../../api/products/products.types';
import {
  stockJobInventorySchema,
  stockJobVariantInventorySchema,
} from '../../stock-jobs-section.schema';

export interface StockJobsAssembleInventoryFormData {
  assembleStandardInventories: StockJobInventory[];
  assembleVariantInventories: StockJobVariantInventory[];
  id: number;
  variantIds: number[];
}

export const convertStockJobInventoriesToInventoryRequests = <
  InventoryData extends Partial<StockJobInventory>,
>(
  inventories: InventoryData[],
): (InventoryRequest & Omit<InventoryData, keyof StockJobInventory>)[] => {
  return inventories.map(
    ({ amount, inventoryId, name, status, unit, ...rest }) => {
      const inventoryRequest: InventoryRequest &
        Omit<InventoryData, keyof StockJobInventory> = {
        ...rest,
        inventory: {
          id: inventoryId,
          name,
          status,
          unit,
        },
        name,
        need: amount,
        unit,
      };

      return inventoryRequest;
    },
  );
};

export const getStockJobsAssembleInventoryInitialValues = ({
  product,
  stockJobsBlock,
}: {
  product: Product;
  stockJobsBlock: JobBlockStock;
}): StockJobsAssembleInventoryFormData => {
  return {
    assembleStandardInventories:
      stockJobsBlock?.assembleStandardInventories || [],
    assembleVariantInventories:
      stockJobsBlock?.assembleVariantInventories || [],
    id: stockJobsBlock?.id,
    variantIds:
      stockJobsBlock?.variantIds ||
      product?.variants?.map(({ id }) => {
        return id;
      }) ||
      [],
  };
};

export const stockJobsAssembleInventoryFormikSchema: yup.SchemaOf<StockJobsAssembleInventoryFormData> =
  yup.object().shape({
    assembleStandardInventories: stockJobInventorySchema,
    assembleVariantInventories: stockJobVariantInventorySchema,
    id: yup.number().required(),
    variantIds: yup.array().of(yup.number()).required(),
  });
