import { GetRowProps } from 'gantri-components';
import { Vendor } from '../../api/vendors/vendors.types';
import { TableSortField } from '../../components/common/table/table.props';
import { VendorsSortingField } from './components/vendors-filters/vendors-filters.types';

export const vendorSortFields: TableSortField<VendorsSortingField>[] = [
  {
    bidirectional: true,
    label: 'Name',
    sortingField: 'name',
  },
  {
    bidirectional: true,
    label: 'Active inventories',
    sortingField: 'activeInventoriesCount',
  },
  {
    bidirectional: true,
    label: 'Last ordered date',
    sortingField: 'lastOrdered',
  },
];

export const getRowProps: GetRowProps<Vendor> = ({ original }) => {
  const { delayedShipments, quantityIssues } = original;

  const shouldWarn = !!delayedShipments || !!quantityIssues;

  return {
    status: shouldWarn ? 'warning' : undefined,
  };
};
