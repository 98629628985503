import { Flex, Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { ViewBatchAnchor } from '../../../../../../../../batch/components/view-batch-anchor';
import { updatePartBatchModalAtoms } from '../../../update-part-batch-modal.atoms';

export const PartAddedSuccessContent = () => {
  const newBatchId = useRecoilValue(updatePartBatchModalAtoms.newBatchId);

  return (
    <Stack alignContent="center" gap="0" minHeight="12.4rem">
      <Flex gap="x" justifyContent="center">
        <Typography text={`Part added to Batch #${newBatchId}`} />
        <ViewBatchAnchor batchId={newBatchId} />
      </Flex>
      <Typography
        align="center"
        text="Make sure part is placed in the correct rack or container."
      />
    </Stack>
  );
};
