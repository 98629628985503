import { useRecoilValue } from 'recoil';
import { addStocksModalAtoms } from '../../add-stocks-modal.atoms';
import { addStocksModalSteps } from '../../add-stocks-modal.constants';
import { AddStocksModalContentProps } from './add-stocks-modal-content.types';
import { SetQuantityContent } from '../step-set-quantity/set-quantity-content';
import { ConfirmQuantityContent } from '../step-confirm-quantity/confirm-quantity-content';

export const AddStocksModalContent = (props: AddStocksModalContentProps) => {
  const { stocks } = props;

  const step = useRecoilValue(addStocksModalAtoms.step);

  switch (step) {
    case addStocksModalSteps.setQty:
      return <SetQuantityContent stocks={stocks} />;

    case addStocksModalSteps.confirmQty:
      return <ConfirmQuantityContent stocks={stocks} />;

    default:
      return null;
  }
};
