import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { restockStock } from './restock-stock';
import { RestockStockArgs, RestockStockResponse } from './restock-stock.types';

export const useRestockStock = <TransformedData = RestockStockResponse>(
  props?: GenericMutateQueryProps<
    RestockStockArgs,
    RestockStockResponse,
    TransformedData
  >,
) => {
  const { onMutate: onRestockStock, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to restock stock.',
    mutationFn: restockStock,
    ...props,
  });

  return { ...rest, onRestockStock };
};
