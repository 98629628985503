import {
  Cell,
  Grid,
  Radio,
  Stack,
  TextArea,
  Typography,
} from 'gantri-components';
import { useRecoilState } from 'recoil';
import { debounce } from 'lodash';
import { Fragment } from 'react';
import { restartOrCancelPartModalAtoms } from '../../../restart-or-cancel-part-modal.atoms';
import { restartReasonOptions } from './restart-reason-content.constants';

export const RestartReasonContent = () => {
  const [restartReason, setRestartReason] = useRecoilState(
    restartOrCancelPartModalAtoms.restartReason,
  );
  const [notes, setNotes] = useRecoilState(restartOrCancelPartModalAtoms.notes);

  return (
    <Stack gap="0.8rem" paddingTop="1.6rem">
      <Typography text="Re-start Reason" variant="h4" />
      <Typography text="Please select a reason for re-starting this part. " />
      <Grid
        columnGap="0.8rem"
        columns="repeat(2, max-content)"
        rowGap="1.2rem"
        verticalPadding="0.8rem"
      >
        {restartReasonOptions.map(({ label, subTitle, value }) => {
          return (
            <Fragment key={label}>
              <Cell paddingTop="0.2rem">
                <Radio
                  groupValue={value}
                  value={restartReason}
                  onSelected={setRestartReason}
                />
              </Cell>
              <Stack gap="0.8rem">
                <Typography
                  text={label}
                  onClick={() => {
                    setRestartReason(value);
                  }}
                />
                <Typography
                  color="t2"
                  text={subTitle}
                  variant="p3"
                  onClick={() => {
                    setRestartReason(value);
                  }}
                />
              </Stack>
            </Fragment>
          );
        })}
      </Grid>
      <Typography
        text="Add additional information about your selection:"
        textStyle="bold"
      />
      <TextArea
        placeholder="Enter details about the lost or failed part"
        required={restartReason === 'Failed'}
        value={notes}
        onTextChange={debounce(setNotes, 300)}
      />
    </Stack>
  );
};
