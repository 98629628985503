import { Checkbox } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { AtomsCheckboxList } from '../../../../../../components/common/atoms-checkbox-list';
import DatePicker from '../../../../../../components/common/date-picker';
import { OnDateChanged } from '../../../../../../components/common/date-picker/date-picker.types';
import {
  FilterGrid,
  FilterRow,
} from '../../../../../../components/common/filter-grid';
import { FilterResetLabel } from '../../../../../../components/common/filter-reset-label';
import { inventoryPurchaseStatusOptions } from '../../../../../../components/dropdowns/inventory-purchases-filter/inventory-purchases-filter.constants';
import { vendorPurchasesFilterAtoms } from './vendor-purchases-filters.atoms';

export const VendorPurchasesFilters = () => {
  const [createdDateRange, setCreatedDateRange] = useRecoilState(
    vendorPurchasesFilterAtoms.filters.createdDateRange,
  );
  const [completedDateRange, setCompletedDateRange] = useRecoilState(
    vendorPurchasesFilterAtoms.filters.completedDateRange,
  );
  const [hasDelayedShipping, setHasDelayedShipping] = useRecoilState(
    vendorPurchasesFilterAtoms.filters.hasDelayedShipping,
  );
  const [hasQuantityIssues, setHasQuantityIssues] = useRecoilState(
    vendorPurchasesFilterAtoms.filters.hasQuantityIssues,
  );

  const onCreatedDateChanged: OnDateChanged = ({
    formattedFrom,
    formattedTo,
  }) => {
    setCreatedDateRange({
      from: formattedFrom || null,
      to: formattedTo || null,
    });
  };

  const onCompletedDateChanged: OnDateChanged = ({
    formattedFrom,
    formattedTo,
  }) => {
    setCompletedDateRange({
      from: formattedFrom || null,
      to: formattedTo || null,
    });
  };

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={vendorPurchasesFilterAtoms.filters.createdDateRange}
          default={vendorPurchasesFilterAtoms.defaults.filters.createdDateRange}
          text="Created"
        />
        <DatePicker
          autoWidth
          initialValue={createdDateRange}
          range
          onDateChanged={onCreatedDateChanged}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={vendorPurchasesFilterAtoms.filters.completedDateRange}
          default={
            vendorPurchasesFilterAtoms.defaults.filters.completedDateRange
          }
          text="Completed"
        />
        <DatePicker
          autoWidth
          initialValue={completedDateRange}
          range
          onDateChanged={onCompletedDateChanged}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={vendorPurchasesFilterAtoms.filters.statuses}
          default={vendorPurchasesFilterAtoms.defaults.filters.statuses}
          text="Status"
        />
        <AtomsCheckboxList
          atom={vendorPurchasesFilterAtoms.filters.statuses}
          items={inventoryPurchaseStatusOptions}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={vendorPurchasesFilterAtoms.filters.hasDelayedShipping}
          default={
            vendorPurchasesFilterAtoms.defaults.filters.hasDelayedShipping
          }
          text="Has delayed shipping"
        />
        <Checkbox
          checked={hasDelayedShipping}
          onSelected={setHasDelayedShipping}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={vendorPurchasesFilterAtoms.filters.hasQuantityIssues}
          default={
            vendorPurchasesFilterAtoms.defaults.filters.hasQuantityIssues
          }
          text="Has incorrect quantity"
        />
        <Checkbox
          checked={hasQuantityIssues}
          onSelected={setHasQuantityIssues}
        />
      </FilterRow>
    </FilterGrid>
  );
};
