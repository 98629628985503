import { Conditional, Typography } from 'gantri-components';
import { List } from '../../../../../../components/common/list';
import { CellJobPartsProps } from './cell-job-parts.types';

export const CellJobParts = (props: CellJobPartsProps) => {
  const { getValue } = props;

  const parts = getValue();

  return (
    <Conditional condition={!!parts?.length}>
      <List type="ordered">
        {parts?.map((part, index) => {
          return (
            <li key={index}>
              <Typography text={part} />
            </li>
          );
        })}
      </List>
    </Conditional>
  );
};
