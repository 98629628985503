import { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon, Typography } from 'gantri-components';
import { jobTypeToIconTypeMap } from '../../../../constants/jobs';

const JobTypeDataCell = ({ type }) => {
  return (
    <Grid alignItems="center" columns="max-content 1fr" gap="0.7rem">
      <Icon color="t2" name={jobTypeToIconTypeMap[type]} />
      <Typography color="t1" text={type} variant="p2" />
    </Grid>
  );
};

JobTypeDataCell.propTypes = {
  type: PropTypes.string.isRequired,
};

export default memo(JobTypeDataCell);
