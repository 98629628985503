import {
  Cell,
  Conditional,
  FormikInput,
  Grid,
  Stack,
  TextField,
  Typography,
} from 'gantri-components';
import { Divider } from '../../../../../../../../../../components/divider';
import { PartJobBlocksInstructions } from '../part-job-blocks-instructions';
import { formatAsLocaleNumber } from '../../../../../../../../../../helpers/formatter';
import {
  jobsBlockGridColumns,
  detailsGridColumns,
  defaultJobBlockDurations,
} from '../../part-job-blocks.constants';
import { PartJobBlocksBondoBlockProps } from './part-job-blocks-bondo-block.types';
import { useIsEditingPartRowJobsDataForm } from '../../../../hooks/use-is-editing-part-row';

export const PartJobBlocksBondoBlock = (
  props: PartJobBlocksBondoBlockProps,
) => {
  const { bondoBlock, hasBondo } = props;

  const isEditingPartRow = useIsEditingPartRowJobsDataForm();

  const formattedSandRawDuration = bondoBlock.sandRawDuration
    ? `${formatAsLocaleNumber(bondoBlock.sandRawDuration)}m`
    : '-';

  return (
    <Conditional condition={hasBondo}>
      <Stack gap="4x">
        <Divider />

        <Grid columns={jobsBlockGridColumns} gap="x">
          <Cell>
            <Typography text="Bondo" variant="h5" />
          </Cell>
          <Grid columns={detailsGridColumns} gap="x">
            <Typography text="Sand-Raw Duration" textStyle="bold" />
            <Conditional
              condition={isEditingPartRow}
              Fallback={<Typography text={formattedSandRawDuration} />}
            >
              <FormikInput
                Field={
                  <TextField
                    placeholder={`Suggested: ${defaultJobBlockDurations.sandRaw}`}
                    rightIcon={<Typography color="t2" text="m" />}
                    type="number"
                  />
                }
                fieldVariant="standard"
                name="bondoBlock.sandRawDuration"
                required
              />
            </Conditional>
            <Typography text="Instructions" textStyle="bold" />
            <PartJobBlocksInstructions
              instructions={bondoBlock.instructions}
              instructionsJobMap={bondoBlock.instructionsJobMap}
              isEditing={isEditingPartRow}
              schemaId="bondoBlock.instructions"
            />
          </Grid>
        </Grid>
      </Stack>
    </Conditional>
  );
};
