import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  GetDownloadCutSheetUrlArgsDef,
  GetDownloadCutSheetUrlResponseDef,
} from './get-download-cut-sheet-url.types';

export const getDownloadCutSheetUrl = ({
  sku,
}: GetDownloadCutSheetUrlArgsDef) => {
  return axios
    .get<GetDownloadCutSheetUrlResponseDef>(
      `${getCoreApiUrl('products')}/${sku}/download-cut-sheet`,
    )
    .then(({ data }) => {
      return data;
    });
};
