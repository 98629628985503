import React, { FC, useMemo, useState } from 'react';
import {
  Cell,
  Conditional,
  Dropdown,
  Flex,
  getFileUrl,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from 'gantri-components';
import { StockHeaderProps } from './stock-header.props';
import { Thumbnail } from '../../../../components';
import { PartStatusBadge } from '../../../../components/common/part-status-badge';
import { stockPrioritiesOptions } from './stock-header.constants';
import { stocksApi } from '../../../../api';
import { StockPriority } from '../../../../api/stocks/routes/update-priority/update-priority.types';
import { useNotification } from '../../../../hooks/useNotification';
import { Part } from '../../../../api/jobs/jobs.types';
import { NotApplicableText } from '../../../../components/common/not-applicable-text';
import { noneOption } from '../../../../constants/options';
import { Divider } from '../../../../components/divider';
import { productCategories } from '../../../../api/products/products.constants';
import { ColorSwatchAndLabel } from '../../../../components/common/color-swatch-and-label';
import { unpaintedColor } from '../../../../constants/colors';

export const StockHeader: FC<StockHeaderProps> = (props) => {
  const { refreshStock, stock } = props;
  const {
    category,
    color,
    highPriority,
    id: stockId,
    imageUrl,
    name,
    parts,
    productId,
    sku,
    status,
    variants,
  } = stock;
  const [editingPriority, setEditingPriority] = useState(false);
  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const fileUrl = getFileUrl<'products'>({
    directory: 'products',
    fileName: imageUrl,
    fileType: 'product-photos',
    identifiers: {
      productId: +productId,
      sku,
    },
  });

  const [priority, setPriority] = useState<StockPriority>(
    highPriority ? 'High Priority' : 'Default',
  );

  const partsCount = parts?.length || 0;
  const completedPartsCount = useMemo(() => {
    return (
      parts?.filter((part: Part) => {
        return part.status === 'Completed';
      }).length || 0
    );
  }, [parts]);

  const updatePriority = async () => {
    setEditingPriority(false);

    try {
      showLoading();

      await stocksApi.updatePriority({
        priority,
        stockId,
      });

      refreshStock();
    } catch (error) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to fetch stock details.',
      });
    } finally {
      hideLoading();
    }
  };

  const isAccessory = category === productCategories.accessory;

  const stockColorCode =
    stock.color === noneOption.code ? unpaintedColor.code : stock.color;

  return (
    <Grid
      alignContent="center"
      alignItems="center"
      columns={{ lg: '12.6rem 1fr', sm: '1fr' }}
      marginTop="4x"
      rowGap="3x"
    >
      <Cell hidden={{ lg: true, md: false }} width={{ lg: 2, sm: 1 }}>
        <Typography text={name} variant="h3" />
      </Cell>

      <Thumbnail source="dynamic" src={fileUrl} />

      <Cell gap="3x">
        <Typography hidden={{ md: true }} text={name} variant="h3" />

        <Grid
          columns={{
            lg: 'repeat(11, max-content)',
            md: 'repeat(7, max-content)',
            sm: 'repeat(5, max-content)',
          }}
          gap="3x"
        >
          <Stack gap="x">
            <Typography text="Color" textStyle="bold" />

            <Flex alignItems="center" gap="0.6rem">
              <ColorSwatchAndLabel
                colorCode={stockColorCode}
                hideUnpaintedIcon
                labelColor={
                  stockColorCode === unpaintedColor.code ? 't2' : 't1'
                }
              />
            </Flex>
          </Stack>

          <Cell>
            <Divider borderLocation="left" height="100%" />
          </Cell>

          <Stack gap="x">
            <Typography text="Variant" textStyle="bold" />
            <Conditional
              condition={!!variants.length}
              Fallback={<NotApplicableText />}
            >
              <Stack gap=".5x">
                {variants?.map(({ label, name }) => {
                  const text = `${label}: ${name}`;

                  return <Typography key={text} text={text} />;
                })}
              </Stack>
            </Conditional>
          </Stack>

          <Cell>
            <Divider borderLocation="left" height="100%" />
          </Cell>

          <Stack gap="x">
            <Typography text="Status" textStyle="bold" />
            <PartStatusBadge status={status} />
          </Stack>

          <Cell hidden={{ sm: true }}>
            <Divider borderLocation="left" height="100%" />
          </Cell>

          <Stack gap="x">
            <Typography text="Priority Level" textStyle="bold" />

            <Flex alignItems="center" gap="0.6rem">
              <Conditional
                condition={editingPriority}
                Fallback={
                  <>
                    <Typography
                      color={priority === 'Default' ? 't2' : 'alert'}
                      text={priority}
                    />
                    <Typography
                      color="link"
                      decoration="underline"
                      text="Edit"
                      onClick={() => {
                        return setEditingPriority(true);
                      }}
                    />
                  </>
                }
              >
                <Dropdown
                  items={stockPrioritiesOptions}
                  value={priority}
                  onSelect={(item) => {
                    return setPriority(item.label);
                  }}
                />

                <Typography
                  color="link"
                  decoration="underline"
                  text="Update"
                  onClick={updatePriority}
                />
              </Conditional>
            </Flex>
          </Stack>

          <Conditional condition={!isAccessory}>
            <Cell hidden={{ md: true, sm: false }}>
              <Divider borderLocation="left" height="100%" />
            </Cell>

            <Stack gap="x">
              <Typography text="Part Completion" textStyle="bold" />
              <Grid columns="10rem max-content" gap="0.6rem">
                <LinearProgress
                  current={completedPartsCount}
                  total={partsCount}
                />
                <Typography text={`${completedPartsCount}/${partsCount}`} />
              </Grid>
            </Stack>
          </Conditional>
        </Grid>
      </Cell>
    </Grid>
  );
};
