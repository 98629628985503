import { Cell, Dropdown, Toggle } from 'gantri-components';
import { FC } from 'react';
import { useRecoilState } from 'recoil';
import {
  FilterGrid,
  FilterRow,
} from '../../../../components/common/filter-grid';
import { FilterResetLabel } from '../../../../components/common/filter-reset-label';
import { convertStringArrayToValueLabelArray } from '../../../../helpers/formatter';
import { vendorsPageAtoms } from './vendors-filters.atoms';

export const VendorsFilters: FC<{ inventoryTypes: string[] }> = (props) => {
  const { inventoryTypes } = props;

  const [hasDelayedShipping, setHasDelayedShipping] = useRecoilState(
    vendorsPageAtoms.filters.hasDelayedShipping,
  );

  const [inventoryType, setInventoryType] = useRecoilState(
    vendorsPageAtoms.filters.inventoryType,
  );
  const [isActive, setIsActive] = useRecoilState(
    vendorsPageAtoms.filters.isActive,
  );

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={vendorsPageAtoms.filters.inventoryType}
          default={vendorsPageAtoms.defaults.filters.inventoryType}
          text="Inventory type"
        />
        <Dropdown
          items={convertStringArrayToValueLabelArray(inventoryTypes)}
          placeholder="Select Type"
          value={inventoryType}
          onSelect={(item) => {
            return setInventoryType(item.value);
          }}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={vendorsPageAtoms.filters.isActive}
          default={vendorsPageAtoms.defaults.filters.isActive}
          text="Active status only"
        />
        <Cell justifyContent="start">
          <Toggle value={isActive} onSelected={setIsActive} />
        </Cell>
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={vendorsPageAtoms.filters.hasDelayedShipping}
          default={vendorsPageAtoms.defaults.filters.hasDelayedShipping}
          text="Has delayed shipping"
        />
        <Cell justifyContent="start">
          <Toggle
            value={hasDelayedShipping}
            onSelected={setHasDelayedShipping}
          />
        </Cell>
      </FilterRow>
    </FilterGrid>
  );
};
