import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { getPartReleaseRateCount } from './get-part-release-rate-count';
import {
  GetPartReleaseRateCountArgsDef,
  GetPartReleaseRateCountResponseDef,
} from './get-part-release-rate-count.types';

const getPartReleaseRateCountQueriesObj = getGenericQueryObj({
  groupName: 'part-release-rate-count',
  query: getPartReleaseRateCount,
});

export const useGetPartReleaseRateCount = <
  TransformedData = GetPartReleaseRateCountResponseDef,
>(
  props?: GenericFetchProps<
    GetPartReleaseRateCountArgsDef,
    GetPartReleaseRateCountResponseDef,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch part release rate count.',
    queryObj: getPartReleaseRateCountQueriesObj,
    ...props,
  });
};

export const useInvalidateGetPartReleaseRateCountCache = () => {
  const invalidateGetPartReleaseRateCountCache = useGetInvalidateQueryCache(
    getPartReleaseRateCountQueriesObj,
  );

  return { invalidateGetPartReleaseRateCountCache };
};
