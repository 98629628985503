import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { GetAdminResponse } from './get-admin.types';
import { getAdminUser } from './get-admin';

const getAdminUserQueriesObj = getGenericQueryObj({
  groupName: 'get-admin-user',
  query: getAdminUser,
});

export const useGetAdminUser = <TransformedData = GetAdminResponse>(
  props?: GenericFetchProps<never, GetAdminResponse, TransformedData>,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch admin user.',
    queryObj: getAdminUserQueriesObj,
    ...props,
  });
};

export const useInvalidateGetAdminUserCache = () => {
  const invalidateGetAdminUserCache = useGetInvalidateQueryCache(
    getAdminUserQueriesObj,
  );

  return { invalidateGetAdminUserCache };
};
