import {
  getColorsByProduct,
  Conditional,
  Cell,
  ProductColorDetail,
} from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import { ColorSwatchAndLabel } from '../../../../../../../../../../../../components/common/color-swatch-and-label';
import { ColorSwatchDropdown } from '../../../../../../../../../../../../components/common/color-swatch-dropdown';
import { ColorNameAndSwatchCellProps } from './color-name-and-swatch-cell.types';
import { filterSkusByColor } from '../../helpers/filter-skus-by-color';
import { productInfoTabAtoms } from '../../../../../../../../info.atoms';

export const ColorNameAndSwatchCell = (props: ColorNameAndSwatchCellProps) => {
  const { colorCode, isNew } = props;

  const setMarketplaceColors = useSetRecoilState(
    productInfoTabAtoms.marketplaceColors,
  );

  const product = useRecoilValue(productAtoms.product);

  const colorItems = getColorsByProduct({
    allowTradeColors: false,
    isPainted: product.isPainted,
    productId: product.id,
  }).filter((item) => {
    const isAlreadySelected = product.colors.some((color) => {
      return color.code === item.code;
    });

    return !isAlreadySelected;
  });

  const onSelect: (item: ProductColorDetail) => void = (item) => {
    setMarketplaceColors((colors) => {
      return colors.map((color) => {
        if (color?.isNew) {
          return {
            ...color,
            code: item?.code,
            defaultSku: filterSkusByColor({
              colorCode: item?.code,
              skus: product.skus,
            })[0],
            name: item?.shortColorName,
          };
        }

        return color;
      });
    });
  };

  return (
    <Conditional
      condition={!!isNew}
      Fallback={<ColorSwatchAndLabel colorCode={colorCode} />}
    >
      <Cell justifyItems="start">
        <ColorSwatchDropdown
          dropdownPopupWidth="16.6rem"
          idProperty="code"
          items={colorItems}
          labelProperty="shortColorName"
          placeholder="Select color"
          value={colorCode}
          width="15rem"
          onSelect={onSelect}
        />
      </Cell>
    </Conditional>
  );
};
