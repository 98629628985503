import { useState } from 'react';
import { Button, Dropdown, Modal } from 'gantri-components';
import { MachineType } from '../../../../api/machines/machines.types';
import { machineTypeOptions } from '../../machine.constants';
import { useBulkUpdateMachines } from '../../../../api/machines/routes';
import { UpdateMachineTypeModalProps } from './update-machine-type-modal.types';
import { useConfirmMachineTypeChangeModal } from '../../../machine/hooks/use-confirm-machine-type-change-modal';

export const UpdateMachineTypeModal = (props: UpdateMachineTypeModalProps) => {
  const { machinesToUpdate, onClose, onSuccess } = props;

  const [machineTypeSelected, setMachineTypeSelected] = useState<MachineType>();

  const { isLoading, onBulkUpdateMachines } = useBulkUpdateMachines({
    onSuccess,
  });

  const onConfirm = async () => {
    await onBulkUpdateMachines({
      action: 'Update type',
      ids: machinesToUpdate.map(({ id }) => {
        return id;
      }),
      type: machineTypeSelected,
    });
    onClose();
  };

  const { handleConfirmMachinesUpdate } = useConfirmMachineTypeChangeModal();

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            processing={isLoading}
            size="large"
            text="Yes"
            onClick={async () => {
              await handleConfirmMachinesUpdate({
                machines: machinesToUpdate.map((machine) => {
                  return { ...machine, type: machineTypeSelected };
                }),
                onConfirm,
              });
            }}
          />
        </>
      }
      header="Update Type"
      width={{ lg: '43rem', md: '100%' }}
      onClose={onClose}
    >
      <Dropdown
        items={machineTypeOptions}
        labelText="Update Machine Type to"
        placeholder="Select type"
        width="100%"
        onSelect={(option) => {
          return setMachineTypeSelected(option.value);
        }}
      />
    </Modal>
  );
};
