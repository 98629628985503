import { Button } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { jobStatuses } from '../../../../../../../../../constants/options';
import { useNotification } from '../../../../../../../../../hooks/useNotification';
import { ConfirmBeginFooterProps } from './confirm-begin-footer.types';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { finishingQcModalAtoms } from '../../../complete-finishing-qa-modal.atoms';
import { useStartJob } from '../../../../../../../../../api/jobs/routes';

export const ConfirmBeginFooter = (props: ConfirmBeginFooterProps) => {
  const { handleCloseModal, job } = props;

  const setStep = useSetRecoilState(finishingQcModalAtoms.step);
  const setUpdateOnClose = useSetRecoilState(
    finishingQcModalAtoms.updateOnClose,
  );
  const { notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const { onStartJob } = useStartJob({
    onSuccess: async () => {
      setUpdateOnClose(true);
    },
  });

  const handleStartJob = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        const isReady = job.status === jobStatuses.ready;

        if (isReady) {
          await onStartJob({ jobId: job.id, machineId: job.machine?.id });
        }

        setStep('CHECKLIST');
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to start job.',
        });
      }
    });
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        processing={processing}
        size={fullScreenModalFooterButtonSize}
        text="Yes"
        onClick={handleStartJob}
      />
    </>
  );
};
