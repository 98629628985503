import { Conditional, Flex, Grid, Stack, Typography } from 'gantri-components';
import { jobSteps, jobTypeOptions } from '../../../../constants/options';
import { YieldDetails } from './components/yield-details';
import { getYieldDetails } from './helpers/get-yield-details';
import { getDaysSince } from './helpers/get-days-since';
import { WarningIcon } from './components/warning-icon';
import { NotApplicableText } from '../../../../components/common/not-applicable-text';
import { getIsInProgressPart } from '../part-jobs/helpers/get-is-in-progress-part';
import { PartAttentionProps } from './part-attention.types';
import { SectionTitle } from '../../../../components/layout/section-title';

export const PartAttention = (props: PartAttentionProps) => {
  const { part } = props;
  const {
    completedAt,
    createdAt,
    finishQCYield,
    lastActionAt,
    printQCYield,
    printYield,
    status,
  } = part;

  const isInProgressPart = getIsInProgressPart(status);
  const daysSinceLastAction = getDaysSince(lastActionAt, completedAt);
  const daysPartInProgress = getDaysSince(createdAt, completedAt);

  const { reasons: printYieldReasons } = getYieldDetails({
    part,
    step: jobSteps.print,
    type: jobTypeOptions.print,
  });

  const { reasons: printQCYieldReasons } = getYieldDetails({
    part,
    step: jobSteps.print,
    type: jobTypeOptions.qc,
  });

  const { reasons: finishQCYieldReasons } = getYieldDetails({
    part,
    step: jobSteps.finish,
    type: jobTypeOptions.qc,
  });

  const getDaysText = (days: number) => {
    return `${days} day${days !== 1 ? 's' : ''}`;
  };

  return (
    <Stack gap="2x">
      <SectionTitle text="Attention" />
      <Grid
        alignItems="baseline"
        columns={{ lg: '18rem 1fr', sm: 'repeat(2, 1fr)' }}
        gap="1.2rem"
      >
        <Typography text="Days since last action" textStyle="bold" />
        <Conditional
          condition={isInProgressPart && daysSinceLastAction >= 0}
          Fallback={<NotApplicableText />}
        >
          <Flex alignItems="center" gap="0.8rem">
            <Conditional condition={daysSinceLastAction > 10}>
              <WarningIcon />
            </Conditional>
            <Typography text={getDaysText(daysSinceLastAction)} />
          </Flex>
        </Conditional>

        <Typography text="Days since part created" textStyle="bold" />
        <Conditional
          condition={daysPartInProgress >= 0}
          Fallback={<NotApplicableText />}
        >
          <Flex alignItems="center" gap="0.8rem">
            <Conditional
              condition={isInProgressPart && daysPartInProgress > 30}
            >
              <WarningIcon />
            </Conditional>
            <Typography text={getDaysText(daysPartInProgress)} />
          </Flex>
        </Conditional>

        <Typography text="Print yield" textStyle="bold" />
        <YieldDetails
          isInProgressPart={isInProgressPart}
          reasons={printYieldReasons}
          value={printYield}
        />

        <Typography text="Print QC yield" textStyle="bold" />
        <YieldDetails
          isInProgressPart={isInProgressPart}
          reasons={printQCYieldReasons}
          value={printQCYield}
        />

        <Typography text="Finish QC yield" textStyle="bold" />
        <YieldDetails
          isInProgressPart={isInProgressPart}
          reasons={finishQCYieldReasons}
          value={finishQCYield}
        />
      </Grid>
    </Stack>
  );
};
