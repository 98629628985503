import { useFormikContext } from 'formik';
import { Button } from 'gantri-components';
import { updatePartReleaseRateModalSteps } from '../../../update-part-release-rate-modal.constants';
import { UpdatePartReleaseRateModalSchemaDef } from '../../../update-part-release-rate-modal.types';
import { PartReleaseRateModalUpdateFooterPropsDef } from './part-release-modal-update-footer.types';

export const PartReleaseRateModalUpdateFooter = (
  props: PartReleaseRateModalUpdateFooterPropsDef,
) => {
  const { onClose } = props;

  const { isValid, setFieldValue } =
    useFormikContext<UpdatePartReleaseRateModalSchemaDef>();

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={onClose}
      />
      <Button
        disabled={!isValid}
        size="large"
        text="Next"
        onClick={async () => {
          await setFieldValue(
            'step',
            updatePartReleaseRateModalSteps.confirmUpdate,
          );
        }}
      />
    </>
  );
};
