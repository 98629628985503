import { Grid, Stack } from 'gantri-components';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import {
  productJobsTabAtoms,
  stockJobsSections,
} from '../../../../product-jobs.atoms';
import { PartJobBlocksInstructions } from '../../../parts-section/components/part-job-blocks/components/part-job-blocks-instructions';
import { productAtoms } from '../../../../../../product.atoms';
import { useInvalidateFetchProductCache } from '../../../../../../../../api/products/routes';
import { StockJobsHeader } from '../stock-jobs-header';
import {
  getStockJobsInstructionsInitialValues,
  StockJobsInstructionsFormData,
  stockJobsInstructionsFormikSchema,
} from './stock-jobs-instructions.schema';
import { useUpdateProductJobBlocks } from '../../../../../../../../api/products/routes/update-product-job-blocks';
import { jobBlockTypes } from '../../../../../../../../api/products/routes/update-product-job-blocks/update-product-job-blocks.types';
import { putInstructionsInOrder } from '../../../parts-section/components/part-job-blocks/components/part-job-blocks-instructions/helpers/put-instructions-in-order';

export const StockJobsInstructions = () => {
  const product = useRecoilValue(productAtoms.product);
  const isAccessory = useRecoilValue(productAtoms.isAccessory);
  const isEditingStockJobsSection = useRecoilValue(
    productJobsTabAtoms.isEditingStockJobsSection,
  );
  const resetIsEditingStockJobsSection = useResetRecoilState(
    productJobsTabAtoms.isEditingStockJobsSection,
  );

  const stockBlock = product?.stockBlock;

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();

  const { onUpdateProductJobBlocks } = useUpdateProductJobBlocks({
    onSuccess: async () => {
      await invalidateFetchProductCache();
      resetIsEditingStockJobsSection();
    },
  });

  const onSubmit = async (values: StockJobsInstructionsFormData) => {
    await onUpdateProductJobBlocks({
      id: stockBlock.id,
      jobBlock: {
        stockBlock: {
          ...stockBlock,
          ...values,
          instructions: putInstructionsInOrder(values, { stripLabels: true }),
        },
        type: jobBlockTypes.stock,
      },
      variantIds: product.variants.map(({ id }) => {
        return id;
      }),
    });
  };

  const initialValues = useMemo(() => {
    return getStockJobsInstructionsInitialValues({
      isAccessory,
      stockJobsBlock: stockBlock,
    });
  }, [stockBlock]);

  const sectionId = stockJobsSections.instructions;
  const isEditing = isEditingStockJobsSection === sectionId;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange
      validateOnMount
      validationSchema={stockJobsInstructionsFormikSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        return (
          <Form>
            <Stack gap="x">
              <StockJobsHeader
                sectionId={sectionId}
                text="Stock Jobs Instructions"
              />
              <Grid columns="56.8rem" gap="x" paddingBottom="3x">
                <PartJobBlocksInstructions
                  boldLabels
                  instructions={values.instructions}
                  instructionsJobMap={values.instructionsJobMap}
                  isEditing={isEditing}
                  schemaId="instructions"
                />
              </Grid>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};
