import {
  Button,
  Cell,
  FormikInput,
  Grid,
  Modal,
  Stack,
  TextArea,
  Typography,
  FileUploader,
  HandleUploadsComplete,
} from 'gantri-components';
import * as React from 'react';
import { useRef } from 'react';
import { Form, Formik } from 'formik';
import { FormikProps } from 'formik/dist/types';
import { inventoryPurchasesApi } from '../../../../../../../../../../../../api/inventory-purchases';
import { Label } from '../../../../../../../../../../../../components/label';
import { formatDate } from '../../../../../../../../../../../../helpers/formatter';
import { useNotification } from '../../../../../../../../../../../../hooks/useNotification';
import {
  ConfirmDeliveryFormDataDef,
  ConfirmDeliveryModalProps,
} from './confirm-delivery-modal.types';
import { confirmDeliveryFormSchema } from './confirm-delivery-form.schema';
import { convertFormDataToShipmentData } from './confirm-delivery-adapter';
import DatePicker from '../../../../../../../../../../../../components/common/date-picker';
import { useFirebaseFileUploader } from '../../../../../../../../../../../../hooks/use-firebase-file-uploader';
import { ShipmentPhotoItem } from './components/shipment-photo-item';

export const ConfirmDeliveryModal = (props: ConfirmDeliveryModalProps) => {
  const {
    editedInventoryPurchase,
    editedShipment,
    onClose,
    onUpdate,
    setEditedShipment,
    shipmentNumber,
  } = props;

  const formRef: React.Ref<FormikProps<ConfirmDeliveryFormDataDef>> = useRef();

  const { hideLoading, notify, notifyAxiosError, showLoading } =
    useNotification();

  const onCancel = () => {
    onUpdateShipment();
    onClose();
  };

  const onUpdateShipment = () => {
    const { values } = formRef.current;

    setEditedShipment((prev) => {
      return {
        ...prev,
        ...convertFormDataToShipmentData(values),
      };
    });

    onUpdate();
  };

  const onSubmit = async (values: ConfirmDeliveryFormDataDef) => {
    try {
      showLoading();

      const { data } =
        await inventoryPurchasesApi.updateInventoryPurchaseShipment({
          id: editedShipment.id,
          ...convertFormDataToShipmentData(values),
        });

      onUpdate(data.purchase);

      notify(
        `Shipment #${shipmentNumber} delivery confirmed, ${values.quantity} units have been added to the "${editedInventoryPurchase.inventoryName}" inventory quantity.`,
      );

      onClose();
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to update shipment.',
      });
    } finally {
      hideLoading();
    }
  };

  const handleUploadsComplete: HandleUploadsComplete = async (
    uploadedFiles,
  ) => {
    await formRef.current.setFieldValue('photos', [
      ...(formRef.current.values.photos ?? []),
      ...uploadedFiles.map(({ fileUrl }) => {
        return fileUrl;
      }),
    ]);
  };

  const thumbnailSize = '10rem';
  const uploadPath = `inventory-purchases/${editedInventoryPurchase.id}/shipment/${editedShipment.id}`;

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl: null,
    handleUploadsComplete,
    onFileDelete: null,
    path: uploadPath,
  });

  return (
    <Formik
      initialValues={{ ...editedShipment }}
      innerRef={formRef}
      validateOnChange
      validateOnMount
      validationSchema={confirmDeliveryFormSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, setFieldValue, values }) => {
        return (
          <Form>
            <Modal
              detailsPanel={
                <Grid
                  alignItems={{ md: 'baseline' }}
                  autoRows="max-content"
                  columns={1}
                  rowGap="1.6rem"
                >
                  <Typography text="Shipment details" variant="h4" />
                  <Cell rowGap=".6rem">
                    <Typography marginTop="s2" text="Name" textStyle="bold" />
                    <Typography text={`Shipment #${shipmentNumber}`} />
                  </Cell>
                  <Cell rowGap=".6rem">
                    <Typography text="Inventory Name" textStyle="bold" />
                    <Typography text={editedInventoryPurchase.inventoryName} />
                  </Cell>
                </Grid>
              }
              footer={
                <>
                  <Button
                    size="large"
                    text="Cancel"
                    variant="secondary"
                    onClick={onCancel}
                  />
                  <Button
                    disabled={!isValid}
                    size="large"
                    text="Confirm"
                    type="submit"
                  />
                </>
              }
              header="Confirm Delivery"
              width={{ lg: '74rem', md: '100%' }}
              onClose={onCancel}
            >
              <Stack gap="s1">
                <Typography
                  text="Confirm the quantity you want to add to the Inventory and add details of any damage or quality issues observed:"
                  textStyle="bold"
                />

                <Grid rowGap=".6rem">
                  <Typography text="Expected Quantity" textStyle="bold" />
                  <Typography
                    text={`${editedShipment.expectedQuantity} units`}
                  />
                </Grid>

                <FormikInput
                  ariaLabel="quantity"
                  labelText="Shipment Quantity"
                  name="quantity"
                  placeholder="Enter quantity in shipment"
                  required
                  rightIcon={<Typography color="t2" text="unit" />}
                  type="number"
                />

                <Cell>
                  <Label paddingBottom=".5x" text="Discarded Quantity" />
                  <Typography
                    marginBottom=".6rem"
                    text="Quantity discarded due to quality issues or damage."
                  />
                  <FormikInput
                    ariaLabel="discardedQuantity"
                    name="discardedQuantity"
                    placeholder="Enter quantity discarded"
                    rightIcon={<Typography color="t2" text="unit" />}
                    type="number"
                  />
                </Cell>

                <Cell>
                  <Typography text="Delivery Date" textStyle="bold" />
                  <FormikInput
                    Field={
                      <DatePicker
                        autoWidth
                        disabled
                        initialValue={{ value: formatDate(Date.now()) }}
                        onDateChanged={async ({ formattedValue }) => {
                          await setFieldValue('deliveredAt', formattedValue);
                        }}
                      />
                    }
                    name="deliveredAt"
                  />
                </Cell>

                <Cell>
                  <Label paddingBottom=".5x" text="Add Photos" />
                  <Grid columns="repeat(4, max-content)" gap="1.6rem">
                    {values.photos.map((fileUrl) => {
                      return (
                        <ShipmentPhotoItem
                          key={fileUrl}
                          fileUrl={fileUrl}
                          handleUploadsComplete={handleUploadsComplete}
                          thumbnailSize={thumbnailSize}
                          uploadPath={uploadPath}
                        />
                      );
                    })}
                    <Cell>
                      <FileUploader
                        {...fileUploaderProps}
                        fileName={null}
                        fileUrl={null}
                        isUploaderOnly
                        maxUploadsAllowed={5}
                        thumbnailSize={thumbnailSize}
                        variant="thumbnail"
                      />
                    </Cell>
                  </Grid>
                </Cell>

                <FormikInput
                  Field={<TextArea debounce={150} minRows={3} />}
                  labelText="Notes"
                  name="notes"
                  placeholder="Enter a note"
                  required={Number(values.discardedQuantity) > 0}
                />
              </Stack>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
