import { useState } from 'react';
import { sortBy } from 'lodash';
import { productFileTypesMaxQty } from '../../../../../../../sku-level-assets/components/bulk-upload-modal/bulk-upload-modal.constants';
import { FormattedSkuLevelAssetData } from '../../../../../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';
import { ProductAssetReportDef } from './use-handle-create-product-assets-review-report.types';

export const useCreateProductAssetsReviewReport = () => {
  const [report, setReport] = useState<ProductAssetReportDef>({
    noDimmingImages: false,
    noScaleImage: false,
    noUsdzFile: false,
    skusMissingOptionalAssets: [],
    skusMissingProductPhotos: [],
  });

  const handleCreateAndSetReport = (assets: FormattedSkuLevelAssetData[]) => {
    const noScaleImage = assets.every(({ scalePhoto }) => {
      return !scalePhoto;
    });
    const noDimmingImages = assets.every(
      ({ dimmingPhotoDark, dimmingPhotoLight }) => {
        return !dimmingPhotoDark && !dimmingPhotoLight;
      },
    );
    const noUsdzFile = assets.every(({ usdz }) => {
      return !usdz?.fileUrl;
    });
    const skusMissingProductPhotos = sortBy(
      assets
        .filter(({ whiteBackgroundPhotos }) => {
          return !whiteBackgroundPhotos?.length;
        })
        .map(({ sku }) => {
          return { sku };
        }),
      ['sku'],
    );
    const skusMissingOptionalAssets = sortBy(
      assets
        .filter(
          ({
            dimmingPhotoDark,
            dimmingPhotoLight,
            scalePhoto,
            usdz,
            whiteBackgroundPhotos,
          }) => {
            return (
              !dimmingPhotoDark ||
              !dimmingPhotoLight ||
              whiteBackgroundPhotos.length <
                productFileTypesMaxQty.whiteBackgroundPhotos ||
              !scalePhoto ||
              !usdz?.fileUrl
            );
          },
        )
        .map(({ sku }) => {
          return { sku };
        }),
      ['sku'],
    );

    setReport({
      noDimmingImages,
      noScaleImage,
      noUsdzFile,
      skusMissingOptionalAssets,
      skusMissingProductPhotos,
    });
  };

  return { handleCreateAndSetReport, report };
};
