import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  UpdateProductAssetsArgs,
  UpdateProductAssetsResponse,
} from './update-product-assets.types';

export const updateProductAssets = ({
  productId,
  ...body
}: UpdateProductAssetsArgs) => {
  return axios
    .put<UpdateProductAssetsResponse>(
      `${getCoreApiUrl('products')}/${productId}/update-assets`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
