import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { GetOverviewArgs, GetOverviewResponseDef } from './get-overview.types';
import { getMachineOverview } from './get-overview';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';

const getMachineOverviewQueriesObj = getGenericQueryObj({
  groupName: 'machine-overview',
  query: getMachineOverview,
});

export const useGetMachineOverviewQuery = <
  TransformedData = GetOverviewResponseDef,
>(
  props?: GenericFetchProps<
    GetOverviewArgs,
    GetOverviewResponseDef,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch machine overview.',
    queryObj: getMachineOverviewQueriesObj,
    ...props,
  });
};

export const useInvalidateGetMachineOverviewCache = () => {
  const invalidateMachineOverviewCache = useGetInvalidateQueryCache(
    getMachineOverviewQueriesObj,
  );

  return { invalidateMachineOverviewCache };
};
