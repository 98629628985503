import { activeProductColorCodes, productColorsMap } from 'gantri-components';
import { MachineIssueDifficulty } from '../../api/jobs/jobs.types';
import {
  DesignsFilterStatus,
  DesignsFilterStep,
} from '../../components/dropdowns/designs-filter/designs-filter.types';
import {
  convertStringArrayToValueLabelArray,
  formatAsLocaleNumber,
} from '../../helpers/formatter';
import { unpaintedColor } from '../colors';
import { MachineRepairType, ProductColorOption } from './options.types';

export const jobStatuses = {
  cancelled: 'Cancelled',
  completed: 'Completed',
  failed: 'Failed',
  inProgress: 'In progress',
  ready: 'Ready',
  rejected: 'Rejected',
  waiting: 'Waiting',
} as const;

export type JobStatus = typeof jobStatuses[keyof typeof jobStatuses];

export const completedJobStatuses = [
  jobStatuses.cancelled,
  jobStatuses.completed,
  jobStatuses.failed,
];

export const jobsStatusesWithNoActions = [
  jobStatuses.waiting,
  ...completedJobStatuses,
];

export const jobSteps = {
  assemble: 'Assemble',
  finish: 'Finish',
  pack: 'Pack',
  print: 'Print',
  qa: 'QA',
  technician: 'Technician',
} as const;

export type JobStep = typeof jobSteps[keyof typeof jobSteps];

export const jobActions = {
  cancel: 'cancel',
  complete: 'complete',
  duplicate: 'duplicate',
  edit: 'edit',
  fail: 'fail',
  start: 'start',
} as const;

export const partStatuses = {
  buffered: 'Buffered',
  cancelled: 'Cancelled',
  completed: 'Completed',
  delayedStart: 'Delayed Start',
  discarded: 'Discarded',
  failed: 'Failed',
  finishing: 'Finishing',
  lost: 'Lost',
  printing: 'Printing',
  waiting: 'Waiting',
} as const;

export type PartStatus = typeof partStatuses[keyof typeof partStatuses];

export const stockStatuses = {
  assembling: 'Assembling',
  cancelled: 'Cancelled',
  completed: 'Completed',
  /**
   * This status is not a stock status directly, but a stock association status.
   * It is used when a shipment is delivered.
   * Stock association is backend only, used to create association between stocks and orders.
   */
  delivered: 'Delivered',
  discarded: 'Discarded',
  finishing: 'Finishing',
  /**
   * This status is not a stock status directly, but a stock association status.
   * It is used when creating an order and the SKU has stock available in either status Completed or Restocked.
   * Stock association is backend only, used to create association between stocks and orders.
   */
  inStock: 'In stock',
  packing: 'Packing',
  printing: 'Printing',
  qa: 'QA',
  refunded: 'Refunded',
  restocked: 'Restocked',
  /**
   * This status is not a stock status directly, but a stock association status.
   * It is used when a stock has been marked for return but has not yet been received.
   * Stock association is backend only, used to create association between stocks and orders.
   */
  returnInProgress: 'Return in progress',
  /** @deprecated Likely no longer used. */
  started: 'Started',
  waiting: 'Waiting',
} as const;

export type StockStatus = typeof stockStatuses[keyof typeof stockStatuses];

export const stockIsTreatedAsCompleted = (status) => {
  return [
    stockStatuses.cancelled,
    stockStatuses.completed,
    stockStatuses.delivered,
    stockStatuses.discarded,
    stockStatuses.refunded,
    stockStatuses.restocked,
    stockStatuses.returnInProgress,
  ].includes(status);
};

export const productJobTypes = {
  assemble: 'Assemble',
  bondo: 'Bondo',
  glue: 'Glue',
  harvest: 'Harvest',
  mask: 'Mask',
  pack: 'Pack',
  paint: 'Paint',
  prime: 'Prime',
  print: 'Print',
  qc: 'QC',
  sandPainted: 'Sand-Painted',
  sandPrimed: 'Sand-Primed',
  sandRaw: 'Sand-Raw',
  stage: 'Stage',
  transfer: 'Transfer',
  tumbleRaw: 'Tumble-Raw',
} as const;

export type ProductJobType =
  typeof productJobTypes[keyof typeof productJobTypes];

export const nonProductJobTypes = {
  bedTaping: 'Bed Taping',
  handoutsPrinting: 'Handouts Printing',
  heavyMachineryRepair: 'Heavy Machinery Repair',
  inventoryManagement: 'Inventory Management',
  paintBoothCleaning: 'Paint Booth Cleaning',
  paintGunCleaning: 'Paint Gun Cleaning',
  rAndDSupport: 'R&D Support',
  repair: 'Repair',
  sandRoomCleaning: 'Sand Room Cleaning',
  shredding: 'Shredding',
  spoolChanging: 'Spool Changing',
  spoolChangingBulk: 'Spool Changing (Bulk)',
  training: 'Training',
} as const;

export type NonProductJobType =
  typeof nonProductJobTypes[keyof typeof nonProductJobTypes];

/** You likely want `productJobTypes`. */
export const jobTypeOptions = {
  ...productJobTypes,
  ...nonProductJobTypes,
} as const;

export const sandJobTypeOptions = [
  jobTypeOptions.tumbleRaw,
  jobTypeOptions.sandPainted,
  jobTypeOptions.sandPrimed,
  jobTypeOptions.sandRaw,
] as const;

export type JobType = typeof jobTypeOptions[keyof typeof jobTypeOptions];

export const JOB_TYPES = Object.values<JobType>(jobTypeOptions);

export const shipmentStatuses = {
  cancelled: 'Cancelled',
  completed: 'Completed',
  delivered: 'Delivered',
  failure: 'FAILURE',
  inProgress: 'In progress',
  inTransit: 'Transit',
  labelCreated: 'Label created',
  manuallyDelivered: 'Manually delivered',
  manuallyShipped: 'Manually shipped',
  partiallyRefunded: 'Partially refunded',
  preTransit: 'Pre_transit',
  readyToShip: 'Ready to ship',
  refunded: 'Refunded',
  returnInProgress: 'Return in progress',
  returned: 'RETURNED',
  shipped: 'Shipped',
  transit: 'TRANSIT',
  unknown: 'Unknown',
  unpaid: 'Unpaid',
  waiting: 'Waiting',
} as const;

export type ShipmentStatus =
  typeof shipmentStatuses[keyof typeof shipmentStatuses];

export const UPSTrackingUrl = (trackingNumber: string) => {
  return `https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=${trackingNumber?.replace(
    /,\s/g,
    '%20',
  )}`;
};

export const orderActionModalTypes = {
  cancel: 'cancel',
  confirmationEmail: 'resend confirmation email',
  createRefund: 'create refund',
  createReplacement: 'create replacement',
  createReturn: 'create return',
  edit: 'edit',
  reassignStock: 're-assign stock',
};

export const transactionTypesMap = {
  designer: 'Designer',
  marketing: 'Marketing',
  order: 'Order',
  refund: 'Refund',
  replacement: 'Replacement',
  rnd: 'R&D',
  thirdParty: 'Third Party',
  thirdPartyRefund: 'Third Party Refund',
  trade: 'Trade',
  tradeRefund: 'Trade Refund',
  wholesale: 'Wholesale',
  wholesaleRefund: 'Wholesale Refund',
} as const;

export type TransactionType =
  typeof transactionTypesMap[keyof typeof transactionTypesMap];

export const downloadTypes = ['component', 'helper', 'design'] as const;

export const orderStatusesMap = {
  cancelled: 'Cancelled',
  delivered: 'Delivered',
  inProgress: 'In progress',
  partiallyCancelled: 'Partially cancelled',
  // todo added to remove error. Need to verify if this is an option.
  partiallyDelivered: 'Partially delivered',
  partiallyRefunded: 'Partially refunded',
  partiallyShipped: 'Partially shipped',
  processed: 'Processed',
  readyToShip: 'Ready to ship',
  refundInProgress: 'Refund in progress',
  refunded: 'Refunded',
  shipped: 'Shipped',
  unpaid: 'Unpaid',
} as const;

export type OrderStatus =
  typeof orderStatusesMap[keyof typeof orderStatusesMap];

export const orderStatusMapByValue = Object.values(orderStatusesMap).reduce(
  (acc, status) => {
    return { ...acc, [status]: status };
  },
  {},
);

export const confirmationEmailTypes = {
  confirmation: 'confirmation',
  delivered: 'delivered',
  shipped: 'shipped',
  updated: 'updated',
};

// todo - add page names as needed
export const pageSources = {
  machine: 'machine',
};

export const activeProductColorOptions = activeProductColorCodes.map((code) => {
  const productColorOption = {
    code: productColorsMap[code].code,
    color: productColorsMap[code].hexColor,
    name: productColorsMap[code].shortColorName,
  } as const satisfies ProductColorOption;

  return productColorOption;
});

export const productColorOptions = [
  {
    code: unpaintedColor.code,
    color: unpaintedColor.name,
    name: unpaintedColor.name,
  },
  ...activeProductColorOptions,
] satisfies ProductColorOption[];

export const productSizes = {
  cm: 'Compact',
  lg: 'Large',
  md: 'Medium',
  sm: 'Small',
} as const;

export type ProductSizeCode = keyof typeof productSizes;

export type ProductSizeName = typeof productSizes[keyof typeof productSizes];

export const productSizeOptions = [
  {
    code: 'cm',
    name: 'Compact',
  },
  {
    code: 'sm',
    name: 'Small',
  },
  {
    code: 'md',
    name: 'Medium',
  },
  {
    code: 'lg',
    name: 'Large',
  },
] satisfies { code: ProductSizeCode; name: ProductSizeName }[];

export const shipmentDateLabelsMap = {
  deliveredOn: {
    key: 'deliveredOnSec',
    label: 'Delivered on',
  },
  deliveringOn: {
    key: 'deliveryBySec',
    label: 'Delivering on',
  },
  estDeliveryBy: {
    key: 'estDeliveryBySec',
    label: 'Est. delivery by',
  },
  shipBy: {
    key: 'leadTimeSec',
    label: 'Ship by',
  },
  shippedOn: {
    key: 'shippedOnSec',
    label: 'Shipped on',
  },
};

export const giftCodeTypesMap = [
  {
    name: 'Discount',
    value: 'Discount',
  },
  {
    name: 'Discount %',
    value: 'Discount %',
  },
  {
    name: 'Gift',
    value: 'Gift',
  },
];

export const designSteps = {
  concept: 'Concept',
  content: 'Content',
  engineering: 'Engineering',
  model: 'Model',
} satisfies Record<string, DesignsFilterStep>;

export const designStatuses = {
  approved: 'Approved',
  completed: 'Completed',
  failed: 'Failed',
  inProgress: 'In progress',
  submitted: 'Submitted',
} satisfies Record<string, DesignsFilterStatus>;

export const inventoryTypes = {
  hardware: 'Hardware',
  lightingComponent: 'Lighting Component',
  machinePart: 'Machine Part',
  material: 'Material',
  packaging: 'Packaging',
  sundries: 'Sundries',
} as const;

export type InventoryType = typeof inventoryTypes[keyof typeof inventoryTypes];

export const productStatuses = {
  active: 'Active',
  inPreparation: 'In preparation',
  offMarket: 'Off Market',
  ready: 'Ready',
} as const;

export const reasons = [
  'Clog',
  'Layer line',
  'Under extrusion',
  'Over extrusion',
  'Off center',
  'Other',
] as const;

export const machineRepairTypes: MachineRepairType[] = [
  {
    description:
      'Remove nozzle and Pneumatic Fitting, Clear Heat sink, Reseat Nozzle, Clean Fans, Check bowden, loom, and fan screen, Clean',
    type: 'Hot End Rebuild',
  },
  {
    description:
      'Test cables and components, Cable replacement, Cable repair, soldering, heat shrink, fire tape',
    type: 'Cable Repair/Replacement',
  },
  {
    description: 'Test cables and components, replace damaged components',
    type: 'Component Replacement',
  },
  {
    description: 'Extruder rebuild, clean gears, extrusion test',
    type: 'Extruder Maintenance',
  },
  {
    description:
      'Ensure wheels are rolling smoothly on the track, Belt Tension standard, Switch, Couplers, Motors',
    type: 'X/Y Axis Movement Maintenance',
  },
  {
    description: 'Leveling, Lead Screws, Bushings, Linear Guides, Motors',
    type: 'Zed Axis Movement Maintenance',
  },
  {
    description:
      'Any maintenance involving recalibrating, or adding post process upgrades',
    type: 'Calibrations, Firmware',
  },
  {
    description:
      '1) Check, clean, grease lead screws.\n2) Check, clean, oil metal Y and Z rods.\n3) Check and clean fans, Carbon rods. **No lubrication on carbon rods\n4) Check belt tension.\n5) Check extruder gears for dust buildup.\n6) Check and clean silicone sock, replace if necessary.\n7) Clean buildplate\n8) Replace Hot-End.',
    type: 'Maintenance',
  },
  {
    description: 'Flip the plate to side 2 and wipe the plate clean.',
    type: 'Flip Build Plate',
  },
  {
    description:
      '1) Mark the expired build plate plate with an “X” on both sides and then dispose of it in the designated area.\n2) Install a new plate. Make sure the plate is set to side 1.',
    type: 'Replace Build Plate',
  },
  {
    description: '',
    type: 'Other',
  },
];

export const IssuesDifficulties = ['Easy', 'Moderate', 'Hard', 'Multiple'];

export type MachineIssueDifficultyWithMultiple =
  | 'Easy'
  | 'Moderate'
  | 'Hard'
  | 'Multiple';

export interface MachineIssueChecklistItem {
  allowBulkCreation: boolean;
  description: string;
  difficulty: MachineIssueDifficulty;
  notes?: string;
  observation: string;
  thumbnailSrc: string;
  type: string;
}

export const machineIssueOptions: MachineIssueChecklistItem[] = [
  {
    allowBulkCreation: false,
    description:
      'BL touch is bent, broken off or the pin is bent, red light flashing, no light, unresponsive pin',
    difficulty: 'Easy',
    observation: 'Irregular blinking, Wrong or no light',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/bl_touch_damaged_e9wpyu.png',
    type: 'BL Touch Issue',
  },
  {
    allowBulkCreation: false,
    description: 'No filament coming from nozzle',
    difficulty: 'Easy',
    observation:
      'No filament coming from nozzle, No Extrusion, Under/Over Extrusion',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/blow_out_dzqxfh.png',
    type: 'Clog/Extrusion Issue',
  },
  {
    allowBulkCreation: false,
    description: 'No filament coming from nozzle',
    difficulty: 'Easy',
    observation:
      'Broken fans, Heat sink angled, Loose Heatsink/Carriage, Damaged nozzle or heater block',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/extrusion_stopped_flxupo.png',
    type: 'Hot End Issue',
  },
  {
    allowBulkCreation: false,
    description:
      'The Hot-End has issues moving right, left, forward, or backward',
    difficulty: 'Moderate',
    observation:
      "Printer won't home, moves inconsistently, or skip steps in X or Y direction",
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1660338971/static-assets/diagram_base_deformed_2x_htfv8s.png',
    type: 'X/Y Axis Movement Issue',
  },
  {
    allowBulkCreation: false,
    description: 'Build plate has issues traveling Up and Down',
    difficulty: 'Moderate',
    observation: 'Build plate has issues traveling Up and Down',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430192/static-assets/layer_lines_dro38v.png',
    type: 'Zed Axis Issue',
  },
  {
    allowBulkCreation: false,
    description:
      'Heating Error Message Displayed before machine begins printing (during heating up).',
    difficulty: 'Moderate',
    observation: 'Error Message Displayed',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/heating_failed_kclljw.png',
    type: 'Heating Error',
  },
  {
    allowBulkCreation: false,
    description: 'Error Message Displayed',
    difficulty: 'Easy',
    observation: 'Other heating issue',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/heating_failed_kclljw.png',
    type: 'Other Heating Issue',
  },
  {
    allowBulkCreation: false,
    description: 'Error Message Displayed or inaccurate heat reading.',
    difficulty: 'Moderate',
    observation: 'Error Message Displayed',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430147/static-assets/thermister_error_s3kpxi.png',
    type: 'Thermistor Error',
  },
  {
    allowBulkCreation: false,
    description: 'Temperature drifted too far from setpoint',
    difficulty: 'Moderate',
    observation: 'Error Message Displayed',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430147/static-assets/thermal_runaway_p7jpfk.png',
    type: 'Thermal Runaway',
  },
  {
    allowBulkCreation: false,
    description:
      'Parts coming off the machine are heavily over/under extruded (E-step) and/or when parts are over/under sized (XY calibration).',
    difficulty: 'Easy',
    observation:
      'Printing off size or off shape, Old Firmware, Slow Extrusion, Missing Upgrades',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/blow_out_dzqxfh.png',
    type: 'Calibrations/Firmware',
  },
  {
    allowBulkCreation: true,
    description: 'Machine held from production (Admin must resolve)',
    difficulty: 'Hard',
    observation: 'Machine held from production (Admin must resolve)',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1660338971/static-assets/diagram_administrative_b1pv0f.png',
    type: 'Administrative',
  },
  {
    allowBulkCreation: false,
    description: '3 Consecutive Print QC Fails ',
    difficulty: 'Hard',
    observation: 'Auto generated issue after 3 consecutive failed prints',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/do_you_have_the_wrong_print_vmhyq4.png',
    type: 'Consecutive Job Fails',
  },
  {
    allowBulkCreation: false,
    description:
      'Any issue concerning the LCD, Motherboard, Power Supply or other internal components',
    difficulty: 'Hard',
    observation: '',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653325137/static-assets/diagram_cord_issue_2x_z9msyz.png',
    type: 'Control Box',
  },
  {
    allowBulkCreation: true,
    description: 'Offline for scheduled maintenance',
    difficulty: 'Easy',
    observation: '',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1660338971/static-assets/diagram_maintenance_2x_e0mnky.png',
    type: 'Maintenance',
  },
  {
    allowBulkCreation: true,
    description: 'Unknown, further Diagnosis Needed',
    difficulty: 'Hard',
    observation: '',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1652475738/static-assets/diagram_other_2x_z5mxh5.png',
    type: 'Unknown, further diagnosis needed',
  },
];

export const referralCancelReasons = ['Duplicate', 'Other'];

export const locationStatuses = {
  active: 'Active',
  inactive: 'Inactive',
} as const;

export const locationStatusOptions = convertStringArrayToValueLabelArray(
  Object.values(locationStatuses),
);

export type LocationStatus =
  typeof locationStatuses[keyof typeof locationStatuses];

export const inventoryStatuses = {
  active: 'Active',
  inactive: 'Inactive',
} as const;

export type InventoryStatus =
  typeof inventoryStatuses[keyof typeof inventoryStatuses];

/** 100 hour intervals. */
const numIntervals = 75;

export const lifespanOptions: { label: string; value: number }[] = new Array(
  numIntervals,
)
  .fill(null)
  .map((_, index) => {
    const value = (index + 1) * 100;

    return {
      label: formatAsLocaleNumber(value),
      value,
    };
  });

export const usShippingMethods: ShippingMethod[] = [
  { label: 'UPS Ground 3-5 days', provider: 'UPS', shippingType: 'ups_ground' },
  {
    label: 'UPS 3 Day Select',
    provider: 'UPS',
    shippingType: 'ups_3_day_select',
  },
  {
    label: 'UPS Next Day Air 1-day',
    provider: 'UPS',
    shippingType: 'ups_next_day_air',
  },
  {
    label: 'Fedex Ground 7 days',
    provider: 'FedEx',
    shippingType: 'fedex_ground',
  },
  {
    label: 'Fedex Express Saver 3-days',
    provider: 'FedEx',
    shippingType: 'fedex_express_saver',
  },
  {
    label: 'Fedex 2Day A.M. 1-day',
    provider: 'FedEx',
    shippingType: 'fedex_2_day_am',
  },
];

export const defaultUSShippingMethod = usShippingMethods[0];

export const canadianShippingMethods: ShippingMethod[] = [
  {
    label: 'UPS Standard 3-5 days',
    provider: 'UPS',
    shippingType: 'ups_standard',
  },
  {
    label: 'UPS Worldwide Expedited 2-days',
    provider: 'UPS',
    shippingType: 'ups_expedited',
  },
  {
    label: 'UPS Worldwide Express 1-day',
    provider: 'UPS',
    shippingType: 'ups_express',
  },
  {
    label: 'Fedex Ground 7-days',
    provider: 'FedEx',
    shippingType: 'fedex_ground',
  },
  {
    label: 'Fedex International Economy 4-days',
    provider: 'FedEx',
    shippingType: 'fedex_international_economy',
  },
  {
    label: 'Fedex International Priority 2-days',
    provider: 'FedEx',
    shippingType: 'fedex_international_priority',
  },
];

export const defaultCanadianShippingMethod = canadianShippingMethods[0];

export const transformLegacyShippingMethod = (
  method: 'cheapest' | 'fastest' | 'threedays',
  country: string = 'United States',
): ShippingMethod => {
  if (country === 'United States') {
    return transformUsLegacyShippingMethod(method);
  }

  return transformCanadianLegacyShippingMethod(method);
};

export const transformUsLegacyShippingMethod = (
  method: 'cheapest' | 'fastest' | 'threedays',
): ShippingMethod => {
  if (method === 'cheapest') {
    return defaultUSShippingMethod;
  }

  if (method === 'threedays') {
    return usShippingMethods[1];
  }

  return usShippingMethods[2];
};

export const transformCanadianLegacyShippingMethod = (
  method: 'cheapest' | 'fastest' | 'threedays',
): ShippingMethod => {
  if (method === 'cheapest' || method === 'threedays') {
    return defaultCanadianShippingMethod;
  }

  return canadianShippingMethods[2];
};

export type ShippingMethod = {
  label: string;
  provider: 'FedEx' | 'UPS';
  shippingType: string;
};

export const noneOption = {
  code: '0',
  name: 'none',
} as const satisfies { code: string; name: string };

export type NoneOptionCode = typeof noneOption.code;
