import { Stack } from 'gantri-components';
import { StockJobsDurations } from './components/stock-jobs-durations';
import { SimpleErrorBoundary } from '../../../../../../components/simple-error-boundary';
import { StockJobsInstructions } from './components/stock-jobs-instructions';
import { StockJobsAssembleInventory } from './components/stock-jobs-assemble-inventory';
import { StockJobsPackInventory } from './components/stock-jobs-pack-inventory';

export const StockJobsSection = () => {
  return (
    <Stack gap="6x" paddingBottom="6x">
      <SimpleErrorBoundary>
        <StockJobsDurations />
      </SimpleErrorBoundary>

      <SimpleErrorBoundary>
        <StockJobsAssembleInventory />
      </SimpleErrorBoundary>

      <SimpleErrorBoundary>
        <StockJobsPackInventory />
      </SimpleErrorBoundary>

      <SimpleErrorBoundary>
        <StockJobsInstructions />
      </SimpleErrorBoundary>
    </Stack>
  );
};
