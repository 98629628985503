import { Box } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledThumbnailBox = styled(Box)<{
  $isDisabled: boolean;
  $isSelected: boolean;
}>`
  ${({ $isDisabled, $isSelected, theme }) => {
    return css`
      border: 1px solid
        ${$isSelected ? theme.colors.typography.link : theme.colors.dividers.t1};
      background-color: ${theme.colors.surfaces.monochrome.t1Alt};
      user-select: none;
      cursor: ${$isDisabled ? 'not-allowed' : 'pointer'};
    `;
  }}
`;
