export interface CloudinaryTransformations {
  /**
   * Can be set as either:
   * * A string value in the form a:b, where a is the width and b is the height (e.g., 16:9).
   * * A decimal value representing the width divided by the height (e.g., 0.5).
   *
   * https://cloudinary.com/documentation/transformation_reference#ar_aspect_ratio
   */
  aspectRatio?: AspectRatio;
  /**
   * Set as `${width}px_solid_${color}`
   *
   * The `color` can be set as:
   * * A 3- or 4-digit RGB/RGBA hex
   * * An RGB or RGBA hex triplet or quadruplet (6 or 8 digits).
   * * A named color
   *
   * When specifying any RGB or RGBA value, always prefix the color value in the URL with rgb: (without `#`). For example, `5px_solid_rgb:999` or `5px_solid_rgb:FEB61FC2`.
   *
   * When using a named color, specify only the color name. For example 5px_solid_blue.
   *
   * https://cloudinary.com/documentation/transformation_reference#bo_border
   */
  border?: `${number}px_solid_${string}`;
  /**
   * Changes the size of the delivered asset according to the requested width & height dimensions.
   *
   * Depending on the selected <crop mode>, parts of the original asset may be cropped out and/or the asset may be resized (scaled up or down).
   *
   * When using any of the modes that can potentially crop parts of the asset, the selected `gravity` parameter controls which part of the original asset is kept in the resulting delivered file.
   *
   * Required transformations: At least one of the following: `width`, `height`.
   *
   * Optional transformations: `aspectRatio`
   *
   * https://cloudinary.com/documentation/transformation_reference#c_crop_resize
   */
  crop?: Crop;
  /**
   * Delivers the image or video in the specified device pixel ratio.
   *
   * Important When delivering at a DPR value larger than 1, ensure that you also set the desired final display dimensions in your image or video tag. For example, if you set `h_300,dpr_2` in your delivery URL, you should also set `height=300` in your image tag. Otherwise, the image will be delivered at 2.0 x the requested dimensions (a height of 600px in this example).
   *
   * `'auto'` Delivers the image in a resolution that automatically matches the DPR (Device Pixel Ratio) setting of the requesting device, rounded up to the nearest integer. Only works for certain browsers and when Client-Hints are enabled.
   */
  dpr?: number | 'auto';
  /**
   * `attachment` alters the normal behavior of the overall transformation. Instead of delivering an asset in a web page, it causes the transformed media file to be downloaded as an attachment. This flag can be used independently of other transformations.
   *
   * `relative` modifies the way overlay resize parameters are evaluated. This flag can only be used in conjunction with the overlay (l_) parameter.
   *
   * `layer_apply` tells Cloudinary to apply all chained transformations, until a transformation component that includes this flag, on the last added overlay or underlay asset instead of applying them on the base asset. This flag acts as an indicator to close the layer section of the transformation, similar to a closing bracket. Placement definitions for how the layer should be applied, such as gravity and offsets are also defined in the component with this flag.
   *
   * https://cloudinary.com/documentation/image_transformations#transformation_flags
   */
  flags?: ('attachment' | 'layer_apply' | 'relative')[];
  /**
   * Converts (if necessary) and delivers an asset in the specified format regardless of the file extension used in the delivery URL.
   *
   * Default: `'auto'`
   *
   * https://cloudinary.com/documentation/transformation_reference#f_format
   */
  format?: ImageFormat | VideoFormat | RawFormat;
  /**
   * Determines which part of an asset to focus on, and thus which part of the asset to keep, when any part of the asset is cropped.
   *
   * Default: `'center'`
   *
   * https://cloudinary.com/documentation/transformation_reference#g_gravity
   * */
  gravity?: Gravity;
  /**
   * Required transformations: `crop`.
   *
   * Can be set as either:
   * * An integer value to set the height to the given number in pixels (e.g., `150` sets the height to exactly 150 pixels).
   * * A decimal value to set the height to a multiple of the original dimension (e.g., `0.5` sets the height to half the original height).
   *
   * https://cloudinary.com/documentation/transformation_reference#h_height
   */
  height?: number;
  /**
   * Possible values:
   *
   * * `number`: 1-100
   * * `'best'`: Ensures the best visual quality, using a less aggressive algorithm
   * * `'good'`: Ensures optimal visual quality without affecting its perceptual quality
   * * `'eco'`: Ensures a smaller file size using a more aggressive algorithm
   * * `'low'`: Ensures the smallest file size using the most aggressive algorithm
   *
   * Default: `'best'`
   *
   * https://cloudinary.com/documentation/transformation_reference#q_quality
   */
  quality?: Quality;
  /**
   * The radius value to apply to all four corners of the asset.
   *
   * https://cloudinary.com/documentation/transformation_reference#r_round_corners
   */
  round?: number;
  /**
   * Set to `1` to attempt to cache-bust without the version.
   *
   * https://cloudinary.com/documentation/invalidate_cached_media_assets_on_the_cdn
   */
  version?: number;
  /**
   * Required transformations: `crop`.
   *
   * Can be set as either:
   * * An integer value to set the height to the given number in pixels (e.g., `150` sets the height to exactly 150 pixels).
   * * A decimal value to set the height to a multiple of the original dimension (e.g., `0.5` sets the height to half the original width).
   *
   * https://cloudinary.com/documentation/transformation_reference#w_width
   */
  width?: number;
  /**
   * The starting location or offset for custom-coordinates based cropping, overlay placement and certain region related effects.
   * Specify as a number of pixels or as a float value corresponding to a percentage in relation to `1.0`. For example `0.8` is equal to a 80%.
   * Default: `0`.
   *
   * https://cloudinary.com/documentation/transformation_reference#x_y_coordinates
   */
  x?: number;
  /**
   * The starting location or offset for custom-coordinates based cropping, overlay placement and certain region related effects.
   * Specify as a number of pixels or as a float value corresponding to a percentage in relation to `1.0`. For example `0.8` is equal to a 80%.
   * Default: `0`.
   *
   * https://cloudinary.com/documentation/transformation_reference#x_y_coordinates
   */
  y?: number;
  /**
   * * When used with `thumb` crop mode, the detected coordinates are scaled to completely fill the requested dimensions and then cropped as needed.
   * * When used with the `crop` crop mode, the zoom qualifier has an impact only if resize dimensions (height and/or width) are not specified.
   * In this case, the crop dimensions are determined by the detected coordinates and then adjusted based on the requested zoom.
   *
   * The amount of zoom to apply when cropping around the detected coordinates of faces, objects or custom regions.
   * Specify as a float value corresponding to a percentage, where `1.0` instructs to crop to the detected coordinates.
   * Values less than one will result in a crop outside the coordinates (zoom out) and values greater than one will
   * crop closer than the coordinates (zoom in). For example `0.8` represents a zoom amount of 80%.
   *
   * Default: `1.0`.
   *
   * https://cloudinary.com/documentation/transformation_reference#z_zoom
   */
  zoom?: number;
}

/** Aspect ratio format for cloudinary URLs */
export type AspectRatio = `${number}:${number}` | number;

type Crop =
  | 'crop'
  | 'fill'
  | 'fill_pad'
  | 'fit'
  | 'imagga_crop'
  | 'imagga_scale'
  | 'lfill'
  | 'limit'
  | 'lpad'
  | 'mfit'
  | 'mpad'
  | 'pad'
  | 'scale'
  | 'thumb';

export const imageExtensions = ['gif', 'jpeg', 'jpg', 'png', 'webp'] as const;

export type ImageFormat = typeof imageExtensions[number];

export const videoExtensions = [
  '3g2',
  '3gp',
  'avi',
  'flv',
  'm2ts',
  'm3u8',
  'mkv',
  'mov',
  'mp4',
  'mpd',
  'mpeg',
  'mts',
  'mxf',
  'ogv',
  'ts',
  'webm',
  'wmv',
] as const;

export type VideoFormat = typeof videoExtensions[number];

export const nonStandardImgExtensions = [
  'ai',
  'auto',
  'avif',
  'bmp',
  'dwg',
  'eps',
  'eps3',
  'ept',
  'fbx',
  'flif',
  'glb',
  'gltf',
  'hdp',
  'heic',
  'heif',
  'ico',
  'indd',
  'jp2',
  'jpe',
  'jxr',
  'pdf',
  'ply',
  'ps',
  'psd',
  'svg',
  'tga',
  'tif',
  'tiff',
  'wdp',
] as const;

export const rawFileExtensions = [
  ...nonStandardImgExtensions,
  'gcode',
  'obj',
  'step',
  'stl',
  'usdz',
] as const;

export type RawFormat = typeof rawFileExtensions[number];

export type AllFileFormats = ImageFormat | VideoFormat | RawFormat;

type Gravity =
  | 'north_east'
  | 'north'
  | 'north_west'
  | 'west'
  | 'south_west'
  | 'south'
  | 'south_east'
  | 'east'
  | 'center';

type Quality = number | 'best' | 'good' | 'eco' | 'low';
