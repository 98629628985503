import { FC, PropsWithChildren, UIEventHandler, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  StyledCell,
  StyledDesktopSidebarContainer,
  StyledDesktopSidebarHeader,
} from './desktop-sidebar.styles';
import SidebarLinks from '../sidebar-links';
import { AccountDropdown } from '../account-dropdown';
import { DesktopSidebarProps } from './desktop-sidebar.types';
import { HeaderDetails } from './components/header-details';
import { SidebarToggle } from './components/sidebar-toggle';
import { uiAtoms } from '../../../global-atoms/ui';
import { userAtoms } from '../../../global-atoms/users';

export const DesktopSidebar: FC<PropsWithChildren<DesktopSidebarProps>> = (
  props,
) => {
  const { version } = props;

  const { type } = useRecoilValue(userAtoms.user);
  const isSidebarOpen = useRecoilValue(uiAtoms.isSidebarOpen);

  const [headerHasDropShadow, setHeaderHasDropShadow] = useState(false);

  const checkForDropShadow: UIEventHandler<HTMLDivElement> = (event) => {
    const { currentTarget } = event;
    const addDropShadow = currentTarget.scrollTop > 0;

    if (addDropShadow !== headerHasDropShadow) {
      setHeaderHasDropShadow(addDropShadow);
    }
  };

  const headerDataAttrs = {
    'data-desktop-sidebar-header': '',
  };

  return (
    <StyledDesktopSidebarContainer
      isSidebarOpen={isSidebarOpen}
      onScroll={checkForDropShadow}
    >
      {isSidebarOpen ? (
        <StyledDesktopSidebarHeader
          alignContent="center"
          alignItems="center"
          columns="1fr max-content"
          gap="unset"
          hasDropShadow={headerHasDropShadow}
          isSidebarOpen={isSidebarOpen}
          {...headerDataAttrs}
        >
          <HeaderDetails type={type} version={version} />
          <SidebarToggle />
        </StyledDesktopSidebarHeader>
      ) : (
        <StyledDesktopSidebarHeader
          alignContent="center"
          alignItems="center"
          columns={1}
          gap="0.5rem"
          hasDropShadow={headerHasDropShadow}
          justifyContent="center"
          {...headerDataAttrs}
        >
          <StyledCell>
            <SidebarToggle />
          </StyledCell>
          <StyledCell>
            <AccountDropdown />
          </StyledCell>
        </StyledDesktopSidebarHeader>
      )}
      {!!type && <SidebarLinks userType={type} />}
    </StyledDesktopSidebarContainer>
  );
};
