import { Flex } from 'gantri-components';
import styled, { css } from 'styled-components';
import { radioButtonVariants } from './radio-button.constants';
import { RadioButtonVariant } from './radio-button.types';

export const StyledRadioWrapperFlex = styled(Flex)<{
  $buttonVariant: RadioButtonVariant;
  $checked: boolean;
}>`
  ${({ $buttonVariant, $checked, theme }) => {
    return css`
      cursor: pointer;

      ${$buttonVariant === radioButtonVariants.button &&
      css`
        border: 1px solid
          ${$checked ? theme.colors.typography.success : 'transparent'};
        border-radius: 0.4rem;
        background-color: ${theme.colors.surfaces.blue.t1};
        box-shadow: ${$checked && theme.colors.shadow.low};
        padding: 1.6rem;
      `}
    `;
  }}
`;
