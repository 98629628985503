import { ColorDetails, ColorPicker, SearchField } from 'gantri-components';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { stockSummaryPageAtoms } from './stock-summary.atoms';
import { useFetchActiveProductsAndDesignersQuery } from '../../api/products/routes/fetch-active-products-and-designers';
import {
  productCategories,
  productLightCategories,
} from '../../api/products/products.constants';
import { FilterResetLabel } from '../../components/common/filter-reset-label';
import { AtomsCheckboxList } from '../../components/common/atoms-checkbox-list';
import { FilterGrid, FilterRow } from '../../components/common/filter-grid';

export const StockSummaryFilter = () => {
  const [category, setCategory] = useRecoilState(
    stockSummaryPageAtoms.filters.category,
  );
  const [colors, setColors] = useRecoilState(
    stockSummaryPageAtoms.filters.colors,
  );
  const [designerId, setDesignerId] = useRecoilState(
    stockSummaryPageAtoms.filters.designerId,
  );
  const resetDesignerId = useResetRecoilState(
    stockSummaryPageAtoms.filters.designerId,
  );
  const [productId, setProductId] = useRecoilState(
    stockSummaryPageAtoms.filters.productId,
  );
  const resetProductId = useResetRecoilState(
    stockSummaryPageAtoms.filters.productId,
  );

  const handleColorSelect = (selectedColorsDetails: ColorDetails[]) => {
    const newColorCodes = selectedColorsDetails
      ? selectedColorsDetails.map(({ code }) => {
          return code;
        })
      : [];

    setColors(newColorCodes);
  };

  const { data, isLoading } = useFetchActiveProductsAndDesignersQuery();

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={stockSummaryPageAtoms.filters.productId}
          default={stockSummaryPageAtoms.defaults.filters.productId}
          text="Product"
        />
        <SearchField
          disabled={isLoading}
          idProperty="id"
          items={data?.productNames}
          labelProperty="name"
          maxHeight="33rem"
          placeholder="Search products"
          value={productId}
          onSelect={(item) => {
            const productId = item?.id;

            if (productId) {
              setProductId(productId);
            } else {
              resetProductId();
            }
          }}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={stockSummaryPageAtoms.filters.colors}
          default={stockSummaryPageAtoms.defaults.filters.colors}
          text="Colors"
        />
        <ColorPicker value={colors} onValueChange={handleColorSelect} />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={stockSummaryPageAtoms.filters.category}
          default={stockSummaryPageAtoms.defaults.filters.category}
          text="Category"
        />
        <AtomsCheckboxList
          atom={stockSummaryPageAtoms.filters.category}
          items={[
            productCategories.accessory,
            ...Object.values(productLightCategories),
          ]}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={stockSummaryPageAtoms.filters.designerId}
          default={stockSummaryPageAtoms.defaults.filters.designerId}
          text="Designer"
        />
        <SearchField
          disabled={isLoading}
          idProperty="id"
          items={data?.designerNames}
          labelProperty="name"
          maxHeight="33rem"
          placeholder="Search designers"
          value={designerId}
          onSelect={(item) => {
            const designerId = item?.id;

            if (designerId) {
              setDesignerId(designerId);
            } else {
              resetDesignerId();
            }
          }}
        />
      </FilterRow>
    </FilterGrid>
  );
};
