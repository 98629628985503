import { debounce } from 'lodash';
import { Cell, TextField, Box, FileUploader } from 'gantri-components';
import { useFirebaseFileUploader } from '../../../../../../../../hooks/use-firebase-file-uploader';
import { PrototypingItemPropsDef } from './prototyping-item.types';

export const PrototypingItem = (props: PrototypingItemPropsDef) => {
  const {
    engineering,
    index,
    item,
    onPrototypeUploaded,
    prototypeUploadPath,
    recordUpdated,
    thumbnailSize,
    transformFileName,
  } = props;

  const onPrototypeNameTextChange = (index) => {
    return async (value: string) => {
      const images = [...engineering.prototyping.images];

      if (images[index].nameOfPhoto !== value) {
        images[index].nameOfPhoto = value;

        await recordUpdated('prototyping', 'images', images)();
      }
    };
  };

  const getOnPrototypeDelete = (index: number) => {
    return async () => {
      const images = [...engineering.prototyping.images];

      images.splice(index, 1);

      await recordUpdated('prototyping', 'images', images)();
    };
  };

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl: item.fileUrl,
    handleUploadsComplete: onPrototypeUploaded,
    onFileDelete: getOnPrototypeDelete(index),
    path: prototypeUploadPath,
  });

  return (
    <Cell>
      <Box marginBottom="2x">
        <FileUploader
          {...fileUploaderProps}
          fileName={item.fileName}
          thumbnailSize={thumbnailSize}
          transformFileName={transformFileName}
          variant="thumbnail"
        />
      </Box>
      <TextField
        placeholder="Name of photo"
        value={item.nameOfPhoto}
        onTextChange={debounce(onPrototypeNameTextChange(index), 300)}
      />
    </Cell>
  );
};
