import PropTypes from 'prop-types';
import routePaths from '../../../../config/route-paths';
import { StyledAnchor } from '../../styled-anchor';

const TimelineUserInfo = ({ email, firstName, lastName, top, userId }) => {
  const text = firstName
    ? [firstName, lastName].filter(Boolean).join(' ')
    : email || 'User info not available';

  return (
    <StyledAnchor
      display="inline"
      style={{ top }}
      text={text}
      to={`${routePaths.users}/${userId}`}
    />
  );
};

TimelineUserInfo.propTypes = {
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  top: PropTypes.string,
  userId: PropTypes.number.isRequired,
};

export default TimelineUserInfo;
