import { useFormikContext } from 'formik';
import { Conditional, Icon, Stack, Typography } from 'gantri-components';
import { useAsync } from 'react-use';
import { useGetPartReleaseRateCount } from '../../../../../../../../../api/parts/routes';
import { Label } from '../../../../../../../../../components/label';
import {
  jobStatuses,
  jobSteps,
  jobTypeOptions,
  partStatuses,
} from '../../../../../../../../../constants/options';
import { UpdatePartReleaseRateModalSchemaDef } from '../../../update-part-release-rate-modal.types';

export const PartReleaseRateModalConfirmContent = () => {
  const { setFieldValue, values } =
    useFormikContext<UpdatePartReleaseRateModalSchemaDef>();

  const { data, isLoading } = useGetPartReleaseRateCount({
    cacheTime: 0,
    fetchArgs: { newPartsCount: values.partsPerDay },
  });

  const numPartsMovedToProduction = data?.quantity;

  useAsync(async () => {
    await setFieldValue('processing', isLoading);
  }, [isLoading]);

  return (
    <Stack gap="4x">
      <Stack gap=".5x">
        <Label text="Update to" />
        <Typography text={`${values.partsPerDay} parts per day`} />
      </Stack>

      <Stack gap=".5x">
        <Label text="Notes" />
        <Conditional
          condition={!!values.notes}
          Fallback={<Typography color="t2" text="-" />}
        >
          <Typography htmlText={values.notes?.replace(/\n/g, '<br />')} />
        </Conditional>
      </Stack>

      <Stack gap=".5x">
        <Label text="Impacts to job queue" />
        <Conditional
          key="is-loading"
          condition={isLoading}
          Fallback={
            <Conditional
              key="are parts released"
              condition={!!numPartsMovedToProduction}
              Fallback={<Typography color="t2" text="No changes" />}
            >
              <Typography
                text={`${numPartsMovedToProduction} parts will be released into '${
                  partStatuses.waiting
                }' status. Their ${jobSteps.print.toLowerCase()} ${jobTypeOptions.transfer.toLowerCase()} jobs will now be in '${
                  jobStatuses.ready
                }' status.`}
              />
            </Conditional>
          }
        >
          <Icon color="link" name="animated:loader" />
        </Conditional>
      </Stack>
    </Stack>
  );
};
