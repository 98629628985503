import { Conditional, Icon, Tooltip, Typography } from 'gantri-components';
import { ColorOverrideTooltip } from './components/color-override-tooltip';
import { PartTemplateColorOverridesCellProps } from './part-template-color-overrides-cell.types';

export const PartTemplateColorOverridesCell = (
  props: PartTemplateColorOverridesCellProps,
) => {
  const { colorOverrides } = props;

  const numColorOverrides = colorOverrides.length;

  return (
    <Conditional
      condition={!!numColorOverrides}
      Fallback={<Typography text="None" />}
    >
      <Typography
        icon={
          <Tooltip
            Component={<ColorOverrideTooltip colorOverrides={colorOverrides} />}
            position="top-end"
          >
            <Icon name="alert:i_circle" />
          </Tooltip>
        }
        iconPosition="right"
        text={`${numColorOverrides} Override`}
      />
    </Conditional>
  );
};
