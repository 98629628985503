import { useRecoilValue } from 'recoil';
import { setMachinesOfflineModalSteps } from '../../set-machines-offline-modal.constants';
import { setMachinesOfflineModalAtoms } from '../../set-machines-offline-modal.atoms';
import { ReviewOfflinedMachinesContent } from '../step-review-offline-machines/review-offline-machines-content';
import { ConfirmSetMachinesOfflineContent } from '../step-confirm-set-machines-offline/confirm-set-machines-offline-content';

export const SetMachinesOfflineModalContent = () => {
  const step = useRecoilValue(setMachinesOfflineModalAtoms.step);

  switch (step) {
    case setMachinesOfflineModalSteps.confirmSetMachinesOffline:
      return <ConfirmSetMachinesOfflineContent />;

    case setMachinesOfflineModalSteps.reviewOfflinedMachines:
      return <ReviewOfflinedMachinesContent />;

    default:
      return null;
  }
};
