import { Button } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { publishDraftModalAtoms } from '../../../publish-draft-modal.atoms';
import { DraftDetailsFooterProps } from './draft-details-footer.types';

export const DraftDetailsFooter = (props: DraftDetailsFooterProps) => {
  const { onCloseModal } = props;

  const setStep = useSetRecoilState(publishDraftModalAtoms.step);
  const draftDetails = useRecoilValue(publishDraftModalAtoms.draftDetails);

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={onCloseModal}
      />
      <Button
        disabled={!draftDetails}
        size="large"
        text="Continue"
        onClick={() => {
          setStep('CONFIRM_PUBLISH');
        }}
      />
    </>
  );
};
