import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Conditional,
  Flex,
  FormikInput,
  Grid,
  SearchField,
  TextArea,
  Typography,
} from 'gantri-components';
import { Form, Formik } from 'formik';
import { FormikProps } from 'formik/dist/types';
import pick from 'lodash/pick';
import { StyledPageSubsection } from '../../components/layout/page-layout-styles';
import {
  StyledHeaderContainer,
  StyledInventoryPurchaseFieldContainer,
} from './new-inventory-purchase.styles';
import routePaths from '../../config/route-paths';
import { countries } from '../../constants/locales';
import { deleteBlob } from '../../helpers/firebase';
import MetaData from '../../components/meta-data';
import { PageHeading } from '../../components/layout/page-heading';
import { PurchaseDetailsSection } from './components/purchase-details-section/purchase-details-section';
import { initialsFormValues } from './new-inventory-purchase.constants';
import { ShippingDetailsOrigin } from './components/shipping-details/shipping-details-origin';
import { ShippingDetailsDestination } from './components/shipping-details/shipping-details-destination';
import { useRouter } from '../../hooks';
import { NewInventoryPurchaseFormState } from './new-inventory-purchase.types';
import { NewInventoryPurchaseSchema } from './new-inventory-purchase.schema';
import { Inventory } from '../../api/inventories/routes/get-inventory/get-inventory.types';
import { useFetchAllInventories } from '../../api/inventories/routes/fetch-all';
import { useNotification } from '../../hooks/useNotification';
import { inventoryPurchasesApi } from '../../api/inventory-purchases';
import { AttachmentsSection } from './components/attachments-section';

export const NewInventoryPurchase = () => {
  const { navigate } = useRouter();
  const formRef = useRef<FormikProps<NewInventoryPurchaseFormState>>();

  const [confirming, setConfirming] = useState(false);
  const [processingUpload, setProcessingUpload] = useState<boolean>(false);

  const { notify, notifyAxiosError } = useNotification();

  const { data } = useFetchAllInventories({
    showLoading: true,
    transform: ({ inventories }) => {
      return {
        inventories: inventories?.filter((inventory) => {
          return inventory.status === 'Active';
        }),
      };
    },
  });

  const inventories = data?.inventories || [];

  const onInventoryChange = async (inventory: Inventory) => {
    if (inventory?.vendor) {
      const { vendor } = inventory;

      const country = countries.find((country) => {
        return country.code === vendor.country;
      });

      await formRef.current.setFieldValue(
        'country',
        country?.name || 'United States',
        false,
      );
      await formRef.current.setFieldValue('state', vendor?.state || '', false);
    }
  };

  const onCancel = () => {
    const { attachments } = formRef.current.values;

    if (attachments.length === 0) {
      navigate(routePaths.inventoryPurchases);

      return;
    }

    attachments.forEach((attachment) => {
      notify(`Deleting ${attachment.fileName}`);

      deleteBlob({
        fileUrl: attachment.url,
        onComplete: () => {
          return navigate(routePaths.inventoryPurchases);
        },
      });
    });
  };

  const onConfirmCreate = async (formValues: NewInventoryPurchaseFormState) => {
    try {
      setConfirming(true);

      const newInventory = pick(formValues, [
        'attachments',
        'address',
        'city',
        'country',
        'currency',
        'invoice',
        'notes',
        'priority',
        'state',
        'totalCost',
        'units',
      ]);

      const createRequestData = {
        ...newInventory,
        inventoryId: Number(formValues.inventory),
        totalCost: newInventory.totalCost * 100,
        units: Number(newInventory.units),
      };

      const { data } = await inventoryPurchasesApi.createInventoryPurchase(
        createRequestData,
      );

      if (data.success) {
        navigate(`${routePaths.inventoryPurchases}/${data.purchase.id}`);
      }
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to create inventory purchase.',
      });
    } finally {
      setConfirming(false);
    }
  };

  return (
    <Formik
      initialValues={initialsFormValues}
      innerRef={formRef}
      validateOnChange
      validateOnMount
      validationSchema={NewInventoryPurchaseSchema}
      onSubmit={onConfirmCreate}
    >
      {({ isValid, setFieldValue, values }) => {
        const { selectedInventory } = values as NewInventoryPurchaseFormState;

        return (
          <Form noValidate>
            <>
              <MetaData title="New Inventory Purchase" />
              <PageHeading title="New Inventory Purchase" />
              <Flex
                alignItems="flex-start"
                direction="column"
                gap="1rem"
                width="100%"
              >
                <Flex direction="column">
                  <Typography
                    marginTop="1rem"
                    text="Inventory"
                    textStyle="bold"
                    variant="h4"
                  />
                  <Grid
                    columns={{
                      lg: 'repeat(auto-fit, minmax(0, 1fr))',
                      sm: `repeat(${selectedInventory ? 2 : 1}, 1fr)`,
                    }}
                    width="100%"
                  >
                    <StyledInventoryPurchaseFieldContainer
                      style={{ width: !!selectedInventory ? '100%' : '38rem' }}
                    >
                      <Typography text="Name" textStyle="bold" />
                      <FormikInput
                        Field={
                          <SearchField
                            dropdownPopupWidth="38rem"
                            filterFn={(searchBy, option) => {
                              return (
                                String(option.id)
                                  ?.toLowerCase()
                                  ?.includes(searchBy.toLowerCase()) ||
                                String(option.name)
                                  ?.toLowerCase()
                                  ?.includes(searchBy.toLowerCase())
                              );
                            }}
                            header={
                              <StyledHeaderContainer
                                columnGap="1rem"
                                columns="5rem 1fr"
                                padding=".8rem"
                              >
                                <Typography color="t3" text="ID" />
                                <Typography color="t3" text="Inventory Name" />
                              </StyledHeaderContainer>
                            }
                            idProperty="id"
                            items={inventories}
                            labelProperty="name"
                            placeholder="Select Inventory"
                            renderItem={(option: {
                              id: number;
                              name: string;
                            }) => {
                              return (
                                <Grid
                                  columnGap="1rem"
                                  columns="5rem 1fr"
                                  padding=".8rem"
                                >
                                  <Typography text={option.id} />
                                  <Typography text={option.name} />
                                </Grid>
                              );
                            }}
                            width="38rem"
                            onSelect={async (inventory) => {
                              await setFieldValue(
                                'selectedInventory',
                                inventory,
                              );
                              await onInventoryChange(inventory as Inventory);
                            }}
                          />
                        }
                        fieldVariant="standard"
                        name="inventory"
                      />
                    </StyledInventoryPurchaseFieldContainer>

                    <Conditional condition={!!selectedInventory}>
                      <StyledInventoryPurchaseFieldContainer>
                        <Typography text="Type" textStyle="bold" />
                        <Typography text={selectedInventory?.type} />
                      </StyledInventoryPurchaseFieldContainer>
                      <StyledInventoryPurchaseFieldContainer>
                        <Typography text="ID" textStyle="bold" />
                        <Link
                          to={`${routePaths.inventories}/${selectedInventory?.id}`}
                        >
                          <Typography
                            color="link"
                            text={
                              selectedInventory && `#${selectedInventory.id}`
                            }
                          />
                        </Link>
                      </StyledInventoryPurchaseFieldContainer>
                      <StyledInventoryPurchaseFieldContainer>
                        <Typography text="Vendor" textStyle="bold" />
                        {selectedInventory?.vendor && (
                          <Typography text={selectedInventory.vendor.name} />
                        )}
                      </StyledInventoryPurchaseFieldContainer>
                      <StyledInventoryPurchaseFieldContainer>
                        <Typography text="Email" textStyle="bold" />
                        {selectedInventory?.vendor && (
                          <Typography
                            text={
                              selectedInventory.vendor.email ||
                              'No email associated with this vendor.'
                            }
                          />
                        )}
                      </StyledInventoryPurchaseFieldContainer>
                    </Conditional>
                  </Grid>
                </Flex>

                <Conditional condition={!!selectedInventory}>
                  <PurchaseDetailsSection />

                  <Flex alignItems="flex-start" direction="column">
                    <Typography
                      marginTop="1rem"
                      text="Shipping Details"
                      textStyle="bold"
                      variant="h4"
                    />
                    <ShippingDetailsOrigin />
                    <ShippingDetailsDestination />
                  </Flex>

                  <Flex alignItems="flex-start" direction="column" width="100%">
                    <AttachmentsSection
                      setProcessingUpload={setProcessingUpload}
                    />
                    <StyledPageSubsection>
                      <StyledInventoryPurchaseFieldContainer margin="1rem">
                        <Typography
                          text="Notes"
                          textStyle="bold"
                          variant="h4"
                        />

                        <FormikInput
                          Field={
                            <TextArea
                              minRows={6}
                              placeholder="Inventory Purchase Notes"
                            />
                          }
                          name="notes"
                        />
                      </StyledInventoryPurchaseFieldContainer>
                    </StyledPageSubsection>
                  </Flex>
                </Conditional>

                <Flex
                  alignItems="center"
                  gap="s1"
                  marginRight="1rem"
                  width="38rem"
                >
                  <Button
                    disabled={processingUpload}
                    text="Cancel"
                    variant="secondary"
                    width="100%"
                    onClick={onCancel}
                  />
                  <Button
                    disabled={!isValid || processingUpload}
                    processing={confirming}
                    text="Create"
                    type="submit"
                    width="100%"
                  />
                </Flex>
              </Flex>
            </>
          </Form>
        );
      }}
    </Formik>
  );
};
