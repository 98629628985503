import { Button } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { stocksApi } from '../../../../../../../../../api';
import { useNotification } from '../../../../../../../../../hooks/useNotification';
import {
  cancelOrUpdateStockVersionModalNewStockAtom,
  cancelOrUpdateStockVersionModalStepAtom,
} from '../../../cancel-or-update-stock-version-modal.atoms';
import { ConfirmUpdateFooterProps } from './confirm-update-footer.types';

export const ConfirmUpdateFooter = (props: ConfirmUpdateFooterProps) => {
  const { onClose, stockId } = props;

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const setStep = useSetRecoilState(cancelOrUpdateStockVersionModalStepAtom);
  const setNewStock = useSetRecoilState(
    cancelOrUpdateStockVersionModalNewStockAtom,
  );

  const handleStockVersionUpdate = async () => {
    try {
      showLoading();

      const { data } = await stocksApi.updateVersion(stockId);

      setNewStock({
        id: data.newStockId,
        version: data.newVersionNumber,
      });
      setStep('UPDATE_SUCCESS');
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to update stock version.',
      });
      setStep('UPDATE_ERROR');
    } finally {
      hideLoading();
    }
  };

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={onClose}
      />
      <Button
        size="large"
        text="Yes, update version"
        onClick={handleStockVersionUpdate}
      />
    </>
  );
};
