import styled, { css } from 'styled-components';
import { Property } from 'csstype';
import { mediaQueries } from '../../constants/styles';

export const StyledPayoutPageLayout = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  margin-top: -3rem;
  gap: 4rem;
`;

export const StyledLeftContainer = styled.div`
  flex: 2;
  width: 100%;
  margin-top: 3rem;
`;

export const StyledRightContainer = styled.div`
  flex: 1;
  width: 100%;
  margin-top: 3rem;
`;

export const StyledPayoutAvatar = styled.div<{ img: string }>`
  width: 8rem;
  height: 8rem;
  background-image: ${(props) => {
    return `url(${props.img})`;
  }};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 2rem;
`;

export const StyledLabelContainer = styled.div`
  width: 12rem;
  display: flex;
  align-items: center;
`;

export const StyledFieldContainer = styled.div<{ width?: Property.Width }>`
  display: flex;
  align-items: center;
  ${(props) => {
    return (
      props.width &&
      css`
        min-width: ${props.width};
        ${mediaQueries.mobile('min-width: unset;')}
      `
    );
  }}
`;

export const StyledSubSectionTitle = styled.div`
  margin-bottom: 1rem;
`;
