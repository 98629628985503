import {
  generateStylesForResolutionAwareProps,
  Table,
} from 'gantri-components';
import { ExtractStyleFromResolutionAwarePropEntry } from 'gantri-components/dist/types/layout';
import { AppSize } from 'gantri-components/dist/types/resolution-aware-prop.type';
import styled, { css } from 'styled-components';

export const dataAttrActiveJobRow = 'data-is-active-job-row';

export const StyledTable = styled(Table)`
  ${({ theme }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'font-family',
        resolutionAwareProp: 'p1',
        valueFormatter: () => {
          const { family } = theme.fonts.p1;

          return `${family}, "Helvetica", sans-serif;`;
        },
      },
      {
        cssProperty: 'font-size',
        resolutionAwareProp: [],
        valueFormatter: ([], size: AppSize) => {
          const type = theme.fonts.p1;

          return type[
            size === 'sm'
              ? 'sizeSmaller'
              : size === 'md'
              ? 'sizeMedium'
              : 'size'
          ];
        },
      },
      {
        cssProperty: 'line-height',
        resolutionAwareProp: [],
        valueFormatter: ([], size: AppSize) => {
          const type = theme.fonts.p1;

          return type[
            size === 'sm'
              ? 'lineHeightSmaller'
              : size === 'md'
              ? 'lineHeightMedium'
              : 'lineHeight'
          ];
        },
      },
      {
        cssProperty: 'font-weight',
        resolutionAwareProp: 'p1',
        valueFormatter: () => {
          const type = theme.fonts.p1;

          return String(type.fontWeight ?? 400);
        },
      },
    ];

    return css`
      table {
        .tbody {
          .tr {
            .td {
              &:not([data-cell-status]):not([data-cell-actions]) {
                *,
                & {
                  ${generateStylesForResolutionAwareProps(entries)};
                }
              }
            }

            &[${dataAttrActiveJobRow}] {
              .td {
                background-color: ${theme.colors.surfaces.monochrome
                  .t1Alt} !important;
                border-top: 2px solid ${theme.colors.dividers.t2};
                border-bottom: 2px solid ${theme.colors.dividers.t2};

                &:first-child {
                  border-left: 2px solid ${theme.colors.dividers.t2};
                }
                &:last-child {
                  border-right: 2px solid ${theme.colors.dividers.t2};
                }
              }
            }
          }
        }
      }
    `;
  }}
`;
