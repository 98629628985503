import { useState, useCallback } from 'react';
import {
  Table,
  CustomActionComponentProps,
  RowSelectionProviders,
  useRowSelection,
} from 'gantri-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MachinesFilter } from '../../components/dropdowns/machines-filter';
import {
  MachineRecord,
  LocationType,
  LoadMachinesPage,
} from './machines.types';
import { PageWithTable } from '../../components/layout';
import {
  dataAttrMachineId,
  modalsFiltersDetails,
  refreshPageMessage,
  sortOptions,
} from './machine.constants';
import { useGetMachinesColumns } from './hooks/use-get-machines-columns';
import { MachinesBulkActions } from './components/machines-bulk-actions';
import {
  FetchPaginatedMachinesArgs,
  MachinesSortingField,
} from '../../api/machines/routes/fetch-paginated-machines/fetch-paginated-machines.types';
import {
  FiltersFetchRequestArgs,
  useTableFilters,
} from '../../components/common/table/hooks';
import {
  machinesFiltersDefaults,
  machinesPageAtoms,
  pageName,
} from '../../components/dropdowns/machines-filter/machines-filter.atoms';
import { MachinesHeaderContent } from './components/machines-header-content';
import { useRealTimeWebHook } from '../../hooks/use-real-time-web-hook';
import { SimpleErrorBoundary } from '../../components/simple-error-boundary';
import {
  useFetchPaginatedMachines,
  useInvalidateFetchPaginatedMachinesCache,
  useInvalidateGetMachineOverviewCache,
} from '../../api/machines/routes';
import { machinesAtoms } from './machines.atoms';
import { getRowProps } from './helpers/get-row-props';
import { realTimeChannels } from '../../hooks/use-real-time-web-hook/use-real-time-web-hook.constants';
import { useNotification } from '../../hooks/useNotification';
import { RealTimeMachineChangedData } from '../../hooks/use-real-time-web-hook/use-real-time-web-hook.types';

const MachinesComponent = () => {
  const [filters, setFilters] =
    useState<
      FiltersFetchRequestArgs<FetchPaginatedMachinesArgs, MachinesSortingField>
    >();
  const [machineLocations, setMachineLocations] = useState<LocationType[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [disableRowSelection, setDisableRowSelection] = useRecoilState(
    machinesAtoms.disableRowSelection,
  );

  const [machinesSelected, setMachinesSelected] = useRecoilState(
    machinesAtoms.machinesSelected,
  );

  const { notifyWarning } = useNotification();

  useFetchPaginatedMachines({
    enabled: !!filters,
    fetchArgs: filters,
    onSuccess: async (data) => {
      setRecords(data.machines);
      setTotalCount(data.totalMachines);
      setMachineLocations(data.machineLocations);
      // ensure always enabled again after request.
      setShowLoading(true);
    },
    showLoading,
  });

  const {
    currentPage,
    filtersProps,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    MachineRecord,
    FetchPaginatedMachinesArgs,
    MachinesSortingField
  >({
    fetchRequest: setFilters,
    filtersContent: <MachinesFilter machineLocations={machineLocations} />,
    modalFiltersDetails: modalsFiltersDetails,
    pageName,
    searchFilterDetails: {
      atom: machinesPageAtoms.search,
    },
    sortByFilterDetails: {
      atom: machinesPageAtoms.sortBy,
      defaultValue: machinesFiltersDefaults.sortBy,
    },
    sortOptions,
  });

  const { invalidateFetchPaginatedMachinesCache } =
    useInvalidateFetchPaginatedMachinesCache();

  const loadMachinesPage: LoadMachinesPage = async (args) => {
    const { onlyClearRowsIfFilters, page } = args || {};

    if (onlyClearRowsIfFilters) {
      if (!!filtersProps.numActive) {
        clearSelectedRows();
      }
    } else {
      clearSelectedRows();
    }

    await invalidateFetchPaginatedMachinesCache({
      ...filters,
      page: page || currentPage,
    });
  };

  const { clearSelectedRows } = useRowSelection();

  const columns = useGetMachinesColumns({
    loadMachinesPage,
  });

  const { invalidateMachineOverviewCache } =
    useInvalidateGetMachineOverviewCache();

  const MachinesBulkActionsSection = useCallback(
    // useCallback prevents window resize from closing bulk action modals
    (props: CustomActionComponentProps<MachineRecord>) => {
      return (
        <MachinesBulkActions
          {...props}
          onSuccess={async () => {
            await invalidateMachineOverviewCache();

            return loadMachinesPage();
          }}
        />
      );
    },
    [filters],
  );

  const activeStatusFilters = useRecoilValue(
    machinesPageAtoms.filters.statuses,
  );

  useRealTimeWebHook<MachineRecord, RealTimeMachineChangedData>(
    {
      activeStatusFilters,
      channel: realTimeChannels.machines,
      dataAttrForRow: dataAttrMachineId,
      disableHandleFetchRequest: ({ changedData }) => {
        const selectionIncludesChangedData = changedData.some((updatedData) => {
          return machinesSelected.some((selectedMachine) => {
            return selectedMachine.id === updatedData.id;
          });
        });

        if (selectionIncludesChangedData) {
          setDisableRowSelection(true);
          notifyWarning(refreshPageMessage, { keepOpen: true });
        }

        return selectionIncludesChangedData;
      },
      handleFetchRequest: async () => {
        setShowLoading(false);

        await invalidateFetchPaginatedMachinesCache({
          ...filters,
          page: currentPage,
        });
      },
      records,
      setRecords,
    },
    [machinesSelected],
  );

  return (
    <PageWithTable pageTitle="Machines">
      <PageWithTable.Header title="Machines">
        <SimpleErrorBoundary>
          <MachinesHeaderContent
            downloadFilters={filters}
            loadMachinesPage={loadMachinesPage}
            totalResults={pagingProps.total}
          />
        </SimpleErrorBoundary>
      </PageWithTable.Header>

      <PageWithTable.Content>
        <SimpleErrorBoundary>
          <Table
            columns={columns}
            customAction={{
              Component: MachinesBulkActionsSection,
              position: 'handle-selections',
            }}
            data={records || []}
            filters={filtersProps}
            getRowProps={getRowProps}
            highlightHoveredRow
            paging={pagingProps}
            rowSelection={{
              disabled: disableRowSelection,
              onSelect: setMachinesSelected,
              type: 'checkboxes',
            }}
            search={searchProps}
            sorting={sortProps}
            stickyLastColumn
          />
        </SimpleErrorBoundary>
      </PageWithTable.Content>
    </PageWithTable>
  );
};

export const Machines = () => {
  return (
    <RowSelectionProviders>
      <MachinesComponent />
    </RowSelectionProviders>
  );
};
