import { InventoryPurchaseStatus } from '../../../../../../api/inventory-purchases/inventory-purchases.types';
import { DateRangeFilter } from '../../../../../../components/common/table/components/table-filters/table-filters.types';
import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
} from '../../../../../../components/common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../../../../helpers/get-generic-atom-family';
import { SortBy } from '../../../../../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../../../../helpers/get-generic-atom-family/get-generic-atom-family.types';

export type VendorPurchasesSortingField = 'createdAt' | 'completedAt';

export const vendorPurchasesFiltersDefaults: {
  filters: {
    completedDateRange: DateRangeFilter;
    createdDateRange: DateRangeFilter;
    hasDelayedShipping: boolean;
    hasQuantityIssues: boolean;
    statuses: InventoryPurchaseStatus[];
  };
  sortBy: SortBy<VendorPurchasesSortingField>;
} = {
  filters: {
    completedDateRange: {
      from: null,
      to: null,
    },
    createdDateRange: {
      from: null,
      to: null,
    },
    hasDelayedShipping: false,
    hasQuantityIssues: false,
    statuses: [],
  },
  sortBy: {
    sortingField: 'createdAt',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'VENDOR_PURCHASES_FILTERS';

export const pageName: AtomPageName = 'vendor-purchases';

export const vendorPurchasesFilterAtoms = {
  defaults: vendorPurchasesFiltersDefaults,
  filters: {
    completedDateRange: getGenericAtomFamily({
      defaultValue: vendorPurchasesFiltersDefaults.filters.completedDateRange,
      key: `${atomKeyPrefix}-completed-date-range`,
    })(pageName),
    createdDateRange: getGenericAtomFamily({
      defaultValue: vendorPurchasesFiltersDefaults.filters.createdDateRange,
      key: `${atomKeyPrefix}-created-date-range`,
    })(pageName),
    hasDelayedShipping: getGenericAtomFamily({
      defaultValue: vendorPurchasesFiltersDefaults.filters.hasDelayedShipping,
      key: `${atomKeyPrefix}-has-delayed-shipping`,
    })(pageName),
    hasQuantityIssues: getGenericAtomFamily({
      defaultValue: vendorPurchasesFiltersDefaults.filters.hasQuantityIssues,
      key: `${atomKeyPrefix}-has-quantity-issues`,
    })(pageName),
    statuses: getGenericAtomFamily({
      defaultValue: vendorPurchasesFiltersDefaults.filters.statuses,
      key: `${atomKeyPrefix}-statuses`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<VendorPurchasesSortingField>>({
    defaultValue: vendorPurchasesFiltersDefaults.sortBy,
  })(pageName),
};
