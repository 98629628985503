import {
  Conditional,
  Flex,
  Icon,
  Tooltip,
  Typography,
  useModal,
} from 'gantri-components';
import { Cell, CellContext, ColumnDef } from '@tanstack/react-table';
import { memo, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { MachineStatusesDataCell } from '../../components/machine-status-data-cell/machine-status-data-cell';
import { StyledTextAndIconContainer } from '../../../jobs/components/custom-data-cells/custom-data-cell-styles';
import { formatDate } from '../../../../helpers/formatter';
import { LONG_FORMAT, MEDIUM_FORMAT } from '../../../../constants/dates';
import { StyledFieldIconWrapper } from '../../../../components/common/custom-data-cells/custom-data-cells-styles';
import { MachineIssues } from '../../components/machine-issues';
import { MachineRecord } from '../../machines.types';
import { AttentionGridCell } from '../../../../components/layout/attention-grid-cell';
import { machineStatuses } from '../../../../constants/machines';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import { MoreMenu } from '../../../../components/dropdowns';
import { UseGetMachinesColumnsProps } from './use-get-machines-columns.types';
import routePaths from '../../../../config/route-paths';
import { getIsMachineTrackingMaintenance } from '../../../machine/components/hours-until-maintenance';
import { NotApplicableText } from '../../../../components/common/not-applicable-text';
import { MoreMenuToggleOption } from '../../../../hooks/use-more-menu/components/more-menu-toggle/more-menu-toggle.types';
import { userRoles } from '../../../../constants/users';
import {
  getIsCrealityMachine,
  getResetBuildPlateDisabled,
  getSetOfflineDisabled,
  getSetOnlineDisabled,
} from '../../helpers';
import { useHandleBulkUpdateMachines } from '../use-handle-bulk-update-machines';
import { AddOrEditMachineModal } from '../../components/add-or-edit-machine-modal';
import { getRolesForAccessLevelAndAbove } from '../../../../helpers/get-roles-for-access-level-and-above';
import { machinesAtoms } from '../../machines.atoms';
import { useInvalidateGetMachineOverviewCache } from '../../../../api/machines/routes';

export const useGetMachinesColumns = (props: UseGetMachinesColumnsProps) => {
  const { loadMachinesPage } = props;

  const disableRowSelection = useRecoilValue(machinesAtoms.disableRowSelection);

  const [machineToEdit, setMachineToEdit] = useState<MachineRecord>();

  const { invalidateMachineOverviewCache } =
    useInvalidateGetMachineOverviewCache();

  const { handleBulkUpdate } = useHandleBulkUpdateMachines({
    onSuccess: async () => {
      await invalidateMachineOverviewCache();

      return loadMachinesPage({ onlyClearRowsIfFilters: true });
    },
  });

  const [showEditMachineModal, hideEditMachineModal] = useModal(() => {
    return (
      <AddOrEditMachineModal
        loadMachinesPage={loadMachinesPage}
        machineToEdit={machineToEdit}
        onClose={hideEditMachineModal}
      />
    );
  }, [machineToEdit, loadMachinesPage]);

  const handleEditMachine = (machine: MachineRecord) => {
    setMachineToEdit(machine);
    showEditMachineModal();
  };

  const columns: ColumnDef<MachineRecord>[] = useMemo(() => {
    return [
      {
        accessorKey: 'id',
        cell: memo(
          ({ row }: CellContext<MachineRecord, MachineRecord['id']>) => {
            return (
              <StyledAnchor
                href={`${routePaths.machines}/${row.original.id}`}
                text={row.original.name}
              />
            );
          },
        ),
        header: 'Machine',
        meta: {
          // intentionally disabling clickable column as it causes confusion with selectable rows checkboxes
          getIsClickable: () => {
            return false;
          },
        },
      },
      {
        accessorKey: 'type',
        header: 'Type',
      },
      {
        accessorKey: 'bay',
        header: 'Bay',
      },
      {
        accessorKey: 'location',
        cell: memo(
          ({
            getValue,
          }: CellContext<MachineRecord, MachineRecord['location']>) => {
            return <Typography text={getValue() || 'San Leandro'} />;
          },
        ),
        header: 'Location',
      },
      {
        accessorKey: 'status',
        cell: memo(
          ({
            row: { original },
          }: CellContext<MachineRecord, MachineRecord['status']>) => {
            return <MachineStatusesDataCell {...original} />;
          },
        ),
        header: 'Status',
      },
      {
        accessorKey: 'lastStatusChangeInfo',
        cell: memo(
          ({
            getValue,
          }: CellContext<
            MachineRecord,
            MachineRecord['lastStatusChangeInfo']
          >) => {
            const lastStatusChangeInfo = getValue();

            return (
              <StyledTextAndIconContainer>
                {!!lastStatusChangeInfo && (
                  <>
                    <Typography
                      color="t1"
                      text={formatDate(
                        lastStatusChangeInfo.date,
                        MEDIUM_FORMAT,
                      )}
                    />
                    <div style={{ marginLeft: '5px' }}>
                      <StyledAnchor
                        href={`${routePaths.users}/${lastStatusChangeInfo.id}`}
                        target="_blank"
                        text={lastStatusChangeInfo.name}
                      />
                    </div>
                    <Tooltip
                      overlayContainerStyles={{
                        position: 'relative',
                        zIndex: 1,
                      }}
                      position="right"
                      title={formatDate(lastStatusChangeInfo.date, LONG_FORMAT)}
                    >
                      <StyledFieldIconWrapper>
                        <Icon
                          color="t1"
                          name="alert:i_circle"
                          size="1.6rem"
                          top="2px"
                        />
                      </StyledFieldIconWrapper>
                    </Tooltip>
                  </>
                )}
              </StyledTextAndIconContainer>
            );
          },
        ),
        header: 'Last status change',
        meta: {
          getIsClickable: ({
            getValue,
          }: Cell<MachineRecord, MachineRecord['lastStatusChangeInfo']>) => {
            return !!getValue()?.name?.trim();
          },
        },
        size: 200,
      },
      {
        accessorKey: 'printHours',
        cell: memo(
          ({
            getValue,
            row,
          }: CellContext<MachineRecord, MachineRecord['printHours']>) => {
            const printHours = getValue();
            const machineType = row.original.type;
            const isMachineTrackingMaintenance =
              getIsMachineTrackingMaintenance(machineType);

            return (
              <Conditional
                condition={
                  isMachineTrackingMaintenance && typeof printHours === 'number'
                }
                Fallback={<NotApplicableText />}
              >
                <Typography text={printHours} />
              </Conditional>
            );
          },
        ),
        header: 'Maintenance in',
      },
      {
        accessorKey: 'issues',
        cell: memo(
          ({
            getValue,
          }: CellContext<MachineRecord, MachineRecord['issues']>) => {
            return <MachineIssues issues={getValue()} />;
          },
        ),
        header: 'Open issues',
      },
      {
        accessorKey: 'attentions',
        cell: memo(
          ({
            getValue,
          }: CellContext<MachineRecord, MachineRecord['attentions']>) => {
            return <AttentionGridCell attentions={getValue()} />;
          },
        ),
        header: 'Attention',
      },
      {
        cell: memo(({ row }: CellContext<MachineRecord, never>) => {
          const { status, type } = row.original;

          const isInProgress = status === machineStatuses.inProgress;
          const isOffline = status === machineStatuses.offline;

          const isCreality = getIsCrealityMachine({ type });

          const disabledDueToStatusMessage = `Cannot take this action when machine status is "${status}".`;

          const options: MoreMenuToggleOption<MachineRecord>[] = [
            {
              allowedFor: getRolesForAccessLevelAndAbove(userRoles.lead),
              disabled: isInProgress,
              disabledTooltipProps: { description: disabledDueToStatusMessage },
              name: 'Edit',
              onOptionClick: handleEditMachine,
            },
            isOffline
              ? {
                  allowedFor: [userRoles.admin, userRoles.lead],
                  disabled: getSetOnlineDisabled,
                  disabledTooltipProps: {
                    description: isOffline
                      ? 'Cannot take this action when there are open issues.'
                      : disabledDueToStatusMessage,
                  },
                  name: 'Set Machine Online',
                  onOptionClick: async (machine) => {
                    await handleBulkUpdate({
                      action: 'Set online',
                      singleMachine: machine,
                    });
                  },
                }
              : {
                  allowedFor: [userRoles.admin, userRoles.lead],
                  disabled: getSetOfflineDisabled,
                  disabledTooltipProps: {
                    description: disabledDueToStatusMessage,
                  },
                  name: 'Set Machine Offline',
                  onOptionClick: async (machine) => {
                    await handleBulkUpdate({
                      action: 'Set offline',
                      singleMachine: machine,
                    });
                  },
                },
            {
              disabled: getResetBuildPlateDisabled,
              disabledTooltipProps: {
                description: !isCreality
                  ? 'Cannot reset the build plate of non-Creality machines.'
                  : disabledDueToStatusMessage,
              },
              name: 'Reset Build Plate',
              onOptionClick: async (machine) => {
                await handleBulkUpdate({
                  action: 'Reset build plate',
                  singleMachine: machine,
                });
              },
            },
          ];

          return (
            <Flex justifyContent="flex-end">
              <MoreMenu data={row.original} options={options} />
            </Flex>
          );
        }),
        id: 'menu',
        maxSize: 40,
      },
    ];
  }, [disableRowSelection]);

  return columns;
};
