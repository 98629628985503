import { FetchAllInventoriesResponse } from './fetch-all-inventories.types';
import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { fetchAllInventories } from './fetch-all-inventories';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';

const fetchAllInventoriesQueriesObj = getGenericQueryObj({
  groupName: 'fetch-all-inventories',
  query: fetchAllInventories,
});

export const useFetchAllInventories = <
  TransformedData = FetchAllInventoriesResponse,
>(
  props?: GenericFetchProps<
    never,
    FetchAllInventoriesResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch all inventories.',
    queryObj: fetchAllInventoriesQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchAllInventoriesCache = () => {
  const invalidateFetchAllInventoriesCache = useGetInvalidateQueryCache(
    fetchAllInventoriesQueriesObj,
  );

  return { invalidateFetchAllInventoriesCache };
};

export const paintInventoryNamePattern = /Paint\s?[-(—]/i;
