import axios from 'axios';
import { getCoreApiUrl } from '../../../helpers/auth';
import {
  UpdatePressRecordArgs,
  UpdatePressRecordResponse,
} from './update-press-record.types';

export const updatePressRecord = ({ id, ...body }: UpdatePressRecordArgs) => {
  const { publishDate } = body;

  return axios
    .put<UpdatePressRecordResponse>(`${getCoreApiUrl('press')}/${id}`, {
      ...body,
      publishDate,
    })
    .then(({ data }) => {
      return data;
    });
};
