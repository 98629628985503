import { groupBy } from 'lodash';
import { OrderRecord, Shipment } from './orders.types';
import { formatAsCurrency } from '../../helpers/formatter';

export const isAbleToShip = (order: OrderRecord): boolean => {
  const notAbleToShip = ['Refunded', 'Cancelled'];

  return order.readyToShip && !!order.readyToShipId;
};

export const canCompleteRefund = (order: OrderRecord): boolean => {
  return order.canCompleteRefund;
};

export const calculateTotal = (order: OrderRecord): string => {
  const {
    amount: {
      credit = 0,
      discount = 0,
      gift = 0,
      shipping = 0,
      subtotal = 0,
      tax = 0,
    },
  } = order;

  const total = subtotal + shipping + tax - (discount || gift || credit);

  return formatAsCurrency(total, { isCents: true });
};

export const getShipmentInfo = (shipment: Shipment): string => {
  const grouped = groupBy(shipment.stocks || [], 'stockName');

  return Object.keys(grouped)
    .map((key) => {
      const [stock] = grouped[key];

      return `${stock.stockName} x ${grouped[key].length}`;
    })
    .join(', ');
};
