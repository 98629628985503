import { FC, PropsWithChildren } from 'react';
import { Flex, Line, Typography } from 'gantri-components';
import { SectionProps } from './section.types';

export const NewOrderSection: FC<PropsWithChildren<SectionProps>> = ({
  action,
  children,
  title,
}) => {
  return (
    <Flex direction="column" gap="x">
      <Flex justifyContent="space-between">
        {typeof title === 'string' ? (
          <Typography text={title} variant="h4" />
        ) : (
          title
        )}
        {action}
      </Flex>
      <Line marginBottom="x" />
      {children}
    </Flex>
  );
};
