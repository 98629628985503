import { Dropdown } from 'gantri-components';
import { useSelectProductFormOptions } from '../../hooks/use-select-product-form-options';
import { QuantityDropdownProps } from './quantity-dropdown.types';

export const QuantityDropdown = (props: QuantityDropdownProps) => {
  const { productId, ...rest } = props;

  const { quantityOptions } = useSelectProductFormOptions({
    productId,
  });

  return (
    <Dropdown
      {...rest}
      items={quantityOptions}
      placeholder="Select Quantity"
      width="7rem"
    />
  );
};
