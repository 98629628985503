import { Button, Modal } from 'gantri-components';
import { ReviewAssetsModalPropsDef } from './review-assets-modal.types';
import { ReviewAssetsModalContent } from './components/review-assets-modal-content';

export const ReviewAssetsModal = (props: ReviewAssetsModalPropsDef) => {
  const { onClose } = props;

  return (
    <Modal
      contentStackProps={{
        horizontalPadding: '2x',
        verticalPadding: '2x',
      }}
      footer={<Button size="large" text="Done" onClick={onClose} />}
      header="Review Assets"
      maxWidth={{ lg: '48rem', md: '100%' }}
      onClose={onClose}
    >
      <ReviewAssetsModalContent onClose={onClose} />
    </Modal>
  );
};
