import { Button, Flex } from 'gantri-components';
import { memo } from 'react';
import { StockSummarySelectedRowsActionsProps } from './stock-summary-selected-rows-actions.types';

export const StockSummarySelectedRowsActions = memo(
  (props: StockSummarySelectedRowsActionsProps) => {
    const { showAddStockModal, showUpdateInventoryTargetModal } = props;

    return (
      <Flex alignItems="center" gap="x">
        <Button
          text="Update Inventory Target"
          variant="secondary"
          onClick={showUpdateInventoryTargetModal}
        />
        <Button
          text="Add Stock"
          variant="secondary"
          onClick={showAddStockModal}
        />
      </Flex>
    );
  },
);
