import { Cell, Flex, Grid, Typography, Table, Box } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { formatAsCurrency } from '../../../../helpers/formatter';
import { productAtoms } from '../../product.atoms';
import { ProductSkuDropdown } from '../product-sku-dropdown';
import { useBomColumns } from './hooks/use-bom-columns';
import { useLaborColumns } from './hooks/use-labor-columns';
import { SimpleErrorBoundary } from '../../../../components/simple-error-boundary';
import { Divider } from '../../../../components/divider';
import { Label } from '../../../../components/label';
import { useShippingColumns } from './hooks/use-shipping-columns';

export const Costs = () => {
  const product = useRecoilValue(productAtoms.product);
  const selectedSku = useRecoilValue(productAtoms.selectedSku);

  const bomColumns = useBomColumns();
  const laborColumns = useLaborColumns();
  const shippingColumns = useShippingColumns();

  return (
    <Box paddingBottom="6x">
      <Grid
        alignItems="end"
        columns={{
          lg: 'max-content 1fr',
          md: '1fr',
        }}
        gap="6x"
        maxWidth="100%"
        overflow="auto"
        paddingBottom="3x"
      >
        <Cell>
          <ProductSkuDropdown />
        </Cell>

        <Grid
          columns={{
            lg: 'repeat(6, max-content)',
            sm: 'repeat(3, max-content)',
          }}
          gap="5x"
        >
          <Cell>
            <Label text="BOM" />
            <Typography
              text={
                selectedSku &&
                formatAsCurrency(product?.bomTotal?.totalCost || 0, {
                  isCents: true,
                })
              }
            />
          </Cell>

          <Cell>
            <Label text="Labor" />
            <Typography
              text={
                selectedSku &&
                formatAsCurrency(product?.laborTotal?.totalCost || 0, {
                  isCents: true,
                })
              }
            />
          </Cell>

          <Cell>
            <Label text="Shipping" />
            <Typography
              text={
                selectedSku &&
                formatAsCurrency(product?.shippingTotal || 0, {
                  isCents: true,
                })
              }
            />
          </Cell>

          <Cell>
            <Label text="Grand Total" />
            <Typography
              text={
                (selectedSku &&
                  formatAsCurrency(product?.grandPrice || 0, {
                    isCents: true,
                  })) ||
                0
              }
            />
          </Cell>

          <Cell>
            <Label text="Price & Margin" />
            <Flex gap="x">
              <Typography
                text={formatAsCurrency(product?.price || 0, {
                  isCents: true,
                })}
              />
              <Typography color="t2" text={`${product.margin || 0}%`} />
            </Flex>
          </Cell>

          <Cell>
            <Label text="Profit / Labor Hr" />
            <Typography
              text={
                selectedSku &&
                formatAsCurrency(product?.profit || 0, {
                  isCents: true,
                })
              }
            />
          </Cell>
        </Grid>
      </Grid>

      <Divider />

      <Typography
        marginBottom="2x"
        marginTop="3x"
        text="BOM"
        textStyle="bold"
        variant="h4"
      />
      <SimpleErrorBoundary>
        <Cell>
          <Table
            columns={bomColumns}
            data={product?.bom || []}
            highlightHoveredRow
          />
          <Divider />
        </Cell>
      </SimpleErrorBoundary>

      <Typography
        marginBottom="2x"
        marginTop="3x"
        text="Labor"
        textStyle="bold"
        variant="h4"
      />
      <SimpleErrorBoundary>
        <Cell>
          <Table
            columns={laborColumns}
            data={product?.labor || []}
            highlightHoveredRow
          />
          <Divider />
        </Cell>
      </SimpleErrorBoundary>

      <Typography
        marginBottom="2x"
        marginTop="3x"
        text="Shipping"
        textStyle="bold"
        variant="h4"
      />
      <SimpleErrorBoundary>
        <Cell>
          <Table
            columns={shippingColumns}
            data={
              product?.shipping?.length
                ? product.shipping
                : [
                    {
                      cost: 0,
                      id: null,
                      name: null,
                    },
                  ]
            }
            highlightHoveredRow
          />
        </Cell>
      </SimpleErrorBoundary>
    </Box>
  );
};
