import {
  Button,
  Conditional,
  Flex,
  Icon,
  Typography,
  useModal,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { productAtoms } from '../../../../product.atoms';
import { ReviewAssetsModal } from './components/review-assets-modal';

export const ReviewAssetsButton = () => {
  const product = useRecoilValue(productAtoms.product);

  const [showReviewAssetsModal, hideReviewAssetsModal] = useModal(() => {
    return <ReviewAssetsModal onClose={hideReviewAssetsModal} />;
  }, []);

  return (
    <Flex alignItems="center" gap="2x">
      <Conditional condition={product.isMissingRequiredAssets}>
        <Typography
          color="warning"
          icon={<Icon color="warning" name="alert:warning_triangle" />}
          text="Missing required assets"
        />
      </Conditional>

      <Button
        text="Review"
        variant="tertiary"
        onClick={showReviewAssetsModal}
      />
    </Flex>
  );
};
