import { Conditional } from 'gantri-components';
import { jobTemplateFloatingPartFileUploadPath } from '../../../../../../../helpers/gcodes';
import { useGetIsEditingRow } from '../../../use-get-is-editing-row';
import { useValidateFields } from '../job-template-type-cell/hooks/use-validate-fields';
import { JobTemplateGcodeCellProps } from './job-template-gcode-cell.types';
import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../constants/options';
import { GCodeDetails } from './components/gcode-details';
import { OnGCodeUpload } from './components/gcode-details/gcode-details.types';

export const JobTemplateGcodeCell = (props: JobTemplateGcodeCellProps) => {
  const { getValue, onEdit, partName, row } = props;

  const gcode = getValue();
  const { getHasError } = useValidateFields();
  const isEditingRow = useGetIsEditingRow(row);
  const hasError = getHasError('gcode');
  const path = jobTemplateFloatingPartFileUploadPath({
    fileType: 'gcodes',
  });

  const onRemove = () => {
    onEdit({
      ...row.original,
      gcode: {},
    });
  };

  const onUpload: OnGCodeUpload = (gcode) => {
    onEdit({
      ...row.original,
      gcode,
    });
  };

  const disabled = !row.original.weight;
  const isPrintTransferJob =
    row.original.step === jobSteps.print &&
    row.original.type === jobTypeOptions.transfer;

  return (
    <Conditional condition={isPrintTransferJob}>
      <GCodeDetails
        disabled={disabled}
        forceOriginalFileName
        gcode={gcode}
        hasError={hasError}
        isEditing={isEditingRow}
        partName={partName}
        uploadPath={path}
        weight={row.original.weight}
        onRemove={onRemove}
        onUpload={onUpload}
      />
    </Conditional>
  );
};
