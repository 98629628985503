import { Button, Conditional } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { completeSandJobModalAtoms } from '../../../complete-sand-job-modal.atoms';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { CompleteChecklistButtonWrapper } from '../../../../../common/job-checklist/components/complete-checklist-button-wrapper';
import { CompleteChecklistFooterProps } from './complete-checklist-footer.types';
import { checkAgainstChecklistItems } from '../../../../../helpers/check-against-checklist-items';
import { useCompleteJob } from '../../../../../../../../../api/jobs/routes/complete-job';

export const CompleteChecklistFooter = (
  props: CompleteChecklistFooterProps,
) => {
  const { handleCloseModal, job } = props;

  const setNextJob = useSetRecoilState(completeSandJobModalAtoms.nextJob);
  const checklist = useRecoilValue(completeSandJobModalAtoms.checklist);
  const isChecklistReviewed = useRecoilValue(
    completeSandJobModalAtoms.isChecklistReviewed,
  );
  const setStep = useSetRecoilState(completeSandJobModalAtoms.step);
  const setUpdateOnClose = useSetRecoilState(
    completeSandJobModalAtoms.updateOnClose,
  );

  const isChecklistFailing = checkAgainstChecklistItems(
    checklist,
    ({ status }) => {
      return status === 'Fail';
    },
  );

  const { isLoading, onCompleteJob } = useCompleteJob({
    onSuccess: async ({ nextJob }) => {
      setNextJob(nextJob);
      setUpdateOnClose(true);
      await handleCloseModal({ updateOnClose: true });
    },
  });

  const handleCompleteJob = async () => {
    await onCompleteJob({
      jobId: job.id,
    });
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <CompleteChecklistButtonWrapper checklist={checklist}>
        <Conditional
          condition={isChecklistFailing}
          Fallback={
            <Button
              disabled={!isChecklistReviewed}
              processing={isLoading}
              size={fullScreenModalFooterButtonSize}
              text={`Confirm ${job.type} Completion`}
              onClick={handleCompleteJob}
            />
          }
        >
          <Button
            disabled={!isChecklistReviewed}
            size={fullScreenModalFooterButtonSize}
            text="Confirm Fail"
            variant="primaryAlert"
            onClick={() => {
              setStep('RECOMMENDED_NEXT_STEP');
            }}
          />
        </Conditional>
      </CompleteChecklistButtonWrapper>
    </>
  );
};
