import { FC } from 'react';
import { Flex, Typography } from 'gantri-components';
import {
  StyledSummaryFieldLabelWrapper,
  StyledSummaryFieldValueWrapper,
  StyledSummarySpacer,
  StyledUserSummaryBorder,
  StyledUserSummarySectionContainer,
} from '../../../shared.styles';
import { Creation } from '../../../../../api/users/users.types';

export const CreationSection: FC<{ accountCreationDetails: Creation }> = ({
  accountCreationDetails,
}) => {
  const acountCreationDate = accountCreationDetails.created || '';

  return (
    <StyledUserSummaryBorder>
      <StyledUserSummarySectionContainer>
        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="Created" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            <Typography color="t1" text={acountCreationDate} />
          </StyledSummaryFieldValueWrapper>
        </Flex>
        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="IP Address" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            <Typography color="t1" text={accountCreationDetails.ip} />
          </StyledSummaryFieldValueWrapper>
        </Flex>
        <StyledSummarySpacer />
        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="Source" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            <Typography color="t1" text={accountCreationDetails.source} />
          </StyledSummaryFieldValueWrapper>
        </Flex>
        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="User Agent" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            <Typography color="t1" text={accountCreationDetails.userAgent} />
          </StyledSummaryFieldValueWrapper>
        </Flex>
        <StyledSummarySpacer />

        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="UTM Medium" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            <Typography color="t2" text={accountCreationDetails.utm_campaign} />
          </StyledSummaryFieldValueWrapper>
        </Flex>

        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="UTM Source" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            <Typography color="t1" text={accountCreationDetails.utm_source} />
          </StyledSummaryFieldValueWrapper>
        </Flex>

        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="UTM Campaign" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            <Typography color="t2" text={accountCreationDetails.utm_campaign} />
          </StyledSummaryFieldValueWrapper>
        </Flex>

        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="Sign Up Source" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            <Typography
              color="t2"
              text={accountCreationDetails.signUpEventName}
            />
          </StyledSummaryFieldValueWrapper>
        </Flex>
      </StyledUserSummarySectionContainer>
    </StyledUserSummaryBorder>
  );
};
