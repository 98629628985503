import {
  Button,
  Cell,
  Tab,
  TabPanel,
  Tabs,
  TabsList,
  Tooltip,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import MetaData from '../../components/meta-data';
import { PageHeading } from '../../components/layout/page-heading';
import { useSyncPropertyWithQueryString } from '../../hooks/useSyncPropertyWithQueryString';
import { StyledTabListGrid } from './product.styles';
import { Assets, Costs, Info, ProductJobs } from './components';
import { ViewHistoryButton } from './components/view-history-button';
import { ProductFetchType } from '../../api/products/routes/get-product/get-product.types';
import { productAtoms } from './product.atoms';
import { useResetRecoilAtoms } from '../../hooks/use-reset-recoil-atoms';
import { useFetchProductData } from './hooks/use-fetch-product-data';
import { useGetProductIdFromParams } from './hooks/use-get-product-id-from-param';
import { productJobsTabAtoms } from './components/product-jobs/product-jobs.atoms';
import { PageHeadingProps } from '../../components/layout/page-heading/page-heading.types';
import { useGetVersionDetails } from './components/product-jobs/hooks/use-get-version-details';
import { productFetchTypes } from '../../api/products/routes';
import { ReviewAssetsButton } from './components/assets/components/review-assets-button';
import { productQueryKeys } from './product.constants';

export const Product = () => {
  const productId = useGetProductIdFromParams();

  const product = useRecoilValue(productAtoms.product);

  const resetProductAtoms = useResetRecoilAtoms(productAtoms);
  const resetProductJobsTabAtoms = useResetRecoilAtoms(productJobsTabAtoms);

  const { currentValue: tabQuery, updateQueryString: setTabQuery } =
    useSyncPropertyWithQueryString<ProductFetchType>(
      productQueryKeys.tab,
      productFetchTypes.info,
    );

  const { processing, updateProduct } = useFetchProductData({
    autoFetch: true,
  });

  const subTitleProps: Partial<PageHeadingProps> = product?.designer
    ? {
        subTitle: product.designer?.name,
        subTitleProps: {
          color: 'link',
          style: { textDecoration: 'underline' },
          to: product.designer?.id
            ? `/designers/${product.designer?.id}`
            : undefined,
        },
      }
    : {};

  const { isFirstVersionDraft } = useGetVersionDetails();

  useEffect(() => {
    return () => {
      resetProductAtoms();
      resetProductJobsTabAtoms();
    };
  }, []);

  return product ? (
    <>
      <MetaData title={`Product #${productId}`} />
      <PageHeading {...subTitleProps} title={product.name} />
      <Tabs value={tabQuery}>
        <StyledTabListGrid alignItems="center" columns="1fr max-content">
          <Cell paddingTop="1.2rem">
            <TabsList
              hasBorder={false}
              onValueChange={(value: ProductFetchType) => {
                setTabQuery(value);
              }}
            >
              <Tab label="Info" value={productFetchTypes.info} />
              <Tab label="Jobs" value={productFetchTypes.jobs} />
              <Tooltip
                description="Assets are only shown for the published version."
                position="top"
              >
                <Tab
                  disabled={isFirstVersionDraft}
                  label="Assets"
                  value={productFetchTypes.assets}
                />
              </Tooltip>
              <Tooltip
                description="Costs are only shown for the published version."
                position="top"
              >
                <Tab
                  disabled={isFirstVersionDraft}
                  label="Costs"
                  value={productFetchTypes.costs}
                />
              </Tooltip>
            </TabsList>
          </Cell>
          <TabPanel style={{ paddingTop: '0' }} value={productFetchTypes.info}>
            <Button
              processing={processing}
              text="Update"
              onClick={async () => {
                await updateProduct();
              }}
            />
          </TabPanel>
          <TabPanel style={{ paddingTop: '0' }} value={productFetchTypes.jobs}>
            <ViewHistoryButton />
          </TabPanel>
          <TabPanel
            style={{ paddingTop: '0' }}
            value={productFetchTypes.assets}
          >
            <ReviewAssetsButton />
          </TabPanel>
        </StyledTabListGrid>

        <TabPanel value={productFetchTypes.info}>
          <Info />
        </TabPanel>
        <TabPanel value={productFetchTypes.jobs}>
          <ProductJobs />
        </TabPanel>
        <TabPanel value={productFetchTypes.assets}>
          <Assets />
        </TabPanel>
        <TabPanel value={productFetchTypes.costs}>
          <Costs />
        </TabPanel>
      </Tabs>
    </>
  ) : null;
};
