import { atom } from 'recoil';
import { PressRecordModalAction } from './press-record-modal.types';
import { PressRecord } from '../../../../api/press/press.types';

const atomKeyPrefix = 'PRESS_RECORD_MODAL';

export const pressRecordModalAtoms = {
  modalAction: atom<PressRecordModalAction>({
    default: 'Add',
    key: `${atomKeyPrefix}-modal-action`,
  }),
  pressRecord: atom<PressRecord>({
    default: {
      id: undefined,
      link: '',
      publishDate: '',
      source: '',
      title: '',
      type: undefined,
    },
    key: `${atomKeyPrefix}-press-record`,
  }),
};
