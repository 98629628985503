import { useGenericMutateQuery } from '../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { useInvalidateGetPaginatedPressRecordsCache } from '../get-paginated-press-records';
import { deletePressRecord } from './delete-press-record';
import {
  DeletePressRecordArgs,
  DeletePressRecordResponse,
} from './delete-press-record.types';

export const useDeletePressRecordMutation = <
  TransformedData = DeletePressRecordResponse,
>(
  props?: GenericMutateQueryProps<
    DeletePressRecordArgs,
    DeletePressRecordResponse,
    TransformedData
  >,
) => {
  const { invalidateGetPaginatedPressRecordsCache } =
    useInvalidateGetPaginatedPressRecordsCache();

  const { onMutate: onDeletePressRecord, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to delete press record.',
    mutationFn: deletePressRecord,
    ...props,
    onSuccess: async (data, args) => {
      await props?.onSuccess(data, args);

      await invalidateGetPaginatedPressRecordsCache();
    },
  });

  return { ...rest, onDeletePressRecord };
};
