import { TransactionResponse } from '../../transactions.types';
import { GetTransactionArgs } from './get-transaction.types';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { getTransaction } from './get-transaction';

const fetchOrderQueriesObj = getGenericQueryObj({
  groupName: 'fetch-order',
  query: getTransaction,
});

export const useFetchOrder = <TransformedData = TransactionResponse>(
  props?: GenericFetchProps<
    GetTransactionArgs,
    TransactionResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch order.',
    queryObj: fetchOrderQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchOrderCache = () => {
  const invalidateFetchOrderCache =
    useGetInvalidateQueryCache(fetchOrderQueriesObj);

  return { invalidateFetchOrderCache };
};
