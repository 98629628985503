import { useRecoilValue } from 'recoil';
import { discardStockModalAtoms } from '../../discard-stock-modal.atoms';
import { discardStockModalSteps } from '../../discard-stock-modal.constants';
import { NotifyCustomerHeader } from '../step-notify-customer/notify-customer-header';
import { ConfirmDiscardHeader } from '../step-confirm-discard/confirm-discard-header';

export const DiscardStockModalHeader = () => {
  const step = useRecoilValue(discardStockModalAtoms.step);

  switch (step) {
    case discardStockModalSteps.confirmDiscard:
      return <ConfirmDiscardHeader />;

    case discardStockModalSteps.notifyCustomer:
      return <NotifyCustomerHeader />;

    default:
      return null;
  }
};
