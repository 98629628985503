import * as yup from 'yup';
import axios from 'axios';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import {
  Button,
  Conditional,
  Flex,
  FormikInput,
  Icon,
  Stack,
  Typography,
} from 'gantri-components';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { batchApi } from '../../../../../../api';
import { useNotification } from '../../../../../../hooks/useNotification';
import {
  AddPartToBatchFormikProps,
  AddPartToBatchFormProps,
} from './add-part-to-batch-form.types';
import { batchAtoms } from '../../../../batch.atoms';

export const AddPartToBatchForm = (props: AddPartToBatchFormProps) => {
  const { batch, setBatch } = props;

  const [isAddingPart, setIsAddingPart] = useRecoilState(
    batchAtoms.isAddingPart,
  );
  const [errorText, setErrorText] = useState<string>('');

  const { notify, notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const completeAddPart = async (
    { partId }: AddPartToBatchFormikProps,
    { resetForm }: FormikHelpers<AddPartToBatchFormikProps>,
  ) => {
    await onInterceptProcessingRequest(async () => {
      try {
        const { data } = await batchApi.addPartToBatch({
          batchId: batch.id,
          partId: Number(partId),
          returnBatch: true,
        });

        notify(data.notice);

        setBatch(data.batch);

        resetForm();

        const partIdInput: HTMLInputElement = document.querySelector(
          'input[name="partId"]',
        );

        partIdInput?.focus?.();

        if (errorText) {
          setErrorText('');
        }
      } catch (error: unknown) {
        const errorText = axios.isAxiosError(error)
          ? // @ts-expect-error // provided by back-end as banner errors do not match form errors
            error.response.data?.errorData?.formMessage
          : undefined;

        if (errorText) {
          setErrorText(errorText);
        }

        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to add part.',
        });
      }
    });
  };

  useEffect(() => {
    if (!isAddingPart && errorText) {
      setErrorText('');
    }
  }, [isAddingPart]);

  return (
    <Formik
      enableReinitialize
      initialValues={{ partId: '' }}
      validationSchema={yup.object().shape({
        partId: yup.string().required('Part ID is a required field.'),
      })}
      onSubmit={completeAddPart}
    >
      {({
        setFieldValue,
        values: { partId },
      }: FormikProps<AddPartToBatchFormikProps>) => {
        const disableSaveBtn = !partId;

        return (
          <Form>
            <Stack gap="0">
              <Flex gap=".5x">
                <FormikInput
                  autoFocus
                  fieldVariant="standard"
                  name="partId"
                  placeholder="Enter Part ID"
                  rightIcon={
                    <Conditional condition={!!partId}>
                      <Icon
                        cursor="pointer"
                        name="ui-control:x"
                        onClick={async () => {
                          await setFieldValue('partId', '');
                        }}
                      />
                    </Conditional>
                  }
                  type="number"
                />
                <Button
                  disabled={disableSaveBtn}
                  icon={
                    <Icon
                      color={disableSaveBtn ? 't1' : 'white'}
                      name="ui-control:plus"
                    />
                  }
                  processing={processing}
                  text="Add Part"
                  type="submit"
                  variant="primary"
                />
                <Button
                  disabled={processing}
                  text="Cancel"
                  variant="secondary"
                  onClick={() => {
                    setIsAddingPart(false);
                  }}
                />
              </Flex>
              <Conditional condition={!!errorText}>
                <Typography marginTop="x" text={errorText} textStyle="bold" />
                <Typography text="Please try another ID number." />
              </Conditional>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};
