import { useRecoilValue } from 'recoil';
import { ProductSkuLevelFileType } from '../../../../../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';
import { bulkUploadModalAtoms } from '../../../../bulk-upload-modal.atoms';
import { productFileTypesMaxQty } from '../../../../bulk-upload-modal.constants';

export const useGetIsInvalidQty = () => {
  const assetsBySku = useRecoilValue(bulkUploadModalAtoms.assetsBySku);

  const getIsInvalidQty = (props: {
    fileType: ProductSkuLevelFileType;
    skus: string[];
  }) => {
    const { fileType, skus } = props;

    const skusWithValidQty: string[] = [];

    const isInvalidQty = skus
      .map((sku) => {
        const existingAssets = assetsBySku[sku][fileType];

        const isArray = Array.isArray(existingAssets);

        if (isArray) {
          if (existingAssets.length >= productFileTypesMaxQty[fileType]) {
            return true;
          }
        } else if (existingAssets) {
          return true;
        }

        skusWithValidQty.push(sku);

        return false;
      })
      .every(Boolean);

    return { isInvalidQty, skusWithValidQty };
  };

  return getIsInvalidQty;
};
