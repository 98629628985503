import { Dropdown } from 'gantri-components';
import { useSelectProductFormOptions } from '../../hooks/use-select-product-form-options';
import { VariantDropdownProps } from './variant-dropdown.types';

export const VariantDropdowns = (props: VariantDropdownProps) => {
  const {
    getOnVariantSelect,
    productId,
    variantPartialDetails,
    ...dropdownProps
  } = props;

  const { variantOptions } = useSelectProductFormOptions({
    productId,
  });

  return (
    <>
      {variantOptions?.map((variantSelectors, variantIndex) => {
        return variantSelectors.map(({ label, options }, partialIndex) => {
          const key = `${label}-${variantIndex}-${partialIndex}`;

          return (
            <Dropdown
              key={key}
              idProperty="code"
              items={options}
              labelProperty="name"
              labelText={label}
              placeholder={`Select ${label}`}
              required
              value={
                variantPartialDetails?.[variantIndex]?.[partialIndex]?.code
              }
              onSelect={getOnVariantSelect({
                partialIndex,
                variantIndex,
              })}
              {...dropdownProps}
            />
          );
        });
      })}
    </>
  );
};
