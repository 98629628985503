import { Cell, CellContext, ColumnDef } from '@tanstack/react-table';
import { Conditional, Flex, Typography } from 'gantri-components';
import { DependencyList, useMemo } from 'react';
import { Job } from '../../../../../../api/jobs/jobs.types';
import { CellJobType } from '../../components/cell-job-type';
import { CellJobName } from '../../components/cell-job-name';
import { CellJobStep } from '../../components/cell-job-step';
import { CellJobDuration } from '../../components/cell-job-duration';
import { CellJobInstruction } from '../../components/cell-job-instruction';
import { CellJobMachine } from '../../components/cell-job-machine';
import { CellJobMachineType } from '../../components/cell-job-machine-type';
import { CellJobInventoryRequests } from '../../components/cell-job-inventory-requests';
import { CellJobCreatedAt } from '../../components/cell-job-created-at';
import { CellJobStartDate } from '../../components/cell-job-start-date';
import { CellJobEndDate } from '../../components/cell-job-end-date';
import { CellJobStatus } from '../../components/cell-job-status';
import { CellJobAttempt } from '../../components/cell-job-attempt';
import { JobMenu } from '../../../../../../components/common/jobMenu/jobMenu';
import { JobActionCell } from '../../../../../jobs/components';
import { downloadToastText } from '../../../../../../hooks/use-download-with-small-toast/use-download-with-small-toast.constants';
import { useDownloadWithSmallToast } from '../../../../../../hooks/use-download-with-small-toast';
import GcodeDataCell from '../../../../../../components/common/custom-data-cells/gcode-data-cell';

export const useGetPartPageJobTableColumns = (
  props: {
    completeJob: (jobId: number) => Promise<void>;
    hideJobMenu: boolean;
    onRefresh: () => Promise<void>;
    startJob: (jobId: number) => Promise<void>;
  },
  dependencies: DependencyList,
) => {
  const { completeJob, hideJobMenu, onRefresh, startJob } = props;

  const {
    DownloadStatusToast: DownloadGcodeStatusToast,
    downloadSingleFile: downloadGcode,
  } = useDownloadWithSmallToast({ ...downloadToastText.gcode });

  const {
    DownloadStatusToast: DownloadInstructionStatusToast,
    downloadSingleFile: downloadInstruction,
  } = useDownloadWithSmallToast({ ...downloadToastText.instruction });

  const columns: ColumnDef<Job>[] = useMemo(() => {
    return [
      {
        accessorKey: 'type',
        cell: CellJobType,
        header: 'Type',
        meta: {
          hideable: false,
          reorderable: false,
        },
      },
      {
        accessorKey: 'description',
        cell: CellJobName,
        header: 'Name',
        meta: {
          hideable: false,
          reorderable: false,
        },
      },
      {
        accessorKey: 'attempt',
        cell: ({ getValue }: CellContext<Job, Job['attempt']>) => {
          return <CellJobAttempt attempt={getValue()} />;
        },
        header: 'Atmpt',
        meta: { hideable: false },
        size: 60,
      },
      {
        accessorKey: 'step',
        cell: CellJobStep,
        header: 'Step',
        size: 100,
      },
      {
        accessorKey: 'duration',
        cell: CellJobDuration,
        header: 'Dur (m)',
        size: 80,
      },
      {
        accessorKey: 'instruction',
        cell: CellJobInstruction,
        header: 'Instructions',
        minSize: 280,
      },
      {
        accessorKey: 'machine',
        cell: CellJobMachine,
        header: 'Machine',
        meta: {
          getIsClickable: ({ getValue }: Cell<Job, Job['machine']>) => {
            return !!getValue()?.id;
          },
        },
        minSize: 140,
      },
      {
        accessorKey: 'gcode',
        cell: ({ getValue }: CellContext<Job, Job['gcode']>) => {
          return <GcodeDataCell gcode={getValue()} />;
        },
        header: 'G-code',
        meta: {
          getOnClick: ({ getValue }: Cell<Job, Job['gcode']>) => {
            const gcode = getValue();
            const isClickable = !!gcode?.url;

            return isClickable
              ? () => {
                  return downloadGcode({
                    fileName: gcode?.fileName,
                    url: gcode?.url,
                  });
                }
              : undefined;
          },
        },
        minSize: 280,
      },
      {
        accessorKey: 'weight',
        cell: ({ getValue }: CellContext<Job, Job['weight']>) => {
          const weight = getValue();

          return <Typography text={weight && `${weight} g`} />;
        },
        header: 'Weight',
        size: 60,
      },
      {
        accessorKey: 'machineType',
        cell: CellJobMachineType,
        header: 'Machine Type',
        size: 140,
      },
      {
        accessorKey: 'inventoryRequests',
        cell: CellJobInventoryRequests,
        header: 'Inventories',
        minSize: 280,
      },
      {
        accessorKey: 'createdAt',
        cell: CellJobCreatedAt,
        header: 'Created',
        meta: { hideable: false },
      },
      {
        accessorKey: 'startDate',
        cell: CellJobStartDate,
        header: 'Started',
        meta: { hideable: false },
        size: 80,
      },
      {
        accessorKey: 'endDate',
        cell: CellJobEndDate,
        header: 'Ended',
        meta: { hideable: false },
        size: 80,
      },
      {
        accessorKey: 'status',
        cell: CellJobStatus,
        header: 'Status',
        meta: { hideable: false },
        size: 140,
      },
      {
        cell: (props: CellContext<Job, never>) => {
          return (
            <Conditional condition={!hideJobMenu}>
              <Flex alignItems="center" gap="2x" justifyContent="flex-end">
                <JobActionCell
                  {...props}
                  onComplete={completeJob}
                  onStart={startJob}
                />
                <JobMenu job={props.row.original} onRefresh={onRefresh} />
              </Flex>
            </Conditional>
          );
        },
        header: '',
        id: 'actions',
        meta: {
          hideable: false,
          label: 'Actions',
          reorderable: false,
        },
        size: 30,
      },
    ];
  }, dependencies);

  return { DownloadGcodeStatusToast, DownloadInstructionStatusToast, columns };
};
