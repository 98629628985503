import { atom } from 'recoil';
import { PublishDraftModalStep } from './publish-draft-modal.types';

export const atomKeyPrefix = 'PUBLISH_DRAFT_MODAL';

export const publishDraftModalAtoms = {
  draftDetails: atom<string>({
    default: '',
    key: `${atomKeyPrefix}-draft-details`,
  }),
  isWithoutAssembleStandardInventory: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-without-assemble-standard-inventory`,
  }),
  isWithoutAssemblyInstructions: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-without-assembly-instructions`,
  }),
  isWithoutPackInventory: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-without-pack-inventory`,
  }),
  isWithoutParts: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-without-parts`,
  }),
  numAffectedStocks: atom<number>({
    default: 0,
    key: `${atomKeyPrefix}-num-affected-stocks`,
  }),
  step: atom<PublishDraftModalStep>({
    default: null,
    key: `${atomKeyPrefix}-step`,
  }),
};
