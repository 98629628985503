import {
  Conditional,
  Flex,
  Icon,
  Stack,
  Tooltip,
  Typography,
} from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import {
  stocksFiltersDefaults,
  stocksPageAtoms,
} from '../../components/dropdowns/stocks-filter/stocks-filter.atoms';
import { FetchPaginatedStocksArgs } from '../../api/stocks/routes/fetch-paginated-stocks/fetch-paginated-stocks.types';
import { ModalFiltersDetail } from '../../components/common/table/hooks';
import { StyledStockWithPriority, StyledVLMTooltip } from './stocks.styles';
import { formatDate } from '../../helpers/formatter';
import { MEDIUM_FORMAT } from '../../constants/dates';
import { TableHeaderWithInfoToolTip } from '../../components/common/custom-data-cells/headers';
import { Stock } from '../../api/stocks/stocks.types';
import { AttentionGridCell } from '../../components/layout/attention-grid-cell';
import { StyledAnchor } from '../../components/common/styled-anchor';
import routePaths from '../../config/route-paths';
import { getIsClickableIfValue } from '../../helpers/get-is-clickable-if-value';
import { ShortProductSummary } from '../../components/common/short-product-summary';
import { StockTotalCompletionJobs } from './components/stock-total-completion-jobs';

export const modalFiltersDetails: ModalFiltersDetail<FetchPaginatedStocksArgs>[] =
  [
    {
      atom: stocksPageAtoms.filters.assigned,
      defaultValue: stocksFiltersDefaults.filters.assigned,
      payloadKey: 'assigned',
    },
    {
      atom: stocksPageAtoms.filters.attention,
      defaultValue: stocksFiltersDefaults.filters.attention,
      payloadKey: 'attention',
    },
    {
      atom: stocksPageAtoms.filters.color,
      defaultValue: stocksFiltersDefaults.filters.color,
      payloadKey: 'color',
    },
    {
      atom: stocksPageAtoms.filters.orderId,
      defaultValue: stocksFiltersDefaults.filters.orderId,
      payloadKey: 'orderId',
    },
    {
      atom: stocksPageAtoms.filters.highPriority,
      defaultValue: stocksFiltersDefaults.filters.highPriority,
      payloadKey: 'highPriority',
    },
    {
      atom: stocksPageAtoms.filters.productId,
      defaultValue: stocksFiltersDefaults.filters.productId,
      payloadKey: 'productId',
    },
    {
      atom: stocksPageAtoms.filters.size,
      defaultValue: stocksFiltersDefaults.filters.size,
      payloadKey: 'size',
    },
    {
      atom: stocksPageAtoms.sortBy,
      defaultValue: stocksFiltersDefaults.filters.sortBy,
      payloadKey: 'sortBy',
    },
    {
      atom: stocksPageAtoms.filters.statuses,
      defaultValue: stocksFiltersDefaults.filters.statuses,
      payloadKey: 'statuses',
    },
  ];

export const columns: ColumnDef<Stock>[] = [
  {
    accessorKey: 'id',
    cell: ({
      getValue,
      row: { original },
    }: CellContext<Stock, Stock['id']>) => {
      const id = getValue();

      return (
        <StyledStockWithPriority>
          <StyledAnchor href={`${routePaths.stocks}/${id}`} text={`#${id}`} />
          {original.highPriority && (
            <Tooltip
              description="High-priority stock"
              overlayContainerStyles={{
                position: 'relative',
                zIndex: 1,
              }}
            >
              <Icon color="t2" name="arrows:arrow_triangle_up" />
            </Tooltip>
          )}
        </StyledStockWithPriority>
      );
    },
    header: 'Stock #',
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
    size: 100,
  },
  {
    accessorKey: 'createdAt',
    cell: ({ getValue }: CellContext<Stock, Stock['createdAt']>) => {
      return formatDate(getValue(), MEDIUM_FORMAT);
    },
    header: 'Created',
    size: 80,
  },
  {
    accessorKey: 'completedBy',
    cell: ({ getValue }: CellContext<Stock, Stock['completedBy']>) => {
      return formatDate(getValue(), MEDIUM_FORMAT);
    },
    header: 'Complete by',
    size: 120,
  },
  {
    accessorKey: 'completedAt',
    cell: ({ getValue }: CellContext<Stock, Stock['completedAt']>) => {
      const completedAtSec = getValue();

      return completedAtSec ? formatDate(completedAtSec, MEDIUM_FORMAT) : 'TBD';
    },
    header: 'Completed',
    size: 80,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    size: 100,
  },
  {
    accessorKey: 'totalCompletionJobs',
    cell: ({ getValue }: CellContext<Stock, Stock['totalCompletionJobs']>) => {
      const totalCompletionJobs = getValue();

      return (
        <StockTotalCompletionJobs totalCompletionJobs={totalCompletionJobs} />
      );
    },
    header: 'Completion',
    size: 120,
  },
  {
    accessorKey: 'stockYield',
    cell: ({ getValue }: CellContext<Stock, Stock['stockYield']>) => {
      return (
        <Typography
          text={String(getValue() ?? '').replace(/(\d+)%?$/g, '$1%')}
        />
      );
    },
    header: () => {
      return (
        <TableHeaderWithInfoToolTip
          headerText="Yield"
          tooltipText="# of completed jobs / (# of completed jobs + # of failed jobs)"
        />
      );
    },
    size: 80,
  },
  {
    accessorKey: 'product',
    cell: ({ getValue }: CellContext<Stock, Stock['product']>) => {
      return <ShortProductSummary {...getValue()} />;
    },
    header: 'Item',
    size: 300,
  },
  {
    accessorKey: 'location',
    cell: ({ getValue }: CellContext<Stock, Stock['location']>) => {
      const location = getValue();

      return location ? (
        <Stack gap="0.3rem">
          <Typography text={location.rack} />
          <Typography text={location.shelf} />
          <Flex gap="0.5rem">
            <Typography text={location.bin} />
            <Tooltip
              Component={<StyledVLMTooltip>{location.name}</StyledVLMTooltip>}
            >
              <Icon color="t1" name="alert:i_circle" />
            </Tooltip>
          </Flex>
        </Stack>
      ) : (
        <Typography text="Unassigned" />
      );
    },
    header: 'VLM Assignment',
    size: 140,
  },
  {
    accessorKey: 'orderId',
    cell: ({
      getValue,
      row: { original },
    }: CellContext<Stock, Stock['orderId']>) => {
      const { orderType } = original;
      const orderId = getValue();

      return (
        <Conditional condition={!!orderId}>
          <StyledAnchor
            href={`${routePaths.orders}/${orderId}`}
            text={`${orderType ?? ''} #${orderId}`}
          />
        </Conditional>
      );
    },
    header: 'Order #',
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
    size: 100,
  },
  {
    accessorKey: 'attentions',
    cell: ({ getValue }: CellContext<Stock, Stock['attentions']>) => {
      return <AttentionGridCell attentions={getValue()} />;
    },
    header: 'Attention',
  },
];
