import { useRecoilValue } from 'recoil';
import { updatePartBatchModalAtoms } from '../../update-part-batch-modal.atoms';
import { BatchUpdateErrorContent } from '../batch-update-error-content/batch-update-error-content';
import { BatchCreationSuccessContent } from '../step-batch-creation-success/batch-creation-success-content';
import { PartAddedSuccessContent } from '../step-part-added-success/part-added-success-content';
import { PartRemovedSuccessContent } from '../step-part-removed-success/part-added-success-content';
import { UpdateBatchContent } from '../step-update-batch/update-batch-content';

export const UpdatePartBatchModalContent = ({
  currentBatchId,
}: {
  currentBatchId: number;
}) => {
  const step = useRecoilValue(updatePartBatchModalAtoms.step);

  switch (step) {
    case 'UPDATE_BATCH':
      return (
        <UpdateBatchContent
          atomActiveBatches={updatePartBatchModalAtoms.activeBatches}
          atomBatchUpdateMethod={updatePartBatchModalAtoms.batchUpdateMethod}
          atomNewBatchId={updatePartBatchModalAtoms.newBatchId}
          currentBatchId={currentBatchId}
          minHeight="17.2rem"
          showRemoveOption={!!currentBatchId}
        />
      );

    case 'BATCH_CREATION_SUCCESS':
      return <BatchCreationSuccessContent />;

    case 'PART_ADDED_SUCCESS':
      return <PartAddedSuccessContent />;

    case 'PART_REMOVED_SUCCESS':
      return <PartRemovedSuccessContent />;

    case 'BATCH_CREATION_ERROR':
    case 'PART_ADDED_ERROR':
    case 'PART_REMOVED_ERROR':
      return <BatchUpdateErrorContent />;

    default:
      return null;
  }
};
