import { Conditional, Flex, Icon, Tooltip } from 'gantri-components';
import { FC } from 'react';
import { partStatuses } from '../../../../../../constants/options';
import { PartStatusBadge } from '../../../../../../components/common/part-status-badge';
import { CellPartStatusProps } from './cell-part-status.types';

export const CellPartStatus: FC<CellPartStatusProps> = (props) => {
  const { getValue } = props;

  const value = getValue();

  return (
    <Conditional
      condition={value === partStatuses.discarded}
      Fallback={<PartStatusBadge status={value} />}
    >
      <Flex alignItems="center" gap="0.8rem">
        <PartStatusBadge status={value} />
        <Tooltip
          description="Due to the stock being cancelled, this part now has a Discarded status. Please ensure that the part is discarded."
          overlayContainerStyles={{ display: 'flex' }}
          position="top"
        >
          <Icon name="alert:i_circle" />
        </Tooltip>
      </Flex>
    </Conditional>
  );
};
