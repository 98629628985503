import { ChangeEvent, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, TextField, useConfirmationModal } from 'gantri-components';
import {
  StyledDesignerFeatureContainer,
  StyledHeaderButtonContainer,
} from './designer-feature.styles';
import { PageHeading } from '../../components/layout/page-heading';
import MetaData from '../../components/meta-data';
import { useNotification } from '../../hooks/useNotification';
import { environment } from '../../environment';
import {
  breakCrumbData,
  currentFeatureInit,
  FeatureReducer,
  validationFields,
} from './designer-feature.constants';
import { FeatureParams } from './desginer-feature.types';
import {
  GlobalSettings,
  IntroSection,
  LifestyleImagery,
  MeetCollection,
  PageColors,
  PageHeader,
  ProductIncluded,
  StorefrontSettings,
  ValueProps,
} from './components';
import { useGetInvalidateQueryCache } from '../../hooks/use-fetch/use-query-fetch';
import {
  getDesigner,
  getDesignerFeatureSettings,
} from '../../api/designers/routes';
import { useGetDesignerFeatureSettings } from '../../api/designers/routes/get-designer-feature-settings/get-designer-feature-settings.query';
import { useUpdateFeatureSettings } from '../../api/designers/routes/update-feature-settings/update-feature-settings.query';
import routePaths from '../../config/route-paths';

export const DesignerFeature = () => {
  const [currentFeature, updateFeatureInfo] = useReducer(
    FeatureReducer,
    currentFeatureInit,
  );

  const { notify, notifyError } = useNotification();

  const [editName, setEditName] = useState<string>('');
  const [requiredFields, setRequiredFields] = useState(validationFields);

  const { designerId: userId, featureId } = useParams<FeatureParams>();

  breakCrumbData[0].path = `${routePaths.designers}/${userId}?tab=storefront`;

  const onEditNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditName(event.target.value);
  };

  const [showEditNameModal, hideEditNameModal] = useConfirmationModal({
    confirmButtonIsDisabled: !editName,
    content: (
      <TextField
        debounce={500}
        labelPosition="top"
        labelText="Edit name"
        placeholder="Title..."
        value={editName}
        onChange={onEditNameChange}
      />
    ),
    headerText: 'Edit name',
    onClose: () => {
      hideEditNameModal();
      setEditName('');
    },
    onConfirm: async () => {
      hideEditNameModal();
      updateFeatureInfo({ key: 'name', type: 'key', value: editName });
      setEditName('');
    },
    width: { lg: '40rem', md: 'auto' },
  });

  const validateFields = () => {
    let requiredUpdate = false;
    const error = validationFields;
    const { valueProps } = currentFeature;

    if (valueProps.length > 0) {
      error.valueProps = Array(valueProps.length).fill({});
      valueProps.forEach((value, index) => {
        const { title } = value;

        error.valueProps[index] = {
          title: { error: !title },
        };

        if (!requiredUpdate) {
          requiredUpdate = !title;
        }
      });
    }

    setRequiredFields(error);

    return requiredUpdate;
  };

  const onInvalidateQueryCaches = async () => {
    await invalidateDesignerQueryCache();
    await invalidateDesignerFeatureSettingsQueryCache();
  };

  const { isLoading: processing, onUpdateFeatureSetting } =
    useUpdateFeatureSettings();

  const updateFeaturePageSettings = async () => {
    const hasErrors = validateFields();

    if (!hasErrors) {
      await onUpdateFeatureSetting(
        { settings: currentFeature },
        {
          onSuccess: onInvalidateQueryCaches,
        },
      );
    } else {
      notifyError('Required field missing.');
    }
  };

  const viewFeaturePage = () => {
    window.open(
      `${environment.SHOP_URL}/${currentFeature.profileLink}/${currentFeature.pageUrl}`,
      '_blank',
    );
  };

  const { data, isLoading } = useGetDesignerFeatureSettings({
    args: {
      featureId,
      userId,
    },
  });

  const invalidateDesignerQueryCache = useGetInvalidateQueryCache(getDesigner);
  const invalidateDesignerFeatureSettingsQueryCache =
    useGetInvalidateQueryCache(getDesignerFeatureSettings);

  useEffect(() => {
    if (!isLoading) {
      updateFeatureInfo({ type: 'state', value: data });
    }
  }, [isLoading, data]);

  return (
    <StyledDesignerFeatureContainer>
      <>
        <MetaData title="Designer's Feature Settings" />
        <PageHeading breadcrumbs={breakCrumbData} title={currentFeature.name}>
          <StyledHeaderButtonContainer>
            <Button
              disabled={currentFeature.status !== 'published'}
              text="View page"
              variant="secondary"
              onClick={viewFeaturePage}
            />
            <Button
              text="Edit name"
              variant="secondary"
              onClick={showEditNameModal}
            />
            <Button
              processing={processing}
              text="Save changes"
              onClick={updateFeaturePageSettings}
            />
          </StyledHeaderButtonContainer>
        </PageHeading>
        <GlobalSettings
          notify={notify}
          pageUrl={currentFeature.pageUrl}
          status={currentFeature.status}
          updateFeatureInfo={updateFeatureInfo}
        />
        <StorefrontSettings
          feature={currentFeature.feature}
          id={currentFeature.id}
          updateFeatureInfo={updateFeatureInfo}
          userId={Number(userId)}
        />
        <PageColors
          primaryBackground={currentFeature.primaryBackground}
          primaryText={currentFeature.primaryText}
          secondaryBackground={currentFeature.secondaryBackground}
          secondaryText={currentFeature.secondaryText}
          updateFeatureInfo={updateFeatureInfo}
        />
        <PageHeader
          desktopImage={currentFeature.desktopImage}
          id={currentFeature.id}
          mobileImage={currentFeature.mobileImage}
          showLogo={currentFeature.showLogo}
          showName={currentFeature.showName}
          subtitle={currentFeature.subtitle}
          textColor={currentFeature.textColor}
          title={currentFeature.title}
          updateFeatureInfo={updateFeatureInfo}
          userId={Number(userId)}
        />
        <IntroSection
          ctaLink={currentFeature.ctaLink}
          ctaTitle={currentFeature.ctaTitle}
          id={currentFeature.id}
          introParagraph={currentFeature.introParagraph}
          introPhoto={currentFeature.introPhoto}
          introTitle={currentFeature.introTitle}
          updateFeatureInfo={updateFeatureInfo}
          userId={Number(userId)}
          videoUrl={currentFeature.videoUrl}
        />
        <MeetCollection
          meetCollection={currentFeature.meetCollection}
          products={currentFeature.products}
          updateFeatureInfo={updateFeatureInfo}
        />
        <ValueProps
          id={currentFeature.id}
          updateFeatureInfo={updateFeatureInfo}
          userId={Number(userId)}
          valuePropErrors={requiredFields.valueProps}
          valueProps={currentFeature.valueProps}
        />
        <LifestyleImagery
          id={currentFeature.id}
          photos={currentFeature.editorialImages}
          type="editorialImages"
          updateFeatureInfo={updateFeatureInfo}
          userId={Number(userId)}
        />
        <ProductIncluded
          productIncluded={currentFeature.productIncluded}
          products={currentFeature.products}
          updateFeatureInfo={updateFeatureInfo}
        />
      </>
    </StyledDesignerFeatureContainer>
  );
};
