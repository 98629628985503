import { RadioList } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { AtomsRadioListProps } from './atoms-radio-list.types';

export const AtomsRadioList = <Value extends string>(
  props: AtomsRadioListProps<Value>,
) => {
  const { atom, onSelected, ...rest } = props;

  const [atomValue, setAtomValue] = useRecoilState(atom);

  return (
    <RadioList
      {...rest}
      value={atomValue}
      onSelected={async (value) => {
        setAtomValue(value);

        await onSelected?.(value);
      }}
    />
  );
};
