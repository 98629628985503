import {
  Button,
  Conditional,
  Flex,
  Icon,
  Stack,
  Tooltip,
  Typography,
} from 'gantri-components';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { Divider } from '../../../../../../../../../../components/divider';
import { productJobsTabAtoms } from '../../../../../../product-jobs.atoms';
import { PartJobBlocksFormData } from '../../part-job-blocks.types';
import { getTemplateEditDisabledReason } from '../../../../../../../../../../components/common/jobs-template-table/helpers/get-template-edit-disabled-reason';
import { useGetVersionDetails } from '../../../../../../hooks/use-get-version-details';
import { useIsEditingPartRowJobsDataForm } from '../../../../hooks/use-is-editing-part-row';

export const PartJobBlocksHeader = () => {
  const isEditingJobs = useRecoilValue(productJobsTabAtoms.isEditingJobs);

  const setInvalidPartIds = useSetRecoilState(
    productJobsTabAtoms.invalidPartIds,
  );

  const setIsEditingPartId = useSetRecoilState(
    productJobsTabAtoms.isEditingPartId,
  );
  const resetIsEditingPartId = useResetRecoilState(
    productJobsTabAtoms.isEditingPartId,
  );

  const { isDraft } = useGetVersionDetails();

  const { isSubmitting, isValid, resetForm, validateForm, values } =
    useFormikContext<PartJobBlocksFormData>();

  const { partTemplateId } = values;

  const isEditingPartRow = useIsEditingPartRowJobsDataForm();

  const jobBlocksTitle = [isEditingPartRow && 'Edit', 'Job Blocks']
    .filter(Boolean)
    .join(' ');

  const onEdit = () => {
    setIsEditingPartId(partTemplateId);
  };

  const onCancel = async () => {
    resetIsEditingPartId();
    resetForm();
    await validateForm(values);
  };

  useEffect(() => {
    setInvalidPartIds((partIds) => {
      return { ...partIds, [partTemplateId]: !isValid };
    });

    return () => {
      // remove part ID from invalid list on unmount.  If re-mounting, it will be added back in, otherwise it has been deleted.
      setInvalidPartIds((partIds) => {
        return { ...partIds, [partTemplateId]: undefined };
      });
    };
  }, [isValid]);

  return (
    <Stack gap="x">
      <Flex alignItems="center" justifyContent="space-between">
        <Typography text={jobBlocksTitle} variant="h5" />
        <Flex alignItems="center" gap="x" width="auto">
          <Conditional condition={isDraft}>
            <Conditional
              condition={isEditingPartRow}
              Fallback={
                <Tooltip
                  description={getTemplateEditDisabledReason({
                    isEditing: isEditingJobs,
                  })}
                  position="top-end"
                >
                  <Button
                    disabled={isEditingJobs}
                    icon={
                      <Icon
                        color={isEditingJobs ? 't3' : 'link'}
                        name="docs:pencil"
                      />
                    }
                    text="Edit"
                    variant="secondary"
                    onClick={onEdit}
                  />
                </Tooltip>
              }
            >
              <Button text="Cancel" variant="secondary" onClick={onCancel} />
              <Tooltip
                description={!isValid && 'Missing required fields.'}
                position="top-end"
              >
                <Button
                  disabled={!isValid}
                  processing={isSubmitting}
                  text="Save"
                  type="submit"
                />
              </Tooltip>
            </Conditional>
          </Conditional>
        </Flex>
      </Flex>

      <Divider />
    </Stack>
  );
};
