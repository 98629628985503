import styled from 'styled-components';

export const StyledSidebarToggleWrapper = styled.div<{
  isSidebarOpen: boolean;
}>`
  display: flex;
  justify-content: center;
  padding: ${({ isSidebarOpen }) => {
    return isSidebarOpen && '1.6rem';
  }};
  cursor: pointer;
`;
