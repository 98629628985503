import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { DiscardDraftArgs, DiscardDraftResponse } from './discard-draft.types';
import { discardDraft } from './discard-draft';

export const useDiscardDraft = <TransformedData = DiscardDraftResponse>(
  props?: GenericMutateQueryProps<
    DiscardDraftArgs,
    DiscardDraftResponse,
    TransformedData
  >,
) => {
  const { onMutate: onDiscardDraft, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to discard draft.',
    mutationFn: discardDraft,
    ...props,
  });

  return { ...rest, onDiscardDraft };
};
