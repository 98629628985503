import { Cell, Conditional, Stack, Typography } from 'gantri-components';
import { Fragment } from 'react';
import { useRecoilValue } from 'recoil';
import { Divider } from '../../../../../../../../components/divider';
import { Label } from '../../../../../../../../components/label';
import { productAtoms } from '../../../../../../product.atoms';

export const SkuConfigurationVariants = () => {
  const product = useRecoilValue(productAtoms.product);

  const selectedVariants = product.variants || [];

  return (
    <Stack gap="x" justifyContent="start">
      <Label text="Variants" />

      <Conditional
        condition={!!selectedVariants.length}
        Fallback={<Typography color="t2" text="None" />}
      >
        {selectedVariants?.map((variant, index) => {
          const { id, name, options } = variant;

          return (
            <Fragment key={id}>
              <Conditional condition={!!index}>
                <Divider />
              </Conditional>

              <Stack gap=".5x">
                <Typography text={name} />

                <Cell>
                  {options.map((variantName) => {
                    return (
                      <Typography
                        key={[id, variantName].join('-')}
                        color="t2"
                        text={variantName}
                      />
                    );
                  })}
                </Cell>
              </Stack>
            </Fragment>
          );
        })}
      </Conditional>
    </Stack>
  );
};
