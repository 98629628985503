import {
  invalidFileReasons,
  productSkuLevelAssetsFileTypes,
  productFileTypesMaxQty,
} from '../../../../../../bulk-upload-modal.constants';
import { FormattedSkuAssets } from '../../../../../../../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';
import { GetUpdatedAssetsBySkuPropsDef } from './get-updated-assets-by-sku.types';

export const getUpdatedAssetsBySku = (props: GetUpdatedAssetsBySkuPropsDef) => {
  const { addToInvalidFiles, formattedValidFiles, prevAssetsBySku } = props;
  const updatedAssetsBySku = formattedValidFiles.reduce<FormattedSkuAssets>(
    (updatedAssetsBySkuAccumulator, { fileType, skus, ...newFileData }) => {
      const updatedData = skus.reduce<FormattedSkuAssets>(
        (updatedDataAccumulator, sku) => {
          const isProductPhotos =
            fileType === productSkuLevelAssetsFileTypes.whiteBackgroundPhotos;
          const existingProductPhotos =
            updatedAssetsBySkuAccumulator?.[sku]?.[
              productSkuLevelAssetsFileTypes.whiteBackgroundPhotos
            ] || [];

          if (isProductPhotos) {
            const isInvalidQty =
              existingProductPhotos.length >= productFileTypesMaxQty[fileType];

            if (isInvalidQty) {
              addToInvalidFiles({
                errorMessage: invalidFileReasons.isInvalidQty,
                fileBlob: undefined,
                fileExtension: undefined,
                fileName: newFileData?.fileName,
                fileSize: undefined,
                metadata: undefined,
              });

              return updatedDataAccumulator;
            }
          }

          const dataForSku = updatedAssetsBySkuAccumulator[sku];

          const newDataForFileType = isProductPhotos
            ? [...existingProductPhotos, newFileData]
            : newFileData;

          const selectedWhiteBackgroundPhoto = existingProductPhotos.length
            ? dataForSku.selectedWhiteBackgroundPhoto
            : newFileData.fileName;

          return {
            ...updatedDataAccumulator,
            [sku]: {
              ...dataForSku,
              [fileType]: newDataForFileType,
              selectedWhiteBackgroundPhoto,
            },
          };
        },
        {},
      );

      return {
        ...updatedAssetsBySkuAccumulator,
        ...updatedData,
      };
    },
    prevAssetsBySku,
  );

  return updatedAssetsBySku;
};
