import { renameFile } from '../rename-file';
import { getDeletionPublicIdsFromUrl } from '../rename-file/rename-file.helpers';
import { DeleteFileArgs } from './delete-file.types';

export const deleteFile = async ({ fileUrl }: DeleteFileArgs) => {
  const { fromPublicId, resourceType, toPublicId } =
    getDeletionPublicIdsFromUrl(fileUrl);

  return renameFile({ fromPublicId, resourceType, toPublicId });
};
