import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import {
  FetchStockSummaryArgs,
  FetchStockSummaryResponse,
} from './fetch-stock-summary.types';
import { fetchStockSummary } from './fetch-stock-summary';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';

export const useFetchStockSummaryQuery = <
  TransformedData = FetchStockSummaryResponse,
>(
  props: GenericFetchProps<
    FetchStockSummaryArgs,
    FetchStockSummaryResponse,
    TransformedData
  >,
) => {
  const { data, isLoading } = useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch stock summary.',
    queryObj: fetchStockSummary,
    ...props,
  });

  return {
    data,
    isLoading,
  };
};

export const useInvalidateFetchStockSummaryCache = () => {
  const invalidateFetchStockSummaryCache =
    useGetInvalidateQueryCache(fetchStockSummary);

  return { invalidateFetchStockSummaryCache };
};
