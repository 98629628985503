import { Radio, Stack } from 'gantri-components';
import {
  EvaluatePartContentProps,
  InitialEvaluation,
} from './evaluate-part-content.types';
import { StyledRadioWrapper } from './evaluate-part-content.styles';
import { Checklist } from '../job-checklist/job-checklist.types';
import { FailedReason } from '../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { ModalContentHeading } from '../modal-content-heading';

export const EvaluatePartContent = <
  ChecklistType extends Partial<Checklist<FailedReason>>,
>(
  props: EvaluatePartContentProps<ChecklistType>,
) => {
  const {
    getDefaultChecklist,
    initialEvaluation,
    labelFail,
    labelPass,
    onSelected,
    setChecklist,
    setInitialEvaluation,
    subTitleText,
    titleText,
  } = props;

  return (
    <Stack gap="2x">
      <ModalContentHeading subTitleText={subTitleText} titleText={titleText} />

      <Stack gap="x">
        <StyledRadioWrapper status="PASS">
          <Radio
            groupValue="PASS"
            labelText={labelPass}
            value={initialEvaluation}
            onSelected={(value: InitialEvaluation) => {
              setInitialEvaluation(value);
              onSelected?.(value);

              const checklist = getDefaultChecklist(value);

              setChecklist(checklist);
            }}
          />
        </StyledRadioWrapper>
        <StyledRadioWrapper status="FAIL">
          <Radio
            groupValue="FAIL"
            labelText={labelFail}
            value={initialEvaluation}
            onSelected={(value: InitialEvaluation) => {
              setInitialEvaluation(value);
              onSelected?.(value);

              const checklist = getDefaultChecklist(value);

              setChecklist(checklist);
            }}
          />
        </StyledRadioWrapper>
      </Stack>
    </Stack>
  );
};
