import { Box, SearchField } from 'gantri-components';
import { useFormikContext } from 'formik';
import { useSelectProductFormOptions } from '../../hooks/use-select-product-form-options';
import { ProductOptionItem, ProductSearchProps } from './product-search.types';
import { ProductNameAndStatus } from './components/product-name-and-status';
import { SelectProductFormData } from '../../../../select-product-form.types';

export const ProductSearch = (props: ProductSearchProps) => {
  const {
    productId,
    /** Likely passed from the FormikInput props */
    ...rest
  } = props;

  const { disableProduct, productOptions } = useSelectProductFormOptions({
    productId,
  });

  const { values } = useFormikContext<SelectProductFormData>();

  const selectedOption = productOptions.find(({ value }) => {
    return value === values.productId;
  });
  const numCharacters =
    (selectedOption?.label?.length || 0) +
    (selectedOption?.status?.length || 0);

  return (
    <Box minWidth="25rem" width={`${numCharacters}ch`}>
      <SearchField
        {...rest}
        disabled={disableProduct}
        dropdownPopupWidth="30rem"
        items={productOptions}
        placeholder="Select Product"
        renderItem={(item: ProductOptionItem) => {
          return (
            <ProductNameAndStatus name={item?.label} status={item?.status} />
          );
        }}
        renderPlaceholder={(item: ProductOptionItem) => {
          return (
            <ProductNameAndStatus
              name={item?.label}
              status={item?.status}
              whitespace="nowrap"
            />
          );
        }}
        size="medium"
      />
    </Box>
  );
};
