import { Typography, Flex, Conditional, Grid } from 'gantri-components';
import { ZoomableThumbnail } from '../../../../../../../../../../../../../../components';
import { BulkUploadTableDimmingPhotosCellProps } from './bulk-upload-table-dimming-photos-cell.types';

export const BulkUploadTableDimmingPhotosCell = (
  props: BulkUploadTableDimmingPhotosCellProps,
) => {
  const { dimmingPhotoDark, dimmingPhotoLight } = props;

  return (
    <Grid alignItems="center" columns="repeat(2, 6.8rem)" gap="3x">
      <Flex alignItems="center" gap=".5x" width="auto">
        <Typography color="t2" text="L:" />

        <Conditional
          condition={!!dimmingPhotoLight?.fileUrl}
          Fallback={<Typography text="-" />}
        >
          <ZoomableThumbnail
            heading={dimmingPhotoLight?.fileName}
            size={36}
            source={dimmingPhotoLight?.fileBlob ? 'absolute' : 'dynamic'}
            src={dimmingPhotoLight?.fileUrl}
          />
        </Conditional>
      </Flex>

      <Flex alignItems="center" gap=".5x" width="auto">
        <Typography color="t2" text="D:" />

        <Conditional
          condition={!!dimmingPhotoDark?.fileUrl}
          Fallback={<Typography text="-" />}
        >
          <ZoomableThumbnail
            heading={dimmingPhotoDark?.fileName}
            size={36}
            source={dimmingPhotoDark?.fileBlob ? 'absolute' : 'dynamic'}
            src={dimmingPhotoDark?.fileUrl}
          />
        </Conditional>
      </Flex>
    </Grid>
  );
};
