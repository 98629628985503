import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Conditional, Typography } from 'gantri-components';
import { GiftCodeTransaction } from '../../../../api/gift-codes/routes/fetch-giftcode/fetch-giftcode.types';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import routePaths from '../../../../config/route-paths';
import { formatDate } from '../../../../helpers/formatter';
import { getIsClickableIfValue } from '../../../../helpers/get-is-clickable-if-value';

export const useGetGiftCodeColumns = () => {
  const columns: ColumnDef<GiftCodeTransaction>[] = [
    {
      accessorKey: 'orderId',
      cell: ({
        getValue,
      }: CellContext<GiftCodeTransaction, GiftCodeTransaction['orderId']>) => {
        const orderId = getValue();

        return (
          <StyledAnchor
            text={`#${orderId}`}
            to={`${routePaths.orders}/${orderId}`}
          />
        );
      },
      header: 'Order #',
      meta: {
        getIsClickable: getIsClickableIfValue,
      },
      size: 80,
    },
    {
      accessorKey: 'orderCreatedAt',
      cell: ({
        getValue,
      }: CellContext<
        GiftCodeTransaction,
        GiftCodeTransaction['orderCreatedAt']
      >) => {
        return <Typography text={formatDate(getValue())} />;
      },
      header: 'Order date',
      size: 80,
    },
    {
      accessorKey: 'user',
      cell: ({
        getValue,
      }: CellContext<GiftCodeTransaction, GiftCodeTransaction['user']>) => {
        const user = getValue();

        if (!user) return '-';

        return (
          <Conditional condition={!!user}>
            <StyledAnchor
              text={`${user.firstName} ${user.lastName}`}
              to={`${routePaths.orders}/${user.id}`}
            />
          </Conditional>
        );
      },
      header: 'User',
      meta: {
        getIsClickable: getIsClickableIfValue,
      },
    },
    {
      accessorKey: 'user',
      cell: ({
        getValue,
      }: CellContext<GiftCodeTransaction, GiftCodeTransaction['user']>) => {
        const user = getValue();

        if (!user) return '-';

        return (
          <Conditional condition={!!user}>
            <Typography text={user.userType} />
          </Conditional>
        );
      },
      header: 'User type',
      id: 'userType',
      size: 80,
    },
  ];

  return columns;
};
