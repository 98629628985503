import styled, { css } from 'styled-components';
import { media } from 'gantri-components';
import Grid from '../../../components/common/grid';
import { shadows, transitions, zIndexes } from '../../../constants/styles';
import { notificationHeight } from '../../../components/notification/notification.styles';
import { getActiveEnv } from '../../../helpers/get-active-env';

export const StyledMobileHeaderContainer = styled.div<{
  numToasts: number;
}>`
  ${({ numToasts, theme }) => {
    const { isDevelopEnv, isStagingEnv } = getActiveEnv();

    const topOffsetValues = new Array(numToasts)
      .fill(notificationHeight)
      .join(' + ');

    return css`
      padding: 1.8rem 3rem;
      background-color: ${theme.colors.surfaces.monochrome.t2};
      position: fixed;
      top: calc(${topOffsetValues});
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: ${zIndexes.navigation};
      ${shadows.overhang}
      ${transitions.slow}

      background-color: ${isDevelopEnv && theme.colors.surfaces.warning.t1};
      background-color: ${isStagingEnv && theme.colors.surfaces.green.t1};

      ${media.lessThan('md')`
        display: grid;
        grid-template-columns: 1fr max-content;
        align-items: center;
        height: 4.8rem;
        max-height: 4.8rem;
        padding: 0 2rem;
      `}
    `;
  }}
`;

export const StyledMobileSidebarContainer = styled.div<{ isActive: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  overflow-y: scroll;
  width: 24rem;
  padding-top: 6.8rem;
  background-color: ${(props) => {
    return props.theme.colors.surfaces.monochrome.t2;
  }};
  z-index: ${zIndexes.mobileSidebar};
  right: -24rem;
  ${transitions.base}
  ${({ isActive }) => {
    return (
      isActive &&
      css`
        right: 0;
      `
    );
  }}
`;

export const StyledMobileLogoWrapper = styled(Grid)`
  & h5:last-child {
    margin-left: 2rem;
    margin-bottom: 0.1rem;
  }
`;
