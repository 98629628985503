import { Icon, Stack, Typography } from 'gantri-components';

export const ConfirmDiscardContent = () => {
  return (
    <Stack gap="3x">
      <Typography
        icon={<Icon color="warning" name="alert:warning_triangle" />}
        text="Warning: This stock is currently assigned to an order."
        variant="h6"
      />
      <Typography text="Upon discarding, system will assign available or in-progress stock. If none available, a new stock will be created." />
      <Typography text="Shipment timelines may be delayed." />
    </Stack>
  );
};
