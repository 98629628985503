import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Conditional, Typography } from 'gantri-components';
import { useMemo } from 'react';
import { ShippingInventory } from '../../../../../../api/products/products.types';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import { Label } from '../../../../../../components/label';
import routePaths from '../../../../../../config/route-paths';
import { formatAsCurrency } from '../../../../../../helpers/formatter';
import { getIsClickableIfValue } from '../../../../../../helpers/get-is-clickable-if-value';

export const useShippingColumns = () => {
  const columns = useMemo(() => {
    const columns: ColumnDef<ShippingInventory>[] = [
      {
        accessorKey: 'id',
        cell: ({
          getValue,
        }: CellContext<ShippingInventory, ShippingInventory['id']>) => {
          const id = getValue();

          return (
            <Conditional condition={!!id} Fallback={<Typography text="-" />}>
              <StyledAnchor
                text={`#${id}`}
                to={`${routePaths.inventories}/${id}`}
              />
            </Conditional>
          );
        },
        header: 'ID',
        maxSize: 80,
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
        size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Inventory',
        size: 250,
      },
      {
        accessorKey: 'cost',
        cell: ({
          getValue,
        }: CellContext<ShippingInventory, ShippingInventory['cost']>) => {
          return (
            <Label
              text={formatAsCurrency(getValue() || 0, { isCents: true })}
            />
          );
        },
        header: 'Cost',
        size: 80,
      },
    ];

    return columns;
  }, []);

  return columns;
};
