import { Button } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { publishDraftModalAtoms } from '../../../publish-draft-modal.atoms';
import { MissingItemsFooterProps } from './missing-items-footer.types';

export const MissingItemsFooter = (props: MissingItemsFooterProps) => {
  const { onCloseModal } = props;

  const setStep = useSetRecoilState(publishDraftModalAtoms.step);

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={onCloseModal}
      />
      <Button
        size="large"
        text="Continue"
        onClick={() => {
          setStep('DRAFT_DETAILS');
        }}
      />
    </>
  );
};
