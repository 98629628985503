import { Icon, Stack, Typography } from 'gantri-components';
import { RemovedFromVlmContentProps } from './removed-from-vlm-content.types';

export const RemovedFromVlmContent = (props: RemovedFromVlmContentProps) => {
  const { stockId } = props;

  return (
    <Stack
      gap="0.8rem"
      justifyItems="center"
      justifySelf="center"
      maxWidth="48.8rem"
      verticalPadding="0.8rem"
    >
      <Icon
        color="success"
        name="ui-control:check_mark_circle_filled_24"
        size="2.4rem"
      />
      <Stack gap="0.4rem" justifyItems="center" justifySelf="center">
        <Typography
          align="center"
          color="success"
          text={`Stock #${stockId} parts have been picked successfully and the VLM bin has been unassigned.`}
        />
        <Typography
          align="center"
          color="success"
          text="Please discard old parts."
        />
      </Stack>
    </Stack>
  );
};
