import {
  Cell,
  Grid,
  Typography,
  FileUploader,
  HandleDeleteFile,
  HandleUploadsComplete,
} from 'gantri-components';
import { HeadshotLogoProps } from './headshot-logo.types';
import { useCloudinaryFileUploader } from '../../../../../../../../hooks/use-cloudinary-file-uploader';

export const HeadShotLogo = (props: HeadshotLogoProps) => {
  const { headshotUrl, logoUrl, updateDesignerInfo, userId } = props;

  const getHandleImageUploaded = (property: 'headshotUrl' | 'logoUrl') => {
    const handleUploadsComplete: HandleUploadsComplete = async ([
      { fileName },
    ]) => {
      updateDesignerInfo({
        key: property,
        type: 'key',
        value: fileName,
      });
    };

    return handleUploadsComplete;
  };

  const getOnFileRecovered = (property: 'headshotUrl' | 'logoUrl') => {
    return async (updatedFileName: string) => {
      updateDesignerInfo({
        key: property,
        type: 'key',
        value: updatedFileName,
      });
    };
  };

  const getHandleImageDelete = (property: 'headshotUrl' | 'logoUrl') => {
    const onFileDelete: HandleDeleteFile = async () => {
      updateDesignerInfo({
        key: property,
        type: 'key',
        value: '',
      });
    };

    return onFileDelete;
  };

  const { fileUploaderProps: headshotFileUploaderProps } =
    useCloudinaryFileUploader<'designers'>({
      directory: 'designers',
      fileName: headshotUrl,
      fileType: 'headshot',
      handleUploadsComplete: getHandleImageUploaded('headshotUrl'),
      identifiers: {
        userId,
      },
      onFileDelete: getHandleImageDelete('headshotUrl'),
      onFileRecovered: getOnFileRecovered('headshotUrl'),
      transformFileName: ({ applyUuid, removeWhitespace }) => {
        return applyUuid(removeWhitespace('headshot'));
      },
    });

  const { fileUploaderProps: logoFileUploaderProps } =
    useCloudinaryFileUploader<'designers'>({
      directory: 'designers',
      fileName: logoUrl,
      fileType: 'logo',
      handleUploadsComplete: getHandleImageUploaded('logoUrl'),
      identifiers: {
        userId,
      },
      onFileDelete: getHandleImageDelete('logoUrl'),
      onFileRecovered: getOnFileRecovered('logoUrl'),
      transformFileName: ({ applyUuid, removeWhitespace }) => {
        return applyUuid(removeWhitespace('logo'));
      },
    });

  return (
    <Cell width={{ lg: 2, sm: 1 }}>
      <Grid columns="repeat(2, max-content)" gap={{ lg: '2rem', sm: '6rem' }}>
        <Cell>
          <Typography
            marginBottom="1rem"
            text="Headshot"
            textStyle="bold"
            variant="p2"
          />
          <FileUploader {...headshotFileUploaderProps} variant="thumbnail" />
        </Cell>
        <Cell>
          <Typography
            marginBottom="1rem"
            text="Logo"
            textStyle="bold"
            variant="p2"
          />
          <FileUploader
            {...logoFileUploaderProps}
            minImageHeight={50}
            minImageWidth={50}
            variant="thumbnail"
          />
        </Cell>
      </Grid>
    </Cell>
  );
};
