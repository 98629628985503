import { Button, GetCellProps, Table, useModal } from 'gantri-components';
import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { PageWithTable } from '../../components/layout';
import { useGetPressPageColumns } from './hooks/use-get-press-page-columns';
import { useTableFilters } from '../../components/common/table/hooks';
import {
  PressRecord,
  PressRecordSortingField,
} from '../../api/press/press.types';
import {
  pageName,
  pressPageAtoms,
  pressPageAtomsDefaults,
} from './press.atoms';
import { PressRecordModal } from './components/press-record-modal';
import { useGetPaginatedPressRecords } from '../../api/press';
import { GetPaginatedPressRecordsArgs } from '../../api/press/get-paginated-press-records/get-paginated-press-records.types';
import { pressRecordModalActions } from './components/press-record-modal/press-record-modal.constants';
import { PressPageFilters } from './components/press-page-filters';
import { pressRecordModalAtoms } from './components/press-record-modal/press-record-modal.atoms';

export const Press = () => {
  const [filters, updateFilters] = useState<GetPaginatedPressRecordsArgs>();

  const setModalAction = useSetRecoilState(pressRecordModalAtoms.modalAction);

  const [showPressRecordModal, hidePressRecordModal] = useModal(() => {
    return <PressRecordModal onClose={hidePressRecordModal} />;
  }, []);

  const columns = useGetPressPageColumns();

  const getCellProps: GetCellProps<PressRecord> = (props) => {
    const { column } = props;

    switch (column.id) {
      case 'link':
        return { isClickable: true };

      default:
        return {};
    }
  };

  const { data, isLoading } = useGetPaginatedPressRecords({
    enabled: !!filters,
    fetchArgs: filters,
  });

  const {
    filtersProps,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    PressRecord,
    GetPaginatedPressRecordsArgs,
    PressRecordSortingField
  >({
    fetchRequest: updateFilters,
    filtersContent: <PressPageFilters />,
    modalFiltersDetails: [
      {
        atom: pressPageAtoms.filters.dateRange,
        defaultValue: pressPageAtomsDefaults.filters.dateRange,
        payloadKey: 'dateRange',
      },
      {
        atom: pressPageAtoms.filters.type,
        defaultValue: pressPageAtomsDefaults.filters.type,
        payloadKey: 'type',
      },
    ],
    pageName,
    searchFilterDetails: {
      atom: pressPageAtoms.search,
    },
    sortByFilterDetails: {
      atom: pressPageAtoms.sortBy,
      defaultValue: pressPageAtomsDefaults.sortBy,
    },
    sortOptions: [
      { bidirectional: true, label: 'Date', sortingField: 'publishDate' },
      { bidirectional: true, label: 'Source ', sortingField: 'source' },
    ],
  });

  useEffect(() => {
    if (!isLoading) {
      setRecords(data?.data || []);
      setTotalCount(data?.pagination.totalItems || 0);
    }
  }, [isLoading, data]);

  return (
    <PageWithTable pageTitle="Press">
      <PageWithTable.Header title="Press">
        <Button
          text="New Record"
          onClick={() => {
            setModalAction(pressRecordModalActions.add);
            showPressRecordModal();
          }}
        />
      </PageWithTable.Header>
      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          getCellProps={getCellProps}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          sorting={sortProps}
          stickyLastColumn
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
