import { useNotification } from '../../../useNotification';
import {
  FileToDownload,
  UseHandleDownloadRequestProps,
} from './use-handle-download-request.types';

/** @deprecated This function is intended for use by `useDownload` hooks and should not be used outside of that hook. Use `useDownload` instead. */
export const useHandleDownloadRequest = (
  props: UseHandleDownloadRequestProps,
) => {
  const { onError } = props;

  const { notifyError } = useNotification();

  const downloadFile = (file: FileToDownload) => {
    return new Promise<void>((resolve, reject) => {
      const { fileName, url } = file;

      const handleError = () => {
        if (onError) {
          onError(file);
        } else {
          notifyError('Unable to download file.');
        }

        reject();
      };

      const xhr = new XMLHttpRequest();

      xhr.responseType = 'blob';

      xhr.open('GET', url);

      xhr.onerror = handleError;

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const headers = xhr
              .getAllResponseHeaders()
              .split(/\n|\r/)
              .filter(Boolean)
              .reduce((accumulator, header) => {
                const [key, value] = header.split(': ');

                return { ...accumulator, [key]: value };
              }, {});

            const contentDisposition = headers['content-disposition'] || '';

            const systemFileName = contentDisposition.replace(
              /.+filename="([^"]+).+/,
              '$1',
            );

            const urlFileName = url.replace(/.+\//g, '');

            const href = URL.createObjectURL(xhr.response);

            const link = document.createElement('a');

            link.href = href;
            link.setAttribute(
              'download',
              fileName || systemFileName || urlFileName,
            );
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);

            resolve();
          } else {
            handleError();
          }
        }
      };

      xhr.send();
    });
  };

  return downloadFile;
};
