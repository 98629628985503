import { Icon, Stack, Typography } from 'gantri-components';
import { UpdateErrorContentProps } from './update-error-content.types';

export const UpdateErrorContent = (props: UpdateErrorContentProps) => {
  const { stockId } = props;

  return (
    <Stack
      gap="0.8rem"
      justifyItems="center"
      justifySelf="center"
      maxWidth="48.8rem"
    >
      <Icon color="alert" name="alert:warning_triangle_24" size="2.4rem" />
      <Typography
        align="center"
        color="alert"
        text={`There was an error and version update for Stock #${stockId} was not successful.`}
      />
    </Stack>
  );
};
