import React, { FC } from 'react';
import { Conditional } from 'gantri-components';
import { CellLocationProps } from './cell-location.types';
import { NotApplicableText } from '../../../../../../components/common/not-applicable-text';

export const CellLocation: FC<CellLocationProps> = (props) => {
  const { getValue } = props;

  const location = getValue();

  return (
    <Conditional condition={!!location?.name} Fallback={<NotApplicableText />}>
      {location?.name}
    </Conditional>
  );
};
