import {
  Conditional,
  Flex,
  FormikInput,
  Stack,
  TextField,
  Typography,
} from 'gantri-components';
import { SelectedStocksDetails } from '../../../selected-stocks-details';
import { UpdateInventoryTargetModalContentProps } from './update-inventory-target-modal-content.types';

export const UpdateInventoryTargetModalContent = (
  props: UpdateInventoryTargetModalContentProps,
) => {
  const { stocks } = props;

  const numStocks = stocks.length;
  const [firstStock] = stocks;

  return (
    <Stack gap="4x">
      <SelectedStocksDetails stocks={stocks} />
      <Stack gap=".5x">
        <Flex gap="x">
          <Typography text="Inventory target" textStyle="bold" />
          <Conditional condition={numStocks === 1}>
            <Typography
              color="t2"
              text={`Current: ${firstStock.target || 0}`}
            />
          </Conditional>
        </Flex>
        <FormikInput
          Field={
            <TextField minValue={1} placeholder="Input target" type="number" />
          }
          name="inventoryTarget"
          required
        />
      </Stack>
    </Stack>
  );
};
