import { isValid } from 'date-fns';
import { ReferralUser } from '../../api/referrals/referrals.types';

export const canSendReferral = (purchaseDate, purchaseDateSec) => {
  const thirtyDaysAgoSec = 2.592e9;
  const purchasedThirtyDaysAgo =
    Date.now() - parseInt(purchaseDateSec, 10) >= thirtyDaysAgoSec;

  return isValid(new Date(purchaseDate)) && purchasedThirtyDaysAgo;
};

export const isReferralCancellable = (referral: ReferralUser) => {
  if (
    referral.cancelRewardDateSec ||
    referral.rewardDateSec ||
    referral.purchaseStatus === 'Cancelled'
  ) {
    return false;
  }

  if (canSendReferral(referral.purchaseDate, referral.purchaseDateSec)) {
    return true;
  }

  return !referral.purchaseDateSec;
};
