import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  GetPaginatedBatchesArgs,
  GetPaginatedBatchesResponse,
} from './get-paginated-batches.types';

export const getPaginatedBatches = (args: GetPaginatedBatchesArgs) => {
  const {
    count,
    createdDateRange,
    page,
    search,
    sortingField,
    sortingType,
    ...rest
  } = args;

  const filter = {
    createdEndDate: createdDateRange?.to,
    createdStartDate: createdDateRange?.from,
    ...rest,
  };

  return axios
    .post<GetPaginatedBatchesResponse>(`${coreApiUrl}/batch/paginated`, {
      data: {
        filter,
        limit: count,
        page,
        search,
        sortingField,
        sortingType,
      },
    })
    .then(({ data }) => {
      return data;
    });
};
