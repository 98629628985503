import { useMemo, useState } from 'react';
import { Button, Modal } from 'gantri-components';
import { ChangeItemsModalPropsDef } from './change-items-modal.types';
import { transactionsApi } from '../../../../api';
import { ChangeItemDef } from '../../../../api/transactions/routes/change-items/change-items.types';
import { useInvalidateFetchOrderCache } from '../../../../api/transactions/routes';
import { useNotification } from '../../../../hooks/useNotification';
import { getEditableStocksDetails } from './helpers/get-editable-stock-details';
import { EditableShipments } from './components/editable-shipments';

export const ChangeItemsModal = (props: ChangeItemsModalPropsDef) => {
  const { onClose, order } = props;

  const { id: orderId, shipments } = order;

  const [colorChangedStocks, setColorChangedStocks] = useState<ChangeItemDef[]>(
    [],
  );

  const { onInterceptProcessingRequest, processing } = useNotification();

  const { invalidateFetchOrderCache } = useInvalidateFetchOrderCache();

  const handleConfirm = async () => {
    return onInterceptProcessingRequest(async () => {
      await transactionsApi.changeItems({
        changeItemData: colorChangedStocks,
        orderId,
      });

      await invalidateFetchOrderCache();

      onClose();
    });
  };

  const { editableShipments, editableStocks } = useMemo(() => {
    return getEditableStocksDetails(shipments);
  }, [shipments]);

  const totalShipments = shipments?.length || 0;

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            disabled={!colorChangedStocks.length}
            processing={processing}
            size="large"
            text="Confirm"
            onClick={handleConfirm}
          />
        </>
      }
      header="Change items"
      width={{
        lg: '40rem',
        md: '100%',
      }}
      onClose={onClose}
    >
      <EditableShipments
        colorChangedStocks={colorChangedStocks}
        editableShipments={editableShipments}
        editableStocks={editableStocks}
        setColorChangedStocks={setColorChangedStocks}
        totalShipments={totalShipments}
      />
    </Modal>
  );
};
