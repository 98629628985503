import { Flex, useModal } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MoreMenu } from '../../../../../../../../../../components/dropdowns/more-menu';
import { productJobsTabAtoms } from '../../../../../../product-jobs.atoms';
import { ProductJobsPartMenuProps } from './product-jobs-part-menu.types';
import { MoreMenuOption } from '../../../../../../../../../../components/dropdowns/more-menu/more-menu.types';
import { getTemplateEditDisabledReason } from '../../../../../../../../../../components/common/jobs-template-table/helpers/get-template-edit-disabled-reason';
import { AddOrEditPartModal } from '../../../../components/add-or-edit-part-modal';
import { addOrEditPartModalType } from '../../../../components/add-or-edit-part-modal/add-or-edit-part-modal.constants';
import { DeletePartModal } from '../../../../components/delete-part-modal';
import { useGetVersionDetails } from '../../../../../../hooks/use-get-version-details';

export const ProductJobsPartMenu = (props: ProductJobsPartMenuProps) => {
  const { part } = props;

  const isEditingJobs = useRecoilValue(productJobsTabAtoms.isEditingJobs);
  const setIsEditingPartId = useSetRecoilState(
    productJobsTabAtoms.isEditingPartId,
  );

  const { isMinorVersion } = useGetVersionDetails();

  const [showEditPartModal, hideEditPartModal] = useModal(() => {
    return (
      <AddOrEditPartModal
        modalType={addOrEditPartModalType.edit}
        part={part}
        onClose={hideEditPartModal}
      />
    );
  }, [part]);

  const [showDeleteModal, hideDeleteModal] = useModal(() => {
    return <DeletePartModal part={part} onClose={hideDeleteModal} />;
  }, [part]);

  const options: MoreMenuOption[] = [
    {
      disabled: isEditingJobs || isMinorVersion,
      disabledTooltipProps: {
        description: getTemplateEditDisabledReason({
          isEditing: isEditingJobs,
          isMinorVersion,
        }),
      },
      name: 'Edit part',
      onOptionClick: showEditPartModal,
    },
    {
      disabled: isEditingJobs,
      disabledTooltipProps: {
        description: getTemplateEditDisabledReason({
          isEditing: isEditingJobs,
        }),
      },
      name: 'Edit job blocks',
      onOptionClick: () => {
        setIsEditingPartId(part.id);
      },
    },
    {
      disabled: isEditingJobs || isMinorVersion,
      disabledTooltipProps: {
        description: getTemplateEditDisabledReason({
          isEditing: isEditingJobs,
          isMinorVersion,
        }),
      },
      name: 'Delete',
      onOptionClick: showDeleteModal,
    },
  ];

  return (
    <Flex justifyContent="end">
      <MoreMenu options={options} />
    </Flex>
  );
};
