import {
  Conditional,
  FileUploader,
  HandleDeleteFile,
  HandleUploadsComplete,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { ZoomableThumbnail } from '../../../../../../../../../../../../components';
import { productJobsTabAtoms } from '../../../../../../../../product-jobs.atoms';
import { PartDiagramProps } from './part-diagram.types';
import { useGetVersionDetails } from '../../../../../../../../hooks/use-get-version-details';
import { useInvalidateFetchProductCache } from '../../../../../../../../../../../../api/products/routes';
import { useUpdatePartTemplateThumbnail } from '../../../../../../../../../../../../api/part-templates/routes/update-part-template-thumbnail';
import { useFirebaseFileUploader } from '../../../../../../../../../../../../hooks/use-firebase-file-uploader/use-firebase-file-uploader';

export const PartDiagram = (props: PartDiagramProps) => {
  const { part } = props;
  const { id } = part;

  const isEditingJobs = useRecoilValue(productJobsTabAtoms.isEditingJobs);

  const { isDraft } = useGetVersionDetails();

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();

  const { onUpdatePartTemplateThumbnail } = useUpdatePartTemplateThumbnail({
    onSuccess: async () => {
      await invalidateFetchProductCache();
    },
    showLoading: true,
  });

  const onFileDelete: HandleDeleteFile = async () => {
    await onUpdatePartTemplateThumbnail({
      id,
      thumbnail: null,
    });
  };

  const handleUploadsComplete: HandleUploadsComplete = async ([
    { fileUrl },
  ]) => {
    await onUpdatePartTemplateThumbnail({
      id,
      thumbnail: fileUrl,
    });
  };

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl: part.thumbnail,
    handleUploadsComplete,
    onFileDelete,
    path: `parts/${id}`,
  });

  const showUploader = isDraft && !isEditingJobs;

  return (
    <Conditional
      condition={showUploader}
      Fallback={
        <ZoomableThumbnail
          alt={part.name}
          heading={part.name}
          size={64}
          source="absolute"
          src={part.thumbnail}
        />
      }
    >
      <FileUploader
        {...fileUploaderProps}
        fileName={null}
        thumbnailSize="10rem"
        variant="thumbnail"
      />
    </Conditional>
  );
};
