import { Button, Tooltip } from 'gantri-components';
import { useFormikContext } from 'formik';
import { useRecoilValue } from 'recoil';
import { ConfigureCutSheetFormSchemaDef } from '../../configure-cut-sheets-modal.types';
import { ConfigureCutSheetsModalFooterPropsDef } from './configure-cut-sheet-modal-footer.types';
import { configureCutSheetsModalAtoms } from '../../configure-cut-sheets-modal.atoms';

export const ConfigureCutSheetsModalFooter = (
  props: ConfigureCutSheetsModalFooterPropsDef,
) => {
  const { isSaving, onClose } = props;

  const processingUpload = useRecoilValue(
    configureCutSheetsModalAtoms.processingUpload,
  );

  const { isValid, values } =
    useFormikContext<ConfigureCutSheetFormSchemaDef>();

  return (
    <>
      <Tooltip
        description={
          !values.closeable &&
          'Unable to cancel. Please complete the cut sheet configuration and save.'
        }
        overlayContainerStyles={{
          display: 'block',
        }}
        position="top"
      >
        <Button
          disabled={!values.closeable || processingUpload}
          size="large"
          text="Cancel"
          variant="secondary"
          onClick={onClose}
        />
      </Tooltip>

      <Button
        disabled={!isValid || processingUpload}
        processing={isSaving}
        size="large"
        text="Save Configuration"
        type="submit"
      />
    </>
  );
};
