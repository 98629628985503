import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import {
  FetchPaginatedInventoriesArgs,
  FetchPaginatedInventoriesResponse,
} from './fetch-paginated-inventories.types';
import { fetchPaginatedInventories } from './fetch-paginated-inventories';

const fetchPaginatedInventoriesQueriesObj = getGenericQueryObj({
  groupName: 'fetch-paginated-inventories',
  query: fetchPaginatedInventories,
});

export const useFetchPaginatedInventories = <
  TransformedData = FetchPaginatedInventoriesResponse,
>(
  props?: GenericFetchProps<
    FetchPaginatedInventoriesArgs,
    FetchPaginatedInventoriesResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch paginatedInventories.',
    queryObj: fetchPaginatedInventoriesQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchPaginatedInventoriesCache = () => {
  const invalidateFetchPaginatedInventoriesCache = useGetInvalidateQueryCache(
    fetchPaginatedInventoriesQueriesObj,
  );

  return { invalidateFetchPaginatedInventoriesCache };
};
