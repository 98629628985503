import { Button } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { restartOrCancelPartModalAtoms } from '../../../restart-or-cancel-part-modal.atoms';
import { ConfirmRestartFooterProps } from './confirm-restart-footer.types';
import { partsApi } from '../../../../../../../../../api';
import { useNotification } from '../../../../../../../../../hooks/useNotification';
import routePaths from '../../../../../../../../../config/route-paths';
import { useRouter } from '../../../../../../../../../hooks';

export const ConfirmRestartFooter = (props: ConfirmRestartFooterProps) => {
  const { navigate } = useRouter();

  const { handleCloseModal, part } = props;

  const { id: partId } = part;

  const setStep = useSetRecoilState(restartOrCancelPartModalAtoms.step);
  const notes = useRecoilValue(restartOrCancelPartModalAtoms.notes);
  const restartReason = useRecoilValue(
    restartOrCancelPartModalAtoms.restartReason,
  );

  const { hideLoading, notify, notifyAxiosError, showLoading } =
    useNotification();

  const handleRestartPart = async () => {
    try {
      showLoading();

      const { data } = await partsApi.restartPart({
        partId,
        reason: restartReason,
        reasonDetails: notes,
      });

      notify(data.notice, { time: 5000 });

      navigate(`${routePaths.parts}/${data.newPartId}`);

      await handleCloseModal();
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to restart part.' });
    } finally {
      hideLoading();
    }
  };

  return (
    <>
      <Button
        text="Back"
        variant="secondary"
        onClick={() => {
          setStep('RESTART_REASON');
        }}
      />
      <Button
        text="Re-start Part"
        variant="primaryAlert"
        onClick={handleRestartPart}
      />
    </>
  );
};
