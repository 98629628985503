import { useModal } from 'gantri-components';
import { UpdateGrowthMultiplierModal } from './components/update-growth-multiplier-modal';
import { UpdateInventoryTargetModal } from './components/update-inventory-target-modal';
import { AddStocksModal } from './components/add-stocks-modal';
import { UseStockSummaryModalsProps } from './use-stock-summary-modals.types';

export const useStockSummaryModals = (props: UseStockSummaryModalsProps) => {
  const { selectedStocks } = props;

  const [showUpdateGrowthMultiplierModal, hideUpdateGrowthMultiplierModal] =
    useModal(() => {
      return (
        <UpdateGrowthMultiplierModal
          onClose={hideUpdateGrowthMultiplierModal}
        />
      );
    }, [selectedStocks]);

  const [showAddStockModal, hideAddStockModal] = useModal(() => {
    return (
      <AddStocksModal stocks={selectedStocks} onClose={hideAddStockModal} />
    );
  }, [selectedStocks]);

  const [showUpdateInventoryTargetModal, hideUpdateInventoryTargetModal] =
    useModal(() => {
      return (
        <UpdateInventoryTargetModal
          stocks={selectedStocks}
          onClose={hideUpdateInventoryTargetModal}
        />
      );
    }, [selectedStocks]);

  return {
    showAddStockModal,
    showUpdateGrowthMultiplierModal,
    showUpdateInventoryTargetModal,
  };
};
