import * as yup from 'yup';
import { AddStocksFormData } from './add-stocks-modal.types';

const max = 100;

export const getAddStocksFormSchema = (
  numStocks: number,
): yup.SchemaOf<AddStocksFormData> => {
  return yup.object().shape({
    stocksToAdd: yup
      .number()
      .min(1, 'Quantity must be at least 1.')
      .test({
        // mark the test as exclusive, meaning only one test of the same name can be active at once
        exclusive: false,
        // the validation error message
        message: ({ value }: { value: number }) => {
          return !!value
            ? `Cannot create more than ${max} stocks. Please enter a quantity of ${Math.floor(
                max / numStocks,
              )} or less.`
            : 'Required';
        },
        // unique name identifying the test
        name: 'max',
        // test function, determines schema validity
        test: (value) => {
          return numStocks * value <= max;
        },
      })
      .required('Required'),
  });
};

export const addStocksFormInitialValues: AddStocksFormData = {
  stocksToAdd: undefined,
};
