import { useNotification } from '../../../../hooks/useNotification';
import { FetchPaginatedDesignersArgs } from './fetch-paginated-designers.types';
import { useQueryFetch } from '../../../../hooks/use-fetch/use-query-fetch';
import { fetchPaginatedDesigners } from '..';

interface UseFetchPaginatedDesignersProps {
  filters: FetchPaginatedDesignersArgs;
}

export const useFetchPaginatedDesigners = (
  props: UseFetchPaginatedDesignersProps,
) => {
  const { filters } = props;
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch({
    config: {
      enabled: !!filters,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch designers.',
        });
      },
      queryFn: () => {
        return fetchPaginatedDesigners.query(filters);
      },
      queryKey: fetchPaginatedDesigners.key(filters),
    },
    showLoading: true,
  });

  return { ...data, isLoading };
};
