import { Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { updatePartBatchModalAtoms } from '../../update-part-batch-modal.atoms';
import { UpdatePartBatchModalStep } from '../../update-part-batch-modal.types';

export const BatchUpdateErrorContent = () => {
  const step = useRecoilValue(updatePartBatchModalAtoms.step);

  return (
    <Stack alignContent="center" gap="0" minHeight="12.4rem">
      <Typography align="center" text={getErrorText(step)} />
      <Typography align="center" text="Please try again." />
    </Stack>
  );
};

const getErrorText = (step: UpdatePartBatchModalStep) => {
  switch (step) {
    case 'PART_REMOVED_ERROR':
      return 'There was an error and part could not be removed.';

    case 'PART_ADDED_ERROR':
      return 'There was an error and part could not be added.';

    case 'BATCH_CREATION_ERROR':
      return 'There was en error and batch could not be created.';

    default:
      return '';
  }
};
