import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  BulkStartPrintTransferQueueSetJobsArgs,
  BulkStartPrintTransferQueueSetJobsResponse,
} from './bulk-start-print-transfer-queue-set-jobs.types';

export const bulkStartPrintTransferQueueSetJobs = (
  args: BulkStartPrintTransferQueueSetJobsArgs,
) => {
  const { queueId, setIndex, ...body } = args;

  return axios
    .put<BulkStartPrintTransferQueueSetJobsResponse>(
      `${getCoreApiUrl(
        'queue/print-transfer',
      )}/${queueId}/${setIndex}/bulk/start`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
