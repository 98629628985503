import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  SetInventoryTargetArgs,
  SetInventoryTargetResponse,
} from './set-inventory-target.types';

export const setInventoryTarget = (args: SetInventoryTargetArgs) => {
  return axios
    .put<SetInventoryTargetResponse>(
      `${coreApiUrl}/products/inventory-target`,
      args,
    )
    .then(({ data }) => {
      return data;
    });
};
