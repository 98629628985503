import { Conditional } from 'gantri-components';
import MachineDataCell from '../../../../../../components/common/custom-data-cells/machine-data-cell';
import { CellJobMachineProps } from './cell-job-machine.types';

export const CellJobMachine = (props: CellJobMachineProps) => {
  const {
    getValue,
    row: { original },
  } = props;

  const machine = getValue();

  return (
    <Conditional condition={!!machine}>
      <MachineDataCell assignedInfo={original.assignedInfo} machine={machine} />
    </Conditional>
  );
};
