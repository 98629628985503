import { Button } from 'gantri-components';
import { useFormikContext } from 'formik';
import { ChangeUserTypeModalFormDataDef } from '../../../user-type-modal.types';
import { changeUserTypeModalSteps } from '../../../user-type-modal.constants';

export const ChangeUserTypeModalConfirmFooter = () => {
  const { isSubmitting, setFieldValue } =
    useFormikContext<ChangeUserTypeModalFormDataDef>();

  const onBack = async () => {
    await setFieldValue('step', changeUserTypeModalSteps.update);
  };

  return (
    <>
      <Button size="large" text="Back" variant="secondary" onClick={onBack} />
      <Button
        processing={isSubmitting}
        size="large"
        text="Confirm"
        type="submit"
      />
    </>
  );
};
