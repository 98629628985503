import {
  FormikInput,
  Grid,
  Stack,
  TextField,
  Typography,
} from 'gantri-components';
import { Fragment } from 'react';
import { productCategories } from '../../../../../../../../api/products/products.constants';

export const UpdateGrowthMultiplierModalContent = () => {
  const PercentIcon = () => {
    return <Typography color="t2" text="%" />;
  };

  const displayOrderCategories = [
    'clampLight',
    'floorLight',
    'pendantLight',
    'tableLight',
    'wallLight',
    'wallSconce',
  ] satisfies (keyof typeof productCategories)[];

  return (
    <Stack gap="3x">
      <Typography text="Multiplier % per category will be multiplied by past sales data to project sales in 30 days per SKU." />
      <Grid alignItems="center" columnGap="2x" columns="10.4rem 1fr" rowGap="x">
        {displayOrderCategories.map((category) => {
          return (
            <Fragment key={category}>
              <Typography text={productCategories[category]} textStyle="bold" />
              <FormikInput
                Field={
                  <TextField
                    placeholder="--"
                    rightIcon={<PercentIcon />}
                    type="number"
                  />
                }
                name={category}
              />
            </Fragment>
          );
        })}
      </Grid>
    </Stack>
  );
};
