import { Button } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { transactionsApi } from '../../../../../../../api';
import { useNotification } from '../../../../../../../hooks/useNotification';
import { shipmentActionsModalAtoms } from '../../../shipment-actions-modal.atoms';
import { StepCancelShipmentFooterPropsDef } from './step-cancel-shipment-footer.types';

export const StepCancelShipmentFooter = (
  props: StepCancelShipmentFooterPropsDef,
) => {
  const { handleCloseModal } = props;

  const { notify, notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const shipmentId = useRecoilValue(shipmentActionsModalAtoms.shipmentId);

  const onConfirm = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        const { data } = await transactionsApi.cancelShipment(shipmentId);

        notify(data.notice);

        await handleCloseModal({ updateOnClose: true });
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to cancel shipment.',
        });
      }
    });
  };

  return (
    <>
      <Button
        size="large"
        text="No"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        processing={processing}
        size="large"
        text="Yes"
        onClick={onConfirm}
      />
    </>
  );
};
