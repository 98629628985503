import { TableSortField } from 'gantri-components/dist/components/table/components/table-actions-wrapper/components/sort/sort.types';
import { ModalFiltersDetail } from '../../components/common/table/hooks';
import {
  stockSummaryFiltersDefaults,
  stockSummaryPageAtoms,
} from './stock-summary.atoms';
import {
  FetchStockSummaryArgs,
  StockSummaryRecord,
} from '../../api/stocks/routes/fetch-stock-summary/fetch-stock-summary.types';

export const stockSummarySortOptions: TableSortField<
  keyof StockSummaryRecord
>[] = [
  {
    label: 'Name',
    sortingField: 'name',
    sortingType: 'ASC',
  },
  {
    bidirectional: true,
    label: 'Sales prev 30',
    sortingField: 'sales',
  },
  {
    bidirectional: true,
    label: 'Available',
    sortingField: 'available',
  },
  {
    bidirectional: true,
    label: 'Being made',
    sortingField: 'beingMade',
  },
  {
    bidirectional: true,
    label: 'Qty to add',
    sortingField: 'qtyToAdd',
  },
];

export const stockSummaryModalFiltersDetails: ModalFiltersDetail<FetchStockSummaryArgs>[] =
  [
    {
      atom: stockSummaryPageAtoms.filters.category,
      defaultValue: stockSummaryFiltersDefaults.filters.category,
      payloadKey: 'category',
    },
    {
      atom: stockSummaryPageAtoms.filters.colors,
      defaultValue: stockSummaryFiltersDefaults.filters.colors,
      payloadKey: 'colors',
    },
    {
      atom: stockSummaryPageAtoms.filters.designerId,
      defaultValue: stockSummaryFiltersDefaults.filters.designerId,
      payloadKey: 'designerId',
    },
    {
      atom: stockSummaryPageAtoms.filters.productId,
      defaultValue: stockSummaryFiltersDefaults.filters.productId,
      payloadKey: 'productId',
    },
  ];
