import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  UpdatePartTemplateArgs,
  UpdatePartTemplateResponse,
} from './update-part-template.types';
import { updatePartTemplate } from './update-part-template';

export const useUpdatePartTemplate = <
  TransformedData = UpdatePartTemplateResponse,
>(
  props?: GenericMutateQueryProps<
    UpdatePartTemplateArgs,
    UpdatePartTemplateResponse,
    TransformedData
  >,
) => {
  const { onMutate: onUpdatePartTemplate, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to update the part template.',
    mutationFn: updatePartTemplate,
    ...props,
  });

  return { ...rest, onUpdatePartTemplate };
};
