import * as yup from 'yup';
import { optional, required } from '../../../../../../helpers/validators';

export const VendorFormSchema = yup.object().shape({
  city: required(),
  contact: required(),
  country: required(),
  email: required().email(),
  name: required(),
  notes: optional(),
  password: optional(),
  phone: optional(),
  state: required(),
  username: optional(),
  website: optional(),
});
