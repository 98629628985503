import {
  Cell,
  Conditional,
  Flex,
  Grid,
  Stack,
  Typography,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { printPrepareQueueModalAtoms } from '../../../../print-transfer-queue-modal.atoms';
import StatusDataCell from '../../../../../../../../../components/common/custom-data-cells/status-data-cell';
import { Divider } from '../../../../../../../../../components/divider';
import { useGetActiveJobSet } from '../../../../hooks/use-get-active-job-set';
import { NotApplicableText } from '../../../../../../../../../components/common/not-applicable-text';

export const DetailsPanelSetSection = () => {
  const activeSetIndex = useRecoilValue(
    printPrepareQueueModalAtoms.activeSetIndex,
  );

  const set = useGetActiveJobSet();

  const jobDetails = set?.jobDetails;
  const setNumber = activeSetIndex + 1;

  return (
    <Stack gap="3x" paddingLeft="2x">
      <Typography text={`Set ${setNumber}`} variant="h4" />
      <Grid columnGap="3x" columns="13rem 1fr" rowGap="x">
        <Typography text="Part" textStyle="bold" />
        <Typography text={jobDetails?.partName} />

        <Cell width={2}>
          <Divider />
        </Cell>

        <Typography text="Product" textStyle="bold" />
        <Conditional
          condition={!!jobDetails?.productName}
          Fallback={<NotApplicableText />}
        >
          <Flex alignItems="baseline" gap="2x">
            <Typography display="inline" text={jobDetails?.productName} />
            <Typography
              color="t2"
              display="inline"
              text={jobDetails?.productSize}
            />
          </Flex>
        </Conditional>

        <Cell width={2}>
          <Divider />
        </Cell>

        <Typography text="Version" textStyle="bold" />
        <Conditional
          condition={!!jobDetails?.version}
          Fallback={<NotApplicableText />}
        >
          <Typography text={`v${jobDetails?.version}`} />
        </Conditional>

        <Cell width={2}>
          <Divider />
        </Cell>

        <Typography text="G-code" textStyle="bold" />
        <Typography text={jobDetails?.gcode?.fileName} />

        <Cell width={2}>
          <Divider />
        </Cell>

        <Typography text="Weight" textStyle="bold" />
        <Typography text={`${jobDetails?.weight || 0}g`} />

        <Cell width={2}>
          <Divider />
        </Cell>

        <Typography text="Machine Type" textStyle="bold" />
        <Typography text={jobDetails?.machineType} />

        <Cell width={2}>
          <Divider />
        </Cell>

        <Typography text="Job Status" textStyle="bold" />
        <Flex alignItems="center" gap="x">
          {jobDetails?.statuses?.map?.((status) => {
            return <StatusDataCell key={status} status={status} />;
          })}
        </Flex>
      </Grid>
    </Stack>
  );
};
