import {
  Button,
  Cell,
  Conditional,
  Grid,
  Stack,
  Table,
  Typography,
  useModal,
} from 'gantri-components';
import { FC } from 'react';
import { AddCreditsModal } from './add-credits-modal';
import { creditCardColumns, storeCreditColumns } from './credits.constant';
import { CreditsProps } from './credits.types';
import { getCreditAvailable } from './credits.adapter';

export const Credits: FC<CreditsProps> = ({ currentUser, updateUser }) => {
  const { credits, payments = [] } = currentUser;

  const formattedTotalCredit = getCreditAvailable(credits);

  const { creditTransactions = [] } = credits;

  const [showAddCreditModal, hideAddCreditModal] = useModal(() => {
    return (
      <AddCreditsModal
        currentUser={currentUser}
        updateUser={updateUser}
        onClose={hideAddCreditModal}
      />
    );
  }, [currentUser]);

  return (
    <Conditional condition={!!credits}>
      <Stack gap="5x">
        <Stack gap="2x">
          <Grid
            alignContent="end"
            columnGap="x"
            columns="max-content 1fr max-content"
          >
            <Typography text="Credit" textStyle="bold" variant="h4" />

            <Typography
              color="t2"
              text={formattedTotalCredit}
              textStyle="regular"
              variant="h4"
            />

            <Button text="Add Credit" onClick={showAddCreditModal} />
          </Grid>

          <Cell data-credit-section="">
            <Table columns={storeCreditColumns} data={creditTransactions} />
          </Cell>
        </Stack>

        <Stack gap="2x">
          <Cell>
            <Typography text="Cards" textStyle="bold" variant="h4" />
          </Cell>
          <Cell data-gift-section="">
            <Table columns={creditCardColumns} data={payments} />
          </Cell>
        </Stack>
      </Stack>
    </Conditional>
  );
};
