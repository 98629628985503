import {
  Badge,
  Grid,
  Radio,
  Icon,
  Flex,
  Conditional,
  Tooltip,
} from 'gantri-components';
import { useTheme } from 'styled-components';
import { TableColumnRadioProps } from './table-column-radio.types';

export const TableColumnRadio = (props: TableColumnRadioProps) => {
  const { currentStockId, originalData, selectedStock, setSelectedStock } =
    props;
  const { highPriority, stockId } = originalData;

  const theme = useTheme();

  return (
    <Grid alignItems="center" columns="max-content 1fr" gap="x">
      <Radio
        groupValue={stockId}
        labelText={`#${stockId}`}
        name={`#${stockId}`}
        value={selectedStock?.stockId}
        onSelected={() => {
          return setSelectedStock(originalData);
        }}
      />
      <Flex alignItems="center" gap="x">
        <Conditional condition={currentStockId === stockId}>
          <Badge
            borderColor={theme.colors.dividers.t2}
            color={theme.colors.surfaces.monochrome.t2}
            text="Current"
            textColor={theme.colors.typography.t1}
          />
        </Conditional>
        <Conditional condition={highPriority}>
          <Tooltip description="High Priority Stock" position="top">
            <Icon color="t1" name="arrows:arrow_triangle_up" />
          </Tooltip>
        </Conditional>
      </Flex>
    </Grid>
  );
};
