import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Conditional, Typography } from 'gantri-components';
import { TableSortField } from '../../components/common/table/table.props';
import {
  User,
  UsersSortingField,
} from '../../api/users/routes/get-paginated-users';
import { formatDate } from '../../helpers/formatter';
import { StyledAnchor } from '../../components/common/styled-anchor';
import routePaths from '../../config/route-paths';
import { getIsClickableIfValue } from '../../helpers/get-is-clickable-if-value';
import { TableHeaderWithInfoToolTip } from '../../components/common/custom-data-cells/headers';

export const columns: ColumnDef<User>[] = [
  {
    accessorKey: 'id',
    cell: ({ getValue }: CellContext<User, User['id']>) => {
      const userId = getValue();

      return (
        <StyledAnchor
          href={`${routePaths.users}/${userId}`}
          text={`#${userId}`}
        />
      );
    },
    header: 'User #',
    maxSize: 60,
    meta: {
      getIsClickable: getIsClickableIfValue,
      hidden: false,
      hideable: false,
      reorderable: false,
    },
  },
  {
    accessorKey: 'type',
    header: 'Type',
    maxSize: 60,
    meta: {
      hidden: false,
      hideable: false,
      reorderable: false,
    },
  },
  {
    accessorKey: 'name',
    cell: ({ getValue }: CellContext<User, User['name']>) => {
      const name = getValue()?.trim();

      return (
        <Conditional condition={!!name} Fallback={<Typography text="-" />}>
          <Typography text={name} />
        </Conditional>
      );
    },
    header: 'Name',
    meta: {
      hidden: false,
      hideable: false,
      reorderable: false,
    },
  },
  {
    accessorKey: 'email',
    header: 'Email',
    meta: {
      hidden: false,
      hideable: false,
      reorderable: false,
    },
  },
  {
    accessorKey: 'joinDate',
    cell: ({ getValue }: CellContext<User, User['joinDate']>) => {
      return <Typography text={formatDate(getValue())} />;
    },
    header: 'Join Date',
  },
  {
    accessorKey: 'purchases',
    header: 'Purchases',
  },
  {
    accessorKey: 'lastActive',
    header: 'Last Date',
  },
  {
    accessorKey: 'addedReferrals',
    header: 'Referrals',
  },
  {
    accessorKey: 'cartSize',
    header: 'Cart',
  },
  {
    accessorKey: 'avgProductRating',
    header: 'Avg Product Rating',
  },
  {
    accessorKey: 'avgNpsRating',
    header: 'Avg NPS Rating',
  },
  {
    accessorKey: 'npsReviewDate',
    cell: ({ getValue }: CellContext<User, User['npsReviewDate']>) => {
      return <Typography text={formatDate(getValue()) || '-'} />;
    },
    header: () => {
      return (
        <TableHeaderWithInfoToolTip
          headerText="NPS Review Date"
          tooltipText="Last date that the NPS Review was updated by user."
        />
      );
    },
    meta: {
      label: 'NPS Review Date',
    },
    size: 170,
  },
  {
    cell: () => {
      return null;
    },
    header: '',
    id: 'actions',
    meta: {
      hideable: false,
      label: 'Actions',
      reorderable: false,
    },
    size: 30,
  },
];

export const sortOptions: TableSortField<UsersSortingField>[] = [
  { bidirectional: true, label: 'Name', sortingField: 'name' },
  {
    bidirectional: true,
    label: 'Email',
    sortingField: 'email',
  },
  {
    bidirectional: true,
    label: 'Join Date',
    sortingField: 'joinDate',
  },
  {
    bidirectional: true,
    label: 'Purchases',
    sortingField: 'purchases',
  },
  {
    bidirectional: true,
    label: 'Referrals',
    sortingField: 'referrals',
  },
  {
    bidirectional: true,
    label: 'Avg Product Rating',
    sortingField: 'avgProductRating',
  },
  {
    bidirectional: true,
    label: 'Avg NPS Rating',
    sortingField: 'avgNpsRating',
  },
  {
    bidirectional: true,
    label: 'NPS Review Date',
    sortingField: 'npsReviewDate',
  },
];
