import { GetRowProps, Table } from 'gantri-components';
import { inventoryPurchasesApi } from '../../../../api/inventory-purchases';
import { PaginatedInventoryPurchase } from '../../../../api/inventory-purchases/inventory-purchases.types';
import { GetPaginatedInventoryPurchasesArgs } from '../../../../api/inventory-purchases/routes/get-paginated-inventory-purchases/get-paginated-inventory-purchases.types';
import { useTableFilters } from '../../../../components/common/table/hooks/use-table-filters';
import { FiltersFetchRequestArgs } from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { useNotification } from '../../../../hooks/useNotification';
import { VendorPurchasesFilters } from './components/vendor-purchases-filters';
import {
  pageName,
  vendorPurchasesFilterAtoms,
  VendorPurchasesSortingField,
} from './components/vendor-purchases-filters/vendor-purchases-filters.atoms';
import {
  columns,
  vendorInventoryPurchasesSortFields,
} from './vendor-purchases.constants';
import { VendorPurchasesProps } from './vendor-purchases.types';

export const VendorPurchases = (props: VendorPurchasesProps) => {
  const { vendorId } = props;

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const fetchVendorPurchases = async (
    args: FiltersFetchRequestArgs<
      GetPaginatedInventoryPurchasesArgs,
      VendorPurchasesSortingField
    >,
  ) => {
    try {
      showLoading();

      const { data } =
        await inventoryPurchasesApi.getPaginatedInventoryPurchases({
          ...args,
          vendor: vendorId,
        });

      setTotalCount(data.totalPurchases);
      setRecords(data.purchases);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to fetch vendor inventory purchases.',
      });
    } finally {
      hideLoading();
    }
  };

  const {
    filtersProps,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    PaginatedInventoryPurchase,
    GetPaginatedInventoryPurchasesArgs,
    VendorPurchasesSortingField
  >({
    fetchRequest: fetchVendorPurchases,
    filtersContent: <VendorPurchasesFilters />,
    modalFiltersDetails: [
      {
        atom: vendorPurchasesFilterAtoms.filters.hasDelayedShipping,
        defaultValue:
          vendorPurchasesFilterAtoms.defaults.filters.hasDelayedShipping,
        payloadKey: 'hasDelayedShipping',
      },
      {
        atom: vendorPurchasesFilterAtoms.filters.hasQuantityIssues,
        defaultValue:
          vendorPurchasesFilterAtoms.defaults.filters.hasQuantityIssues,
        payloadKey: 'hasQuantityIssues',
      },
      {
        atom: vendorPurchasesFilterAtoms.filters.completedDateRange,
        defaultValue:
          vendorPurchasesFilterAtoms.defaults.filters.completedDateRange,
        payloadKey: 'completedDateRange',
      },
      {
        atom: vendorPurchasesFilterAtoms.filters.createdDateRange,
        defaultValue:
          vendorPurchasesFilterAtoms.defaults.filters.createdDateRange,
        payloadKey: 'createdDateRange',
      },
      {
        atom: vendorPurchasesFilterAtoms.filters.statuses,
        defaultValue: vendorPurchasesFilterAtoms.defaults.filters.statuses,
        payloadKey: 'statuses',
      },
    ],
    pageName,
    searchFilterDetails: {
      atom: vendorPurchasesFilterAtoms.search,
    },
    sortByFilterDetails: {
      atom: vendorPurchasesFilterAtoms.sortBy,
      defaultValue: vendorPurchasesFilterAtoms.defaults.sortBy,
    },
    sortOptions: vendorInventoryPurchasesSortFields,
  });

  const getRowProps: GetRowProps<PaginatedInventoryPurchase> = (row) => {
    const { hasDelayedShipping, hasQuantityIssues } = row.original;

    return {
      status: hasDelayedShipping || hasQuantityIssues ? 'warning' : undefined,
    };
  };

  return (
    <Table
      columns={columns}
      data={records}
      filters={filtersProps}
      getRowProps={getRowProps}
      highlightHoveredRow
      paging={pagingProps}
      search={searchProps}
      sorting={sortProps}
    />
  );
};
