import { getColorsByProduct } from 'gantri-components';
import { range } from 'lodash';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { noneOption } from '../../../../../../../constants/options';
import { selectProductFormAtoms } from '../../../../select-product-form.atoms';
import { useGetProductsListItems } from '../../components/product-search/hooks/use-get-products-list-items';
import { QuantityOptionItem } from '../../components/quantity-dropdown/quantity-dropdown.types';
import {
  ColorOptionItem,
  UseSelectProductFormOptionsProps,
} from './use-select-product-form-options.types';

export const useSelectProductFormOptions = (
  props: UseSelectProductFormOptionsProps,
) => {
  const { productId } = props;

  const filteredProducts = useRecoilValue(
    selectProductFormAtoms.filteredProducts,
  );

  const selectedProduct = useMemo(() => {
    return filteredProducts.find(({ id }) => {
      return id === productId;
    });
  }, [filteredProducts, productId]);

  const productOptions = useGetProductsListItems(filteredProducts);

  const colorOptions = useMemo(() => {
    return getColorsByProduct({
      allowTradeColors: true,
      isPainted: selectedProduct?.isPainted,
      productId: selectedProduct?.id,
    })?.map<ColorOptionItem>((color) => {
      return {
        label: color.shortColorName,
        value: color.code,
      };
    });
  }, [selectedProduct?.id]);

  const skuPartialCodes = useMemo(() => {
    return selectedProduct?.skuPartialCodes || [];
  }, [selectedProduct?.id]);

  const variantOptions = useMemo(() => {
    return (selectedProduct?.selectors || []).map((variantData) => {
      return variantData.map(({ label, options }) => {
        return {
          label,
          options: options.map((option) => {
            return { label, ...option };
          }),
        };
      });
    });
  }, [selectedProduct?.id]);

  const quantityOptions = useMemo(() => {
    return range(1, 11).map<QuantityOptionItem>((qty) => {
      return {
        label: String(qty),
        value: qty,
      };
    });
  }, []);

  const hasColors = !!colorOptions.length;
  const hasVariants = !!variantOptions.length;

  return {
    colorOptions,
    disableColor: colorOptions.length <= 1,
    disableProduct: !productOptions.length,
    disableVariants: variantOptions.length <= 1,
    hasColors,
    hasVariants,
    productOptions,
    quantityOptions,
    sizeCode: selectedProduct?.size || noneOption.code,
    skuPartialCodes,
    variantOptions,
  };
};
