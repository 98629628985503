import { getSortByFilterAtomFamily } from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.atoms';
import { SortBy } from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { PartJobsSortingField } from './part-jobs.types';

export const partPageJobsFiltersDefaults: {
  sortBy: SortBy<PartJobsSortingField>;
} = {
  sortBy: {
    sortingField: '',
    sortingType: 'ASC',
  },
};

export const pageName: AtomPageName = 'part-jobs';

export const partPageJobsPageAtoms = {
  sortBy: getSortByFilterAtomFamily<SortBy<PartJobsSortingField>>({
    defaultValue: partPageJobsFiltersDefaults.sortBy,
  })(pageName),
};
