interface GcodeArgs {
  partName?: string;
  productName?: string;
  productVersion?: string;
  weight?: number;
}

interface JobTemplateFilePathArg {
  productId: number;
  type: JobFileType;
  version: string;
}

interface JobTemplateFloatingPartPathArg {
  fileType: JobFileType;
}

type JobFileType = 'gcodes' | 'instructions' | 'part-thumbnail';

export const formatGcodeFileName = (args: GcodeArgs) => {
  const { partName, productName, productVersion, weight } = args;

  const fileName = [
    productName,
    partName,
    !!weight && `${weight}g`,
    !!productVersion && `V${productVersion}`,
  ]
    .filter(Boolean)
    .join(' ')
    .replace('.gcode', '');

  return `${fileName}.gcode`;
};

export const getGcodePrintQtyPartName = (args: {
  numPrints: number;
  partName: string;
  printName: string;
}) => {
  const { numPrints, partName, printName } = args;

  return numPrints > 1 ? `Subprint ${printName}` : partName;
};

interface GetFormattedGcodeFileNameProps {
  numPrints: number;
  partName: string;
  printName: string;
  productName: string;
  productVersion: string;
  weight: number;
}

/** Returns the formatted file name based on the qty of print jobs. */
export const getGcodePrintQtyFileName = (
  props: GetFormattedGcodeFileNameProps,
): string => {
  const {
    numPrints,
    partName,
    printName,
    productName,
    productVersion,
    weight,
  } = props;

  const gcodePartName = getGcodePrintQtyPartName({
    numPrints,
    partName,
    printName,
  });

  const formattedGcodeFileName = formatGcodeFileName({
    partName: gcodePartName,
    productName,
    productVersion,
    weight,
  });

  return formattedGcodeFileName;
};

export const jobTemplateFileUploadPath = ({
  productId,
  type,
  version,
}: JobTemplateFilePathArg) => {
  return `products/${productId}/job-templates/${version}/${type}`;
};

export const jobTemplateFloatingPartFileUploadPath = ({
  fileType,
}: JobTemplateFloatingPartPathArg) => {
  return `floating-parts/${fileType}`;
};
