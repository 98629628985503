import { Flex, Icon, Typography } from 'gantri-components';

export const NotifyCustomerHeader = () => {
  return (
    <Flex alignItems="center" gap="0.6rem" justifyContent="center">
      <Icon
        color="success"
        name="ui-control:check_mark_circle_filled_24"
        size="2.4rem"
      />
      <Typography text="Stock Discarded" variant="h4" />
    </Flex>
  );
};
