import { FC } from 'react';
import {
  FileUploader,
  HandleDeleteFile,
  HandleUploadsComplete,
} from 'gantri-components';
import { UploadResaleCertificateProps } from './upload-resale-certificate.types';
import { useNotification } from '../../../../../hooks/useNotification';
import { usersApi } from '../../../../../api';
import { useFirebaseFileUploader } from '../../../../../hooks/use-firebase-file-uploader';
import { useDownloadWithSmallToast } from '../../../../../hooks/use-download-with-small-toast';
import { downloadToastText } from '../../../../../hooks/use-download-with-small-toast/use-download-with-small-toast.constants';

export const UploadResaleCertificate: FC<UploadResaleCertificateProps> = (
  props,
) => {
  const { currentUser, resaleCertificate, updateUser, userId } = props;

  const { notify, notifyAxiosError } = useNotification();

  const fileName = resaleCertificate
    ?.split('%2Fresale-certificate%2F')[1]
    ?.split('?')?.[0];

  const onFileDelete: HandleDeleteFile = async () => {
    try {
      const { data } = await usersApi.removeResaleCertificate({
        userId,
      });

      updateUser({ ...currentUser, resaleCertificate: '' });

      notify(data.notice);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to remove resale certificate.',
      });
    }
  };

  const handleUploadsComplete: HandleUploadsComplete = async ([
    { fileUrl },
  ]) => {
    try {
      const { data } = await usersApi.addResaleCertificate({
        url: fileUrl,
        userId,
      });

      notify(data.notice);

      updateUser({
        ...currentUser,
        resaleCertificate: data.user.account.resaleCertificate,
      });
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to add resale certificate.',
      });
    }
  };

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl: resaleCertificate,
    handleUploadsComplete,
    onFileDelete,
    path: `users/${userId}/resale-certificate`,
  });

  const { DownloadStatusToast, downloadSingleFile, processing } =
    useDownloadWithSmallToast({
      ...downloadToastText.file,
    });

  const handleDownload = async () => {
    if (!processing) {
      await downloadSingleFile({ fileName, url: resaleCertificate });
    }
  };

  return userId ? (
    <FileUploader
      {...fileUploaderProps}
      buttonProps={{
        text: 'Add Certificate',
      }}
      expectedExtensions={['pdf']}
      fileName={fileName}
      variant="link"
      onClick={handleDownload}
    >
      <DownloadStatusToast position="top-start" />
    </FileUploader>
  ) : null;
};
