import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import {
  FetchSandingMinutesArgs,
  FetchSandingMinutesResponse,
} from './fetch-sanding-minutes.types';
import { fetchSandingMinutes } from './fetch-sanding-minutes';

const fetchSandingMinutesQueriesObj = getGenericQueryObj({
  groupName: 'fetch-sanding-minutes',
  query: fetchSandingMinutes,
});

export const useFetchSandingMinutes = <
  TransformedData = FetchSandingMinutesResponse,
>(
  props?: GenericFetchProps<
    FetchSandingMinutesArgs,
    FetchSandingMinutesResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch sanding minutes.',
    queryObj: fetchSandingMinutesQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchSandingMinutesCache = () => {
  const invalidateFetchSandingMinutesCache = useGetInvalidateQueryCache(
    fetchSandingMinutesQueriesObj,
  );

  return { invalidateFetchSandingMinutesCache };
};
