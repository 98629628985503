import { Conditional, Flex, Icon, Stack, Typography } from 'gantri-components';
import { useFormikContext } from 'formik';
import { ChangeUserTypeModalFormDataDef } from '../../../user-type-modal.types';
import { Label } from '../../../../../../components/label';
import { ChangeUserTypeModalConfirmContentPropsDef } from './change-user-type-modal-confirm-content.types';

export const ChangeUserTypeModalConfirmContent = (
  props: ChangeUserTypeModalConfirmContentPropsDef,
) => {
  const { initialValues } = props;

  const { values } = useFormikContext<ChangeUserTypeModalFormDataDef>();

  const getIsSanderValue = (isSander: boolean) => {
    return isSander ? 'Yes' : 'No';
  };

  return (
    <Stack gap=".5x">
      <Label text="Confirm the following updates" />

      <Conditional condition={initialValues.type !== values.type}>
        <Flex alignItems="center" gap="x">
          <Typography text="User type:" />
          <Typography text={initialValues.type} />
          <Icon name="arrows:arrow_right" />
          <Typography text={values.type} textStyle="bold" />
        </Flex>
      </Conditional>

      <Conditional condition={initialValues.isSander !== values.isSander}>
        <Flex alignItems="center" gap="x">
          <Typography text="Is sander:" />
          <Typography text={getIsSanderValue(initialValues.isSander)} />
          <Icon name="arrows:arrow_right" />
          <Typography
            text={getIsSanderValue(values.isSander)}
            textStyle="bold"
          />
        </Flex>
      </Conditional>
    </Stack>
  );
};
