import { useResetRecoilState } from 'recoil';
import {
  cancelOrUpdateStockVersionModalStepAtom,
  cancelOrUpdateStockVersionModalNewStockAtom,
  cancelOrUpdateStockVersionModalUpdateOnCloseAtom,
  cancelOrUpdateStockVersionModalVlmNotifiedAtom,
  cancelOrUpdateStockVersionModalVlmLocationAtom,
} from '../../cancel-or-update-stock-version-modal.atoms';

export const useResetCancelOrUpdateStockVersionModalStates = () => {
  const resetStep = useResetRecoilState(
    cancelOrUpdateStockVersionModalStepAtom,
  );
  const resetNewStock = useResetRecoilState(
    cancelOrUpdateStockVersionModalNewStockAtom,
  );
  const resetUpdateOnClose = useResetRecoilState(
    cancelOrUpdateStockVersionModalUpdateOnCloseAtom,
  );
  const resetVlmNotified = useResetRecoilState(
    cancelOrUpdateStockVersionModalVlmNotifiedAtom,
  );
  const resetVlmLocation = useResetRecoilState(
    cancelOrUpdateStockVersionModalVlmLocationAtom,
  );

  return () => {
    resetStep();
    resetNewStock();
    resetUpdateOnClose();
    resetVlmNotified();
    resetVlmLocation();
  };
};
