import { Modal } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useNotification } from '../../../../hooks/useNotification';
import {
  useAddPressRecordMutation,
  useUpdatePressRecordMutation,
} from '../../../../api/press';
import { PressRecordModalProps } from './press-record-modal.types';
import { pressRecordModalActions } from './press-record-modal.constants';
import {
  pressRecordModalInitialValues,
  pressRecordModalSchema,
} from './press-record-modal.schema';
import { PressRecordModalContent } from './components/press-record-modal-content';
import { PressRecordModalFooter } from './components/press-record-modal-footer';
import { pressRecordModalAtoms } from './press-record-modal.atoms';
import { useResetRecoilAtoms } from '../../../../hooks/use-reset-recoil-atoms';

export const PressRecordModal = (props: PressRecordModalProps) => {
  const { onClose } = props;

  const pressRecord = useRecoilValue(pressRecordModalAtoms.pressRecord);
  const modalAction = useRecoilValue(pressRecordModalAtoms.modalAction);
  const resetAtoms = useResetRecoilAtoms(pressRecordModalAtoms);

  const {
    notifyAxiosError,
    onInterceptProcessingRequest,
    processing: processingForm,
  } = useNotification();

  const { onAddPressRecord } = useAddPressRecordMutation();
  const { onUpdatePressRecord } = useUpdatePressRecordMutation();

  const isAddingRecord = modalAction === pressRecordModalActions.add;
  const isUpdatingRecord = modalAction === pressRecordModalActions.update;

  const handleCloseModal = () => {
    onClose();

    setTimeout(() => {
      // prevents flashing of form when closing delete action modal
      resetAtoms();
    }, 100);
  };

  const onSubmit = async (values) => {
    return onInterceptProcessingRequest(async () => {
      try {
        if (isAddingRecord) {
          await onAddPressRecord(values);
        } else if (isUpdatingRecord) {
          await onUpdatePressRecord(values);
        }

        handleCloseModal();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: `Unable to ${modalAction.toLowerCase()} press record.`,
        });
      }
    });
  };

  const header = useMemo(() => {
    return `${modalAction} Press Record`;
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={
        isAddingRecord ? pressRecordModalInitialValues : pressRecord
      }
      validateOnChange
      validateOnMount
      validationSchema={pressRecordModalSchema}
      onSubmit={onSubmit}
    >
      {() => {
        return (
          <Form>
            <Modal
              footer={
                <PressRecordModalFooter
                  handleCloseModal={handleCloseModal}
                  processingForm={processingForm}
                />
              }
              header={header}
              width={{ lg: '42rem', md: '100%' }}
              onClose={handleCloseModal}
            >
              <PressRecordModalContent />
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
