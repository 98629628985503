import styled, { css } from 'styled-components';
import { PaletteColor } from 'gantri-components/dist/styles/theme';
import { Box, Typography } from 'gantri-components';

export const StyledStatusDot = styled.div<{ color: PaletteColor }>`
  ${({ color, theme }) => {
    return css`
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 30px;
      top: 0.4rem;
      background-color: ${theme.colors.palette[color]};
    `;
  }}
`;

export const StyledTypography = styled(Typography)`
  ${({ theme }) => {
    return css`
      text-align: left;

      span {
        font-family: ${theme.fonts.boldFontFamily};
      }
    `;
  }}
`;

export const StyledBox = styled(Box)`
  ${({ theme }) => {
    return css`
      padding: 2.4rem;
      border: 1px solid ${theme.colors.dividers.t1};
      background-color: ${theme.colors.surfaces.monochrome.t1Alt};
      min-width: 31.5rem;
      text-align: center;

      ul {
        list-style: inherit;
        display: inline-block;

        color: ${theme.colors.typography.t1};
      }
    `;
  }}
`;
