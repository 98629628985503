import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { Cell, Grid, Stack, Table, Typography } from 'gantri-components';
import { giftCodesApi } from '../../api';
import { formatDate } from '../../helpers/formatter';
import { LONG_FORMAT } from '../../constants/dates';
import TimelineItem from '../../components/common/timeline/base-timeline-item';
import Timeline from '../../components/common/timeline';
import MetaData from '../../components/meta-data';
import { PageHeading } from '../../components/layout/page-heading';
import { useSpinner } from '../../hooks';
import { useNotification } from '../../hooks/useNotification';
import { FetchGiftCodeResponse } from '../../api/gift-codes/routes/fetch-giftcode/fetch-giftcode.types';
import { useGetGiftCodeColumns } from './hooks/use-get-gift-code-columns';
import { getAmount, getCreatedBy, getUserType } from './gift-code.adapter';
import routePaths from '../../config/route-paths';

export const GiftCode = () => {
  const { id: giftCodeId } = useParams<{ id?: string }>();
  const [giftCode, setGiftCode] = useState<FetchGiftCodeResponse>();
  const { onInterceptRequest } = useSpinner();
  const { notifyAxiosError } = useNotification();

  const columns = useGetGiftCodeColumns();

  useAsync(async () => {
    if (!giftCodeId) {
      return;
    }

    await onInterceptRequest(async () => {
      try {
        const data = await giftCodesApi.fetchGiftCode(Number(giftCodeId));

        if (data) {
          setGiftCode(data);
        }
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch gift code.',
        });
      }
    });
  }, [giftCodeId]);

  return giftCode ? (
    <>
      <MetaData title={`Gift Code #${giftCodeId}`} />
      <PageHeading title={`Gift code ${giftCode.code}`} />
      <Stack gap="4rem">
        <Grid
          columns="repeat(6, max-content)"
          data-testid="summary-section"
          marginTop="2rem"
        >
          <Cell data-testid="summary-section-created-at">
            <Typography text="Created" />
            <Typography
              color="t2"
              text={formatDate(giftCode.createdAt, LONG_FORMAT)}
            />
          </Cell>

          <Cell data-testid="summary-section-user-type">
            <Typography text="User type" />
            <Typography color="t2" text={getUserType(giftCode)} />
          </Cell>

          <Cell data-testid="summary-section-gift-type">
            <Typography text="Gift type" />
            <Typography color="t2" text={giftCode.type} />
          </Cell>

          <Cell data-testid="summary-section-amount">
            <Typography text="Amount" />
            <Typography color="t2" text={getAmount(giftCode)} />
          </Cell>

          <Cell data-testid="summary-section-status">
            <Typography text="Status" />
            <Typography color="t1" text={giftCode.status} />
          </Cell>

          <Cell data-testid="summary-section-created-by">
            <Typography text="Created by" />
            <Link to={`${routePaths.users}/${giftCode.user.id}`}>
              <Typography color="link" text={getCreatedBy(giftCode)} />
            </Link>
          </Cell>
        </Grid>

        <Cell data-testid="usage-section">
          <Typography
            marginBottom="30px"
            text="Usage"
            textStyle="bold"
            variant="h4"
          />

          <Table
            columns={columns}
            data={giftCode.giftCodeTransactions}
            highlightHoveredRow
          />
        </Cell>

        <Timeline items={giftCode.timelines || []} renderItem={TimelineItem} />
      </Stack>
    </>
  ) : null;
};
