import { CreateStocksArgs, CreateStocksResponse } from './create-stocks.types';
import { createStocks } from './create-stocks';
import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';

export const useCreateStocks = <TransformedData = CreateStocksResponse>(
  props: GenericMutateQueryProps<
    CreateStocksArgs,
    CreateStocksResponse,
    TransformedData
  >,
) => {
  const { onMutate: onCreateStocks, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to create stock(s).',
    mutationFn: createStocks,
    ...props,
  });

  return { ...rest, onCreateStocks };
};
