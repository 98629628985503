import { useState } from 'react';
import { useModal } from 'gantri-components';
import { ErrorBoundary } from 'react-error-boundary';
import { jobsApi } from '../../../api';
import { StartJobModal } from '../components';
import { JobDetails } from '../../../api/jobs/routes/get-job-details/get-job-details.types';
import { ErrorFallback, errorHandler } from '../../../components/error';
import { useNotification } from '../../../hooks/useNotification';
import { useInvalidateFetchSandingMinutesCache } from '../../../api/jobs/routes';
import { sandJobTypeOptions } from '../../../constants/options';

export interface UseJobStartParams {
  onStartEnd: (jobId: number) => Promise<void>;
}

export const useJobStart = (params: UseJobStartParams) => {
  const { onStartEnd } = params;

  const [currentJob, setCurrentJob] = useState<JobDetails>();

  const { notifyError } = useNotification();

  const { invalidateFetchSandingMinutesCache } =
    useInvalidateFetchSandingMinutesCache();

  const onJobUpdated = async () => {
    const isSandingJob = sandJobTypeOptions.some((type) => {
      return type === currentJob.type;
    });

    if (isSandingJob) {
      await invalidateFetchSandingMinutesCache();
    }

    return onStartEnd(currentJob.id);
  };

  const [showStartJobModal, hideStartJobModal] = useModal(() => {
    return (
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(error, info) => {
          notifyError(error.message);
          hideStartJobModal();

          return errorHandler(error, info);
        }}
      >
        <StartJobModal
          job={currentJob}
          onClose={hideStartJobModal}
          onJobUpdated={onJobUpdated}
        />
      </ErrorBoundary>
    );
  }, [currentJob]);

  return async (jobId: number) => {
    const { job } = await jobsApi.getJobDetails(jobId);

    setCurrentJob(job);
    showStartJobModal();
  };
};
