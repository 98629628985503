import { CellContext, ColumnDef } from '@tanstack/react-table';
import {
  Conditional,
  Flex,
  Icon,
  Table,
  Tooltip,
  Typography,
} from 'gantri-components';
import { useMemo } from 'react';
import { Divider } from '../../../../components/divider';
import { partStatuses } from '../../../../constants/options';
import {
  DigitalBacklogSectionPropsDef,
  DigitalBacklogItemDef,
} from './digital-backlog-section.types';

export const DigitalBacklogSection = (props: DigitalBacklogSectionPropsDef) => {
  const { data } = props;

  const columns = useMemo(() => {
    const columns: ColumnDef<DigitalBacklogItemDef>[] = [
      {
        accessorKey: 'label',
        header: 'Digital Backlog',
        size: 160,
      },
      {
        accessorKey: 'parts',
        cell: ({
          getValue,
          row,
        }: CellContext<
          DigitalBacklogItemDef,
          DigitalBacklogItemDef['parts']
        >) => {
          const parts = getValue();
          const { stocks } = row.original;

          return (
            <Flex alignItems="center" gap="x">
              <Typography text={`${parts} parts`} />

              <Conditional condition={!!stocks}>
                <Divider
                  borderColor="t2"
                  borderLocation="right"
                  height="1.2rem"
                />
                <Typography
                  icon={
                    <Tooltip
                      maxWidth="22.4rem"
                      position="top-end"
                      title={`Stocks that have all parts in either '${partStatuses.buffered}' or '${partStatuses.delayedStart}' statuses.`}
                    >
                      <Icon name="alert:i_circle" />
                    </Tooltip>
                  }
                  iconPosition="right"
                  text={`${stocks} stocks`}
                />
              </Conditional>
            </Flex>
          );
        },
        header: null,
        size: 200,
      },
    ];

    return columns;
  }, []);

  return <Table columns={columns} data={data} />;
};
