import { dataAttrIsSticky } from '../../mark-sticky-elements.constants';
import { GetIntersectionObserverCallbackArgs } from './get-intersection-observer-callback.types';

export const getIntersectionObserverCallback = (
  args: GetIntersectionObserverCallbackArgs,
) => {
  const { attrToApply, elementsToMark, stickyTo } = args;

  const callback: IntersectionObserverCallback = ([e]) => {
    const isIntersecting = stickyTo
      ? e.boundingClientRect[stickyTo] !== e.intersectionRect[stickyTo]
      : e.intersectionRatio < 1;

    const attrName =
      attrToApply ||
      (stickyTo ? `${dataAttrIsSticky}-${stickyTo}` : dataAttrIsSticky);

    elementsToMark.forEach((element) => {
      element?.toggleAttribute(attrName, isIntersecting);
    });
  };

  return callback;
};
