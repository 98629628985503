import { Box, Typography } from 'gantri-components';

export const NetworkErrorMessage = () => {
  return (
    <Box verticalPadding="2x">
      <Typography
        text="Oops! Something went wrong :("
        textStyle="bold"
        variant="p1"
      />
      <Typography
        color="t2"
        text="Please refresh your browser to re-attempt connection to server."
      />
    </Box>
  );
};
