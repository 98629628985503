import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useCompleteJob } from '../../../../../../../../../../../api/jobs/routes/complete-job';
import { useFailPrintJob } from '../../../../../../../../../../../api/jobs/routes/fail-print-job';
import { checkAgainstFailingChecklistItems } from '../../../../../../../helpers/check-against-failing-checklist-items';
import { getFailJobReasonArg } from '../../../../../../../helpers/get-fail-job-reason-arg';
import { completePrintHarvestModalAtoms } from '../../../../../complete-print-harvest-modal.atoms';
import { UsePassOrFailJobProps } from './use-pass-or-fail-job.types';

export const usePassOrFailJob = (props: UsePassOrFailJobProps) => {
  const { handleCloseModal, job } = props;

  const checklist = useRecoilValue(completePrintHarvestModalAtoms.checklist);

  const printCompletionStatus = useRecoilValue(
    completePrintHarvestModalAtoms.printCompletionStatus,
  );
  const setDuplicatedJobs = useSetRecoilState(
    completePrintHarvestModalAtoms.duplicatedJobs,
  );
  const setMachine = useSetRecoilState(completePrintHarvestModalAtoms.machine);
  const setMachinePrintHoursExceeded = useSetRecoilState(
    completePrintHarvestModalAtoms.machinePrintHoursExceeded,
  );
  const setNextJob = useSetRecoilState(completePrintHarvestModalAtoms.nextJob);
  const setStep = useSetRecoilState(completePrintHarvestModalAtoms.step);
  const setUpdateOnClose = useSetRecoilState(
    completePrintHarvestModalAtoms.updateOnClose,
  );

  const setBuildPlateIssueType = useSetRecoilState(
    completePrintHarvestModalAtoms.buildPlateIssueType,
  );

  const { isLoading: isLoadingFail, onFailPrintJob } = useFailPrintJob({
    onSuccess: async ({
      duplicatedJobs,
      machine,
      machineBuildPlateIssueType,
      machinePrintHoursExceeded,
    }) => {
      if (!!duplicatedJobs?.length) {
        setDuplicatedJobs(duplicatedJobs);
      }

      if (!!machine) {
        setMachine(machine);
      }

      if (!!machineBuildPlateIssueType) {
        setBuildPlateIssueType(machineBuildPlateIssueType);
      }

      if (!!machinePrintHoursExceeded) {
        setMachinePrintHoursExceeded(true);
      }

      setUpdateOnClose(true);
      setStep('JOB_FAILED');
    },
  });

  const { isLoading: isLoadingPass, onCompleteJob } = useCompleteJob({
    onSuccess: async ({
      machineBuildPlateIssueType,
      machinePrintHoursExceeded,
      nextJob,
    }) => {
      if (!!nextJob) {
        setNextJob(nextJob);
      }

      if (!!machineBuildPlateIssueType) {
        setUpdateOnClose(true);
        setBuildPlateIssueType(machineBuildPlateIssueType);
        setStep('MACHINE_OFFLINE');

        return;
      }

      if (!!machinePrintHoursExceeded) {
        setMachinePrintHoursExceeded(true);
        setUpdateOnClose(true);
        setStep('MACHINE_OFFLINE');

        return;
      }

      await handleCloseModal({ updateOnClose: true });
    },
  });

  const onFail = async () => {
    const reason = getFailJobReasonArg(checklist);
    const shouldReprintPart = checkAgainstFailingChecklistItems(
      checklist,
      (item) => {
        return item.failStatusRecommendation === 'Reprint';
      },
    );

    await onFailPrintJob({
      duplicateType: shouldReprintPart ? 'Reprint' : undefined,
      id: job.id,
      partialComplete: printCompletionStatus === 'FAIL',
      reason,
    });
  };

  const onPass = async () => {
    const reason = getFailJobReasonArg(checklist);

    await onCompleteJob({
      jobId: job.id,
      reason,
    });
  };

  return { onFail, onPass, processing: isLoadingFail || isLoadingPass };
};
