import { stockStatuses } from '../../../constants/options';
import { AttentionOption } from './stocks-filter.types';

export const stocksStatusOptions: StocksStatus[] = Object.values(stockStatuses);

export type StocksStatus = typeof stockStatuses[keyof typeof stockStatuses];

export const stockAttentionAlertOptions = [
  'Production time',
  'Assembly job fails',
  '2 or more parts with attention alerts',
] as const;

export type StockAttentionAlert = typeof stockAttentionAlertOptions[number];

export const productionFilterOptions: StocksStatus[] = [
  'Assembling',
  'Finishing',
  'Packing',
  'Printing',
  'QA',
  'Started',
  'Waiting',
];

export const assignedOptions: AttentionOption[] = [
  {
    label: 'All stocks',
    outputValue: null,
    value: 'all',
  },
  {
    label: 'Assigned to an order',
    outputValue: true,
    value: 'assigned',
  },
  {
    label: 'Idle stocks',
    outputValue: false,
    value: 'idle',
  },
];
