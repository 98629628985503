import { VariantPartialDetail } from '../../../../select-product-form.types';
import { useSelectProductFormOptions } from '../use-select-product-form-options';
import { UseGetVariantPartialDetailProps } from './use-get-variant-partial-detail.types';

export const useGetVariantPartialDetail = (
  props: UseGetVariantPartialDetailProps,
) => {
  const { productId } = props;

  const { variantOptions } = useSelectProductFormOptions({
    productId,
  });

  const getVariantPartialDetail = (props: {
    code: string;
    name: string;
    partialIndex: number;
    variantIndex: number;
  }) => {
    const { code, name, partialIndex, variantIndex } = props;

    const { label } = variantOptions[variantIndex][partialIndex] || {};

    const variantPartialDetail: VariantPartialDetail = {
      code,
      label,
      name,
    };

    return variantPartialDetail;
  };

  return {
    /** Returns the `code`, `label`, and `name` for the applicable variant partial. */
    getVariantPartialDetail,
  };
};
