import pick from 'lodash/pick';
import { Address } from '../../new-order.type';
import { addressSchema } from './address.schema';

export const defaultAddress: Address = {
  city: undefined,
  company: undefined,
  country: 'US',
  createdAt: undefined,
  defaultPayment: undefined,
  defaultPayout: undefined,
  firstName: undefined,
  id: undefined,
  lastName: undefined,
  phone: undefined,
  state: 'AL',
  street: undefined,
  unit: undefined,
  updatedAt: undefined,
  userId: undefined,
  zip: undefined,
};

export const isValidAddressForm = (
  values: Record<string, unknown>,
): boolean => {
  return addressSchema.isValidSync(values || {});
};

export const getSaveAddressPayload = (address: Address) => {
  return pick(address, [
    'city',
    'company',
    'country',
    'firstName',
    'lastName',
    'phone',
    'state',
    'street',
    'unit',
    'zip',
    'id',
  ]);
};
