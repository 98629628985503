export const getFilesPath = (inventoryId: number) => {
  return `inventory-purchases/${inventoryId}`;
};

export const initialsFormValues = {
  address: '2265 Polvorosa Ave., Suite 310, San Leandro, California, 94577',
  attachments: [],
  city: '',
  country: 'United States',
  currency: 'USD',
  inventory: null,
  invoice: '',
  notes: '',
  priority: 'Normal',
  selectedInventory: null,
  state: '',
  totalCost: 0,
  units: 0,
};
