import {
  Cell,
  Conditional,
  GetRowProps,
  Grid,
  Stack,
  Toggle,
  Typography,
} from 'gantri-components';
import { useState } from 'react';
import { Job } from '../../../../../../api/jobs/jobs.types';
import {
  JOB_DETAILS_PANEL_LINKED_TO,
  dataAttrLinkedTo,
} from '../../../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { jobStatuses } from '../../../../../../constants/options';
import { dataAttrJobId } from '../../../../../jobs/jobs.constants';
import {
  dataAttrActiveJobRow,
  StyledTable,
} from './search-part-summary-jobs.styles';
import { SearchPartSummaryJobsProps } from './search-part-summary-jobs.types';
import { useGetSearchPartSummaryJobColumns } from './helpers/get-search-part-summary-job-columns';
import { useSearchPart } from '../../../../search-part.context';
import { JobsDetailsPanelWrapper } from '../../../../../../components/jobs-details-panel-wrapper';
import { useTableColumnsSync } from '../../../../../../hooks';

export const SearchPartSummaryJobs = (props: SearchPartSummaryJobsProps) => {
  const { clearPartSearch, getPartDetails, part } = props;
  const { onOpenAssignMachineModal } = useSearchPart();
  const [showAllJobs, setShowAllJobs] = useState<boolean>(false);

  const currentJob =
    part?.jobs?.find(({ status }) => {
      return [jobStatuses.ready, jobStatuses.inProgress].some((validStatus) => {
        return validStatus === status;
      });
    }) ||
    part?.jobs?.find(({ status }) => {
      return jobStatuses.waiting === status;
    });

  const columnsSyncProps = useTableColumnsSync('search-part');

  const columns = useGetSearchPartSummaryJobColumns(
    {
      clearPartSearch,
      getPartDetails,
      onOpenAssignMachineModal,
      partStatus: part?.status,
    },
    [part.id, part.status],
  );

  const showTable = showAllJobs || (!showAllJobs && !!currentJob);

  const getRowProps: GetRowProps<Job> = ({ original }) => {
    return {
      [dataAttrActiveJobRow]:
        showAllJobs && original?.id === currentJob?.id ? '' : undefined,
      [dataAttrJobId]: original?.id,
      [dataAttrLinkedTo]: JOB_DETAILS_PANEL_LINKED_TO,
    };
  };

  return (
    <Stack
      height="unset"
      horizontalPadding="1.2rem"
      paddingBottom="2rem"
      paddingTop="2rem"
    >
      <Grid
        alignItems="center"
        columns="max-content max-content 1fr"
        gap="1rem"
      >
        <Typography text="Job Overview" textStyle="bold" variant="p1" />
        <Cell justifyContent="flex-start" paddingLeft="1.8rem">
          <Toggle
            name="show-all-jobs"
            value={showAllJobs}
            onSelected={setShowAllJobs}
          />
        </Cell>
        <Cell>
          <Typography color={showAllJobs ? 't1' : 't2'} text="View all jobs" />
        </Cell>
      </Grid>
      <Conditional condition={showTable}>
        <JobsDetailsPanelWrapper
          records={part?.jobs}
          onRefresh={getPartDetails}
        >
          <StyledTable
            columns={columns}
            data={showAllJobs ? part.jobs : [currentJob]}
            getRowProps={getRowProps}
            highlightHoveredRow
            stickyLastColumn
            {...columnsSyncProps}
          />
        </JobsDetailsPanelWrapper>
      </Conditional>
    </Stack>
  );
};
