import { Dropdown, FormikInput } from 'gantri-components';
import { useFormikContext } from 'formik';
import { materials } from '../../../../../../../../../../../../constants/parts';
import { AddOrEditPartsFormData } from '../../../../add-or-edit-part-modal.types';
import { BondoOption } from './part-bondo.types';
import { useGetVersionDetails } from '../../../../../../../../hooks/use-get-version-details';

export const PartBondo = () => {
  const { isMinorVersion } = useGetVersionDetails();

  const { values } = useFormikContext<AddOrEditPartsFormData>();
  const { hasBondo, material } = values;

  const isOpaque = material === materials.opaque;

  const bondoOptions: BondoOption[] = isOpaque
    ? [
        {
          label: 'No',
          value: false,
        },
        {
          label: 'Yes',
          value: true,
        },
      ]
    : [];

  const bondoPlaceholder =
    bondoOptions.find(({ value }) => {
      return value === hasBondo;
    })?.label || 'No';
  const disableHasBondo = isMinorVersion || !isOpaque;

  return (
    <FormikInput
      Field={
        <Dropdown
          autoSelectFirst
          disabled={disableHasBondo}
          items={bondoOptions}
          labelPosition="top"
          labelText="Bondo"
          maxHeight={400}
          placeholder={bondoPlaceholder}
        />
      }
      fieldVariant="standard"
      name="hasBondo"
      required
    />
  );
};
