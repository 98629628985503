import { Modal } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useResetRecoilAtomsOnMount } from '../../../../../../hooks/use-reset-recoil-atoms-on-mount';
import { UpdatePartBatchModalContent } from './components/update-part-batch-modal-content';
import { UpdatePartBatchModalFooter } from './components/update-part-batch-modal-footer';
import { UpdatePartBatchModalHeader } from './components/update-part-batch-modal-header';
import { updatePartBatchModalAtoms } from './update-part-batch-modal.atoms';
import { UpdatePartBatchModalProps } from './update-part-batch-modal.types';

export const UpdatePartBatchModal = (props: UpdatePartBatchModalProps) => {
  const { currentBatchId, onClose, onUpdate, partId } = props;

  const updateOnClose = useRecoilValue(updatePartBatchModalAtoms.updateOnClose);

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    updatePartBatchModalAtoms,
  );

  const handleCloseModal = async () => {
    if (updateOnClose) {
      await onUpdate();
    }

    onClose();
  };

  return (
    <ResetAtomsWrapper>
      <Modal
        footer={
          <UpdatePartBatchModalFooter
            currentBatchId={currentBatchId}
            handleCloseModal={handleCloseModal}
            partId={partId}
          />
        }
        header={<UpdatePartBatchModalHeader />}
        width={{ lg: '62rem', md: '100%' }}
        onClose={handleCloseModal}
      >
        <UpdatePartBatchModalContent currentBatchId={currentBatchId} />
      </Modal>
    </ResetAtomsWrapper>
  );
};
