import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { createProduct } from './create-product';
import {
  CreateProductArgs,
  CreateProductResponse,
} from './create-product.types';

export const useCreateProduct = <TransformedData = CreateProductResponse>(
  props?: GenericMutateQueryProps<
    CreateProductArgs,
    CreateProductResponse,
    TransformedData
  >,
) => {
  const { onMutate: onCreateProduct, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to create new product.',
    mutationFn: createProduct,
    ...props,
  });

  return { ...rest, onCreateProduct };
};
