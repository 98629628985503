import {
  Button,
  Cell,
  Conditional,
  Grid,
  Icon,
  Table,
  Tooltip,
  useModal,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { productAtoms } from '../../../../product.atoms';
import { SectionTitle } from '../../../../../../components/layout/section-title';
import { useDesignsColumns } from './hooks/use-designs-columns';
import { useMoreMenu } from '../../../../../../hooks/use-more-menu';
import { productStatuses } from '../../../../../../constants/options';
import { SelectDesignModal } from './components/select-design-modal';

export const InfoTabDesigns = () => {
  const product = useRecoilValue(productAtoms.product);

  const productStatus = useRecoilValue(productAtoms.productStatus);

  const designs = product?.designs || [];

  const columns = useDesignsColumns();

  const [showSelectDesignModal, hideSelectDesignModal] = useModal(() => {
    return <SelectDesignModal onClose={hideSelectDesignModal} />;
  }, []);

  const isSelectDesignDisabled = productStatuses.active === productStatus;
  const disabledSelectDesignMessage = `Cannot update design if product status is ${productStatuses.active}.`; // Update tooltip depending on feedback from Nayeli

  const { MoreMenu } = useMoreMenu({
    options: [
      {
        disabled: isSelectDesignDisabled,
        disabledTooltipProps: { description: disabledSelectDesignMessage },
        name: 'Update design',
        onOptionClick: showSelectDesignModal,
      },
    ],
  });

  return (
    <Cell>
      <SectionTitle text="Designs">
        <Grid columns="1fr max-content">
          <Conditional condition={!designs.length} Fallback={<div />}>
            <Cell justifyContent="start">
              <Tooltip
                maxWidth="22.7rem"
                position="top-start"
                title={`There is no design selected. Product cannot become '${productStatuses.active}' until a design is selected.`}
              >
                <Icon color="warning" name="alert:warning_triangle" />
              </Tooltip>
            </Cell>
          </Conditional>

          <MoreMenu />
        </Grid>
      </SectionTitle>

      <Conditional
        condition={!!designs.length}
        Fallback={
          <Cell justifySelf="start" paddingTop="2x">
            <Tooltip
              description={
                isSelectDesignDisabled && disabledSelectDesignMessage
              }
              position="right"
            >
              <Button
                disabled={isSelectDesignDisabled}
                text="Select Design"
                variant="secondary"
                onClick={showSelectDesignModal}
              />
            </Tooltip>
          </Cell>
        }
      >
        <Table columns={columns} data={designs} highlightHoveredRow />
      </Conditional>
    </Cell>
  );
};
