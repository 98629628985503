import { memo, useMemo } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import {
  Conditional,
  Icon,
  productColorsMap,
  Tooltip,
  Typography,
} from 'gantri-components';
import { StockSummaryRecord } from '../../../../api/stocks/routes/fetch-stock-summary/fetch-stock-summary.types';
import { StockSummaryRowMenu } from './components/stock-summary-row-menu';
import { UseStockSummaryColumnsProps } from './use-stock-summary-columns.tyes';
import { PercentCell } from '../../../dashboard/components/common/percent-cell';
import { formatAsLocaleNumber } from '../../../../helpers/formatter';
import { NotApplicableText } from '../../../../components/common/not-applicable-text';
import { noneOption } from '../../../../constants/options';

export const useStockSummaryColumns = (props: UseStockSummaryColumnsProps) => {
  const { footerData } = props;

  const columns = useMemo(() => {
    const columns: ColumnDef<StockSummaryRecord>[] = [
      {
        accessorKey: 'name',
        footer: 'TOTAL',
        header: 'Name',
        size: 250,
      },
      {
        accessorKey: 'sku',
        header: 'SKU',
        size: 200,
      },
      {
        accessorKey: 'color',
        cell: memo(
          ({
            getValue,
          }: CellContext<StockSummaryRecord, StockSummaryRecord['color']>) => {
            const colorCode = getValue();

            const showColor = !!colorCode && colorCode !== noneOption.code;

            return (
              <Conditional
                condition={showColor}
                Fallback={<NotApplicableText />}
              >
                <Typography
                  text={productColorsMap[colorCode]?.shortColorName}
                />
              </Conditional>
            );
          },
        ),
        header: 'Color',
        size: 80,
      },
      {
        accessorKey: 'variant',
        cell: memo(
          ({
            getValue,
          }: CellContext<
            StockSummaryRecord,
            StockSummaryRecord['variants']
          >) => {
            const variants = getValue() || [];

            return (
              <Conditional
                condition={!!variants.length}
                Fallback={<NotApplicableText />}
              >
                {variants.map(({ code, label, name }) => {
                  return (
                    <Typography key={code} text={[label, name].join(': ')} />
                  );
                })}
              </Conditional>
            );
          },
        ),
        header: 'Variant',
        size: 160,
      },
      {
        accessorKey: 'sales',
        footer: formatAsLocaleNumber(footerData?.sales || 0),
        header: memo(() => {
          return (
            <Typography
              icon={
                <Tooltip
                  maxWidth="17.3rem"
                  position="top-start"
                  title="Quantity of items sold in the last 30 days."
                >
                  <Icon name="alert:i_circle" />
                </Tooltip>
              }
              iconPosition="right"
              text="30 day Sales"
              textStyle="bold"
            />
          );
        }),
      },
      {
        accessorKey: 'available',
        footer: formatAsLocaleNumber(footerData?.available || 0),
        header: 'Available',
        size: 80,
      },
      {
        accessorKey: 'beingMade',
        footer: formatAsLocaleNumber(footerData?.beingMade || 0),
        header: 'Being Made',
        size: 100,
      },
      {
        accessorKey: 'target',
        header: 'Target',
        size: 80,
      },
      {
        accessorKey: 'growthMultiplier',
        cell: memo(
          ({
            getValue,
          }: CellContext<
            StockSummaryRecord,
            StockSummaryRecord['growthMultiplier']
          >) => {
            return <PercentCell value={getValue()} />;
          },
        ),
        header: 'Growth Multiplier',
        size: 150,
      },
      {
        accessorKey: 'qtyToAdd',
        cell: memo(
          ({
            getValue,
          }: CellContext<
            StockSummaryRecord,
            StockSummaryRecord['qtyToAdd']
          >) => {
            const qtyToAdd = getValue();
            const needToAddQty = qtyToAdd > 0;

            return (
              <Typography
                color={needToAddQty ? 'alert' : undefined}
                text={needToAddQty ? qtyToAdd : '-'}
              />
            );
          },
        ),
        footer: formatAsLocaleNumber(footerData?.qtyToAdd || 0),
        header: 'Qty to Add',
        size: 100,
      },
      {
        cell: memo(({ row }: CellContext<StockSummaryRecord, never>) => {
          return <StockSummaryRowMenu data={row.original} />;
        }),
        id: 'menu',
        size: 30,
      },
    ];

    return columns;
  }, [footerData]);

  return columns;
};
