import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  UpdatePartTemplateThumbnailArgs,
  UpdatePartTemplateThumbnailResponse,
} from './update-part-template-thumbnail.types';

export const updatePartTemplateThumbnail = ({
  id,
  ...body
}: UpdatePartTemplateThumbnailArgs) => {
  return axios
    .put<UpdatePartTemplateThumbnailResponse>(
      `${getCoreApiUrl('product-part-templates')}/${id}/thumbnail`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
