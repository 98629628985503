import {
  Cell,
  Conditional,
  Grid,
  Stack,
  Tab,
  TabPanel,
  Tabs,
} from 'gantri-components';
import { useState } from 'react';
import { PageHeading } from '../../components/layout/page-heading';
import { MetaData } from '../../components/meta-data/metaData';
import { PartReleaseGraph } from './components/part-release-graph';
import { DigitalBacklogSection } from './components/digital-backlog-section';
import { PartReleaseRateSummary } from './components/part-release-rate-summary';
import { partsReleaseRateTabs } from './part-release-rate.constants';
import { PartsReleaseRateTab } from './part-release-rate.types';
import { StyledBlockTabsList } from './part-release-rate.styles';
import { useGetPartReleaseRateDetails } from '../../api/parts/routes';
import { GetPartReleaseRateDetailsResponseDef } from '../../api/parts/routes/get-part-release-rate-details/get-part-release-rate-details.types';
import { PartReleaseRateQueues } from './components/part-release-rate-queues';

export const PartReleaseRate = () => {
  const pageTitle = 'Part Release Rate';

  const [activeTab, setActiveTab] = useState<PartsReleaseRateTab>(
    partsReleaseRateTabs.wipByType,
  );

  const { data } =
    useGetPartReleaseRateDetails<GetPartReleaseRateDetailsResponseDef>({
      showLoading: true,
      transform: ({ outsByType, wipByType, ...rest }) => {
        return {
          ...rest,
          /** Reversed to match desired table display order */
          outsByType: [...outsByType].reverse(),
          /** Reversed to match desired table display order */
          wipByType: [...wipByType].reverse(),
        };
      },
    });

  return (
    <>
      <MetaData title={pageTitle} />
      <PageHeading title={pageTitle} />
      <Conditional condition={!!data}>
        <Stack height="unset" paddingTop="2x">
          <PartReleaseRateSummary
            lastUpdatedAt={data?.lastUpdatedAt}
            numBufferedParts={data?.digitalBacklog?.[0]?.parts}
            partsPerDay={data?.partsPerDay}
            timeline={data?.timeline}
          />

          <Grid columns={{ lg: 2, sm: 1 }} gap="5x">
            <Cell style={{ paddingTop: '0.6rem' }}>
              <Tabs value={activeTab}>
                <StyledBlockTabsList
                  onValueChange={(value: PartsReleaseRateTab) => {
                    setActiveTab(value);
                  }}
                >
                  <Tab
                    label={partsReleaseRateTabs.wipByType}
                    value={partsReleaseRateTabs.wipByType}
                    variant="p2"
                  />
                  <Tab
                    label={partsReleaseRateTabs.outsByType}
                    value={partsReleaseRateTabs.outsByType}
                    variant="p2"
                  />
                </StyledBlockTabsList>

                <TabPanel value={partsReleaseRateTabs.wipByType}>
                  <PartReleaseGraph tableData={data?.wipByType} />
                </TabPanel>

                <TabPanel value={partsReleaseRateTabs.outsByType}>
                  <PartReleaseGraph tableData={data?.outsByType} />
                </TabPanel>
              </Tabs>
            </Cell>

            <DigitalBacklogSection data={data?.digitalBacklog} />
          </Grid>

          <PartReleaseRateQueues
            bufferedParts={data?.bufferedParts}
            waitingParts={data?.inProductionQueueParts}
          />
        </Stack>
      </Conditional>
    </>
  );
};
