import { Cell, CellContext, ColumnDef } from '@tanstack/react-table';
import { Flex } from 'gantri-components';
import { Job } from '../../../../../../api/jobs/jobs.types';
import { CellJobType } from '../../../../../part/components/part-jobs/components/cell-job-type';
import { CellJobName } from '../../../../../part/components/part-jobs/components/cell-job-name';
import { CellJobStep } from '../../../../../part/components/part-jobs/components/cell-job-step';
import { CellJobAttempt } from '../../../../../part/components/part-jobs/components/cell-job-attempt';
import { CellJobDuration } from '../../../../../part/components/part-jobs/components/cell-job-duration';
import { CellJobInstruction } from '../../../../../part/components/part-jobs/components/cell-job-instruction';
import { CellJobInventoryRequests } from '../../../../../part/components/part-jobs/components/cell-job-inventory-requests';
import { CellJobCreatedAt } from '../../../../../part/components/part-jobs/components/cell-job-created-at';
import { CellJobStartDate } from '../../../../../part/components/part-jobs/components/cell-job-start-date';
import { CellJobEndDate } from '../../../../../part/components/part-jobs/components/cell-job-end-date';
import { CellJobStatus } from '../../../../../part/components/part-jobs/components/cell-job-status';
import { CellJobParts } from '../../components';
import { JobHandoutsCell } from '../../../../../jobs/hooks/use-get-job-columns/components/job-handouts-cell';
import { JobMenu } from '../../../../../../components/common/jobMenu/jobMenu';
import { UseGetStockPageJobTableColumnsProps } from './use-get-stock-page-job-table-columns.types';
import { JobActionCell } from '../../../../../jobs/components';
import { useDownloadWithSmallToast } from '../../../../../../hooks/use-download-with-small-toast';
import { downloadToastText } from '../../../../../../hooks/use-download-with-small-toast/use-download-with-small-toast.constants';

export const useGetStockPageJobTableColumns = (
  props: UseGetStockPageJobTableColumnsProps,
) => {
  const { completeJob, onRefresh, startJob } = props;

  const {
    DownloadStatusToast: DownloadInstructionStatusToast,
    downloadSingleFile: downloadInstruction,
  } = useDownloadWithSmallToast({ ...downloadToastText.instruction });

  const {
    DownloadStatusToast: DownloadHandoutStatusToast,
    downloadSingleFile: downloadHandout,
  } = useDownloadWithSmallToast({ ...downloadToastText.handout });

  const columns: ColumnDef<Job>[] = [
    {
      accessorKey: 'type',
      cell: CellJobType,
      header: 'Type',
      meta: {
        hideable: false,
        reorderable: false,
      },
    },
    {
      accessorKey: 'description',
      cell: CellJobName,
      header: 'Name',
      meta: {
        hideable: false,
        reorderable: false,
      },
    },
    {
      accessorKey: 'attempt',
      cell: ({ getValue }: CellContext<Job, Job['attempt']>) => {
        return <CellJobAttempt attempt={getValue()} />;
      },
      header: 'Atmpt',
      meta: {
        hideable: false,
      },
      size: 60,
    },
    {
      accessorKey: 'step',
      cell: CellJobStep,
      header: 'Step',
      size: 100,
    },
    {
      accessorKey: 'duration',
      cell: CellJobDuration,
      header: 'Dur (m)',
      size: 80,
    },
    {
      accessorKey: 'instruction',
      cell: CellJobInstruction,
      header: 'Instructions',
      minSize: 280,
    },
    {
      accessorKey: 'partNames',
      cell: CellJobParts,
      header: 'Parts',
      minSize: 200,
    },
    {
      accessorKey: 'inventoryRequests',
      cell: CellJobInventoryRequests,
      header: 'Inventories',
      minSize: 280,
    },
    {
      accessorKey: 'product',
      cell: (props: CellContext<Job, Job['product']>) => {
        return <JobHandoutsCell {...props} downloadHandout={downloadHandout} />;
      },
      header: 'Handouts',
      id: 'handouts',
      meta: {
        getOnClick: ({ getValue }: Cell<Job, Job['product']>) => {
          const handouts = getValue()?.handouts || [];
          const isClickable = handouts.length === 1;

          return isClickable
            ? async () => {
                return downloadHandout({
                  fileName: handouts[0].name,
                  url: handouts[0].link,
                });
              }
            : undefined;
        },
        label: 'Handouts',
      },
      minSize: 160,
    },
    {
      accessorKey: 'createdAt',
      cell: CellJobCreatedAt,
      header: 'Created',
      meta: {
        hideable: false,
      },
      size: 100,
    },
    {
      accessorKey: 'startDate',
      cell: CellJobStartDate,
      header: 'Start',
      meta: {
        hideable: false,
      },
      size: 100,
    },
    {
      accessorKey: 'endDate',
      cell: CellJobEndDate,
      header: 'End',
      meta: {
        hideable: false,
      },
      size: 100,
    },
    {
      accessorKey: 'status',
      cell: CellJobStatus,
      header: 'Status',
      meta: {
        hideable: false,
      },
      size: 140,
    },
    {
      cell: (props: CellContext<Job, never>) => {
        return (
          <Flex alignItems="center" gap="2x" justifyContent="flex-end">
            <JobActionCell
              {...props}
              onComplete={completeJob}
              onStart={startJob}
            />
            <JobMenu job={props.row.original} onRefresh={onRefresh} />
          </Flex>
        );
      },
      header: '',
      id: 'actions',
      meta: { hideable: false, label: 'Actions', reorderable: false },
      size: 30,
    },
  ];

  return {
    DownloadHandoutStatusToast,
    DownloadInstructionStatusToast,
    columns,
  };
};
