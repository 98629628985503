import axios from 'axios';
import { sortBy } from 'lodash';
import { coreApiUrl } from '../../../../helpers/auth';
import { FetchAllInventoriesResponse } from './fetch-all-inventories.types';

export const fetchAllInventories = () => {
  return axios
    .get<FetchAllInventoriesResponse>(`${coreApiUrl}/inventory/all`)
    .then(({ data }) => {
      return {
        ...data,
        inventories: sortBy(data.inventories || [], 'name'),
      };
    });
};
