import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useNotification } from '../../../../hooks/useNotification';
import { bulkStartPrintTransferQueueSetJobs } from './bulk-start-print-transfer-queue-set-jobs';
import {
  BulkStartPrintTransferQueueSetJobsArgs,
  BulkStartPrintTransferQueueSetJobsResponse,
} from './bulk-start-print-transfer-queue-set-jobs.types';

export const useBulkStartPrintTransferQueueSetJobs = (args: {
  onDeleted: () => void;
  showLoading?: boolean;
}) => {
  const { onDeleted, showLoading } = args;

  const { notify, notifyAxiosError } = useNotification();

  const { isLoading, onMutate } = useQueryMutation<
    BulkStartPrintTransferQueueSetJobsArgs,
    BulkStartPrintTransferQueueSetJobsResponse
  >({
    config: {
      onError: (error) => {
        // @ts-expect-error
        if (error.response.status === 404) {
          onDeleted();
        } else {
          notifyAxiosError({
            error,
            fallbackMessage:
              'Unable to bulk start jobs in print transfer queue.',
          });
        }
      },
      onSuccess: async (data) => {
        notify(data.notice);
      },
    },
    mutationFn: bulkStartPrintTransferQueueSetJobs,
    showLoading,
  });

  return { isLoading, onBulkStartPrintTransferQueueSetJobs: onMutate };
};
