import {
  Cell,
  Conditional,
  Stack,
  FileUploader,
  HandleUploadsComplete,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { productAtoms } from '../../../../../../product.atoms';
import { useCloudinaryFileUploader } from '../../../../../../../../hooks/use-cloudinary-file-uploader';
import { Product } from '../../../../../../../../api/products/products.types';
import { useFetchProductData } from '../../../../../../hooks/use-fetch-product-data';
import { UploadedFileItem } from './components/uploaded-file-item';
import { UploadStackProps } from './upload-stack.types';

export const UploadStack = (props: UploadStackProps) => {
  const {
    expectedExtensions,
    fileNames = [],
    isRawFile,
    maxFileSizeMB,
    maxFiles = 10,
    type,
  } = props;

  const product = useRecoilValue(productAtoms.product);

  const { updateProduct } = useFetchProductData();

  const handleUploadsComplete: HandleUploadsComplete = async (files) => {
    const fileNames = files.map(({ fileName }) => {
      return fileName;
    });
    const updatedProduct: Product = {
      ...product,
      downloads: {
        ...product.downloads,
        [type]: [...(product.downloads?.[type] || []), ...fileNames],
      },
    };

    await updateProduct(updatedProduct);
  };

  const { fileUploaderProps } = useCloudinaryFileUploader<'products'>({
    directory: 'products',
    fileType: 'downloads',
    handleUploadsComplete,
    identifiers: {
      productId: product.id,
    },
    isRawFile,
  });

  return (
    <Cell>
      <Stack gap="x">
        {fileNames.map((fileName, index) => {
          return (
            <UploadedFileItem
              key={`${fileName}-${index}`}
              {...props}
              fileName={fileName}
              index={index}
            />
          );
        })}
        <Conditional condition={fileNames.length < maxFiles}>
          <Cell justifyContent="start">
            <FileUploader
              {...fileUploaderProps}
              buttonProps={{
                variant: 'ghost',
              }}
              expectedExtensions={expectedExtensions}
              maxFileSizeMB={maxFileSizeMB}
              maxUploadsAllowed={maxFiles}
              purifyFileName
              variant="button"
            />
          </Cell>
        </Conditional>
      </Stack>
    </Cell>
  );
};
