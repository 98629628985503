import { useEffect, useState } from 'react';
import { Button, Typography, Modal, Stack, Flex } from 'gantri-components';
import DatePicker from '../../../../../../../../../../components/common/date-picker';
import { TimePicker } from '../../../../../../../../../../components/common/time-picker/time-picker';
import { productsApi } from '../../../../../../../../../../api';
import { useNotification } from '../../../../../../../../../../hooks/useNotification';
import { formatDate } from '../../../../../../../../../../helpers/formatter';
import {
  ScheduleLaunchModalProps,
  CalendarDateType,
} from './schedule-launch-modal.types';

export const ScheduleLaunchModal = (props: ScheduleLaunchModalProps) => {
  const { launchDate, onClose, productId, refetchProduct } = props;

  const [date, setDate] = useState<CalendarDateType>({
    formattedFrom: '',
    formattedTo: '',
    formattedValue: '',
    from: '',
    to: '',
    value: '',
  });

  const [time, setTime] = useState({
    formatted: '',
    hour: '',
    meridiem: '',
    minute: '',
  });

  const { notifyError, onInterceptProcessingRequest, processing } =
    useNotification();

  const onConfirm = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        await productsApi.scheduleLaunch({
          id: productId,
          scheduledAt: `${date.formattedFrom} ${time.formatted}`,
        });

        await refetchProduct();
        onClose();
      } catch (error) {
        notifyError(
          'Launch date can be edited up to 30 minutes before current product launch date',
        );
      }
    });
  };

  useEffect(() => {
    if (launchDate) {
      const date = new Date(launchDate);

      setTime({
        formatted: time.formatted,
        hour: formatDate(date, 'hh'),
        meridiem: formatDate(date, 'a'),
        minute: formatDate(date, 'mm'),
      });
    }
  }, [launchDate]);

  return (
    <Modal
      footer={
        <>
          <Button text="Cancel" variant="secondary" onClick={onClose} />
          <Button
            disabled={!time || !(date && date.formattedFrom)}
            processing={processing}
            text="Confirm"
            onClick={onConfirm}
          />
        </>
      }
      header="Schedule launch"
      width={{ lg: '45rem', md: '100%' }}
      onClose={onClose}
    >
      <Stack justifyContent="center">
        <Stack gap=".5x">
          <Typography text="Date" textStyle="bold" />
          <DatePicker
            allowFutureDays
            allowFutureDaysOnly
            initialValue={launchDate ? { value: new Date(launchDate) } : null}
            range={false}
            onDateChanged={(date) => {
              return setDate(date);
            }}
          />
        </Stack>

        <Stack gap=".5x">
          <Flex gap="x">
            <Typography display="inline" text="Date" textStyle="bold" />
            <Typography
              color="t1"
              display="inline"
              text="(Pacific time, USA)"
            />
          </Flex>

          <TimePicker onChange={setTime} {...time} />
        </Stack>
      </Stack>
    </Modal>
  );
};
