import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  BulkCompletePrintTransferQueueSetJobsArgs,
  BulkCompletePrintTransferQueueSetJobsResponse,
} from './bulk-complete-print-transfer-queue-set-jobs.types';

export const bulkCompletePrintTransferQueueSetJobs = (
  args: BulkCompletePrintTransferQueueSetJobsArgs,
) => {
  const { queueId, setIndex, ...body } = args;

  return axios
    .put<BulkCompletePrintTransferQueueSetJobsResponse>(
      `${getCoreApiUrl(
        'queue/print-transfer',
      )}/${queueId}/${setIndex}/bulk/complete`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
