import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { FetchInvitesArgs, FetchInvitesResponse } from './fetch-invites.types';

export const fetchInvites = (body: FetchInvitesArgs) => {
  return axios.post<FetchInvitesResponse>(
    `${baseApiUrl}/paginatedInvites`,
    body,
  );
};
