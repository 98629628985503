import { FC } from 'react';
import {
  Button,
  Conditional,
  Flex,
  Icon,
  Tooltip,
  Typography,
} from 'gantri-components';
import { ReferralUser } from '../../../api/referrals/referrals.types';
import { canSendReferral } from '../referrals.adapter';

export const RewardCell: FC<{
  onShowConfirmation: CallableFunction;
  referral: ReferralUser;
}> = ({ onShowConfirmation, referral }) => {
  const {
    cancelReason,
    cancelRewardDateSec,
    id: userId,
    purchaseDate,
    purchaseDateSec,
    purchaseStatus,
    rewardDate,
  } = referral;

  if (cancelRewardDateSec) {
    const details = cancelReason.details ? `: ${cancelReason.details}` : '';

    return (
      <Typography
        color="t2"
        icon={
          <Tooltip
            description={`${cancelReason.reason}${details}`}
            position="top"
          >
            <Icon name="alert:i_circle" />
          </Tooltip>
        }
        iconPosition="right"
        text="Cancelled"
      />
    );
  }

  if (purchaseStatus === 'Cancelled') {
    return <Typography text={purchaseStatus} />;
  }

  if (!purchaseDate) {
    return <Typography text="-" />;
  }

  if (rewardDate) {
    return <Typography text={rewardDate} />;
  }

  const allowSend =
    purchaseDate && canSendReferral(purchaseDate, purchaseDateSec);

  return (
    <Flex alignItems="center" gap="x">
      <Button
        disabled={!allowSend}
        text="Send"
        variant="secondary"
        onClick={() => {
          onShowConfirmation(referral.id);
        }}
      />
      <Conditional condition={!allowSend}>
        <Tooltip description="Purchased less than 30 days ago" position="top">
          <Icon name="alert:i_circle" />
        </Tooltip>
      </Conditional>
    </Flex>
  );
};
