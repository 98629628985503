import { Flex } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledDropZoneFlex = styled(Flex)<{
  $isDisabled: boolean;
  $isDraggingOver: boolean;
  $processing: boolean;
}>`
  ${({ $isDisabled, $isDraggingOver, $processing, theme }) => {
    const getBorder = () => {
      if ($isDisabled) {
        return `1px dashed ${theme.colors.dividers.t2}`;
      }

      if ($isDraggingOver) {
        return `1px dashed ${theme.colors.iconography.success}`;
      }

      return `1px dashed ${theme.colors.iconography.link}`;
    };

    return css`
      border: ${getBorder()};
      overflow: hidden;
      aspect-ratio: 1;
      cursor: ${$isDisabled || $processing ? 'not-allowed' : 'pointer'};
      background-color: ${$isDraggingOver
        ? theme.name === 'dark'
          ? theme.colors.palette.blue_900
          : theme.colors.palette.blue_50
        : $processing
        ? theme.colors.surfaces.monochrome.t1Alt
        : undefined};
    `;
  }}
`;
