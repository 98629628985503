import React, { FC, useMemo, useState } from 'react';
import {
  Conditional,
  Dropdown,
  FormikInput,
  Grid,
  SearchField,
  Typography,
} from 'gantri-components';
import { useField, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import * as yup from 'yup';
import { orderTypeOptions } from './details-section.constant';
import { useFetchUsersEmailsQuery } from '../../../../api/products/routes/fetch-users-emails';
import { ResaleCertificate } from './resale-certificate';

export const NewOrderDetailsSection: FC = React.memo(() => {
  const { setFormikState } = useFormikContext<any>();
  const [{ value: orderType }] = useField<string>('orderType');
  const [{ value: email }] = useField<string>('email');
  const [query, setQuery] = useState<string>('');

  const showInvoiceInput = useMemo(() => {
    if (!orderType) return false;

    return ['Trade', 'Third Party', 'Wholesale'].includes(orderType);
  }, [orderType]);

  const isWholesaleOrder = useMemo(() => {
    if (!orderType) return false;

    return orderType === 'Wholesale';
  }, [orderType]);

  const { isLoading, users } = useFetchUsersEmailsQuery({
    enabled: !!query,
    search: query,
    transform: (response) => {
      return response.users.map((user) => {
        return {
          ...user,
          id: user.email,
          userId: user.id,
        };
      });
    },
  });

  return (
    <Grid columns={2} gap="2x">
      <FormikInput
        Field={<Dropdown items={orderTypeOptions} placeholder="Select Type" />}
        labelText="Type"
        name="orderType"
        required
      />

      <Conditional condition={showInvoiceInput}>
        <FormikInput
          labelText="Invoice #"
          name="invoice"
          placeholder="Invoice"
        />

        <FormikInput
          Field={
            <SearchField
              creatableLabel="user"
              idProperty="id"
              isCreatable
              isValidCreatableItem={(term) => {
                return yup.string().email().isValidSync(term);
              }}
              items={isLoading ? [] : users ?? []}
              labelProperty="id"
              maxHeight="33rem"
              minSearchLength={3}
              placeholder="Search"
              processing={isLoading}
              renderItem={(user) => {
                return (
                  <Typography
                    horizontalPadding="1.6rem"
                    text={user.email}
                    variant="p2"
                    verticalPadding="0.8rem"
                  />
                );
              }}
              searchable
              searchMode="external"
              value={email}
              onSelect={(op) => {
                const fileName = op?.resaleCertificate
                  ?.split('%2Fresale-certificate%2F')[1]
                  ?.split('?')?.[0];

                const fileUrl = op?.resaleCertificate;

                setFormikState((prevState) => {
                  return {
                    ...prevState,
                    values: {
                      ...prevState.values,
                      resaleCertificate: op?.hasResaleCertificate
                        ? {
                            fileName,
                            fileUrl,
                          }
                        : {
                            fileBlob: undefined,
                            fileName: undefined,
                            fileSize: undefined,
                            fileUrl: undefined,
                            metadata: undefined,
                          },
                      userSelected: op,
                    },
                  };
                });
              }}
              onTextChange={debounce(setQuery, 300)}
            />
          }
          fieldVariant="standard"
          labelText="Email"
          name="email"
          required
        />
      </Conditional>

      <Conditional condition={isWholesaleOrder}>
        <ResaleCertificate />
      </Conditional>
    </Grid>
  );
});
