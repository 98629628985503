import {
  generateStylesForResolutionAwareProps,
  Stack,
} from 'gantri-components';
import styled, { css, CSSProperties } from 'styled-components';
import {
  ExtractStyleFromResolutionAwarePropEntry,
  ResolutionAwareProp,
} from 'gantri-components/dist/types';

export const StyledChartWrapper = styled.div<{
  $minHeight: ResolutionAwareProp<CSSProperties['minHeight']>;
}>`
  /* overflow: hidden; // prevents chart jump from tooltip being rendered on mouse enter causing overflow */
  width: 99%; // https://github.com/plouc/nivo/issues/411#issuecomment-761939245
  height: 100%;

  ${({ $minHeight }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'min-height',
        resolutionAwareProp: $minHeight,
        valueFormatter: (value) => {
          return value || '12rem';
        },
      },
    ];

    return generateStylesForResolutionAwareProps(entries);
  }}

  > div > div {
    // solves issue with div wrapping chart svg adding ~5px extra height
    display: grid;
  }

  // solves issue with linear gradient CSS defs in Safari sometimes failing to load, causing a black fill area
  path[fill='url(#part-level-color)'] {
    ${({ theme }) => {
      return css`
        fill: ${theme.colors.surfaces.blue.t3};
      `;
    }}
  }
  // solves issue with linear gradient CSS defs in Safari sometimes failing to load, causing a black fill area
  path[fill='url(#product-level-color)'] {
    ${({ theme }) => {
      return css`
        fill: ${theme.colors.palette.monochrome_700};
      `;
    }}
  }
`;

export const StyledTooltipStack = styled(Stack)`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.surfaces.monochrome.t1};
      border: 1px solid ${theme.colors.dividers.t1};
      border-radius: 2px;
    `;
  }}
`;
