import { useState } from 'react';
import { useAsync } from 'react-use';
import { useQueryFetch } from '../use-fetch/use-query-fetch';
import { useNotification } from '../useNotification';
import { UseGenericFetchProps } from './use-generic-fetch-query.types';

/**
 * @example
 * const [YOUR_FETCH_QUERY_NAME]QueriesObj = getGenericQueryObj({
 *   groupName: YOUR_UNIQUE_GROUP_NAME,
 *   query,
 * });
 *
 * export const use[YOUR_FETCH_QUERY_NAME] = <
 *   TransformedData = YOUR_FETCH_RESPONSE_TYPE,
 * >(
 *   props?: GenericFetchProps<
 *     YOUR_FETCH_ARGS_TYPE,
 *     YOUR_FETCH_RESPONSE_TYPE,
 *     TransformedData
 *   >,
 * ) =>
 *   useGenericFetchQuery({
 *     fallbackErrorMessage: 'Unable to fetch ___________.',
 *     queryObj: YOUR_FETCH_OBJ,
 *     ...props,
 *   });
 *
 * export const useInvalidate[YOUR_FETCH_QUERY_NAME]Cache = () => {
 *   const invalidate[YOUR_FETCH_QUERY_NAME]Cache = useGetInvalidateQueryCache(
 *     YOUR_FETCH_OBJ,
 *   );
 *
 *   return { invalidate[YOUR_FETCH_QUERY_NAME]Cache };
 * };
 */
export const useGenericFetchQuery = <
  FetchArgs extends Record<string, any> | never,
  FetchResponse extends Record<any, any>,
  TransformedData = FetchResponse,
>(
  props: UseGenericFetchProps<FetchArgs, FetchResponse, TransformedData>,
) => {
  const {
    cacheTime,
    enabled = true,
    fallbackErrorMessage,
    fetchArgs,
    onError,
    onStart,
    onSuccess,
    queryObj,
    showLoading,
    transform = (response) => {
      return response;
    },
    transformDependencies,
  } = props;

  const { notifyAxiosError } = useNotification();

  const [response, setResponse] = useState<TransformedData>();

  const { data, isLoading } = useQueryFetch<FetchResponse>({
    config: {
      cacheTime,
      enabled,

      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: fallbackErrorMessage,
        });

        onError?.(error);
      },
      queryFn: async () => {
        await onStart?.();

        return queryObj.query(fetchArgs);
      },
      queryKey: queryObj.key(fetchArgs),
    },
    showLoading,
    transformDependencies,
  });

  useAsync(async () => {
    if (enabled && !isLoading) {
      const transformedData = transform(data);

      await onSuccess?.(transformedData);

      setResponse(transformedData);
    }
  }, [data, enabled, isLoading]);

  return {
    data: response,
    isLoading,
  };
};
