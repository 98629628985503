import { fetchUserNpsReviews } from './fetch-user-nps-reviews';
import {
  useGetInvalidateQueryCache,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';
import { useNotification } from '../../../../hooks/useNotification';

interface UseFetchUserReviewsProps {
  userId: number;
}

export const useFetchUserNpsReviewsQuery = (
  props?: UseFetchUserReviewsProps,
) => {
  const { userId } = props ?? {};
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch({
    config: {
      enabled: !!userId,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch user nps reviews.',
        });
      },
      queryFn: () => {
        return fetchUserNpsReviews.query(userId);
      },
      queryKey: fetchUserNpsReviews.key(userId),
    },
    showLoading: true,
  });

  return { ...data, isLoading };
};

export const useInvalidateFetchUserNpsFetchCache = () => {
  return useGetInvalidateQueryCache(fetchUserNpsReviews);
};
