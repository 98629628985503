import { ProductColorCode } from 'gantri-components';
import { ProductSizeCode } from '../../../constants/options';
import { getSearchFilterAtomFamily } from '../../common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../helpers/get-generic-atom-family';
import { AtomPageName } from '../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { StocksSortingField } from '../../../pages/stocks/stocks.types';
import { StockAttentionAlert, StocksStatus } from './stocks-filter.constants';

export const stocksFiltersDefaults: {
  filters: {
    /**
     * `true` === Assigned only
     *
     * `false` === Idle only
     *
     * `null` === All
     */
    assigned: boolean | null;
    attention: StockAttentionAlert[];
    color: ProductColorCode;
    highPriority: boolean;
    orderId: number;
    productId: number;
    size: ProductSizeCode;
    sortBy: StocksSortingField;
    statuses: StocksStatus[];
  };
} = {
  filters: {
    assigned: null,
    attention: [],
    color: undefined,
    highPriority: false,
    orderId: null,
    productId: null,
    size: null,
    sortBy: 'Priority',
    statuses: [],
  },
};

const atomKeyPrefix = 'STOCKS_FILTERS';

export const pageName: AtomPageName = 'stocks';

export const stocksPageAtoms = {
  defaults: stocksFiltersDefaults,
  filters: {
    assigned: getGenericAtomFamily({
      defaultValue: stocksFiltersDefaults.filters.assigned,
      key: `${atomKeyPrefix}-assigned`,
    })(pageName),
    attention: getGenericAtomFamily({
      defaultValue: stocksFiltersDefaults.filters.attention,
      key: `${atomKeyPrefix}-attention`,
    })(pageName),
    color: getGenericAtomFamily({
      defaultValue: stocksFiltersDefaults.filters.color,
      key: `${atomKeyPrefix}-color`,
    })(pageName),
    highPriority: getGenericAtomFamily({
      defaultValue: stocksFiltersDefaults.filters.highPriority,
      key: `${atomKeyPrefix}-high-priority`,
    })(pageName),
    orderId: getGenericAtomFamily({
      defaultValue: stocksFiltersDefaults.filters.orderId,
      key: `${atomKeyPrefix}-order-id`,
    })(pageName),
    productId: getGenericAtomFamily({
      defaultValue: stocksFiltersDefaults.filters.productId,
      key: `${atomKeyPrefix}-product-id`,
    })(pageName),
    size: getGenericAtomFamily({
      defaultValue: stocksFiltersDefaults.filters.size,
      key: `${atomKeyPrefix}-size`,
    })(pageName),
    statuses: getGenericAtomFamily({
      defaultValue: stocksFiltersDefaults.filters.statuses,
      key: `${atomKeyPrefix}-statuses`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getGenericAtomFamily({
    defaultValue: stocksFiltersDefaults.filters.sortBy,
    key: `${atomKeyPrefix}-sort-by`,
  })(pageName),
};
