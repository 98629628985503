import { IconType } from 'gantri-components/dist/components/icon/icon.type';
import { JobStep, jobSteps, JobType, jobTypeOptions } from './options';

/** Job types associated with product creation (plus the `Repair` job type). */
export const jobTypesForJobStep = {
  [jobSteps.assemble]: [jobTypeOptions.stage, jobTypeOptions.assemble],
  [jobSteps.finish]: [
    jobTypeOptions.glue,
    jobTypeOptions.sandRaw,
    jobTypeOptions.bondo,
    jobTypeOptions.mask,
    jobTypeOptions.tumbleRaw,
    jobTypeOptions.sandPrimed,
    jobTypeOptions.sandPainted,
    jobTypeOptions.prime,
    jobTypeOptions.paint,
    jobTypeOptions.qc,
    jobTypeOptions.stage,
  ],
  [jobSteps.pack]: [jobTypeOptions.pack],
  [jobSteps.print]: [
    jobTypeOptions.transfer,
    jobTypeOptions.print,
    jobTypeOptions.qc,
  ],
  [jobSteps.qa]: [jobTypeOptions.qc],
  [jobSteps.technician]: [jobTypeOptions.repair],
} satisfies Record<JobStep, JobType[]>;

/** Job types associated with non-product creation jobs (excluding the `Repair` job type). */
export const nonProductJobTypesForJobStep = {
  [jobSteps.assemble]: [
    jobTypeOptions.handoutsPrinting,
    jobTypeOptions.inventoryManagement,
    jobTypeOptions.training,
  ],
  [jobSteps.finish]: [
    jobTypeOptions.inventoryManagement,
    jobTypeOptions.paintBoothCleaning,
    jobTypeOptions.paintGunCleaning,
    jobTypeOptions.sandRoomCleaning,
    jobTypeOptions.training,
  ],
  [jobSteps.pack]: [
    jobTypeOptions.inventoryManagement,
    jobTypeOptions.training,
  ],
  [jobSteps.print]: [
    jobTypeOptions.bedTaping,
    jobTypeOptions.inventoryManagement,
    jobTypeOptions.rAndDSupport,
    jobTypeOptions.shredding,
    jobTypeOptions.spoolChangingBulk,
    jobTypeOptions.training,
  ],
  [jobSteps.technician]: [
    jobTypeOptions.heavyMachineryRepair,
    jobTypeOptions.inventoryManagement,
    jobTypeOptions.rAndDSupport,
    jobTypeOptions.training,
  ],
} satisfies Partial<Record<JobStep, JobType[]>>;

export const primaryStepsForJobs = [
  'Print',
  'Finish',
  'Assemble',
  'QA',
  'Pack',
] satisfies JobStep[];

type DeprecatedJobType = 'Sand' | 'QA';

export const jobTypeToIconTypeMap: Record<
  JobType | DeprecatedJobType,
  IconType
> = {
  [jobTypeOptions.assemble]: 'work:assemble',
  [jobTypeOptions.bedTaping]: 'work:tape',
  [jobTypeOptions.harvest]: 'work:basket_filled',
  [jobTypeOptions.bondo]: 'actions:link',
  [jobTypeOptions.glue]: 'work:glue',
  [jobTypeOptions.handoutsPrinting]: 'docs:document_lines',
  [jobTypeOptions.heavyMachineryRepair]: 'work:wrench',
  [jobTypeOptions.inventoryManagement]: 'work:triangle_ruler',
  [jobTypeOptions.mask]: 'work:mask',
  [jobTypeOptions.pack]: 'work:box',
  [jobTypeOptions.paint]: 'work:water_drop',
  [jobTypeOptions.paintBoothCleaning]: 'work:broom',
  [jobTypeOptions.paintGunCleaning]: 'work:broom',
  [jobTypeOptions.prime]: 'work:water_drop_dotted',
  [jobTypeOptions.print]: 'ui-control:lines_three',
  [jobTypeOptions.qc]: 'actions:thumbs_up',
  [jobTypeOptions.rAndDSupport]: 'work:light_bulb',
  [jobTypeOptions.repair]: 'work:print_nozzle',
  [jobTypeOptions.sandPainted]: 'work:sand_painted',
  [jobTypeOptions.sandPrimed]: 'work:sand_primed',
  [jobTypeOptions.sandRaw]: 'work:sand_paper',
  [jobTypeOptions.sandRoomCleaning]: 'work:broom',
  [jobTypeOptions.shredding]: 'ui-control:lines_three_vertical',
  [jobTypeOptions.spoolChanging]: 'work:spool',
  [jobTypeOptions.spoolChangingBulk]: 'work:spool',
  [jobTypeOptions.stage]: 'work:assemble',
  [jobTypeOptions.training]: 'people:people',
  [jobTypeOptions.transfer]: 'work:sd_card_outline',
  [jobTypeOptions.tumbleRaw]: 'work:tumbler',
  /** @deprecated legacy value */
  QA: 'actions:thumbs_up',
  /** @deprecated legacy value */
  Sand: 'work:sand_paper',
};

export const maxFinishAttemptsBeforeRestart = 5;
