import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useRouter } from '../../../hooks';
import { userAtoms } from '../../../global-atoms/users';
import routePaths from '../../../config/route-paths';
import { ProtectedRoutePropsDef } from './protected-route.types';

export const ProtectedRoute: FC<ProtectedRoutePropsDef> = (props) => {
  const { Component, allowedFor, getUnauthorizedRedirect } = props;
  const { location } = useRouter();

  const { type } = useRecoilValue(userAtoms.user);

  if (!allowedFor || (type && allowedFor.includes(type))) {
    return <Component />;
  }

  const unauthorizedRedirect = getUnauthorizedRedirect?.({ userType: type });

  return (
    <Navigate
      state={{ from: location.pathname }}
      to={unauthorizedRedirect || routePaths.unauthorized}
    />
  );
};
