import { Button } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useDeletePressRecordMutation } from '../../../../../../../api/press';
import { pressRecordModalAtoms } from '../../../press-record-modal.atoms';
import { ActionDeleteFooterProps } from './action-delete-footer.types';

export const ActionDeleteFooter = (props: ActionDeleteFooterProps) => {
  const { handleCloseModal } = props;

  const pressRecord = useRecoilValue(pressRecordModalAtoms.pressRecord);

  const { isLoading: processingDeletion, onDeletePressRecord } =
    useDeletePressRecordMutation();

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={handleCloseModal}
      />
      <Button
        processing={processingDeletion}
        size="large"
        text="Delete"
        type="submit"
        variant="primaryAlert"
        onClick={async () => {
          await onDeletePressRecord({ id: pressRecord.id });
        }}
      />
    </>
  );
};
