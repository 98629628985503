import { Button } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledThumbnailLinkWrapper = styled.div`
  ${({ theme }) => {
    return css`
      div {
        box-shadow: ${theme.colors.shadow.low};
        border: 1px solid ${theme.colors.dividers.t1};
        border-radius: 2px;
      }
    `;
  }}
`;

export const StyledMiniButton = styled(Button)`
  &[type='button'] {
    padding: 0.2rem 0.6rem;
  }
`;
