import {
  JobBlockBondo,
  JobBlockFinish,
  JobBlockPrint,
  JobBlockStock,
} from '../../products.types';

export type UpdateProductJobBlocksArgs =
  | {
      /** The block's ID. */
      id: number;
      jobBlock: PartJobBlock;
      variantIds?: never;
    }
  | {
      /** The block's ID. */
      id: number;
      jobBlock: StockJobBlock;
      variantIds: number[];
    };

type PartJobBlock = {
  bondoBlock?: JobBlockBondo;
  finishBlock: JobBlockFinish;
  printBlock: JobBlockPrint[];
  type: typeof jobBlockTypes.part;
};

type StockJobBlock = {
  stockBlock: JobBlockStock;
  type: typeof jobBlockTypes.stock;
};

export interface UpdateProductJobBlocksResponse {
  notice: string;
  success: boolean;
}

export const jobBlockTypes = {
  part: 'Part',
  stock: 'Stock',
} as const;
