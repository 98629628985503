import { useModal } from 'gantri-components';
import { useRef } from 'react';
import { CancelReferralModal } from './cancel-referral-modal';
import { useSpinner } from '../../../../hooks';
import { referralsApi } from '../../../../api';
import { useNotification } from '../../../../hooks/useNotification';

export const useCancelReferralModal = (props?: {
  onRefreshReferralList?: CallableFunction;
}) => {
  const { onInterceptRequest } = useSpinner();
  const { notify, notifyAxiosError } = useNotification();

  const referralIdRef = useRef<number>();

  const onCancelReferral = async (values) => {
    const { reason, reasonDescription } = values;

    await onInterceptRequest(async () => {
      try {
        const response = await referralsApi.cancelReferral(
          referralIdRef.current,
          { details: reasonDescription, reason },
        );

        notify(response.data.notice);

        await props?.onRefreshReferralList();
      } catch (error) {
        notifyAxiosError(error);
      } finally {
        hideCancelModal();
      }
    });
  };

  const [showCancelModal, hideCancelModal] = useModal(() => {
    return (
      <CancelReferralModal
        onCancel={hideCancelModal}
        onSubmit={onCancelReferral}
      />
    );
  }, []);

  const onShowCancelReferral = (referralId: number) => {
    referralIdRef.current = referralId;
    showCancelModal();
  };

  return { onShowCancelReferral };
};
