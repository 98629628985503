import { Modal } from 'gantri-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { productAtoms } from '../../../../../../product.atoms';
import { FullScreenModalHeader } from '../../../../../../../jobs/components/modals/common/full-screen-modal-header';
import { useHandleCloseModal } from '../../../../../../../jobs/components/modals/hooks/use-handle-close-modal';
import { BulkUploadModalFooter } from './components/bulk-upload-modal-footer';
import { BulkUploadModalContent } from './components/bulk-upload-modal-content';
import { bulkUploadModalAtoms } from './bulk-upload-modal.atoms';
import { useResetRecoilAtomsOnMount } from '../../../../../../../../hooks/use-reset-recoil-atoms-on-mount';
import { exitWarningModalHeader } from './bulk-upload-modal.constants';
import { BulkUploadExitWarningModalContent } from './components/bulk-upload-exit-warning-modal-content';
import { useInvalidateFetchSkuAssetsCache } from '../../../../../../../../api/products/routes/fetch-sku-assets';
import { useFetchProductData } from '../../../../../../hooks/use-fetch-product-data';

export const BulkUploadModal = (props: { onClose: () => void }) => {
  const { onClose } = props;

  const product = useRecoilValue(productAtoms.product);

  const numFilesAdded = useRecoilValue(bulkUploadModalAtoms.numFilesAdded);
  const numFilesRemoved = useRecoilValue(bulkUploadModalAtoms.numFilesRemoved);

  const [disableSave, setDisableSave] = useRecoilState(
    bulkUploadModalAtoms.disableSave,
  );

  const { ResetAtomsWrapper } =
    useResetRecoilAtomsOnMount(bulkUploadModalAtoms);

  const { invalidateFetchBulkProductAssetsCache } =
    useInvalidateFetchSkuAssetsCache();

  const { invalidateFetchProductCache } = useFetchProductData();

  const handleCloseModal = useHandleCloseModal({
    onClose,
    onUpdate: async () => {
      await invalidateFetchBulkProductAssetsCache();
      await invalidateFetchProductCache();
    },
    updateAfterClose: true,
  });

  useEffect(() => {
    const disableSave = !numFilesAdded && !numFilesRemoved;

    setDisableSave(disableSave);
  }, [numFilesAdded, numFilesRemoved]);

  return (
    <ResetAtomsWrapper>
      <Modal
        closeable={false}
        contentStackProps={{
          alignContent: 'stretch',
          horizontalPadding: '0',
          verticalPadding: '0',
        }}
        footer={<BulkUploadModalFooter handleCloseModal={handleCloseModal} />}
        header={
          <FullScreenModalHeader
            disableLeavePageConfirmation={disableSave}
            exitModalHeaderText={exitWarningModalHeader}
            handleCloseModal={handleCloseModal}
            ModalContent={<BulkUploadExitWarningModalContent />}
            text={`${product.name} ${product.category} - ${exitWarningModalHeader}`}
          />
        }
        isFullScreen
      >
        <BulkUploadModalContent />
      </Modal>
    </ResetAtomsWrapper>
  );
};
