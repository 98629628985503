import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { updateProduct } from './update-product';
import {
  UpdateProductArgs,
  UpdateProductResponse,
} from './update-product.types';

export const useUpdateProduct = <TransformedData = UpdateProductResponse>(
  props: GenericMutateQueryProps<
    UpdateProductArgs,
    UpdateProductResponse,
    TransformedData
  >,
) => {
  const { onMutate: onUpdateProduct, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to update product.',
    mutationFn: updateProduct,
    ...props,
  });

  return { ...rest, onUpdateProduct };
};
