import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  ArchivePartTemplateArgs,
  ArchivePartTemplateResponse,
} from './archive-part-template.types';
import { archivePart } from './archive-part-template';

export const useArchivePartTemplate = <
  TransformedData = ArchivePartTemplateResponse,
>(
  props?: GenericMutateQueryProps<
    ArchivePartTemplateArgs,
    ArchivePartTemplateResponse,
    TransformedData
  >,
) => {
  const { onMutate: onArchivePart, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to delete part.',
    mutationFn: archivePart,
    ...props,
  });

  return { ...rest, onArchivePart };
};
