import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { ChangeItemsArgs, ChangeItemsResponse } from './change-items.types';

export const changeItems = (args: ChangeItemsArgs) => {
  const { changeItemData, orderId } = args;

  return axios.put<ChangeItemsResponse>(
    `${getCoreApiUrl('transactions')}/${orderId}/change-items`,
    {
      stocks: changeItemData,
    },
  );
};
