import styled, { css } from 'styled-components';
import { palette, Typography } from 'gantri-components';

export const StyledTypographyWithAccent = styled(Typography)`
  ${({ theme }) => {
    return css`
      i {
        font-family: Sohne Extrafett, 'Helvetica', sans-serif;
        font-style: normal;

        &[data-color='green'] {
          color: ${theme.name === 'dark'
            ? palette.green_300
            : palette.green_400};
        }

        &[data-color='gold'] {
          color: ${theme.name === 'dark' ? palette.gold_300 : palette.gold_400};
        }
      }
    `;
  }}
`;

export const StyledVersionTypography = styled(Typography)`
  ${({ theme }) => {
    return css`
      [data-color='green'] {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        color: ${theme.name === 'dark' ? palette.green_300 : palette.green_400};
      }

      [data-color='gold'] {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        color: ${theme.name === 'dark' ? palette.gold_300 : palette.gold_400};
      }
    `;
  }}
`;
