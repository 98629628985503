import { Conditional, Grid, Icon, Stack, Typography } from 'gantri-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useAsync, useToggle } from 'react-use';
import { publishDraftModalAtoms } from '../../../publish-draft-modal.atoms';
import {
  stockMajorDescription,
  stockMinorDescription,
} from './confirm-publish-content.constants';
import { versionApi } from '../../../../../../../../../../../api';
import { useNotification } from '../../../../../../../../../../../hooks/useNotification';
import { ConfirmPublishContentProps } from './confirm-publish-content.types';

export const ConfirmPublishContent = (props: ConfirmPublishContentProps) => {
  const { product } = props;

  const draftVersion = product.version.version;
  const isMinorVersion = product.version.type === 'Minor';

  const draftDetails = useRecoilValue(publishDraftModalAtoms.draftDetails);
  const [numAffectedStocks, setNumAffectedStocks] = useRecoilState(
    publishDraftModalAtoms.numAffectedStocks,
  );
  const [isFetching, toggleIsFetching] = useToggle(true);
  const { notifyAxiosError } = useNotification();

  const fetchStocksThatWillUpdate = async () => {
    try {
      const { data } = await versionApi.getUpdatedStocks({
        productId: product.id,
        type: product.version.type,
        versionId: product.version.id,
      });

      if (data?.success) {
        setNumAffectedStocks(data.numberOfStocks);
      }
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to fetch number of affected stocks.',
      });
    } finally {
      toggleIsFetching();
    }
  };

  useAsync(async () => {
    await fetchStocksThatWillUpdate();
  }, []);

  return (
    <Grid columnGap="2rem" columns={{ lg: 'repeat(2, 1fr)', sm: '1fr' }}>
      <Stack gap="2rem">
        <Stack gap="0.6rem">
          <Typography fontWeight="bold" text="Version" />
          <Typography text={`v${draftVersion}`} />
        </Stack>
        <Stack gap="0.6rem">
          <Typography fontWeight="bold" text="Version Type" />
          <Typography text={product.version.type} />
        </Stack>
        <Stack gap="0.6rem">
          <Typography fontWeight="bold" text="Changes described" />
          <Typography text={draftDetails} />
        </Stack>
      </Stack>
      <Stack gap="0.6rem">
        <Typography fontWeight="bold" text="Updated Stocks" />
        <Conditional
          condition={isFetching}
          Fallback={<Typography text={numAffectedStocks} />}
        >
          <Icon name="animated:loader" />
        </Conditional>
        <Typography
          color="t2"
          text={isMinorVersion ? stockMinorDescription : stockMajorDescription}
          variant="p3"
        />
      </Stack>
    </Grid>
  );
};
