import { Conditional, Stack, Table, Typography } from 'gantri-components';
import { startCase } from 'lodash';
import { useRecoilValue } from 'recoil';
import { machineStatuses } from '../../../../../../../constants/machines';
import { ModalContentHeading } from '../../../../../../jobs/components/modals/common/modal-content-heading';
import { useGetBulkUpdateMachinesColumns } from '../../../../../hooks/use-get-bulk-update-machines-columns';
import { setMachinesOfflineModalAtoms } from '../../../set-machines-offline-modal.atoms';

export const ReviewOfflinedMachinesContent = () => {
  const hasMachinesWithInvalidStatuses = useRecoilValue(
    setMachinesOfflineModalAtoms.hasMachinesWithInvalidStatuses,
  );
  const machinesOfflined = useRecoilValue(
    setMachinesOfflineModalAtoms.machinesOfflined,
  );
  const numMachinesOfflined = machinesOfflined.length;

  const machinesOfflinedColumns = useGetBulkUpdateMachinesColumns();

  return (
    <Stack gap="6x">
      <Stack gap="3x">
        <Conditional condition={!!numMachinesOfflined}>
          <ModalContentHeading
            color="success"
            titleText={`${machinesOfflined.length} machine(s) offlined successfully`}
          />
        </Conditional>

        <Conditional condition={hasMachinesWithInvalidStatuses}>
          <Typography
            text={`Some machines were in ${
              machineStatuses.offline
            } or ${startCase(
              machineStatuses.inProgress,
            )} status and were not eligible to offline.`}
          />
        </Conditional>
      </Stack>

      <Conditional condition={!!numMachinesOfflined}>
        <Stack gap="x">
          <Typography text="Machines offlined" variant="h5" />
          <Table columns={machinesOfflinedColumns} data={machinesOfflined} />
        </Stack>
      </Conditional>
    </Stack>
  );
};
