import { Box, Conditional, Overlay, Stack } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { userAtoms } from '../../global-atoms/users';
import { sandingMinutesProgressVariants } from './sanding-minutes-progress.constants';
import { SandingMinutesProgressPropsDef } from './sanding-minutes-progress.types';
import { useHandleSandingMinutesProgress } from './hooks/use-handle-sanding-minutes-progress';
import { SandingMinutesDetails } from './components/sanding-minutes-details';
import { SandingMinutesEarnedToast } from './components/sanding-minutes-earned-toast';

export const SandingMinutesProgress = (
  props: SandingMinutesProgressPropsDef,
) => {
  const { progressStrokeColor, variant, ...stackProps } = props;

  const { isSander } = useRecoilValue(userAtoms.user);

  const isCompact = variant === sandingMinutesProgressVariants.compact;
  const {
    isActive,
    isLoading,
    isToastRendered,
    lastCompletedJobDuration,
    newSandingMinutes,
    sandingMinutes,
  } = useHandleSandingMinutesProgress();

  return (
    <Conditional key="is-sander" condition={isSander}>
      <Stack
        gap="0"
        rows="1fr"
        {...stackProps}
        horizontalPadding={isCompact ? 'x' : 'unset'}
      >
        <SandingMinutesDetails
          isActive={isActive}
          isCompact={isCompact}
          isLoading={isLoading}
          newSandingMinutes={newSandingMinutes}
          progressStrokeColor={progressStrokeColor}
          sandingMinutes={sandingMinutes}
        />

        <Conditional
          key="is-compact"
          condition={isCompact}
          Fallback={
            <Overlay
              content={
                <SandingMinutesEarnedToast
                  isActive={isActive}
                  isCompact={isCompact}
                  lastCompletedJobDuration={lastCompletedJobDuration}
                />
              }
              open={isToastRendered}
              renderInPortal={false}
              triggerEvent="manual"
            />
          }
        >
          <Conditional key="is-toast-rendered" condition={isToastRendered}>
            <Box>
              <SandingMinutesEarnedToast
                isActive={isActive}
                isCompact={isCompact}
                lastCompletedJobDuration={lastCompletedJobDuration}
              />
            </Box>
          </Conditional>
        </Conditional>
      </Stack>
    </Conditional>
  );
};
