import { useEffect, useState } from 'react';
import { Icon, Typography, Grid, Stack } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { BulbChangeInstructions } from './components/bulb-change-instructions';
import { productAtoms } from '../../../../product.atoms';
import {
  BulbChangeInstruction,
  Product,
} from '../../../../../../api/products/products.types';
import { useFetchProductData } from '../../../../hooks/use-fetch-product-data';
import { SectionTitle } from '../../../../../../components/layout/section-title';
import { productInfoPageMaxWidth } from '../../info.constants';

export const InfoTabBulbChange = () => {
  const [product, setProduct] = useRecoilState(productAtoms.product);

  const [bulbInstructions, setBulbInstructions] = useState([]);

  const { updateProduct } = useFetchProductData();

  const onBulbInstructionsSave = async (
    instructions: BulbChangeInstruction[],
  ) => {
    const newProduct: Product = {
      ...product,
      bulbChangeInstructions: instructions,
    };

    setProduct(newProduct);
    await updateProduct(newProduct);
  };

  useEffect(() => {
    setBulbInstructions(product.bulbChangeInstructions || []);
  }, [product.bulbChangeInstructions]);

  const addBulbInstructionStep = () => {
    setBulbInstructions([...bulbInstructions, {}]);
  };

  return (
    <Stack gap="2x">
      <SectionTitle text="Bulb change" />

      <Grid gap="3x" maxWidth={productInfoPageMaxWidth}>
        {bulbInstructions.map(({ content, imageUrl }, index) => {
          return (
            <BulbChangeInstructions
              key={`${imageUrl}-${index}`}
              bulbInstructions={bulbInstructions}
              content={content}
              imageUrl={imageUrl}
              index={index}
              productId={product.id}
              setBulbInstructions={setBulbInstructions}
              onBulbInstructionsSave={onBulbInstructionsSave}
            />
          );
        })}

        <Typography
          color="link"
          icon={<Icon color="link" name="ui-control:plus_circle" />}
          justifySelf="start"
          text="Add step"
          onClick={addBulbInstructionStep}
        />
      </Grid>
    </Stack>
  );
};
