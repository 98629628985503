import { Table } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledTable = styled(Table)`
  ${({ theme }) => {
    return css`
      td {
        &[data-cell-toggle] {
          background-color: ${theme.colors.surfaces.monochrome.t1} !important;

          &:not([data-cell-is-clickable]) {
            cursor: auto;
          }
        }
      }
    `;
  }}
`;
