import { useRecoilValue } from 'recoil';
import { addStocksModalAtoms } from '../../add-stocks-modal.atoms';
import { addStocksModalSteps } from '../../add-stocks-modal.constants';
import { AddStocksModalFooterProps } from './add-stocks-modal-footer.types';
import { SetQuantityFooter } from '../step-set-quantity/set-quantity-footer';
import { ConfirmQuantityFooter } from '../step-confirm-quantity/confirm-quantity-footer';

export const AddStocksModalFooter = (props: AddStocksModalFooterProps) => {
  const { onClose, processing } = props;

  const step = useRecoilValue(addStocksModalAtoms.step);

  switch (step) {
    case addStocksModalSteps.setQty:
      return <SetQuantityFooter onClose={onClose} />;

    case addStocksModalSteps.confirmQty:
      return <ConfirmQuantityFooter processing={processing} />;

    default:
      return null;
  }
};
