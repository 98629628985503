import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  UpdatePartTemplateArgs,
  UpdatePartTemplateResponse,
} from './update-part-template.types';

export const updatePartTemplate = ({ id, ...body }: UpdatePartTemplateArgs) => {
  return axios
    .put<UpdatePartTemplateResponse>(
      `${getCoreApiUrl('product-part-templates')}/${id}`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
