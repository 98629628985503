import { useEffect } from 'react';
import { Modal } from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { CompletePrintHarvestModalProps } from './complete-print-harvest-modal.types';
import { CompletePrintFooter } from './components/complete-print-harvest-footer';
import { CompletePrintHarvestContent } from './components/complete-print-harvest-content';
import { completePrintHarvestModalAtoms } from './complete-print-harvest-modal.atoms';
import { JobDetailsPanelLarge } from '../../common/job-details-panel-large';
import { useResetRecoilAtomsOnMount } from '../../../../../../hooks/use-reset-recoil-atoms-on-mount';
import { FullScreenModalHeader } from '../../common/full-screen-modal-header';
import { useHandleCloseModal } from '../../hooks/use-handle-close-modal';

export const CompletePrintHarvestModal = (
  props: CompletePrintHarvestModalProps,
) => {
  const { job: initialJob, onClose, onJobUpdated } = props;

  const [job, setJob] = useRecoilState(completePrintHarvestModalAtoms.job);
  const setMachine = useSetRecoilState(completePrintHarvestModalAtoms.machine);
  const updateOnClose = useRecoilValue(
    completePrintHarvestModalAtoms.updateOnClose,
  );

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    completePrintHarvestModalAtoms,
  );

  const handleCloseModal = useHandleCloseModal({
    onClose,
    onUpdate: onJobUpdated,
    updateOnClose,
  });

  useEffect(() => {
    setMachine(initialJob.machine);
    setJob(initialJob);
  }, []);

  return (
    <ResetAtomsWrapper>
      <Modal
        closeable={false}
        dataAttributes={{ 'linked-to': JOB_DETAILS_PANEL_LINKED_TO }}
        detailsPanel={
          <JobDetailsPanelLarge
            job={job}
            machineName={job?.machine?.name}
            sectionsToShow={[
              'part',
              'color & material',
              'product',
              'machine',
              'instructions',
              'part diagram',
            ]}
          />
        }
        detailsPanelWidth="1fr"
        footer={<CompletePrintFooter handleCloseModal={handleCloseModal} />}
        header={
          <FullScreenModalHeader
            handleCloseModal={handleCloseModal}
            job={job}
          />
        }
        isFullScreen
      >
        <CompletePrintHarvestContent />
      </Modal>
    </ResetAtomsWrapper>
  );
};
