import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  CreateInventoryPurchaseArgs,
  CreateInventoryPurchaseResponse,
} from './create-inventory-purchase.types';

export const createInventoryPurchase = (body: CreateInventoryPurchaseArgs) => {
  return axios.post<CreateInventoryPurchaseResponse>(
    `${coreApiUrl}/inventory-purchases/create`,
    body,
  );
};
