import { Button } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { updatePartBatchModalAtoms } from '../../update-part-batch-modal.atoms';
import { UpdateBatchFooter } from '../step-update-batch/update-batch-footer';

export const UpdatePartBatchModalFooter = ({
  currentBatchId,
  handleCloseModal,
  partId,
}: {
  currentBatchId: number;
  handleCloseModal: () => Promise<void>;
  partId: number;
}) => {
  const step = useRecoilValue(updatePartBatchModalAtoms.step);

  switch (step) {
    case 'UPDATE_BATCH':
      return (
        <UpdateBatchFooter
          currentBatchId={currentBatchId}
          handleCloseModal={handleCloseModal}
          partId={partId}
        />
      );

    case 'BATCH_CREATION_SUCCESS':
    case 'BATCH_CREATION_ERROR':
    case 'PART_ADDED_SUCCESS':
    case 'PART_ADDED_ERROR':
    case 'PART_REMOVED_SUCCESS':
    case 'PART_REMOVED_ERROR':
      return <Button size="large" text="Done" onClick={handleCloseModal} />;

    default:
      return null;
  }
};
