import { FC, useMemo, useState } from 'react';
import { Cell, Conditional, OnRowClick } from 'gantri-components';
import { ExpandedState, getExpandedRowModel } from '@tanstack/react-table';
import { StockPartsProps, StockPartsTableRecord } from './stock-parts.types';
import { useGetStockPartsColumns } from './stock-parts.constants';
import { convertStockPartsToStockPartsTableRecords } from './stock-parts.adapters';
import routePaths from '../../../../config/route-paths';
import { StyledTable } from './stock-parts.styles';
import { SectionTitle } from '../../../../components/layout/section-title';

export const StockParts: FC<StockPartsProps> = (props) => {
  const { stock } = props;
  const { parts } = stock;

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const data = useMemo(() => {
    return convertStockPartsToStockPartsTableRecords(parts);
  }, [parts]);

  const onRowClick: OnRowClick<StockPartsTableRecord> = (
    { original },
    event,
  ) => {
    if (event.target instanceof Element) {
      const isToggleCell = event.target.getAttribute('data-cell-toggle');
      const isWithinToggleCell = event.target.closest('td[data-cell-toggle]');

      if (isToggleCell || isWithinToggleCell) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        window.open(`${routePaths.parts}/${original.id}`, '_blank');
      }
    }
  };

  const hasPartsWithAttempts = parts.some((part) => {
    return part.attempt > 1;
  });
  const columns = useGetStockPartsColumns({ hasPartsWithAttempts });

  return (
    <Conditional condition={!!parts.length}>
      <Cell>
        <SectionTitle text={`Parts (${parts.length})`} />

        <StyledTable
          columns={columns}
          data={data}
          options={{
            getExpandedRowModel: getExpandedRowModel(),
            onExpandedChange: setExpanded,
            state: { expanded },
          }}
          onRowClick={onRowClick}
        />
      </Cell>
    </Conditional>
  );
};
