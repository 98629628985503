import axios from 'axios';
import { environment } from '../../../../environment';
import {
  UpdateAddressArgs,
  UpdateAddressResponse,
} from './update-address.types';

export const updateAddress = (body: UpdateAddressArgs) => {
  return axios.put<UpdateAddressResponse>(
    `${environment.API_URL}/address/update`,
    body,
  );
};
