import axios from 'axios';
import { getCoreApiUrl } from '../../../helpers/auth';
import {
  DeletePressRecordArgs,
  DeletePressRecordResponse,
} from './delete-press-record.types';

export const deletePressRecord = ({ id }: DeletePressRecordArgs) => {
  return axios
    .delete<DeletePressRecordResponse>(`${getCoreApiUrl('press')}/${id}`)
    .then(({ data }) => {
      return data;
    });
};
