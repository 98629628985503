import PropTypes from 'prop-types';
import { Conditional, Flex, Typography } from 'gantri-components';
import { Link } from 'react-router-dom';
import TimelineUserInfo from '../../common/timeline/user-info';
import { formatDate } from '../../../helpers/formatter';
import { LONG_FORMAT } from '../../../constants/dates';
import routePaths from '../../../config/route-paths';
import { Timeline } from '../../../api/jobs/jobs.types';

const StockTimelineItem = ({
  createdAtSec,
  description,
  extra,
  userId,
  ...rest
}: Timeline) => {
  let showOrderLink = false;

  if (
    extra?.orderId &&
    (description === 'Unassigned from' || description === 'Assigned to')
  ) {
    showOrderLink = true;
  } else if (description.indexOf('Unassigned from ') === 0) {
    showOrderLink = true;
    description = 'Unassigned from';
  }

  return (
    <div>
      <Typography text={formatDate(createdAtSec, LONG_FORMAT)} />
      <Flex gap="0.6rem">
        <Typography color="t1" text={description} whiteSpace="nowrap" />
        <Conditional condition={showOrderLink}>
          <Link to={`${routePaths.orders}/${extra?.orderId}`}>
            <Typography
              color="link"
              decoration="underline"
              text={`#${extra?.orderId}`}
              whiteSpace="nowrap"
            />
          </Link>
        </Conditional>
        <Conditional condition={!!extra?.oldStockId}>
          <Flex gap="0.6rem">
            <Link to={`${routePaths.stocks}/${extra?.oldStockId}`}>
              <Typography
                color="link"
                decoration="underline"
                text={`#${extra?.oldStockId}`}
                whiteSpace="nowrap"
              />
            </Link>
            <Typography text={extra?.description} whiteSpace="nowrap" />
          </Flex>
        </Conditional>
        <Conditional condition={!!userId && !extra?.oldStockId}>
          <Conditional condition={!/\s(by|from|to)$/i.test(description)}>
            <Typography color="t1" text="by" whiteSpace="nowrap" />
          </Conditional>
          <TimelineUserInfo userId={userId} {...rest} />
        </Conditional>
      </Flex>
      <Conditional condition={!!extra?.newStockId}>
        <Flex gap="0.6rem">
          <Typography text={extra?.description} whiteSpace="nowrap" />
          <Link to={`${routePaths.stocks}/${extra?.newStockId}`}>
            <Typography
              color="link"
              decoration="underline"
              text={`#${extra?.newStockId}`}
              whiteSpace="nowrap"
            />
          </Link>
        </Flex>
      </Conditional>
    </div>
  );
};

StockTimelineItem.propTypes = {
  createdAtSec: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  extra: PropTypes.shape({
    orderId: PropTypes.number,
  }),
  rest: PropTypes.object,
  userId: PropTypes.number,
};

export default StockTimelineItem;
