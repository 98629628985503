import { Typography } from 'gantri-components';

export const ConfirmCancelContent = () => {
  return (
    <Typography
      align="center"
      text="Cancelling this stock will cancel all jobs."
    />
  );
};
