import { Typography, Icon, Conditional } from 'gantri-components';
import { StyledAnchor } from '../../../../../../../../../../../../../../components/common/styled-anchor';
import { useDownloadWithSmallToast } from '../../../../../../../../../../../../../../hooks/use-download-with-small-toast';
import { downloadToastText } from '../../../../../../../../../../../../../../hooks/use-download-with-small-toast/use-download-with-small-toast.constants';
import { getTransformProductAssetFileName } from '../../../../../../../../../../helpers/get-transform-product-asset-file-name';
import { BulkUploadTableUsdzCellProps } from './bulk-upload-table-usdz-cell.types';

export const BulkUploadTableUsdzCell = (
  props: BulkUploadTableUsdzCellProps,
) => {
  const { sku, usdz } = props;

  const { transformFileName } = getTransformProductAssetFileName({
    fileType: 'model',
    sku,
  });

  const fileName =
    (usdz?.id
      ? transformFileName({
          applyUuid: (value) => {
            return value;
          },
          fileName: usdz?.fileName,
        })
      : usdz?.fileName) || '';

  const { DownloadStatusToast, downloadSingleFile, processing } =
    useDownloadWithSmallToast({
      ...downloadToastText.file,
    });

  const onClick = async () => {
    if (!processing) {
      await downloadSingleFile({
        fileName,
        url: usdz.fileUrl,
      });
    }
  };

  return (
    <Conditional
      condition={!!usdz?.fileUrl || !!usdz?.fileBlob}
      Fallback={<Typography text="-" />}
    >
      <DownloadStatusToast position="top" />
      <StyledAnchor
        icon={<Icon color="link" name="arrows:arrow_download" />}
        minWidth="max-content"
        text={fileName}
        onClick={onClick}
      />
    </Conditional>
  );
};
