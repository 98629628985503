import { Typography, Flex } from 'gantri-components';
import { StyledAnchor } from '../../../../../../../../../../../../../../components/common/styled-anchor';
import { Label } from '../../../../../../../../../../../../../../components/label';
import { BulkUploadHeaderTooltipIcon } from '../bulk-upload-table-header-tooltip-icon';

export const BulkUploadTableSkuHeader = () => {
  return (
    <Label
      icon={
        <BulkUploadHeaderTooltipIcon
          footer={
            <Flex gap=".5x" wrap="wrap">
              <Typography text="View" />
              <StyledAnchor
                href="https://www.notion.so/gantri/Bulk-Asset-Upload-File-Validation-34d1d7f0624d4130abeb9712c5b1774f"
                target="_blank"
                text="full documentation"
              />
              <Typography text="for details." />
            </Flex>
          }
          header={
            <Typography
              text="For accurate asset mapping, filename:"
              textStyle="bold"
            />
          }
          items={[
            'Begins with Product ID or name',
            'Uses a space, underscore (_), or dash (-), to separate all elements in the filename',
            'Includes color code OR short color name',
            "Includes variant partial's code OR name",
          ]}
        />
      }
      iconPosition="right"
      text="SKU"
    />
  );
};
