import { PaletteColor } from 'gantri-components/dist/styles/theme';
import { DefaultTheme } from 'styled-components';
import {
  ProductReviewStatus,
  ProductReviewStatuses,
} from '../../../../../api/users/routes';

export const getColorByStatus = (status: ProductReviewStatus): PaletteColor => {
  if (status === ProductReviewStatuses.PENDING) return 'yellow_200';
  if (status === ProductReviewStatuses.REJECTED) return 'red_200';
  if (status === ProductReviewStatuses.INCOMPLETE) return 'monochrome_200';
  if (status === ProductReviewStatuses.PUBLISHED) return 'green_200';

  return 'monochrome_200';
};

export const getBadgeColorByStatus = (
  status: ProductReviewStatus,
  theme: DefaultTheme,
): {
  borderColor?: PaletteColor;
  color?: PaletteColor;
  textColor?: PaletteColor;
} => {
  if (status === ProductReviewStatuses.PENDING)
    return {
      borderColor: theme.colors.surfaces.warning.t3,
      color: theme.colors.surfaces.warning.t1,
      textColor: theme.colors.typography.t1,
    };

  if (status === ProductReviewStatuses.REJECTED)
    return {
      borderColor: theme.colors.surfaces.alert.t2,
      color: theme.colors.surfaces.alert.t1,
      textColor: theme.colors.typography.t1,
    };

  if (status === ProductReviewStatuses.INCOMPLETE)
    return {
      borderColor: theme.colors.dividers.t2,
      color: theme.colors.surfaces.monochrome.t2,
      textColor: theme.colors.typography.t1,
    };

  if (
    status === ProductReviewStatuses.PUBLISHED ||
    status === ProductReviewStatuses.COMPLETED
  )
    return {
      borderColor: theme.colors.surfaces.green.t2,
      color: theme.colors.surfaces.green.t1,
      textColor: theme.colors.typography.t1,
    };

  return {
    borderColor: theme.colors.dividers.t2,
    color: theme.colors.surfaces.monochrome.t2,
    textColor: theme.colors.typography.t1,
  };
};
