import { useRecoilValue } from 'recoil';
import { PrintTransferQueueSet } from '../../../../../../../api/queue/print-transfer/print-transfer.types';
import { printPrepareQueueModalAtoms } from '../../print-transfer-queue-modal.atoms';

export const useGetActiveJobSet = (): PrintTransferQueueSet => {
  const queueData = useRecoilValue(printPrepareQueueModalAtoms.queueData);
  const activeSetIndex = useRecoilValue(
    printPrepareQueueModalAtoms.activeSetIndex,
  );

  return queueData?.sets?.[activeSetIndex];
};
