const firebaseConfig = {
  develop: {
    apiKey: 'AIzaSyBctSCKYJGcBM5Kw2otpg4MpIgRKXKlW1U',
    authDomain: 'gantri-develop.firebaseapp.com',
    baseUrl:
      'https://firebasestorage.googleapis.com/v0/b/gantri-develop.appspot.com/o/',
    projectId: 'gantri-develop',
    storageBucket: 'gantri-develop.appspot.com',
  },
  local: {
    apiKey: 'AIzaSyBctSCKYJGcBM5Kw2otpg4MpIgRKXKlW1U',
    authDomain: 'gantri-develop.firebaseapp.com',
    baseUrl:
      'https://firebasestorage.googleapis.com/v0/b/gantri-develop.appspot.com/o/',
    projectId: 'gantri-develop',
    storageBucket: 'gantri-develop.appspot.com',
  },
  production: {
    apiKey: 'AIzaSyDRNNcuWGxreBlUx6DoFygNikwhjIa82Kw',
    authDomain: 'gantri-508ea.firebaseapp.com',
    baseUrl:
      'https://firebasestorage.googleapis.com/v0/b/gantri-508ea.appspot.com/o/',
    projectId: 'gantri-508ea',
    storageBucket: 'gantri-508ea.appspot.com',
  },
  staging: {
    apiKey: 'AIzaSyDTq4V0VihgW8WTbhxoAoOEIf-1YGMAXCU',
    authDomain: 'gantri-staging.firebaseapp.com',
    baseUrl:
      'https://firebasestorage.googleapis.com/v0/b/gantri-staging.appspot.com/o/',
    projectId: 'gantri-staging',
    storageBucket: 'gantri-staging.appspot.com',
  },
};

export default firebaseConfig;
