import { Grid, Stack, Typography } from 'gantri-components';
import { PropsWithChildren } from 'react';
import { Divider } from '../../divider';
import { SectionTitleProps } from './section-title.types';

export const SectionTitle = (props: PropsWithChildren<SectionTitleProps>) => {
  const {
    children,
    icon,
    iconPosition,
    text,
    variant = 'h4',
    ...gridProps
  } = props;

  return (
    <Stack gap="x">
      <Grid alignItems="end" columns="max-content 1fr" gap="x" {...gridProps}>
        <Typography
          icon={icon}
          iconPosition={iconPosition}
          text={text}
          variant={variant}
        />
        {children}
      </Grid>

      <Divider />
    </Stack>
  );
};
