import Cookies from 'universal-cookie';
import { usersApi } from '../../../api';
import { redirectToMantle } from '../../../helpers/auth';
import {
  ADMIN_ROLE_TYPE,
  AUTH_TOKEN,
  FIREBASE_TOKEN,
  STD_COOKIE_OPTIONS,
} from '../../../constants/environment';
import { useInvalidateGetAdminUserCache } from '../../../api/users/routes';
import { useResetRecoilAtoms } from '../../../hooks/use-reset-recoil-atoms';
import { sandingMinutesProgressAtoms } from '../../../components/sanding-minutes-progress/sanding-minutes-progress.atoms';
import { userAtoms } from '../../../global-atoms/users';

export const useAsyncLogOutAdmin = () => {
  const { invalidateGetAdminUserCache } = useInvalidateGetAdminUserCache();

  const resetUserAtoms = useResetRecoilAtoms(userAtoms);
  const resetSandingMinutesAtoms = useResetRecoilAtoms(
    sandingMinutesProgressAtoms,
  );

  const onLogoutAdmin = async () => {
    const cookies = new Cookies();

    cookies.remove(AUTH_TOKEN, STD_COOKIE_OPTIONS);
    cookies.remove(FIREBASE_TOKEN, STD_COOKIE_OPTIONS);
    cookies.remove(ADMIN_ROLE_TYPE, STD_COOKIE_OPTIONS);
    cookies.set('CART_OUTDATED', 'true', STD_COOKIE_OPTIONS);

    await usersApi.logoutAdmin();

    resetSandingMinutesAtoms();
    resetUserAtoms();

    await invalidateGetAdminUserCache();

    redirectToMantle();
  };

  return onLogoutAdmin;
};
