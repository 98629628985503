import { useDownload } from '../use-download';
import { UseDownloadWithSmallToastProps } from './use-download-with-small-toast.types';
import { useSmallToast } from '../use-small-toast';
import { UseDownloadProps } from '../use-download/use-download.types';

export const useDownloadWithSmallToast = (
  props: UseDownloadWithSmallToastProps,
) => {
  const {
    completedText,
    errorText,
    onComplete,
    onError,
    onStart,
    startedText,
  } = props;

  const { Toast, setToastStatus, setToastText } = useSmallToast();

  const useDownloadArgs: Required<UseDownloadProps> = {
    onComplete: () => {
      setToastText(completedText);
      setToastStatus('success');
      onComplete?.();
    },
    onError: (file) => {
      setToastText(errorText);
      setToastStatus('error');
      onError?.(file);
    },
    onStart: () => {
      setToastText(startedText);
      onStart?.();
    },
  };
  const useDownloadValues = useDownload(useDownloadArgs);

  return {
    DownloadStatusToast: Toast,
    ...useDownloadValues,
    ...useDownloadArgs,
  };
};
