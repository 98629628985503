import { Button, Conditional, Flex, Icon, Tooltip } from 'gantri-components';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useFormikContext } from 'formik';
import { productJobsTabAtoms } from '../../../../product-jobs.atoms';
import { getTemplateEditDisabledReason } from '../../../../../../../../components/common/jobs-template-table/helpers/get-template-edit-disabled-reason';
import { useGetVersionDetails } from '../../../../hooks/use-get-version-details';
import { SectionTitle } from '../../../../../../../../components/layout/section-title';
import { StockJobsHeaderProps } from './stock-jobs-header.types';

export const StockJobsHeader = (props: StockJobsHeaderProps) => {
  const { sectionId, showInvalidIcon, text } = props;

  const isEditingJobs = useRecoilValue(productJobsTabAtoms.isEditingJobs);
  const [isEditingStockJobsSection, setIsEditingStockJobsSection] =
    useRecoilState(productJobsTabAtoms.isEditingStockJobsSection);
  const resetIsEditingStockJobs = useResetRecoilState(
    productJobsTabAtoms.isEditingStockJobsSection,
  );

  const { isDraft } = useGetVersionDetails();

  const { isSubmitting, isValid, resetForm } = useFormikContext();

  const onEdit = () => {
    setIsEditingStockJobsSection(sectionId);
  };

  const onCancel = () => {
    resetIsEditingStockJobs();
    resetForm();
  };

  const isEditingStockJobs = isEditingStockJobsSection === sectionId;

  return (
    <SectionTitle
      icon={
        <Conditional condition={showInvalidIcon}>
          <Tooltip
            maxWidth="22.7rem"
            position="bottom-start"
            title="Section is missing necessary job details."
          >
            <Icon
              color="warning"
              cursor="pointer"
              name="alert:warning_triangle"
              top=".3rem"
            />
          </Tooltip>
        </Conditional>
      }
      text={text}
    >
      <Flex alignItems="center" gap="x" justifySelf="end" width="auto">
        <Conditional condition={isDraft}>
          <Conditional
            condition={isEditingStockJobs}
            Fallback={
              <Tooltip
                description={getTemplateEditDisabledReason({
                  isEditing: isEditingJobs,
                })}
                position="top-end"
              >
                <Button
                  disabled={isEditingJobs}
                  icon={
                    <Icon
                      color={isEditingJobs ? 't3' : 'link'}
                      name="docs:pencil"
                    />
                  }
                  text="Edit"
                  variant="secondary"
                  onClick={onEdit}
                />
              </Tooltip>
            }
          >
            <Button text="Cancel" variant="secondary" onClick={onCancel} />
            <Tooltip
              description={!isValid && 'Missing required fields.'}
              position="top-end"
            >
              <Button
                disabled={!isValid}
                processing={isSubmitting}
                text="Save"
                type="submit"
              />
            </Tooltip>
          </Conditional>
        </Conditional>
      </Flex>
    </SectionTitle>
  );
};
