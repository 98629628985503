import { Conditional, Grid, Typography } from 'gantri-components';
import { flatten } from 'lodash';
import { useRecoilValue } from 'recoil';
import { useHandleSetSelectedSku } from '../../hooks/use-handle-set-selected-sku';
import { productAtoms } from '../../product.atoms';
import { SkuConfigColorDropdown } from './components/sku-config-color-dropdown';
import { SkuConfigVariantDropdowns } from './components/sku-config-variant-dropdowns';
import { SkuLabelWrapper } from './components/sku-label-wrapper';
import { ProductSkuDropdownPropsDef } from './product-sku-dropdown.types';

export const ProductSkuDropdown = (props: ProductSkuDropdownPropsDef) => {
  const { showSelectedSku } = props;

  const product = useRecoilValue(productAtoms.product);
  const selectedSku = useRecoilValue(productAtoms.selectedSku);

  const { handleSetSelectedSku } = useHandleSetSelectedSku();

  const hasVariants = !!product.selectors?.length;

  return (
    <Grid
      alignItems={hasVariants ? 'end' : 'center'}
      columns={`max-content repeat(${
        (product.colors ? 1 : 0) + flatten(product.selectors || []).length
      }, ${hasVariants ? '20rem' : '25rem'}) ${
        showSelectedSku ? 'max-content' : ''
      }`}
      gap="x"
    >
      <SkuLabelWrapper hasVariants={hasVariants}>
        <Typography text="SKU" />
      </SkuLabelWrapper>

      <SkuConfigColorDropdown
        highlightMarketplaceColors
        product={product}
        setSku={handleSetSelectedSku}
        sku={selectedSku}
      />

      <SkuConfigVariantDropdowns
        product={product}
        setSku={handleSetSelectedSku}
        sku={selectedSku}
      />

      <Conditional condition={showSelectedSku}>
        <SkuLabelWrapper hasVariants={hasVariants}>
          <Typography color="t2" text={`SKU: ${selectedSku || '-'}`} />
        </SkuLabelWrapper>
      </Conditional>
    </Grid>
  );
};
