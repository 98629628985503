import { useRecoilValue } from 'recoil';
import { discardStockModalAtoms } from '../../discard-stock-modal.atoms';
import { discardStockModalSteps } from '../../discard-stock-modal.constants';
import { NotifyCustomerFooter } from '../step-notify-customer/notify-customer-footer';
import { ConfirmDiscardFooter } from '../step-confirm-discard/confirm-discard-footer';
import { DiscardStockModalFooterProps } from './discard-stock-modal-footer.types';

export const DiscardStockModalFooter = (
  props: DiscardStockModalFooterProps,
) => {
  const { handleCloseModal, stockId } = props;

  const step = useRecoilValue(discardStockModalAtoms.step);

  switch (step) {
    case discardStockModalSteps.confirmDiscard:
      return (
        <ConfirmDiscardFooter
          handleCloseModal={handleCloseModal}
          stockId={stockId}
        />
      );

    case discardStockModalSteps.notifyCustomer:
      return <NotifyCustomerFooter handleCloseModal={handleCloseModal} />;

    default:
      return null;
  }
};
