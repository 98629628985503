import { Typography } from 'gantri-components';
import { CellContext } from '@tanstack/react-table';
import { GetYieldCellDetailsReturn } from './get-yield-cell-details.types';

export const getYieldCellDetails = (
  cellProps: CellContext<any, any>,
): GetYieldCellDetailsReturn => {
  const { getValue, row } = cellProps;
  const isYield = /^yield$/i.test(row.original.type);
  const value = getValue();

  if (isYield) {
    const isYieldLabel = typeof value === 'string';

    const YieldCell = () => {
      return (
        <Typography text={isYieldLabel ? `${value} %` : `${value || 0}%`} />
      );
    };

    return { YieldCell, isYield };
  }

  return { isYield: false };
};
