import {
  Flex,
  Icon,
  Stack,
  Toggle,
  Tooltip,
  Typography,
} from 'gantri-components';
import { useToggle } from 'react-use';

export const useQuickViewTableAction = () => {
  const [isQuickViewEnabled, toggleIsQuickViewEnabled] = useToggle(false);

  const QuickViewTableAction = () => {
    return (
      <Flex alignItems="center" gap="0.8rem" width="auto">
        <Toggle
          value={isQuickViewEnabled}
          onSelected={toggleIsQuickViewEnabled}
        />
        <Typography text="Quickview" />
        <Tooltip
          Component={
            <Stack
              horizontalPadding="1.5rem"
              verticalPadding="1.2rem"
              width="26rem"
            >
              <Typography text="For in-progress parts: latest action, current job, and next job." />
              <Typography text="For parts no longer in-progress: 3 last actions (jobs completed / failed)." />
            </Stack>
          }
          overlayContainerStyles={{ display: 'flex' }}
          position="top-start"
        >
          <Icon name="alert:i_circle" />
        </Tooltip>
      </Flex>
    );
  };

  return { QuickViewTableAction, isQuickViewEnabled };
};
