import PropTypes from 'prop-types';
import { Icon, Typography } from 'gantri-components';
import { Link } from 'react-router-dom';
import routePaths from '../../../../config/route-paths';
import {
  StyledTextAndIconContainer,
  StyledFieldIconWrapper,
  StyledTooltipTypography,
} from '../custom-data-cells-styles';
import Tooltip from '../../tooltip';
import { formatDate } from '../../../../helpers/formatter';
import { LONG_FORMAT } from '../../../../constants/dates';

const MachineDataCell = ({ assignedInfo, machine }) => {
  return machine ? (
    <StyledTextAndIconContainer>
      <Link to={`${routePaths.machines}/${machine.id}`}>
        <Typography color="link" text={machine.name} />
      </Link>

      {assignedInfo && assignedInfo.userId && (
        <Tooltip
          content={
            <>
              <StyledTooltipTypography
                color="t2"
                text={assignedInfo.description}
              />
              <Link to={`${routePaths.users}/${assignedInfo.userId}`}>
                <StyledTooltipTypography
                  color="link"
                  text={` ${assignedInfo.userName} `}
                />
              </Link>
              <StyledTooltipTypography
                color="t2"
                text={formatDate(assignedInfo.assignedAt, LONG_FORMAT)}
              />
            </>
          }
        >
          <StyledFieldIconWrapper>
            <Icon color="t2" name="alert:i_circle" />
          </StyledFieldIconWrapper>
        </Tooltip>
      )}
    </StyledTextAndIconContainer>
  ) : null;
};

MachineDataCell.propTypes = {
  assignedInfo: PropTypes.shape({
    assignedAt: PropTypes.number,
    description: PropTypes.string,
    userId: PropTypes.number,
    userName: PropTypes.string,
  }),
  machine: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

export default MachineDataCell;
