import { Grid, Icon, Stack, Tab, Tabs, Typography } from 'gantri-components';
import { useState } from 'react';
import { StyledBlockTabsList } from '../../part-release-rate.styles';
import {
  PartReleaseRateQueueTab,
  PartReleaseRateQueuesPropsDef,
} from './part-release-rate-queues.types';
import { partReleaseRateQueueTabs } from './part-release-rate-queues.constants';
import { PartReleaseRateQueueTable } from './components/part-release-rate-queue-table';
import { StyledTabPanel } from './part-release-rate-queues.styles';

export const PartReleaseRateQueues = (props: PartReleaseRateQueuesPropsDef) => {
  const { bufferedParts, waitingParts } = props;

  const [activeTab, setActiveTab] = useState<PartReleaseRateQueueTab>(
    partReleaseRateQueueTabs.bufferedParts,
  );

  return (
    <Stack gap="x" paddingBottom="2x" paddingTop="3x">
      <Tabs value={activeTab}>
        <StyledBlockTabsList
          onValueChange={(value: PartReleaseRateQueueTab) => {
            setActiveTab(value);
          }}
        >
          <Grid
            alignItems="baseline"
            columns="max-content max-content 1fr"
            gap="3x"
            width="100%"
          >
            <Tab
              label={partReleaseRateQueueTabs.bufferedParts}
              value={partReleaseRateQueueTabs.bufferedParts}
              variant="p2"
            />
            <Tab
              label={partReleaseRateQueueTabs.waitingParts}
              value={partReleaseRateQueueTabs.waitingParts}
              variant="p2"
            />

            <Typography
              color="t2"
              icon={<Icon name="arrows:arrow_updown" />}
              justifySelf="flex-end"
              text="Sorted by Priority"
            />
          </Grid>
        </StyledBlockTabsList>

        <StyledTabPanel value={partReleaseRateQueueTabs.bufferedParts}>
          <PartReleaseRateQueueTable data={bufferedParts} />
        </StyledTabPanel>

        <StyledTabPanel value={partReleaseRateQueueTabs.waitingParts}>
          <PartReleaseRateQueueTable data={waitingParts} />
        </StyledTabPanel>
      </Tabs>
    </Stack>
  );
};
