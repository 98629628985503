import React, { FC, useState } from 'react';
import {
  Button,
  Conditional,
  Flex,
  Stack,
  Typography,
} from 'gantri-components';
import { useToggle } from 'react-use';
import { debounce } from 'lodash';
import { StockNotesProps } from './stock-notes.types';
import { useNotification } from '../../../../hooks/useNotification';
import { StyledTextArea } from '../../../part/components/part-notes/part-notes.styles';
import { stocksApi } from '../../../../api';
import { SectionTitle } from '../../../../components/layout/section-title';

export const StockNotes: FC<StockNotesProps> = (props) => {
  const { stock } = props;
  const { id: stockId, note: stockNotes } = stock;

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const [notes, setNotes] = useState<string>(stockNotes);
  const [isEditing, toggleIsEditing] = useToggle(false);

  const handleOnCancel = () => {
    setNotes(stockNotes);
    toggleIsEditing();
  };

  const handleOnSave = async () => {
    try {
      showLoading();

      await stocksApi.updateNotes({
        note: notes,
        stockId,
      });

      toggleIsEditing();
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to save stock notes.',
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <Stack alignContent="flex-start" gap="2x">
      <SectionTitle text="Notes">
        <Flex alignItems="center" gap="2.4rem">
          <Conditional
            condition={isEditing}
            Fallback={
              <Button
                size="small"
                text="Edit"
                variant="ghost"
                onClick={toggleIsEditing}
              />
            }
          >
            <Flex gap="0.4rem">
              <Button
                size="small"
                text="Cancel"
                variant="ghost"
                onClick={handleOnCancel}
              />
              <Typography text="|" />
              <Button
                size="small"
                text="Save"
                variant="ghost"
                onClick={handleOnSave}
              />
            </Flex>
          </Conditional>
        </Flex>
      </SectionTitle>

      <Conditional
        condition={isEditing}
        Fallback={<Typography htmlText={notes?.replace(/\n/g, '<br />')} />}
      >
        <StyledTextArea
          minRows={10}
          value={notes}
          onTextChange={debounce(setNotes, 300)}
        />
      </Conditional>
    </Stack>
  );
};
