import { Button } from 'gantri-components';
import { useFormikContext } from 'formik';
import { UpdateInventoryTargetFormData } from '../../update-inventory-target-modal.types';
import { UpdateInventoryTargetModalFooterProps } from './update-inventory-target-modal-footer.types';

export const UpdateInventoryTargetModalFooter = (
  props: UpdateInventoryTargetModalFooterProps,
) => {
  const { onClose, processing } = props;

  const { isValid } = useFormikContext<UpdateInventoryTargetFormData>();

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={onClose}
      />
      <Button
        disabled={!isValid}
        processing={processing}
        size="large"
        text="Save"
        type="submit"
      />
    </>
  );
};
