import { GetHeaderTextArgs } from './get-header-text.types';

export const getHeaderText = ({ step }: GetHeaderTextArgs) => {
  switch (step) {
    case 'MISSING_ITEMS':
      return 'Missing items';

    case 'DRAFT_DETAILS':
      return 'Publish draft version';

    case 'CONFIRM_PUBLISH':
      return 'Are you sure you wish to publish?';

    default:
      return null;
  }
};
