import { Conditional, Dropdown, Typography } from 'gantri-components';
import {
  MachineType,
  machineTypes,
  printerTypes,
} from '../../../../../../../constants/machines';
import { jobTypeOptions } from '../../../../../../../constants/options';
import { useGetIsEditingRow } from '../../../use-get-is-editing-row';
import { useValidateFields } from '../job-template-type-cell/hooks/use-validate-fields';
import { JobTemplateMachineTypeCellProps } from './job-template-machine-type-cell.types';

export const JobTemplateMachineTypeCell = (
  props: JobTemplateMachineTypeCellProps,
) => {
  const { getValue, onEdit, row } = props;
  const isEditingRow = useGetIsEditingRow(row);
  const jobType = row.original.type;

  const { getHasError } = useValidateFields();
  const hasError = getHasError('machineType');

  const isPrintTransferJob = jobType === jobTypeOptions.transfer;
  const isTumbleRawJob = jobType === jobTypeOptions.tumbleRaw;
  const showDropdown = isEditingRow && (isPrintTransferJob || isTumbleRawJob);

  const tumblerMachineTypes: MachineType[] = [machineTypes.tumbler];
  const printerMachineTypes: MachineType[] = printerTypes.filter((type) => {
    const isInvalidMachineType = [
      machineTypes.tumbler,
      machineTypes.ultimakerHighTemp,
      machineTypes.ultimakerTranslucent,
    ].some((invalidType) => {
      return invalidType === type;
    });

    return !isInvalidMachineType;
  });

  const baseTypeOptions = isTumbleRawJob
    ? ['None', ...tumblerMachineTypes]
    : printerMachineTypes;

  const items = baseTypeOptions.map((option) => {
    return {
      label: option,
      value: option,
    };
  });

  return (
    <Conditional
      condition={showDropdown}
      Fallback={<Typography text={getValue()} />}
    >
      <Dropdown
        errorMessage={hasError ? 'Required' : null}
        items={items}
        placeholder="Machine Type"
        value={getValue()}
        onSelect={({ value }) => {
          onEdit({
            ...row.original,
            machineType: value,
          });
        }}
      />
    </Conditional>
  );
};
