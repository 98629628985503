import { Button } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { transactionsApi } from '../../../../../../../api';
import { useNotification } from '../../../../../../../hooks/useNotification';
import { shipmentActionsModalAtoms } from '../../../shipment-actions-modal.atoms';
import { StepShipManuallyFooterPropsDef } from './step-ship-manually-footer.types';

export const StepShipManuallyFooter = (
  props: StepShipManuallyFooterPropsDef,
) => {
  const { handleCloseModal, order } = props;

  const { notify, notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const handleReturnAndShipManually = useRecoilValue(
    shipmentActionsModalAtoms.shipManually.handleReturnAndShipManually,
  );
  const shipmentId = useRecoilValue(shipmentActionsModalAtoms.shipmentId);
  const trackingNumber = useRecoilValue(
    shipmentActionsModalAtoms.shipManually.trackingNumber,
  );
  const shippingCost = useRecoilValue(
    shipmentActionsModalAtoms.shipManually.shippingCost,
  );

  const onConfirm = async () => {
    await onInterceptProcessingRequest(async () => {
      if (handleReturnAndShipManually) {
        await handleReturnAndShipManually({ shippingCost, trackingNumber });
      } else {
        try {
          const { data } = await transactionsApi.shipManually({
            orderId: order.id,
            rateCost: shippingCost,
            shipmentId,
            trackingNumber,
          });

          notify(data.notice);

          await handleCloseModal({ updateOnClose: true });
        } catch (error: unknown) {
          notifyAxiosError({
            error,
            fallbackMessage: 'Unable to mark order as shipped.',
          });
        }
      }
    });
  };

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        disabled={!trackingNumber || !shippingCost}
        processing={processing}
        size="large"
        text="Confirm"
        onClick={onConfirm}
      />
    </>
  );
};
