import { atom } from 'recoil';
import { DeleteFileArgs } from '../../../../../../../../api/cloudinary/routes/delete-file/delete-file.types';
import { FormattedSkuAssets } from '../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';
import { FileValidationRecord } from './bulk-upload-modal.types';

const atomKeyPrefix = 'BULK_UPLOAD_MODAL';

export const bulkUploadModalAtoms = {
  assetsBySku: atom<FormattedSkuAssets>({
    default: {},
    key: `${atomKeyPrefix}-assets-by-sku`,
  }),
  disableSave: atom<boolean>({
    default: true,
    key: `${atomKeyPrefix}-disable-save`,
  }),
  fileValidationByTimestamp: atom<Record<string, FileValidationRecord>>({
    default: {},
    key: `${atomKeyPrefix}-file-validation-by-timestamp`,
  }),
  filesToDeleteCloudinary: atom<DeleteFileArgs[]>({
    default: [],
    key: `${atomKeyPrefix}-files-to-delete-cloudinary`,
  }),
  filesToDeleteFirebase: atom<DeleteFileArgs[]>({
    default: [],
    key: `${atomKeyPrefix}-files-to-delete-firebase`,
  }),
  isHistoryExpanded: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-history-expanded`,
  }),
  numFilesAdded: atom<number>({
    default: 0,
    key: `${atomKeyPrefix}-num-files-added`,
  }),
  numFilesRemoved: atom<number>({
    default: 0,
    key: `${atomKeyPrefix}-num-files-removed`,
  }),
  processingFiles: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-processing-files`,
  }),
};
