import { useAsync } from 'react-use';
import MetaData from '../../components/meta-data';
import { PageHeading } from '../../components/layout/page-heading';
import { PurchaseStatusSection } from './components/purchase-status-section';
import { InventorySection } from './components/inventory-section';
import { PurchaseDetailsSection } from './components/purchase-details-section';
import { ShippingDetailsSection } from './components/shipping-details-section';
import { AttachmentsSection } from './components/attachments-section';
import { PageHeadingContent } from './components/page-heading-content';
import { Divider } from '../../components/divider';
import { NotesSection } from './components/notes-section';
import { StyledStack } from './inventory-purchase.styles';
import { TimelineSection } from './components/timeline-section';
import { useInventoryPurchaseHandlers } from './hooks/use-inventory-purchase-handlers';
import { useRouter } from '../../hooks';

export const InventoryPurchase = () => {
  const { location } = useRouter();
  const id = Number(location.pathname.split('/inventory-purchases/')[1]);

  const {
    editedInventoryPurchase,
    fetchInventoryPurchase,
    handleSave,
    isEditingDisabled,
    isPurchaseComplete,
    onUpdate,
    setIsEditingDisabled,
    toggleEditMode,
    updateEditedInventoryPurchaseWith,
  } = useInventoryPurchaseHandlers({ id });

  useAsync(async () => {
    await fetchInventoryPurchase();
  }, []);

  return editedInventoryPurchase ? (
    <>
      <MetaData title={`Inventory Purchase #${id}`} />
      <PageHeading title={`Inventory Purchase #${id}`}>
        <PageHeadingContent
          editedInventoryPurchase={editedInventoryPurchase}
          isEditingDisabled={isEditingDisabled}
          updateEditedInventoryPurchaseWith={updateEditedInventoryPurchaseWith}
          onUpdate={onUpdate}
        />
      </PageHeading>
      <StyledStack gap="2rem" height="unset" paddingTop="2.7rem">
        <PurchaseStatusSection
          editedInventoryPurchase={editedInventoryPurchase}
          isEditingDisabled={isEditingDisabled}
          isPurchaseComplete={isPurchaseComplete}
          setIsEditingDisabled={setIsEditingDisabled}
          onUpdate={onUpdate}
        />
        <Divider paddingTop="2rem" />
        <InventorySection editedInventoryPurchase={editedInventoryPurchase} />
        <Divider paddingTop="2rem" />
        <PurchaseDetailsSection
          editedInventoryPurchase={editedInventoryPurchase}
          handleSave={handleSave}
          isEditingDisabled={isEditingDisabled}
          toggleEditMode={toggleEditMode}
          updateEditedInventoryPurchaseWith={updateEditedInventoryPurchaseWith}
        />
        <Divider paddingTop="2rem" />
        <ShippingDetailsSection
          editedInventoryPurchase={editedInventoryPurchase}
          handleSave={handleSave}
          isEditingDisabled={isEditingDisabled}
          toggleEditMode={toggleEditMode}
          updateEditedInventoryPurchaseWith={updateEditedInventoryPurchaseWith}
        />
        <Divider paddingTop="2rem" />
        <AttachmentsSection
          editedInventoryPurchase={editedInventoryPurchase}
          handleSave={handleSave}
          isEditingDisabled={isEditingDisabled}
          toggleEditMode={toggleEditMode}
          updateEditedInventoryPurchaseWith={updateEditedInventoryPurchaseWith}
        />
        <Divider paddingTop="2rem" />
        <NotesSection
          editedInventoryPurchase={editedInventoryPurchase}
          handleSave={handleSave}
          isEditingDisabled={isEditingDisabled}
          toggleEditMode={toggleEditMode}
          updateEditedInventoryPurchaseWith={updateEditedInventoryPurchaseWith}
        />
        <Divider paddingTop="2rem" />
        <TimelineSection editedInventoryPurchase={editedInventoryPurchase} />
      </StyledStack>
    </>
  ) : null;
};
