import { useEffect, useState } from 'react';
import {
  Icon,
  Typography,
  TextField,
  Grid,
  Dropdown,
  Stack,
} from 'gantri-components';
import { useRecoilState } from 'recoil';
import { outlets, productInfoPageMaxWidth } from '../../info.constants';
import { convertStringArrayToValueLabelArray } from '../../../../../../helpers/formatter';
import { productAtoms } from '../../../../product.atoms';
import { AsSeenOn as AsSeenOnType } from '../../../../../../api/products/products.types';
import { SectionTitle } from '../../../../../../components/layout/section-title';

export const InfoTabAwards = () => {
  const [product, setProduct] = useRecoilState(productAtoms.product);

  const [asSeenOn, setAsSeenOn] = useState<AsSeenOnType[]>([]);

  useEffect(() => {
    setAsSeenOn(product.asSeenOn || []);
  }, [product?.asSeenOn?.length]);

  const addSource = () => {
    setAsSeenOn([...asSeenOn, { source: '', url: '' }]);
  };

  const removeSource = (index: number) => {
    const temporal = [...asSeenOn];

    temporal.splice(index, 1);
    setAsSeenOn(temporal);
    setProduct({
      ...product,
      asSeenOn: temporal.filter((item) => {
        return item.source && item.url;
      }),
    });
  };

  const onAsSeenOnUpdated = ({ source, url }, index) => {
    if (
      (source || url) &&
      (asSeenOn[index].source !== source || asSeenOn[index].url !== url)
    ) {
      const temporal = [...asSeenOn];

      temporal[index] = {
        source,
        url,
      };

      setAsSeenOn(temporal);

      if (source && url) {
        setProduct({
          ...product,
          asSeenOn: temporal.filter((item) => {
            return item.source && item.url;
          }),
        });
      }
    }
  };

  return (
    <Stack gap="2x">
      <SectionTitle text="Awards" />

      <Stack gap="3x" maxWidth={productInfoPageMaxWidth}>
        {asSeenOn.map((resource, index) => {
          return (
            <Grid key={index} columns={2}>
              <Dropdown
                items={convertStringArrayToValueLabelArray([...outlets])}
                labelText="Source"
                placeholder="Select source"
                value={resource.source}
                onSelect={(item) => {
                  return onAsSeenOnUpdated(
                    {
                      source: item.value,
                      url: resource.url,
                    },
                    index,
                  );
                }}
              />

              <Grid columns="1fr max-content" gap="x">
                <TextField
                  labelText="URL"
                  value={resource.url}
                  onBlurTextChange={(value) => {
                    return onAsSeenOnUpdated(
                      {
                        source: resource.source,
                        url: value,
                      },
                      index,
                    );
                  }}
                />

                <Icon
                  color="alert"
                  cursor="pointer"
                  name="actions:trash_can"
                  top="3.4rem"
                  onClick={() => {
                    return removeSource(index);
                  }}
                />
              </Grid>
            </Grid>
          );
        })}

        {asSeenOn.length < 5 && (
          <Typography
            className="cursor-pointer"
            color="link"
            icon={<Icon color="link" name="ui-control:plus_circle" />}
            text="Add award"
            onClick={addSource}
          />
        )}
      </Stack>
    </Stack>
  );
};
