import { Cell, Flex, FormikInput, Icon } from 'gantri-components';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { AddOrEditPartsFormData } from '../../../../../../add-or-edit-part-modal.types';
import { Divider } from '../../../../../../../../../../../../../../components/divider';
import { deselectPartColorOverrideItem } from '../../part-color-overrides.constants';
import {
  getFromDropdownItems,
  getHandleSelectFromItem,
  getHandleSelectToItem,
  getToDropdownItems,
} from './part-color-override-item.helpers';
import { PartColorOverrideItemProps } from './part-color-override-item.types';
import { ColorSwatchDropdown } from '../../../../../../../../../../../../../../components/common/color-swatch-dropdown';

export const PartColorOverrideItem = (props: PartColorOverrideItemProps) => {
  const { from, index, items, remove, replace, to } = props;

  const { values } = useFormikContext<AddOrEditPartsFormData>();
  const colorOverride = values.colorOverride || [];

  const schemaId = 'colorOverride';

  const fromItems = useMemo(() => {
    return getFromDropdownItems({
      colorOverride,
      from,
      items,
      to,
    });
  }, [colorOverride, from, items, to]);
  const toItems = useMemo(() => {
    return getToDropdownItems({ from, items });
  }, [from, items]);

  const handleSelectFromItem = getHandleSelectFromItem({
    colorOverride,
    replace,
  });

  const handleSelectToItem = getHandleSelectToItem({
    colorOverride,
    replace,
  });

  return (
    <>
      <FormikInput
        Field={
          <ColorSwatchDropdown
            idProperty="code"
            items={[deselectPartColorOverrideItem, ...fromItems]}
            labelProperty="shortColorName"
            maxHeight={400}
            value={from?.code}
            onSelect={(item) => {
              handleSelectFromItem({ index, item });
            }}
          />
        }
        fieldVariant="standard"
        name={`${schemaId}[${index}].from.code`}
        required
      />
      <FormikInput
        Field={
          <ColorSwatchDropdown
            idProperty="code"
            items={toItems}
            labelProperty="shortColorName"
            maxHeight={400}
            value={to?.code}
            onSelect={(item) => {
              handleSelectToItem({ index, item });
            }}
          />
        }
        fieldVariant="standard"
        name={`${schemaId}[${index}].to.code`}
        required
      />
      <Flex alignItems="center" minHeight="3.6rem">
        <Icon
          color="alert"
          name="actions:trash_can"
          onClick={() => {
            remove(index);
          }}
        />
      </Flex>
      <Cell width={3}>
        <Divider />
      </Cell>
    </>
  );
};
