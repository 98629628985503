import { useNotification } from '../../../../hooks/useNotification';
import { UseQueryProps } from '../../../../hooks/use-fetch/react-queries.types';
import { useQueryFetch } from '../../../../hooks/use-fetch/use-query-fetch';
import { GetTaxesArgs, GetTaxesResponse } from './get-taxes.types';
import { FetchUsersEmailsResponse } from '../../../products/routes/fetch-users-emails/fetch-users-emails.types';
import { getTaxesObj } from './get-taxes';

interface Props<TData> extends UseQueryProps<TData, GetTaxesResponse> {
  args: GetTaxesArgs;
}

export const useGetTaxesQuery = <TData = Record<string, unknown>>(
  props?: Props<TData>,
) => {
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch<GetTaxesResponse | TData>({
    config: {
      enabled: props?.enabled ?? true,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch the taxes.',
        });
      },
      queryFn: () => {
        return getTaxesObj.query(props.args);
      },
      queryKey: getTaxesObj.key(props.args),
      select: (response: FetchUsersEmailsResponse) => {
        return props?.transform?.(response) ?? response;
      },
    },
    showLoading: props?.showLoading,
    transformDependencies: props?.transformDependencies,
  });

  return {
    isLoading,
    taxes: data as TData,
  };
};
