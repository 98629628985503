import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { getPartReleaseRateDetails } from './get-part-release-rate-details';
import { GetPartReleaseRateDetailsResponseDef } from './get-part-release-rate-details.types';

const getPartReleaseRateDetailsQueriesObj = getGenericQueryObj({
  groupName: 'part-release-rate-details',
  query: getPartReleaseRateDetails,
});

export const useGetPartReleaseRateDetails = <
  TransformedData = GetPartReleaseRateDetailsResponseDef,
>(
  props?: GenericFetchProps<
    never,
    GetPartReleaseRateDetailsResponseDef,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch part release rate details.',
    queryObj: getPartReleaseRateDetailsQueriesObj,
    ...props,
  });
};

export const useInvalidateGetPartReleaseRateDetailsCache = () => {
  const invalidateGetPartReleaseRateDetailsCache = useGetInvalidateQueryCache(
    getPartReleaseRateDetailsQueriesObj,
  );

  return { invalidateGetPartReleaseRateDetailsCache };
};
