import { Cell, Conditional, Flex, Typography } from 'gantri-components';
import { WarningIcon } from '../warning-icon';
import { YieldDetailsProps } from './yield-details.types';
import { NotApplicableText } from '../../../../../../components/common/not-applicable-text';

export const YieldDetails = (props: YieldDetailsProps) => {
  const { isInProgressPart, reasons, value } = props;

  return (
    <Conditional condition={value !== null} Fallback={<NotApplicableText />}>
      <Cell>
        <Flex alignItems="center" gap="0.8rem">
          <Conditional condition={isInProgressPart && value < 50}>
            <WarningIcon />
          </Conditional>
          <Typography text={`${value}%`} />
        </Flex>
        <Conditional condition={!!reasons.length}>
          {/* Written this way to achieve desired mobile layout */}
          <Flex
            gap={{ lg: '0.8rem', sm: '0' }}
            // @ts-ignore
            wrap={{ lg: 'nowrap', sm: 'wrap' }}
          >
            <Typography text="Top Failures:" whiteSpace="nowrap" />
            <Flex
              gap={{ lg: '1.2rem', sm: '0.4rem' }}
              // @ts-ignore
              wrap={{ lg: 'nowrap', sm: 'wrap' }}
            >
              {reasons.map(({ label, value }, index) => {
                const isLastIndex = index === reasons.length - 1;
                const text = `${label} ${value}%`;

                return (
                  <Typography
                    key={`${label}-${value}-${index}`}
                    text={`${text}${isLastIndex ? '' : ','}`}
                  />
                );
              })}
            </Flex>
          </Flex>
        </Conditional>
      </Cell>
    </Conditional>
  );
};
