import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import {
  GetOrderTimelineArgs,
  GetOrderTimelineResponse,
} from './get-order-timeline.types';

export const getOrderTimeline = (args: GetOrderTimelineArgs) => {
  const { orderId } = args;

  return axios
    .get<GetOrderTimelineResponse>(
      `${baseApiUrl}/transactions/${orderId}/timeline`,
    )
    .then(({ data }) => {
      return data;
    });
};
