import { InstructionFile } from '../../../../../../../../../../../../../../api/products/routes/fetch-product-part-templates/fetch-product-part-templates.types';
import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../../../../../../../constants/options';

export const getCanAddInstruction = (props: {
  files: InstructionFile[];
  label: string;
}) => {
  const { files, label } = props;

  const isPackPack = label === `${jobSteps.pack} ${jobTypeOptions.pack}`;
  const isAssembleAssemble =
    label === `${jobSteps.assemble} ${jobTypeOptions.assemble}`;

  const maxFiles = isAssembleAssemble || isPackPack ? 2 : 1;

  const hasInvalidFiles =
    files.filter(({ url }) => {
      return !!url;
    }).length !== files.length;

  return files.length < maxFiles && !hasInvalidFiles;
};
