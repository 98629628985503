import { Button } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { shipmentActionsModalAtoms } from '../../../shipment-actions-modal.atoms';
import { useHandleShipItems } from '../../../hooks';
import { StepShipNormallyFooterPropsDef } from './step-ship-normally-footer.types';

export const StepShipNormallyFooter = (
  props: StepShipNormallyFooterPropsDef,
) => {
  const { handleCloseModal } = props;

  const isDoNotShip = useRecoilValue(
    shipmentActionsModalAtoms.shipNormally.isDoNotShip,
  );
  const isGift = useRecoilValue(shipmentActionsModalAtoms.shipNormally.isGift);

  const isGiftMessageIncluded = useRecoilValue(
    shipmentActionsModalAtoms.shipNormally.isGiftMessageIncluded,
  );
  const isInPlainPackaging = useRecoilValue(
    shipmentActionsModalAtoms.shipNormally.isInPlainPackaging,
  );

  const { handleShipItems, processing: processingHandleShip } =
    useHandleShipItems();

  const confirmButtonText = isDoNotShip ? 'Confirm' : 'Shippo';

  const disableConfirm =
    isGift && (!isInPlainPackaging || !isGiftMessageIncluded);

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        disabled={disableConfirm}
        processing={processingHandleShip}
        size="large"
        text={confirmButtonText}
        onClick={async () => {
          await handleShipItems({ handleCloseModal, isDoNotShip });
        }}
      />
    </>
  );
};
