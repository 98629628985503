import styled from 'styled-components';

export const StyledHeaderButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledHeaderButtonWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 10px;
  }
`;
