import { atom } from 'recoil';
import { CancelItemsModalStep } from './cancel-items-modal.types';
import { cancelItemsModalSteps } from './cancel-items-modal.constants';

const atomKeyPrefix = 'CANCEL_ITEMS_MODAL';

export const cancelItemsModalAtoms = {
  step: atom<CancelItemsModalStep>({
    default: cancelItemsModalSteps.selectItems,
    key: `${atomKeyPrefix}-step`,
  }),
};
