import { Cell, Conditional, Typography } from 'gantri-components';
import { useFormikContext } from 'formik';
import { Fragment } from 'react';
import { sortBy } from 'lodash';
import { InventoryDataCell } from '../../../../../../../../../../../../components/common/custom-data-cells/inventory-data-cell';
import {
  convertStockJobInventoriesToInventoryRequests,
  StockJobsAssembleInventoryFormData,
} from '../../../../stock-jobs-assemble-inventory.schema';
import { StockJobVariantInventory } from '../../../../../../../../../../../../api/products/products.types';
import { GlobalVariantOptionItemProps } from './global-variant-option-item.types';

export const GlobalVariantOptionItem = (
  props: GlobalVariantOptionItemProps,
) => {
  const {
    globalVariantId,
    globalVariantOptionItem,
    isEditing,
    stockJobVariantInventories,
  } = props;

  const { code, name } = globalVariantOptionItem;

  const { setFieldValue, values } =
    useFormikContext<StockJobsAssembleInventoryFormData>();

  const { assembleVariantInventories } = values;

  const assembleVariantInventoryRequests =
    convertStockJobInventoriesToInventoryRequests(stockJobVariantInventories);

  return (
    <Fragment key={code}>
      <Cell>
        <Typography color="t2" text={name} />
      </Cell>
      <Cell>
        <Conditional
          condition={isEditing || !!stockJobVariantInventories.length}
          Fallback={<Typography text="-" />}
        >
          <InventoryDataCell
            editing={isEditing}
            hideExceedsStockIcon
            inventoryRequests={assembleVariantInventoryRequests}
            onEdit={async (
              updatedInventoryRequests: typeof assembleVariantInventoryRequests,
            ) => {
              const updatedAssembleVariantInventories: StockJobVariantInventory[] =
                updatedInventoryRequests.map((inventoryRequest) => {
                  const { id, ...rest } = inventoryRequest.inventory;
                  const stockJobInventory: StockJobVariantInventory = {
                    amount: Number(inventoryRequest.need || 0),
                    globalVariantId:
                      inventoryRequest.globalVariantId || globalVariantId,
                    inventoryId: id as number,
                    variantCode: inventoryRequest.variantCode || code,
                    ...rest,
                  };

                  return stockJobInventory;
                });

              const otherVariantInventories = assembleVariantInventories.filter(
                (inventory) => {
                  return !(
                    inventory.globalVariantId === globalVariantId &&
                    inventory.variantCode === code
                  );
                },
              );

              const assembleVariantInventoriesWithUpdates = sortBy(
                [
                  ...otherVariantInventories,
                  ...updatedAssembleVariantInventories,
                ],
                ['globalVariantId', 'variantCode'],
              );

              await setFieldValue(
                'assembleVariantInventories',
                assembleVariantInventoriesWithUpdates,
              );
            }}
          />
        </Conditional>
      </Cell>
    </Fragment>
  );
};
