import { Box, Flex, Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import { StyledAnchor } from '../../../../../../../components/common/styled-anchor';
import { Label } from '../../../../../../../components/label';
import { pressRecordModalAtoms } from '../../../press-record-modal.atoms';

export const ActionDeleteContent = () => {
  const pressRecord = useRecoilValue(pressRecordModalAtoms.pressRecord);

  return (
    <Stack>
      <Typography text="Are you sure you want to delete this press record?" />

      <Stack gap=".5x">
        <Label text={pressRecord.type} />

        <StyledBox padding="x">
          <Stack gap="2x" verticalPadding="x">
            <Flex justifyContent="space-between">
              <Typography text={pressRecord.source} variant="p3" />
              <Typography
                color="t2"
                text={pressRecord.publishDate}
                variant="p3"
              />
            </Flex>

            <Typography text={pressRecord.title} variant="h5" />

            <StyledAnchor
              href={pressRecord.link}
              target="_blank"
              text={pressRecord.link}
              variant="p3"
            />
          </Stack>
        </StyledBox>
      </Stack>
    </Stack>
  );
};

export const StyledBox = styled(Box)`
  ${({ theme }) => {
    return css`
      border: 1px solid ${theme.colors.dividers.t1};
      border-radius: 4px;
    `;
  }}
`;
