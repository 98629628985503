import { jobsApi } from '../../../api';
import { useNotification } from '../../../hooks/useNotification';

export const useCompleteAllJobs = () => {
  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const completeAllJobs = async (stockId: number) => {
    try {
      showLoading();

      await jobsApi.completeAllJobs(stockId);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to complete all jobs.',
      });
    } finally {
      hideLoading();
    }
  };

  return { completeAllJobs };
};
