import { FC, PropsWithChildren, useMemo, useState } from 'react';
import { Button, Table, Typography } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { productsApi } from '../../api';
import { NewProductModal } from './components';
import { ProductsFilter } from '../../components/dropdowns';
import { Product } from './products.types';
import { formatDate } from '../../helpers/formatter';
import { TableHeaderWithInfoToolTip } from '../../components/common/custom-data-cells/headers';
import { PageWithTable } from '../../components/layout';
import { useTableFilters } from '../../components/common/table/hooks/use-table-filters';
import {
  pageName,
  productsFiltersDefaults,
  productsPageAtoms,
} from '../../components/dropdowns/products-filter/products-filter.atoms';
import { useNotification } from '../../hooks/useNotification';
import { ProductsSortingField } from '../../components/dropdowns/products-filter/products-filter.types';
import { FiltersFetchRequestArgs } from '../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { StyledAnchor } from '../../components/common/styled-anchor';
import routePaths from '../../config/route-paths';
import { getIsClickableIfValue } from '../../helpers/get-is-clickable-if-value';
import Protected from '../../components/common/protected/protected';
import { ShortProductSummary } from '../../components/common/short-product-summary';
import { productCategories } from '../../api/products/products.constants';

export const Products: FC<PropsWithChildren> = () => {
  const [showNewProductModal, setShowNewProductModal] = useState(false);

  const columns = useMemo(() => {
    const columns: ColumnDef<Product>[] = [
      {
        accessorKey: 'id',
        cell: ({ getValue }: CellContext<Product, Product['name']>) => {
          const id = getValue();

          return (
            <StyledAnchor
              href={`${routePaths.products}/${id}`}
              text={`#${id}`}
            />
          );
        },
        header: 'Product #',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
        size: 80,
      },
      {
        accessorKey: 'name',
        cell: ({ getValue, row }: CellContext<Product, Product['name']>) => {
          const name = getValue();
          const { category, id, thumbnailImage } = row.original;

          const isAccessory = category === productCategories.accessory;
          const productName = isAccessory ? name : [name, category].join(' ');

          return (
            <ShortProductSummary
              category={category}
              id={id}
              name={productName}
              sku={thumbnailImage.sku}
              thumbnail={thumbnailImage.image}
            />
          );
        },
        header: 'Name',
        size: 250,
      },
      {
        accessorKey: 'category',
        header: 'Category',
        size: 100,
      },
      {
        accessorKey: 'size',
        header: 'Size',
        size: 80,
      },
      {
        accessorKey: 'designer',
        cell: ({ getValue }: CellContext<Product, Product['designer']>) => {
          const designer = getValue();

          return designer ? (
            <StyledAnchor
              text={designer.name}
              to={`${routePaths.designers}/${designer.id}`}
            />
          ) : null;
        },
        header: 'Designer',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'status',
        cell: ({ getValue }: CellContext<Product, Product['status']>) => {
          const status = getValue();

          return (
            <Typography
              color={status === 'Off Market' ? 'alert' : undefined}
              text={status}
            />
          );
        },
        header: 'Status',
        size: 80,
      },
      {
        accessorKey: 'createdAt',
        cell: ({ getValue }: CellContext<Product, Product['createdAt']>) => {
          return formatDate(getValue());
        },
        header: 'Created',
        size: 80,
      },
      {
        accessorKey: 'units',
        header: () => {
          return (
            <TableHeaderWithInfoToolTip
              headerText="Units"
              tooltipText="Total units sold in last 3 months"
            />
          );
        },
        size: 80,
      },
      {
        accessorKey: 'margin',
        header: 'Margin',
        size: 80,
      },
    ];

    return columns;
  }, []);

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const fetchProducts = async ({
    categories,
    count,
    inventory,
    page,
    search,
    sortingField,
    sortingType,
    statuses,
  }: FiltersFetchRequestArgs<
    Parameters<typeof productsApi.getPaginatedProducts>[0],
    ProductsSortingField
  >) => {
    try {
      showLoading();

      const { data } = await productsApi.getPaginatedProducts({
        categories,
        count,
        inventory,
        page,
        search,
        sortingField,
        sortingType,
        statuses,
      });

      setRecords(data.products);
      setTotalCount(data.allProducts);
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to fetch products.' });
    } finally {
      hideLoading();
    }
  };

  const toggleNewProduct = () => {
    setShowNewProductModal(!showNewProductModal);
  };

  const {
    filtersProps,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    any,
    Parameters<typeof productsApi.getPaginatedProducts>[0],
    ProductsSortingField
  >({
    fetchRequest: fetchProducts,
    filtersContent: <ProductsFilter />,
    modalFiltersDetails: [
      {
        atom: productsPageAtoms.filters.statuses,
        defaultValue: productsFiltersDefaults.filters.statuses,
        payloadKey: 'statuses',
      },
      {
        atom: productsPageAtoms.filters.categories,
        defaultValue: productsFiltersDefaults.filters.categories,
        payloadKey: 'categories',
      },
      {
        atom: productsPageAtoms.filters.inventory,
        defaultValue: productsFiltersDefaults.filters.inventory,
        payloadKey: 'inventory',
      },
    ],
    pageName,
    searchFilterDetails: {
      atom: productsPageAtoms.search,
    },
    sortByFilterDetails: {
      atom: productsPageAtoms.sortBy,
      defaultValue: productsFiltersDefaults.sortBy,
    },
    sortOptions: [
      { bidirectional: true, label: 'ID', sortingField: 'id' },
      {
        bidirectional: true,
        label: 'Designer',
        sortingField: 'designer',
      },
      { bidirectional: true, label: 'Status', sortingField: 'status' },
      {
        bidirectional: true,
        label: 'Created Date',
        sortingField: 'createdAt',
      },
      { bidirectional: true, label: 'Units', sortingField: 'units' },
      { bidirectional: true, label: 'Margin', sortingField: 'margin' },
    ],
  });

  return (
    <PageWithTable pageTitle="Products">
      <PageWithTable.Header title="Products">
        <Protected allowedFor={['Admin']}>
          <Button text="New Product" onClick={toggleNewProduct} />
        </Protected>
      </PageWithTable.Header>
      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          sorting={sortProps}
        />
        {showNewProductModal && <NewProductModal onClose={toggleNewProduct} />}
      </PageWithTable.Content>
    </PageWithTable>
  );
};
