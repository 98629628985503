import { Button, Flex, useModal } from 'gantri-components';
import { useGetDownloadMenuOption } from '../../../../hooks/use-get-download-menu-option';
import { useMoreMenu } from '../../../../hooks/use-more-menu';
import Protected from '../../../../components/common/protected/protected';
import { MachinesHeaderContentProps } from './machines-header-content.types';
import { downloadColumns } from './machines-header-content.constants';
import { fetchPaginatedMachinesQueryObj } from '../../../../api/machines/routes/fetch-paginated-machines/fetch-paginated-machines';
import { AddOrEditMachineModal } from '../add-or-edit-machine-modal';

export const MachinesHeaderContent = (props: MachinesHeaderContentProps) => {
  const { downloadFilters, loadMachinesPage, totalResults } = props;

  const [showAddMachineModal, hideAddMachineModal] = useModal(() => {
    return (
      <AddOrEditMachineModal
        loadMachinesPage={loadMachinesPage}
        onClose={hideAddMachineModal}
      />
    );
  }, [loadMachinesPage]);

  const { Toast: DownloadCsvToast, downloadOption } = useGetDownloadMenuOption({
    columns: downloadColumns,
    fetchRequestWithFilters: (overrides) => {
      return fetchPaginatedMachinesQueryObj
        .query({ ...downloadFilters, ...overrides })
        .then(({ machines }) => {
          return machines;
        });
    },
    fileNamePrefix: 'Machines Admin',
    totalResults,
  });

  const { MoreMenu } = useMoreMenu({
    options: [
      {
        ...downloadOption,
        allowedFor: ['Lead', 'Admin'],
      },
    ],
  });

  return (
    <>
      <Flex alignItems="center" gap=".5x">
        <Protected allowedFor={['Admin']}>
          <Button text="New Machine" onClick={showAddMachineModal} />
        </Protected>
        <Protected allowedFor={['Lead', 'Admin']}>
          <MoreMenu />
        </Protected>
      </Flex>
      <DownloadCsvToast />
    </>
  );
};
