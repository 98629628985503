import {
  Cell,
  Conditional,
  Grid,
  Icon,
  Stack,
  Typography,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { publishDraftModalAtoms } from '../../../publish-draft-modal.atoms';

export const MissingItemsContent = () => {
  const isWithoutAssemblyInstructions = useRecoilValue(
    publishDraftModalAtoms.isWithoutAssemblyInstructions,
  );
  const isWithoutAssembleStandardInventory = useRecoilValue(
    publishDraftModalAtoms.isWithoutAssembleStandardInventory,
  );
  const isWithoutPackInventory = useRecoilValue(
    publishDraftModalAtoms.isWithoutPackInventory,
  );
  const isWithoutParts = useRecoilValue(publishDraftModalAtoms.isWithoutParts);

  return (
    <Stack gap="2.4rem">
      <Grid columns="max-content 1fr" gap="s1">
        <Icon color="warning" name="alert:warning_triangle_24" size="2.4rem" />
        <Cell>
          <Conditional condition={!!isWithoutAssembleStandardInventory}>
            <Typography text="Product is missing assembly inventory." />
          </Conditional>
          <Conditional condition={!!isWithoutAssemblyInstructions}>
            <Typography text="Product is missing assembly instructions." />
          </Conditional>
          <Conditional condition={!!isWithoutPackInventory}>
            <Typography text="Product is missing pack inventory." />
          </Conditional>
          <Conditional condition={!!isWithoutParts}>
            <Typography text="Product has no parts." />
          </Conditional>

          <Typography text="Are you sure you want to continue to publish?" />
        </Cell>
      </Grid>
    </Stack>
  );
};
