import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  UpdateProductDesignArgs,
  UpdateProductDesignResponse,
} from './update-product-design.types';
import { updateProductDesign } from './update-product-design';

export const useUpdateProductDesign = <
  TransformedData = UpdateProductDesignResponse,
>(
  props?: GenericMutateQueryProps<
    UpdateProductDesignArgs,
    UpdateProductDesignResponse,
    TransformedData
  >,
) => {
  const { onMutate: onUpdateProductDesign, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to update product design.',
    mutationFn: updateProductDesign,
    ...props,
  });

  return { ...rest, onUpdateProductDesign };
};
