import { useState } from 'react';
import {
  Table,
  Typography,
  useConfirmationModal,
  useModal,
} from 'gantri-components';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useGetLocationColumns } from './hooks/use-get-location-columns';
import { useNotification } from '../../hooks/useNotification';
import { locationsApi } from '../../api';
import {
  LocationBuildings,
  LocationState,
  LocationWorkshop,
} from './locations.types';
import { PageWithTable } from '../../components/layout';
import {
  FiltersFetchRequestArgs,
  SortBy,
} from '../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import {
  LocationSortingField,
  PaginatedLocationsArgs,
} from '../../api/locations/routes/paginated-location/paginated-location.types';
import { useTableFilters } from '../../components/common/table/hooks/use-table-filters';
import {
  locationsFiltersDefaults,
  locationsPageAtoms,
  pageName,
} from './locations.atoms';
import { MoreMenuOption } from '../../components/dropdowns/more-menu/more-menu.types';
import { useTableColumnsSync } from '../../hooks';
import { LocationFilters } from './components/locations-filters';
import { LocationModal } from './components/location-modal';
import { locationModalAtoms } from './components/location-modal/location-modal.atoms';
import { LocationsHeaderContent } from './components/locations-header-content';

export const Locations = () => {
  const setIsEditModal = useSetRecoilState(locationModalAtoms.isEditModal);
  const setLocation = useSetRecoilState(locationModalAtoms.location);
  const resetLocation = useResetRecoilState(locationModalAtoms.location);

  const { hideLoading, notify, notifyError, showLoading } = useNotification();

  const [locationBuildings, setLocationBuildings] = useState<
    LocationBuildings[]
  >([]);
  const [locationWorkshops, setLocationWorkshop] = useState<LocationWorkshop[]>(
    [],
  );
  const [filterArgs, setFilterArgs] =
    useState<
      FiltersFetchRequestArgs<PaginatedLocationsArgs, LocationSortingField>
    >();

  const [locationToDelete, setLocationToDelete] = useState<number>(null);

  const handleDeleteLocation = async () => {
    try {
      showLoading();

      const response = await locationsApi.deleteLocation({
        locationId: locationToDelete,
      });

      if (response.success) {
        setRecords(
          records.filter((location: { id: number }) => {
            return location.id !== locationToDelete;
          }),
        );
        setTotalCount(records.length - 1);
        notify(response.notice);
      }

      hideDeleteLocationModal();
      setLocationToDelete(null);
    } catch (error) {
      notifyError(
        error?.response?.data?.error ||
          'An error occurred deleting the current location.',
      );
    } finally {
      hideLoading();
    }
  };

  const [showDeleteLocationModal, hideDeleteLocationModal] =
    useConfirmationModal({
      confirmButtonText: 'Delete',
      confirmButtonVariant: 'primaryAlert',
      content: (
        <Typography
          align="center"
          text="Are you sure you want to delete this location?"
        />
      ),
      headerText: 'Delete Location',
      onClose: () => {
        hideDeleteLocationModal();
        setLocationToDelete(null);
      },
      onConfirm: handleDeleteLocation,
      width: { lg: '44rem', md: '100%' },
    });

  const fetchLocation = async (
    args: FiltersFetchRequestArgs<PaginatedLocationsArgs, LocationSortingField>,
  ) => {
    try {
      showLoading();

      setFilterArgs(args);

      const { data } = await locationsApi.paginatedLocations(args);

      if (data.success) {
        setRecords(data.locations || []);
        setTotalCount(data.totalLocations || 0);
        setLocationBuildings(data.locationBuildings || []);
        setLocationWorkshop(data.locationWorkshops || []);
      }
    } catch (error) {
      notifyError(
        error?.response?.data?.error ||
          'An error occurred fetching all locations',
      );
    } finally {
      hideLoading();
    }
  };

  const {
    filtersProps,
    handleFetchRequest,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    LocationState,
    PaginatedLocationsArgs,
    LocationSortingField
  >({
    excludeFromActiveFiltersCount: ['sortBy'],
    fetchRequest: fetchLocation,
    filtersContent: <LocationFilters />,
    modalFiltersDetails: [
      {
        atom: locationsPageAtoms.filters.type,
        defaultValue: locationsFiltersDefaults.filters.type,
        payloadKey: 'type',
      },
      {
        atom: locationsPageAtoms.filters.workshop,
        defaultValue: locationsFiltersDefaults.filters.workshop,
        payloadKey: 'workshop',
      },
      {
        atom: locationsPageAtoms.sortBy,
        defaultValue: locationsFiltersDefaults.filters.sortBy,
        payloadKey: 'sortBy',
      },
    ],
    pageName,
    searchFilterDetails: {
      atom: locationsPageAtoms.search,
    },
    sortOptions: [
      { label: 'Building', sortingField: 'building' },
      { label: 'Workshop', sortingField: 'workshop' },
      { label: 'Parts desc.', sortingField: 'parts', sortingType: 'DESC' },
      {
        label: 'Inventory desc.',
        sortingField: 'totalInventories',
        sortingType: 'DESC',
      },
      { label: 'Status', sortingField: 'status' },
    ],
  });

  const [showLocationModal, hideLocationModal] = useModal(() => {
    return (
      <LocationModal
        handleFetchRequest={handleFetchRequest}
        locationBuildings={locationBuildings}
        locationWorkshops={locationWorkshops}
        records={records}
        setRecords={setRecords}
        onClose={hideLocationModal}
      />
    );
  }, [records, locationBuildings, locationWorkshops]);

  const handleAddLocation = () => {
    resetLocation();
    setIsEditModal(false);
    showLocationModal();
  };

  const menuOptions: MoreMenuOption<LocationState>[] = [
    {
      enabled: true,
      name: 'Edit',
      onOptionClick: (location) => {
        setLocation(location);
        setIsEditModal(true);
        showLocationModal();
      },
    },
    {
      disabledTooltipProps: {
        description:
          'Unable to delete a location that currently has parts and is active',
      },
      enabled: (record) => {
        return !(!!record.parts && record.status === 'Active');
      },
      name: 'Delete',
      onOptionClick: ({ id }) => {
        setLocationToDelete(id);
        showDeleteLocationModal();
      },
    },
  ];

  const columns = useGetLocationColumns({ menuOptions });

  const [sortingField, setSortingField] = useRecoilState(
    locationsPageAtoms.sortBy,
  );

  const onSort = async ({ sortingField }: SortBy<LocationSortingField>) => {
    // overriding default onSort due to using non-standard sorting args
    setSortingField(sortingField);
    await handleFetchRequest({ page: 1 });
  };

  const columnsSyncProps = useTableColumnsSync('locations');

  return (
    <PageWithTable pageTitle="Locations">
      <PageWithTable.Header title="Locations">
        <LocationsHeaderContent
          filterArgs={filterArgs}
          handleAddLocation={handleAddLocation}
          totalResults={pagingProps.total}
        />
      </PageWithTable.Header>

      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          highlightHoveredRow
          paging={pagingProps}
          search={{ ...searchProps, placeholder: 'Search by name or type' }}
          sorting={{
            ...sortProps,
            current: sortingField,
            onSort,
          }}
          stickyLastColumn
          {...columnsSyncProps}
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
