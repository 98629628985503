import { RestartReason } from '../../../restart-or-cancel-part-modal.types';

export const restartReasonOptions: {
  label: string;
  subTitle: string;
  value: RestartReason;
}[] = [
  {
    label: 'Lost part',
    subTitle: 'Part cannot be found or missing RFID',
    value: 'Lost',
  },
  {
    label: 'Failed part',
    subTitle: 'Worker damaged a part beyond repair',
    value: 'Failed',
  },
];
