import { Button, Modal, Typography } from 'gantri-components';
import { useArchivePartTemplate } from '../../../../../../../../api/part-templates/routes/archive-part-template';
import { useInvalidateFetchProductCache } from '../../../../../../../../api/products/routes';
import { DeletePartModalProps } from './delete-part-modal.types';

export const DeletePartModal = (props: DeletePartModalProps) => {
  const { onClose, part } = props;

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();

  const { isLoading, onArchivePart } = useArchivePartTemplate({
    onSuccess: async () => {
      await invalidateFetchProductCache();

      onClose();
    },
  });

  const handleDeletePart = async () => {
    await onArchivePart({
      id: part.id,
    });
  };

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            processing={isLoading}
            size="large"
            text="Delete"
            variant="primaryAlert"
            onClick={handleDeletePart}
          />
        </>
      }
      header="Delete Part"
      maxWidth={{ lg: '40rem', md: '100%' }}
      onClose={onClose}
    >
      <Typography
        align="center"
        htmlText={`Are you sure you want to delete <strong>${part.name}</strong>?`}
      />
    </Modal>
  );
};
