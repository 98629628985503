import { useFormikContext } from 'formik';
import {
  Flex,
  Typography,
  FileUploader,
  HandleDeleteFile,
  ValidateFile,
  HandleUploadsComplete,
  Cell,
} from 'gantri-components';
import { useEffect } from 'react';
import { imageExtensions } from '../../../../helpers/images/cloudinary/generate-transformations-string/generate-transformations-string.types';
import { useFirebaseFileUploader } from '../../../../hooks/use-firebase-file-uploader';
import { StyledInventoryPurchaseFieldContainer } from '../../new-inventory-purchase.styles';
import {
  NewInventoryPurchaseFormState,
  Attachment,
} from '../../new-inventory-purchase.types';
import { AttachmentsSectionProps } from './attachments-section.types';

export const AttachmentsSection = (props: AttachmentsSectionProps) => {
  const { setProcessingUpload } = props;

  const { setFieldValue, values } =
    useFormikContext<NewInventoryPurchaseFormState>();

  const { attachments, inventory } = values;

  const validateFile: ValidateFile = ({ fileName }) => {
    const existingAttachment = attachments
      .map((item) => {
        return item.fileName;
      })
      .includes(fileName);

    if (existingAttachment) {
      return `"${fileName}" already exists. Please rename.`;
    }
  };

  const handleUploadsComplete: HandleUploadsComplete = async ([
    { fileName, fileUrl },
  ]) => {
    const newAttachment = {
      fileName,
      url: fileUrl,
    };

    await setFieldValue('attachments', [...attachments, newAttachment], true);
  };

  const onFileDelete: HandleDeleteFile = async ({ fileName, fileUrl }) => {
    const updatedAttachments = attachments.filter((attachment) => {
      return attachment.url !== fileUrl && attachment.fileName !== fileName;
    });

    await setFieldValue('attachments', updatedAttachments, true);
  };

  const uploadPath = `inventory-purchases/${inventory}`;

  const { fileUploaderProps, processing: processingUpload } =
    useFirebaseFileUploader({
      fileUrl: null,
      handleUploadsComplete,
      onFileDelete: null,
      path: uploadPath,
      validateFile,
    });

  const expectedExtensions = [...imageExtensions, 'pdf'] as const;

  const Attachment = (props: { attachment: Attachment }) => {
    const { attachment } = props;

    const { fileUploaderProps: attachmentFileUploaderProps } =
      useFirebaseFileUploader({
        fileUrl: attachment.url,
        handleUploadsComplete: null,
        onFileDelete,
        path: null,
      });

    return (
      <StyledInventoryPurchaseFieldContainer margin="1rem">
        <Cell justifyItems="start">
          <FileUploader
            {...attachmentFileUploaderProps}
            fileName={attachment.fileName}
            variant="plain"
          />
        </Cell>
      </StyledInventoryPurchaseFieldContainer>
    );
  };

  useEffect(() => {
    setProcessingUpload(processingUpload);
  }, [processingUpload]);

  return (
    <>
      <Typography text="Attachments" textStyle="bold" variant="h4" />

      <Flex direction="column" width="38rem" wrap="wrap">
        {attachments.map((attachment) => {
          return <Attachment key={attachment.url} attachment={attachment} />;
        })}
        <StyledInventoryPurchaseFieldContainer margin="1rem">
          <FileUploader
            {...fileUploaderProps}
            expectedExtensions={[...expectedExtensions]}
            fileName={null}
            variant="button"
          />
        </StyledInventoryPurchaseFieldContainer>
      </Flex>
    </>
  );
};
