import { OnValidationCompleted } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { bulkUploadModalAtoms } from '../../../../bulk-upload-modal.atoms';
import { FileValidationRecord } from '../../../../bulk-upload-modal.types';
import { delayFor } from '../../../../../../../../../../../../helpers/delay-for';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import { SkuAssetUploadFileData } from '../../../../../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';
import { getUpdatedAssetsBySku } from './helpers/get-updated-assets-by-sku';
import { useFormatValidFile } from './helpers/use-format-valid-file';

export const useOnValidationCompleted = () => {
  const skus = useRecoilValue(productAtoms.skus);
  const setNumFilesAdded = useSetRecoilState(
    bulkUploadModalAtoms.numFilesAdded,
  );
  const setFileValidationByTimestamp = useSetRecoilState(
    bulkUploadModalAtoms.fileValidationByTimestamp,
  );
  const setProcessingFiles = useSetRecoilState(
    bulkUploadModalAtoms.processingFiles,
  );
  const setAssetsBySku = useSetRecoilState(bulkUploadModalAtoms.assetsBySku);
  const setIsHistoryExpanded = useSetRecoilState(
    bulkUploadModalAtoms.isHistoryExpanded,
  );

  const formatValidFile = useFormatValidFile();

  const onValidationCompleted: OnValidationCompleted = async ({
    invalidFiles,
    validFiles,
  }) => {
    try {
      const updatedInvalidFiles = [...invalidFiles];

      const formattedValidFiles: SkuAssetUploadFileData[] = await Promise.all(
        validFiles.map(formatValidFile),
      );

      setAssetsBySku((prevAssetsBySku) => {
        const updatedAssetsBySku = getUpdatedAssetsBySku({
          addToInvalidFiles: (invalidFile) => {
            invalidFiles.push(invalidFile);
          },
          formattedValidFiles,
          prevAssetsBySku,
        });

        return updatedAssetsBySku;
      });

      setFileValidationByTimestamp((prevRecords) => {
        const timestamp = Date.now();

        const processingRecord: Record<string, FileValidationRecord> = {
          [timestamp]: {
            invalidFiles: updatedInvalidFiles.map(
              ({ errorMessage, fileName }) => {
                return { errorMessage, fileName };
              },
            ),
            timestamp,
            validFiles: formattedValidFiles,
          },
        };

        return {
          ...prevRecords,
          ...processingRecord,
        };
      });

      setNumFilesAdded((value) => {
        return value + validFiles.length;
      });

      // Delay is not necessary, but helps keep the timing of the updated table rows in sync with both the dropzone processing status change and history panel opening
      await delayFor(skus.length * 10);

      setIsHistoryExpanded(true);
    } finally {
      setProcessingFiles(false);
    }
  };

  return onValidationCompleted;
};
