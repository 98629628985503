import { Table } from 'gantri-components';
import { useEffect, useState } from 'react';
import { GiftCardsFilter } from '../../components/dropdowns';
import { GiftCardRecord } from './gift-cards.types';
import { PageWithTable } from '../../components/layout';
import { useTableFilters } from '../../components/common/table/hooks';
import {
  FetchPaginatedGiftCardsArgs,
  GiftCardsSortingField,
  useFetchPaginatedGiftCardsQuery,
} from '../../api/gift-cards/routes';
import {
  giftCardsFiltersDefaults,
  giftCardsPageAtoms,
  pageName,
} from '../../components/dropdowns/gift-cards-filter/gift-cards-filter.atoms';
import { columns, sortOptions } from './gift-cards.constant';

export const GiftCards = () => {
  const [filters, updateFilters] =
    useState<FetchPaginatedGiftCardsArgs>(undefined);
  const { isLoading, ...data } = useFetchPaginatedGiftCardsQuery({
    args: filters,
  });

  useEffect(() => {
    if (isLoading) return;

    setRecords(data?.records ?? []);
    setTotalCount(data?.total ?? 0);
  }, [isLoading, data]);

  const {
    filtersProps,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    GiftCardRecord,
    FetchPaginatedGiftCardsArgs,
    GiftCardsSortingField
  >({
    fetchRequest: updateFilters,
    filtersContent: <GiftCardsFilter />,
    modalFiltersDetails: [
      {
        atom: giftCardsPageAtoms.filters.statuses,
        defaultValue: giftCardsFiltersDefaults.filters.statuses,
        payloadKey: 'statuses',
      },
    ],
    pageName,
    searchFilterDetails: {
      atom: giftCardsPageAtoms.search,
    },
    sortByFilterDetails: {
      atom: giftCardsPageAtoms.sortBy,
      defaultValue: giftCardsFiltersDefaults.sortBy,
    },
    sortOptions,
  });

  return (
    <PageWithTable pageTitle="Gift Cards">
      <PageWithTable.Header title="Gift Cards" />

      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          sorting={sortProps}
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
