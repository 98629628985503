import { useState } from 'react';
import {
  CustomActionProps,
  RowSelectionProviders,
  Table,
} from 'gantri-components';
import { PageWithTable } from '../../components/layout';
import { useMoreMenu } from '../../hooks/use-more-menu';
import { useStockSummaryColumns } from './hooks/use-stock-summary-columns';
import { StockSummarySelectedRowsActions } from './components/stock-summary-selected-rows-actions';
import { useStockSummaryModals } from './hooks/use-stock-summary-modals';
import { useStockSummaryTableData } from './hooks/use-stock-summary-table-data';
import { StockSummaryRecord } from '../../api/stocks/routes/fetch-stock-summary/fetch-stock-summary.types';
import Protected from '../../components/common/protected/protected';

const StockSummaryComponent = () => {
  const { filtersProps, footerData, pagingProps, records, sortProps } =
    useStockSummaryTableData();

  const columns = useStockSummaryColumns({
    footerData,
  });

  const [selectedStocks, setSelectedStocks] = useState<StockSummaryRecord[]>(
    [],
  );

  const {
    showAddStockModal,
    showUpdateGrowthMultiplierModal,
    showUpdateInventoryTargetModal,
  } = useStockSummaryModals({
    selectedStocks,
  });

  const { MoreMenu } = useMoreMenu({
    options: [
      {
        enabled: true,
        name: 'Update Growth Multiplier',
        onOptionClick: showUpdateGrowthMultiplierModal,
      },
    ],
  });

  const stockSummaryPageTitle = 'Stock Summary';

  const customAction: CustomActionProps<StockSummaryRecord> = {
    Component: (props) => {
      return (
        <StockSummarySelectedRowsActions
          {...props}
          showAddStockModal={showAddStockModal}
          showUpdateInventoryTargetModal={showUpdateInventoryTargetModal}
        />
      );
    },
    position: 'handle-selections',
  };

  return (
    <PageWithTable pageTitle={stockSummaryPageTitle}>
      <PageWithTable.Header title={stockSummaryPageTitle}>
        <Protected allowedFor={['Admin']}>
          <MoreMenu />
        </Protected>
      </PageWithTable.Header>
      <PageWithTable.Content>
        <Table
          columns={columns}
          customAction={customAction}
          data={records}
          filters={filtersProps}
          highlightHoveredRow
          paging={pagingProps}
          rowSelection={{
            idProperty: 'sku',
            onSelect: setSelectedStocks,
            type: 'checkboxes',
          }}
          sorting={sortProps}
          stickyFooter
          stickyLastColumn
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};

export const StockSummary = () => {
  return (
    <RowSelectionProviders>
      <StockSummaryComponent />
    </RowSelectionProviders>
  );
};
