import { Table } from 'gantri-components';
import { PageWithTable } from '../../components/layout';
import { useReferrals } from './use-referrals';
import { useGetReferralsColumns } from './hooks/use-get-referrals-columns';

export const Referrals = () => {
  const {
    filtersProps,
    onShowCancelReferral,
    onShowConfirmation,
    pagingProps,
    records,
    searchProps,
    sortProps,
  } = useReferrals();

  const columns = useGetReferralsColumns({
    onShowCancelReferral,
    onShowConfirmation,
  });

  return (
    <PageWithTable pageTitle="Referrals">
      <PageWithTable.Header title="Referrals" />
      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          getRowProps={({ original }) => {
            return {
              'data-referral-id': original.id,
            };
          }}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          sorting={sortProps}
          stickyLastColumn
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
