import { atom } from 'recoil';
import { discardStockModalSteps } from './discard-stock-modal.constants';
import { DiscardStockModalStep } from './discard-stock-modal.types';

const atomKeyPrefix = 'DISCARD_STOCK_MODAL';

export const discardStockModalAtoms = {
  step: atom<DiscardStockModalStep>({
    default: discardStockModalSteps.confirmDiscard,
    key: `${atomKeyPrefix}-step`,
  }),
  updateOnClose: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
};
