import { FC, PropsWithChildren } from 'react';
import { Icon, Typography } from 'gantri-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  StyledMobileHeaderContainer,
  StyledMobileLogoWrapper,
  StyledMobileSidebarContainer,
} from './mobile-header.styles';
import SidebarLinks from '../sidebar-links';
import MenuIcon from './components/menu-icon';
import routePaths from '../../../config/route-paths';
import { AccountDropdown } from '../account-dropdown';
import Grid from '../../../components/common/grid';
import { MobileHeaderProps } from './mobile-header.types';
import { getEnvLabel } from '../helpers/get-env-label';
import { useRouter } from '../../../hooks';
import { notificationAtoms } from '../../../components/notification/notification.atoms';
import { userAtoms } from '../../../global-atoms/users';
import { uiAtoms } from '../../../global-atoms/ui';

export const MobileHeader: FC<PropsWithChildren<MobileHeaderProps>> = ({
  version,
}) => {
  const { navigate } = useRouter();

  const notifications = useRecoilValue(notificationAtoms.notifications);
  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(
    uiAtoms.isSidebarOpen,
  );
  const { type: accountType } = useRecoilValue(userAtoms.user);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const goHome = () => {
    navigate(routePaths.main);
  };

  const headerDataAttrs = {
    'data-mobile-sidebar-header': '',
  };

  return (
    <>
      <StyledMobileHeaderContainer
        numToasts={notifications.length}
        {...headerDataAttrs}
      >
        <StyledMobileLogoWrapper
          alignItems="baseline"
          columns="repeat(3, max-content)"
          gap="1rem"
        >
          <Typography
            text={accountType}
            textStyle="bold"
            variant="h4"
            onClick={goHome}
          />
          <Icon height="2rem" name="people:person" top="4px" />
          <AccountDropdown showName />
        </StyledMobileLogoWrapper>
        <Grid alignItems="center" columns="repeat(3, max-content)" gap="1rem">
          <Typography
            style={{ fontSize: '1.2rem' }}
            text={getEnvLabel()}
            variant="p2"
          />
          {!!version && <Typography color="t1" text={version} variant="p2" />}
          <MenuIcon isActive={isSidebarOpen} onClick={toggleSidebar} />
        </Grid>
      </StyledMobileHeaderContainer>
      <StyledMobileSidebarContainer isActive={isSidebarOpen}>
        {accountType && (
          <SidebarLinks userType={accountType} onLinkClicked={closeSidebar} />
        )}
      </StyledMobileSidebarContainer>
    </>
  );
};
