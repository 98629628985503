import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import {
  FetchUsersEmailsRequest,
  FetchUsersEmailsResponse,
} from './fetch-users-emails.types';

const fetchUsersEmailsQuery = (request: FetchUsersEmailsRequest) => {
  return axios
    .get<FetchUsersEmailsResponse>(`${coreApiUrl}/users/get-users-list/`, {
      params: request,
    })
    .then(({ data }) => {
      return data;
    });
};

export const fetchUsersEmailsQueriesObj = getGenericQueryObj({
  groupName: 'fetch-users-emails',
  query: fetchUsersEmailsQuery,
});
