import axios from 'axios';
import { getFileUrl } from 'gantri-components';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  FetchSkuAssetsArgs,
  FetchSkuAssetsAxiosResponse,
  FetchSkuAssetsResponse,
  FormattedSkuAssets,
  FormattedSkuLevelAssetData,
} from './fetch-sku-assets.types';

export const fetchSkuAssets = async (props: FetchSkuAssetsArgs) => {
  const { productId } = props;

  return axios
    .get<FetchSkuAssetsAxiosResponse>(
      `${getCoreApiUrl('products')}/${productId}/sku-assets`,
    )
    .then(({ data }) => {
      const skus = Object.keys(data.skuAssets);
      const formattedSkuAssets = skus.reduce<FormattedSkuAssets>(
        (accumulator, sku) => {
          const assetsForSku = data.skuAssets[sku];

          const {
            dimmingPhotoDark,
            dimmingPhotoLight,
            scalePhoto,
            selectedWhiteBackgroundPhoto,
            usdz,
            whiteBackgroundPhotos,
          } = assetsForSku;

          const lightFileName = dimmingPhotoLight;
          const lightSrc = lightFileName
            ? getFileUrl<'products'>({
                directory: 'products',
                fileName: lightFileName,
                fileType: 'dimming-photos',
                identifiers: {
                  productId,
                  sku,
                },
              })
            : undefined;

          const darkFileName = dimmingPhotoDark;
          const darkSrc = darkFileName
            ? getFileUrl<'products'>({
                directory: 'products',
                fileName: darkFileName,
                fileType: 'dimming-photos',
                identifiers: {
                  productId,
                  sku,
                },
              })
            : undefined;

          const scalePhotoFileName = scalePhoto;
          const scalePhotoSrc = scalePhoto
            ? getFileUrl<'products'>({
                directory: 'products',
                fileName: scalePhoto,
                fileType: 'scale-photo',
                identifiers: {
                  productId,
                  sku,
                },
              })
            : undefined;

          const transformedData: FormattedSkuLevelAssetData = {
            dimmingPhotoDark: darkFileName
              ? {
                  fileName: darkFileName,
                  fileUrl: darkSrc,
                }
              : undefined,
            dimmingPhotoLight: lightFileName
              ? {
                  fileName: lightFileName,
                  fileUrl: lightSrc,
                }
              : undefined,
            scalePhoto: scalePhoto
              ? {
                  fileName: scalePhotoFileName,
                  fileUrl: scalePhotoSrc,
                }
              : undefined,
            selectedWhiteBackgroundPhoto,
            sku,
            usdz,
            whiteBackgroundPhotos: whiteBackgroundPhotos.map((fileName) => {
              const fileUrl = getFileUrl<'products'>({
                directory: 'products',
                fileName,
                fileType: 'product-photos',
                identifiers: {
                  productId,
                  sku,
                },
              });

              return {
                fileName,
                fileUrl,
              };
            }),
          };

          return { ...accumulator, [sku]: transformedData };
        },
        {},
      );

      const formattedResponse: FetchSkuAssetsResponse = {
        ...data,
        skuAssets: formattedSkuAssets,
      };

      return formattedResponse;
    });
};
