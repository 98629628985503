import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  CreateMachineArgs,
  CreateMachineResponse,
} from './create-machine.types';
import { createMachine } from './create-machine';

export const useCreateMachine = <TransformedData = CreateMachineResponse>(
  props?: GenericMutateQueryProps<
    CreateMachineArgs,
    CreateMachineResponse,
    TransformedData
  >,
) => {
  const { onMutate: onCreateMachine, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to create machine.',
    mutationFn: createMachine,
    ...props,
  });

  return { ...rest, onCreateMachine };
};
