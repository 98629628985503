import { Conditional, Icon, Tooltip, Typography } from 'gantri-components';
import { StyledAttentionsList } from './cell-job-name.styles';
import { StyledIconWithTextContainer } from '../../../../../../assets/styles/common';
import { CellJobNameProps } from './cell-job-name.types';

export const CellJobName = (props: CellJobNameProps) => {
  const {
    getValue,
    row: { original },
  } = props;

  const name = getValue();
  const { attentions } = original;

  return (
    <Conditional condition={!!name}>
      <StyledIconWithTextContainer>
        <Typography text={name} />
        <Conditional condition={!!attentions.length}>
          <Tooltip
            Component={
              <StyledAttentionsList>
                {attentions.map((attention, index) => {
                  return (
                    <Typography
                      key={index}
                      color="t2"
                      text={attention.description}
                    />
                  );
                })}
              </StyledAttentionsList>
            }
            position="top"
          >
            <Icon color="alert" name="view:eye_open" />
          </Tooltip>
        </Conditional>
      </StyledIconWithTextContainer>
    </Conditional>
  );
};
