import { atom } from 'recoil';
import {
  RestartOrCancelPartModalStep,
  RestartReason,
} from './restart-or-cancel-part-modal.types';

export const atomKeyPrefix = 'RESTART_OR_CANCEL_PART_MODAL';

export const restartOrCancelPartModalAtoms = {
  notes: atom<string>({
    default: '',
    key: `${atomKeyPrefix}-notes`,
  }),

  restartReason: atom<RestartReason>({
    /** Intentionally null so radio is not selected by default. */
    default: null,
    key: `${atomKeyPrefix}-restart-reason`,
  }),

  step: atom<RestartOrCancelPartModalStep>({
    /** Intentionally null as starting step is set on load. */
    default: null,
    key: `${atomKeyPrefix}-step`,
  }),
};
