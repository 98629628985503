import {
  Conditional,
  Flex,
  Grid,
  Icon,
  Tooltip,
  Typography,
} from 'gantri-components';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import routePaths from '../../../../../../config/route-paths';
import { InventoryPurchaseCellProps } from './inventory-purchase-cell.types';

export const InventoryPurchaseCell = (props: InventoryPurchaseCellProps) => {
  const { getValue, row } = props;
  const id = getValue();
  const { hasDelayedShipping, hasQuantityIssues } = row.original;

  const tooltipMessages = [
    hasDelayedShipping &&
      'Actual shipment date was later than expected shipment date.',
    hasQuantityIssues &&
      'Quantity of units delivered was lower than expected quantity.',
  ].filter((item) => {
    return item;
  });
  const moreThanOneMessage = tooltipMessages.length > 1;

  return (
    <Flex alignItems="center" gap="0.7rem">
      <Link to={`${routePaths.inventoryPurchases}/${id}`}>
        <Typography color="link" decoration="underline" text={id} />
      </Link>
      <Conditional condition={hasDelayedShipping || hasQuantityIssues}>
        <Icon color="warning" name="alert:warning_triangle" />
        <Tooltip
          Component={
            <Conditional
              condition={moreThanOneMessage}
              Fallback={
                <Typography
                  color="t2"
                  horizontalPadding="1.2rem"
                  text={tooltipMessages[0]}
                  verticalPadding="0.8rem"
                />
              }
            >
              <Grid
                alignItems="baseline"
                columnGap="0.6rem"
                columns="max-content 1fr"
                height="auto"
                horizontalPadding="1.2rem"
                rowGap="1rem"
                verticalPadding="0.8rem"
                width="22.3rem"
              >
                {tooltipMessages.map((text, index) => {
                  return (
                    <Fragment key={index}>
                      <Typography
                        key={text}
                        color="t2"
                        text={`${index + 1}.`}
                      />
                      <Typography key={text} color="t2" text={text} />
                    </Fragment>
                  );
                })}
              </Grid>
            </Conditional>
          }
          overlayContainerStyles={null}
          position="bottom-start"
        >
          <Icon name="alert:i_circle" />
        </Tooltip>
      </Conditional>
    </Flex>
  );
};
