import PropTypes from 'prop-types';
import { Typography } from 'gantri-components';
import { formatDate } from '../../../../helpers/formatter';
import TimelineUserInfo from '../user-info';
import { LONG_FORMAT } from '../../../../constants/dates';
import { StyledBox } from './base-timeline-item.styles';

const TimelineItem = ({ createdAtSec, description, userId, ...rest }) => {
  return (
    <>
      <Typography text={formatDate(createdAtSec, LONG_FORMAT)} />
      <StyledBox>
        <Typography
          display="inline"
          htmlText={description.replace(/\n/g, '<br />')}
        />
        &nbsp;
        {userId && <TimelineUserInfo userId={userId} {...rest} />}
      </StyledBox>
    </>
  );
};

TimelineItem.propTypes = {
  createdAtSec: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  rest: PropTypes.object,
  userId: PropTypes.number,
};

export default TimelineItem;
