import { useEffect, useState } from 'react';
import { Button, Conditional, Flex, Icon, Stack } from 'gantri-components';
import { ShortProductSummary } from '../../../../../../../../components/common/short-product-summary';
import { useFetchProduct } from '../../../../../../../../api/products/routes';
import { SkuConfigColorDropdown } from '../../../../../../../product/components/product-sku-dropdown/components/sku-config-color-dropdown';
import { SkuConfigVariantDropdowns } from '../../../../../../../product/components/product-sku-dropdown/components/sku-config-variant-dropdowns';
import { EditableStockItemPropsDef } from './editable-stock-item.types';
import { StyledStockWrapperBox } from './editable-stock-item.styles';

export const EditableStockItem = (props: EditableStockItemPropsDef) => {
  const { handleUpdateSku, productId, sku, stockInfoId } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedSku, setSelectedSku] = useState<string>(sku);

  const { data, isLoading } = useFetchProduct({
    enabled: isEditing,
    fetchArgs: {
      id: productId,
      sku: undefined,
      type: 'assets',
      versionId: undefined,
    },
  });

  const productData = data?.product;

  const isSkuDifferent = sku !== selectedSku;

  const onEdit = () => {
    setIsEditing(true);
  };

  const onCancel = () => {
    setIsEditing(false);

    setSelectedSku(sku);
  };

  const onSave = () => {
    setIsEditing(false);

    if (isSkuDifferent) {
      handleUpdateSku({ sku: selectedSku, stockAssociationId: stockInfoId });
    }
  };

  useEffect(() => {
    setSelectedSku(sku);
  }, [sku]);

  return (
    <StyledStockWrapperBox padding="x">
      <ShortProductSummary fetchSku={sku}>
        <Stack gap="2x" height="unset" paddingBottom="x" paddingTop="2x">
          <Conditional
            condition={isEditing}
            Fallback={
              <Button
                icon={<Icon name="docs:pencil" />}
                justifySelf="start"
                size="small"
                text="Edit"
                variant="secondary"
                onClick={onEdit}
              />
            }
          >
            <Conditional
              condition={isLoading || !productData}
              Fallback={
                <>
                  <SkuConfigColorDropdown
                    highlightMarketplaceColors
                    product={productData}
                    setSku={setSelectedSku}
                    sku={selectedSku}
                  />

                  <SkuConfigVariantDropdowns
                    product={productData}
                    setSku={setSelectedSku}
                    sku={selectedSku}
                  />
                </>
              }
            >
              <Icon color="link" name="animated:loader" />
            </Conditional>

            <Flex gap=".5x">
              <Button
                justifySelf="start"
                size="small"
                text="Cancel"
                variant="secondary"
                onClick={onCancel}
              />
              <Button
                disabled={isLoading || !isSkuDifferent}
                justifySelf="start"
                size="small"
                text="Save"
                onClick={onSave}
              />
            </Flex>
          </Conditional>
        </Stack>
      </ShortProductSummary>
    </StyledStockWrapperBox>
  );
};
