import { Button, Conditional, Icon } from 'gantri-components';
import { useToggle } from 'react-use';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { History } from '../../../../components/history';
import { GetVersionType } from '../product-jobs/components/product-jobs-header/product-jobs-header.types';
import { productJobsTabAtoms } from '../product-jobs/product-jobs.atoms';

export const ViewHistoryButton = () => {
  const setVersionId = useSetRecoilState(productJobsTabAtoms.versionId);

  const { id } = useParams<{ id: string }>();
  const productId = Number(id);

  const [showHistory, toggleShowHistory] = useToggle(false);

  const getVersion = async ({ id }: GetVersionType) => {
    setVersionId(id);
  };

  return (
    <>
      <Button
        icon={<Icon color="t2" name="time:clock" />}
        variant="tertiary"
        onClick={() => {
          toggleShowHistory();
        }}
      />
      <Conditional condition={showHistory}>
        <History
          getVersion={getVersion}
          open={showHistory}
          productId={productId}
          onClose={toggleShowHistory}
        />
      </Conditional>
    </>
  );
};
