import { Stack } from 'gantri-components';
import { Form, Formik } from 'formik';
import { BatchPartCreationHeader } from './components/batch-part-creation-header';
import { BatchPartCreationContent } from './components/batch-part-creation-content';
import { schema } from './batch-part-creation-schema';
import { batchPartCreationDefaults } from './batch-part-creation.atoms';
import { HandleBatchPartCreationLeave } from './components/handle-batch-part-creation-leave';

export const BatchPartCreation = () => {
  return (
    <Formik
      enableReinitialize
      initialValues={batchPartCreationDefaults}
      validationSchema={schema}
      onSubmit={() => {}}
    >
      {() => {
        return (
          <Form>
            <HandleBatchPartCreationLeave />
            <Stack gap="1.6rem" height="unset" paddingBottom="2rem">
              <BatchPartCreationHeader />
              <BatchPartCreationContent />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};
