import { Cell, Conditional, Grid, Stack, Typography } from 'gantri-components';
import { FieldArray } from 'formik';
import { PartJobBlocksInstructions } from '../part-job-blocks-instructions';
import { pluralize } from '../../../../../../../../../../helpers/pluralize';
import { PartJobBlocksPrintName } from './components/part-job-blocks-print-name';
import { PartJobBlocksPrintDuration } from './components/part-job-blocks-print-duration';
import { PartJobBlocksPrintWeight } from './components/part-job-blocks-print-weight';
import { PartJobBlocksPrintGcode } from './components/part-job-blocks-print-gcode';
import {
  jobsBlockGridColumns,
  detailsGridColumns,
} from '../../part-job-blocks.constants';
import { PartJobBlocksPrintBlocksProps } from './part-job-blocks-print-blocks.types';
import { Divider } from '../../../../../../../../../../components/divider';
import { useIsEditingPartRowJobsDataForm } from '../../../../hooks/use-is-editing-part-row';

export const PartJobBlocksPrintBlocks = (
  props: PartJobBlocksPrintBlocksProps,
) => {
  const { partName, printBlocks } = props;

  const isEditingPartRow = useIsEditingPartRowJobsDataForm();

  return (
    <FieldArray name="printBlock">
      {({ name: fieldArrayName, replace }) => {
        return printBlocks.map((printBlock, index) => {
          const {
            estimatedPrintDuration,
            gcode,
            instructions,
            instructionsJobMap,
            name,
            weight,
          } = printBlock;

          const numPrints = printBlocks.length;
          const hasMultiplePrintBlocks = numPrints > 1;
          const printNumber = index + 1;
          const disableGCode = (hasMultiplePrintBlocks && !name) || !weight;
          const printHeading = pluralize('Print', numPrints, {
            plural: `Print ${printNumber}`,
          });

          return (
            <Stack key={[fieldArrayName, printNumber].join('-')} gap="4x">
              <Conditional condition={index > 0}>
                <Divider />
              </Conditional>
              <Grid columns={jobsBlockGridColumns} gap="x">
                <Cell>
                  <Typography text={printHeading} variant="h5" />
                </Cell>

                <Grid columns={detailsGridColumns} gap="x">
                  <Conditional condition={hasMultiplePrintBlocks}>
                    <Typography text="Name" textStyle="bold" />
                    <PartJobBlocksPrintName
                      index={index}
                      partName={partName}
                      printBlock={printBlock}
                      printName={name}
                      replace={replace}
                    />
                  </Conditional>

                  <Typography text="Est. Print Duration" textStyle="bold" />
                  <PartJobBlocksPrintDuration
                    estimatedPrintDuration={estimatedPrintDuration}
                    index={index}
                    printBlock={printBlock}
                    replace={replace}
                  />

                  <Typography text="Weight" textStyle="bold" />
                  <PartJobBlocksPrintWeight
                    index={index}
                    partName={partName}
                    printBlock={printBlock}
                    replace={replace}
                    weight={weight}
                  />

                  <Typography text="G-code" textStyle="bold" />
                  <PartJobBlocksPrintGcode
                    disable={disableGCode}
                    gcode={gcode}
                    index={index}
                    numPrints={numPrints}
                    partName={partName}
                    printBlock={printBlock}
                    printName={name}
                    replace={replace}
                    schemaId={`printBlock[${index}].gcode`}
                    weight={weight}
                  />

                  <Typography text="Instructions" textStyle="bold" />
                  <PartJobBlocksInstructions
                    instructions={instructions}
                    instructionsJobMap={instructionsJobMap}
                    isEditing={isEditingPartRow}
                    schemaId={`printBlock[${index}].instructions`}
                  />
                </Grid>
              </Grid>
            </Stack>
          );
        });
      }}
    </FieldArray>
  );
};
