import { useFormikContext } from 'formik';
import { ChangeUserTypeModalFormDataDef } from '../../user-type-modal.types';
import { changeUserTypeModalSteps } from '../../user-type-modal.constants';
import { ChangeUserTypeModalConfirmFooter } from '../step-confirm/change-user-type-modal-confirm-footer';
import { ChangeUserTypeModalUpdateFooter } from '../step-update/change-user-type-modal-update-footer';
import { ChangeUserTypeModalFooterPropsDef } from './change-user-type-modal-footer.types';

export const ChangeUserTypeModalFooter = (
  props: ChangeUserTypeModalFooterPropsDef,
) => {
  const { initialValues, onClose } = props;

  const { values } = useFormikContext<ChangeUserTypeModalFormDataDef>();

  switch (values.step) {
    case changeUserTypeModalSteps.update:
      return (
        <ChangeUserTypeModalUpdateFooter
          initialValues={initialValues}
          onClose={onClose}
        />
      );

    case changeUserTypeModalSteps.confirm:
      return <ChangeUserTypeModalConfirmFooter />;

    default:
      return null;
  }
};
