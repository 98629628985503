import { useState } from 'react';
import { Typography, useModal, Table, Button, Cell } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useRecoilValue } from 'recoil';
import { UploadHandoutModal } from './components/upload-handout-modal';
import { Handout } from '../../../../../../api/products/products.types';
import { formatDate } from '../../../../../../helpers/formatter';
import { NameCell } from './components/name-cell';
import { MenuCell } from './components/menu-cell';
import { DeleteHandoutModal } from './components/delete-handout-modal';
import { getIsClickableIfValue } from '../../../../../../helpers/get-is-clickable-if-value';
import { productAtoms } from '../../../../product.atoms';
import { SectionTitle } from '../../../../../../components/layout/section-title';

export const InfoTabHandouts = () => {
  const product = useRecoilValue(productAtoms.product);

  const [handout, setHandout] = useState<Handout>();

  const handoutColumns: ColumnDef<Handout>[] = [
    {
      accessorKey: 'name',
      cell: NameCell,
      header: 'Name',
      meta: {
        getIsClickable: getIsClickableIfValue,
      },
    },
    {
      accessorKey: 'type',
      header: 'Type',
    },
    {
      accessorKey: 'universal',
      cell: ({ getValue }) => {
        return <Typography text={getValue() ? 'Yes' : 'No'} />;
      },
      header: 'Universal',
    },
    {
      accessorKey: 'updatedAt',
      cell: ({ getValue }) => {
        return formatDate(getValue());
      },
      header: 'Last Update',
    },
    {
      accessorKey: 'type',
      cell: (props: CellContext<Handout, Handout['type']>) => {
        return (
          <MenuCell
            {...props}
            setHandout={setHandout}
            showDeleteModal={showDeleteModal}
          />
        );
      },
      header: '',
      id: 'actions',
    },
  ];

  const [showUploadModal, hideUploadModal] = useModal(() => {
    return <UploadHandoutModal handout={handout} onClose={hideUploadModal} />;
  }, [handout]);

  const [showDeleteModal, hideDeleteModal] = useModal(() => {
    return <DeleteHandoutModal handout={handout} onClose={hideDeleteModal} />;
  }, [handout]);

  const handleAddHandout = async () => {
    setHandout(undefined);
    showUploadModal();
  };

  return (
    <Cell>
      <SectionTitle text="Handouts">
        <Button
          justifySelf="end"
          size="small"
          text="Add handout"
          variant="secondary"
          onClick={handleAddHandout}
        />
      </SectionTitle>

      <Table
        columns={handoutColumns}
        data={product.handouts || []}
        highlightHoveredRow
      />
    </Cell>
  );
};
