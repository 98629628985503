import { useModal } from 'gantri-components';
import {
  UseInventoryMigrationProps,
  UseInventoryMigration,
} from './inventory-migration.types';
import { InventoryMigrationProvider } from './inventory-migration-context';
import { InventoryMigrationModal } from './inventory-migration-modal';

export const useInventoryMigrationModal = (
  props?: UseInventoryMigrationProps,
): UseInventoryMigration => {
  const [showModal, hideModal] = useModal(() => {
    return (
      <InventoryMigrationProvider
        onClose={hideModal}
        onSuccess={props?.onSuccess}
      >
        <InventoryMigrationModal />
      </InventoryMigrationProvider>
    );
  }, []);

  return { hideModal, showModal };
};
