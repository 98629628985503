import {
  Cell,
  generateSku,
  getColorsByProduct,
  getSkuDetails,
  ProductColorCode,
  TypographyColor,
} from 'gantri-components';
import { ColorSwatchDropdown } from '../../../../../../components/common/color-swatch-dropdown';
import {
  SkuConfigColorDropdownPropsDef,
  ColorItemDef,
} from './sku-config-color-dropdown.types';

export const SkuConfigColorDropdown = (
  props: SkuConfigColorDropdownPropsDef,
) => {
  const { highlightMarketplaceColors, product, setSku, sku, ...dropdownProps } =
    props;

  const skuDetails = getSkuDetails({
    sku: sku || '',
    skuPartialCodes: product.skuPartialCodes,
  });

  const { color } = skuDetails;

  const getTextColorForProductColor = (colorCode: ProductColorCode) => {
    const isDesignerPreferredColor =
      highlightMarketplaceColors &&
      product.colors.some(({ code }) => {
        return code === colorCode;
      });

    const textColor: TypographyColor = isDesignerPreferredColor ? 'link' : 't1';

    return textColor;
  };

  const colorItems: ColorItemDef[] = getColorsByProduct({
    allowTradeColors: true,
    isPainted: product.isPainted,
    productId: product.id,
  }).map((colorItem) => {
    const textColor = getTextColorForProductColor(colorItem.code);

    return { ...colorItem, textColor };
  });

  const isDisabled = !colorItems.length;

  const hasVariants = !!product.selectors?.length;

  const onSelect = (item: ColorItemDef) => {
    const sku = generateSku({
      ...skuDetails,
      color: item?.code,
    });

    setSku(sku);
  };

  return (
    <Cell>
      <ColorSwatchDropdown
        disabled={isDisabled}
        idProperty="code"
        items={colorItems}
        labelPosition={hasVariants ? 'top' : 'left'}
        labelProperty="shortColorName"
        labelText="Color"
        value={color}
        onSelect={onSelect}
        {...dropdownProps}
      />
    </Cell>
  );
};
