import { Button } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { restartOrCancelPartModalAtoms } from '../../../restart-or-cancel-part-modal.atoms';
import { RestartReasonFooterProps } from './restart-reason-footer.types';

export const RestartReasonFooter = (props: RestartReasonFooterProps) => {
  const { handleCloseModal } = props;

  const restartReason = useRecoilValue(
    restartOrCancelPartModalAtoms.restartReason,
  );
  const notes = useRecoilValue(restartOrCancelPartModalAtoms.notes);
  const setStep = useSetRecoilState(restartOrCancelPartModalAtoms.step);

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        disabled={!restartReason || (restartReason === 'Failed' && !notes)}
        size="large"
        text="Next"
        variant="primaryAlert"
        onClick={() => {
          setStep('CONFIRM_RESTART');
        }}
      />
    </>
  );
};
