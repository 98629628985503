import styled, { css } from 'styled-components';
import { Flex } from 'gantri-components';
import { transitions } from '../../constants/styles';

const SUBPATH_PADDING = '4rem';

export const StyledStyledSidebarLinkSpacer = styled.div`
  padding: 1.5rem;
`;

export const StyledSidebarLink = styled(Flex)<{
  active?: boolean;
  noHover?: boolean;
  subpath?: boolean;
}>`

  ${transitions.base}
  transition-property: padding, height;
  user-select: none;

  ${({ subpath }) => {
    return (
      subpath &&
      css`
        padding-left: ${SUBPATH_PADDING};
      `
    );
  }}
}

;

${({ noHover }) => {
  return (
    !noHover &&
    css`
      &:hover {
        cursor: pointer;
        background-color: var(--colors-hover-color);
      }
    `
  );
}}
`;

export const StyledSidebarIconWrapper = styled.div<{
  collapsed?: boolean;
  pointer?: boolean;
}>`
  margin-right: ${({ collapsed }) => {
    return !collapsed && '1rem';
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ collapsed }) => {
    return !collapsed && '1.6rem';
  }};
  height: 1.6rem;
  ${({ pointer }) => {
    return pointer && 'cursor: pointer;';
  }}
`;

export const StyledLogoutLink = styled.div`
  margin-left: 1rem;
  cursor: pointer;
`;

export const StyledFadeWrapper = styled.div<{ isSidebarOpen: boolean }>`
  display: flex;
  align-items: center;
  ${transitions.base}
  opacity: ${({ isSidebarOpen }) => {
    return isSidebarOpen ? '1' : '0';
  }};
`;
