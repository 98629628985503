import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { updatePartReleaseRate } from './update-part-release-rate';
import {
  UpdatePartReleaseRateResponseDef,
  UpdatePartReleaseRateArgsDef,
} from './update-part-release-rate.types';

export const useUpdatePartReleaseRate = <
  TransformedData = UpdatePartReleaseRateResponseDef,
>(
  props?: GenericMutateQueryProps<
    UpdatePartReleaseRateArgsDef,
    UpdatePartReleaseRateResponseDef,
    TransformedData
  >,
) => {
  const { onMutate: onUpdatePartReleaseRate, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to update part release rate.',
    mutationFn: updatePartReleaseRate,
    ...props,
  });

  return { ...rest, onUpdatePartReleaseRate };
};
