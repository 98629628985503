import React, { FC } from 'react';
import { Cell } from 'gantri-components';
import { StockInventoriesProps } from './stock-inventories.types';
import { StockInventoriesTable } from './stock-inventories-table/stock-inventories-table';
import { SectionTitle } from '../../../../components/layout/section-title';

export const StockInventories: FC<StockInventoriesProps> = (props) => {
  return (
    <Cell>
      <SectionTitle text="Inventories" />
      <StockInventoriesTable {...props} />
    </Cell>
  );
};
