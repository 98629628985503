import { memo } from 'react';
import {
  Button,
  Conditional,
  Flex,
  Icon,
  Tooltip,
  Typography,
  useModal,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { Divider } from '../../../../../../../../components/divider';
import { getTemplateEditDisabledReason } from '../../../../../../../../components/common/jobs-template-table/helpers/get-template-edit-disabled-reason';
import { AddOrEditPartModal } from '../add-or-edit-part-modal';
import { productJobsTabAtoms } from '../../../../product-jobs.atoms';
import { addOrEditPartModalType } from '../add-or-edit-part-modal/add-or-edit-part-modal.constants';
import { productAtoms } from '../../../../../../product.atoms';
import { PartsSectionHeaderProps } from './parts-section-header.types';
import { useGetVersionDetails } from '../../../../hooks/use-get-version-details';

export const PartsSectionHeader = memo((props: PartsSectionHeaderProps) => {
  const { parts } = props;

  const isEditingJobs = useRecoilValue(productJobsTabAtoms.isEditingJobs);
  const product = useRecoilValue(productAtoms.product);
  const selectedSku = useRecoilValue(productAtoms.selectedSku);

  const numParts = parts.length;

  const { isDraft, isMinorVersion } = useGetVersionDetails();

  const [showNewPartModal, hideNewPartModal] = useModal(() => {
    return (
      <AddOrEditPartModal
        modalType={addOrEditPartModalType.add}
        onClose={hideNewPartModal}
      />
    );
  }, [selectedSku, product.id]);
  const disableAddPart = isMinorVersion || isEditingJobs;

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        verticalPadding="x"
      >
        <Typography text={`Parts (${numParts})`} variant="h4" />

        <Conditional condition={isDraft}>
          <Tooltip
            description={getTemplateEditDisabledReason({
              isEditing: isEditingJobs,
              isMinorVersion,
            })}
            position="left"
          >
            <Button
              disabled={disableAddPart}
              icon={
                <Icon
                  color={disableAddPart ? 't3' : 'link'}
                  name="ui-control:plus_circle"
                />
              }
              text="Add Part"
              variant="secondary"
              onClick={showNewPartModal}
            />
          </Tooltip>
        </Conditional>
      </Flex>
      <Divider />
    </>
  );
});
