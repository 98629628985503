import { FieldArray } from 'formik';
import { Cell, Conditional, Grid, Stack } from 'gantri-components';
import { Fragment, useMemo } from 'react';
import { PartJobBlocksInstructionsProps } from './part-job-blocks-instructions.types';
import { AllInstructionsNonEditView } from './components/all-instructions-non-edit-view';
import { Divider } from '../../../../../../../../../../components/divider';
import { AddInstructionsButton } from './components/add-instructions-button';
import { InstructionDetails } from './components/instruction-details';
import { InstructionJobSelect } from './components/instruction-job-select';
import { mapInstructionsToLabels } from '../../part-job-blocks.schema';

export const PartJobBlocksInstructions = (
  props: PartJobBlocksInstructionsProps,
) => {
  const { boldLabels, instructions, instructionsJobMap, isEditing, schemaId } =
    props;

  const mappedInstructions = useMemo(() => {
    return mapInstructionsToLabels({
      instructions,
      instructionsJobMap,
    });
  }, [instructions, instructionsJobMap]);

  return (
    <Stack
      verticalPadding={!isEditing && !mappedInstructions.length ? '0' : 'x'}
    >
      <Conditional
        condition={isEditing}
        Fallback={
          <AllInstructionsNonEditView
            boldLabels={boldLabels}
            instructions={mappedInstructions}
            instructionsJobMap={instructionsJobMap}
          />
        }
      >
        <FieldArray name={schemaId}>
          {({ name: key, push, remove, replace }) => {
            return (
              <>
                {mappedInstructions.map((instruction, index) => {
                  return (
                    <Fragment key={key}>
                      <Grid columns="1fr max-content" gap="x">
                        <InstructionJobSelect
                          index={index}
                          instruction={instruction}
                          instructions={mappedInstructions}
                          instructionsJobMap={instructionsJobMap}
                          remove={remove}
                          replace={replace}
                          schemaId={schemaId}
                        />

                        <Cell width={2}>
                          <Stack gap="x">
                            <InstructionDetails
                              index={index}
                              instruction={instruction}
                              schemaId={schemaId}
                            />
                          </Stack>
                        </Cell>
                      </Grid>

                      <Divider />
                    </Fragment>
                  );
                })}

                <AddInstructionsButton
                  instructions={mappedInstructions}
                  instructionsJobMap={instructionsJobMap}
                  push={push}
                />
              </>
            );
          }}
        </FieldArray>
      </Conditional>
    </Stack>
  );
};
