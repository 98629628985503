import {
  Box,
  Cell,
  Conditional,
  Grid,
  Icon,
  Stack,
  Typography,
} from 'gantri-components';
import { Label } from '../../../../../../../../../../components/label';
import { Divider } from '../../../../../../../../../../components/divider';
import { AssembleInventoryVariantInventorySectionProps } from './assemble-inventory-variant-inventory-section.types';
import { GlobalVariantOptionItem } from './components/global-variant-option-item';
import { useInventoriesByVariants } from './hooks/use-inventories-by-variants';

export const AssembleInventoryVariantInventorySection = (
  props: AssembleInventoryVariantInventorySectionProps,
) => {
  const { isEditing } = props;

  const { globalVariants, inventoriesByVariants, isLoading } =
    useInventoriesByVariants();

  const globalVariantIds = Object.keys(inventoriesByVariants);

  return (
    <Stack gap="x">
      <Label text="Variant Inventory" />

      <Stack gap="2x">
        <Conditional
          condition={isLoading}
          Fallback={
            <Conditional
              condition={!!globalVariantIds.length}
              Fallback={<Typography color="t2" text="None" />}
            >
              {globalVariantIds.map((id) => {
                const globalVariantId = Number(id);
                const matchingGlobalVariant = globalVariants.find(({ id }) => {
                  return id === globalVariantId;
                });

                return matchingGlobalVariant ? (
                  <Grid
                    key={globalVariantId}
                    columnGap="x"
                    columns="11.2rem 1fr"
                    rowGap={isEditing ? '.5x' : 'x'}
                  >
                    <Cell width={2}>
                      <Typography text={matchingGlobalVariant?.name} />
                    </Cell>
                    <Cell width={2}>
                      <Divider />
                    </Cell>
                    {matchingGlobalVariant.options.map(
                      (globalVariantOptionItem) => {
                        const { code } = globalVariantOptionItem;
                        const stockJobVariantInventories =
                          inventoriesByVariants[globalVariantId][code];

                        return (
                          <GlobalVariantOptionItem
                            key={code}
                            globalVariantId={globalVariantId}
                            globalVariantOptionItem={globalVariantOptionItem}
                            isEditing={isEditing}
                            stockJobVariantInventories={
                              stockJobVariantInventories
                            }
                          />
                        );
                      },
                    )}
                  </Grid>
                ) : null;
              })}
            </Conditional>
          }
        >
          <Box verticalPadding="x">
            <Icon color="link" name="animated:loader" size="3.2rem" />
          </Box>
        </Conditional>
      </Stack>
    </Stack>
  );
};
