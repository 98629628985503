import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { updateProductLifestylePhotos } from './update-product-lifestyle-photos';
import {
  UpdateProductLifestylePhotosArgs,
  UpdateProductLifestylePhotosResponse,
} from './update-product-lifestyle-photos.types';

export const useUpdateProductLifestylePhotos = <
  TransformedData = UpdateProductLifestylePhotosResponse,
>(
  props?: GenericMutateQueryProps<
    UpdateProductLifestylePhotosArgs,
    UpdateProductLifestylePhotosResponse,
    TransformedData
  >,
) => {
  const { onMutate: onUpdateLifestylePhotos, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to update lifestyle photos.',
    mutationFn: updateProductLifestylePhotos,
    ...props,
  });

  return { ...rest, onUpdateLifestylePhotos };
};
