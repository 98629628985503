import * as yup from 'yup';
import { SchemaOf } from 'yup';
import {
  productCategoryOptions,
  productSubCategoryOptions,
} from '../../../product/components/info/info.constants';
import { ProductSubCategory } from '../../../../api/products/products.types';
import { NewProductModalFormData } from './new-product-modal.type';
import { newProductTypes } from './new-product-modal.constants';

const requiredText = 'Required';

export const newProductModalSchema: SchemaOf<NewProductModalFormData> = yup
  .object()
  .shape({
    category: yup.mixed().oneOf(productCategoryOptions).required(requiredText),
    name: yup.string().required(requiredText),
    subCategory: yup
      .mixed()
      .oneOf(productSubCategoryOptions)
      .required(requiredText),
    type: yup
      .mixed()
      .oneOf(Object.values(newProductTypes))
      .required(requiredText),
  });

export const newProductModalInitialValues: NewProductModalFormData = {
  category: 'Accessory',
  name: '',
  subCategory: '' as ProductSubCategory,
  type: 'Accessory',
};
