import { Link } from 'react-router-dom';
import { Button, Stack, Typography } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { formatDate } from '../../helpers/formatter';
import { MEDIUM_FORMAT } from '../../constants/dates';
import {
  calculateTotal,
  canCompleteRefund,
  getShipmentInfo,
  isAbleToShip,
} from './order.adapters';
import { TableSortField } from '../../components/common/table/table.props';
import { OrderRecord } from './orders.types';
import { OrdersSortingField } from '../../api/transactions/routes/fetch-paginated-transactions/fetch-paginated-transactions.types';
import { StyledAnchor } from '../../components/common/styled-anchor';
import routePaths from '../../config/route-paths';
import { getIsClickableIfValue } from '../../helpers/get-is-clickable-if-value';

export const columns: ColumnDef<OrderRecord>[] = [
  {
    accessorKey: 'id',
    cell: ({ getValue }: CellContext<OrderRecord, OrderRecord['id']>) => {
      const orderId = getValue();

      return (
        <StyledAnchor
          href={`${routePaths.orders}/${orderId}`}
          text={`#${orderId}`}
        />
      );
    },
    header: 'Order #',
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
    size: 100,
  },
  {
    accessorKey: 'type',
    header: 'Type',
    size: 100,
  },
  {
    accessorKey: 'createdAtSec',
    cell: ({
      getValue,
    }: CellContext<OrderRecord, OrderRecord['createdAtSec']>) => {
      return formatDate(getValue(), MEDIUM_FORMAT);
    },
    header: 'Created',
    size: 100,
  },
  {
    accessorKey: 'completedAtSec',
    cell: ({
      getValue,
    }: CellContext<OrderRecord, OrderRecord['completedAtSec']>) => {
      return getValue() ? formatDate(getValue()) : 'TBD';
    },
    header: 'Completed',
    size: 100,
  },
  {
    accessorKey: 'user',
    cell: ({ getValue }: CellContext<OrderRecord, OrderRecord['user']>) => {
      const { email, firstName, id, lastName } = getValue();
      const href = `${routePaths.users}/${id}`;

      return (
        <StyledAnchor
          href={href}
          target="_blank"
          text={firstName && lastName ? `${firstName} ${lastName}` : email}
        />
      );
    },
    header: 'User',
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
  },
  {
    cell: ({ row: { original } }: CellContext<OrderRecord, unknown>) => {
      return calculateTotal(original);
    },
    header: 'Total',
  },
  {
    accessorKey: 'status',
    header: 'Order Status',
  },
  {
    accessorKey: 'shipments',
    cell: ({
      getValue,
    }: CellContext<OrderRecord, OrderRecord['shipments']>) => {
      const shipments = getValue();

      return (
        <Stack gap="x">
          {shipments.map((shipment) => {
            return (
              <Typography
                key={shipment.id}
                text={formatDate(shipment.shipsAtSec, MEDIUM_FORMAT)}
              />
            );
          })}
        </Stack>
      );
    },
    header: 'Ships By',
    id: 'shipsBy',
    size: 80,
  },
  {
    accessorKey: 'shipments',
    cell: ({
      getValue,
    }: CellContext<OrderRecord, OrderRecord['shipments']>) => {
      const shipments = getValue();

      return (
        <Stack gap="x">
          {shipments.map((shipment) => {
            return (
              <Typography
                key={shipment.id}
                text={shipment.status}
                whiteSpace="nowrap"
              />
            );
          })}
        </Stack>
      );
    },
    header: 'Shipments',
    id: 'shipments',
    size: 100,
  },
  {
    accessorKey: 'shipments',
    cell: ({
      getValue,
    }: CellContext<OrderRecord, OrderRecord['shipments']>) => {
      const shipments = getValue();

      return (
        <Stack gap="x">
          {shipments.map((shipment) => {
            const text = getShipmentInfo(shipment);

            return <Typography key={shipment.id} color="t1" text={text} />;
          })}
        </Stack>
      );
    },
    header: 'Items',
    id: 'items',
  },
  {
    cell: ({ row: { original } }: CellContext<OrderRecord, unknown>) => {
      let actionText = '';

      if (isAbleToShip(original)) {
        actionText = 'Ship';
      }

      if (canCompleteRefund(original)) {
        actionText = 'Complete Refund';
      }

      return (
        !!actionText && (
          <Link
            style={{ float: 'left', textDecoration: 'none' }}
            to={`${routePaths.orders}/${original.id}`}
          >
            <Button text={actionText} variant="secondary" />
          </Link>
        )
      );
    },
    header: 'Action',
  },
];

export const sortOptions: TableSortField<OrdersSortingField>[] = [
  {
    bidirectional: true,
    label: 'Create Date',
    sortingField: 'createdAt',
  },
  {
    bidirectional: true,
    label: 'Complete Date',
    sortingField: 'completedAt',
  },
  {
    bidirectional: true,
    /** This option was previously set behind the scenes (using ascending logic) when enabling the "Status: Production Filter" option. It now appears as a separate item in the Sort by dropdown and must be manually set. */
    label: 'Ships By',
    sortingField: 'shipsAt',
  },
];
