import { Stack } from 'gantri-components';
import { Label } from '../../../../../../components/label';
import { ChangeItemDef } from '../../../../../../api/transactions/routes/change-items/change-items.types';
import { EditableStockItem } from './components/editable-stock-item';
import { EditableShipmentsPropsDef } from './editable-shipments.types';

export const EditableShipments = (props: EditableShipmentsPropsDef) => {
  const {
    colorChangedStocks,
    editableShipments,
    editableStocks,
    setColorChangedStocks,
    totalShipments,
  } = props;

  const getHandleUpdateSku = (props: { stockInfoId: number }) => {
    const { stockInfoId } = props;

    return (updatedStock: ChangeItemDef) => {
      setColorChangedStocks((prevColorChangedStocks) => {
        const isAlreadyUpdated = prevColorChangedStocks.some((prevStock) => {
          return prevStock.stockAssociationId === stockInfoId;
        });

        if (isAlreadyUpdated) {
          const isSameAsOriginal = editableStocks.some(
            ({ sku, stockInfoId }) => {
              return (
                stockInfoId === updatedStock.stockAssociationId &&
                sku === updatedStock.sku
              );
            },
          );

          if (isSameAsOriginal) {
            return prevColorChangedStocks.filter((prevStock) => {
              return (
                prevStock.stockAssociationId !== updatedStock.stockAssociationId
              );
            });
          }

          return prevColorChangedStocks.map((prevStock) => {
            const isUpdatedStock = prevStock.stockAssociationId === stockInfoId;

            if (isUpdatedStock) {
              return updatedStock;
            }

            return prevStock;
          });
        }

        return [...prevColorChangedStocks, updatedStock];
      });
    };
  };

  return (
    <Stack gap="3x">
      {editableShipments.map(
        ({ editableStocks, shipmentId, shipmentNumber }) => {
          return (
            <Stack key={shipmentId} gap="x">
              <Label text={`Shipment ${shipmentNumber}/${totalShipments}`} />

              <Stack gap="x">
                {editableStocks.map(
                  ({ productId, sku: originalSku, stockInfoId }) => {
                    const selectedSku =
                      colorChangedStocks.find((stock) => {
                        return stock.stockAssociationId === stockInfoId;
                      })?.sku || originalSku;

                    const handleUpdateSku = getHandleUpdateSku({ stockInfoId });

                    return (
                      <EditableStockItem
                        key={stockInfoId}
                        handleUpdateSku={handleUpdateSku}
                        productId={productId}
                        sku={selectedSku}
                        stockInfoId={stockInfoId}
                      />
                    );
                  },
                )}
              </Stack>
            </Stack>
          );
        },
      )}
    </Stack>
  );
};
