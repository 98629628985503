import { useEffect, useMemo, useState } from 'react';
import { Icon, Table, Typography } from 'gantri-components';
import { CellContext } from '@tanstack/react-table';
import { formatAsCurrency } from '../../helpers/formatter';
import routePaths from '../../config/route-paths';
import { PageWithTable } from '../../components/layout';
import { PayoutFilters } from '../../components/dropdowns';
import {
  FetchPaginatedPayoutsArgs,
  PaginatedPayout,
  PayoutsSortingField,
} from '../../api/transactions/routes/fetch-paginated-payouts/fetch-paginated-payouts.types';
import { useTableFilters } from '../../components/common/table/hooks/use-table-filters';
import {
  pageName,
  payoutsFiltersDefaults,
  payoutsPageAtoms,
} from './payouts.atoms';
import { StyledAnchor } from '../../components/common/styled-anchor';
import { getIsClickableIfValue } from '../../helpers/get-is-clickable-if-value';
import { useGetPaginatedPayouts } from '../../api/transactions/routes/fetch-paginated-payouts';

export const Payouts = () => {
  const [filters, updateFilters] = useState<FetchPaginatedPayoutsArgs>();

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'id',
        cell: ({
          getValue,
        }: CellContext<PaginatedPayout, PaginatedPayout['id']>) => {
          const id = getValue();

          return (
            <StyledAnchor
              href={`${routePaths.payouts}/${id}`}
              text={`#${id}`}
            />
          );
        },
        header: 'Payout #',
        maxSize: 100,
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'period',
        header: 'Period',
      },
      {
        accessorKey: 'designer',
        cell: ({
          getValue,
        }: CellContext<PaginatedPayout, PaginatedPayout['designer']>) => {
          const designer = getValue();

          return (
            <StyledAnchor
              text={designer.name}
              to={`${routePaths.designers}/${designer.id}`}
            />
          );
        },
        header: 'Designer',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'method',
        header: 'Payment Method',
      },
      {
        accessorKey: 'amount',
        cell: ({
          getValue,
        }: CellContext<PaginatedPayout, PaginatedPayout['amount']>) => {
          const amount = getValue();

          return (
            <Typography
              align="right"
              text={formatAsCurrency(Number(amount), { isCents: true })}
            />
          );
        },
        header: () => {
          return <Typography align="right" text="Amount" />;
        },
        maxSize: 60,
      },
      {
        accessorKey: 'status',
        cell: ({
          getValue,
        }: CellContext<PaginatedPayout, PaginatedPayout['status']>) => {
          const status = getValue();

          return (
            <Typography
              icon={
                status === 'Paid' ? (
                  <Icon
                    color="success"
                    name="ui-control:check_mark"
                    size="2rem"
                  />
                ) : undefined
              }
              text={status}
            />
          );
        },
        header: 'Status',
        maxSize: 60,
      },
    ];
  }, []);

  const { data, isLoading } = useGetPaginatedPayouts({
    enabled: !!filters,
    fetchArgs: filters,
  });

  const {
    filtersProps,
    pagingProps,
    records,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    PaginatedPayout,
    FetchPaginatedPayoutsArgs,
    PayoutsSortingField
  >({
    fetchRequest: updateFilters,
    filtersContent: <PayoutFilters />,
    modalFiltersDetails: [
      {
        atom: payoutsPageAtoms.filters.dateRange,
        defaultValue: payoutsFiltersDefaults.filters.dateRange,
        payloadKey: 'dateRange',
      },
      {
        atom: payoutsPageAtoms.filters.statuses,
        defaultValue: payoutsFiltersDefaults.filters.statuses,
        payloadKey: 'statuses',
      },
    ],
    pageName,
    pageSize: 80,
    sortByFilterDetails: {
      atom: payoutsPageAtoms.sortBy,
      defaultValue: payoutsFiltersDefaults.sortBy,
    },
    sortOptions: [
      { bidirectional: true, label: 'Status', sortingField: 'status' },
    ],
  });

  useEffect(() => {
    if (!isLoading) {
      setRecords(data?.data || []);
      setTotalCount(data?.pagination?.totalItems || 0);
    }
  }, [isLoading, data]);

  return (
    <PageWithTable pageTitle="Payouts">
      <PageWithTable.Header title="Payouts" />
      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          highlightHoveredRow
          paging={pagingProps}
          sorting={sortProps}
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
