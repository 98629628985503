import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import {
  FetchSkuAssetsArgs,
  FetchSkuAssetsResponse,
} from './fetch-sku-assets.types';
import { fetchSkuAssets } from './fetch-sku-assets';

const fetchBulkProductAssetsQueriesObj = getGenericQueryObj({
  groupName: 'fetch-bulk-product-assets',
  query: fetchSkuAssets,
});

export const useFetchSkuAssets = <TransformedData = FetchSkuAssetsResponse>(
  props?: GenericFetchProps<
    FetchSkuAssetsArgs,
    FetchSkuAssetsResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch sku assets.',
    queryObj: fetchBulkProductAssetsQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchSkuAssetsCache = () => {
  const invalidateFetchBulkProductAssetsCache = useGetInvalidateQueryCache(
    fetchBulkProductAssetsQueriesObj,
  );

  return { invalidateFetchBulkProductAssetsCache };
};
