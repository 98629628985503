import { memo, ChangeEvent, useRef } from 'react';
import { ChromePicker } from 'react-color';
import { useClickAway, useToggle } from 'react-use';
import {
  Grid,
  Cell,
  Typography,
  TextField,
  Toggle,
  FileUploader,
  HandleDeleteFile,
  HandleUploadsComplete,
} from 'gantri-components';
import { StyledColorPopup } from '../../../designer/designer-styles';
import {
  StyledSectionContainer,
  StyledColorInputContainer,
} from '../../designer-feature.styles';
import {
  ImageType,
  InputType,
  ToggleType,
  PageHeaderProps,
} from './page-header.types';
import {
  fileRecoveredMessage,
  useCloudinaryFileUploader,
} from '../../../../hooks/use-cloudinary-file-uploader';
import { useNotification } from '../../../../hooks/useNotification';

export const Section = (props: PageHeaderProps) => {
  const {
    desktopImage,
    id,
    mobileImage,
    showLogo,
    showName,
    subtitle,
    textColor,
    title,
    updateFeatureInfo,
    userId,
  } = props;

  const { notify } = useNotification();

  const getHandleFileSelected = (property: ImageType) => {
    const handleUploadsComplete: HandleUploadsComplete = async ([
      { fileName },
    ]) => {
      updateFeatureInfo({
        key: property,
        type: 'key',
        value: fileName,
      });
    };

    return handleUploadsComplete;
  };

  const getOnFileRecovered = (property: ImageType) => {
    return async (updatedFileName: string) => {
      notify(fileRecoveredMessage.saveRequired, {
        keepOpen: true,
      });

      updateFeatureInfo({
        key: property,
        type: 'key',
        value: updatedFileName,
      });
    };
  };

  const getHandleFileDelete = (property: ImageType) => {
    const onFileDelete: HandleDeleteFile = async () => {
      updateFeatureInfo({
        key: property,
        type: 'key',
        value: '',
      });
    };

    return onFileDelete;
  };

  const { fileUploaderProps: desktopFileUploaderProps } =
    useCloudinaryFileUploader<'designers'>({
      directory: 'designers',
      fileName: desktopImage,
      fileType: 'collection-pages',
      handleUploadsComplete: getHandleFileSelected('desktopImage'),
      identifiers: {
        collectionId: id,
        userId,
      },
      onFileDelete: getHandleFileDelete('desktopImage'),
      onFileRecovered: getOnFileRecovered('desktopImage'),
      transformFileName: ({ applyUuid, removeWhitespace }) => {
        return applyUuid(removeWhitespace('page-header-desktop'));
      },
    });

  const { fileUploaderProps: mobileFileUploaderProps } =
    useCloudinaryFileUploader<'designers'>({
      directory: 'designers',
      fileName: mobileImage,
      fileType: 'collection-pages',
      handleUploadsComplete: getHandleFileSelected('mobileImage'),
      identifiers: {
        collectionId: id,
        userId,
      },
      onFileDelete: getHandleFileDelete('mobileImage'),
      onFileRecovered: getOnFileRecovered('mobileImage'),
      transformFileName: ({ applyUuid, removeWhitespace }) => {
        return applyUuid(removeWhitespace('page-header-mobile'));
      },
    });

  const onInputChange = (property: InputType) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      updateFeatureInfo({
        key: property,
        type: 'key',
        value: event.target.value,
      });
    };
  };

  const onToggle = (property: ToggleType) => {
    return (value: boolean) => {
      updateFeatureInfo({
        key: property,
        type: 'key',
        value,
      });
    };
  };

  const [textColorVisible, toggleTextColorVisiblePicker] = useToggle(false);

  const onColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateFeatureInfo({
      key: 'textColor',
      type: 'key',
      value: event.target.value,
    });
  };

  const textColorRef = useRef();

  useClickAway(textColorRef, () => {
    toggleTextColorVisiblePicker();
  });

  const onColorPickerChange = (color: string) => {
    updateFeatureInfo({
      key: 'textColor',
      type: 'key',
      value: color,
    });
  };

  return (
    <StyledSectionContainer>
      <Typography
        marginBottom="1.2rem"
        text="Page header"
        textStyle="bold"
        variant="h4"
      />
      <Grid
        columns={{ lg: 'max-content 1fr', sm: '1fr' }}
        gap="1.8rem"
        marginBottom="1.8rem"
      >
        <Cell>
          <Typography
            marginBottom="5px"
            text="Desktop Image"
            textStyle="bold"
          />
          <FileUploader {...desktopFileUploaderProps} variant="thumbnail" />
        </Cell>
        <Cell>
          <Typography marginBottom="5px" text="Mobile Image" textStyle="bold" />
          <FileUploader {...mobileFileUploaderProps} variant="thumbnail" />
        </Cell>
      </Grid>
      <Grid columns={{ lg: 4, sm: 1 }}>
        <Cell>
          <Typography text="Show collection name?" textStyle="bold" />
          <Toggle value={showName} onSelected={onToggle('showName')} />
        </Cell>
        <Cell>
          <Typography text="Show logo?" textStyle="bold" />
          <Toggle value={showLogo} onSelected={onToggle('showLogo')} />
        </Cell>
        <Cell>
          <TextField
            debounce={500}
            labelPosition="top"
            labelText="Header title"
            placeholder="Title..."
            value={title}
            onChange={onInputChange('title')}
          />
        </Cell>
        <Cell>
          <TextField
            debounce={500}
            labelPosition="top"
            labelText="Header subtitle"
            placeholder="Subtitle..."
            value={subtitle}
            onChange={onInputChange('subtitle')}
          />
        </Cell>
      </Grid>
      <Grid columns={{ lg: 1 }}>
        <Cell>
          <StyledColorInputContainer color={textColor}>
            <TextField
              labelPosition="top"
              labelText="Text color"
              placeholder="#FAFAFA"
              value={textColor}
              onChange={onColorChange}
              onClick={toggleTextColorVisiblePicker}
            />
            {textColorVisible && (
              <StyledColorPopup ref={textColorRef}>
                <ChromePicker
                  color={textColor}
                  disableAlpha
                  onChangeComplete={({ hex }) => {
                    return onColorPickerChange(hex);
                  }}
                />
              </StyledColorPopup>
            )}
          </StyledColorInputContainer>
        </Cell>
      </Grid>
    </StyledSectionContainer>
  );
};

export const PageHeader = memo(Section);
