import { Button } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { ConfirmBeginFooterProps } from './confirm-begin-footer.types';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { completeSandJobModalAtoms } from '../../../complete-sand-job-modal.atoms';
import { useStartJob } from '../../../../../../../../../api/jobs/routes';

export const ConfirmBeginFooter = (props: ConfirmBeginFooterProps) => {
  const { handleCloseModal, job } = props;

  const setStep = useSetRecoilState(completeSandJobModalAtoms.step);
  const setUpdateOnClose = useSetRecoilState(
    completeSandJobModalAtoms.updateOnClose,
  );

  const { isLoading, onStartJob } = useStartJob({
    onSuccess: async () => {
      setUpdateOnClose(true);
      setStep('COMPLETE_CHECKLIST');
    },
  });

  const handleConfirmBegin = async () => {
    await onStartJob({ jobId: job.id });
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        processing={isLoading}
        size={fullScreenModalFooterButtonSize}
        text="Yes"
        onClick={handleConfirmBegin}
      />
    </>
  );
};
