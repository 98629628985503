import { useRecoilValue } from 'recoil';
import { shipmentActionsModalSteps } from '../../shipment-actions-modal.constants';
import { shipmentActionsModalAtoms } from '../../shipment-actions-modal.atoms';

export const useGetShipmentActionsModalHeader = () => {
  const step = useRecoilValue(shipmentActionsModalAtoms.step);
  const isGift = useRecoilValue(shipmentActionsModalAtoms.shipNormally.isGift);
  const isDoNotShip = useRecoilValue(
    shipmentActionsModalAtoms.shipNormally.isDoNotShip,
  );

  switch (step) {
    case shipmentActionsModalSteps.cancelShipment:
      return 'Cancel Shipment';

    case shipmentActionsModalSteps.shipManually:
      return 'Ship Manually';

    case shipmentActionsModalSteps.shipNormally: {
      if (isGift) {
        return 'Ship gift order';
      }

      if (isDoNotShip) {
        return 'Confirm shipment delivery';
      }

      return 'Ship order';
    }

    case shipmentActionsModalSteps.shippingError:
      return 'Shipping Error';

    default:
      return undefined;
  }
};
