import {
  Cell,
  Flex,
  Icon,
  Stack,
  TextArea,
  Typography,
} from 'gantri-components';
import { useRecoilState } from 'recoil';
import { debounce } from 'lodash';
import { restartOrCancelPartModalAtoms } from '../../../restart-or-cancel-part-modal.atoms';
import { partStatuses } from '../../../../../../../../../constants/options';
import { PartStatusBadge } from '../../../../../../../../../components/common/part-status-badge';
import { List } from '../../../../../../../../../components/common/list';

export const ConfirmCancelContent = () => {
  const [notes, setNotes] = useRecoilState(restartOrCancelPartModalAtoms.notes);

  return (
    <Stack gap="0.8rem" paddingTop="1.6rem">
      <Icon color="alert" name="alert:warning_triangle_24" size="2.4rem" />
      <Typography text="Confirm Cancel Part" variant="h4" />
      <Typography htmlText="Please confirm the information below to cancel this part. <strong>This action cannot be reversed.</strong>" />
      <Cell paddingBottom="0.8rem" paddingTop="1.2rem">
        <List>
          <li>
            <Flex gap="0.4rem">
              <Typography text="Part status will be updated to" />
              <PartStatusBadge status={partStatuses.cancelled} />
            </Flex>
          </li>
          <li>
            <Typography text="All waiting and ready jobs for the part will be cancelled." />
          </li>
        </List>
      </Cell>
      <Typography
        text="Add additional information about your selection:"
        textStyle="bold"
      />
      <TextArea
        placeholder="Enter details about the cancelled part"
        required
        value={notes}
        onTextChange={debounce(setNotes, 300)}
      />
    </Stack>
  );
};
