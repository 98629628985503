import { Flex, Typography } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import ThumbnailLink from '../../../../../../../../components/common/thumbnail-link';
import routePaths from '../../../../../../../../config/route-paths';
import { formatDate } from '../../../../../../../../helpers/formatter';
import { StyledAnchor } from '../../../../../../../../components/common/styled-anchor';
import { getIsClickableIfValue } from '../../../../../../../../helpers/get-is-clickable-if-value';
import { ProductDesign } from '../../../../../../../../api/products/products.types';
import { placeholderImgSrc } from '../../../../../../../search-part/components/search-part-summary/components/search-part-summary-thumbnail/search-part-summary-thumbnail.constants';

export const useDesignsColumns = () => {
  const columns: ColumnDef<ProductDesign>[] = useMemo(() => {
    return [
      {
        accessorKey: 'id',
        cell: ({
          getValue,
        }: CellContext<ProductDesign, ProductDesign['id']>) => {
          const id = getValue();

          return (
            <StyledAnchor text={`#${id}`} to={`${routePaths.designs}/${id}`} />
          );
        },
        header: 'ID',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
        size: 80,
      },
      {
        accessorKey: 'name',
        cell: ({
          getValue,
          row,
        }: CellContext<ProductDesign, ProductDesign['name']>) => {
          return (
            <Flex alignItems="center">
              <ThumbnailLink
                imageUrl={row.original.thumbnail || placeholderImgSrc}
              />
              <Typography text={getValue()} />
            </Flex>
          );
        },
        header: 'Name',
      },
      {
        accessorKey: 'createdAtSec',
        cell: ({
          getValue,
        }: CellContext<ProductDesign, ProductDesign['createdAtSec']>) => {
          return <Typography text={formatDate(getValue())} />;
        },
        header: 'Created',
        size: 200,
      },
      {
        accessorKey: 'approvalDateSec',
        cell: ({
          getValue,
        }: CellContext<ProductDesign, ProductDesign['approvalDateSec']>) => {
          return <Typography text={formatDate(getValue())} />;
        },
        header: 'Completed',
        size: 200,
      },
    ];
  }, []);

  return columns;
};
