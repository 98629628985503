import styled, { css } from 'styled-components';

// prettier-ignore
export const StyledAccountSettingsWrapper = styled.div<{
  showName: boolean;
}>`
  user-select: none;

  ${({ showName }) => {
return !showName && css`
      display: flex;
      justify-content: center;
    `;
}}
  
  a, a:active, a:visited {
    text-decoration: none;
  }
`;
