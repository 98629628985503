import { Form, Formik } from 'formik';
import { Modal } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { CreatePrintTransferQueueModalProps } from './create-print-transfer-queue-modal.types';
import { useCreatePrintTransferQueue } from '../../../../../api/queue/print-transfer';
import { getCreatePrintTransferQueueSchema } from './create-print-transfer-queue-modal.schema';
import { CreatePrintTransferQueueModalContent } from './components/create-print-transfer-queue-modal-content';
import { createPrintTransferQueueModalAtoms } from './create-print-transfer-queue-modal.atoms';
import { CreatePrintTransferQueueModalFooter } from './components/create-print-transfer-queue-modal-footer';
import { useOnSubmit } from './hooks/use-on-submit';
import { useHandleCloseModal } from '../hooks/use-handle-close-modal';
import { jobTypeOptions } from '../../../../../constants/options';

export const CreatePrintTransferQueueModal = (
  props: CreatePrintTransferQueueModalProps,
) => {
  const { onClose, onConfirm } = props;

  const availableJobs = useRecoilValue(
    createPrintTransferQueueModalAtoms.availableJobs,
  );

  const preparePrintsModalSchema = getCreatePrintTransferQueueSchema({
    availableJobs,
  });

  const { isLoading: processingConfirm, onCreatePrintTransferQueue } =
    useCreatePrintTransferQueue();

  const handleCloseModal = useHandleCloseModal({ onClose });

  const onSubmit = useOnSubmit({
    onClose,
    onConfirm,
    onCreatePrintTransferQueue,
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{ amount: undefined }}
      validateOnChange
      validateOnMount
      validationSchema={preparePrintsModalSchema}
      onSubmit={onSubmit}
    >
      {() => {
        return (
          <Form>
            <Modal
              contentStackProps={{
                alignContent: { lg: 'center', md: 'flex-start' },
                minHeight: '16.8rem',
              }}
              footer={
                <CreatePrintTransferQueueModalFooter
                  handleCloseModal={handleCloseModal}
                  processingConfirm={processingConfirm}
                />
              }
              header={`${jobTypeOptions.transfer} Prints`}
              width={{ lg: '42.8rem', md: '100%' }}
              onClose={onClose}
            >
              <CreatePrintTransferQueueModalContent />
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
