import { useEffect, useState } from 'react';
import {
  Button,
  Cell,
  ColorPicker,
  Flex,
  Grid,
  productColorsMap,
  Stack,
  TextField,
  Typography,
} from 'gantri-components';
import { useHandleFetchDesign } from '../../hooks/fetch-design';
import { DesignHeading } from '../design-heading';
import { bytesToSize } from '../../../../helpers/file';
import { Messages } from '../messages';
import {
  StyledActions,
  StyledAssemblyThumbnail,
  StyledReviewItem,
} from './design-model.styles';
import { designsApi } from '../../../../api';
import { useNotification } from '../../../../hooks/useNotification';
import { useInvalidateFetchDesignCache } from '../../../../api/designs/routes';

const downloadAll = (urls) => {
  urls.forEach((url) => {
    if (url) {
      window.open(url, '_blank');
    }
  });
};

export const DesignModel = () => {
  const { notifyAxiosError, onInterceptRequest } = useNotification();
  const { current, setCurrent } = useHandleFetchDesign();
  const reviewSteps = current?.reviewSteps;
  const [reviews, setReviews] = useState({});
  const [reviewSubmitted, setReviewSubmitted] = useState(false);

  const { invalidateFetchDesignCache } = useInvalidateFetchDesignCache();

  const firstStep = current?.model?.subSteps?.['1'];
  const secondStep = current?.model?.subSteps?.['2'];
  const thirdStep = current?.model?.subSteps?.['3'] || { colors: [] };

  useEffect(() => {
    if (current?.model) {
      if (current?.model?.review?.['1'] || reviewSteps) {
        setReviews(
          current?.model?.review?.['1']
            ? current?.model?.review
            : reviewSteps?.model,
        );
      }
    }
  }, [current?.model, reviewSteps]);

  const getOnInspirationItemUpdated = (
    reviewKey: string,
    position: number,
    approved: boolean,
    message?: string,
  ) => {
    return async () => {
      await onInterceptRequest(async () => {
        try {
          const temporal = { ...reviews };

          temporal[reviewKey].sections = temporal[reviewKey].sections.map(
            (item, idx) => {
              return idx === position ? { ...item, approved, message } : item;
            },
          );

          setReviews(temporal);

          await designsApi.updateDesignReview(current.id, {
            review: reviews,
            step: 'Model',
          });
        } catch (error: unknown) {
          notifyAxiosError({
            error,
            fallbackMessage: 'Unable to update design review.',
          });
        }
      });
    };
  };

  const completeReview = async () => {
    await onInterceptRequest(async () => {
      try {
        setReviewSubmitted(true);
        await designsApi.completeDesignReview(current?.id, {
          review: reviews,
          step: 'Model',
        });
        setReviewSubmitted(false);
        await invalidateFetchDesignCache();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to complete review.',
        });
      }
    });
  };

  const onReasonBlur = (reviewKey, position) => {
    return (message: string) => {
      getOnInspirationItemUpdated(reviewKey, position, false, message)();
    };
  };

  const downloadModels = () => {
    const urls = (secondStep.parts || []).map((part) => {
      return part.fileUrl;
    });

    urls.push(firstStep.assemblyFile.fileUrl);
    downloadAll(urls);
  };

  const colorsList = (thirdStep.colors || [])
    .map(({ code, name }) => {
      return productColorsMap[code]?.shortColorName || name;
    })
    .join(', ');

  return firstStep && secondStep && thirdStep ? (
    <Grid columns="2fr 1fr" gap="3rem" paddingTop="s1">
      <Grid columns={1} gap="12rem">
        <DesignHeading design={current} />

        <Grid columns={1} gap="3rem">
          <Typography text="Model files" textStyle="bold" variant="h2" />

          <Cell>
            <Typography
              marginBottom="2rem"
              text="ASSEMBLY"
              textStyle="bold"
              variant="h5"
            />

            <Grid columns="6rem auto" gap="2rem">
              <StyledAssemblyThumbnail />

              <div>
                <Typography
                  text={`${firstStep.assemblyFile.fileName}${firstStep.assemblyFile.fileExtension}`}
                />
                <Typography
                  color="t1"
                  text={bytesToSize(firstStep.assemblyFile.fileSize)}
                />
              </div>
            </Grid>
          </Cell>

          <Cell>
            <Typography
              marginBottom="2rem"
              text="PARTS"
              textStyle="bold"
              variant="h5"
            />

            <Grid columns={1} gap="1rem">
              {(secondStep.parts || []).map((part, index) => {
                return (
                  <Cell key={index}>
                    <Grid columns="6rem auto" gap="2rem">
                      <StyledAssemblyThumbnail />

                      <div>
                        <Typography
                          text={`${part.fileName}${part.fileExtension}`}
                        />
                        <Typography
                          color="t1"
                          text={bytesToSize(part.fileSize)}
                        />
                      </div>
                    </Grid>
                  </Cell>
                );
              })}
            </Grid>
          </Cell>

          <Cell>
            <Button
              text="Download All"
              variant="secondary"
              onClick={downloadModels}
            />
          </Cell>
        </Grid>

        <Grid columns={1} gap="1rem">
          <Typography text="Colors" textStyle="bold" variant="h2" />
          <Typography color="t2" marginRight="2rem" text={colorsList} />
          <ColorPicker
            readOnly
            value={thirdStep.colors?.map((item) => {
              return item.code;
            })}
            variant="small"
          />
        </Grid>

        {Object.keys(reviews || {}).map((reviewKey) => {
          return (
            <Cell key={reviewKey}>
              <Flex alignItems="center" justifyContent="space-between">
                <Typography
                  text={reviews[reviewKey].header}
                  textStyle="bold"
                  variant="h2"
                />
                <Typography text="Approve" variant="p2" />
              </Flex>

              <Stack gap="0" marginTop="3rem">
                {reviews[reviewKey].sections.map((item, position) => {
                  return (
                    <StyledReviewItem key={item.title}>
                      <Flex alignItems="center" justifyContent="space-between">
                        <div>
                          <Typography text={item.title} variant="p2" />
                          <Typography
                            color="t1"
                            text={item.description}
                            variant="p2"
                          />
                        </div>

                        <Grid columns={2} gap="1rem">
                          <Button
                            text="Yes"
                            variant={item.approved ? 'primary' : 'secondary'}
                            onClick={getOnInspirationItemUpdated(
                              reviewKey,
                              position,
                              true,
                            )}
                          />
                          <Button
                            text="No"
                            variant={
                              item.approved ? 'secondary' : 'primaryAlert'
                            }
                            onClick={getOnInspirationItemUpdated(
                              reviewKey,
                              position,
                              false,
                            )}
                          />
                        </Grid>
                      </Flex>
                      {!item.approved && (
                        <div>
                          <TextField
                            placeholder="Give a reason"
                            value={item.message}
                            onBlurTextChange={onReasonBlur(reviewKey, position)}
                          />
                        </div>
                      )}
                    </StyledReviewItem>
                  );
                })}
              </Stack>
            </Cell>
          );
        })}

        {current.model.status === 'Submitted' && (
          <StyledActions>
            <Button
              processing={reviewSubmitted}
              text="Complete Review"
              onClick={completeReview}
            />
          </StyledActions>
        )}
        <Cell />
      </Grid>
      <Cell>
        <Messages
          current={current}
          designId={current.id}
          messages={current.messages || []}
          notes={current.notes || []}
          updateMessages={setCurrent}
        />
      </Cell>
    </Grid>
  ) : null;
};
