import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import {
  GetOrderTimelineArgs,
  GetOrderTimelineResponse,
} from './get-order-timeline.types';
import { getOrderTimeline } from './get-order-timeline';

const fetchOrderTimelineQueriesObj = getGenericQueryObj({
  groupName: 'fetch-order-timeline',
  query: getOrderTimeline,
});

export const useFetchOrderTimeline = <
  TransformedData = GetOrderTimelineResponse,
>(
  props?: GenericFetchProps<
    GetOrderTimelineArgs,
    GetOrderTimelineResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch order timeline.',
    queryObj: fetchOrderTimelineQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchOrderTimelineCache = () => {
  const invalidateFetchOrderTimelineCache = useGetInvalidateQueryCache(
    fetchOrderTimelineQueriesObj,
  );

  return { invalidateFetchOrderTimelineCache };
};
