import {
  Stack,
  Button,
  Table,
  Icon,
  Conditional,
  Cell,
} from 'gantri-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { sortBy } from 'lodash';
import { useEffect } from 'react';
import { productAtoms } from '../../../../../../product.atoms';
import { useGetMarketplaceSkuColorsColumns } from './hooks/use-get-marketplace-sku-colors-columns';
import { productInfoTabAtoms } from '../../../../info.atoms';

export const SkuConfigurationColors = () => {
  const product = useRecoilValue(productAtoms.product);

  const [marketplaceColors, setMarketplaceColors] = useRecoilState(
    productInfoTabAtoms.marketplaceColors,
  );

  const isAddingNewColor = marketplaceColors.some(({ isNew }) => {
    return isNew;
  });

  const columns = useGetMarketplaceSkuColorsColumns({
    isAddingNewColor,
  });

  const handleAddVisibleColorRow = () => {
    setMarketplaceColors((oldColors) => {
      return [
        ...oldColors,
        {
          code: undefined,
          defaultSku: undefined,
          isNew: true,
          name: undefined,
        },
      ];
    });
  };

  useEffect(() => {
    const sortedColors = sortBy(product.colors, 'name');

    setMarketplaceColors(sortedColors);
  }, [product.colors]);

  return (
    <Stack gap="2x">
      <Cell justifyItems="start">
        <Table columns={columns} data={marketplaceColors} />
      </Cell>
      <Conditional condition={product.isPainted && !isAddingNewColor}>
        <Button
          icon={<Icon color="link" name="ui-control:plus" />}
          justifySelf="start"
          text="Select Visible Color"
          variant="ghost"
          onClick={handleAddVisibleColorRow}
        />
      </Conditional>
    </Stack>
  );
};
