import { Button, Table } from 'gantri-components';
import routePaths from '../../config/route-paths';
import { InventoryPurchasesFilter } from '../../components/dropdowns';
import { PageWithTable } from '../../components/layout';
import { inventoryPurchasesApi } from '../../api/inventory-purchases';
import { PaginatedInventoryPurchase } from '../../api/inventory-purchases/inventory-purchases.types';
import { useNotification } from '../../hooks/useNotification';
import {
  FiltersFetchRequestArgs,
  useTableFilters,
} from '../../components/common/table/hooks';
import {
  inventoryPurchasesFiltersDefaults,
  inventoryPurchasesPageAtoms,
  InventoryPurchasesSortingField,
  pageName,
} from '../../components/dropdowns/inventory-purchases-filter';
import { GetPaginatedInventoryPurchasesArgs } from '../../api/inventory-purchases/routes/get-paginated-inventory-purchases/get-paginated-inventory-purchases.types';
import { useRouter, useSpinner } from '../../hooks';
import { columns } from './inventory-purchases.constants';
import Protected from '../../components/common/protected/protected';

export const InventoryPurchases = () => {
  const { navigate } = useRouter();
  const { notifyAxiosError } = useNotification();
  const { onInterceptRequest } = useSpinner();

  const fetchPaginatedInventoryPurchases = async ({
    country,
    ...args
  }: FiltersFetchRequestArgs<
    GetPaginatedInventoryPurchasesArgs,
    InventoryPurchasesSortingField
  >) => {
    await onInterceptRequest(async () => {
      try {
        const {
          data: { purchases, totalPurchases },
        } = await inventoryPurchasesApi.getPaginatedInventoryPurchases({
          ...args,
          country: country === 'all' ? '' : country,
        });

        setTotalCount(totalPurchases);
        setRecords(purchases);
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch inventory purchases.',
        });
      }
    });
  };

  const {
    filtersProps,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    PaginatedInventoryPurchase,
    GetPaginatedInventoryPurchasesArgs,
    InventoryPurchasesSortingField
  >({
    fetchRequest: fetchPaginatedInventoryPurchases,
    filtersContent: <InventoryPurchasesFilter />,
    modalFiltersDetails: [
      {
        atom: inventoryPurchasesPageAtoms.filters.completedDateRange,
        defaultValue:
          inventoryPurchasesFiltersDefaults.filters.completedDateRange,
        payloadKey: 'completedDateRange',
      },
      {
        atom: inventoryPurchasesPageAtoms.filters.country,
        defaultValue: inventoryPurchasesFiltersDefaults.filters.country,
        payloadKey: 'country',
      },
      {
        atom: inventoryPurchasesPageAtoms.filters.createdDateRange,
        defaultValue:
          inventoryPurchasesFiltersDefaults.filters.createdDateRange,
        payloadKey: 'createdDateRange',
      },
      {
        atom: inventoryPurchasesPageAtoms.filters.statuses,
        defaultValue: inventoryPurchasesFiltersDefaults.filters.statuses,
        payloadKey: 'statuses',
      },
      {
        atom: inventoryPurchasesPageAtoms.filters.hasDiscardedShipment,
        defaultValue:
          inventoryPurchasesFiltersDefaults.filters.hasDiscardedShipment,
        payloadKey: 'hasDiscardedShipment',
      },
    ],
    pageName,
    searchFilterDetails: {
      atom: inventoryPurchasesPageAtoms.search,
    },
    sortByFilterDetails: {
      atom: inventoryPurchasesPageAtoms.sortBy,
      defaultValue: inventoryPurchasesFiltersDefaults.sortBy,
    },
    sortOptions: [
      {
        bidirectional: true,
        label: 'Create Date',
        sortingField: 'createdAt',
      },
      {
        bidirectional: true,
        label: 'Complete Date',
        sortingField: 'completedAt',
      },
    ],
  });

  return (
    <PageWithTable pageTitle="Inventory Purchases">
      <PageWithTable.Header title="Inventory Purchases">
        <Protected allowedFor={['Admin']}>
          <Button
            text="New Purchase"
            onClick={() => {
              return navigate(routePaths.newInventoryPurchase);
            }}
          />
        </Protected>
      </PageWithTable.Header>

      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          sorting={sortProps}
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
