import { Button, Modal, Typography } from 'gantri-components';
import { transactionsApi } from '../../../../api';
import { useInvalidateFetchOrderCache } from '../../../../api/transactions/routes';
import { useNotification } from '../../../../hooks/useNotification';
import { ResendConfirmationEmailModalPropsDef } from './resend-confirmation-email-modal.types';

export const ResendConfirmationEmailModal = (
  props: ResendConfirmationEmailModalPropsDef,
) => {
  const { emailInfo, onClose, orderId } = props;

  const { notify, notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const { invalidateFetchOrderCache } = useInvalidateFetchOrderCache();

  const onConfirm = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        const { data } = await transactionsApi.resendConfirmationEmail({
          currentOrderId: orderId,
          shipmentId: emailInfo.ownerId,
          type: emailInfo.emailType,
        });

        notify(data.notice);

        await invalidateFetchOrderCache();
        onClose();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to re-send confirmation email.',
        });
      }
    });
  };

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            processing={processing}
            size="large"
            text="Confirm"
            onClick={onConfirm}
          />
        </>
      }
      header="Re-send confirmation email?"
      maxWidth={{ lg: '40rem', md: '100%' }}
      onClose={onClose}
    >
      <Typography
        align="center"
        text="Are you sure you want to re-send this email?"
      />
    </Modal>
  );
};
