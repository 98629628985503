import { convertStringArrayToValueLabelArray } from '../../../../helpers/formatter';

export const orderTypes = [
  'R&D',
  'Marketing',
  'Third Party',
  'Designer',
  'Trade',
  'Wholesale',
] as const;

export type OrderType = typeof orderTypes[number];

export const orderTypeOptions = convertStringArrayToValueLabelArray(orderTypes);
