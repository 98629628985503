import { allProductColorCodes } from 'gantri-components';
import * as yup from 'yup';
import { noneOption, productSizes } from '../../../constants/options';
import { SelectProductFormData } from './select-product-form.types';

export const selectProductFormInitialValues: SelectProductFormData = {
  colorCode: undefined,
  productId: undefined,
  quantity: 1,
  selectedSku: undefined,
  sizeCode: undefined,
  variantCodes: [],
  variantPartialDetails: [],
};

const requiredLabel = 'Required';

export const selectProductFormSchema: yup.SchemaOf<SelectProductFormData> = yup
  .object()
  .shape({
    colorCode: yup
      .mixed()
      .oneOf([...allProductColorCodes, noneOption.code])
      .required(requiredLabel),
    productId: yup.number().required(requiredLabel),
    quantity: yup.number().required(requiredLabel),
    selectedSku: yup.string().required(requiredLabel),
    sizeCode: yup
      .mixed()
      .oneOf([...Object.keys(productSizes), noneOption.code])
      .required(requiredLabel),
    variantCodes: yup.array().of(yup.string().required(requiredLabel)),
    variantPartialDetails: yup.array().of(
      yup.array().of(
        yup.object().shape({
          code: yup.string().required(requiredLabel),
          label: yup.string().required(requiredLabel),
          name: yup.string().required(requiredLabel),
        }),
      ),
    ),
  });
