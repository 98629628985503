import { Stack } from 'gantri-components';
import { Divider } from '../../../../components/divider';
import { GeneralSection } from './components/general-section';
import { InventoriesSection } from './components/inventories-section';
import { VendorInfoProps } from './vendor-info.types';

export const VendorInfo = (props: VendorInfoProps) => {
  const { editedVendor, handleSave, isEditingDisabled, toggleEditMode } = props;

  return (
    <Stack gap="2rem" paddingBottom="1.2rem">
      <GeneralSection
        editedVendor={editedVendor}
        handleSave={handleSave}
        isEditingDisabled={isEditingDisabled}
        toggleEditMode={toggleEditMode}
      />
      <Divider />
      <InventoriesSection editedVendor={editedVendor} />
    </Stack>
  );
};
