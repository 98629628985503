import PropTypes from 'prop-types';
import { getThemeColor, palette } from 'gantri-components';
import {
  StyledGantriLogoGroup,
  StyledGantriLogoWrapper,
  StyledLargeGroup,
} from './gantri-logo-styles';

const GantriLogo = ({ fillColor, hoverColor, isCompressed, noPointer }) => {
  return (
    <StyledGantriLogoWrapper
      hoverColor={getThemeColor(hoverColor || fillColor || 'monochrome_black')}
      noPointer={noPointer}
    >
      <svg height="27px" version="1.1" viewBox="0 0 120 27" width="120px">
        <StyledGantriLogoGroup
          fillColor={getThemeColor(fillColor || 'monochrome_black')}
          transform="translate(-30.000000, -72.000000)"
        >
          <g transform="translate(30.000000, 72.000000)">
            <path d="M22.0125153,9.09999625 C21.8437441,5.5475913 18.321561,4.17564418 15.6121894,4.17564418 C10.4649478,4.17564418 5.68968036,6.88377398 5.68968036,13.4972627 C5.68968036,18.2111322 8.12755034,22.8194674 15.6460565,22.8194674 C17.7796867,22.8194674 19.6423796,22.5380424 22.3517512,21.6585891 L22.3517512,16.804007 L14.5284407,16.804007 L14.5284407,12.8640563 L27.7021957,12.8640563 L27.7021957,24.7185002 C24.2138798,26.266338 18.8634353,26.9699006 15.3073851,26.9699006 C6.19768753,26.9699006 0,22.7491112 0,13.5324408 C0,5.93455074 5.18167318,0.235693479 14.7993779,0.235693479 C22.1818511,0.235693479 27.7021957,3.15547837 27.7021957,9.09999625 L22.0125153,9.09999625" />
          </g>
          <StyledLargeGroup
            isCompressed={isCompressed}
            transform="translate(30.000000, 72.000000)"
          >
            <path d="M42.5230228,18.2463104 C38.6283011,18.2463104 36.4269367,19.0905855 36.4269367,21.2006871 C36.4269367,22.3967436 37.3752167,24.0149376 40.3216584,24.0149376 C42.8616942,24.0149376 46.6881172,22.4676861 46.7564159,18.2463104 L42.5230228,18.2463104 Z M51.9036575,22.9595937 C51.9036575,24.120472 51.9375246,25.1054597 52.0391261,26.3015161 L47.2977258,26.3015161 C47.1961243,25.4924191 47.1622572,24.6833221 47.1622572,23.8742251 C45.0286271,26.0904473 42.3875542,26.9699006 39.1024411,26.9699006 C33.1418236,26.9699006 31.0764922,24.0852939 31.0764922,21.5524684 C31.0764922,16.0300881 36.1559995,15.0802786 42.6584914,15.0802786 L46.7564159,15.0802786 C46.7564159,11.9494249 44.6227858,11.1403279 42.0488828,11.1403279 C39.542714,11.1403279 38.1202939,12.2308499 37.9170911,13.602797 L32.5660822,13.602797 C33.0740893,9.06481812 37.7816225,7.97429605 42.1843513,7.97429605 C46.9590543,7.97429605 51.9036575,9.3814213 51.9036575,15.5727724 L51.9036575,22.9595937 L51.9036575,22.9595937 Z" />
            <path d="M56.1517264,12.8288782 C56.1517264,11.2810404 56.0839921,9.76838074 56.0162578,8.64268054 L60.960861,8.64268054 C61.0285953,9.62766822 61.0957651,10.5774778 61.0957651,11.5624654 L61.1634994,11.5624654 C62.8907238,9.06481812 65.2275568,7.97429605 68.3433341,7.97429605 C71.3913772,7.97429605 76.132213,9.69802448 76.132213,15.4320599 L76.132213,26.3015161 L70.9849714,26.3015161 L70.9849714,16.9447195 C70.9849714,12.5826312 69.0545442,11.5624654 66.5822426,11.5624654 C63.9067381,11.5624654 61.2995324,13.4972627 61.2995324,17.7889947 L61.2995324,26.3015161 L56.1517264,26.3015161 L56.1517264,12.8288782" />
            <path d="M87.715341,3.26101277 L87.715341,8.64268054 L93.7769955,8.64268054 L93.7769955,12.0197811 L87.715341,12.0197811 L87.715341,20.2514639 C87.715341,22.1862611 88.4942854,23.2410187 90.4580153,23.2410187 C91.5756311,23.2410187 92.9303169,23.1354843 93.641527,22.9244155 L93.641527,26.3015161 C92.252974,26.4774068 90.6956498,26.6181193 89.2732297,26.6181193 C84.1254237,26.6181193 82.567535,24.8943909 82.567535,20.7439577 L82.567535,12.0197811 L77.8261347,12.0197811 L77.8261347,8.64268054 L82.567535,8.64268054 L82.567535,4.8786205 L87.715341,3.26101277" />
            <path d="M97.0965402,13.0751251 C97.0965402,11.3865748 97.0288059,9.838737 96.9610716,8.64268054 L101.905675,8.64268054 C101.973409,9.73320261 102.041143,10.7885465 102.041143,11.8790686 L102.108878,11.8790686 C102.616885,10.6830122 103.768368,8.29089923 107.730259,8.29089923 C108.814008,8.29089923 109.830022,8.39701993 111.049239,8.64268054 L111.049239,12.6529875 C110.744435,12.5826312 110.371897,12.5474531 109.965491,12.512275 C109.592952,12.4770968 109.152679,12.4419187 108.645237,12.4419187 C104.479578,12.4419187 102.244346,14.3063597 102.244346,19.1609418 L102.244346,26.3015161 L97.0965402,26.3015161 L97.0965402,13.0751251" />
            <path d="M114.233316,26.3015161 L119.381122,26.3015161 L119.381122,8.64268054 L114.233316,8.64268054 L114.233316,26.3015161 Z M113.962378,5.19580998 L119.652059,5.19580998 L119.652059,0.904077973 L113.962378,0.904077973 L113.962378,5.19580998 Z" />
          </StyledLargeGroup>
        </StyledGantriLogoGroup>
      </svg>
    </StyledGantriLogoWrapper>
  );
};

GantriLogo.propTypes = {
  fillColor: PropTypes.oneOf(Object.keys(palette)),
  hoverColor: PropTypes.oneOf(Object.keys(palette)),
  isCompressed: PropTypes.bool,
  noPointer: PropTypes.bool,
};

export default GantriLogo;
