import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  UpdateSkuAssetsArgs,
  UpdateSkuAssetsResponse,
} from './update-sku-assets.types';

export const updateSkuAssets = async (props: UpdateSkuAssetsArgs) => {
  const { productId, ...body } = props;

  return axios
    .put<UpdateSkuAssetsResponse>(
      `${getCoreApiUrl('products')}/${productId}/sku-assets`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
