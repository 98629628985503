import { useMemo } from 'react';
import { Typography } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useRecoilValue } from 'recoil';
import { formatAsCurrency } from '../../../../../../helpers/formatter';
import JobTypeDataCell from '../../../../../../components/common/custom-data-cells/job-type-data-cell';
import { LaborByJobType } from '../../../../../../api/products/products.types';
import { productAtoms } from '../../../../product.atoms';

export const useLaborColumns = () => {
  const product = useRecoilValue(productAtoms.product);

  const laborColumns = useMemo(() => {
    const columns: ColumnDef<LaborByJobType>[] = [
      {
        accessorKey: 'type',
        cell: ({
          getValue,
        }: CellContext<LaborByJobType, LaborByJobType['type']>) => {
          return <JobTypeDataCell type={getValue()} />;
        },
        footer: 'TOTAL',
        header: 'Job Type',
      },
      {
        accessorKey: 'jobs',
        footer: () => {
          return product?.laborTotal?.jobs;
        },
        header: 'Jobs',
      },
      {
        accessorKey: 'duration',
        footer: () => {
          return product?.laborTotal?.duration;
        },
        header: 'Duration (m)',
      },
      {
        accessorKey: 'cost',
        cell: ({
          getValue,
        }: CellContext<LaborByJobType, LaborByJobType['cost']>) => {
          return (
            <Typography
              text={formatAsCurrency(getValue(), { isCents: true })}
            />
          );
        },
        header: 'Cost / Min',
      },
      {
        accessorKey: 'totalCost',
        cell: ({
          getValue,
        }: CellContext<LaborByJobType, LaborByJobType['totalCost']>) => {
          return (
            <Typography
              text={formatAsCurrency(getValue(), { isCents: true })}
            />
          );
        },
        footer: formatAsCurrency(product?.laborTotal?.totalCost, {
          isCents: true,
        }),
        header: 'Total Cost',
      },
    ];

    return columns;
  }, [product?.laborTotal]);

  return laborColumns;
};
