import { getFileUrl, Image } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';
import { productAtoms } from '../../../../../../../../../../../../product.atoms';
import { cloudinaryUrl } from '../../../../../../../../../../../../../../helpers/images';
import { ConfigureCutSheetFormSchemaDef } from '../../../../configure-cut-sheets-modal.types';
import { StyledThumbnailBox } from './cut-sheet-lifestyle-photo-item.styles';
import { CutSheetLifestylePhotoItemPropsDef } from './cut-sheet-lifestyle-photo-item.types';

export const CutSheetLifestylePhotoItem = (
  props: CutSheetLifestylePhotoItemPropsDef,
) => {
  const { getIsDisabled, photo } = props;

  const product = useRecoilValue(productAtoms.product);

  const { setFieldValue, values } =
    useFormikContext<ConfigureCutSheetFormSchemaDef>();

  const url = getFileUrl<'products'>({
    directory: 'products',
    fileName: photo.fileName,
    fileType: 'lifestyle-photos',
    identifiers: {
      productId: product.id,
    },
  });

  const isSelected = [
    values.firstLifestyleViewPhoto,
    values.secondLifestyleViewPhoto,
  ].some((fileName) => {
    return fileName === photo.fileName;
  });

  const onClick = async () => {
    if (values.requires2LifestylePhotos) {
      if (isSelected) {
        const matchesFirstLifestylePhoto =
          values.firstLifestyleViewPhoto === photo.fileName;

        if (matchesFirstLifestylePhoto) {
          await setFieldValue('firstLifestyleViewPhoto', null);
        } else {
          await setFieldValue('secondLifestyleViewPhoto', null);
        }
      } else if (
        !values.firstLifestyleViewPhoto ||
        !values.secondLifestyleViewPhoto
      ) {
        if (!!values.firstLifestyleViewPhoto) {
          await setFieldValue('secondLifestyleViewPhoto', photo.fileName);
        } else {
          await setFieldValue('firstLifestyleViewPhoto', photo.fileName);
        }
      }
    } else if (!isSelected) {
      await setFieldValue('firstLifestyleViewPhoto', photo.fileName);
    }
  };

  return (
    <StyledThumbnailBox
      key={photo.fileName}
      $isDisabled={getIsDisabled({ isSelected })}
      $isSelected={isSelected}
      backgroundColor="t2"
      height="10rem"
      padding=".5x"
      width="10rem"
      onClick={onClick}
    >
      <Image
        alt={`${product.name} lifestyle photo`}
        source="absolute"
        src={cloudinaryUrl(url, {
          aspectRatio: '1:1',
          crop: 'pad',
          dpr: 2,
          width: 100,
        })}
        style={{ position: 'relative' }}
      />
    </StyledThumbnailBox>
  );
};
