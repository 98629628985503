import React, { FC, useMemo } from 'react';
import {
  Conditional,
  Flex,
  Grid,
  Icon,
  Stack,
  Typography,
} from 'gantri-components';
import sumBy from 'lodash/sumBy';
import { Link } from 'react-router-dom';
import { productCategories } from '../../../../api/products/products.constants';
import { StockAttentionProps } from './stock-attention.types';
import { getDaysSince } from '../../../part/components/part-attention/helpers/get-days-since';
import { prepareFailureReasons } from '../../../../helpers/fail-reason';
import routePaths from '../../../../config/route-paths';
import { stockStatuses } from '../../../../constants/options';
import { SectionTitle } from '../../../../components/layout/section-title';

export const StockAttention: FC<StockAttentionProps> = (props) => {
  const { stock } = props;

  const { completedAtSec, createdAt, status } = stock;

  const daysSinceStockCreated = getDaysSince(createdAt, completedAtSec);
  const countOfAssemblyFails = useMemo(() => {
    const assemblyFailAttention = stock?.attentions?.find((item) => {
      return item.name === 'Assembly job fails';
    });

    if (assemblyFailAttention) {
      return {
        count: sumBy(assemblyFailAttention.jobs, 'attempt'),
        failReasons: assemblyFailAttention.jobs
          .reduce((accumulator, job) => {
            const failReasons = prepareFailureReasons(job.failedReason).map(
              ({ code, details, label }) => {
                return code === 'other_failure_modes' ? details : label;
              },
            );

            return [...accumulator, ...failReasons];
          }, [])
          .filter((value, index, array) => {
            return array.indexOf(value) === index;
          })
          .join(', '),
      };
    }

    return {
      count: 0,
      failReasons: '',
    };
  }, [stock?.attentions]);

  const shouldShowAttentionStatus = ![
    stockStatuses.completed,
    stockStatuses.cancelled,
    stockStatuses.discarded,
  ].some((finalizedStatus) => {
    return finalizedStatus === status;
  });

  const isAccessory = stock.category === productCategories.accessory;

  return (
    <Stack gap="2x">
      <SectionTitle text="Attention" />

      <Grid columns={{ lg: '18rem 1fr', sm: 'repeat(2, 1fr)' }} gap="1.2rem">
        <Typography text="Days since stock created" textStyle="bold" />

        <Conditional
          condition={shouldShowAttentionStatus}
          Fallback={<Typography text={`${daysSinceStockCreated} days`} />}
        >
          <Flex alignItems="center" gap="0.8rem">
            <Conditional condition={daysSinceStockCreated >= 30}>
              <Icon color="alert" name="alert:warning_triangle" />
            </Conditional>
            <Typography text={`${daysSinceStockCreated} days`} />
          </Flex>
        </Conditional>

        <Conditional condition={!isAccessory}>
          <Typography text="Assembly fails" textStyle="bold" />
          <Conditional
            condition={countOfAssemblyFails.count > 0}
            Fallback={<Typography text={0} />}
          >
            <div>
              <Flex alignItems="center" gap="0.8rem">
                <Conditional condition={shouldShowAttentionStatus}>
                  <Icon color="alert" name="alert:warning_triangle" />
                </Conditional>
                <Typography text={countOfAssemblyFails.count} />
              </Flex>

              <Conditional condition={countOfAssemblyFails.count > 0}>
                <Typography
                  paddingTop="0.8rem"
                  text={`Failures: ${countOfAssemblyFails.failReasons}`}
                />
              </Conditional>
            </div>
          </Conditional>
        </Conditional>

        <Conditional condition={!isAccessory}>
          <Typography text="Parts with attention alerts" textStyle="bold" />
          <Conditional
            condition={stock.partsWithAttentions?.length > 0}
            Fallback={<Typography text={0} />}
          >
            <div>
              <Flex alignItems="center" gap="0.8rem">
                <Conditional
                  condition={
                    shouldShowAttentionStatus &&
                    stock.partsWithAttentions?.length >= 2
                  }
                >
                  <Icon color="alert" name="alert:warning_triangle" />
                </Conditional>
                <Typography text={stock.partsWithAttentions?.length} />
              </Flex>

              <Conditional condition={stock.partsWithAttentions?.length > 0}>
                <Stack gap="unset" marginTop="0.8rem">
                  {stock.partsWithAttentions?.map((part, index) => {
                    return (
                      <Flex key={index} gap="0.4rem">
                        <Link
                          target="_blank"
                          to={`${routePaths.parts}/${part.id}`}
                        >
                          <Typography
                            color="link"
                            decoration="underline"
                            text={`#${part.id}`}
                          />
                        </Link>

                        <Typography text={part.name} />
                      </Flex>
                    );
                  })}
                </Stack>
              </Conditional>
            </div>
          </Conditional>
        </Conditional>
      </Grid>
    </Stack>
  );
};
