import {
  useInvalidateQueryByPredicate,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';
import { useNotification } from '../../../../hooks/useNotification';
import { getPrintTransferQueues } from './get-print-transfer-queues';

export const useGetPrintTransferQueues = (args?: { showLoading?: boolean }) => {
  const { showLoading } = args || {};

  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch({
    config: {
      enabled: true,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch print transfer queues.',
        });
      },
      queryFn: () => {
        return getPrintTransferQueues.query();
      },
      queryKey: getPrintTransferQueues.key(),
    },
    showLoading,
  });

  return {
    ...data,
    isLoading,
  };
};

export const useInvalidateGetPrintTransferQueuesCache = () => {
  const invalidate = useInvalidateQueryByPredicate();

  const invalidateGetPrintTransferQueuesCache = async () => {
    await invalidate((query) => {
      if (typeof query.queryKey[0] === 'string') return false;

      const { groupKey } = query.queryKey[0] as {
        groupKey: string;
        key: string;
      };

      return groupKey === getPrintTransferQueues.groupKey();
    });
  };

  return { invalidateGetPrintTransferQueuesCache };
};
