import { Typography } from 'gantri-components';
import { Label } from '../../../../../../../../../../../../../../components/label';
import { BulkUploadHeaderTooltipIcon } from '../bulk-upload-table-header-tooltip-icon';

export const BulkUploadTableDimmingPhotosHeader = () => {
  return (
    <Label
      icon={
        <BulkUploadHeaderTooltipIcon
          footer={
            <Typography text="1 Dark image and 1 Light image allowed per SKU." />
          }
          header={
            <Typography
              htmlText="For mapping to type <u><strong>Dimming</strong></u>:"
              textStyle="bold"
            />
          }
          items={[
            'Filename includes all elements listed in SKU mapping info.',
            'Filename includes the following codes/names:',
            [
              '‘dimming_dark’ OR ‘Dimming Dark’',
              '‘dimming_light’ OR ‘Dimming Light’',
            ],
          ]}
        />
      }
      iconPosition="right"
      text="Dimming"
    />
  );
};
