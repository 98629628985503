import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../constants/options';
import { RecommendedStepOptionItem } from '../../../../../../../../pages/jobs/components/modals/complete-job/complete-finishing-qa-modal/components/step-job-failed-recommended-next-step/job-failed-recommended-next-step-content/components/recommended-step-option/recommended-step-option.types';
import { Job } from '../../../../../../../../api/jobs/jobs.types';

export const getNextStepOptions = (job: Job): RecommendedStepOptionItem[] => {
  const duplicateOption: RecommendedStepOptionItem = {
    icon: 'work:mask',
    label: 'Duplicate rejected job',
    step: 'JOB_FAILED_DUPLICATE',
    subText:
      job.step === jobSteps.print && job.type === jobTypeOptions.print
        ? `Duplicates ${jobSteps.print} ${jobTypeOptions.print} and ${jobSteps.print} ${jobTypeOptions.transfer} jobs with a new job IDs.`
        : `Duplicates ${job.step} ${job.type} job with a new job ID.`,
  };
  const restartOption: RecommendedStepOptionItem = {
    icon: 'ui-control:lines_three',
    label: 'Re-start part',
    step: 'JOB_FAILED_RESTART',
    subText: 'Creates new part and part ID.',
  };

  if (job.step === jobSteps.print) {
    if (
      [jobTypeOptions.transfer, jobTypeOptions.print].some((type) => {
        return type === job.type;
      })
    ) {
      return [duplicateOption];
    }

    if (
      [jobTypeOptions.qc].some((type) => {
        return type === job.type;
      })
    ) {
      return [restartOption];
    }
  }

  return [duplicateOption, restartOption];
};
