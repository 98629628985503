import { PaginatedInventory } from '../../../../api/inventories/routes/fetch-paginated-inventories/fetch-paginated-inventories.types';
import { InventoriesDownloadData } from './get-download-data.types';

export const getDownloadData = (
  data: PaginatedInventory[],
): InventoriesDownloadData => {
  return data.map(
    ({ alertLevel, id, name, stock, type, unit, unitCost, usage, vendor }) => {
      return {
        alertLevel,
        id,
        name,
        stock,
        type,
        unit,
        unitCost,
        usage,
        vendorName: vendor?.name,
      };
    },
  );
};
