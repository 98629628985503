import { Dropdown } from 'gantri-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import {
  useInvalidateFetchProductCache,
  useUpdateMarketplaceColors,
} from '../../../../../../../../../../../../api/products/routes';
import { DefaultSkuCellProps } from './default-sku-cell.types';
import { filterSkusByColor } from '../../helpers/filter-skus-by-color';
import { productInfoTabAtoms } from '../../../../../../../../info.atoms';
import { MarketplaceColor } from '../../../../marketplace-skus-colors.types';

export const DefaultSkuCell = (props: DefaultSkuCellProps) => {
  const { code, defaultSku, isAddingNewColor, isNew } = props;

  const product = useRecoilValue(productAtoms.product);

  const [marketplaceColors, setMarketplaceColors] = useRecoilState(
    productInfoTabAtoms.marketplaceColors,
  );

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();

  const { onUpdateMarketplaceColors } = useUpdateMarketplaceColors({
    onSuccess: async () => {
      await invalidateFetchProductCache();
    },
  });

  const items: { isNew: boolean; label: string; value: string }[] =
    useMemo(() => {
      return sortBy(
        filterSkusByColor({
          colorCode: code,
          skus: product.skus,
        }),
      ).map((label) => {
        return { isNew, label, value: label };
      });
    }, [code, product.skus]);

  const disabled = items.length <= 1 || (!isNew && isAddingNewColor);

  return (
    <Dropdown
      disabled={disabled}
      dropdownPopupWidth="max-content"
      items={items}
      placeholder="Select SKU"
      value={defaultSku}
      onSelect={async (item) => {
        const updatedColors: MarketplaceColor[] = marketplaceColors.map(
          (color) => {
            if (color.code === code) {
              const updatedColor: MarketplaceColor = {
                ...color,
                defaultSku: item?.value,
              };

              return updatedColor;
            }

            return color;
          },
        );

        if (item?.isNew) {
          setMarketplaceColors(updatedColors);
        } else {
          await onUpdateMarketplaceColors({
            colors: updatedColors,
            id: product.id,
          });
        }
      }}
    />
  );
};
