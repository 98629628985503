import { useGenericMutateQuery } from '../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { useInvalidateGetPaginatedPressRecordsCache } from '../get-paginated-press-records';
import { addPressRecord } from './add-press-record';
import {
  AddPressRecordArgs,
  AddPressRecordResponse,
} from './add-press-record.types';

export const useAddPressRecordMutation = <
  TransformedData = AddPressRecordResponse,
>(
  props?: GenericMutateQueryProps<
    AddPressRecordArgs,
    AddPressRecordResponse,
    TransformedData
  >,
) => {
  const { invalidateGetPaginatedPressRecordsCache } =
    useInvalidateGetPaginatedPressRecordsCache();

  const { onMutate: onAddPressRecord, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to add new press record.',
    mutationFn: addPressRecord,
    ...props,
    onSuccess: async (data, args) => {
      await props?.onSuccess(data, args);

      await invalidateGetPaginatedPressRecordsCache();
    },
  });

  return { ...rest, onAddPressRecord };
};
