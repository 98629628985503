import { Modal } from 'gantri-components';
import { useEffect } from 'react';
import { useInvalidateFetchOrderCache } from '../../../../api/transactions/routes';
import { useHandleCloseModal } from '../../../jobs/components/modals/hooks/use-handle-close-modal';
import { ShipmentActionsModalPropsDef } from './shipment-actions-modal.types';
import { useGetShipmentActionsModalHeader } from './hooks';
import { ShipmentActionsModalFooter } from './components/shipment-actions-modal-footer';
import { ShipmentActionsModalContent } from './components/shipment-actions-modal-content';
import { shipmentActionsModalAtoms } from './shipment-actions-modal.atoms';
import { useResetRecoilAtoms } from '../../../../hooks/use-reset-recoil-atoms';

export const ShipmentActionsModal = (props: ShipmentActionsModalPropsDef) => {
  const { onClose, order } = props;

  const { shipManually, shipNormally, ...atoms } = shipmentActionsModalAtoms;
  const resetAtoms = useResetRecoilAtoms({
    ...atoms,
    ...shipManually,
    ...shipNormally,
  });

  const { invalidateFetchOrderCache } = useInvalidateFetchOrderCache();

  const handleCloseModal = useHandleCloseModal({
    onClose,
    onUpdate: async () => {
      await invalidateFetchOrderCache();
    },
  });

  const header = useGetShipmentActionsModalHeader();

  useEffect(() => {
    return () => {
      resetAtoms();
    };
  }, []);

  return (
    <Modal
      footer={
        <ShipmentActionsModalFooter
          handleCloseModal={handleCloseModal}
          order={order}
        />
      }
      header={header}
      maxWidth={{ lg: '40rem', md: '100%' }}
      onClose={onClose}
    >
      <ShipmentActionsModalContent order={order} />
    </Modal>
  );
};
