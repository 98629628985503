import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { GetVersionResponse } from './get-version.types';
import { getVersion } from './get-version';

const getAdminVersionQueriesObj = getGenericQueryObj({
  groupName: 'fetch-admin-version',
  query: getVersion,
});

export const useGetAdminVersion = <TransformedData = GetVersionResponse>(
  props?: GenericFetchProps<never, GetVersionResponse, TransformedData>,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch admin version.',
    queryObj: getAdminVersionQueriesObj,
    ...props,
  });
};

export const useInvalidateGetAdminVersionCache = () => {
  const invalidateGetAdminVersionCache = useGetInvalidateQueryCache(
    getAdminVersionQueriesObj,
  );

  return { invalidateGetAdminVersionCache };
};
