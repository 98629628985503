import { getIntersectionObserverCallback } from './helpers/get-intersection-observer-callback';
import { MarkStickyElementsArgs } from './mark-sticky-elements.types';

/**
 * When each arg's `elementsToObserve` is recognized as sticky, the `dataAttrIsSticky` variable value is applied to that element.
 * @example
 * useEffect(() => {
 *   const elementsToMark = [document.querySelector('table')];
 *   const elementsToObserve = Array.from(
 *     document.querySelectorAll('tr td:first-child'),
 *   );
 *   const stickyTo = 'left';
 *
 *    const { cleanupUseEffect } = markStickyElements({
 *     attrToApply: `${dataAttrIsSticky}-${stickyTo}`,
 *     elementsToMark,
 *     elementsToObserve,
 *     stickyTo,
 *   });
 *
 *    return cleanupUseEffect;
 * }, [allColumns]);
 *
 * @source https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
 */
export const markStickyElements = (...args: MarkStickyElementsArgs) => {
  const watchResults = args.map((arg) => {
    const {
      attrToApply,
      elementsToMark: baseElementsToMark,
      elementsToObserve,
      root,
      stickyTo,
    } = arg;

    const elementsToMark = !!baseElementsToMark?.length
      ? baseElementsToMark
      : elementsToObserve;

    const callback = getIntersectionObserverCallback({
      attrToApply,
      elementsToMark,
      stickyTo,
    });

    const observer = new IntersectionObserver(callback, {
      root,
      threshold: [1],
    });

    return elementsToObserve.map((element) => {
      if (element) {
        observer.observe(element);
      }

      return {
        elementsToObserve,
        observer,
      };
    });
  });

  const cleanupUseEffect = () => {
    watchResults.forEach((arg) => {
      arg.forEach(({ elementsToObserve, observer }) => {
        elementsToObserve.forEach((element) => {
          if (element) {
            observer.unobserve(element);
          }
        });
      });
    });
  };

  return { cleanupUseEffect };
};
