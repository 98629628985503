import { Conditional } from 'gantri-components';
import { FC } from 'react';
import { DateWithUserDataCell } from '../../../../../../components/common/custom-data-cells/date-user-data-cell';
import { CellPartCreatedAtProps } from './cell-part-created-at.types';

export const CellPartCreatedAt: FC<CellPartCreatedAtProps> = (props) => {
  const {
    getValue,
    row: { original },
  } = props;

  const value = getValue();

  return (
    <Conditional condition={!!value}>
      <DateWithUserDataCell
        data={original.statusInfo}
        date={value}
        partId={original.id}
        type="Created"
        variant="days-since"
      />
    </Conditional>
  );
};
