import { Flex, Stack, Typography } from 'gantri-components';
import { Divider } from '../../../../../../../../components/divider';
import { pluralize } from '../../../../../../../../helpers/pluralize';
import { SingleStockLevelsProps } from './single-stock-levels.types';

export const SingleStockLevels = (props: SingleStockLevelsProps) => {
  const { stock } = props;
  const { projected, qtyToAdd, recommended } = stock;

  return (
    <Stack gap="x">
      <Flex justifyContent="space-between">
        <Typography text="Recommended EOH" />
        <Typography
          text={`${recommended} ${pluralize('stock', recommended)}`}
        />
      </Flex>
      <Divider />
      <Flex justifyContent="space-between">
        <Typography text="Projected EOH" />
        <Typography
          color={qtyToAdd > 0 ? 'alert' : undefined}
          text={`${projected} ${pluralize('stock', projected)}`}
        />
      </Flex>
      <Divider />
    </Stack>
  );
};
