import { useRecoilValue } from 'recoil';
import { pressRecordModalActions } from '../../press-record-modal.constants';
import { pressRecordModalAtoms } from '../../press-record-modal.atoms';
import { ActionFormContent } from '../action-form/action-form-content';
import { ActionDeleteContent } from '../action-delete/action-delete-content';

export const PressRecordModalContent = () => {
  const modalAction = useRecoilValue(pressRecordModalAtoms.modalAction);

  switch (modalAction) {
    case pressRecordModalActions.delete:
      return <ActionDeleteContent />;

    default:
      return <ActionFormContent />;
  }
};
