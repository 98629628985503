import { atom } from 'recoil';
import { ProductListRecord } from '../../../api/products/routes/get-all/get-all.types';

const atomKeyPrefix = 'SELECT_PRODUCT_FORM';

export const selectProductFormAtoms = {
  /** Value set on mount to match `showActiveProductsToggle`. */
  activeProductsOnly: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-active-products-only`,
  }),
  allProducts: atom<ProductListRecord[]>({
    default: [],
    key: `${atomKeyPrefix}-all-products`,
  }),
  filteredProducts: atom<ProductListRecord[]>({
    default: [],
    key: `${atomKeyPrefix}-filtered-products`,
  }),
};
