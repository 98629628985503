import {
  FiltersFetchRequestArgs,
  useTableFilters,
} from '../../components/common/table/hooks';
import {
  fetchPaginatedReferrals,
  FetchPaginatedReferralsArgs,
} from '../../api/referrals/routes';
import { ReferralsSortingField } from './referrals.types';
import { ReferralUser } from '../../api/referrals/referrals.types';
import { ReferralsFilter } from '../../components/dropdowns';
import {
  pageName,
  referralsFiltersDefaults,
  referralsPageAtoms,
} from '../../components/dropdowns/referrals-filter/referrals-filter.atoms';
import { sortOptions } from './referrals-constants';
import { useSpinner } from '../../hooks';
import { useNotification } from '../../hooks/useNotification';
import { useCancelReferralModal, useSendReward } from './components';

export const useReferrals = () => {
  const { onInterceptRequest } = useSpinner();
  const { notifyAxiosError } = useNotification();

  const fetchPaginatedData = async (
    args: FiltersFetchRequestArgs<
      FetchPaginatedReferralsArgs,
      ReferralsSortingField
    >,
  ) => {
    await onInterceptRequest(async () => {
      try {
        const {
          data: { resultsCount, users },
        } = await fetchPaginatedReferrals(args);

        setRecords(users);
        setTotalCount(resultsCount);
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch referrals.',
        });
      }
    });
  };

  const {
    currentPage,
    handleFetchRequest,
    setRecords,
    setTotalCount,
    ...tableProps
  } = useTableFilters<
    ReferralUser,
    FetchPaginatedReferralsArgs,
    ReferralsSortingField
  >({
    fetchRequest: fetchPaginatedData,
    filtersContent: <ReferralsFilter />,
    modalFiltersDetails: [
      {
        atom: referralsPageAtoms.filters.purchased,
        defaultValue: referralsFiltersDefaults.filters.purchased,
        payloadKey: 'purchased',
      },
    ],
    pageName,
    searchFilterDetails: {
      atom: referralsPageAtoms.search,
    },
    sortByFilterDetails: {
      atom: referralsPageAtoms.sortBy,
      defaultValue: referralsFiltersDefaults.sortBy,
    },
    sortOptions,
  });

  const { onShowCancelReferral } = useCancelReferralModal({
    onRefreshReferralList: () => {
      return handleFetchRequest({ page: currentPage });
    },
  });

  const { onShowConfirmation } = useSendReward({
    onRefreshReferralList: () => {
      return handleFetchRequest({ page: currentPage });
    },
  });

  return {
    ...tableProps,
    currentPage,
    handleFetchRequest,
    onShowCancelReferral,
    onShowConfirmation,
  };
};
