import { CellContext } from '@tanstack/react-table';
import { Box, Flex, Icon } from 'gantri-components';
import { useMemo } from 'react';
import { PartTemplateColorOverride } from '../../../../../../../../../../../../api/products/products.types';
import { ColorSwatchAndLabel } from '../../../../../../../../../../../../components/common/color-swatch-and-label';
import { ColorOverrideTooltipProps } from './color-override-tooltip.types';
import { StyledTable } from './color-override-tooltip.styles';

export const ColorOverrideTooltip = (props: ColorOverrideTooltipProps) => {
  const { colorOverrides } = props;

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'from',
        cell: ({
          getValue,
        }: CellContext<
          PartTemplateColorOverride,
          PartTemplateColorOverride['from']
        >) => {
          return <ColorSwatchAndLabel colorCode={getValue().code} />;
        },
        header: 'Product Color',
        size: 50,
      },
      {
        cell: () => {
          return (
            <Flex alignItems="center" height="100%">
              <Icon name="arrows:arrow_right" />
            </Flex>
          );
        },
        id: 'icon',
        size: 10,
      },
      {
        accessorKey: 'to',
        cell: ({
          getValue,
        }: CellContext<
          PartTemplateColorOverride,
          PartTemplateColorOverride['to']
        >) => {
          return <ColorSwatchAndLabel colorCode={getValue().code} />;
        },
        header: 'Part Color',
        size: 50,
      },
    ];
  }, []);

  return (
    <Box horizontalPadding="2x" verticalPadding="x">
      <StyledTable columns={columns} data={colorOverrides} />
    </Box>
  );
};
