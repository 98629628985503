import { useRecoilValue } from 'recoil';
import { shipmentActionsModalSteps } from '../../shipment-actions-modal.constants';
import { shipmentActionsModalAtoms } from '../../shipment-actions-modal.atoms';
import { ShipmentActionsModalContentPropsDef } from './shipment-actions-modal-content.types';
import { StepCancelShipmentContent } from '../step-cancel-shipment/cancel-shipment-content';
import { StepShipManuallyContent } from '../step-ship-manually/step-ship-manually-content';
import { StepShipNormallyContent } from '../step-ship-ship-normally/step-ship-normally-content';
import { StepShippingErrorContent } from '../step-shipping-error/shipping-error-content';

export const ShipmentActionsModalContent = (
  props: ShipmentActionsModalContentPropsDef,
) => {
  const { order } = props;

  const step = useRecoilValue(shipmentActionsModalAtoms.step);

  switch (step) {
    case shipmentActionsModalSteps.cancelShipment:
      return <StepCancelShipmentContent />;

    case shipmentActionsModalSteps.shipManually:
      return <StepShipManuallyContent order={order} />;

    case shipmentActionsModalSteps.shipNormally:
      return <StepShipNormallyContent order={order} />;

    case shipmentActionsModalSteps.shippingError:
      return <StepShippingErrorContent />;

    default:
      return null;
  }
};
