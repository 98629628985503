import { Conditional, Flex, Typography } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useRecoilValue } from 'recoil';
import { formatDate } from '../../../../helpers/formatter';
import { ReferralUser } from '../../../../api/referrals/referrals.types';
import { RewardCell } from '../../components';
import { isReferralCancellable } from '../../referrals.adapter';
import { MoreMenuOption } from '../../../../components/dropdowns/more-menu/more-menu.types';
import { MoreMenu } from '../../../../components/dropdowns';
import { UseGetReferralsColumnsProps } from './use-get-referrals-columns.types';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import routePaths from '../../../../config/route-paths';
import { userAtoms } from '../../../../global-atoms/users';
import { getIsClickableIfValue } from '../../../../helpers/get-is-clickable-if-value';

export const useGetReferralsColumns = (props: UseGetReferralsColumnsProps) => {
  const { onShowCancelReferral, onShowConfirmation } = props;

  const { type: role } = useRecoilValue(userAtoms.user);

  const columns: ColumnDef<ReferralUser>[] = [
    {
      accessorKey: 'name',
      cell: ({
        getValue,
        row,
      }: CellContext<ReferralUser, ReferralUser['name']>) => {
        const name = getValue()?.trim();

        return (
          <Conditional condition={!!name} Fallback={<Typography text="-" />}>
            <StyledAnchor
              href={`${routePaths.users}/${row.original.id}`}
              text={name}
            />
          </Conditional>
        );
      },
      header: 'Name',
      meta: {
        getIsClickable: getIsClickableIfValue,
      },
    },
    {
      accessorKey: 'joinDate',
      cell: ({
        getValue,
      }: CellContext<ReferralUser, ReferralUser['joinDate']>) => {
        const joinDate = getValue();

        return (
          <Conditional
            condition={!!joinDate}
            Fallback={<Typography text="-" />}
          >
            <Typography text={formatDate(joinDate)} />
          </Conditional>
        );
      },
      header: 'Join Date',
    },
    {
      accessorKey: 'purchaseDate',
      cell: ({
        getValue,
      }: CellContext<ReferralUser, ReferralUser['purchaseDate']>) => {
        const purchaseDate = getValue();

        return (
          <Conditional
            condition={!!purchaseDate}
            Fallback={<Typography text="-" />}
          >
            <Typography text={formatDate(purchaseDate)} />
          </Conditional>
        );
      },
      header: 'Purchase Date',
    },
    {
      accessorKey: 'referrer',
      cell: ({
        getValue,
        row,
      }: CellContext<ReferralUser, ReferralUser['referrer']>) => {
        return (
          <Conditional condition={!!row.original.name}>
            <StyledAnchor
              href={`${routePaths.users}/${row.original.referrerId}`}
              text={getValue()}
            />
          </Conditional>
        );
      },
      header: 'Referrer',
      meta: {
        getIsClickable: getIsClickableIfValue,
      },
    },
    {
      accessorKey: 'email',
      header: 'Referrer Email',
    },
    {
      accessorKey: 'rewardDate',
      cell: ({
        row,
      }: CellContext<ReferralUser, ReferralUser['rewardDate']>) => {
        return (
          <RewardCell
            referral={row.original}
            onShowConfirmation={onShowConfirmation}
          />
        );
      },
      header: 'Reward',
      size: 100,
    },
    {
      cell: ({ row }: CellContext<ReferralUser, never>) => {
        const options: MoreMenuOption<ReferralUser>[] = [
          role === 'Admin' && {
            enabled: isReferralCancellable,
            name: 'Cancel',
            onOptionClick: ({ id }) => {
              onShowCancelReferral(Number(id));
            },
          },
        ];

        return (
          <Flex justifyContent="flex-end">
            <MoreMenu data={row.original} options={options} />
          </Flex>
        );
      },
      id: 'menu',
      meta: { label: 'Actions' },
      size: 40,
    },
  ];

  return columns;
};
