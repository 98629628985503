import { Button, Modal, Stack, Typography, useModal } from 'gantri-components';
import { useState } from 'react';
import { bambuMachineTypes } from '../../../../constants/machines';
import { HandleConfirmMachinesUpdateArgsDef } from './use-confirm-machine-type-change-modal.types';

export const useConfirmMachineTypeChangeModal = () => {
  const [executeOnConfirm, setExecuteOnConfirm] = useState<() => Promise<void>>(
    async () => {},
  );

  const [showConfirmMachineTypeChangeModal, hideMachineTypeChangeModal] =
    useModal(() => {
      return (
        <Modal
          footer={
            <>
              <Button
                size="large"
                text="Cancel"
                variant="secondary"
                onClick={hideMachineTypeChangeModal}
              />
              <Button
                size="large"
                text="Save changes"
                onClick={async () => {
                  await executeOnConfirm();
                }}
              />
            </>
          }
          header="Confirm save"
          maxWidth={{ lg: '42rem', md: '100%' }}
          onClose={hideMachineTypeChangeModal}
        >
          <Stack>
            <Typography
              align="center"
              text="The machine type selected does not have the following fields: MAC Address, IP Address, and password."
            />
            <Typography
              align="center"
              text="Values for these fields will be cleared upon saving."
              textStyle="bold"
            />
          </Stack>
        </Modal>
      );
    }, [executeOnConfirm]);

  const handleConfirmMachinesUpdate = async (
    props: HandleConfirmMachinesUpdateArgsDef,
  ) => {
    const { machines, onConfirm } = props;

    const shouldConfirm = machines.some((machine) => {
      const { ipAddress, macAddress, password, type } = machine;

      const isBambuMachine = bambuMachineTypes.includes(type);

      if (!isBambuMachine && (!!ipAddress || !!macAddress || !!password)) {
        return true;
      }

      return false;
    });

    if (shouldConfirm) {
      setExecuteOnConfirm(() => {
        return async () => {
          hideMachineTypeChangeModal();

          await onConfirm();
        };
      });

      showConfirmMachineTypeChangeModal();
    } else {
      await onConfirm();
    }
  };

  return {
    /** If machine type is not Bambu and machine contains network fields, show a warning confirmation modal. */
    handleConfirmMachinesUpdate,
    showConfirmMachineTypeChangeModal,
  };
};
