import { useState } from 'react';
import { Job } from '../../../../../../api/jobs/jobs.types';
import { useTableFilters } from '../../../../../../components/common/table/hooks/use-table-filters';
import {
  pageName,
  partPageJobsFiltersDefaults,
  partPageJobsPageAtoms,
} from '../../part-jobs.atoms';
import {
  PartJobsCallbackArgs,
  PartJobsSortingField,
} from '../../part-jobs.types';
import { useSorting } from '../../../../../../hooks/use-sorting';
import { FiltersFetchRequestArgs } from '../../../../../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { getSortMapping } from '../../../../../../components/common/table/components/table-actions/helpers/get-sort-mapping';
import { sortOptions } from '../../part-jobs.constants';
import { getQuickViewJobs } from './helpers/get-quick-view-jobs';
import { UseGetPartJobsTableDataProps } from './use-get-part-jobs-table-data.types';

export const useGetPartJobsTableData = (
  props: UseGetPartJobsTableDataProps,
) => {
  const { isQuickViewEnabled, part } = props;

  const [quickViewJobs, setQuickViewJobs] = useState<Job[]>(part?.jobs);

  const handlePartJobsTableData = async (
    args: FiltersFetchRequestArgs<PartJobsCallbackArgs, PartJobsSortingField>,
  ) => {
    const { sortingField, sortingType } = args;

    setSortingField(sortingField);
    setSortingType(sortingType);

    if (isQuickViewEnabled) {
      const quickViewJobs = getQuickViewJobs({
        part,
      });

      setQuickViewJobs(quickViewJobs);
    } else {
      setRecords([...(part?.jobs || [])]);
    }
  };

  const { handleFetchRequest, records, setRecords, sortProps } =
    useTableFilters<Job, PartJobsCallbackArgs, PartJobsSortingField>(
      {
        fetchRequest: handlePartJobsTableData,
        pageName,
        sortByFilterDetails: {
          atom: partPageJobsPageAtoms.sortBy,
          defaultValue: partPageJobsFiltersDefaults.sortBy,
        },
        sortOptions,
      },
      [isQuickViewEnabled, part?.jobs],
    );

  const {
    setSortingField,
    setSortingType,
    sortedData: sortedJobs,
  } = useSorting({
    data: records,
    // @ts-ignore
    fieldsMapping: getSortMapping(sortOptions),
    initialSortingField: partPageJobsFiltersDefaults.sortBy.sortingField,
    initialSortingType: partPageJobsFiltersDefaults.sortBy.sortingType,
  });

  return {
    data: isQuickViewEnabled ? quickViewJobs : sortedJobs,
    handleFetchRequest,
    sortProps,
  };
};
