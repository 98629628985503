import { Stack } from 'gantri-components';
import Timeline from '../../../../components/common/timeline';
import { TimelineItem } from './components/timeline-item';
import { PartTimelineProps } from './part-timeline.types';
import { SectionTitle } from '../../../../components/layout/section-title';

export const PartTimeline = (props: PartTimelineProps) => {
  const { part } = props;

  return (
    <Stack gap="2x">
      <SectionTitle text="Timeline" />
      <Timeline
        isTitleVisible={false}
        items={part?.timelines || []}
        renderItem={TimelineItem}
      />
    </Stack>
  );
};
