import { useFormikContext } from 'formik';
import {
  Conditional,
  Dropdown,
  FormikInput,
  Stack,
  TextArea,
} from 'gantri-components';
import { range } from 'lodash';
import { DatePicker } from '../../../../../../../components/common/date-picker/date-picker';
import {
  pressRecordTypeItems,
  pressRecordTypes,
} from '../../../../../press.constants';
import {
  PressRecord,
  PressRecordType,
} from '../../../../../../../api/press/press.types';
import { Label } from '../../../../../../../components/label';
import { formatDateWithoutTimezone } from '../../../../../../../helpers/formatter';
import { MEDIUM_FORMAT } from '../../../../../../../constants/dates';

export const ActionFormContent = () => {
  const { setFieldValue, values } = useFormikContext<PressRecord>();

  const getIsAward = (type: PressRecordType) => {
    return type === pressRecordTypes.award;
  };

  const isAward = getIsAward(values?.type);
  const currentYear = new Date().getFullYear();
  const awardDateItems = range(currentYear, 2017).map((year) => {
    const value = String(year);

    return {
      label: value,
      value,
    };
  });

  return (
    <Stack>
      <FormikInput
        Field={
          <Dropdown
            items={pressRecordTypeItems}
            labelText="Type"
            placeholder="Select a type..."
            value={values?.type}
            onSelect={(item: typeof pressRecordTypeItems[number]) => {
              const type = item?.value;
              const isAward = getIsAward(type);
              const currentDate = values.publishDate;
              const date = new Date(currentDate || Date.now());

              if (isAward) {
                date.setMonth(0);
                date.setDate(1);
              }

              const publishDate = formatDateWithoutTimezone(
                date,
                MEDIUM_FORMAT,
              );

              setFieldValue('type', type);
              setFieldValue('publishDate', publishDate);
            }}
          />
        }
        fieldVariant="standard"
        name="type"
      />

      <FormikInput
        Field={
          <Stack gap=".5x">
            <Label text="Date" />
            <Conditional
              condition={isAward}
              Fallback={
                <DatePicker
                  initialValue={{ value: values?.publishDate }}
                  range={false}
                  value={values?.publishDate}
                  onDateChanged={(value) => {
                    const { formattedValue } = value;

                    setFieldValue('publishDate', formattedValue);
                  }}
                />
              }
            >
              <Dropdown
                autoSelectFirst
                items={awardDateItems}
                placeholder="Select a date..."
                value={formatDateWithoutTimezone(values?.publishDate, 'yyyy')}
                onSelect={(item) => {
                  const year = item?.value;

                  const date = new Date(Date.now());

                  date.setMonth(0);
                  date.setDate(1);
                  date.setFullYear(year);

                  const publishDate = formatDateWithoutTimezone(date);

                  setFieldValue('publishDate', publishDate);
                }}
              />
            </Conditional>
          </Stack>
        }
        fieldVariant="standard"
        name="publishDate"
      />

      <FormikInput
        Field={
          <TextArea
            labelText="Source"
            placeholder="Enter the source..."
            value={values?.source}
          />
        }
        fieldVariant="standard"
        name="source"
      />

      <FormikInput
        Field={
          <TextArea
            labelText="Title"
            placeholder="Enter the title..."
            value={values?.title}
          />
        }
        fieldVariant="standard"
        name="title"
      />

      <FormikInput
        Field={
          <TextArea
            labelText="Link"
            placeholder="Enter the link..."
            value={values?.link}
          />
        }
        fieldVariant="standard"
        name="link"
      />
    </Stack>
  );
};
