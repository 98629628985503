import { Modal } from 'gantri-components';
import { useState } from 'react';
import { useAsync } from 'react-use';
import { partsApi } from '../../../../api';
import { JobDetails } from '../../../../api/jobs/routes/get-job-details/get-job-details.types';
import { RecommendedStock } from '../../../../api/parts/routes/get-recommended-stocks/get-recommended-stocks.types';
import { useNotification } from '../../../../hooks/useNotification';
import { FullScreenModalHeader } from '../../../jobs/components/modals/common/full-screen-modal-header';
import { JobDetailsPanelLarge } from '../../../jobs/components/modals/common/job-details-panel-large';
import { Content } from './components/content';
import { Footer } from './components/footer';
import {
  PartSwitchingModalProps,
  PartSwitchingModalStep,
} from './part-switching-modal.types';

export const PartSwitchingModal = (props: PartSwitchingModalProps) => {
  const { onClose, onUpdatePart, part } = props;

  const [recommendedStocks, setRecommendedStocks] = useState<
    RecommendedStock[]
  >([]);
  const [step, setStep] = useState<PartSwitchingModalStep>(
    'PART_SWITCHING_TABLE',
  );
  const [selectedStock, setSelectedStock] = useState<RecommendedStock>();

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const handleCloseModal = async () => {
    onClose();

    if (step === 'PART_SWITCH_COMPLETE') {
      await onUpdatePart(true);
    }
  };

  const job = {
    part: { ...part, color: part?.product.color },
    product: {
      ...part?.product,
      name: part?.product.name,
    },
    stockId:
      step === 'PART_SWITCH_COMPLETE' ? selectedStock?.stockId : part?.stockId,
    versionInfo: part.versionInfo,
  } as unknown as JobDetails; /* Intentionally using "as" since most of the job data will be unused and does not need to be filled with placeholders */

  useAsync(async () => {
    try {
      showLoading();

      const response = await partsApi.getRecommendedStocks(part?.id);
      const { currentStock, recommendedStocks = [] } = response.data;

      if (recommendedStocks.length) {
        const currentStockData: RecommendedStock = {
          partId: part?.id,
          ...currentStock,
        };
        const allStocks = [currentStockData, ...recommendedStocks];
        const selectedStock = allStocks.find((stock) => {
          return stock.recommended;
        });

        setSelectedStock(selectedStock);
        setRecommendedStocks(allStocks);
      }
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to fetch recommended stocks.',
      });
    } finally {
      hideLoading();
    }
  }, []);

  return (
    <Modal
      closeable={false}
      detailsPanel={
        <JobDetailsPanelLarge
          job={job}
          paddingTop="x"
          sectionsToShow={[
            'part',
            'color & material',
            'product',
            'instructions',
            'part diagram',
          ]}
        />
      }
      detailsPanelWidth="1fr"
      footer={
        <Footer
          currentPartId={part?.id}
          recommendedStocks={recommendedStocks}
          setStep={setStep}
          step={step}
          switchToPartId={selectedStock?.partId}
          onClose={onClose}
          onUpdatePart={onUpdatePart}
        />
      }
      header={
        <FullScreenModalHeader
          handleCloseModal={handleCloseModal}
          text="Part Switching"
        />
      }
      isFullScreen
    >
      <Content
        part={part}
        recommendedStocks={recommendedStocks}
        selectedStock={selectedStock}
        setSelectedStock={setSelectedStock}
        step={step}
      />
    </Modal>
  );
};
