import { Button } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { publishDraftModalAtoms } from '../../../publish-draft-modal.atoms';
import { useNotification } from '../../../../../../../../../../../hooks/useNotification';
import { versionApi } from '../../../../../../../../../../../api';
import { ConfirmPublishFooterProps } from './confirm-publish-footer.types';
import { useInvalidateFetchProductCache } from '../../../../../../../../../../../api/products/routes';

export const ConfirmPublishFooter = (props: ConfirmPublishFooterProps) => {
  const { onCloseModal, product } = props;

  const setStep = useSetRecoilState(publishDraftModalAtoms.step);
  const draftDetails = useRecoilValue(publishDraftModalAtoms.draftDetails);

  const { notify, notifyAxiosError, onInterceptRequest } = useNotification();

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();

  const publishDraft = async () => {
    await onInterceptRequest(async () => {
      try {
        await versionApi.publishVersion({
          description: draftDetails,
          productId: product.id,
          type: product.version.type,
        });

        notify(
          `Successfully published v${product.version.version} of ${product.name}.`,
        );

        onCloseModal();

        await invalidateFetchProductCache();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Error occurred publishing new version.',
        });
      }
    });
  };

  return (
    <>
      <Button
        size="large"
        text="Back"
        variant="secondary"
        onClick={() => {
          setStep('DRAFT_DETAILS');
        }}
      />
      <Button size="large" text="Confirm" onClick={publishDraft} />
    </>
  );
};
