import { Conditional, Icon, Stack, Typography } from 'gantri-components';
import { message } from './vlm-notified-message.constants';
import { VlmNotifiedMessageProps } from './vlm-notified-message.types';

export const VlmNotifiedMessage = ({
  vlmNotified,
}: VlmNotifiedMessageProps) => {
  return (
    <Stack alignContent="center" gap="0.8rem" justifyItems="center">
      <Conditional
        condition={vlmNotified}
        Fallback={
          <>
            <Icon
              color="warning"
              name="alert:warning_triangle_24"
              size="2.4rem"
            />
            <Typography color="warning" text={message.failure} />
            <Typography color="warning" text={message.completeManually} />
          </>
        }
      >
        <Icon
          color="success"
          name="ui-control:check_mark_circle_filled_24"
          size="2.4rem"
        />
        <Typography color="success" text={message.success} />
      </Conditional>
    </Stack>
  );
};
