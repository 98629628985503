import {
  jobStatuses,
  jobSteps,
  jobTypeOptions,
} from '../../../../constants/options';
import { formatDate } from '../../../../helpers/formatter';
import { Job } from '../../jobs.types';
import { getPaginatedJobs } from '../get-paginated-jobs';
import {
  FetchSandingMinutesArgs,
  FetchSandingMinutesResponse,
} from './fetch-sanding-minutes.types';

export const fetchSandingMinutes = async ({
  userId,
}: FetchSandingMinutesArgs): Promise<FetchSandingMinutesResponse> => {
  const today = formatDate(Date.now());

  const { jobs, success } = await getPaginatedJobs({
    completedDateRange: {
      from: today,
      to: today,
    },
    count: 250,
    queries: { request: 'fetch-sanding-minutes' },
    sortBy: 'End Date',
    statuses: [jobStatuses.failed, jobStatuses.completed],
    steps: [jobSteps.finish],
    types: [
      jobTypeOptions.sandRaw,
      jobTypeOptions.sandPrimed,
      jobTypeOptions.sandPainted,
    ],
    userIds: [userId],
    userStatuses: [jobStatuses.failed, jobStatuses.completed],
  }).then(({ data }) => {
    return data;
  });

  const [lastJob] = jobs;

  const lastCompletedJobDuration = getCalculatedJobDuration(lastJob);

  const sandingMinutes = jobs.reduce((accumulator, job) => {
    const calculatedJobDuration = getCalculatedJobDuration(job);

    return accumulator + calculatedJobDuration;
  }, 0);

  return {
    jobsCompleted: jobs.length,
    lastCompletedJobDuration,
    sandingMinutes,
    success,
  };
};

/** If status is Failed, returns the actual duration if less than the expected duration, otherwise returns the expected duration. Value is in minutes. */
const getCalculatedJobDuration = (job: Job) => {
  if (job) {
    const { duration, endDate, startDate, status } = job;

    const expectedDuration = Number(duration);

    if (status === jobStatuses.failed) {
      const actualDurationMs = Number(endDate) - Number(startDate);

      const actualDurationMinutes = Math.round(actualDurationMs / 1000 / 60);

      if (actualDurationMinutes < expectedDuration) {
        return actualDurationMinutes;
      }
    }

    return expectedDuration;
  }

  return 0;
};
