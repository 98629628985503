import { CellContext, ColumnDef } from '@tanstack/react-table';
import {
  ProductReviewStatus,
  UserNPSReview,
  UserProductReview,
} from '../../../api/users/routes';
import { StyledAnchor } from '../../../components/common/styled-anchor';
import routePaths from '../../../config/route-paths';
import { formatDate } from '../../../helpers/formatter';
import { getIsClickableIfValue } from '../../../helpers/get-is-clickable-if-value';
import { ReviewBadgeStatus } from './components/badge-status/badge-status';
import { ShortProductSummary } from '../../../components/common/short-product-summary';

export const useProductColumns = [
  {
    accessorKey: 'status',
    cell: ({ getValue }) => {
      const status = getValue() as ProductReviewStatus;

      return <ReviewBadgeStatus status={status} />;
    },
    header: 'Review Status',
  },
  {
    accessorKey: 'rating',
    header: 'Product Rating (1-5)',
  },
  {
    accessorKey: 'lastDate',
    cell: ({ getValue }) => {
      const date = getValue();

      return formatDate(date, 'MM/dd/yyyy');
    },
    header: 'Last Date',
  },
  {
    accessorKey: 'product',
    cell: ({
      getValue,
    }: CellContext<UserProductReview, UserProductReview['product']>) => {
      const product = getValue();

      return <ShortProductSummary {...product} />;
    },
    header: 'Product',
  },
  {
    accessorKey: 'category',
    cell: ({ row }) => {
      return row.original.product.category;
    },
    header: 'Product Category',
  },
] as ColumnDef<Partial<UserProductReview>>[];

export const npsColumns = [
  {
    accessorKey: 'status',
    cell: ({ getValue }) => {
      const status = getValue() as ProductReviewStatus;

      return <ReviewBadgeStatus status={status} />;
    },
    header: 'Review Status',
  },
  {
    accessorKey: 'rating',
    header: 'NPS Rating (1-10)',
  },
  {
    accessorKey: 'lastDate',
    cell: ({ getValue }) => {
      const date = getValue();

      return formatDate(date, 'MM/dd/yyyy');
    },
    header: 'Last Date',
  },
  {
    accessorKey: 'type',
    header: 'NPS Type',
  },
  {
    accessorKey: 'orderId',
    cell: ({ getValue }) => {
      const orderId = getValue() as string;

      if (!orderId) return '';

      return (
        <StyledAnchor
          text={`#${orderId}`}
          to={`${routePaths.orders}/${orderId}`}
        />
      );
    },
    header: 'Order #',
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
  },
] as ColumnDef<Partial<UserNPSReview>>[];
