import { useRecoilValue } from 'recoil';
import { UploadFileResponse } from '../../../../../../../../../../../../../../api/cloudinary/routes/upload-file/upload-file.types';
import { bulkUploadModalAtoms } from '../../../../../../bulk-upload-modal.atoms';
import {
  AssetsBySku,
  SkuLevelAssetData,
} from '../../../../../../../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';
import { useHandleUploadCloudinaryAsset } from './hooks/use-handle-upload-cloudinary-asset';
import { useHandleBulkUploadUsdz } from './hooks/use-handle-bulk-upload-usdz';

export const useHandleBulkUploadAssets = () => {
  const assetsBySku = useRecoilValue(bulkUploadModalAtoms.assetsBySku);

  const handleUploadCloudinaryAsset = useHandleUploadCloudinaryAsset();

  const handleBulkUploadUsdz = useHandleBulkUploadUsdz();

  const handleBulkUploadAssets = async () => {
    let numUploaded = 0;

    const assetsBySkuWithUploadData = structuredClone(assetsBySku);

    await Promise.all(
      Object.values(assetsBySku).map(
        async ({
          dimmingPhotoDark,
          dimmingPhotoLight,
          scalePhoto,
          sku,
          usdz,
          whiteBackgroundPhotos,
        }): Promise<UploadFileResponse[]> => {
          const uploadResponses: UploadFileResponse[] = [];

          if (dimmingPhotoDark?.fileBlob) {
            const response = await handleUploadCloudinaryAsset({
              fileData: dimmingPhotoDark,
              fileType: 'dimming-photos',
              sku,
            });

            numUploaded += 1;

            assetsBySkuWithUploadData[sku].dimmingPhotoDark = response;

            uploadResponses.push(response);
          }

          if (dimmingPhotoLight?.fileBlob) {
            const response = await handleUploadCloudinaryAsset({
              fileData: dimmingPhotoLight,
              fileType: 'dimming-photos',
              sku,
            });

            numUploaded += 1;

            assetsBySkuWithUploadData[sku].dimmingPhotoLight = response;

            uploadResponses.push(response);
          }

          if (
            whiteBackgroundPhotos.some(({ fileBlob }) => {
              return !!fileBlob;
            })
          ) {
            const filesToUpload = whiteBackgroundPhotos.filter(
              ({ fileBlob }) => {
                return fileBlob;
              },
            );

            const responses = await Promise.all(
              filesToUpload.map(async (fileData) => {
                return handleUploadCloudinaryAsset({
                  fileData,
                  fileType: 'product-photos',
                  sku,
                });
              }),
            );

            numUploaded += responses.length;

            const existingAssets = assetsBySkuWithUploadData[
              sku
            ].whiteBackgroundPhotos.filter(({ fileBlob }) => {
              return !fileBlob;
            });

            const updatedWhiteBackgroundPhotos = [
              ...existingAssets,
              ...responses,
            ];

            assetsBySkuWithUploadData[sku].whiteBackgroundPhotos =
              updatedWhiteBackgroundPhotos;

            const selectedPhotoMatchesAsset = updatedWhiteBackgroundPhotos.some(
              ({ fileName }) => {
                return (
                  fileName ===
                  assetsBySkuWithUploadData[sku].selectedWhiteBackgroundPhoto
                );
              },
            );

            if (!selectedPhotoMatchesAsset) {
              assetsBySkuWithUploadData[sku].selectedWhiteBackgroundPhoto =
                updatedWhiteBackgroundPhotos[0].fileName;
            }

            uploadResponses.push(...responses);
          }

          if (scalePhoto?.fileBlob) {
            const response = await handleUploadCloudinaryAsset({
              fileData: scalePhoto,
              fileType: 'scale-photo',
              sku,
            });

            numUploaded += 1;

            assetsBySkuWithUploadData[sku].scalePhoto = response;

            uploadResponses.push(response);
          }

          if (usdz?.fileBlob) {
            const response = await handleBulkUploadUsdz({ sku, usdz });

            numUploaded += 1;

            assetsBySkuWithUploadData[sku].usdz = response;

            uploadResponses.push(response);
          }

          return uploadResponses;
        },
      ),
    );

    const skuAssets = Object.keys(
      assetsBySkuWithUploadData,
    ).reduce<AssetsBySku>((accumulator, sku) => {
      const data: SkuLevelAssetData = {
        dimmingPhotoDark:
          assetsBySkuWithUploadData[sku].dimmingPhotoDark?.fileName,
        dimmingPhotoLight:
          assetsBySkuWithUploadData[sku].dimmingPhotoLight?.fileName,
        scalePhoto: assetsBySkuWithUploadData[sku].scalePhoto?.fileName,
        selectedWhiteBackgroundPhoto:
          assetsBySkuWithUploadData[sku].selectedWhiteBackgroundPhoto,
        usdz: assetsBySkuWithUploadData[sku].usdz,
        whiteBackgroundPhotos: assetsBySkuWithUploadData[
          sku
        ].whiteBackgroundPhotos.map(({ fileName }) => {
          return fileName;
        }),
      };

      return { ...accumulator, [sku]: data };
    }, {});

    return { numUploaded, skuAssets };
  };

  return handleBulkUploadAssets;
};
