import { Flex, Conditional, Typography } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { formatDate } from '../../../../helpers/formatter';
import { InviteData } from '../../../../api/designers/routes/fetch-invites/fetch-invites.types';
import { MoreMenu } from '../../../../components/dropdowns';
import { MoreMenuOption } from '../../../../components/dropdowns/more-menu/more-menu.types';
import { useNotification } from '../../../../hooks/useNotification';
import { designersApi } from '../../../../api';

export const useGetDesignerInvitesColumns = ({
  refetchPaginatedInvites,
}: {
  refetchPaginatedInvites: () => Promise<void>;
}) => {
  const { notify, notifyAxiosError, onInterceptRequest } = useNotification();

  const resendDesignerInvite = async ({ id }: InviteData) => {
    await onInterceptRequest(async () => {
      try {
        const { data } = await designersApi.resendInvite(id);

        notify(data.notice);
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to resend designer invite.',
        });
      }
    });
  };

  const deleteDesignerInvite = async ({ id }: InviteData) => {
    await onInterceptRequest(async () => {
      try {
        const { data } = await designersApi.deleteInvite(id);

        notify(data.notice);

        await refetchPaginatedInvites();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to delete designer invite.',
        });
      }
    });
  };

  const columns: ColumnDef<InviteData>[] = [
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'email',
      header: 'Email',
    },
    {
      accessorKey: 'invitedByName',
      header: 'Invited by',
    },
    {
      accessorKey: 'invitedDateSec',
      cell: ({
        getValue,
      }: CellContext<InviteData, InviteData['invitedDateSec']>) => {
        return formatDate(getValue());
      },
      header: 'Invite Date',
    },
    {
      accessorKey: 'joinDateSec',
      cell: ({
        getValue,
      }: CellContext<InviteData, InviteData['joinDateSec']>) => {
        const joinDateSec = getValue();

        return (
          <Conditional
            condition={!!joinDateSec}
            Fallback={<Typography text="-" />}
          >
            <Typography text={formatDate(joinDateSec)} />
          </Conditional>
        );
      },
      header: 'Join date',
    },
    {
      cell: ({ row }: CellContext<InviteData, never>) => {
        const options: MoreMenuOption<InviteData>[] = [
          {
            enabled: true,
            name: 'Resend',
            onOptionClick: resendDesignerInvite,
          },
          {
            enabled: true,
            name: 'Delete',
            onOptionClick: deleteDesignerInvite,
          },
        ];

        return (
          <Conditional condition={!row.original.joinDate}>
            <Flex justifyContent="flex-end">
              <MoreMenu data={row.original} options={options} />
            </Flex>
          </Conditional>
        );
      },
      id: 'menu',
      meta: { label: 'Actions' },
      size: 40,
    },
  ];

  return columns;
};
