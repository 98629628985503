import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { Vendor } from '../../vendors.types';

type CreateVendorArgs = Pick<
  Vendor,
  'name' | 'contact' | 'email' | 'city' | 'state' | 'country'
>;

interface CreateVendorResponse {
  notice: string;
  success: boolean;
  vendor: Vendor;
}

export const createVendor = (body: CreateVendorArgs) => {
  return axios.post<CreateVendorResponse>(`${coreApiUrl}/vendors/create`, body);
};
