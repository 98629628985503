import { useRecoilValue } from 'recoil';
import { generateSku } from 'gantri-components';
import { useNotification } from '../../../../hooks/useNotification';
import { useRouter } from '../../../../hooks';
import { useInvalidateInventoriesQueryCache } from '../../../../api/inventories/routes/queries-cache';
import routePaths from '../../../../config/route-paths';
import { newStocksAtoms } from '../../new-stocks.atoms';
import { useResetRecoilAtoms } from '../../../../hooks/use-reset-recoil-atoms';
import { CreateStocksArgs } from '../../../../api/stocks/routes/create-stocks/create-stocks.types';
import { useInvalidateFetchStockSummaryCache } from '../../../../api/stocks/routes/fetch-stock-summary/fetch-stock-summary.query';
import { useCreateStocks } from '../../../../api/stocks/routes/create-stocks/create-stocks.query';
import { useInvalidateGetAllProductsCache } from '../../../../api/products/routes';

export const useHandleCreateNewStocks = () => {
  const newStocks = useRecoilValue(newStocksAtoms.newStocks);
  const resetNewStocksTable = useResetRecoilAtoms(newStocksAtoms);

  const { navigate } = useRouter();

  const { onInterceptRequest } = useNotification();

  const { invalidateAllInventoriesCache } =
    useInvalidateInventoriesQueryCache();

  const { invalidateGetAllProductsCache } = useInvalidateGetAllProductsCache();

  const { invalidateFetchStockSummaryCache } =
    useInvalidateFetchStockSummaryCache();

  const { onCreateStocks } = useCreateStocks({
    onSuccess: async (data) => {
      const { newStockIds } = data;

      const onlyOneStockCreated = newStockIds.length === 1;

      if (onlyOneStockCreated) {
        const [stockId] = newStockIds;

        navigate(`${routePaths.stocks}/${stockId}`);
      } else {
        navigate(routePaths.stocks);
      }

      resetNewStocksTable();

      await Promise.all([
        invalidateAllInventoriesCache(),
        invalidateGetAllProductsCache(),
        invalidateFetchStockSummaryCache(),
      ]);
    },
  });

  const handleCreateNewStocks = async () => {
    await onInterceptRequest(async () => {
      const args: CreateStocksArgs = newStocks.map((stock) => {
        const sku = generateSku({
          color: stock.colorCode,
          productId: stock.productId,
          size: stock.sizeCode,
          variants: stock.variantCodes,
        });

        return {
          quantity: stock.quantity,
          sku,
        };
      });

      await onCreateStocks(args);
    });
  };

  return handleCreateNewStocks;
};
