import { PartJobsSortingField } from './part-jobs.types';
import { TableSortField } from '../../../../components/common/table/table.props';

export const sortOptions: TableSortField<PartJobsSortingField>[] = [
  {
    label: 'Default job template',
    sortingField: '',
  },
  {
    bidirectional: true,
    label: 'Started date',
    sortingField: 'startDate',
  },
  {
    bidirectional: true,
    label: 'Ended date',
    sortingField: 'endDate',
  },
  {
    bidirectional: true,
    label: 'Created date',
    sortingField: 'createdAt',
  },
];
