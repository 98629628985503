import { useEffect, useReducer } from 'react';
import {
  Box,
  Button,
  Conditional,
  Tab,
  TabPanel,
  Tabs,
  TabsList,
} from 'gantri-components';
import MetaData from '../../components/meta-data';
import {
  DesignerIntegrations,
  DesignerPayPal,
  Handouts,
  Profile,
  Stats,
  Storefront,
} from './components';
import { DesignerData, DesignerTab } from './designer.types';
import { PageHeading } from '../../components/layout/page-heading';
import { currentDesignerInit, DesignerReducer } from './designer.constants';
import { useSyncPropertyWithQueryString } from '../../hooks/useSyncPropertyWithQueryString';
import { useRouter } from '../../hooks';
import {
  fetchPaginatedDesigners,
  getDesigner,
} from '../../api/designers/routes';
import { useGetInvalidateQueryCache } from '../../hooks/use-fetch/use-query-fetch';
import { useGetDesigner } from '../../api/designers/routes/get-designer/get-designer.query';
import { useUpdateDesigner } from '../../api/designers/routes/update-designer/update-designer.query';
import { useCreateDesignerHandout } from '../../api/designers/routes/create-handout/create-handout.query';
import { createHandoutHtml } from './components/handouts/components/handout/helpers/create-handout-html';
import { useDownload } from '../../hooks/use-download';

export const Designer = () => {
  const { params } = useRouter();

  const userId = +params.id;

  const [currentDesigner, updateDesignerInfo] = useReducer(
    DesignerReducer,
    currentDesignerInit,
  );

  const { currentValue, updateQueryString } =
    useSyncPropertyWithQueryString<DesignerTab>('tab', 'profile');

  const invalidatePaginatedDesignersQueryCache = useGetInvalidateQueryCache(
    fetchPaginatedDesigners,
  );

  const { isLoading: isProcessingDesigner, onUpdateDesigner } =
    useUpdateDesigner();

  const { isLoading: isProcessingHandout, onCreateDesignerHandout } =
    useCreateDesignerHandout();

  const { downloadSingleFile } = useDownload();

  const updateProfile = async (args?: {
    data?: Partial<DesignerData>;
    downloadHandoutPdf?: boolean;
  }) => {
    const { data = {}, downloadHandoutPdf = false } = args || {};

    const designerInfo: DesignerData = { ...currentDesigner, ...data };

    const handleDownloadHandout = async ({ link }: { link: string }) => {
      if (link && downloadHandoutPdf) {
        const isValidLink = link.includes('https');

        if (isValidLink) {
          await downloadSingleFile({
            fileName: `${currentDesigner.name}-handout.pdf`,
            url: link,
          });
        }
      }
    };

    await onUpdateDesigner(
      { designerInfo },
      {
        onSuccess: async () => {
          const htmlHandout = createHandoutHtml(designerInfo, true);

          await onCreateDesignerHandout(
            { designerId: userId, htmlHandout },
            { onSuccess: handleDownloadHandout },
          );

          await invalidateDesignerQueryCache();
          await invalidatePaginatedDesignersQueryCache();
        },
      },
    );
  };

  const { designer, isLoading: isFetchingDesigner } = useGetDesigner({
    id: userId,
  });

  const designerName =
    [currentDesigner.firstName, currentDesigner.lastName]
      .filter(Boolean)
      .join(' ') || currentDesigner.name;

  const invalidateDesignerQueryCache = useGetInvalidateQueryCache(getDesigner);

  useEffect(() => {
    if (!isFetchingDesigner) {
      const designerData = {
        ...designer,
        QAs: designer?.QAs || [],
      };

      updateDesignerInfo({ type: 'state', value: designerData });
    }
  }, [isFetchingDesigner]);

  return (
    <>
      <MetaData title={`Designer #${userId}`} />

      <Conditional condition={!!currentDesigner.id}>
        <Box marginBottom="6rem">
          <PageHeading subTitle={currentDesigner.email} title={designerName}>
            <Button
              processing={isProcessingDesigner || isProcessingHandout}
              text="Update profile"
              onClick={
                /* long-hand is intentional to prevent event data from being passed to function */
                () => {
                  return updateProfile();
                }
              }
            />
          </PageHeading>

          <Box marginTop="1rem">
            <Tabs value={currentValue}>
              <TabsList
                onValueChange={(value: DesignerTab) => {
                  updateQueryString(value);
                }}
              >
                <Tab label="Profile" value="profile" />
                <Tab label="Storefront" value="storefront" />
                <Tab label="PayPal" value="paypal" />
                <Tab label="Stats" value="stats" />
                <Tab label="Integrations" value="integrations" />
                <Tab label="Handouts" value="handouts" />
              </TabsList>

              <TabPanel value="profile">
                <Profile
                  designerInfo={currentDesigner}
                  updateDesignerInfo={updateDesignerInfo}
                  onUpdateProfile={(data) => {
                    return updateProfile({ data, downloadHandoutPdf: false });
                  }}
                />
              </TabPanel>

              <TabPanel value="storefront">
                <Storefront
                  currentDesigner={currentDesigner}
                  updateDesignerInfo={updateDesignerInfo}
                />
              </TabPanel>

              <TabPanel value="paypal">
                <DesignerPayPal
                  designerInfo={currentDesigner}
                  updateDesignerInfo={updateDesignerInfo}
                />
              </TabPanel>

              <TabPanel value="stats">
                <Stats currentDesigner={currentDesigner} />
              </TabPanel>

              <TabPanel value="integrations">
                <DesignerIntegrations
                  designerInfo={currentDesigner}
                  updateDesignerInfo={updateDesignerInfo}
                />
              </TabPanel>

              <TabPanel value="handouts">
                <Handouts
                  designerInfo={currentDesigner}
                  updateDesignerInfo={updateDesignerInfo}
                  onProfileSave={(data) => {
                    return updateProfile({ data, downloadHandoutPdf: true });
                  }}
                />
              </TabPanel>
            </Tabs>
          </Box>
        </Box>
      </Conditional>
    </>
  );
};
