import {
  Button,
  Cell,
  Conditional,
  Grid,
  Stack,
  Typography,
} from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formatDate } from '../../../../../../../../helpers/formatter';
import { LONG_FORMAT } from '../../../../../../../../constants/dates';
import { MoreMenu } from '../../../../../../../../components/dropdowns';
import { PublishedVersionHeadingProps } from './published-version-heading.types';
import { productJobsTabAtoms } from '../../../../product-jobs.atoms';

export const PublishedVersionHeading = (
  props: PublishedVersionHeadingProps,
) => {
  const { currentProduct, productVersion, showNewVersionModal } = props;

  const { date, status, version } = productVersion || {};
  const isLatestPublishedVersion = status === 'Published';

  const setVersionId = useSetRecoilState(productJobsTabAtoms.versionId);
  const draftVersionId = useRecoilValue(productJobsTabAtoms.draftVersionId);

  const handleViewDraft = () => {
    setVersionId(draftVersionId);
  };

  const hasDraft = currentProduct.version?.hasDraft;

  return (
    <Grid alignItems="center" columns="1fr max-content" gap="s1">
      <Stack gap="0.4rem">
        <Conditional
          condition={isLatestPublishedVersion}
          Fallback={
            <Typography text={`Past version v${version}`} variant="h5" />
          }
        >
          <Typography text={`Current version v${version}`} variant="h5" />
        </Conditional>
        <Typography
          text={`Published ${formatDate(date, LONG_FORMAT)}`}
          variant="p3"
        />
      </Stack>
      <Cell hidden={{ lg: false, sm: true }}>
        <Conditional
          condition={hasDraft}
          Fallback={
            <Button
              text="Create new version"
              variant="secondary"
              onClick={showNewVersionModal}
            />
          }
        >
          <Button
            text="View draft"
            variant="secondary"
            onClick={handleViewDraft}
          />
        </Conditional>
      </Cell>
      <Cell hidden={{ lg: true, sm: false }}>
        <MoreMenu
          options={[
            hasDraft
              ? {
                  enabled: true,
                  name: 'View draft',
                  onOptionClick: handleViewDraft,
                }
              : {
                  enabled: true,
                  name: 'Create new version',
                  onOptionClick: showNewVersionModal,
                },
          ]}
        />
      </Cell>
    </Grid>
  );
};
