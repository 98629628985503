import { Job } from '../../../../../../api/jobs/jobs.types';
import { JobStatus, jobStatuses } from '../../../../../../constants/options';

/** In this case, "completed" meaning the job has been passed or failed. */
export const completedJobStatuses: JobStatus[] = [
  jobStatuses.completed,
  jobStatuses.failed,
];

/** In this case, "completed" meaning the job has been passed or failed. */
export const getIsCompletedJob = ({ status }: Job): boolean => {
  return completedJobStatuses.some((jobStatus) => {
    return jobStatus === status;
  });
};
