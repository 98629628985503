import { useRecoilValue } from 'recoil';
import { publishDraftModalAtoms } from '../../publish-draft-modal.atoms';
import { MissingItemsFooter } from '../step-missing-items/missing-items-footer';
import { DraftDetailsFooter } from '../step-draft-details/draft-details-footer';
import { ConfirmPublishFooter } from '../step-confirm-publish/confirm-publish-footer';
import { PublishDraftModalFooterProps } from './publish-draft-modal-footer.types';

export const PublishDraftModalFooter = (
  props: PublishDraftModalFooterProps,
) => {
  const { onCloseModal, product, showExitModal } = props;

  const step = useRecoilValue(publishDraftModalAtoms.step);

  switch (step) {
    case 'MISSING_ITEMS':
      return <MissingItemsFooter onCloseModal={showExitModal} />;

    case 'DRAFT_DETAILS':
      return <DraftDetailsFooter onCloseModal={showExitModal} />;

    case 'CONFIRM_PUBLISH':
      return (
        <ConfirmPublishFooter product={product} onCloseModal={onCloseModal} />
      );

    default:
      return null;
  }
};
