import {
  Box,
  Conditional,
  Flex,
  Icon,
  Typography,
  useModal,
} from 'gantri-components';
import { useState } from 'react';
import { capitalize } from 'lodash';
import { LONG_FORMAT } from '../../constants/dates';
import { formatDate } from '../../helpers/formatter';
import Timeline from '../common/timeline';
import { StyledInlineTypography } from '../layout/page-layout-styles';
import SubsectionTitle from '../layout/subsection-title';
import {
  StyledLinkInlineTypography,
  StyledTimelineEmailContainer,
} from './order-timeline.styles';
import TimelineUserInfo from '../common/timeline/user-info';
import { TransactionEmailLog } from '../../types/store';
import { ResendConfirmationEmailModal } from '../../pages/order/components/resend-confirmation-email-modal';
import { useFetchOrderTimeline } from '../../api/transactions/routes';
import { OrderTimelinePropsDef } from './order-timeline.types';
import { StyledAnchor } from '../common/styled-anchor';
import routePaths from '../../config/route-paths';

export const OrderTimeline = (props: OrderTimelinePropsDef) => {
  const { orderId, transactionEmailLog } = props;

  const [emailInfo, setEmailInfo] = useState<
    Pick<TransactionEmailLog, 'emailType' | 'ownerId'>
  >({ emailType: null, ownerId: null });

  const [showResendConfirmationEmailModal, hideResendConfirmationEmailModal] =
    useModal(() => {
      return (
        <ResendConfirmationEmailModal
          emailInfo={emailInfo}
          orderId={orderId}
          onClose={hideResendConfirmationEmailModal}
        />
      );
    }, [emailInfo, orderId]);

  const { data } = useFetchOrderTimeline({
    enabled: !!orderId,
    fetchArgs: {
      orderId,
    },
  });

  const statusTimeline = data?.timeline || [];

  return (
    <Flex
      direction={{ sm: 'column' }}
      gap={{ lg: 'unset', sm: '8x' }}
      marginTop="8x"
    >
      <StyledTimelineEmailContainer>
        <Timeline
          items={statusTimeline}
          renderItem={(item) => {
            return (
              <div>
                <Typography text={formatDate(item.createdAt, LONG_FORMAT)} />
                <Box>
                  <Typography display="inline" text={item.description} />{' '}
                  {item.user && (
                    <TimelineUserInfo userId={item.userId} {...item.user} />
                  )}
                  <Typography display="inline" text="." />
                  <Conditional condition={!!item.extra?.orderId}>
                    <br />
                    <Typography
                      display="inline"
                      text="Refund transaction:"
                    />{' '}
                    <StyledAnchor
                      display="inline"
                      href={`${routePaths.orders}/${item.extra?.orderId}`}
                      text={`#${item.extra?.orderId}`}
                    />
                  </Conditional>
                </Box>
              </div>
            );
          }}
        />
      </StyledTimelineEmailContainer>

      <StyledTimelineEmailContainer>
        <SubsectionTitle>
          <Typography text="Emails" textStyle="bold" variant="h4" />
        </SubsectionTitle>
        {transactionEmailLog.map(({ emailSentAt, emailType, id, ownerId }) => {
          return (
            <Flex key={id} gap="1rem" marginBottom="20px">
              <Icon name="ui-control:email" top="0.4rem" />
              <div>
                <Typography text={formatDate(emailSentAt, LONG_FORMAT)} />
                <StyledInlineTypography
                  color="t2"
                  text={`${capitalize(emailType)} (`}
                />
                <StyledLinkInlineTypography
                  color="link"
                  data-testid="re-send"
                  text="re-send"
                  onClick={() => {
                    setEmailInfo({ emailType, ownerId });
                    showResendConfirmationEmailModal();
                  }}
                />
                <StyledInlineTypography color="t2" text=")" />
              </div>
            </Flex>
          );
        })}
      </StyledTimelineEmailContainer>
    </Flex>
  );
};
