import { Typography, useConfirmationModal } from 'gantri-components';
import { useRef } from 'react';
import { useDeleteMachineIssueMutation } from '../../../api/machines/routes';

export const useConfirmDeleteIssue = ({
  machineId,
  onSuccess,
}: {
  machineId: number;
  onSuccess?: CallableFunction;
}) => {
  const issueIdRef = useRef<number>();
  const { onDeleteIssue } = useDeleteMachineIssueMutation();

  const onConfirm = () => {
    const request = { issueId: issueIdRef.current, machineId };

    onDeleteIssue(request, {
      onSuccess: () => {
        hideModal();
        onSuccess?.();
      },
    });
  };

  const [showModal, hideModal] = useConfirmationModal(
    {
      confirmButtonText: 'Confirm',
      confirmButtonVariant: 'primary',
      content: (
        <Typography
          style={{ textAlign: 'center' }}
          text="Are you sure you want to delete this issue?"
        />
      ),
      headerText: 'Delete issue',
      onClose: () => {
        hideModal();
      },
      onConfirm,
      width: { lg: '42rem', md: '100%' },
    },
    [],
  );

  return (issueId: number) => {
    issueIdRef.current = issueId;
    showModal();
  };
};
