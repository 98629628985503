import React, { FC } from 'react';
import { Cell, Grid, Line, Typography } from 'gantri-components';
import { UserNPSReview } from '../../../../../api/users/routes';
import routePaths from '../../../../../config/route-paths';
import { StyledAnchor } from '../../../../../components/common/styled-anchor';
import { StyledStatusDot } from './review.styles';
import { getColorByStatus } from './review-adapter';
import { formatDate } from '../../../../../helpers/formatter';
import { LONG_FORMAT } from '../../../../../constants/dates';

export const NPSReviewDetails: FC<{ review: UserNPSReview }> = (props) => {
  const { review } = props;

  return (
    <Grid columns="1fr 1fr" gap="3x">
      <Typography text="Order #" textStyle="bold" />
      <StyledAnchor
        href={`${routePaths.orders}/${review.orderId}`}
        text={`#${review.orderId}`}
      />

      <Typography text="Review ID" textStyle="bold" />
      <Typography text={`#${review.id}`} />

      <Typography text="Review Status" textStyle="bold" />
      <Typography
        icon={<StyledStatusDot color={getColorByStatus(review.status)} />}
        iconGap=".6rem"
        text={review.status}
      />

      <Typography text="Last Date" textStyle="bold" />
      <Typography text={formatDate(review.lastDate, LONG_FORMAT)} />

      <Cell width={2}>
        <Line />
      </Cell>

      <Typography text="NPS Type" textStyle="bold" />
      <Typography text={review.type} />

      <Typography text="NPS Rating (1-10)" textStyle="bold" />
      <Typography text={review.rating} />

      <Typography text="Lighting Needs" textStyle="bold" />
      <Grid gap="0">
        {review.lightingNeeds?.map((needs) => {
          return <Typography key={needs} text={needs} />;
        })}
      </Grid>

      <Typography text="Without Gantri Light" textStyle="bold" />
      <Typography text={review.withoutGantriLight} />

      <Cell width={2}>
        <Line />
      </Cell>

      <Typography text="Overall Experience" textStyle="bold" />
      <Cell marginTop="-2rem" width={2}>
        <Typography color="t2" text={review.experience} variant="p2" />
      </Cell>
    </Grid>
  );
};
