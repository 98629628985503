import { Button, Cell, Stack, TextField, Typography } from 'gantri-components';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { shipmentActionsModalSteps } from '../../../shipment-actions-modal.constants';
import { shipmentActionsModalAtoms } from '../../../shipment-actions-modal.atoms';
import { StepShipManuallyContentPropsDef } from './step-ship-manually-content.types';

export const StepShipManuallyContent = (
  props: StepShipManuallyContentPropsDef,
) => {
  const { order } = props;

  const setStep = useSetRecoilState(shipmentActionsModalAtoms.step);
  const setIsDoNotShip = useSetRecoilState(
    shipmentActionsModalAtoms.shipNormally.isDoNotShip,
  );
  const [trackingNumber, setTrackingNumber] = useRecoilState(
    shipmentActionsModalAtoms.shipManually.trackingNumber,
  );
  const [shippingCost, setShippingCost] = useRecoilState(
    shipmentActionsModalAtoms.shipManually.shippingCost,
  );

  const handleShippoOnClick = async () => {
    setIsDoNotShip(order.address.doNotShip);
    setStep(shipmentActionsModalSteps.shipNormally);
  };

  return (
    <Stack>
      <Typography
        align="center"
        color="t2"
        marginBottom="2x"
        text="Enter shipping details."
      />

      <Cell>
        <Typography text="UPS tracking number #" textStyle="bold" />
        <TextField
          name="trackingNumber"
          value={trackingNumber}
          onTextChange={setTrackingNumber}
        />
      </Cell>

      <Cell>
        <Typography text="Shipping cost $" textStyle="bold" />
        <TextField
          name="shippingCost"
          type="number"
          value={shippingCost ? String(shippingCost) : ''}
          onTextChange={(value) => {
            return setShippingCost(+value);
          }}
        />
      </Cell>

      <Button
        justifySelf="center"
        text="Shippo"
        variant="ghost"
        onClick={handleShippoOnClick}
      />
    </Stack>
  );
};
