import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  MachinesBulkActionItem,
  MachinesBulkActionsData,
} from '../../machines-bulk-actions.types';
import { machineStatuses } from '../../../../../../constants/machines';
import { userRoles } from '../../../../../../constants/users';
import {
  getResetBuildPlateDisabled,
  getSetOfflineDisabled,
  getSetOnlineDisabled,
} from '../../../../helpers';
import { machinesAtoms } from '../../../../machines.atoms';
import { useHandleBulkUpdateMachines } from '../../../../hooks/use-handle-bulk-update-machines';
import { UseGetMachinesBulkActionsProps } from './use-get-machines-bulk-actions.types';
import { useUserHasAccessLevel } from '../../../../../../hooks/use-user-has-access-level';
import { getRolesForAccessLevelAndAbove } from '../../../../../../helpers/get-roles-for-access-level-and-above';
import { refreshPageMessage } from '../../../../machine.constants';

export const useGetMachinesBulkActions = (
  props: UseGetMachinesBulkActionsProps,
) => {
  const { maxVisibleActions, onSuccess } = props;

  const disableRowSelection = useRecoilValue(machinesAtoms.disableRowSelection);

  const { handleBulkUpdate } = useHandleBulkUpdateMachines({
    onSuccess,
  });

  const machinesSelected = useRecoilValue(machinesAtoms.machinesSelected);

  const allActions = useMemo(() => {
    const getBulkSetOfflineDisabled = ({
      machinesSelected,
    }: MachinesBulkActionsData): boolean => {
      return machinesSelected?.every(getSetOfflineDisabled);
    };

    const getBulkSetOnlineDisabled = ({
      machinesSelected,
    }: MachinesBulkActionsData): boolean => {
      return machinesSelected?.every(getSetOnlineDisabled);
    };

    const getBulkResetBuildPlateDisabled = ({
      machinesSelected,
    }: MachinesBulkActionsData): boolean => {
      return machinesSelected?.every(getResetBuildPlateDisabled);
    };

    const actions: MachinesBulkActionItem[] = [
      {
        allowedFor: getRolesForAccessLevelAndAbove(userRoles.lead),
        disabled: disableRowSelection || getBulkSetOfflineDisabled,
        disabledTooltipProps: {
          description: disableRowSelection
            ? refreshPageMessage
            : `To offline, select a machine with ${machineStatuses.ready} or ${machineStatuses.manual} status.`,
          position: 'left',
        },
        icon: 'ui-control:x',
        name: 'Set Offline',
        onOptionClick: () => {
          return handleBulkUpdate({ action: 'Set offline' });
        },
      },
      {
        allowedFor: getRolesForAccessLevelAndAbove(userRoles.lead),
        disabled: disableRowSelection || getBulkSetOnlineDisabled,
        disabledTooltipProps: {
          description: disableRowSelection
            ? refreshPageMessage
            : `To online, select a machine with ${machineStatuses.offline} or ${machineStatuses.manual} status (with no open issues).`,
          position: 'left',
        },
        icon: 'ui-control:check_mark',
        name: 'Set Online',
        onOptionClick: () => {
          return handleBulkUpdate({ action: 'Set online' });
        },
      },
      {
        disabled: disableRowSelection,
        disabledTooltipProps: {
          description: refreshPageMessage,
        },
        icon: 'work:wrench',
        name: 'New Issue',
        onOptionClick: () => {
          return handleBulkUpdate({ action: 'New issue' });
        },
      },
      {
        disabled: disableRowSelection || getBulkResetBuildPlateDisabled,
        disabledTooltipProps: {
          description: disableRowSelection
            ? refreshPageMessage
            : `To reset build plate, select a Creality machine with ${machineStatuses.ready} or ${machineStatuses.manual} status.`,
          position: 'left',
        },
        icon: 'arrows:arrow_rotate_two',
        name: 'Reset Build Plate',
        onOptionClick: () => {
          return handleBulkUpdate({ action: 'Reset build plate' });
        },
      },
      {
        allowedFor: getRolesForAccessLevelAndAbove(userRoles.lead),
        disabled: disableRowSelection,
        disabledTooltipProps: { description: refreshPageMessage },
        icon: 'work:print_nozzle',
        name: 'Update Type',
        onOptionClick: () => {
          return handleBulkUpdate({ action: 'Update type' });
        },
      },
    ] satisfies MachinesBulkActionItem[];

    return actions;
  }, [machinesSelected, disableRowSelection]);

  const { getUserHasAccessTo } = useUserHasAccessLevel();

  const renderedActions = allActions.filter(({ allowedFor }) => {
    return getUserHasAccessTo(allowedFor, { trueIfEmpty: true });
  });

  const buttonActions = renderedActions.slice(0, maxVisibleActions);

  const moreMenuActions = renderedActions.slice(maxVisibleActions);

  return { allActions, buttonActions, moreMenuActions };
};
