import { Button, Flex } from 'gantri-components';
import Protected from '../../../../components/common/protected/protected';
import { transactionsApi } from '../../../../api';
import { useRouter } from '../../../../hooks';
import { useGetDownloadMenuOption } from '../../../../hooks/use-get-download-menu-option';
import { useMoreMenu } from '../../../../hooks/use-more-menu';
import { OrdersHeaderContentProps } from './orders-header-content.types';
import { downloadColumns } from './orders-header-content.constants';
import { Amount, OrderRecord } from '../../orders.types';

export const OrdersHeaderContent = (props: OrdersHeaderContentProps) => {
  const { downloadFilters, totalResults } = props;

  const { navigate } = useRouter();

  const goToNewOrderPage = () => {
    navigate('/new-order');
  };

  const { Toast: DownloadCsvToast, downloadOption } = useGetDownloadMenuOption({
    columns: downloadColumns,
    fetchRequestWithFilters: async (overrides) => {
      return transactionsApi
        .fetchPaginatedTransactions({ ...downloadFilters, ...overrides })
        .then(({ data }) => {
          return data.orders.reduce<OrderRecord[]>((accumulator, order) => {
            const { shipments } = order;

            if (shipments.length) {
              const splitOrders = order.shipments.map((shipment, index) => {
                return {
                  ...order,
                  /** Prevent duplicating values which could cause artificial totals. */
                  amount: index ? ({} as Amount) : order.amount,
                  shipments: [shipment],
                };
              });

              return [...accumulator, ...splitOrders];
            }

            // This catches orders containing only gift cards
            return [...accumulator, order];
          }, []);
        });
    },
    fileNamePrefix: 'Orders Admin',
    totalResults,
  });

  const { MoreMenu } = useMoreMenu({
    options: [
      {
        ...downloadOption,
        allowedFor: ['Lead', 'Admin'],
      },
    ],
  });

  return (
    <>
      <Flex alignItems="center" gap=".5x">
        <Protected allowedFor={['Admin']}>
          <Button text="New Order" onClick={goToNewOrderPage} />
        </Protected>
        <Protected allowedFor={['Lead', 'Admin']}>
          <MoreMenu />
        </Protected>
      </Flex>
      <DownloadCsvToast />
    </>
  );
};
