import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { GetPartsArgs, GetPartsResponse } from './get-parts.types';

export const getParts = ({
  completedDateRange,
  lastActionDateRange,
  ...rest
}: GetPartsArgs) => {
  return axios
    .post<GetPartsResponse>(`${coreApiUrl}/parts/pagination`, {
      completedEndDate: completedDateRange?.to,
      completedStartDate: completedDateRange?.from,
      lastActionEndDate: lastActionDateRange?.to,
      lastActionStartDate: lastActionDateRange?.from,
      ...rest,
    })
    .then(({ data }) => {
      return data;
    });
};
