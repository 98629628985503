import { Button } from 'gantri-components';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { fullScreenModalFooterButtonSize } from '../../../../../../jobs/components/modals/modals.constants';
import { setMachinesOnlineModalAtoms } from '../../../set-machines-online-modal.atoms';
import {
  useBulkUpdateMachines,
  useInvalidateGetMachineOverviewCache,
} from '../../../../../../../api/machines/routes';
import { ConfirmSetMachinesOnlineFooterProps } from './confirm-set-machines-online-footer.types';
import { BulkUpdateResponse } from '../../../../../../../api/machines/routes/bulk-update/bulk-update.types';
import { setMachinesOnlineModalSteps } from '../../../set-machines-online-modal.constants';

export const ConfirmSetMachinesOnlineFooter = (
  props: ConfirmSetMachinesOnlineFooterProps,
) => {
  const { handleCloseModal } = props;

  const [detailsPanelMachines, setDetailsPanelMachines] = useRecoilState(
    setMachinesOnlineModalAtoms.detailsPanelMachines,
  );
  const setUpdateOnClose = useSetRecoilState(
    setMachinesOnlineModalAtoms.updateOnClose,
  );
  const setMachinesOnlined = useSetRecoilState(
    setMachinesOnlineModalAtoms.machinesOnlined,
  );
  const setMachinesNotOnlined = useSetRecoilState(
    setMachinesOnlineModalAtoms.machinesNotOnlined,
  );
  const setStep = useSetRecoilState(setMachinesOnlineModalAtoms.step);

  const { invalidateMachineOverviewCache } =
    useInvalidateGetMachineOverviewCache();

  const onSuccess = async ({ notOnlined, onlined }: BulkUpdateResponse) => {
    setMachinesOnlined(onlined);
    setMachinesNotOnlined(notOnlined);

    const updatedDetailsPanelMachines = detailsPanelMachines.map(
      (oldMachine) => {
        const updatedMachine = onlined.find(({ id }) => {
          return id === oldMachine.id;
        });

        return updatedMachine || oldMachine;
      },
    );

    setDetailsPanelMachines(updatedDetailsPanelMachines);
    setUpdateOnClose(true);
    setStep(setMachinesOnlineModalSteps.reviewOnlinedMachines);

    await invalidateMachineOverviewCache();
  };

  const { isLoading, onBulkUpdateMachines } = useBulkUpdateMachines({
    onSuccess,
  });

  const onConfirm = async () => {
    const machineIds = detailsPanelMachines.map(({ id }) => {
      return id;
    });

    const { notOnlined, onlined } = await onBulkUpdateMachines({
      action: 'Set online',
      ids: machineIds,
    });

    setMachinesOnlined(onlined);
    setMachinesNotOnlined(notOnlined);

    const updatedDetailsPanelMachines = detailsPanelMachines.map(
      (oldMachine) => {
        const updatedMachine = onlined.find(({ id }) => {
          return id === oldMachine.id;
        });

        return updatedMachine || oldMachine;
      },
    );

    setDetailsPanelMachines(updatedDetailsPanelMachines);
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={handleCloseModal}
      />
      <Button
        processing={isLoading}
        size={fullScreenModalFooterButtonSize}
        text="Confirm"
        onClick={onConfirm}
      />
    </>
  );
};
