import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  UpdateProductLifestylePhotosArgs,
  UpdateProductLifestylePhotosResponse,
} from './update-product-lifestyle-photos.types';

export const updateProductLifestylePhotos = ({
  lifestylePhotos,
  productId,
  ...rest
}: UpdateProductLifestylePhotosArgs) => {
  const purifiedLifestylePhotos = lifestylePhotos.map(
    ({ color, fileName, variants }) => {
      return { color, fileName, variants };
    },
  );

  return axios
    .put<UpdateProductLifestylePhotosResponse>(
      `${getCoreApiUrl('products')}/${productId}/lifestyle-photos`,
      {
        ...rest,
        lifestylePhotos: purifiedLifestylePhotos,
      },
    )
    .then(({ data }) => {
      return data;
    });
};
