import { FC } from 'react';
import { Button, Modal } from 'gantri-components';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../../../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { StartJobGenericModalProps } from './start-job-generic-modal.types';
import { JobDetailsPanelLarge } from '../../../common/job-details-panel-large';
import { fullScreenModalFooterButtonSize } from '../../../modals.constants';
import { ModalContentHeading } from '../../../common/modal-content-heading';
import { FullScreenModalHeader } from '../../../common/full-screen-modal-header';
import { useStartJob } from '../../../../../../../api/jobs/routes';

export const StartJobGenericModal: FC<StartJobGenericModalProps> = (props) => {
  const { job, onClose, onJobUpdated } = props;

  const { isLoading, onStartJob } = useStartJob({
    onSuccess: async () => {
      await onJobUpdated();
      onClose();
    },
  });

  const startJob = async () => {
    await onStartJob({
      jobId: job.id,
      machineId: job.machine?.id,
    });
  };

  const handleCloseModal = async () => {
    onClose();
  };

  return (
    <Modal
      closeable={false}
      dataAttributes={{ 'linked-to': JOB_DETAILS_PANEL_LINKED_TO }}
      detailsPanel={
        <JobDetailsPanelLarge
          job={job}
          sectionsToShow={[
            'part',
            'color & material',
            'product',
            'instructions',
            'part diagram',
          ]}
        />
      }
      detailsPanelWidth="1fr"
      footer={
        <>
          <Button
            size={fullScreenModalFooterButtonSize}
            text="Cancel"
            variant="secondary"
            onClick={handleCloseModal}
          />
          <Button
            processing={isLoading}
            size={fullScreenModalFooterButtonSize}
            text="Yes"
            onClick={startJob}
          />
        </>
      }
      header={
        <FullScreenModalHeader handleCloseModal={handleCloseModal} job={job} />
      }
      isFullScreen
    >
      <ModalContentHeading
        subTitleText={`Are you sure you want to start ${job?.step} ${job.type}?`}
        titleText="Start job"
      />
    </Modal>
  );
};
