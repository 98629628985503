import { useFormikContext } from 'formik';
import { Button } from 'gantri-components';
import { PreparePrintsModalFormData } from '../../create-print-transfer-queue-modal.types';
import { CreatePrintTransferQueueModalFooterProps } from './create-print-transfer-queue-modal-footer.types';

export const CreatePrintTransferQueueModalFooter = (
  props: CreatePrintTransferQueueModalFooterProps,
) => {
  const { handleCloseModal, processingConfirm } = props;

  const { isValid } = useFormikContext<PreparePrintsModalFormData>();

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        disabled={!isValid}
        processing={processingConfirm}
        size="large"
        text="Confirm"
        type="submit"
      />
    </>
  );
};
