import React, { FC, useEffect, useMemo } from 'react';
import {
  TextArea,
  Typography,
  Flex,
  FormikInput,
  Dropdown,
} from 'gantri-components';
import { Formik, useFormikContext } from 'formik';
import { StyledPageSubsection } from '../../../components/layout/page-layout-styles';
import {
  StyledFieldContainer,
  StyledLabelContainer,
  StyledPayoutAvatar,
  StyledSubSectionTitle,
} from '../payout-styles';
import { formatAsCurrency } from '../../../helpers/formatter';
import { paymentMethods, payoutStatuses } from '../../../constants/payouts';
import { PayoutFormProps } from './payout-form.types';
import {
  formattedDesignerLocation,
  formattedSummaryPeriod,
  getLogoUrl,
} from '../payout.adapter';
import { PayoutForSchema } from './payout-for.schema';
import { Payout } from '../../../api/transactions/routes/get-payout/get-payout.types';

const UpdateUpdateButtonStatus: FC<{
  onFormStatusChange: (output: {
    isValid: boolean;
    values: Partial<Payout>;
  }) => void;
}> = (props) => {
  const { dirty, isValid, values } = useFormikContext();
  const { onFormStatusChange } = props;

  useEffect(() => {
    onFormStatusChange({ isValid: isValid && dirty, values });
  }, [isValid, dirty, values]);

  return null;
};

export const PayoutForm: FC<PayoutFormProps> = (props) => {
  const { onFormStatusChange, payout } = props;

  const designerLocation = useMemo(() => {
    return formattedDesignerLocation(payout);
  }, [payout]);

  const logoSrc = useMemo(() => {
    return getLogoUrl(payout);
  }, [payout]);
  const summaryPeriod = useMemo(() => {
    return formattedSummaryPeriod(payout);
  }, [payout]);

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...payout }}
      validateOnChange
      validationSchema={PayoutForSchema}
      onSubmit={() => {}}
    >
      {() => {
        return (
          <>
            <UpdateUpdateButtonStatus onFormStatusChange={onFormStatusChange} />
            <StyledPageSubsection>
              <StyledSubSectionTitle>
                <StyledPayoutAvatar img={logoSrc} />
                <Typography
                  text={payout.designer}
                  textStyle="bold"
                  variant="h4"
                />
                <Typography color="t2" text={designerLocation} variant="p2" />
              </StyledSubSectionTitle>
              <StyledSubSectionTitle>
                <Typography
                  text={formatAsCurrency(payout.amount.royalty, {
                    isCents: true,
                  })}
                  variant="h2"
                />
              </StyledSubSectionTitle>
              <Flex alignItems="center" marginBottom="1rem" width="100%">
                <StyledLabelContainer>
                  <Typography text="Period" />
                </StyledLabelContainer>
                <StyledFieldContainer>
                  <Typography color="t1" text={summaryPeriod} />
                </StyledFieldContainer>
              </Flex>
              <Flex alignItems="center" marginBottom="1rem" width="100%">
                <StyledLabelContainer>
                  <Typography text="Status" />
                </StyledLabelContainer>
                <StyledFieldContainer>
                  <FormikInput
                    Field={
                      <Dropdown
                        idProperty="value"
                        items={payoutStatuses}
                        placeholder="Status"
                      />
                    }
                    name="status"
                  />
                </StyledFieldContainer>
              </Flex>
            </StyledPageSubsection>
            <StyledPageSubsection>
              <StyledSubSectionTitle>
                <Typography text="Payment" textStyle="bold" variant="h4" />
              </StyledSubSectionTitle>
              <Flex alignItems="center" marginBottom="1rem" width="100%">
                <StyledLabelContainer>
                  <Typography text="Method" />
                </StyledLabelContainer>
                <StyledFieldContainer>
                  <FormikInput
                    Field={
                      <Dropdown
                        idProperty="value"
                        items={paymentMethods}
                        placeholder="Method"
                      />
                    }
                    name="method"
                  />
                </StyledFieldContainer>
              </Flex>
              <Flex alignItems="center" marginBottom="1rem" width="100%">
                <StyledLabelContainer>
                  <Typography text="Name" />
                </StyledLabelContainer>
                <StyledFieldContainer width="30rem">
                  <FormikInput name="name" placeholder="Name" />
                </StyledFieldContainer>
              </Flex>
              <Flex alignItems="center" marginBottom="1rem" width="100%">
                <StyledLabelContainer>
                  <Typography text="Email" />
                </StyledLabelContainer>
                <StyledFieldContainer width="30rem">
                  <FormikInput name="email" placeholder="Email" type="email" />
                </StyledFieldContainer>
              </Flex>
              <Flex alignItems="center" marginBottom="1rem" width="100%">
                <StyledLabelContainer>
                  <Typography text="Date" />
                </StyledLabelContainer>
                <StyledFieldContainer>
                  <Typography color="t1" text={payout.paidDate || 'TBD'} />
                </StyledFieldContainer>
              </Flex>

              <Flex
                alignItems="start"
                direction="column"
                gap="1rem"
                width="100%"
              >
                <Typography text="Notes" textStyle="bold" variant="h4" />

                <FormikInput
                  debounce={500}
                  Field={<TextArea minRows={5} placeholder="Notes" />}
                  name="notes"
                />
              </Flex>
            </StyledPageSubsection>
          </>
        );
      }}
    </Formik>
  );
};
