import { Modal } from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { FullScreenModalHeader } from '../../../jobs/components/modals/common/full-screen-modal-header';
import { SetMachinesOfflineModalProps } from './set-machines-offline-modal.types';
import { setMachinesOfflineModalAtoms } from './set-machines-offline-modal.atoms';
import { SetMachinesOfflineModalFooter } from './components/set-machines-offline-modal-footer';
import { SetMachinesOfflineModalContent } from './components/set-machines-offline-modal-content';
import { MachineStatus, machineStatuses } from '../../../../constants/machines';
import { useResetRecoilAtomsOnMount } from '../../../../hooks/use-reset-recoil-atoms-on-mount';
import { BulkUpdateMachinesModalDetailsPanel } from '../bulk-update-machines-modal-details-panel';
import { SimpleErrorBoundary } from '../../../../components/simple-error-boundary';

export const SetMachinesOfflineModal = (
  props: SetMachinesOfflineModalProps,
) => {
  const { machines, onClose, onSuccess } = props;

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    setMachinesOfflineModalAtoms,
  );
  const updateOnClose = useRecoilValue(
    setMachinesOfflineModalAtoms.updateOnClose,
  );
  const [detailsPanelMachines, setDetailsPanelMachines] = useRecoilState(
    setMachinesOfflineModalAtoms.detailsPanelMachines,
  );
  const setHasMachinesWithInvalidStatuses = useSetRecoilState(
    setMachinesOfflineModalAtoms.hasMachinesWithInvalidStatuses,
  );

  const handleCloseModal = async () => {
    if (updateOnClose) {
      await onSuccess();
    }

    onClose();
  };

  useEffect(() => {
    setDetailsPanelMachines(machines);

    const hasMachinesWithInvalidStatuses = machines.some(({ status }) => {
      const alertStatuses: MachineStatus[] = [
        machineStatuses.offline,
        machineStatuses.inProgress,
      ];

      return alertStatuses.some((alertStatus) => {
        return alertStatus === status;
      });
    });

    setHasMachinesWithInvalidStatuses(hasMachinesWithInvalidStatuses);
  }, []);

  return (
    <ResetAtomsWrapper>
      <Modal
        closeable={false}
        detailsPanel={
          <BulkUpdateMachinesModalDetailsPanel
            machines={detailsPanelMachines}
          />
        }
        detailsPanelWidth="1fr"
        footer={
          <SimpleErrorBoundary>
            <SetMachinesOfflineModalFooter
              handleCloseModal={handleCloseModal}
            />
          </SimpleErrorBoundary>
        }
        header={
          <FullScreenModalHeader
            handleCloseModal={handleCloseModal}
            text="Set Machines Offline"
          />
        }
        isFullScreen
      >
        <SimpleErrorBoundary>
          <SetMachinesOfflineModalContent />
        </SimpleErrorBoundary>
      </Modal>
    </ResetAtomsWrapper>
  );
};
