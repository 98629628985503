import { forwardRef, Ref } from 'react';
import { Icon, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { UserSettingsToggleProps } from './user-settings-toggle.types';
import { StyledToggleWrapper } from './user-settings-toggle.styles';
import { userAtoms } from '../../../../../global-atoms/users';

export const UserSettingsToggle = forwardRef(
  (props: UserSettingsToggleProps, ref: Ref<HTMLDivElement>) => {
    const { isOpen, showName, ...rest } = props;

    const { email, firstName } = useRecoilValue(userAtoms.user);
    const theme = useRecoilValue(userAtoms.theme);

    const username = email ? email.split('@')[0] : '';

    return (
      <StyledToggleWrapper ref={ref} showName={showName} {...rest}>
        {showName ? (
          <Typography
            icon={
              <Icon
                color={theme === 'light' ? 't1' : 't2'}
                height="1.8rem"
                left="0"
                name={
                  isOpen
                    ? 'arrows:arrow_chevron_up'
                    : 'arrows:arrow_chevron_down'
                }
              />
            }
            iconPosition="right"
            style={{ fontSize: '1.5rem', lineHeight: '2.6rem' }}
            text={showName ? firstName || username : ''}
          />
        ) : (
          <Icon color={theme === 'light' ? 't1' : 't2'} name="people:person" />
        )}
      </StyledToggleWrapper>
    );
  },
);
