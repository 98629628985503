import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  CancelOrRefundItemsArgs,
  CancelOrRefundItemsResponse,
} from './cancel-or-refund-items.types';

export const cancelOrRefundItems = (args: CancelOrRefundItemsArgs) => {
  const { transactionId, ...data } = args;

  return axios.post<CancelOrRefundItemsResponse>(
    `${getCoreApiUrl('transactions')}/${transactionId}/cancel-or-refund`,
    data,
  );
};
