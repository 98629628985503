import { Button, getFileUrl, Modal, Typography } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { productsApi } from '../../../../../../../../api';
import { useDeleteCloudinaryFile } from '../../../../../../../../api/cloudinary/routes';
import { useInvalidateFetchProductCache } from '../../../../../../../../api/products/routes';
import { useNotification } from '../../../../../../../../hooks/useNotification';
import { productAtoms } from '../../../../../../product.atoms';
import { DeleteHandoutModalProps } from './delete-handout-modal.types';

export const DeleteHandoutModal = (props: DeleteHandoutModalProps) => {
  const { handout, onClose } = props;

  const [product, setProduct] = useRecoilState(productAtoms.product);
  const { notifyAxiosError, onInterceptRequest } = useNotification();

  const { onDeleteFile } = useDeleteCloudinaryFile();

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();

  const handleDelete = async () => {
    await onInterceptRequest(async () => {
      try {
        const handoutData = { ...handout };

        if (handoutData.universal) {
          handoutData.productId = product.id;
        }

        const fileUrl = handoutData.universal
          ? getFileUrl<'products'>({
              directory: 'products',
              fileName: handoutData.name,
              fileType: 'universal-handouts',
              identifiers: {},
            })
          : getFileUrl<'products'>({
              directory: 'products',
              fileName: handoutData.name,
              fileType: 'handouts',
              identifiers: {
                productId: handoutData.productId,
              },
            });

        await onDeleteFile({
          fileUrl,
        });

        await productsApi.deleteHandoutOrInstructions(handoutData);

        await invalidateFetchProductCache();

        onClose();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to delete file.',
        });
      }
    });
  };

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={() => {
              onClose();
            }}
          />
          <Button size="large" text="Confirm" onClick={handleDelete} />
        </>
      }
      header="Are you sure?"
      width={{ lg: '40rem', md: '100%' }}
      onClose={onClose}
    >
      <Typography
        align="center"
        style={{ wordBreak: 'break-word' }}
        text={`Do you really want to delete ${handout?.name}?`}
      />
    </Modal>
  );
};
