import {
  Button,
  Cell,
  Conditional,
  Dropdown,
  Flex,
  Grid,
  Modal,
  Radio,
  Stack,
  Typography,
} from 'gantri-components';
import { FC, PropsWithChildren, useState } from 'react';
import isNil from 'lodash/isNil';
import { reverse, sortBy } from 'lodash';
import { AxiosError } from 'axios';
import { useSetRecoilState } from 'recoil';
import { CreateVersionProps } from './create-version-modal.types';
import { versionApi } from '../../../../../../../../api';
import { useNotification } from '../../../../../../../../hooks/useNotification';
import {
  CreateVersionArgs,
  LatestVersion,
} from '../../../../../../../../api/version/version.types';
import { formatDate } from '../../../../../../../../helpers/formatter';
import { MEDIUM_FORMAT } from '../../../../../../../../constants/dates';
import { useCreateDraftVersion } from '../../../../../../../../api/version/routes';
import { productJobsTabAtoms } from '../../../../product-jobs.atoms';
import { delayFor } from '../../../../../../../../helpers/delay-for';
import { useInvalidateFetchProductCache } from '../../../../../../../../api/products/routes';

export const CreateVersionModal: FC<CreateVersionProps> = (props) => {
  const { onClose, productId } = props;

  const setVersionId = useSetRecoilState(productJobsTabAtoms.versionId);
  const setDraftVersionId = useSetRecoilState(
    productJobsTabAtoms.draftVersionId,
  );

  const [isMinorVersion, setIsMinorVersion] = useState<boolean>(null);
  const [versionIdToUpdate, setVersionIdToUpdate] = useState<number>(null);
  const [latestVersionId, setLatestVersionId] = useState<number>(null);
  const [versions, setVersions] = useState<LatestVersion[]>([]);

  const { notifyError, onInterceptRequest } = useNotification();

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();

  const onChangeVersionType = async (isMinor: boolean) => {
    setIsMinorVersion(isMinor);
    setVersionIdToUpdate(null);

    if (versions?.length) {
      return;
    }

    if (isMinor) {
      await onInterceptRequest(async () => {
        try {
          const {
            data: { versions },
          } = await versionApi.latestVersions(productId);

          const sortedVersions = reverse(
            sortBy(versions, ({ version }) => {
              return version;
            }),
          );

          setVersions(sortedVersions);

          const [latestVersion] = sortedVersions;

          setVersionIdToUpdate(latestVersion?.id);
          setLatestVersionId(latestVersion?.id);
        } catch (error: unknown) {
          const draftAlreadyExists = getDraftAlreadyExists(
            error as AxiosError<{ error?: string }>,
          );

          if (draftAlreadyExists) {
            notifyError('Draft already exists.');
            await fetchDraftProductAndClose();
          }
        }
      });
    }
  };

  const getDraftAlreadyExists = (error: AxiosError<{ error?: string }>) => {
    const errorMessage = error?.response?.data?.error;
    const draftAlreadyExists = /Draft version already created/i.test(
      errorMessage,
    );

    return draftAlreadyExists;
  };

  const fetchDraftProductAndClose = async (props?: { versionId: number }) => {
    const { versionId } = props || {};

    // Delay added as an immediate request can return the old version data. Unsure what is causing that issue - CD
    await delayFor(500);

    if (versionId) {
      setVersionId(versionId);
      setDraftVersionId(versionId);
    } else {
      await invalidateFetchProductCache();
    }

    onClose();
  };

  const { isLoading, onCreateDraftVersion } = useCreateDraftVersion({
    onError: async (error: AxiosError<{ error?: string }>) => {
      const draftAlreadyExists = getDraftAlreadyExists(error);

      if (draftAlreadyExists) {
        await fetchDraftProductAndClose();
      }
    },
    onSuccess: ({ version }) => {
      return fetchDraftProductAndClose({ versionId: version.id });
    },
  });

  const handleCreateVersion = async () => {
    const createDraftVersionArgs: CreateVersionArgs = isMinorVersion
      ? {
          productId,
          type: 'Minor',
          versionId: versionIdToUpdate,
        }
      : {
          productId,
          type: 'Major',
        };

    await onCreateDraftVersion(createDraftVersionArgs);
  };

  const disabled = isMinorVersion
    ? isNil(versionIdToUpdate)
    : isNil(isMinorVersion);

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            disabled={disabled}
            processing={isLoading}
            size="large"
            text="Next"
            onClick={handleCreateVersion}
          />
        </>
      }
      header="Create New Version"
      width={{ lg: '62rem', md: '100vw' }}
      onClose={onClose}
    >
      <Typography marginBottom="s1" text="Version type" textStyle="bold" />

      <Stack>
        <Cell>
          <Radio
            groupValue
            labelText="Minor Update"
            name="minor-update"
            value={isMinorVersion}
            onSelected={() => {
              return onChangeVersionType(true);
            }}
          />

          <Typography
            color="t2"
            marginTop="0.8rem"
            paddingLeft="3rem"
            text="You can update instructions, inventories, duration, and thumbnails. Changes are compatible with previous versions and will be updated for waiting and in progress stocks."
            variant="p3"
          />

          <Conditional condition={isMinorVersion}>
            <Grid
              alignItems="center"
              columnGap="s1"
              columns={{ lg: 'max-content 1fr', sm: '1fr' }}
              marginTop="s1"
              paddingLeft="3rem"
            >
              <Typography text="Major Version to Update" textStyle="bold" />
              <Dropdown
                idProperty="id"
                items={versions}
                maxHeight="33rem"
                renderItem={(version: LatestVersion) => {
                  return (
                    <DropdownVersionOption>
                      <DropdownVersionOptionTitle
                        isLatestVersion={latestVersionId === version?.id}
                        version={version?.version}
                      />
                      <DropdownVersionOptionDescription
                        date={version?.statusInfo?.lastUpdate?.date}
                      />
                    </DropdownVersionOption>
                  );
                }}
                renderPlaceholder={(version: LatestVersion) => {
                  if (!version) return null;

                  return (
                    <DropdownVersionOption>
                      <DropdownVersionOptionTitle
                        isLatestVersion={latestVersionId === version?.id}
                        version={version?.version}
                      />
                      <DropdownVersionOptionDescription
                        date={version?.statusInfo?.lastUpdate?.date}
                      />
                    </DropdownVersionOption>
                  );
                }}
                value={versionIdToUpdate}
                onSelect={(version) => {
                  return setVersionIdToUpdate(version.id);
                }}
              />
            </Grid>
          </Conditional>
        </Cell>

        <Cell>
          <Radio
            groupValue={false}
            labelText="Major Update"
            name="minor-update"
            value={isMinorVersion}
            onSelected={() => {
              return onChangeVersionType(false);
            }}
          />

          <Typography
            color="t2"
            marginTop="0.8rem"
            paddingLeft="3rem"
            text="You can update all parts of the product. Changes will not be compatible with previous versions and will be updated for all stocks of waiting and future stocks."
            variant="p3"
          />
        </Cell>
      </Stack>
    </Modal>
  );
};

const DropdownVersionOptionTitle: FC<{
  isLatestVersion: boolean;
  version: number;
}> = ({ isLatestVersion, version }) => {
  return (
    <Typography
      display="inline"
      text={`${
        Number.isInteger(version) ? `${+version.toFixed(1)}.00` : version
      } ${isLatestVersion ? '(Latest)' : ''} `}
    />
  );
};

const DropdownVersionOptionDescription: FC<{ date: number }> = ({ date }) => {
  return (
    <Typography
      color="t2"
      display="inline"
      text={`Last Updated: ${formatDate(date, MEDIUM_FORMAT)}`}
      variant="p3"
    />
  );
};

const DropdownVersionOption: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex alignItems="center" gap="s1" padding=".8rem">
      {children}
    </Flex>
  );
};
