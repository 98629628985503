import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { convertQueriesToString } from '../../../../helpers/checks';
import {
  GetPartReleaseRateCountArgsDef,
  GetPartReleaseRateCountResponseDef,
} from './get-part-release-rate-count.types';

export const getPartReleaseRateCount = async (
  queries: GetPartReleaseRateCountArgsDef,
) => {
  return axios
    .get<GetPartReleaseRateCountResponseDef>(
      `${getCoreApiUrl(
        'digital-backlog',
      )}/release-parts${convertQueriesToString(queries)}`,
    )
    .then(({ data }) => {
      return data;
    });
};
