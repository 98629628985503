import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { useModal } from 'gantri-components';
import { SearchPartState } from './search-part.types';
import { Job } from '../../api/jobs/jobs.types';
import { AssignMachineModal } from '../../components/modals/assign-machine-modal';
import { machinesApi } from '../../api';
import { useNotification } from '../../hooks/useNotification';

const SearchPartContext = createContext<SearchPartState>({});

export const SearchPartProvider: FC<PropsWithChildren<SearchPartState>> = ({
  children,
  ...props
}) => {
  const currentJobRef = useRef<Job>();
  const { notify, notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const [showAssignModal, hideAssignModal] = useModal(() => {
    return (
      <AssignMachineModal
        assignedPrinter={null}
        job={currentJobRef.current}
        modalType="ASSIGN"
        processing={processing}
        onAssignConfirm={async (machine) => {
          await onInterceptProcessingRequest(async () => {
            try {
              const { data } = await machinesApi.jobReassign({
                jobId: currentJobRef.current.id,
                machineId: machine,
              });

              hideAssignModal();
              props.onRefreshCurrentPart?.();
              notify(data.notice);
            } catch (error: unknown) {
              notifyAxiosError({
                error,
                fallbackMessage: 'Unable to assign job to machine.',
              });
            }
          });
        }}
        onClose={() => {
          hideAssignModal();
        }}
      />
    );
  }, [currentJobRef.current, processing]);

  const onOpenAssignMachineModal = (job: Job) => {
    currentJobRef.current = job;
    showAssignModal();
  };

  const value = useMemo(() => {
    return { ...props, onOpenAssignMachineModal };
  }, []);

  return (
    <SearchPartContext.Provider value={value}>
      {children}
    </SearchPartContext.Provider>
  );
};

export const useSearchPart = () => {
  return useContext(SearchPartContext);
};
