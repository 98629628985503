import { Flex, Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { ViewBatchAnchor } from '../../../../../../../../batch/components/view-batch-anchor';
import { updatePartBatchModalAtoms } from '../../../update-part-batch-modal.atoms';

export const BatchCreationSuccessContent = () => {
  const newBatchId = useRecoilValue(updatePartBatchModalAtoms.newBatchId);

  return (
    <Stack alignContent="center" gap="0" minHeight="12.4rem">
      <Flex gap="x" justifyContent="center">
        <Typography
          text={`Batch #${newBatchId} has been created and part has been added.`}
        />
        <ViewBatchAnchor batchId={newBatchId} />
      </Flex>
      <Typography
        align="center"
        text="Place a label on the rack or container and make sure part is placed correctly."
      />
    </Stack>
  );
};
