import { Button } from 'gantri-components';
import { useFormikContext } from 'formik';
import { PressRecord } from '../../../../../../../api/press/press.types';
import { ActionFormFooterProps } from './action-form-footer.types';

export const ActionFormFooter = (props: ActionFormFooterProps) => {
  const { handleCloseModal, processing } = props;

  const { isValid } = useFormikContext<PressRecord>();

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={handleCloseModal}
      />
      <Button
        disabled={!isValid}
        processing={processing}
        size="large"
        text="Save"
        type="submit"
      />
    </>
  );
};
