import { Conditional, productColorsMap, Stack } from 'gantri-components';
import { StockSummaryRecord } from '../../../../../../api/stocks/routes/fetch-stock-summary/fetch-stock-summary.types';
import { ShortProductSummary } from '../../../../../../components/common/short-product-summary';
import { noneOption } from '../../../../../../constants/options';
import { SelectedStocksDetailsProps } from './selected-stocks-details.types';

export const SelectedStocksDetails = (props: SelectedStocksDetailsProps) => {
  const { stocks } = props;

  const [firstStock] = stocks;

  return (
    <Conditional
      condition={stocks.length === 1}
      Fallback={
        <Stack gap=".5x">
          {stocks.map((stock) => {
            const { name, productId, sku, thumbnail, variants } = stock;

            return (
              <ShortProductSummary
                key={sku}
                color={stock.color}
                hideImage
                id={productId}
                name={name}
                sku={sku}
                thumbnail={thumbnail}
                variants={variants}
              />
            );
          })}
        </Stack>
      }
    >
      <ShortProductSummary
        color={firstStock.color}
        id={firstStock.productId}
        name={firstStock.name}
        sku={firstStock.sku}
        thumbnail={firstStock.thumbnail}
        variants={firstStock.variants}
      />
    </Conditional>
  );
};

export const getStockDetails = ({ color, variants }: StockSummaryRecord) => {
  return [
    color !== noneOption.code && productColorsMap[color]?.shortColorName,
    ...(variants?.map(({ label, name }) => {
      return [label, name].join(': ');
    }) || []),
  ]
    .filter(Boolean)
    .join(', ');
};
