import { useRecoilState, useSetRecoilState } from 'recoil';
import { EvaluatePartContent as BaseEvaluatePartContent } from '../../../../../common/evaluate-part-content';
import { completePrintHarvestModalAtoms } from '../../../complete-print-harvest-modal.atoms';
import {
  completedPrintChecklistDefaults,
  partialPrintChecklistDefaults,
} from '../../../complete-print-harvest-modal.constants';

export const EvaluatePartContent = () => {
  const [printCompletionStatus, setPrintCompletionStatus] = useRecoilState(
    completePrintHarvestModalAtoms.printCompletionStatus,
  );
  const setChecklist = useSetRecoilState(
    completePrintHarvestModalAtoms.checklist,
  );

  return (
    <BaseEvaluatePartContent
      getDefaultChecklist={(value) => {
        return value === 'PASS'
          ? completedPrintChecklistDefaults
          : partialPrintChecklistDefaults;
      }}
      initialEvaluation={printCompletionStatus}
      labelFail="Partial or incomplete print"
      labelPass="Complete print"
      setChecklist={setChecklist}
      setInitialEvaluation={setPrintCompletionStatus}
      subTitleText="Is the print complete?"
      titleText="Evaluate part"
    />
  );
};
