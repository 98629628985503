import { useSetRecoilState } from 'recoil';
import { printPrepareQueueModalAtoms } from '../../../print-transfer-queue-modal/print-transfer-queue-modal.atoms';
import { PreparePrintsModalFormData } from '../../create-print-transfer-queue-modal.types';
import { UseOnSubmitProps } from './use-on-submit.types';

export const useOnSubmit = (props: UseOnSubmitProps) => {
  const { onClose, onConfirm, onCreatePrintTransferQueue } = props;

  const setQueueData = useSetRecoilState(printPrepareQueueModalAtoms.queueData);

  const onSubmit = async ({ amount }: PreparePrintsModalFormData) => {
    const { printPrepareQueue } = await onCreatePrintTransferQueue({
      amount,
    });

    setQueueData(printPrepareQueue);
    onConfirm();
    onClose();
  };

  return onSubmit;
};
