import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  UpdatePartReleaseRateArgsDef,
  UpdatePartReleaseRateResponseDef,
} from './update-part-release-rate.types';

export const updatePartReleaseRate = async (
  body: UpdatePartReleaseRateArgsDef,
) => {
  return axios
    .post<UpdatePartReleaseRateResponseDef>(
      `${getCoreApiUrl('digital-backlog')}/update-parts-count`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
