import styled, { css } from 'styled-components';
import { transitions } from '../../../../../constants/styles';

export const StyledMobileHeaderToggleChild = styled.span`
  background: ${(prop) => {
    return prop.theme.colors.palette.monochrome_600;
  }};
  display: block;
  height: 1px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  width: 20px;

  ${transitions.base};

  &:nth-child(1) {
    top: calc(50% - 5px);
  }
  &:nth-child(2) {
    top: calc(50% + 5px);
  }
`;

export const StyledMobileHeaderToggleContainer = styled.div`
  height: 24px;
  width: 24px;
  outline: none;
  ${(props) => {
    return (
      props.active &&
      css`
        & > ${StyledMobileHeaderToggleChild} {
          &:nth-child(1) {
            top: 50%;
            transform: translateY(1px) rotate(315deg);
          }

          &:nth-child(2) {
            top: 50%;
            transform: rotate(405deg);
          }
        }
      `
    );
  }}
  &:hover {
    cursor: pointer;
  }
`;
