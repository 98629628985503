import PropTypes from 'prop-types';
import {
  StyledMobileHeaderToggleContainer,
  StyledMobileHeaderToggleChild,
} from './menu-icon-styles';

const MenuIcon = ({ isActive, onClick }) => {
  return (
    <StyledMobileHeaderToggleContainer active={isActive} onClick={onClick}>
      <StyledMobileHeaderToggleChild />
      <StyledMobileHeaderToggleChild />
    </StyledMobileHeaderToggleContainer>
  );
};

MenuIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MenuIcon;
