import { Tab, TabPanel, Tabs, TabsList } from 'gantri-components';
import { useEffect, useState } from 'react';
import { useAsync } from 'react-use';
import { vendorsApi } from '../../api';
import { Vendor as VendorType } from '../../api/vendors/vendors.types';
import { PageHeading } from '../../components/layout/page-heading';
import routePaths from '../../config/route-paths';
import { getActiveQueries } from '../../helpers/checks';
import { useNotification } from '../../hooks/useNotification';
import { useSyncPropertyWithQueryString } from '../../hooks/useSyncPropertyWithQueryString';
import { VendorInfo } from './components/vendor-info';
import { VendorPurchases } from './components/vendor-purchases';
import {
  HandleSave,
  OnUpdate,
  ToggleEditMode,
  VendorPageQueries,
  VendorTab,
} from './vendor.types';
import { useRouter } from '../../hooks';
import { useInvalidateFetchAllVendorNamesCache } from '../../api/vendors/routes';

export const Vendor = () => {
  const { isNewVendor } = getActiveQueries<VendorPageQueries>();
  const { currentValue: currentTab, updateQueryString } =
    useSyncPropertyWithQueryString<VendorTab>('tab', 'info');
  const { invalidateFetchAllVendorNamesCache } =
    useInvalidateFetchAllVendorNamesCache();

  const { location, navigate } = useRouter();
  const [unalteredVendor, setUnalteredVendor] = useState<VendorType>();
  const [editedVendor, setEditedVendor] = useState<VendorType>();
  const [isEditingDisabled, setIsEditingDisabled] =
    useState<boolean>(isNewVendor);

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();
  const [vendorIdString] = location.pathname.match(/\d+/) ?? [];
  const vendorId = Number(vendorIdString);

  const onUpdate: OnUpdate = (newVendorData) => {
    if (newVendorData) {
      setUnalteredVendor(newVendorData);
    } else {
      setEditedVendor(unalteredVendor);
    }
  };

  const handleSave: HandleSave = async ({ setIsEditing, vendor }) => {
    try {
      showLoading();
      setIsEditing(false);

      const { data } = await vendorsApi.updateVendor(vendor.id, vendor);

      onUpdate({
        ...data.vendor,
        activeInventories: unalteredVendor.activeInventories,
        inactiveInventories: unalteredVendor.inactiveInventories,
      });

      const params = new URLSearchParams(window.location.search);

      params.delete('isNewVendor');

      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${params.toString()}`,
      );
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to update inventory purchase.',
      });
    } finally {
      hideLoading();
      invalidateFetchAllVendorNamesCache();
    }
  };

  const toggleEditMode: ToggleEditMode = ({ isEditing, setIsEditing }) => {
    if (isEditing) {
      setEditedVendor(unalteredVendor);
      setIsEditingDisabled(false);
    } else {
      setIsEditingDisabled(true);
    }

    setIsEditing(!isEditing);
  };

  useEffect(() => {
    setEditedVendor(unalteredVendor);
  }, [unalteredVendor]);

  useAsync(async () => {
    try {
      showLoading();

      const { data } = await vendorsApi.getVendor(vendorId);

      setUnalteredVendor(data.vendor);
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to fetch vendor.' });
      navigate(routePaths.vendors);
    } finally {
      hideLoading();
    }
  }, []);

  return editedVendor ? (
    <>
      <PageHeading title={editedVendor.name} />
      <br />
      <Tabs value={currentTab}>
        <TabsList
          onValueChange={(value: VendorTab) => {
            updateQueryString(value);
          }}
        >
          <Tab label="Info" value="info" />
          <Tab label="Purchases" value="purchases" />
        </TabsList>
        <TabPanel value="info">
          <VendorInfo
            editedVendor={editedVendor}
            handleSave={handleSave}
            isEditingDisabled={isEditingDisabled}
            toggleEditMode={toggleEditMode}
          />
        </TabPanel>
        <TabPanel value="purchases">
          <VendorPurchases vendorId={vendorId} />
        </TabPanel>
      </Tabs>
    </>
  ) : null;
};
