import { Box, Stack, Typography } from 'gantri-components';
import { useFormikContext } from 'formik';
import { useGetVersionDetails } from '../../../../../../../../hooks/use-get-version-details';
import { StockJobsAssembleInventoryFormData } from '../../../../stock-jobs-assemble-inventory.schema';
import { useMoreMenu } from '../../../../../../../../../../../../hooks/use-more-menu';
import { VariantsWrapperGrid } from '../../assemble-inventory-variants-section.styles';
import { VariantSectionItemProps } from './variant-section-item.types';
import { getTemplateEditDisabledReason } from '../../../../../../../../../../../../components/common/jobs-template-table/helpers/get-template-edit-disabled-reason';
import { GlobalVariant } from '../../../../../../../../../../../../api/global-variants/routes/fetch-global-variants/fetch-global-variants.types';

export const VariantSectionItem = (props: VariantSectionItemProps) => {
  const { variant } = props;

  const { setFieldValue, values } =
    useFormikContext<StockJobsAssembleInventoryFormData>();

  const { isMinorVersion } = useGetVersionDetails();

  const handleDeleteVariantAndInventories = async (
    variant: GlobalVariant,
  ): Promise<void> => {
    const updatedVariantIds = values.variantIds.filter((id) => {
      return id !== variant.id;
    });

    await setFieldValue('variantIds', updatedVariantIds);

    const updatedAssembleVariantInventories =
      values.assembleVariantInventories.filter(({ globalVariantId }) => {
        return globalVariantId !== variant.id;
      });

    await setFieldValue(
      'assembleVariantInventories',
      updatedAssembleVariantInventories,
    );
  };

  const { MoreMenu } = useMoreMenu({
    data: variant,
    options: [
      {
        disabled: isMinorVersion,
        disabledTooltipProps: {
          description: getTemplateEditDisabledReason({
            isMinorVersion,
          }),
        },
        name: 'Delete',
        onOptionClick: handleDeleteVariantAndInventories,
      },
    ],
  });

  return (
    <VariantsWrapperGrid
      alignItems="start"
      columns="1fr max-content"
      horizontalPadding="2x"
      verticalPadding="x"
    >
      <Stack gap=".5x">
        <Typography text={variant?.name} />
        <Box>
          {variant?.options?.map(({ code, name }) => {
            return (
              <Typography
                key={[variant.id, code].join()}
                color="t2"
                text={name}
              />
            );
          })}
        </Box>
      </Stack>

      <MoreMenu />
    </VariantsWrapperGrid>
  );
};
