import {
  Typography,
  Stack,
  Conditional,
  Box,
  Flex,
  Button,
  Icon,
  useModal,
} from 'gantri-components';
import { Divider } from '../../../../components/divider';
import { SkuLevelAssets } from './components/sku-level-assets';
import { ProductLevelAssets } from './components/product-level-assets';
import { assetLevelViews } from './assets.constants';
import { BulkUploadModal } from './components/sku-level-assets/components/bulk-upload-modal';
import { useSyncPropertyWithQueryString } from '../../../../hooks/useSyncPropertyWithQueryString';
import { AssetLevelView } from './assets.types';
import { productQueryKeys } from '../../product.constants';

export const Assets = () => {
  const { currentValue: assetLevel, updateQueryString: updateAssetView } =
    useSyncPropertyWithQueryString<AssetLevelView>(
      productQueryKeys.assetLevel,
      assetLevelViews.sku,
    );

  const [showBulkUploadModal, hideBulkUploadModal] = useModal(() => {
    return <BulkUploadModal onClose={hideBulkUploadModal} />;
  }, []);

  return (
    <Stack gap="3x" paddingBottom="5x">
      <Stack gap="2x">
        <Flex gap="2x" justifyContent="space-between">
          <Flex alignItems="center" gap="2x">
            <Typography
              horizontalPadding="2x"
              text={assetLevelViews.sku}
              variant={assetLevel === assetLevelViews.sku ? 'h5' : 'p1'}
              verticalPadding="x"
              onClick={() => {
                updateAssetView(assetLevelViews.sku);
              }}
            />

            <Box height="100%" verticalPadding="x">
              <Divider borderLocation="left" height="100%" />
            </Box>

            <Typography
              fontWeight="normal"
              horizontalPadding="2x"
              text={assetLevelViews.product}
              variant={assetLevel === assetLevelViews.product ? 'h5' : 'p1'}
              verticalPadding="x"
              onClick={() => {
                updateAssetView(assetLevelViews.product);
              }}
            />
          </Flex>

          <Conditional condition={assetLevel === assetLevelViews.sku}>
            <Button
              icon={<Icon color="link" name="arrows:arrow_upload" />}
              text="Bulk Manage SKU Assets"
              variant="secondary"
              onClick={showBulkUploadModal}
            />
          </Conditional>
        </Flex>
      </Stack>

      <Conditional
        condition={assetLevel === assetLevelViews.sku}
        Fallback={<ProductLevelAssets />}
      >
        <SkuLevelAssets />
      </Conditional>
    </Stack>
  );
};
