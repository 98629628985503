import {
  JobStatus,
  jobStatuses,
} from '../../../../../../../../constants/options';
import { getIsCompletedJob } from '../../../../helpers/get-is-completed-job';
import { getIsInProgressPart } from '../../../../helpers/get-is-in-progress-part';
import { GetQuickViewJobsArgs } from './get-quick-view-jobs.types';

export const getQuickViewJobs = (args: GetQuickViewJobsArgs) => {
  const { part } = args;

  const isPartInProgress = getIsInProgressPart(part?.status);

  let completedJobs = part?.jobs
    .filter(getIsCompletedJob)
    .sort((jobA, jobB) => {
      return (
        new Date(jobA.endDate).getTime() - new Date(jobB.endDate).getTime()
      );
    });
  const numCompletedJobs = completedJobs.length;

  if (isPartInProgress) {
    const lastCompletedJob = completedJobs[numCompletedJobs - 1];

    const activeJobStatuses: JobStatus[] = [
      jobStatuses.ready,
      jobStatuses.inProgress,
    ];
    const activeJobs = part?.jobs.filter(({ status }) => {
      return activeJobStatuses.some((jobStatus) => {
        return jobStatus === status;
      });
    });

    const firstWaitingJob = part?.jobs.find(({ status }) => {
      return status === jobStatuses.waiting;
    });

    const jobsToRender = [
      lastCompletedJob,
      ...activeJobs,
      firstWaitingJob,
    ].filter(Boolean);

    return jobsToRender;
  }

  if (numCompletedJobs > 3) {
    completedJobs = completedJobs.slice(numCompletedJobs - 3, numCompletedJobs);
  }

  return completedJobs;
};
