import { Button, Conditional } from 'gantri-components';
import { partsApi } from '../../../../../../api';
import { useNotification } from '../../../../../../hooks/useNotification';
import { fullScreenModalFooterButtonSize } from '../../../../../jobs/components/modals/modals.constants';
import { FooterProps } from './footer.types';

export const Footer = (props: FooterProps) => {
  const {
    currentPartId,
    onClose,
    onUpdatePart,
    recommendedStocks,
    setStep,
    step,
    switchToPartId,
  } = props;

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const handlePartSwitching = async () => {
    try {
      showLoading();
      await partsApi.switchParts({
        currentPartId,
        switchToPartId,
      });
      setStep('PART_SWITCH_COMPLETE');
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to switch parts.',
      });
    } finally {
      hideLoading();
    }
  };

  const handleCloseModal = async () => {
    onClose();
    await onUpdatePart(true);
  };

  switch (step) {
    case 'PART_SWITCHING_TABLE':
      return (
        <Conditional
          condition={!!recommendedStocks.length}
          Fallback={
            <Button
              size={fullScreenModalFooterButtonSize}
              text="Done"
              onClick={onClose}
            />
          }
        >
          <Button
            size={fullScreenModalFooterButtonSize}
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            disabled={currentPartId === switchToPartId}
            size={fullScreenModalFooterButtonSize}
            text="Confirm"
            onClick={handlePartSwitching}
          />
        </Conditional>
      );

    case 'PART_SWITCH_COMPLETE':
      return (
        <Button
          size={fullScreenModalFooterButtonSize}
          text="Done"
          onClick={handleCloseModal}
        />
      );
    default:
      return null;
  }
};
