import {
  Cell,
  Conditional,
  Flex,
  Grid,
  Stack,
  Typography,
  useModal,
} from 'gantri-components';
import { Divider } from '../../../../components/divider';
import { PartStatusBadge } from '../../../../components/common/part-status-badge';
import { PartSummaryProps } from './part-summary.types';
import { NotApplicableText } from '../../../../components/common/not-applicable-text';
import { ColorSwatchAndLabel } from '../../../../components/common/color-swatch-and-label';
import { ZoomableThumbnail } from '../../../../components';
import { StyledMiniButton } from './part-summary.styles';
import { UpdatePartBatchModal } from './components/update-part-batch-modal';
import { ViewBatchAnchor } from '../../../batch/components/view-batch-anchor';
import { partStatuses } from '../../../../constants/options';

export const PartSummary = (props: PartSummaryProps) => {
  const { onUpdatePart, part, showLocationModal } = props;

  const { batchId, color, id, location, material, name, status, thumbnail } =
    part;

  const [showUpdateBatchModal, hideUpdateBatchModal] = useModal(() => {
    return (
      <UpdatePartBatchModal
        currentBatchId={batchId}
        partId={id}
        onClose={hideUpdateBatchModal}
        onUpdate={onUpdatePart}
      />
    );
  }, [batchId, id]);

  const isIncompletePart = [
    partStatuses.finishing,
    partStatuses.printing,
    partStatuses.waiting,
  ].some((partStatus) => {
    return partStatus === part?.status;
  });

  return (
    <Grid
      columns={{ lg: '12.4rem 1fr', sm: '1fr' }}
      gap="s3"
      paddingTop={{ lg: '3.2rem', md: '2.4rem' }}
    >
      <Cell hidden={{ lg: true, md: false }} width={{ lg: 2, sm: 1 }}>
        <Typography text={name} variant="h3" />
      </Cell>

      <ZoomableThumbnail heading={name} source="absolute" src={thumbnail} />

      <Stack gap="s3">
        <Typography hidden={{ lg: false, md: true }} text={name} variant="h3" />
        <Grid
          alignItems="stretch"
          columnGap="2.4rem"
          columns={{
            lg: 'repeat(8, max-content) 1fr',
            md: 'repeat(4, max-content) 1fr',
            sm: '1fr max-content 1fr max-content 1fr',
          }}
          rowGap="0.8rem"
        >
          <Stack gap="0.8rem">
            <Typography text="Color" textStyle="bold" />
            <ColorSwatchAndLabel colorCode={color} />
          </Stack>

          <Divider borderLocation="left" height="100%" />

          <Stack gap="0.8rem">
            <Typography text="Material" textStyle="bold" />
            <Conditional
              condition={!!material}
              Fallback={<NotApplicableText />}
            >
              <Typography text={material} />
            </Conditional>
          </Stack>

          <Divider borderLocation="left" height="100%" />

          <Stack gap="0.8rem">
            <Typography text="Status" textStyle="bold" />
            <Flex>
              <PartStatusBadge status={status} />
            </Flex>
          </Stack>

          <Cell hidden={{ lg: false, md: true }}>
            <Divider borderLocation="left" height="100%" />
          </Cell>

          <Cell width={{ lg: 1, md: 5 }}>
            <Stack gap="0.8rem">
              <Typography text="Location" textStyle="bold" />
              <Flex alignItems="center" gap="0.8rem" wrap="wrap">
                <Conditional condition={!!location?.name}>
                  <Typography text={location?.name} />
                </Conditional>
                <StyledMiniButton
                  size="small"
                  text="Update"
                  variant="ghost"
                  onClick={showLocationModal}
                />
              </Flex>
            </Stack>
          </Cell>

          <Cell hidden={{ lg: false, md: true }}>
            <Divider borderLocation="left" height="100%" />
          </Cell>

          <Cell width={{ lg: 1, md: 5 }}>
            <Stack gap="0.8rem">
              <Typography text="Batch" textStyle="bold" />
              <Flex alignItems="center" gap="0.8rem" wrap="wrap">
                <Conditional condition={!!batchId}>
                  <ViewBatchAnchor batchId={batchId} text={`#${batchId}`} />
                </Conditional>
                <StyledMiniButton
                  disabled={!isIncompletePart}
                  size="small"
                  text="Update"
                  variant="ghost"
                  onClick={showUpdateBatchModal}
                />
              </Flex>
            </Stack>
          </Cell>
        </Grid>
      </Stack>
    </Grid>
  );
};
