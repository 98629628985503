import { JobBlockStock } from '../../../../../../../../api/products/products.types';

/** This helper is intended to be used to validate the saved product data, not the current state of the stock jobs block form. */
export const getIsStockJobsDurationValid = ({
  isAccessory,
  stockJobsBlock,
}: {
  isAccessory: boolean;
  stockJobsBlock: JobBlockStock;
}) => {
  return isAccessory ? true : !!stockJobsBlock?.assembleDuration;
};
