import { OnRowClick, Table } from 'gantri-components';
import { useEffect, useState } from 'react';
import { ProductReviewsFilter } from '../../components/dropdowns';
import { PageWithTable } from '../../components/layout';
import { useTableFilters } from '../../components/common/table/hooks';
import { columns, sortOptions } from './product-reviews.constant';
import {
  FetchPaginatedProductReviewsArgs,
  ProductReviewsSortingField,
  Review as ReviewRecord,
  useFetchPaginatedProductReviewsQuery,
} from '../../api/users/routes';
import {
  pageName,
  productReviewsFiltersDefaults,
  productReviewsPageAtoms,
} from '../../components/dropdowns/product-reviews-filter/product-reviews-filter.atoms';
import { useTableColumnsSync } from '../../hooks';
import {
  ReviewDetailsProvider,
  useReviewDetailsPanel,
} from '../user/reviews/reviews-context';
import { ReviewDetailsPanel } from '../user/reviews/components';

export const ProductReviews = () => {
  return (
    <ReviewDetailsProvider>
      <ProductReviewsPage />
      <ReviewDetailsPanel />
    </ReviewDetailsProvider>
  );
};

const ProductReviewsPage = () => {
  const [filters, updateFilters] =
    useState<FetchPaginatedProductReviewsArgs>(undefined);

  const { onShowReviewDetails } = useReviewDetailsPanel();
  const columnsSyncProps = useTableColumnsSync('product-reviews');

  const { isLoading, ...data } = useFetchPaginatedProductReviewsQuery({
    filters,
  });

  const onReviewRowClick: OnRowClick<ReviewRecord> = ({ original }, event) => {
    event.preventDefault();
    event.stopPropagation();
    onShowReviewDetails(original.id, 'REVIEW', original.userId);
  };

  useEffect(() => {
    if (isLoading || (!isLoading && !data?.reviews)) return;

    setRecords(data?.reviews ?? []);
    setTotalCount(data?.totalReviews ?? 0);
  }, [isLoading, data]);

  const {
    filtersProps,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    ReviewRecord,
    FetchPaginatedProductReviewsArgs,
    ProductReviewsSortingField
  >({
    fetchRequest: updateFilters,
    filtersContent: <ProductReviewsFilter />,
    modalFiltersDetails: [
      {
        atom: productReviewsPageAtoms.filters.productId,
        defaultValue: productReviewsFiltersDefaults.filters.productId,
        payloadKey: 'productId',
      },
      {
        atom: productReviewsPageAtoms.filters.statuses,
        defaultValue: productReviewsFiltersDefaults.filters.statuses,
        payloadKey: 'statuses',
      },
      {
        atom: productReviewsPageAtoms.filters.colors,
        defaultValue: productReviewsFiltersDefaults.filters.colors,
        payloadKey: 'colors',
      },
    ],
    pageName,
    searchFilterDetails: {
      atom: productReviewsPageAtoms.search,
    },
    sortByFilterDetails: {
      atom: productReviewsPageAtoms.sortBy,
      defaultValue: productReviewsFiltersDefaults.sortBy,
    },
    sortOptions,
  });

  return (
    <PageWithTable pageTitle="Product Reviews">
      <PageWithTable.Header title="Product Reviews" />

      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          getRowProps={({ original }) => {
            return {
              'review-id': original?.id,
            };
          }}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          sorting={sortProps}
          onRowClick={onReviewRowClick}
          {...columnsSyncProps}
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
