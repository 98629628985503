import { Button, Table } from 'gantri-components';
import { useEffect, useState } from 'react';
import { GiftCode } from '../../api/gift-codes/gift-codes.types';
import { PageWithTable } from '../../components/layout';
import { GiftCodesFilter } from '../../components/dropdowns';
import { useNewGiftCode } from './use-new-gift-code';
import { useTableFilters } from '../../components/common/table/hooks';
import {
  FetchPaginatedGiftCodesArgs,
  useFetchPaginatedGiftCodesQuery,
  useInvalidateFetchPaginatedGiftCodesCache,
} from '../../api/gift-codes/routes/fetch-paginated-giftcodes';
import {
  giftCodesFiltersDefaults,
  giftCodesPageAtoms,
  pageName,
} from '../../components/dropdowns/gift-codes-filter/gift-codes-filter.atoms';
import { useGetGiftCodesColumns } from './hooks/use-get-gift-codes-columns';

export const GiftCodes = () => {
  const [filters, updateFilters] =
    useState<FetchPaginatedGiftCodesArgs>(undefined);

  const { invalidateFetchPaginatedGiftCodesCache } =
    useInvalidateFetchPaginatedGiftCodesCache();

  const { isLoading, ...data } = useFetchPaginatedGiftCodesQuery({
    args: filters,
  });

  const { onShowModal: onShowCreateNewCode } = useNewGiftCode({
    onReloadList: async () => {
      invalidateFetchPaginatedGiftCodesCache();
      await handleFetchRequest({ page: 1 });
    },
  });

  useEffect(() => {
    if (isLoading) return;

    setRecords(data?.giftCodes ?? []);
    setTotalCount(data.resultsCount ?? 0);
  }, [isLoading, data]);

  const {
    filtersProps,
    handleFetchRequest,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
  } = useTableFilters<GiftCode, FetchPaginatedGiftCodesArgs>({
    fetchRequest: updateFilters,
    filtersContent: <GiftCodesFilter />,
    modalFiltersDetails: [
      {
        atom: giftCodesPageAtoms.filters.type,
        defaultValue: giftCodesFiltersDefaults.filters.type,
        payloadKey: 'types',
      },
      {
        atom: giftCodesPageAtoms.filters.active,
        defaultValue: giftCodesFiltersDefaults.filters.active,
        payloadKey: 'active',
      },
    ],
    pageName,
    searchFilterDetails: {
      atom: giftCodesPageAtoms.search,
    },
  });

  const columns = useGetGiftCodesColumns({
    onRefresh: () => {
      invalidateFetchPaginatedGiftCodesCache();
    },
  });

  return (
    <PageWithTable pageTitle="Gift Codes">
      <PageWithTable.Header title="Gift Codes">
        <Button text="New Code" onClick={onShowCreateNewCode} />
      </PageWithTable.Header>

      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          stickyLastColumn
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
