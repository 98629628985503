import {
  Flex,
  Table,
  FileUploader,
  Icon,
  Conditional,
  Stack,
  Box,
  Grid,
  Cell,
} from 'gantri-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { sortBy } from 'lodash';
import { AxiosError } from 'axios';
import { productAtoms } from '../../../../../../../../product.atoms';
import { useBulkUploadColumns } from './hooks/use-bulk-upload-columns';
import { useFetchSkuAssets } from '../../../../../../../../../../api/products/routes/fetch-sku-assets';
import { bulkUploadModalAtoms } from '../../bulk-upload-modal.atoms';
import { bulkUploadsRestrictions } from '../../bulk-upload-modal.constants';
import { useValidateFile } from './hooks/use-validate-file';
import { FileUploaderDropZone } from './components/file-uploader-drop-zone';
import { useOnValidationCompleted } from './hooks/use-on-validation-completed';
import { BulkUploadHistoryPanel } from './components/bulk-upload-history-panel';
import { useSmallToast } from '../../../../../../../../../../hooks/use-small-toast';
import { SimpleErrorBoundary } from '../../../../../../../../../../components/simple-error-boundary';

export const BulkUploadModalContent = () => {
  const product = useRecoilValue(productAtoms.product);
  const skus = useRecoilValue(productAtoms.skus);

  const [processingFiles, setProcessingFiles] = useRecoilState(
    bulkUploadModalAtoms.processingFiles,
  );
  const [assetsBySku, setAssetsBySku] = useRecoilState(
    bulkUploadModalAtoms.assetsBySku,
  );

  const { isLoading } = useFetchSkuAssets({
    fetchArgs: {
      productId: product.id,
    },
    onSuccess: async ({ skuAssets: assetsBySku }) => {
      setAssetsBySku(assetsBySku);
    },
  });

  const tableData = useMemo(() => {
    return sortBy(Object.values(assetsBySku), ['sku']);
  }, [assetsBySku]);

  const {
    Toast: AssetsRemovedToast,
    setToastStatus,
    setToastText,
  } = useSmallToast();

  const showAssetsRemovedToast = () => {
    setToastText(
      'Assets removed from table. Please save changes to complete workflow.',
    );
    setToastStatus('success');
  };

  const columns = useBulkUploadColumns({ showAssetsRemovedToast });

  const validateFile = useValidateFile();

  const onValidationCompleted = useOnValidationCompleted();

  return (
    <Grid columns="1fr max-content" gap="0" height="100%">
      <Stack
        alignItems="stretch"
        horizontalPadding="3x"
        overflow="hidden"
        rows="max-content 1fr"
        verticalPadding="2x"
      >
        <Cell>
          <AssetsRemovedToast maxWidth="27.3rem" position="top-end" />
          <SimpleErrorBoundary>
            <FileUploader
              CustomContent={FileUploaderDropZone}
              expectedExtensions={[
                ...bulkUploadsRestrictions.expectedExtensions,
              ]}
              fileName={undefined}
              fileUrl={undefined}
              handleDeleteFile={undefined}
              handleFileSelected={async () => {
                // Nothing more is needed at this point as it's all handled in onValidationCompleted.
                // This function is executed on a per-file basis, where onValidationCompleted provides all selected files.
              }}
              isDisabled={isLoading || processingFiles}
              isDropZone
              isUploaderOnly
              maxFileSizeMB={bulkUploadsRestrictions.maxFileSizeMB}
              maxUploadsAllowed={bulkUploadsRestrictions.maxUploadsAllowed}
              minImageHeight={bulkUploadsRestrictions.minImageHeight}
              minImageWidth={bulkUploadsRestrictions.minImageWidth}
              onlyUseCustomValidationFn
              processing={processingFiles}
              validateFile={validateFile}
              validateSequentially
              variant="custom"
              onError={({ error, fallbackMessage }) => {
                const axiosError = error as AxiosError<{ error?: string }>;

                // eslint-disable-next-line no-console
                console.error(
                  axiosError?.response?.data?.error || fallbackMessage,
                );
              }}
              onValidationCompleted={onValidationCompleted}
              onValidationStarted={() => {
                setProcessingFiles(true);
              }}
            />
          </SimpleErrorBoundary>
        </Cell>

        <Box height="0" minHeight="100%">
          <Conditional
            condition={isLoading}
            Fallback={
              <SimpleErrorBoundary>
                <Table columns={columns} data={tableData} stickyLastColumn />
              </SimpleErrorBoundary>
            }
          >
            <Flex alignItems="center" justifyContent="center" minHeight="10rem">
              <Icon color="link" name="animated:loader" size="4rem" />
            </Flex>
          </Conditional>
        </Box>
      </Stack>

      <SimpleErrorBoundary>
        <BulkUploadHistoryPanel />
      </SimpleErrorBoundary>
    </Grid>
  );
};
