import { Stack, Typography } from 'gantri-components';
import routePaths from '../../../../../../../../../config/route-paths';
import { NotifyCustomerContentProps } from './notify-customer-content.types';

export const NotifyCustomerContent = (props: NotifyCustomerContentProps) => {
  const { orderId } = props;

  return (
    <Stack gap="0">
      <Typography
        align="center"
        text="An in-progress stock has been assigned to the order."
      />
      <Typography
        align="center"
        htmlText={`Please notify CX of potential delays to order: <a style="text-decoration: underline;" href="${routePaths.orders}/${orderId}" target="_blank">#${orderId}</a>.`}
      />
    </Stack>
  );
};
