import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { StartJobArgs, StartJobResponse } from './start-job.types';

export const startJob = ({ jobId, ...body }: StartJobArgs) => {
  return axios
    .post<StartJobResponse>(`${coreApiUrl}/jobs/${jobId}/start`, {
      ...body,
    })
    .then(({ data }) => {
      return data;
    });
};
