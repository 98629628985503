import { useRecoilValue, useSetRecoilState } from 'recoil';
import { addOrEditPartModalType } from '../../add-or-edit-part-modal.constants';
import { UseOnSubmitProps } from './use-on-submit.types';
import { AddOrEditPartsFormData } from '../../add-or-edit-part-modal.types';
import { productJobsTabAtoms } from '../../../../../../product-jobs.atoms';
import {
  useCreatePartTemplate,
  useUpdatePartTemplate,
} from '../../../../../../../../../../api/part-templates/routes';
import { productAtoms } from '../../../../../../../../product.atoms';
import { getGcodePrintQtyFileName } from '../../../../../../../../../../helpers/gcodes';
import { useInvalidateFetchProductCache } from '../../../../../../../../../../api/products/routes';
import { useGetVersionDetails } from '../../../../../../hooks/use-get-version-details';
import { useUpdateProductJobBlocks } from '../../../../../../../../../../api/products/routes/update-product-job-blocks';
import { jobBlockTypes } from '../../../../../../../../../../api/products/routes/update-product-job-blocks/update-product-job-blocks.types';
import { PartTemplateJobBlock } from '../../../../../../../../../../api/products/products.types';

export const useOnSubmit = (props: UseOnSubmitProps) => {
  const { initialValues, modalType, onClose } = props;

  const product = useRecoilValue(productAtoms.product);

  const { id: versionId } = useGetVersionDetails();

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();

  // ON ADD
  const setIsEditingPartId = useSetRecoilState(
    productJobsTabAtoms.isEditingPartId,
  );

  const { isLoading: isCreating, onCreatePartTemplate } = useCreatePartTemplate(
    {
      onSuccess: async ({ partTemplateId }) => {
        await invalidateFetchProductCache();

        setIsEditingPartId(partTemplateId);

        onClose();
      },
    },
  );

  const onAdd = async ({ id: _id, ...rest }: AddOrEditPartsFormData) => {
    await onCreatePartTemplate({
      ...rest,
      versionId: product.version.id,
    });
  };

  // ON EDIT
  const handleUpdateGcodeInJobBlocks = async ({
    jobBlocks,
    partName,
  }: {
    jobBlocks: PartTemplateJobBlock;
    partName: string;
  }) => {
    const printBlock = jobBlocks.printBlock.map((printBlock) => {
      const formattedGcodeFileName = getGcodePrintQtyFileName({
        numPrints: jobBlocks.printBlock.length,
        partName,
        printName: printBlock.name,
        productName: product.name,
        productVersion: product.version.version,
        weight: printBlock.weight,
      });

      return {
        ...printBlock,
        gcode: { ...printBlock.gcode, fileName: formattedGcodeFileName },
      };
    });

    await onUpdateProductJobBlocks({
      id: jobBlocks.id,
      jobBlock: {
        finishBlock: jobBlocks.finishBlock,
        printBlock,
        type: jobBlockTypes.part,
      },
    });
  };

  const { onUpdateProductJobBlocks } = useUpdateProductJobBlocks();
  const { isLoading: isUpdating, onUpdatePartTemplate } = useUpdatePartTemplate(
    {
      onSuccess: async (_response, { id, name }) => {
        const isNameUpdated = initialValues.name && name !== initialValues.name;

        if (isNameUpdated) {
          const jobBlocks = product.parts.find((part) => {
            return part.id === id;
          })?.jobBlock;

          await handleUpdateGcodeInJobBlocks({
            jobBlocks,
            partName: name,
          });
        }

        await invalidateFetchProductCache();

        onClose();
      },
    },
  );

  const onEdit = async (props: AddOrEditPartsFormData) => {
    await onUpdatePartTemplate({ ...props, versionId });
  };

  // ON SUBMIT
  const onSubmit = async (values: AddOrEditPartsFormData) => {
    switch (modalType) {
      case addOrEditPartModalType.add:
        return onAdd(values);

      case addOrEditPartModalType.edit:
        return onEdit(values);

      default:
        return undefined;
    }
  };

  return { onSubmit, processing: isCreating || isUpdating };
};
