import { Conditional, Icon, Typography } from 'gantri-components';
import { partPaintedStatuses } from '../../../../../../../../../../../../../../api/products/products.constants';
import { PartPaintedStatusProps } from './painted-status.types';

export const PaintedStatus = (props: PartPaintedStatusProps) => {
  const { disabled, value } = props || {};

  return (
    <Conditional condition={!!value}>
      <Typography
        color={disabled ? 't3' : 't1'}
        icon={
          <Conditional
            condition={value === partPaintedStatuses.painted}
            Fallback={
              <Icon color={disabled ? 't3' : 't2'} name="work:no_entry" />
            }
          >
            <Icon color={disabled ? 't3' : 't2'} name="work:water_drop" />
          </Conditional>
        }
        padding="x"
        text={value}
        verticalPadding="x"
      />
    </Conditional>
  );
};
