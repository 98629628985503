import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { Button } from 'gantri-components';
import { PayoutItems } from './payout-items';
import {
  StyledLeftContainer,
  StyledPayoutPageLayout,
  StyledRightContainer,
} from './payout-styles';
import {
  StyledPageSection,
  StyledPageSubsection,
} from '../../components/layout/page-layout-styles';
import MetaData from '../../components/meta-data';
import { PageHeading } from '../../components/layout/page-heading';
import { Payout as PayoutEntity } from '../../api/transactions/routes/get-payout/get-payout.types';
import { useNotification } from '../../hooks/useNotification';
import { PayoutForm } from './payout-form';
import { transactionsApi } from '../../api';
import { useInvalidateGetPaginatedPayoutsCache } from '../../api/transactions/routes/fetch-paginated-payouts';

export const Payout = () => {
  const { id: payoutId } = useParams<{ id: string }>();

  const { notify, notifyAxiosError, onInterceptRequest } = useNotification();

  const [updateInProgress, setUpdateInProgress] = useState<boolean>();
  const [payout, setPayout] = useState<PayoutEntity>();
  const [formStatus, setFormStatus] = useState<{
    isValid: boolean;
    values: Partial<PayoutEntity>;
  }>({ isValid: false, values: undefined });

  const { isValid, values: formValues } = formStatus;

  const { invalidateGetPaginatedPayoutsCache } =
    useInvalidateGetPaginatedPayoutsCache();

  const onUpdatePayout = async () => {
    if (!isValid) return;

    try {
      setUpdateInProgress(true);

      const { data } = await transactionsApi.updatePayout({
        id: formValues.id,
        payout: formValues as PayoutEntity,
      });

      await invalidateGetPaginatedPayoutsCache();

      notify(data.notice);

      if (data) {
        setPayout(data.data);
      }
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to update payout.' });
    } finally {
      setUpdateInProgress(false);
    }
  };

  useAsync(async () => {
    if (payoutId) {
      await onInterceptRequest(async () => {
        try {
          const { data } = await transactionsApi.getPayout({
            id: Number(payoutId),
          });

          if (data.success) {
            setPayout(data.payout);
          }
        } catch (error: unknown) {
          notifyAxiosError({
            error,
            fallbackMessage: 'Unable to fetch payout.',
          });
        }
      });
    }
  }, [payoutId]);

  return payout ? (
    <>
      <MetaData title={`Payout #${payoutId}`} />
      <StyledPageSection>
        <PageHeading title={`Payout #${payoutId}`}>
          <Button
            disabled={!isValid}
            processing={updateInProgress}
            text="Update"
            onClick={onUpdatePayout}
          />
        </PageHeading>
        <StyledPayoutPageLayout>
          <StyledLeftContainer>
            <StyledPageSubsection>
              <PayoutItems items={payout?.items || []} />
            </StyledPageSubsection>
          </StyledLeftContainer>
          <StyledRightContainer>
            <PayoutForm payout={payout} onFormStatusChange={setFormStatus} />
          </StyledRightContainer>
        </StyledPayoutPageLayout>
      </StyledPageSection>
    </>
  ) : null;
};
