import {
  Conditional,
  Flex,
  Icon,
  Stack,
  Table,
  Typography,
} from 'gantri-components';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useGetPrintTransferQueues } from '../../../../../../../../api/queue/print-transfer';
import { manageQueuesModalAtoms } from '../../../manage-queues-modal.atoms';
import { useQueuesListContentColumns } from './hooks/use-queues-list-content-columns';

export const QueuesListContent = () => {
  const [queues, setQueues] = useRecoilState(manageQueuesModalAtoms.queues);

  const columns = useQueuesListContentColumns();

  const { isLoading, printPrepareQueues } = useGetPrintTransferQueues();

  useEffect(() => {
    setQueues(printPrepareQueues || []);
  }, [printPrepareQueues]);

  return (
    <Stack alignContent="center">
      <Conditional
        condition={isLoading}
        Fallback={
          <Conditional
            condition={!!queues?.length}
            Fallback={<Typography align="center" text="No existing queues." />}
          >
            <Table columns={columns} data={queues} />
          </Conditional>
        }
      >
        <Flex justifyContent="center">
          <Icon name="animated:loader" size="2.4rem" />
        </Flex>
      </Conditional>
    </Stack>
  );
};
