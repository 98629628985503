import styled from 'styled-components';
import { Box } from 'gantri-components';
import { shadows } from '../../../../../constants/styles';

export const StyledAccountDropdown = styled.ul`
  min-width: 20rem;
  width: 100%;
  background-color: ${(props) => {
    return props.theme.colors.surfaces.monochrome.t2;
  }};
  border: 1px solid
    ${(props) => {
      return props.theme.colors.dividers.t1;
    }};
  border-radius: 3px;
  ${shadows.overhang}
`;

export const StyledAccountDropdownItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  cursor: pointer;
`;

export const StyledAccountDropdownDivider = styled.div`
  height: 1px;
  background-color: ${(props) => {
    return props.theme.colors.dividers.t2;
  }};
`;

export const StyledToggleBox = styled(Box)<{ themeActive?: string }>`
  border: 1px solid;
  border-radius: 4px;
  border-color: ${({ theme }) => {
    return theme.colors.dividers.t2;
  }};
`;
