import * as yup from 'yup';
import {
  StockJobInventory,
  JobBlockStock,
} from '../../../../../../../../api/products/products.types';
import { stockJobInventorySchema } from '../../stock-jobs-section.schema';

export interface StockJobsPackInventoryFormData {
  id: number;
  packInventory: StockJobInventory[];
}

export const getStockJobsPackInventoryInitialValues = ({
  stockJobsBlock,
}: {
  stockJobsBlock: JobBlockStock;
}): StockJobsPackInventoryFormData => {
  return {
    id: stockJobsBlock?.id,
    packInventory: stockJobsBlock?.packInventory || [],
  };
};

export const stockJobsPackInventoryFormikSchema: yup.SchemaOf<StockJobsPackInventoryFormData> =
  yup.object().shape({
    id: yup.number().required(),
    packInventory: stockJobInventorySchema,
  });
