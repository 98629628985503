import styled, { css } from 'styled-components';
import Grid from '../../../components/common/grid';
import Cell from '../../../components/common/cell';
import {
  SIDEBAR_WIDTH,
  SIDEBAR_WIDTH_COMPACT,
} from '../../../pages/product/components/info/info.constants';
import { transitions } from '../../../constants/styles';
import { getActiveEnv } from '../../../helpers/get-active-env';

export const StyledDesktopSidebarContainer = styled.div<{
  isSidebarOpen: boolean;
}>`
  ${({ isSidebarOpen, theme }) => {
    return css`
      background-color: ${theme.colors.surfaces.monochrome.t2};
      ${transitions.base}
      transition-property: width;
      overflow-y: auto;
      width: ${isSidebarOpen ? SIDEBAR_WIDTH : SIDEBAR_WIDTH_COMPACT};
      height: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    `;
  }}
`;

export const StyledDesktopSidebarHeader = styled(Grid)<{
  hasDropShadow: boolean;
  isSidebarOpen?: boolean;
}>`
  ${({ hasDropShadow, isSidebarOpen, theme }) => {
    const { isDevelopEnv, isStagingEnv } = getActiveEnv();

    return css`
      position: sticky;
      top: 0;
      z-index: 2;
      padding: ${isSidebarOpen ? '1.4rem 0 1.4rem 1.6rem' : '0.5rem'};
      margin-bottom: 1rem;
      min-height: 8rem;
      background-color: ${theme.colors.surfaces.monochrome.t2};
      border-bottom: 1px solid ${theme.colors.dividers.t2};
      user-select: none;
      height: 8rem;
      box-shadow: ${hasDropShadow &&
      '0 4px 4px rgba(0, 0, 0, 0.06)'}; // Should not change colors with theme

      ${isDevelopEnv &&
      css`
        background-color: ${theme.colors.surfaces.warning.t1};
        border-top: 0.4rem solid ${theme.colors.surfaces.warning.t1};
      `}

      ${isStagingEnv &&
      css`
        background-color: ${theme.colors.surfaces.green.t1};
        border-top: 0.4rem solid ${theme.colors.surfaces.green.t1};
      `}
    `;
  }}
`;

export const StyledCell = styled(Cell)<{
  isSidebarOpen?: boolean;
}>`
  ${({ isSidebarOpen }) => {
    return (
      !isSidebarOpen &&
      css`
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
      `
    );
  }};
`;
