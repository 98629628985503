import { Dropdown, FormikInput } from 'gantri-components';
import { useFormikContext } from 'formik';
import { materials } from '../../../../../../../../../../../../constants/parts';
import { AddOrEditPartsFormData } from '../../../../add-or-edit-part-modal.types';
import { MaterialOption } from './part-material.types';
import { partPaintedStatuses } from '../../../../../../../../../../../../api/products/products.constants';

export const PartMaterial = () => {
  const { setFieldValue, values } = useFormikContext<AddOrEditPartsFormData>();
  const { material } = values;

  const materialChoices = Object.values(materials).map((value) => {
    return {
      label: value,
      value,
    };
  });

  const handleMaterialEdit = async (item: MaterialOption) => {
    const material = item?.value;
    const isTranslucent = material === materials.translucent;

    const paintedStatus = isTranslucent
      ? partPaintedStatuses.unpainted
      : values.paintedStatus;
    const colorOverride = isTranslucent ? [] : values.colorOverride;
    const hasBondo = isTranslucent ? false : values.hasBondo;

    await setFieldValue('paintedStatus', paintedStatus);
    await setFieldValue('hasBondo', hasBondo);
    await setFieldValue('machineType', '');
    await setFieldValue('material', material);
    await setFieldValue('colorOverride', colorOverride);
  };

  return (
    <FormikInput
      Field={
        <Dropdown
          items={materialChoices}
          labelPosition="top"
          labelText="Material"
          maxHeight={400}
          placeholder={material || 'Select material'}
          onSelect={handleMaterialEdit}
        />
      }
      fieldVariant="standard"
      name="material"
      required
    />
  );
};
