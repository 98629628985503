import { Button } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { useStartJob } from '../../../../../../../../../../api/jobs/routes';
import { fullScreenModalFooterButtonSize } from '../../../../../../modals.constants';
import { startFinishStageModalAtoms } from '../../../start-finishing-stage-job-modal.atoms';
import { ConfirmStartFooterProps } from './confirm-start-footer.types';

export const ConfirmStartFooter = (props: ConfirmStartFooterProps) => {
  const { handleCloseModal, jobId } = props;

  const setStep = useSetRecoilState(startFinishStageModalAtoms.step);
  const setStockLocation = useSetRecoilState(
    startFinishStageModalAtoms.stockLocation,
  );
  const setVlmNotified = useSetRecoilState(
    startFinishStageModalAtoms.vlmNotified,
  );
  const setUpdateOnClose = useSetRecoilState(
    startFinishStageModalAtoms.updateOnClose,
  );

  const { isLoading, onStartJob } = useStartJob({
    onSuccess: async ({ location, vlmNotified }) => {
      if (vlmNotified) {
        setVlmNotified(vlmNotified);
      }

      setUpdateOnClose(true);
      setStockLocation(location);

      if (location) {
        setStep('PUT_IN_VLM');
      } else {
        setStep('VLM_IS_FULL');
      }
    },
  });

  const handleStartJob = async () => {
    await onStartJob({
      callVlm: true,
      jobId,
    });
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        processing={isLoading}
        size={fullScreenModalFooterButtonSize}
        text="Yes"
        onClick={handleStartJob}
      />
    </>
  );
};
