import { useRecoilValue } from 'recoil';
import { shipmentActionsModalSteps } from '../../shipment-actions-modal.constants';
import { shipmentActionsModalAtoms } from '../../shipment-actions-modal.atoms';
import { ShipmentActionsModalFooterPropsDef } from './shipment-actions-modal-footer.types';
import { StepCancelShipmentFooter } from '../step-cancel-shipment/step-cancel-shipment-footer';
import { StepShipManuallyFooter } from '../step-ship-manually/step-ship-manually-footer';
import { StepShipNormallyFooter } from '../step-ship-ship-normally/step-ship-normally-footer';
import { StepShippingErrorFooter } from '../step-shipping-error/step-shipping-error-footer';

export const ShipmentActionsModalFooter = (
  props: ShipmentActionsModalFooterPropsDef,
) => {
  const { handleCloseModal, order } = props;

  const step = useRecoilValue(shipmentActionsModalAtoms.step);

  switch (step) {
    case shipmentActionsModalSteps.cancelShipment:
      return <StepCancelShipmentFooter handleCloseModal={handleCloseModal} />;

    case shipmentActionsModalSteps.shipManually:
      return (
        <StepShipManuallyFooter
          handleCloseModal={handleCloseModal}
          order={order}
        />
      );

    case shipmentActionsModalSteps.shipNormally:
      return <StepShipNormallyFooter handleCloseModal={handleCloseModal} />;

    case shipmentActionsModalSteps.shippingError:
      return <StepShippingErrorFooter handleCloseModal={handleCloseModal} />;

    default:
      return null;
  }
};
