import { FileUploader, Grid, Icon, Stack, Tooltip } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { SectionTitle } from '../../../../../../../../../../../../components/layout/section-title';
import { Label } from '../../../../../../../../../../../../components/label';
import { useCloudinaryFileUploader } from '../../../../../../../../../../../../hooks/use-cloudinary-file-uploader';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import { ConfigureCutSheetFormSchemaDef } from '../../configure-cut-sheets-modal.types';
import { configureCutSheetsModalAtoms } from '../../configure-cut-sheets-modal.atoms';

export const CutSheetLineDrawings = () => {
  const product = useRecoilValue(productAtoms.product);

  const setProcessingUpload = useSetRecoilState(
    configureCutSheetsModalAtoms.processingUpload,
  );

  const { setFieldValue, values } =
    useFormikContext<ConfigureCutSheetFormSchemaDef>();

  const {
    fileUploaderProps: frontViewFileUploaderProps,
    processing: processingFrontView,
  } = useCloudinaryFileUploader<'products'>({
    directory: 'products',
    fileName: values.frontViewPhoto,
    fileType: '2d-drawings',
    handleUploadsComplete: async ([{ fileName }]) => {
      await setFieldValue('frontViewPhoto', fileName);
    },
    identifiers: {
      productId: product.id,
    },
    onFileDelete: async () => {
      await setFieldValue('frontViewPhoto', null);
      await setFieldValue('closeable', false);
    },
    onFileRecovered: async (updatedFileName) => {
      await setFieldValue('frontViewPhoto', updatedFileName);
    },
    transformFileName: ({ applyUuid, removeWhitespace }) => {
      return applyUuid(
        removeWhitespace(`${product.id}_cut_sheet_2d-drawing-front-view`),
      );
    },
  });

  const {
    fileUploaderProps: sideViewFileUploaderProps,
    processing: processingSideView,
  } = useCloudinaryFileUploader<'products'>({
    directory: 'products',
    fileName: values.sideViewPhoto,
    fileType: '2d-drawings',
    handleUploadsComplete: async ([{ fileName }]) => {
      await setFieldValue('sideViewPhoto', fileName);
    },
    identifiers: {
      productId: product.id,
    },
    onFileDelete: async () => {
      await setFieldValue('sideViewPhoto', null);
      await setFieldValue('closeable', false);
    },
    onFileRecovered: async (updatedFileName) => {
      await setFieldValue('sideViewPhoto', updatedFileName);
    },
    transformFileName: ({ applyUuid, removeWhitespace }) => {
      return applyUuid(
        removeWhitespace(`${product.id}_cut_sheet_2d-drawing-side-view`),
      );
    },
  });

  useEffect(() => {
    setProcessingUpload(processingFrontView || processingSideView);
  }, [processingFrontView, processingSideView]);

  return (
    <Stack gap="2x">
      <SectionTitle
        icon={
          <Tooltip
            maxWidth="25.3rem"
            position="top-start"
            title="Please provide 2D drawings with measurement indicators. System will populate specific values."
          >
            <Icon name="alert:i_circle" />
          </Tooltip>
        }
        iconPosition="right"
        text="Provide line drawings"
        variant="h5"
      />

      <Grid columns="repeat(2, max-content)">
        <Stack gap="x">
          <Label text="Front View" />

          <FileUploader
            {...frontViewFileUploaderProps}
            minImageHeight={200}
            minImageWidth={200}
            thumbnailSize="10rem"
            variant="thumbnail"
          />
        </Stack>

        <Stack gap="x">
          <Label text="Side View" />

          <FileUploader
            {...sideViewFileUploaderProps}
            minImageHeight={200}
            minImageWidth={200}
            thumbnailSize="10rem"
            variant="thumbnail"
          />
        </Stack>
      </Grid>
    </Stack>
  );
};
