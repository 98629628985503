import { Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { bulkUploadModalAtoms } from '../../bulk-upload-modal.atoms';

export const BulkUploadExitWarningModalContent = () => {
  const numFilesAdded = useRecoilValue(bulkUploadModalAtoms.numFilesAdded);
  const numFilesRemoved = useRecoilValue(bulkUploadModalAtoms.numFilesRemoved);

  return (
    <Stack gap="2x">
      <Stack gap="0">
        <Typography
          align="center"
          text="You are about to exit the bulk upload page."
        />
        <Typography
          align="center"
          text="You will lose the following changes:"
        />
      </Stack>

      <Typography
        align="center"
        text={[
          numFilesAdded && `${numFilesAdded} asset(s) added`,
          numFilesRemoved && `${numFilesRemoved} asset(s) removed`,
        ]
          .filter(Boolean)
          .join(', ')}
        textStyle="bold"
      />
      <Typography align="center" text="Are you sure you want to exit?" />
    </Stack>
  );
};
