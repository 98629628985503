import { Conditional, Flex, Icon, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';
import { useFormikContext } from 'formik';
import { useAsync } from 'react-use';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import {
  productFetchTypes,
  useFetchProduct,
} from '../../../../../../../../../../../../api/products/routes';
import { LifestylePhotoData } from '../../../../../../../../../../../../api/products/products.types';
import { CutSheetLifestylePhotoItem } from './components/cut-sheet-lifestyle-photo-item';
import { ConfigureCutSheetFormSchemaDef } from '../../configure-cut-sheets-modal.types';

export const CutSheetLifestylePhotos = () => {
  const product = useRecoilValue(productAtoms.product);
  const selectedSku = useRecoilValue(productAtoms.selectedSku);

  const { setFieldValue, values } =
    useFormikContext<ConfigureCutSheetFormSchemaDef>();

  const [lifestylePhotos, setLifeStylePhotos] = useState<LifestylePhotoData[]>(
    [],
  );

  const { isLoading } = useFetchProduct({
    fetchArgs: {
      id: product.id,
      sku: selectedSku,
      type: productFetchTypes.assets,
    },
    onSuccess: async ({ product }) => {
      setLifeStylePhotos(product.assets.product.lifestylePhotos);
    },
  });

  useAsync(async () => {
    if (values.requires2LifestylePhotos && lifestylePhotos.length === 1) {
      await setFieldValue('requires2LifestylePhotos', false);
    }
  }, [lifestylePhotos.length, values.requires2LifestylePhotos]);

  return (
    <Conditional
      condition={isLoading}
      Fallback={
        <Conditional
          condition={!!lifestylePhotos.length}
          Fallback={
            <Typography color="t2" text="No lifestyle images provided." />
          }
        >
          <Flex gap="x" style={{ alignContent: 'flex-start' }} wrap="wrap">
            {lifestylePhotos.map((photo) => {
              return (
                <CutSheetLifestylePhotoItem
                  key={photo.fileName}
                  getIsDisabled={({ isSelected }) => {
                    return !isSelected && values.requires2LifestylePhotos
                      ? !!values.firstLifestyleViewPhoto &&
                          !!values.secondLifestyleViewPhoto
                      : false;
                  }}
                  photo={photo}
                />
              );
            })}
          </Flex>
        </Conditional>
      }
    >
      <Flex justifyContent="center" padding="3x">
        <Icon color="link" name="animated:loader" size="2.4rem" />
      </Flex>
    </Conditional>
  );
};
