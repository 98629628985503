import axios from 'axios';
import { getCoreApiUrl } from '../../../helpers/auth';
import { convertQueriesToString } from '../../../helpers/checks';
import {
  GetPaginatedPressRecordsArgs,
  GetPaginatedPressRecordsResponse,
} from './get-paginated-press-records.types';

export const getPaginatedPressRecords = (
  args: GetPaginatedPressRecordsArgs,
): Promise<GetPaginatedPressRecordsResponse> => {
  const { count, dateRange, sortingField, sortingType, type, ...rest } = args;

  return axios
    .get<GetPaginatedPressRecordsResponse>(
      `${getCoreApiUrl('press')}/paginate${convertQueriesToString({
        filter: JSON.stringify({
          dateRange,
          type,
        }),
        limit: count,
        sort: JSON.stringify({ field: sortingField, type: sortingType }),
        ...rest,
      })}`,
    )
    .then(({ data }) => {
      return data;
    });
};
