import React, { FC } from 'react';
import { Stack } from 'gantri-components';
import { StockTimelineProps } from './stock-timeline.types';
import StockTimelineItem from '../../../../components/stock/stock-timeline-item';
import Timeline from '../../../../components/common/timeline';
import { SectionTitle } from '../../../../components/layout/section-title';

export const StockTimeline: FC<StockTimelineProps> = (props) => {
  const { timelines } = props;

  return (
    <Stack gap="2x">
      <SectionTitle text="Timeline" />
      <Timeline
        isTitleVisible={false}
        items={timelines || []}
        renderItem={StockTimelineItem}
      />
    </Stack>
  );
};
