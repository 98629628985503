import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import {
  Button,
  Cell,
  Conditional,
  Flex,
  Grid,
  Stack,
} from 'gantri-components';
import { PageHeading } from '../../components/layout/page-heading';
import { PartSummary } from './components/part-summary';
import { usePartLocations } from '../../components/common/part-location-information/use-part-locations';
import { PartDetails } from './components/part-details';
import { PartNotes } from './components/part-notes';
import { PartJobs } from './components/part-jobs';
import { PartTimeline } from './components/part-timeline';
import { MetaData } from '../../components/meta-data/metaData';
import { PartAttention } from './components/part-attention';
import { PartPageMoreMenu } from './components/part-page-more-menu';
import { useFetchPartDetails } from './hooks/use-fetch-part-details';

export const Part = () => {
  const { id } = useParams<{ id: string }>();

  const partId = Number(id);

  const { fetchPartDetails, part, setPart, stock } = useFetchPartDetails({
    partId,
  });

  const { showLocationModal } = usePartLocations({
    id: part?.id,
    location: part?.location,
    locationTimelines: part?.locationTimelines,
    onLocationUpdate: ({ location, locationTimelines }) => {
      setPart({
        ...part,
        location,
        locationTimelines,
      });
    },
    source: 'stock',
    stockId: null,
  });

  useAsync(async () => {
    await fetchPartDetails();
  }, [partId]);

  return (
    <>
      <MetaData title={`Part #${partId}`} />
      <PageHeading title={`Part #${partId}`}>
        <Conditional condition={!!part}>
          <Flex gap="0.8rem">
            <Cell hidden={{ lg: false, sm: true }}>
              <Button text="Update Location" onClick={showLocationModal} />
            </Cell>
            <PartPageMoreMenu part={part} onUpdatePart={fetchPartDetails} />
          </Flex>
        </Conditional>
      </PageHeading>

      <Conditional condition={!!part}>
        <Stack
          gap={{ lg: '5.6rem', md: '4rem' }}
          height="unset"
          paddingBottom="4.8rem"
        >
          <PartSummary
            part={part}
            showLocationModal={showLocationModal}
            onUpdatePart={fetchPartDetails}
          />
          <Grid
            alignItems="baseline"
            columns={{ lg: 2, md: 1 }}
            gap={{ lg: '3.2rem', md: '4rem' }}
          >
            <PartDetails part={part} stock={stock} />
            <PartNotes part={part} setPart={setPart} />
          </Grid>
          <PartAttention part={part} />
          <PartJobs
            key={
              // ? Adding key ensures that page does not crash when navigating
              // ? between part detail pages due to PartJobs atoms snapshot
              part?.id
            }
            fetchPartDetails={fetchPartDetails}
            part={part}
          />
          <PartTimeline part={part} />
        </Stack>
      </Conditional>
    </>
  );
};
