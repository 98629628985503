import { Cell, Grid, Stack } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { productAtoms } from '../../../../product.atoms';
import { Label } from '../../../../../../components/label';
import { SectionTitle } from '../../../../../../components/layout/section-title';
import { UploadStack } from './components/upload-stack';
import { CutSheetsSection } from './components/cut-sheets-section';

export const InfoTabDownloads = () => {
  const { downloads } = useRecoilValue(productAtoms.product);

  return (
    <Stack gap="2x">
      <SectionTitle text="Downloads" />

      <Grid
        alignItems="top"
        columnGap="x"
        columns="16rem max-content"
        rowGap="3x"
      >
        <Label text="Cut Sheet" />
        <Cell>
          <CutSheetsSection />
        </Cell>

        <Label text="Install Instructions" />
        <Cell>
          <UploadStack
            expectedExtensions={['pdf']}
            fileNames={downloads?.instructions}
            maxFiles={1}
            type="instructions"
          />
        </Cell>

        <Label text="Models: 2D" />
        <Cell>
          <UploadStack
            expectedExtensions={['dwg']}
            fileNames={downloads?.models2D}
            isRawFile
            type="models2D"
          />
        </Cell>

        <Label text="Models: 3D" />
        <Cell>
          <UploadStack
            expectedExtensions={['dwg', 'obj', 'skp', 'rfa']}
            fileNames={downloads?.models3D}
            isRawFile
            maxFileSizeMB={50}
            type="models3D"
          />
        </Cell>
      </Grid>
    </Stack>
  );
};
