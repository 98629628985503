import { Stack } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledBackgroundStack = styled(Stack)<{
  $backgroundColor: string;
}>`
  ${({ $backgroundColor }) => {
    return css`
      background-color: ${$backgroundColor};
    `;
  }}
`;
