import { Icon, Stack, Typography } from 'gantri-components';
import { ConfirmUpdateContentProps } from './confirm-update-content.types';

export const ConfirmUpdateContent = (props: ConfirmUpdateContentProps) => {
  const { orderId } = props;

  return (
    <Stack
      gap="4rem"
      horizontalPadding="0.8rem"
      justifyItems="center"
      justifySelf="center"
      maxWidth="48.8rem"
      verticalPadding="1.2rem"
    >
      <Typography text="Are you sure you want to update this stock to the latest version?" />
      <Typography
        icon={<Icon color="warning" name="alert:warning_triangle" />}
        text={`Note: Once updated this stock will be cancelled. A new stock will be created in the latest version${
          orderId ? ` and assigned to Order #${orderId}` : ''
        }.`}
      />
    </Stack>
  );
};
