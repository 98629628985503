import {
  Button,
  Cell,
  Conditional,
  Flex,
  FormikInput,
  Grid,
  Icon,
  Stack,
  Typography,
} from 'gantri-components';
import { useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { useAsync } from 'react-use';
import { partsApi } from '../../api';
import MetaData from '../../components/meta-data';
import { PageHeading } from '../../components/layout/page-heading';
import { useNotification } from '../../hooks/useNotification';
import { useSyncPropertyWithQueryString } from '../../hooks/useSyncPropertyWithQueryString';
import { SearchPartSummary } from './components/search-part-summary';
import { ClearPartSearch, FormikSearch } from './search-part.types';
import { schema } from './search-part.schema';
import { searchFieldName } from './search-part.constants';
import { Part } from '../../api/parts/routes/get-part/get-part.types';
import { SearchPartProvider } from './search-part.context';
import { StyledFormikInputContainer } from './search-part.styles';
import { SandingMinutesProgress } from '../../components/sanding-minutes-progress';

export const SearchPart = () => {
  const { currentValue: partIdQuery, updateQueryString } =
    useSyncPropertyWithQueryString('partId', '');

  const formikRef = useRef<FormikSearch>();
  const [part, setPart] = useState<Part>();
  /** Extracted to separate state to prevent flashing of content. */
  const [noResultsFound, setNoResultsFound] = useState<boolean>(false);

  const { notifyAxiosError, onInterceptRequest } = useNotification();

  const submitForm = async (values: FormikSearch['values']) => {
    const partId = values[searchFieldName];

    await onInterceptRequest(async () => {
      try {
        if (partId) {
          const { data } = await partsApi.getPart(Number(partId));

          updateQueryString(partId);
          setPart(data.part[0]);
          setNoResultsFound(false);
        }
      } catch (error: unknown) {
        setNoResultsFound(true);
        notifyAxiosError({
          error,
          fallbackMessage: `Unable to fetch part with ID ${partId}.`,
        });

        setPart(null);
      }
    });
  };

  const clearPartSearch: ClearPartSearch = (options) => {
    formikRef.current.setFieldValue(searchFieldName, '');

    const searchField = document.querySelector<HTMLInputElement>(
      `[name="${searchFieldName}"]`,
    );

    searchField.focus();

    if (options?.clearCurrentResults) {
      updateQueryString('');
      setPart(null);
    }
  };

  const getPartDetails = async () => {
    if (partIdQuery) {
      await formikRef?.current?.submitForm();
    }
  };

  useAsync(async () => {
    await getPartDetails();
  }, []);

  return (
    <Stack gap="1.6rem" height="unset" paddingBottom="2rem">
      <MetaData title="Search Part" />
      <PageHeading title="Search Part">
        <SandingMinutesProgress height="8rem" width="24rem" />
      </PageHeading>
      <Cell>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Typography
            paddingBottom="0.6rem"
            text="Enter Part ID #:"
            textStyle="bold"
            variant="p1"
          />
        </Flex>
        <Formik
          initialValues={{
            [searchFieldName]: partIdQuery,
          }}
          innerRef={formikRef}
          validationSchema={schema}
          onSubmit={submitForm}
        >
          {({ values }) => {
            return (
              <Form>
                <Grid columns={{ lg: '1fr max-content', sm: '1fr' }} gap="1rem">
                  <StyledFormikInputContainer>
                    <FormikInput
                      aria-autocomplete="both"
                      aria-haspopup="false"
                      aria-label="Search for part ID"
                      autoFocus
                      name={searchFieldName}
                      placeholder="Part ID#..."
                      size="large"
                      type="number"
                    />
                    <Conditional condition={!!values[searchFieldName]}>
                      <Flex
                        alignItems="center"
                        height="100%"
                        horizontalPadding="2x"
                        style={{
                          cursor: 'pointer',
                          position: 'absolute',
                          right: 0,
                        }}
                        width="unset"
                        onClick={() => {
                          clearPartSearch();
                        }}
                      >
                        <Icon name="ui-control:x" />
                      </Flex>
                    </Conditional>
                  </StyledFormikInputContainer>
                  <Button
                    minWidth={{ lg: '20rem', sm: 'auto' }}
                    size="large"
                    text="Search Part"
                    type="submit"
                  />
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Cell>
      <Conditional condition={!!part}>
        <SearchPartProvider onRefreshCurrentPart={getPartDetails}>
          <SearchPartSummary
            clearPartSearch={clearPartSearch}
            getPartDetails={getPartDetails}
            part={part}
          />
        </SearchPartProvider>
      </Conditional>
      <Conditional condition={noResultsFound}>
        <Stack gap="0.8rem">
          <Typography
            text={`No search results found for "${partIdQuery}"`}
            variant="h4"
          />
          <Typography text="Please try another ID number." />
        </Stack>
      </Conditional>
    </Stack>
  );
};
