import { canadaStates, countries, usaStates } from '../../constants/locales';
import { convertStringArrayToValueLabelArray } from '../../helpers/formatter';
import { inventoryPurchasePriority } from '../../constants/inventory-purchases';

export const countryOptions = countries;

export const getStatesByCountry = (country: string) => {
  if (!country) return [];
  if (country !== 'United States' && country !== 'Canada') return [];

  if (country === 'Canada') {
    return canadaStates;
  }

  return usaStates;
};

export const inventoryPurchasePriorityAsOptions =
  convertStringArrayToValueLabelArray(inventoryPurchasePriority);
