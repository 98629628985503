import styled, { css } from 'styled-components';

export const StyledInfoWrapper = styled.div<{
  $pointer: boolean;
  selected: boolean;
}>`
  ${({ $pointer, selected, theme }) => {
    return css`
      border: 1px solid
        ${selected ? theme.colors.surfaces.green.t3 : theme.colors.dividers.t1};
      outline: none;
      padding: 2rem;
      cursor: ${$pointer && 'pointer'};
    `;
  }}
`;
