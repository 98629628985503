import { Conditional } from 'gantri-components';
import { DateWithUserDataCell } from '../../../../../../components/common/custom-data-cells/date-user-data-cell';
import { CellJobCreatedAtProps } from './cell-job-created-at.types';

export const CellJobCreatedAt = (props: CellJobCreatedAtProps) => {
  const {
    getValue,
    row: { original },
  } = props;

  const createdAt = getValue();

  return (
    <Conditional condition={!!createdAt}>
      <DateWithUserDataCell
        data={original.statusInfo}
        date={createdAt}
        jobId={original.id}
        type="Created"
      />
    </Conditional>
  );
};
