import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { UpdateProductArgs } from './update-product.types';

export const updateProduct = ({ product }: UpdateProductArgs) => {
  return axios
    .put(`${getCoreApiUrl('products')}/${product.id}`, product)
    .then(({ data }) => {
      return data;
    });
};
