import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  SetGrowthMultiplierArgs,
  SetGrowthMultiplierResponse,
} from './set-growth-multiplier.types';

export const setGrowthMultiplier = (args: SetGrowthMultiplierArgs) => {
  return axios
    .put<SetGrowthMultiplierResponse>(
      `${coreApiUrl}/products/category/growth-multiplier`,
      args,
    )
    .then(({ data }) => {
      return data;
    });
};
