import { Conditional, Flex, Icon, Typography } from 'gantri-components';
import { StyledTableIconContainer } from './cell-job-type.styles';
import { jobTypeToIconTypeMap } from '../../../../../../constants/jobs';
import { CellJobTypeProps } from './cell-job-type.types';
import { JobType } from '../../../../../../constants/options';

export const CellJobType = (props: CellJobTypeProps) => {
  const { getValue } = props;

  const jobType = getValue();

  return (
    <Conditional condition={!!jobType}>
      <Flex alignItems="center">
        <StyledTableIconContainer>
          <Icon name={jobTypeToIconTypeMap?.[jobType as JobType]} />
        </StyledTableIconContainer>
        <Typography color="t1" text={jobType} variant="p2" />
      </Flex>
    </Conditional>
  );
};
