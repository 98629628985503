import { Product } from '../api/products/products.types';

/** Returns the product name + the category, minus "Light" */
export const getProductName = ({
  category,
  name,
}: Pick<Product, 'category' | 'name'>) => {
  let productName = '';

  if (name) {
    productName += name;
  }

  if (category) {
    const categoryWithoutLight = category.replace(/\s+light/i, '');

    if (!!categoryWithoutLight) {
      productName += ` ${categoryWithoutLight}`;
    }
  }

  return productName;
};
