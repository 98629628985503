import {
  Button,
  Cell,
  Conditional,
  Flex,
  Grid,
  TextField,
  Typography,
} from 'gantri-components';
import { useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { Label } from '../../../../../../components/label';
import { ToggleEditableInputCell } from '../../../../../../components/toggle-editable-input-cell';
import { ToggleEditableTextAreaCell } from '../../../../../../components/toggle-editable-text-area-cell';
import { getActiveQueries } from '../../../../../../helpers/checks';
import { formatDate } from '../../../../../../helpers/formatter';
import { VendorPageQueries } from '../../../../vendor.types';
import { GeneralSectionProps } from './general-section.types';
import { VendorFormSchema } from './vendor-form.schema';
import { Vendor } from '../../../../../../api/vendors/vendors.types';

export const GeneralSection = (props: GeneralSectionProps) => {
  const { editedVendor, handleSave, toggleEditMode } = props;

  const { isNewVendor } = getActiveQueries<VendorPageQueries>();

  const [isEditing, setIsEditing] = useState<boolean>(isNewVendor);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const onSubmit = async (vendor: Vendor) => {
    await handleSave({ setIsEditing, vendor });
  };

  return (
    <Formik
      initialValues={editedVendor}
      validateOnChange
      validateOnMount
      validationSchema={VendorFormSchema}
      onSubmit={onSubmit}
    >
      {({
        dirty: hasBeenEdited,
        isValid,
        setFieldValue,
        values: {
          city,
          contact,
          country,
          createdAt,
          email,
          lastOrdered,
          name,
          notes,
          password,
          phone,
          state,
          userName,
          website,
        },
      }: FormikProps<Vendor>) => {
        return (
          <Form>
            <Grid
              columns={
                isEditing ? '1fr repeat(2, max-content)' : '1fr max-content'
              }
              gap="1.6rem"
            >
              <Typography text="General" variant="h4" />
              <Conditional
                condition={isEditing}
                Fallback={
                  <Button
                    text="Edit"
                    variant="secondary"
                    onClick={() => {
                      toggleEditMode({ isEditing, setIsEditing });
                    }}
                  />
                }
              >
                <Button
                  text="Cancel"
                  variant="secondary"
                  onClick={() => {
                    toggleEditMode({ isEditing, setIsEditing });
                  }}
                />
                <Button
                  disabled={!isValid || (!isNewVendor && !hasBeenEdited)}
                  text="Save"
                  type="submit"
                />
              </Conditional>
            </Grid>
            <Grid
              alignItems="baseline"
              columns={{ lg: 3, md: 2, sm: isEditing ? 1 : 2 }}
              gap="1.6rem"
            >
              <ToggleEditableInputCell
                cellProps={{ width: isEditing ? 1 : { lg: 1, md: 2 } }}
                isEditing={isEditing}
                label="Vendor Name"
                name="name"
                required
                value={name}
              />
              <Cell>
                <Label paddingBottom=".5x" text="Date last ordered" />
                <Typography
                  text={lastOrdered ? formatDate(lastOrdered) : '-'}
                />
              </Cell>
              <Cell>
                <Label paddingBottom=".5x" text="Date created" />
                <Typography text={formatDate(createdAt)} />
              </Cell>
              <ToggleEditableInputCell
                isEditing={isEditing}
                label="Contact name"
                name="contact"
                required
                value={contact}
              />
              <ToggleEditableInputCell
                isEditing={isEditing}
                label="Phone"
                name="phone"
                type="tel"
                value={phone}
              />
              <ToggleEditableInputCell
                cellProps={{ width: isEditing ? 1 : { lg: 1, md: 2 } }}
                isEditing={isEditing}
                label="Email"
                name="email"
                required
                value={email}
              />
              <ToggleEditableInputCell
                cellProps={{ width: isEditing ? 1 : { lg: 1, md: 2 } }}
                Component={
                  <Conditional condition={!!website}>
                    <a
                      href={
                        /^https?:\/\//i.test(website)
                          ? website
                          : `https://${website}`
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      <Typography
                        color="link"
                        style={{ lineBreak: 'anywhere' }}
                        text={website}
                      />
                    </a>
                  </Conditional>
                }
                isEditing={isEditing}
                label="Website"
                name="website"
                value={website}
              />
              <ToggleEditableInputCell
                isEditing={isEditing}
                label="Username"
                name="userName"
                value={userName}
              />
              <Cell>
                <Label paddingBottom=".5x" text="Password" />
                <Conditional
                  condition={isEditing}
                  Fallback={
                    <Conditional condition={!!password}>
                      <Flex alignItems="center" gap="2.8rem">
                        <Typography
                          color="t1"
                          text={
                            isPasswordVisible
                              ? password
                              : new Array(15).fill('•').join('')
                          }
                        />
                        <Button
                          size="small"
                          text={isPasswordVisible ? 'Hide' : 'Show'}
                          variant="secondary"
                          onClick={() => {
                            setIsPasswordVisible(!isPasswordVisible);
                          }}
                        />
                      </Flex>
                    </Conditional>
                  }
                >
                  <TextField
                    name="password"
                    type={isPasswordVisible ? 'text' : 'password'}
                    value={password}
                    onBlur={() => {
                      setIsPasswordVisible(false);
                    }}
                    onFocus={() => {
                      setIsPasswordVisible(true);
                    }}
                    onTextChange={(value) => {
                      return setFieldValue('password', value);
                    }}
                  />
                </Conditional>
              </Cell>
              <Conditional
                condition={isEditing}
                Fallback={
                  <Cell width={isEditing ? 1 : { lg: 1, md: 2 }}>
                    <Label paddingBottom=".5x" text="Location" />
                    <Typography text={city} />
                    <Typography text={state} />
                    <Typography text={country} />
                  </Cell>
                }
              >
                <ToggleEditableInputCell
                  isEditing={isEditing}
                  label="City"
                  name="city"
                  required
                  value={city}
                />
                <ToggleEditableInputCell
                  isEditing={isEditing}
                  label="State / Province"
                  name="state"
                  required
                  value={state}
                />
                <ToggleEditableInputCell
                  isEditing={isEditing}
                  label="Country"
                  name="country"
                  required
                  value={country}
                />
              </Conditional>
              <ToggleEditableTextAreaCell
                cellProps={{
                  width: isEditing ? { lg: 3, md: 2, sm: 1 } : { lg: 3, md: 2 },
                }}
                isEditing={isEditing}
                label="Notes"
                name="notes"
                value={notes}
              />
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
