import { useMemo } from 'react';
import { Grid, Typography } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { PartTemplate } from '../../../../../../../../api/products/products.types';
import { ProductJobsPartMenu } from './components/product-jobs-part-menu';
import { ProductJobsPartNameAndToggle } from './components/product-jobs-part-name-and-toggle';
import { useGetVersionDetails } from '../../../../hooks/use-get-version-details';
import { PartTemplateColorOverridesCell } from './components/part-template-color-overrides-cell';
import { PaintedStatus } from '../../components/add-or-edit-part-modal/components/add-or-edit-part-modal-content/components/part-painted-status/components/painted-status';

export const useGetPartsSectionColumns = () => {
  const { isDraft } = useGetVersionDetails();

  const columns = useMemo(() => {
    const columns: ColumnDef<PartTemplate>[] = [
      {
        accessorKey: 'name',
        cell: ({
          getValue,
          row,
        }: CellContext<PartTemplate, PartTemplate['name']>) => {
          const { getIsSelected, index, original, toggleSelected } = row;

          return (
            <ProductJobsPartNameAndToggle
              getIsSelected={getIsSelected}
              index={index}
              name={getValue()}
              part={original}
              toggleSelected={toggleSelected}
            />
          );
        },
        header: () => {
          return (
            <Grid alignItems="center" columns="4rem 1fr" gap="2x">
              <div data-spacer="" />
              <Typography text="Name" textStyle="bold" />
            </Grid>
          );
        },
        size: 350,
      },
      {
        accessorKey: 'material',
        header: 'Material',
      },
      {
        accessorKey: 'machineType',
        header: 'Machine',
      },
      {
        accessorKey: 'paintedStatus',
        cell: ({
          getValue,
        }: CellContext<PartTemplate, PartTemplate['paintedStatus']>) => {
          return <PaintedStatus value={getValue()} />;
        },
        header: 'Painted',
      },
      {
        accessorKey: 'hasBondo',
        cell: ({
          getValue,
        }: CellContext<PartTemplate, PartTemplate['hasBondo']>) => {
          const hasBondo = getValue();

          return <Typography text={hasBondo ? 'Yes' : 'No'} />;
        },
        header: 'Bondo',
        size: 80,
      },
      {
        accessorKey: 'totalPrintBlocks',
        header: '# Prints',
        size: 80,
      },
      {
        accessorKey: 'colorOverride',
        cell: ({
          getValue,
        }: CellContext<PartTemplate, PartTemplate['colorOverride']>) => {
          const colorOverrides = getValue() || [];

          return (
            <PartTemplateColorOverridesCell colorOverrides={colorOverrides} />
          );
        },
        header: 'Color Overrides',
        size: 110,
      },
      isDraft && {
        cell: ({ row }: CellContext<PartTemplate, never>) => {
          return <ProductJobsPartMenu part={row.original} />;
        },
        id: 'menu',
        size: 30,
      },
    ].filter(Boolean);

    return columns;
  }, [isDraft]);

  return columns;
};
