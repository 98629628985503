import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { FetchGrowthMultiplierResponse } from './fetch-growth-multiplier.types';
import { fetchGrowthMultiplier } from './fetch-growth-multiplier';
import { useInvalidateFetchStockSummaryCache } from '../../../stocks/routes/fetch-stock-summary/fetch-stock-summary.query';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';

export const useFetchGrowthMultiplierQuery = <
  TransformedData = FetchGrowthMultiplierResponse,
>(
  props?: GenericFetchProps<
    never,
    FetchGrowthMultiplierResponse,
    TransformedData
  >,
) => {
  const { data, isLoading } = useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch growth multipliers.',
    queryObj: fetchGrowthMultiplier,
    ...props,
  });

  return {
    ...data,
    isLoading,
  };
};

export const useInvalidateFetchGrowthMultiplierCache = () => {
  const invalidateFetchGrowthMultiplierCache = useGetInvalidateQueryCache(
    fetchGrowthMultiplier,
  );

  const { invalidateFetchStockSummaryCache } =
    useInvalidateFetchStockSummaryCache();

  return {
    invalidateFetchGrowthMultiplierCache: async () => {
      await Promise.all([
        invalidateFetchGrowthMultiplierCache(),
        invalidateFetchStockSummaryCache(),
      ]);
    },
  };
};
