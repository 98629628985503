import { Users } from '../../../types/store';
import { formatAsCurrency } from '../../../helpers/formatter';

export const getCreditAvailable = (credits: Users['credits']): string => {
  return credits.total
    ? formatAsCurrency(Number(credits.total), { isCents: true })
    : credits.creditTransactions
    ? formatAsCurrency(
        credits.creditTransactions.reduce((a, b) => {
          return a + b.amount;
        }, 0),
        { isCents: true },
      )
    : '0';
};
