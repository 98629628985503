import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { CancelReferralResponse } from './cancel-referral.types';
import { CancelData } from '../../referrals.types';

export const cancelReferral = (referralId: number, data: CancelData) => {
  return axios.put<CancelReferralResponse>(
    `${coreApiUrl}/cancel-reward/${referralId}`,
    data,
  );
};
