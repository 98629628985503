import {
  Box,
  Button,
  Checkbox,
  Conditional,
  Stack,
  Typography,
} from 'gantri-components';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { shipmentActionsModalSteps } from '../../../shipment-actions-modal.constants';
import { shipmentActionsModalAtoms } from '../../../shipment-actions-modal.atoms';
import { StepShipNormallyContentPropsDef } from './step-ship-normally-content.types';

export const StepShipNormallyContent = (
  props: StepShipNormallyContentPropsDef,
) => {
  const { order } = props;

  const shipmentId = useRecoilValue(shipmentActionsModalAtoms.shipmentId);
  const isDoNotShip = useRecoilValue(
    shipmentActionsModalAtoms.shipNormally.isDoNotShip,
  );
  const [isGift, setIsGift] = useRecoilState(
    shipmentActionsModalAtoms.shipNormally.isGift,
  );
  const [isGiftMessageIncluded, setIsGiftMessageIncluded] = useRecoilState(
    shipmentActionsModalAtoms.shipNormally.isGiftMessageIncluded,
  );
  const [isInPlainPackaging, setIsInPlainPackaging] = useRecoilState(
    shipmentActionsModalAtoms.shipNormally.isInPlainPackaging,
  );
  const setStep = useSetRecoilState(shipmentActionsModalAtoms.step);

  useEffect(() => {
    const shipment = order.shipments.find((record) => {
      return record.id === shipmentId;
    });
    const isGift = shipment.stocks.some(({ isGift }) => {
      return isGift;
    });

    setIsGift(isGift);
  }, []);

  return (
    <>
      <Conditional condition={!isGift && !isDoNotShip}>
        <Typography
          align="center"
          text="Confirm shipment transaction and shipping option."
        />
      </Conditional>

      <Conditional condition={isGift}>
        <Typography text="Confirm the following and shipping option." />
        <Stack gap="x">
          <Checkbox
            checked={isInPlainPackaging}
            labelText="This item has plain packaging"
            onSelected={setIsInPlainPackaging}
          />
          <Checkbox
            checked={isGiftMessageIncluded}
            labelText="Gift message is included"
            onSelected={setIsGiftMessageIncluded}
          />
        </Stack>
      </Conditional>

      <Box paddingTop="2x">
        <Button
          justifySelf="center"
          text="Ship Manually"
          variant="ghost"
          onClick={() => {
            setStep(shipmentActionsModalSteps.shipManually);
          }}
        />
      </Box>
    </>
  );
};
