import { useState } from 'react';
import { partsApi, stocksApi } from '../../../../api';
import { Part as PartType } from '../../../../api/parts/routes/get-part/get-part.types';
import { useNotification } from '../../../../hooks/useNotification';
import { Stock } from '../../../../api/stocks/stocks.types';

export const useFetchPartDetails = ({ partId }: { partId: number }) => {
  const [part, setPart] = useState<PartType>();
  const [stock, setStock] = useState<Stock>();

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const fetchPartDetails = async () => {
    try {
      showLoading();

      const { data } = await partsApi.getPart(partId);
      const [part] = data.part;

      setPart(part);

      if (!!part?.stockId) {
        try {
          const data = await stocksApi.getStock({
            stockId: part?.stockId,
          });

          setStock(data.stock);
        } catch (error: unknown) {
          notifyAxiosError({
            error,
            fallbackMessage: 'Unable to fetch stock details for part.',
          });
        }
      }
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to fetch part details.',
      });
    } finally {
      hideLoading();
    }
  };

  return { fetchPartDetails, part, setPart, stock };
};
