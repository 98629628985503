import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import {
  GetPaginatedBatchesArgs,
  GetPaginatedBatchesResponse,
} from './get-paginated-batches.types';
import { getPaginatedBatches } from './get-paginated-batches';

const fetchPaginatedBatchesQueriesObj = getGenericQueryObj({
  groupName: 'fetch-paginated-batches',
  query: getPaginatedBatches,
});

export const useFetchPaginatedBatches = <
  TransformedData = GetPaginatedBatchesResponse,
>(
  props?: GenericFetchProps<
    GetPaginatedBatchesArgs,
    GetPaginatedBatchesResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch paginated batches.',
    queryObj: fetchPaginatedBatchesQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchPaginatedBatchesCache = () => {
  const invalidateFetchPaginatedBatchesCache = useGetInvalidateQueryCache(
    fetchPaginatedBatchesQueriesObj,
  );

  return { invalidateFetchPaginatedBatchesCache };
};
