import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect, useMemo } from 'react';
import { productAtoms } from '../../../../../../../../product.atoms';
import { JobBlockStock } from '../../../../../../../../../../api/products/products.types';
import { publishDraftModalAtoms } from '../../publish-draft-modal.atoms';
import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../../../constants/options';

export const useCheckForMissingItems = () => {
  const product = useRecoilValue(productAtoms.product);
  const isAccessory = useRecoilValue(productAtoms.isAccessory);

  const setIsWithoutAssemblyInstructions = useSetRecoilState(
    publishDraftModalAtoms.isWithoutAssemblyInstructions,
  );
  const setIsWithoutAssembleStandardInventory = useSetRecoilState(
    publishDraftModalAtoms.isWithoutAssembleStandardInventory,
  );
  const setIsWithoutPackInventory = useSetRecoilState(
    publishDraftModalAtoms.isWithoutPackInventory,
  );
  const setIsWithoutParts = useSetRecoilState(
    publishDraftModalAtoms.isWithoutParts,
  );

  const getIsWithoutInventory = (
    inventoryRequestsKey: Extract<
      keyof JobBlockStock,
      | 'assembleStandardInventories'
      | 'assembleVariantInventories'
      | 'packInventory'
    >,
  ) => {
    return !product.stockBlock[inventoryRequestsKey].length;
  };

  const isWithoutAssembleStandardInventory = useMemo(() => {
    return isAccessory
      ? false
      : getIsWithoutInventory('assembleStandardInventories');
  }, []);
  const isWithoutPackInventory = useMemo(() => {
    return getIsWithoutInventory('packInventory');
  }, []);

  const isWithoutAssemblyInstructions = useMemo(() => {
    if (isAccessory) {
      return false;
    }

    const { instructions } = product.stockBlock;

    return !instructions.some(({ data, step, type }) => {
      return (
        step === jobSteps.assemble &&
        type === jobTypeOptions.assemble &&
        (!!data.text || !!data.files.length)
      );
    });
  }, []);

  const isWithoutParts = useMemo(() => {
    return isAccessory ? false : !product.parts.length;
  }, []);

  const isProductMissingItems =
    isWithoutAssemblyInstructions ||
    isWithoutAssembleStandardInventory ||
    isWithoutPackInventory ||
    isWithoutParts;

  useEffect(() => {
    setIsWithoutAssemblyInstructions(isWithoutAssemblyInstructions);
  }, [isWithoutAssemblyInstructions]);

  useEffect(() => {
    setIsWithoutAssembleStandardInventory(isWithoutAssembleStandardInventory);
  }, [isWithoutAssembleStandardInventory]);

  useEffect(() => {
    setIsWithoutPackInventory(isWithoutPackInventory);
  }, [isWithoutPackInventory]);

  useEffect(() => {
    setIsWithoutParts(isWithoutParts);
  }, [isWithoutParts]);

  return {
    isProductMissingItems,
  };
};
