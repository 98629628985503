import { useQueryFetch } from '../../../../hooks/use-fetch/use-query-fetch';
import { FetchUsersEmailsResponse } from './fetch-users-emails.types';
import { fetchUsersEmailsQueriesObj } from './fetch-users-emails';
import { UseQueryProps } from '../../../../hooks/use-fetch/react-queries.types';

interface Props<TData> extends UseQueryProps<TData, FetchUsersEmailsResponse> {
  search?: string;
}

export const useFetchUsersEmailsQuery = <TData = Record<string, unknown>>(
  props?: Props<TData>,
) => {
  const { data, isLoading } = useQueryFetch({
    config: {
      enabled: !!props?.search,
      queryFn: () => {
        return fetchUsersEmailsQueriesObj.query(props);
      },
      queryKey: fetchUsersEmailsQueriesObj.key(props),
      select: (response: FetchUsersEmailsResponse) => {
        return props?.transform?.(response) ?? response;
      },
    },
    showLoading: false,
  });

  return { isLoading, users: data as TData };
};
