import { Conditional, Flex, Icon, Tooltip } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { DependencyList, useMemo } from 'react';
import { StockNumberDataCell } from '../../../../../../../parts/custom-data-cells/stock-number';
import { formatDate } from '../../../../../../../../helpers/formatter';
import { MEDIUM_FORMAT } from '../../../../../../../../constants/dates';
import { PartStatusBadge } from '../../../../../../../../components/common/part-status-badge';
import { partStatuses } from '../../../../../../../../constants/options';
import { NotApplicableText } from '../../../../../../../../components/common/not-applicable-text';
import { StyledAnchor } from '../../../../../../../../components/common/styled-anchor';
import routePaths from '../../../../../../../../config/route-paths';
import { getIsClickableIfValue } from '../../../../../../../../helpers/get-is-clickable-if-value';
import { ShortProductSummary } from '../../../../../../../../components/common/short-product-summary';
import { PartReleaseRateQueuePart } from '../../../../../../../../api/parts/routes/get-part-release-rate-details/get-part-release-rate-details.types';

export const usePartReleaseRateQueueColumns = (
  dependencies?: DependencyList,
) => {
  const columns: ColumnDef<PartReleaseRateQueuePart>[] = useMemo(() => {
    return [
      {
        accessorKey: 'id',
        cell: ({
          getValue,
        }: CellContext<
          PartReleaseRateQueuePart,
          PartReleaseRateQueuePart['id']
        >) => {
          const id = getValue();

          return (
            <StyledAnchor href={`${routePaths.parts}/${id}`} text={`#${id}`} />
          );
        },
        header: 'Part #',
        maxSize: 80,
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'product',
        cell: ({
          getValue,
        }: CellContext<
          PartReleaseRateQueuePart,
          PartReleaseRateQueuePart['product']
        >) => {
          const product = getValue();

          return <ShortProductSummary {...product} />;
        },
        header: 'Product',
        size: 300,
      },
      {
        accessorKey: 'stock',
        cell: ({
          getValue,
        }: CellContext<
          PartReleaseRateQueuePart,
          PartReleaseRateQueuePart['stock']
        >) => {
          const stock = getValue();

          return !!stock ? (
            <StockNumberDataCell {...stock} />
          ) : (
            <NotApplicableText />
          );
        },
        header: 'Stock #',
        maxSize: 80,
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'order',
        cell: ({
          getValue,
        }: CellContext<
          PartReleaseRateQueuePart,
          PartReleaseRateQueuePart['order']
        >) => {
          const order = getValue();

          return (
            <Conditional condition={!!order}>
              <StyledAnchor
                href={`${routePaths.orders}/${order?.id}`}
                text={`${order?.type} #${order?.id}`}
              />
            </Conditional>
          );
        },
        header: 'Order #',
        maxSize: 120,
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'name',
        header: 'Part Name',
      },
      {
        accessorKey: 'createdAt',
        cell: ({ getValue }) => {
          return formatDate(getValue(), MEDIUM_FORMAT);
        },
        header: 'Created',
        size: 80,
      },
      {
        accessorKey: 'status',
        cell: ({
          getValue,
        }: CellContext<
          PartReleaseRateQueuePart,
          PartReleaseRateQueuePart['status']
        >) => {
          const status = getValue();

          return (
            <Conditional
              condition={status === partStatuses.discarded}
              Fallback={<PartStatusBadge status={status} />}
            >
              <Flex alignItems="center" gap="0.8rem">
                <PartStatusBadge status={status} />
                <Tooltip
                  description="Due to the stock being cancelled, this part now has a Discarded status. Please ensure that the part is discarded."
                  overlayContainerStyles={{ display: 'flex' }}
                  position="top"
                >
                  <Icon name="alert:i_circle" />
                </Tooltip>
              </Flex>
            </Conditional>
          );
        },
        header: 'Status',
      },
    ];
  }, dependencies);

  return columns;
};
