import * as yup from 'yup';
import { ChangeUserTypeModalFormDataDef } from './user-type-modal.types';
import { userRoles } from '../../../constants/users';
import { changeUserTypeModalSteps } from './user-type-modal.constants';

const requiredText = 'Required';

export const changeUserTypeModalFormSchema: yup.SchemaOf<ChangeUserTypeModalFormDataDef> =
  yup.object().shape({
    isSander: yup.boolean().when('type', {
      is: userRoles.user,
      otherwise: (schema) => {
        return schema.required(requiredText);
      },
      then: (schema) => {
        return schema.nullable();
      },
    }),
    step: yup
      .mixed()
      .oneOf(Object.values(changeUserTypeModalSteps))
      .required(requiredText),
    type: yup.mixed().oneOf(Object.values(userRoles)).required(requiredText),
  });
