import axios from 'axios';
import { environment } from '../../../../environment';
import {
  CreateAddressArgs,
  CreateAddressResponse,
} from './create-address.types';

export const createAddress = (body: CreateAddressArgs) => {
  return axios.post<CreateAddressResponse>(
    `${environment.API_URL}/address/create`,
    body,
  );
};
