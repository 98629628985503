import { atom } from 'recoil';
import { MarketplaceColor } from './components/info-tab-marketplace-skus-overview/components/marketplace-skus-colors/marketplace-skus-colors.types';

const atomKeyPrefix = 'PRODUCT_INFO_SKUS_OVERVIEW';

export const productInfoTabAtoms = {
  marketplaceColors: atom<MarketplaceColor[]>({
    default: [],
    key: `${atomKeyPrefix}-marketplace-colors`,
  }),
};
