import { Conditional } from 'gantri-components';
import { DateWithUserDataCell } from '../../../../../../components/common/custom-data-cells/date-user-data-cell';
import { CellJobEndDateProps } from './cell-job-end-date.types';

export const CellJobEndDate = (props: CellJobEndDateProps) => {
  const {
    getValue,
    row: { original },
  } = props;

  const endDate = getValue();

  return (
    <Conditional condition={!!endDate}>
      <DateWithUserDataCell
        data={original.statusInfo}
        date={endDate}
        jobId={original.id}
        type="End"
      />
    </Conditional>
  );
};
