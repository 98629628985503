import { StartPrintPrintJobModal } from './components/start-print-print-job-modal';
import { StartJobModalProps } from './start-job.types';
import { StartAssembleStageJobModal } from './components/start-assemble-stage-job-modal';
import { StartFinishingStageJobModal } from './components/start-finishing-stage-job-modal';
import { CompleteAssemblyModal } from '../complete-job/complete-assembly-modal';
import { CompletePrintQaModal } from '../complete-job/complete-print-qa-modal';
import { CompleteFinishingQaModal } from '../complete-job/complete-finishing-qa-modal';
import { CompleteFinalQAModal } from '../complete-job/complete-final-qa-modal';
import { StartJobGenericModal } from './components/start-job-generic-modal';
import { CompletePackingModal } from '../complete-job/complete-packing-modal';
import { CompleteSandJobModal } from '../complete-job/complete-sand-job-modal';
import { CompleteJobImmediatelyModal } from '../complete-job/complete-job-immediately-modal';
import { getJobInfo } from '../../../helpers/get-job-info';
import { CompletePrintTransferModal } from '../complete-job/complete-print-transfer-modal';
import { NonProductJobModal } from './components/start-non-product-job-modal';
import { jobStatuses } from '../../../../../constants/options';
import { JobHasWrongStatusModal } from '../job-has-wrong-status-modal';
import { CompletePrintHarvestModal } from '../complete-job/complete-print-harvest-modal';

export const StartJobModal = (props: StartJobModalProps) => {
  const { job } = props;

  const {
    isAssembleJob,
    isAssembleStageJob,
    isFinalQaJob,
    isFinishBondoJob,
    isFinishGlueJob,
    isFinishMaskJob,
    isFinishSandJob,
    isFinishStageJob,
    isFinishingQcJob,
    isNonProductJob,
    isPackingJob,
    isPrintHarvestJob,
    isPrintPrintJob,
    isPrintQcJob,
    isPrintTransferJob,
    isReady,
  } = getJobInfo(job);

  if (!isReady) {
    return (
      <JobHasWrongStatusModal {...props} expectedStatus={jobStatuses.ready} />
    );
  }

  if (isPrintTransferJob) {
    return <CompletePrintTransferModal {...props} />;
  }

  if (isPrintPrintJob) {
    // TODO - rename to CompletePrintPrintJobModal
    return <StartPrintPrintJobModal {...props} />;
  }

  if (isPrintHarvestJob) {
    return <CompletePrintHarvestModal {...props} />;
  }

  if (isPrintQcJob) {
    return <CompletePrintQaModal {...props} />;
  }

  if (isFinishMaskJob || isFinishBondoJob || isFinishGlueJob) {
    return <CompleteJobImmediatelyModal {...props} />;
  }

  if (isFinishSandJob) {
    return <CompleteSandJobModal {...props} />;
  }

  if (isFinishingQcJob) {
    return <CompleteFinishingQaModal {...props} />;
  }

  if (isFinishStageJob) {
    // TODO - rename to CompleteFinishingStageJobModal
    return <StartFinishingStageJobModal {...props} />;
  }

  if (isAssembleStageJob) {
    // TODO - rename to CompleteAssembleStageJobModal
    return <StartAssembleStageJobModal {...props} />;
  }

  if (isAssembleJob) {
    return <CompleteAssemblyModal {...props} />;
  }

  if (isFinalQaJob) {
    return <CompleteFinalQAModal {...props} />;
  }

  if (isPackingJob) {
    return <CompletePackingModal {...props} />;
  }

  if (isNonProductJob) {
    // TODO - rename to CompleteNonProductJobModal
    return <NonProductJobModal {...props} />;
  }

  // Paint and Prime jobs
  // TODO - rename to CompleteJobGenericModal
  return <StartJobGenericModal {...props} />;
};
