import { dataAttrIsRowSelected, Table } from 'gantri-components';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  .tr {
    &[data-is-data-row='false'] {
      box-shadow: none;

      > .td {
        box-shadow: none !important;
        padding-right: 0;
      }
    }

    &[data-is-data-row='true'][${dataAttrIsRowSelected}='true'] {
      box-shadow: none;

      > .td {
        border-bottom: none;
      }
    }

    &[data-is-data-row='false'] {
      > .td {
        width: 100%;
      }
    }
  }
`;
