import { useModal } from 'gantri-components';
import { CreateVersionModal } from '../../components/create-version-modal';
import { UseDraftModalsProps } from './use-draft-modals.types';
import { PublishDraftModal } from '../../components/publish-draft-modal';
import { DiscardDraftModal } from '../../components/discard-draft-modal';

export const useDraftModals = (props: UseDraftModalsProps) => {
  const { currentProduct, productId, productVersion } = props;

  const versionId = productVersion?.id;

  const [showNewVersionModal, closeNewVersionModal] = useModal(() => {
    return (
      <CreateVersionModal
        productId={productId}
        onClose={closeNewVersionModal}
      />
    );
  }, []);

  const [showPublishModal, hidePublishModal] = useModal(() => {
    return (
      <PublishDraftModal product={currentProduct} onClose={hidePublishModal} />
    );
  }, [currentProduct]);

  const [showDiscardDraftModal, hideDiscardDraftModal] = useModal(() => {
    return (
      <DiscardDraftModal
        versionId={versionId}
        onClose={hideDiscardDraftModal}
      />
    );
  }, [versionId]);

  return {
    showDiscardDraftModal,
    showNewVersionModal,
    showPublishModal,
  };
};
