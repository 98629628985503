import { memo, ChangeEvent } from 'react';
import {
  Grid,
  Cell,
  Typography,
  TextField,
  TextArea,
  FileUploader,
  HandleDeleteFile,
  HandleUploadsComplete,
} from 'gantri-components';
import { StyledSectionContainer } from '../../designer-feature.styles';
import { IntroSectionProps } from './intro-section.types';
import {
  fileRecoveredMessage,
  useCloudinaryFileUploader,
} from '../../../../hooks/use-cloudinary-file-uploader';
import { useNotification } from '../../../../hooks/useNotification';

export const Section = (props: IntroSectionProps) => {
  const {
    ctaLink,
    ctaTitle,
    id,
    introParagraph,
    introPhoto,
    introTitle,
    updateFeatureInfo,
    userId,
    videoUrl,
  } = props;

  const { notify } = useNotification();

  const handleUploadsComplete: HandleUploadsComplete = async ([
    { fileName },
  ]) => {
    updateFeatureInfo({
      key: 'introPhoto',
      type: 'key',
      value: fileName,
    });
  };

  const onFileRecovered = async (updatedFileName: string) => {
    notify(fileRecoveredMessage.saveRequired, {
      keepOpen: true,
    });

    updateFeatureInfo({
      key: 'introPhoto',
      type: 'key',
      value: updatedFileName,
    });
  };

  const onFileDelete: HandleDeleteFile = async () => {
    updateFeatureInfo({
      key: 'introPhoto',
      type: 'key',
      value: '',
    });
  };

  const { fileUploaderProps } = useCloudinaryFileUploader<'designers'>({
    directory: 'designers',
    fileName: introPhoto,
    fileType: 'collection-pages',
    handleUploadsComplete,
    identifiers: {
      collectionId: id,
      userId,
    },
    onFileDelete,
    onFileRecovered,
    transformFileName: ({ applyUuid, removeWhitespace }) => {
      return applyUuid(removeWhitespace('intro'));
    },
  });

  const onInputChange = (
    property:
      | 'videoUrl'
      | 'introTitle'
      | 'ctaTitle'
      | 'ctaLink'
      | 'introParagraph',
  ) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      updateFeatureInfo({
        key: property,
        type: 'key',
        value: event.target.value,
      });
    };
  };

  return (
    <StyledSectionContainer>
      <Typography
        marginBottom="1.2rem"
        text="Intro section"
        textStyle="bold"
        variant="h4"
      />
      <Grid
        columns={{ lg: 'max-content 1fr 1fr 1fr', sm: '1fr' }}
        marginBottom="1.6rem"
      >
        <Cell>
          <Typography marginBottom="5px" text="Image" textStyle="bold" />
          <FileUploader {...fileUploaderProps} variant="thumbnail" />
        </Cell>
        <Cell>
          <TextField
            debounce={500}
            labelPosition="top"
            labelText="Video URL"
            placeholder="video Url..."
            value={videoUrl}
            onChange={onInputChange('videoUrl')}
          />
        </Cell>
      </Grid>
      <Grid columns={{ lg: 2, sm: 1 }}>
        <Cell>
          <TextField
            debounce={500}
            labelPosition="top"
            labelText="Title"
            placeholder="Title..."
            value={introTitle}
            onChange={onInputChange('introTitle')}
          />
          <Grid columns={{ lg: 2, sm: 1 }} marginTop="3rem">
            <TextField
              debounce={500}
              labelPosition="top"
              labelText="CTA Title"
              placeholder="CTA title..."
              value={ctaTitle}
              onChange={onInputChange('ctaTitle')}
            />
            <TextField
              debounce={500}
              labelPosition="top"
              labelText="CTA Link"
              placeholder="CTA link..."
              value={ctaLink}
              onChange={onInputChange('ctaLink')}
            />
          </Grid>
        </Cell>
        <Cell>
          <TextArea
            debounce={500}
            labelPosition="top"
            labelText="Paragraph"
            minRows={4}
            placeholder="Paragraph..."
            value={introParagraph}
            onChange={onInputChange('introParagraph')}
          />
        </Cell>
      </Grid>
    </StyledSectionContainer>
  );
};

export const IntroSection = memo(Section);
