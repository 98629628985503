import { useEffect, useState } from 'react';
import { clone } from 'lodash';
import {
  Button,
  Cell,
  Conditional,
  Flex,
  Grid,
  Stack,
  Typography,
  useModal,
} from 'gantri-components';
import { useHandleFetchDesign } from '../../hooks/fetch-design';
import { DesignHeading } from '../design-heading';
import {
  finalizationRecords,
  initialContent,
} from './design-content.constants';
import { Messages } from '../messages';
import { Design } from '../../../../api/designs/routes/fetch-design/fetch-design.types';
import { designsApi } from '../../../../api';
import { StyledActions, StyledReviewItem } from './design-content.styles';
import { CompleteDesignModal } from './components/complete-design-modal';
import { useInvalidateFetchDesignCache } from '../../../../api/designs/routes';

export const DesignContent = () => {
  const { current, setCurrent } = useHandleFetchDesign();
  const [content, setContent] = useState<Design['content']>(initialContent);
  const [canProceed, setCanProceed] = useState(false);

  const { invalidateFetchDesignCache } = useInvalidateFetchDesignCache();

  const [showCompleteDesignModal, hideCompleteDesignModal] = useModal(() => {
    return (
      <CompleteDesignModal
        content={content}
        designId={current?.id}
        designName={current?.name}
        onClose={hideCompleteDesignModal}
      />
    );
  }, [content]);

  const recordUpdated = (key, value) => {
    return async () => {
      const temporal = clone(content);

      content.productFinalization[key].approved = value;
      setContent(temporal);

      await designsApi.saveDesign(current.id, {
        content: temporal,
        save: true,
        step: 'Content',
      });

      await invalidateFetchDesignCache();
    };
  };

  useEffect(() => {
    if (current?.content) {
      setContent(current.content);
      setCanProceed(current.content.stepProgress === 100);
    }
  }, [current?.content]);

  return (
    <Conditional condition={!!current}>
      <Grid columns="2fr 1fr" gap="3x" paddingTop="2x">
        <Stack gap="7x">
          <DesignHeading design={current} />

          <Stack gap="3x">
            <Flex alignItems="center" justifyContent="space-between">
              <Typography text="Product finalization" variant="h2" />
              <Typography text="Complete" />
            </Flex>

            <Stack gap="0">
              {finalizationRecords.map((record) => {
                return (
                  <StyledReviewItem key={record.key}>
                    <Flex alignItems="center" justifyContent="space-between">
                      <Typography text={record.label} />

                      <Grid columns={2} gap="1rem">
                        <Button
                          text="Yes"
                          variant={
                            content.productFinalization[record.key].approved
                              ? 'primary'
                              : 'secondary'
                          }
                          onClick={recordUpdated(record.key, true)}
                        />
                        <Button
                          text="No"
                          variant={
                            content.productFinalization[record.key].approved
                              ? 'secondary'
                              : 'primaryAlert'
                          }
                          onClick={recordUpdated(record.key, false)}
                        />
                      </Grid>
                    </Flex>
                  </StyledReviewItem>
                );
              })}
            </Stack>
          </Stack>

          {content.status !== 'Completed' && content.status !== 'Failed' && (
            <StyledActions>
              <Button
                disabled={!canProceed}
                text="Complete Design"
                onClick={showCompleteDesignModal}
              />
            </StyledActions>
          )}

          <Cell />
        </Stack>
        <Cell>
          <Messages
            current={current}
            designId={current?.id}
            messages={current?.messages || []}
            notes={current?.notes || []}
            updateMessages={setCurrent}
          />
        </Cell>
      </Grid>
    </Conditional>
  );
};
