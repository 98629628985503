import { Cell, Stack, TextArea, Typography } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { debounce } from 'lodash';
import { publishDraftModalAtoms } from '../../../publish-draft-modal.atoms';
import { DraftDetailsContentProps } from './draft-details-content.types';

export const DraftDetailsContent = (props: DraftDetailsContentProps) => {
  const { product } = props;

  const draftVersion = product.version.version;
  const [draftDetails, setDraftDetails] = useRecoilState(
    publishDraftModalAtoms.draftDetails,
  );

  return (
    <Stack>
      <Cell gap="0.6rem">
        <Typography text="Version type" textStyle="bold" />
        <Typography text={`${product.version.type} v${draftVersion}`} />
      </Cell>

      <TextArea
        labelText="Description"
        minRows={2}
        placeholder="Describe the changes made...."
        required
        value={draftDetails}
        onTextChange={debounce(setDraftDetails, 300)}
      />
    </Stack>
  );
};
