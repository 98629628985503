import { useGetInvalidateQueryCache } from '../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { getPaginatedPressRecords } from './get-paginated-press-records';
import {
  GetPaginatedPressRecordsArgs,
  GetPaginatedPressRecordsResponse,
} from './get-paginated-press-records.types';

export const useGetPaginatedPressRecords = <
  TransformedData = GetPaginatedPressRecordsResponse,
>(
  props?: GenericFetchProps<
    GetPaginatedPressRecordsArgs,
    GetPaginatedPressRecordsResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch press records.',
    queryObj: getPressRecordsQueriesObj,
    ...props,
  });
};

export const getPressRecordsQueriesObj = getGenericQueryObj({
  groupName: 'press-paginated-records',
  query: getPaginatedPressRecords,
});

export const useInvalidateGetPaginatedPressRecordsCache = () => {
  const invalidateGetPaginatedPressRecordsCache = useGetInvalidateQueryCache(
    getPressRecordsQueriesObj,
  );

  return { invalidateGetPaginatedPressRecordsCache };
};
