import { atom } from 'recoil';
import { Job } from '../../../../../../api/jobs/jobs.types';
import { NextJob } from '../../../../../../api/jobs/routes/complete-job/complete-job.types';
import { JobDetails } from '../../../../../../api/jobs/routes/get-job-details/get-job-details.types';
import { InitialEvaluation } from '../../common/evaluate-part-content/evaluate-part-content.types';
import { completedPrintChecklistDefaults } from './complete-print-harvest-modal.constants';
import {
  CompletePrintChecklist,
  CompletePrintModalStep,
} from './complete-print-harvest-modal.types';

export const atomKeyPrefix = 'COMPLETE_PRINT_MODAL';

export const completePrintHarvestModalAtoms = {
  buildPlateIssueType: atom<'60_jobs' | '120_jobs' | undefined>({
    default: undefined,
    key: `${atomKeyPrefix}-build-plate-issue-type`,
  }),
  checklist: atom<CompletePrintChecklist>({
    default: completedPrintChecklistDefaults,
    key: `${atomKeyPrefix}-checklist`,
  }),
  duplicatedJobs: atom<Job[]>({
    default: [],
    key: `${atomKeyPrefix}-duplicated-jobs`,
  }),
  isChecklistReviewed: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-checklist-reviewed`,
  }),
  isRfidPlaced: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-rfid-placed`,
  }),
  job: atom<JobDetails>({
    // @ts-expect-error
    default: {},
    key: `${atomKeyPrefix}-job`,
  }),
  machine: atom<Job['machine']>({
    default: null,
    key: `${atomKeyPrefix}-machine`,
  }),
  machinePrintHoursExceeded: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-machine-print-hours-exceeded`,
  }),
  nextJob: atom<NextJob>({
    default: null,
    key: `${atomKeyPrefix}-next-job`,
  }),
  printCompletionStatus: atom<InitialEvaluation>({
    default: 'PASS',
    key: `${atomKeyPrefix}-print-completion-status`,
  }),
  step: atom<CompletePrintModalStep>({
    default: 'EVALUATE_PART',
    key: `${atomKeyPrefix}-step`,
  }),
  updateOnClose: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
};
