import { Stack, Typography, Badge, Flex, Conditional } from 'gantri-components';
import { Link } from 'react-router-dom';
import React from 'react';
import { formatDate } from '../../../../helpers/formatter';
import { HistoryItemProps } from './history-item.types';
import {
  StyledBadgeContainer,
  StyledUserContainer,
} from './history-item.styles';
import { LONG_FORMAT } from '../../../../constants/dates';

export const HistoryItem = (props: HistoryItemProps) => {
  const {
    description,
    getVersion,
    id,
    status,
    statusInfo: {
      created: { date, user },
      lastUpdate,
    },
    version,
  } = props;

  const renderDraftItem = () => {
    const userData = lastUpdate || user;

    return (
      <Stack gap="0.8rem">
        <StyledBadgeContainer>
          <Badge
            color="yellow_100"
            text="Draft"
            textColor="monochrome_black"
            width="4.9rem"
          />
        </StyledBadgeContainer>
        <Flex gap="0.5rem">
          <Typography text="last changed by" />
          {userData?.firstName && (
            <Link to={`/users/${userData?.id}`}>
              <Typography
                color="link"
                decoration="underline"
                text={`${userData?.firstName} ${userData?.lastName}`}
              />
            </Link>
          )}
        </Flex>
        <Typography
          color="link"
          decoration="underline"
          text="View latest edits"
          onClick={() => {
            return getVersion({ id, type: 'draft' });
          }}
        />
      </Stack>
    );
  };

  const renderPreviousItem = () => {
    return (
      <Stack gap="0.8rem">
        <Flex alignItems="center" gap="0.8rem">
          <Typography fontWeight="bold" text={`v${version}`} />
          <Conditional condition={status === 'Published'}>
            <Badge
              color="green_100"
              text="Current version"
              textColor="monochrome_black"
            />
          </Conditional>
        </Flex>
        <StyledUserContainer>
          <Typography
            text={
              user?.firstName
                ? `published ${formatDate(date, LONG_FORMAT)} by `
                : `published ${formatDate(date, LONG_FORMAT)}`
            }
          />
          {user?.firstName && (
            <Link to={`/users/${user.id}`}>
              <Typography
                color="link"
                decoration="underline"
                text={`${user?.firstName} ${user?.lastName}`}
              />
            </Link>
          )}
        </StyledUserContainer>
        <Typography color="t2" text={description} />
        <Typography
          color="link"
          decoration="underline"
          text="View version"
          onClick={() => {
            return getVersion({ id, type: 'previous' });
          }}
        />
      </Stack>
    );
  };

  return <>{status !== 'Draft' ? renderPreviousItem() : renderDraftItem()}</>;
};
