import { Cell, Conditional, FormikInput, Typography } from 'gantri-components';
import { Label } from '../label';
import { ToggleEditableInputCellProps } from './toggle-editable-input-cell.types';

export const ToggleEditableInputCell = (
  props: ToggleEditableInputCellProps,
) => {
  const {
    Component,
    cellProps,
    isEditing,
    label,
    labelProps,
    name,
    textProps,
    value,
    ...rest
  } = props;

  return (
    <Cell {...cellProps}>
      <Label paddingBottom=".5x" text={label} {...labelProps} />
      <Conditional
        condition={isEditing}
        Fallback={
          Component || (
            <Typography
              text={value}
              {...textProps}
              style={{ lineBreak: 'anywhere' }}
            />
          )
        }
      >
        <FormikInput name={name} {...rest} />
      </Conditional>
    </Cell>
  );
};
