import { CompletePrintQaModal } from './complete-print-qa-modal';
import { CompletePrintHarvestModal } from './complete-print-harvest-modal';
import { CompleteJobModalProps } from './complete-job.types';
import { CompleteAssemblyModal } from './complete-assembly-modal';
import { CompleteFinishingQaModal } from './complete-finishing-qa-modal';
import { CompleteFinalQAModal } from './complete-final-qa-modal';
import { RepairJobModal } from '../../../../machine/components/repair-job-modal';
import { getJobInfo } from '../../../helpers/get-job-info';
import { CompleteSandJobModal } from './complete-sand-job-modal';
import { CompletePackingModal } from './complete-packing-modal';
import { CompleteJobImmediatelyModal } from './complete-job-immediately-modal';
import { CompletePrintTransferModal } from './complete-print-transfer-modal';
import { NonProductJobModal } from '../start-job/components/start-non-product-job-modal';
import { jobStatuses } from '../../../../../constants/options';
import { JobHasWrongStatusModal } from '../job-has-wrong-status-modal';

export const CompleteJobModal = (props: CompleteJobModalProps) => {
  const { job } = props;

  const {
    isAssembleJob,
    isFinalQaJob,
    isFinishSandJob,
    isFinishingQcJob,
    isInProgress,
    isNonProductJob,
    isPackingJob,
    isPrintHarvestJob,
    isPrintPrintJob,
    isPrintQcJob,
    isPrintTransferJob,
    isRepairJob,
  } = getJobInfo(job);

  if (!isInProgress) {
    return (
      <JobHasWrongStatusModal
        {...props}
        expectedStatus={jobStatuses.inProgress}
      />
    );
  }

  if (isPrintTransferJob) {
    return <CompletePrintTransferModal {...props} />;
  }

  if (
    // ? See `hasPrintHarvest` JSDoc comment for functionality explanation
    isPrintPrintJob ||
    isPrintHarvestJob
  ) {
    return <CompletePrintHarvestModal {...props} />;
  }

  if (isPrintQcJob) {
    return <CompletePrintQaModal {...props} />;
  }

  if (isFinishSandJob) {
    return <CompleteSandJobModal {...props} />;
  }

  if (isFinishingQcJob) {
    return <CompleteFinishingQaModal {...props} />;
  }

  if (isAssembleJob) {
    return <CompleteAssemblyModal {...props} />;
  }

  if (isFinalQaJob) {
    return <CompleteFinalQAModal {...props} />;
  }

  if (isRepairJob) {
    // TODO - rename to CompleteRepairJobModal
    return <RepairJobModal {...props} />;
  }

  if (isPackingJob) {
    return <CompletePackingModal {...props} />;
  }

  if (isNonProductJob) {
    // TODO - rename to CompleteNonProductJobModal
    return <NonProductJobModal {...props} />;
  }

  return <CompleteJobImmediatelyModal {...props} />;
};
