import { generateSkus, getSkuDetails } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import { useGetBulkUploaderPatterns } from '../use-get-bulk-uploader-patterns';
import { useGetFileType } from '../use-get-file-type';
import { ProductSkuLevelFileType } from '../../../../../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';
import { useGetMatchingVariantCodes } from './hooks/use-get-matching-variant-codes';
import { useGetMatchingColorCodes } from './hooks/use-get-matching-color-codes';

export const useProcessFileNameAndReturnMatchingSkus = () => {
  const product = useRecoilValue(productAtoms.product);

  const getFileType = useGetFileType();

  const { productIdOrNameRegExp } = useGetBulkUploaderPatterns();

  const getMatchingColorCodes = useGetMatchingColorCodes();

  const getMatchingVariantCodes = useGetMatchingVariantCodes();

  const processFileNameAndReturnMatchingSkus = (props: {
    fileExtension: string;
    fileName: string;
  }): { fileType: ProductSkuLevelFileType; skus: string[] } => {
    const { fileExtension, fileName } = props;

    // VALIDATE FILE TYPE (defaults to general product photo)
    const fileType = getFileType({ fileExtension, fileName });

    const invalidReturnValue = { fileType, skus: [] };

    // VALIDATE PRODUCT ID/NAME
    const isProductValid = productIdOrNameRegExp.test(fileName);

    if (!isProductValid) {
      return invalidReturnValue;
    }

    // VALIDATE COLOR CODE
    const { colorCodes, isValid } = getMatchingColorCodes({ fileName });

    if (!isValid) {
      return invalidReturnValue;
    }

    // VALIDATE VARIANTS
    const variantCodes = getMatchingVariantCodes({ fileName });

    // GENERATE SKUS
    const { productId, size: sizeCode } = getSkuDetails({
      sku: product.skus[0],
      skuPartialCodes: product.skuPartialCodes,
    });

    const skus = generateSkus({
      colors: colorCodes,
      productId,
      size: sizeCode,
      variants: variantCodes,
    });

    return { fileType, skus };
  };

  return processFileNameAndReturnMatchingSkus;
};
