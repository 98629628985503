import {
  Cell,
  ColorPickerItem,
  Conditional,
  Flex,
  Icon,
  ProductColorCode,
  productColorsMap,
  Stack,
  Typography,
} from 'gantri-components';
import { useToggle } from 'react-use';
import { StyledJobDetailsPanelGrid } from './job-details-panel-large.styles';
import {
  JobDetailsPanelLargeProps,
  JobDetailsPanelSection,
} from './job-details-panel-large.types';
import { jobDetailsPanelLargeDefaultProps } from './job-details-panel-large.presets';
import { MinorVersionInfo } from '../../../../../../components/minor-version-info';
import { MachineIssues } from '../../../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel/components/machine-issues';
import routePaths from '../../../../../../config/route-paths';
import { SectionItem } from './components/section-item';
import { PartDiagram } from './components/part-diagram';
import { MobileToggleHeader } from './components/mobile-toggle-header';
import { MinorVersionInfoType } from '../../../../../../components/minor-version-info/minor-version-info.types';
import { ColorSwatchAndLabel } from '../../../../../../components/common/color-swatch-and-label';
import { Divider } from '../../../../../../components/divider';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import { NotApplicableText } from '../../../../../../components/common/not-applicable-text';
import { JobInstruction } from './components/part-diagram/components/job-instruction';
import { ShortProductSummary } from '../../../../../../components/common/short-product-summary';
import { SandDurationCell } from '../../../../../batches/hooks/use-get-batches-columns/components/sand-duration-cell';
import { MachineDetails } from './components/machine-details';

export const JobDetailsPanelLarge = (props: JobDetailsPanelLargeProps) => {
  const {
    job,
    machineName,
    machineType,
    repairType,
    sectionsToShow,
    stock,
    title,
    ...gridProps
  } = props;

  const [isExpanded, toggleIsExpanded] = useToggle(true);

  const diagramSections: JobDetailsPanelSection[] = [
    'part diagram',
    'product photo',
  ];
  const renderDiagramSection = sectionsToShow.some((section) => {
    return diagramSections.includes(section);
  });

  let partsArray: {
    /** Only used to render color swatches for Stock parts list */
    color?: ProductColorCode;
    id: number;
    name: string;
    versionInfo?: MinorVersionInfoType;
  }[] =
    /* `stock` currently only provided on assemble > stage jobs */
    !!stock
      ? stock.parts.map((part) => {
          return {
            ...part,
            color: part.color as ProductColorCode,
            versionInfo: {
              version: part.version,
            } as MinorVersionInfoType,
          };
        })
      : [
          {
            id: job?.part?.id,
            name: job?.part?.name,
            versionInfo: job?.versionInfo,
          },
        ];

  partsArray = partsArray.filter(({ id }) => {
    return !!id;
  });

  return (
    <StyledJobDetailsPanelGrid
      alignItems={{ md: 'baseline' }}
      data-testid="job-details-panel"
      gap="0"
      height="unset"
      {...gridProps}
    >
      <MobileToggleHeader
        isExpanded={isExpanded}
        title={title}
        toggleIsExpanded={toggleIsExpanded}
      />
      <Conditional condition={isExpanded}>
        {/* Stack added to aid in styling its contents */}
        <Stack gap="0" paddingBottom="3rem">
          <SectionItem
            condition={sectionsToShow.includes('assigned to')}
            title="Assigned to"
          >
            <Conditional
              condition={!!job?.assignedTo?.userName}
              Fallback={<NotApplicableText />}
            >
              <StyledAnchor
                target="_blank"
                text={job?.assignedTo?.userName}
                to={`${routePaths.users}/${job?.assignedTo?.userId}`}
              />
            </Conditional>
          </SectionItem>
          <SectionItem
            condition={sectionsToShow.includes('stock')}
            title="Stock ID"
          >
            <Conditional
              condition={!!job?.stockId}
              Fallback={<NotApplicableText />}
            >
              <StyledAnchor
                target="_blank"
                text={`#${job?.stockId}`}
                to={`${routePaths.stocks}/${job?.stockId}`}
              />
            </Conditional>
          </SectionItem>
          <SectionItem
            condition={sectionsToShow.includes('part') && !!partsArray.length}
            title={!!stock ? 'Parts' : 'Part'}
          >
            <Stack gap=".5x">
              {partsArray.map((part) => {
                return (
                  <Flex
                    key={part.id}
                    alignItems="center"
                    gap="0.8rem"
                    wrap="wrap"
                  >
                    <StyledAnchor
                      target="_blank"
                      text={`#${part.id}`}
                      to={`${routePaths.parts}/${part.id}`}
                    />
                    <Conditional condition={!!stock}>
                      <Conditional
                        condition={!!part.color}
                        Fallback={
                          <Icon color="t2" name="work:no_entry" size="1.6rem" />
                        }
                      >
                        <ColorPickerItem
                          color={{
                            code: productColorsMap[part.color]?.code,
                            name: productColorsMap[part.color]?.shortColorName,
                          }}
                          size="2rem"
                        />
                      </Conditional>
                    </Conditional>
                    <Typography text={part.name} />
                    <span>
                      <MinorVersionInfo
                        {...part.versionInfo}
                        color="t2"
                        version={
                          part.versionInfo?.version
                            ? `Version ${part.versionInfo?.version}`
                            : undefined
                        }
                      />
                    </span>
                  </Flex>
                );
              })}
            </Stack>
          </SectionItem>
          <SectionItem
            condition={sectionsToShow.includes('sand duration')}
            title="Sand Duration"
          >
            <SandDurationCell sandDuration={job?.expectedSandRawDuration} />
          </SectionItem>
          <SectionItem
            condition={sectionsToShow.includes('color & material')}
            title="Color and Material"
          >
            <Flex alignItems="center" gap="s1" wrap="wrap">
              <ColorSwatchAndLabel
                colorCode={job?.part?.color as ProductColorCode}
              />
              <Conditional condition={!!job?.part?.material}>
                <Divider
                  borderColor="t2"
                  borderLocation="left"
                  paddingTop="2rem"
                />
                <Typography text={job?.part?.material} />
              </Conditional>
            </Flex>
          </SectionItem>
          <SectionItem
            condition={sectionsToShow.includes('product')}
            title="Product"
          >
            <ShortProductSummary {...job?.product} />
          </SectionItem>
          <SectionItem
            condition={sectionsToShow.includes('job name')}
            title="Job Name"
          >
            <Typography color="t1" text={job?.description} />
          </SectionItem>
          <SectionItem
            condition={
              sectionsToShow.includes('machine type') &&
              !!(machineType || job?.machine?.type || job?.machineType)
            }
            title="Machine Type"
          >
            <Typography
              text={machineType || job?.machine?.type || job?.machineType}
            />
          </SectionItem>
          <SectionItem
            condition={
              sectionsToShow.includes('handouts') &&
              !!job?.product?.handouts?.length
            }
            title="Handouts"
          >
            <Cell>
              {job?.product?.handouts?.map((handout) => {
                return (
                  <StyledAnchor
                    key={handout.id}
                    href={handout.link}
                    style={{ wordBreak: 'break-word' }}
                    target="_blank"
                    text={handout.name}
                  />
                );
              })}
            </Cell>
          </SectionItem>
          <SectionItem
            condition={
              sectionsToShow.includes('inventories') &&
              !!job?.inventoryRequests?.length
            }
            title="Inventories"
          >
            <Cell>
              {job?.inventoryRequests?.map(({ inventory, need, unit }) => {
                return (
                  <Typography
                    key={inventory.id}
                    text={`${need} ${
                      inventory.unit?.toLowerCase() ?? unit?.toLowerCase() ?? ''
                    } ${inventory.name}`}
                  />
                );
              })}
            </Cell>
          </SectionItem>
          <SectionItem
            condition={sectionsToShow.includes('weight') && !!job?.weight}
            title="Weight"
          >
            <Typography text={job?.weight && `${job?.weight} g`} />
          </SectionItem>
          <SectionItem
            condition={
              sectionsToShow.includes('machine') &&
              !!(machineName || job?.machine?.name)
            }
            title="Machine"
          >
            <MachineDetails
              bay={job?.machine?.bay}
              name={machineName || job?.machine?.name}
            />
          </SectionItem>
          <SectionItem
            condition={
              sectionsToShow.includes('repair type') && !!repairType?.type
            }
            title="Repair Type"
          >
            <Typography text={repairType?.type} />
          </SectionItem>
          <SectionItem
            condition={
              sectionsToShow.includes('machine issues') &&
              !!job?.machineIssues?.length
            }
            title="Issue(s) to Address"
          >
            <MachineIssues issues={job?.machineIssues || []} />
          </SectionItem>
          <SectionItem
            condition={sectionsToShow.includes('notes') && !!job?.notes}
            title="Notes"
          >
            <Typography
              htmlText={job?.notes?.replaceAll('\n', '<br/>') || ''}
            />
          </SectionItem>
        </Stack>
        <Conditional
          condition={
            sectionsToShow.includes('instructions') &&
            (!!job?.instruction?.text || !!job?.instruction?.files?.length)
          }
        >
          <JobInstruction instruction={job?.instruction} />
        </Conditional>
        <Conditional condition={renderDiagramSection}>
          <PartDiagram
            imageType={
              sectionsToShow.includes('part diagram') ? 'part' : 'product'
            }
            job={job}
            showSwatches={sectionsToShow.includes('part diagram swatches')}
          />
        </Conditional>
      </Conditional>
    </StyledJobDetailsPanelGrid>
  );
};

JobDetailsPanelLarge.defaultProps = jobDetailsPanelLargeDefaultProps;
